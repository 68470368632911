import { createStore } from "vuex";
//To Persist The Data When Page Reloaded
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    state: {
        lang: "ar",
        user: {
            email: "",
            name: "",
            id: "",
            country_code: "",
            mobile_no: "",
            profile_image: "",
            role_id: "",
        },
        superadmin: {},
        order: null,
        storeCredit: 0,
        storePostpaidOrder: {
            is_enabled: null,
            max_amount: null,
        },
        firebaseToken: null,
        unReadNotificationCount: false,
    },
    getters: {
        user(state) {
            return state.user;
        },
        store(state) {
            return state.user.stores ? state.user.stores[0] : null;
        },
        getLang(state) {
            return state.lang;
        },
        serviceProviderCompany(state) {
            return state.user.service_provider_company ?
                state.user.service_provider_company :
                null;
        },
        storePostpaidOrder(state) {
            return state.storePostpaidOrder;
        },
        orderData(state) {
            return state.order;
        },
        storeCredit(state) {
            return state.storeCredit;
        },
        firebaseToken(state) {
            return state.firebaseToken;
        },
        unReadNotificationCount(state) {
            return state.unReadNotificationCount;
        },
    },
    mutations: {
        setUser(state, data) {
            if (data) state.user = data;
        },
        setOrder(state, data) {
            state.order = data;
        },
        setStoreCredit(state, data) {
            state.storeCredit = data;
        },
        setStorePostpaidOrder(state, data) {
            state.storePostpaidOrder = data;
        },
        setLang(state, data) {
            state.lang = data;
        },
        reset(state) {
            // TODO: Write better reset method
            state.user = {
                email: "",
                name: "",
                id: "",
                country_code: "",
                mobile_no: "",
                profile_image: "",
                role_id: "",
            };
            state.permission = [];
        },
        setFirebaseToken(state, data) {
            state.firebaseToken = data;
        },
        setUnreadNotificationCount(state, data) {
            state.unReadNotificationCount = data;
        },
    },
    actions: {
        setLang(context, data) {
            return new Promise((resolve) => {
                if (data) {
                    context.commit("setLang", data);
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        },
        setUser(context, data) {
            context.commit("setUser", data);
        },
        setOrder(context, data) {
            context.commit("setOrder", data);
        },
        setStoreCredit(context, data) {
            context.commit("setStoreCredit", data);
        },
        setStorePostpaidOrder(context, data) {
            context.commit("setStorePostpaidOrder", data);
        },
        authenticate(context, data) {
            return new Promise((resolve, reject) => {
                if (data.to.meta && data.to.meta.module) {
                    var moduleToView = data.to.meta.module;
                    //var permission = context.state.permission;
                    if (context.state.permission[moduleToView]) {
                        resolve(true);
                    } else {
                        reject(false);
                    }
                } else {
                    resolve(true);
                }
            });
        },
        reset(context, data) {
            context.commit("reset", data);
        },
        setFirebaseToken(context, data) {
            context.commit("setFirebaseToken", data);
        },
        setUnreadNotificationCount(context, data) {
            context.commit("setUnreadNotificationCount", data);
        },
    },
    plugins: [createPersistedState()],
});

export default store;