import List from "@/views/Pages/Banner/List";
import Upsert from "@/views/Pages/Banner/Upsert";

export default [
    {
        path: "banner",
        components: { default: List },
        name: "Banner List",
        meta: {
            title: "banner",
            role: [1],
        },
    },
    {
        path: "banner/:id",
        components: { default: Upsert },
        props: true,
        name: "Banner Upsert",
        meta: {
            title: "banner",
            role: [1],
        },
    },
];
