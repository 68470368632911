<template>
    <div>
        <hr />
        <div class="p-5 mb-0 card rounded-md">
            <h3 class="mb-5">{{ $t(addOrEdit) }}</h3>

            <Form @submit="onSubmit" v-loading="isLoading" class="">
                <div class="row">
                    <div class="col-md-6">
                        <label for="">{{ $t("name") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            :placeholder="$t('name')"
                            :label="$t('name')"
                            name="name"
                            v-model="form.name"
                        />
                        <ErrorMessage name="name" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="">{{ $t("name_ar") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            :placeholder="$t('name_ar')"
                            :label="$t('name_ar')"
                            name="name_ar"
                            v-model="form.name_ar"
                        />
                        <ErrorMessage name="name_ar" class="error" />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="">{{ $t("assembly_charge") }}</label>
                        <Field
                            type="text"
                            rules="required|decimal|min_value:0"
                            class="form-control"
                            :placeholder="$t('assembly_charge')"
                            :label="$t('assembly_charge')"
                            name="assembly_charge"
                            v-model="form.assembly_charge"
                        />
                        <ErrorMessage name="assembly_charge" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="status">{{ $t("status") }}</label>
                        <Field
                            v-slot="{ field }"
                            name="status"
                            rules="required"
                            :label="$t('status')"
                            v-model="form.status"
                        >
                            <el-select
                                class="d-block"
                                name="status"
                                v-bind="field"
                                :placeholder="$t('select')"
                                v-model="form.status"
                                filterable
                            >
                                <el-option
                                    v-for="option in defaultOptions.status"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="$t(option.label)"
                                    :key="option.label"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage name="status" class="error" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label
                            for="shipment_type_id"
                            class="form-control-label"
                            >{{ $t("shipment_type") }}</label
                        >
                        <Field
                            rules="required"
                            :label="$t('shipment_type')"
                            v-model="form.shipment_type_id"
                            name="shipment_type_id"
                        >
                            <el-select
                                :placeholder="$t('select')"
                                v-model="form.shipment_type_id"
                                class="d-block"
                                name="shipment_type_id"
                                filterable
                                rules="required"
                            >
                                <el-option
                                    v-for="option in shipmentTypeOptions"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="
                                        lang == 'ar'
                                            ? option.type_ar
                                            : option.type
                                    "
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage
                            name="shipment_type_id"
                            class="error"
                        ></ErrorMessage>
                        <google-maps
                            :lat="form.latitude"
                            :lon="form.longitude"
                            @locationChanged="locationChanged($event)"
                        />
                    </div>
                </div>

                <div class="w-full text-center mt-5">
                    <base-button
                        native-type="submit"
                        class="bg-primary text-white"
                        :loading="submitSpinner"
                    >
                        {{ id === "add" ? $t("create") : $t("update") }}
                    </base-button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getShipmentTypes } from "@/api/shipmentType";
import { add, edit, detail } from "@/api/item";

export default {
    data() {
        return {
            addOrEdit: "",
            form: {
                name: null,
                name_ar: null,
                status: null,
                shipment_type_id: null,
                assembly_charge: null,
            },
            isLoading: false,
            submitSpinner: false,
            shipmentTypeOptions: [],
        };
    },
    props: ["id"],
    mixins: [mixin],
    components: {},
    created() {
        this.getShipmentTypeOptions();
        if (this.id === "add") {
            this.addOrEdit = this.$t("add_item");
        } else {
            this.addOrEdit = this.$t("edit_item");
            this.getItem();
        }
    },

    methods: {
        getItem() {
            this.isLoading = true;
            detail(this.id, {
                include: "shipmentType",
            })
                .then((res) => {
                    this.isLoading = false;
                    this.form = res;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        async onSubmit(data) {
            this.submitSpinner = true;
            if (this.id == "add") {
                add(data)
                    .then((res) => {
                        this.submitSpinner = false;
                        this.$router.push({ name: "items" });
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        this.apiError(response);
                    });
            } else {
                edit(this.id, data)
                    .then((res) => {
                        this.submitSpinner = false;
                        this.$router.push({ name: "items" });
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        this.apiError(response);
                    });
            }
        },
        getShipmentTypeOptions() {
            getShipmentTypes({
                limit: -1,
                filter: {
                    status: "active",
                },
            })
                .then((res) => {
                    this.shipmentTypeOptions = res.data;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
    },
};
</script>

<style></style>
