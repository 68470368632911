import List from "@/views/Pages/PickupStore/List";
import Upsert from "@/views/Pages/PickupStore/Upsert";
export default [{
        path: "pickup-stores",
        components: { default: List },
        name: "pickup-stores",
        meta: {
            title: "pickup_stores",
            role: [1, 6],
        },
    },
    {
        path: "pickup-stores/:id",
        components: { default: Upsert },
        props: true,
        name: "pickup-store",
        meta: {
            title: "pickup_store",
            role: [1, 6],
        },
    },
];