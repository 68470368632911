<template>
    <div>
        <hr />
        <div class="card mb-0 p-5 rounded-md">
            <div
                class="border-0 card-header px-0 d-flex justify-content-between align-items-center"
            >
                <router-link
                    :to="{
                        name: 'Store Upsert',
                        params: { id: 'add' },
                    }"
                >
                    <button class="text-white btn btn-default btn-sm">
                        <i class="mr-2 text-white fas fa-plus-circle fa-lg">
                            <span>{{ $t("add_store") }}</span>
                        </i>
                    </button>
                </router-link>
            </div>

            <div class="d-flex mb-4 pt-2">
                <drop-down-filter
                    class="mr-3"
                    :dropDownFilters="[
                        {
                            id: 'source',
                            options: defaultOptions.storeSource,
                        },
                    ]"
                    @dropDownOptionSelected="
                        (optionsObject) =>
                            (filters = { ...filters, ...optionsObject })
                    "
                />
                <search-filter
                    :filters="[
                        {
                            id: 'name',
                        },
                        {
                            id: 'contact_name',
                        },
                        {
                            id: 'mobile_no',
                            type: 'number',
                        },
                    ]"
                    @search="(searchObject) => (filters = searchObject)"
                />
            </div>
            <!-- End of Filter Div -->
            <div class="row">
                <main class="col-md-12 col-xs-11 p-l-2 p-t-2">
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="stores"
                            v-loading="isLoading"
                            highlight-current-row
                            @row-click="rowClicked"
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('store_name')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.name }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('source')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ $t(row.source) }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('contact_name')"
                                min-width="180px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.user?.name }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('mobile_no')"
                                min-width="180px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                    >
                                        +{{ row.user?.country_code }}
                                        {{ row.user?.mobile_no }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('shipment_type')"
                                min-width="180px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{
                                            $store.getters.getLang === "ar"
                                                ? row.shipment_type?.type_ar
                                                : row.shipment_type?.type
                                        }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('credit')"
                                min-width="180px"
                                prop="credit"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0"
                                        >{{ row.credit }}
                                        {{
                                            row.credit != null ? $t("SAR") : ""
                                        }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('status')"
                                min-width="150px"
                                prop="status"
                            >
                                <template v-slot="{ row }">
                                    <custom-label :label="row.status" />
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('zidship_status')"
                                min-width="150px"
                                prop="status"
                            >
                                <template v-slot="{ row }">
                                    <custom-label
                                        v-if="row.zidship_status"
                                        :label="row.zidship_status"
                                    />
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('postpaid_order_enabled')"
                                min-width="150px"
                                prop="postpaid_order_enabled"
                            >
                                <template v-slot="{ row }">
                                    <custom-label
                                        :label="row.postpaid_order_enabled"
                                    />
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('actions')"
                                min-width="210px"
                            >
                                <template v-slot="{ row }">
                                    <div
                                        class="d-flex align-items-center flex-wrap action-width"
                                    >
                                        <update-store-status
                                            :store="row"
                                            @changeStatusCompleted="getStores"
                                            v-if="isUpdateZidshipStore(row)"
                                        >
                                            <template
                                                v-slot:default="{ clickEvent }"
                                            >
                                                <el-tooltip
                                                    :content="
                                                        $t('update_status')
                                                    "
                                                    placement="top"
                                                >
                                                    <badge
                                                        circle
                                                        size="lg"
                                                        class="bg-sky-dark text-secondary cursor-pointer"
                                                        style="cursor: pointer"
                                                        @click="clickEvent"
                                                    >
                                                        <i
                                                            class="fas fa-sync mx-1"
                                                        ></i>
                                                    </badge>
                                                </el-tooltip>
                                            </template>
                                        </update-store-status>
                                        <el-tooltip
                                            :content="$t('view')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                style="cursor: pointer"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="
                                                        '/stores/' +
                                                        row.id +
                                                        '/detail'
                                                    "
                                                >
                                                    <img
                                                        src="@/assets/svg/view.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>
                                        <el-tooltip
                                            :content="$t('edit')"
                                            placement="top"
                                            v-if="isUpdateZidshipStore(row)"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                :title="$t('edit')"
                                                style="cursor: pointer"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="'/stores/' + row.id"
                                                >
                                                    <img
                                                        src="@/assets/svg/edit-2.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>
                                        <store-credit-add-model
                                            v-if="
                                                (hasRole(1) || hasRole(7)) &&
                                                isUpdateZidshipStore(row)
                                            "
                                            :id="row.id"
                                            :currentRow="row"
                                            :title="$t('add_credit')"
                                            @creditAddModalCompleted="getStores"
                                        ></store-credit-add-model>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- End of Table--->
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="stores.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="storesCurrentPage"
                            :total="storesTotalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>
<script>
import { get } from "@/api/store";
import mixin from "@/mixins/common";
import UpdateStoreStatus from "@/components/common/UpdateStoreStatus.vue";
import DropDownFilter from "../../../components/common/DropDownFilter.vue";
import StoreCreditAddModel from "../../../components/common/StoreCreditAddModel.vue";

export default {
    data() {
        return {
            isLoading: false,
            showButtonLoader: false,
            stores: [],
            meta: {},
            storesCurrentPage: 1,
            filters: {
                name: "",
                contact_name: "",
                mobile_no: "",
                source: "",
            },
            storesTotalData: null,
        };
    },
    created() {
        this.getStores();
    },
    watch: {
        storesCurrentPage: {
            handler: function () {
                this.getStores();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.storesCurrentPage = 1;
                this.getStores();
            },
        },
    },
    mixins: [mixin],

    methods: {
        // viewStoreDetails(id) {
        //     this.$router.push({ name: "Store Details", params: { id } });
        // },
        // editStore(id) {
        //     this.$router.push({ name: "Store Upsert", params: { id } });
        // },
        getStores() {
            this.isLoading = true;
            get({
                page: this.storesCurrentPage,
                query: {
                    ...this.filters,
                },
                include: "user,shipment_type",
            })
                .then((res) => {
                    this.isLoading = false;
                    this.stores = res.data;
                    this.storesTotalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                name: "",
                contact_name: "",
                mobile_no: "",
            };
        },
        isUpdateZidshipStore(data) {
            if (data.source == "zidship_store" && data.status == "inactive") {
                return false;
            }
            return true;
        },
        rowClicked(row, column, event) {
            if (column && column.label != this.$t("actions")) {
                this.$router.push({
                    name: "Store Details",
                    params: { id: row.id },
                });
            }
            return;
        },
    },
    components: {
        StoreCreditAddModel,
        DropDownFilter,
        UpdateStoreStatus,
    },
};
</script>

<style></style>
