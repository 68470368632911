import List from "@/views/Pages/Users/List";
import View from "@/views/Pages/Users/View";
import Upsert from "@/views/Pages/Users/Upsert";

export default [
    {
        path: "users",
        components: { default: List },
        name: "Users List",
        meta: {
            title: "users",
            role: [1, 4],
        },
    },
    {
        path: "users/:id",
        components: { default: Upsert },
        props: true,
        name: "Users Upsert",
        meta: {
            title: "users",
            role: [1, 4],
        },
    },
    {
        path: "users/:id",
        components: { default: View },
        props: true,
        name: "User Details",
        meta: {
            title: "users",
            role: [1, 4],
        },
    },
];
