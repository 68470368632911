import List from "@/views/Pages/VehicleBrand/List";

export default [
    {
        path: "vehicle-brand",
        components: { default: List },
        name: "VehicleBrand List",
        meta: {
            title: "vehicle_brand",
            role: [1],
        },
    }
];
