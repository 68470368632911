import { createI18n } from "vue-i18n";

import enBase from "./en/index.json";
import arBase from "./ar/index.json";
const messages = {
    en: enBase,
    ar: arBase,
};

const $i18n = new createI18n({
    globalInjection: true,
    locale: localStorage.getItem("lang"), // set locale
    fallbackLocale: "ar", // set fallback locale
    messages, // set locale messages
    silentFallbackWarn: true,
    silentTranslationWarn: true,
});

export default $i18n;
