<template>
    <div>
        <hr />
        <div class="card mb-0 p-5 rounded-md">
            <!-- Filter Section Start -->
            <div class="d-flex mb-4 justify-content-start" style="gap: 20px">
                <search-filter
                    :filters="[
                        {
                            id: 'name',
                        },
                        // {
                        //     id: 'email',
                        //     type: 'email',
                        // },
                        {
                            id: 'mobile_no',
                            type: 'number',
                        },
                    ]"
                    @search="(searchObject) => (filters = searchObject)"
                />

                <drop-down-filter
                    :dropDownFilters="[
                        {
                            id: 'stc_updated',
                            options: defaultOptions.stcUpdateOption,
                        },
                    ]"
                    @dropDownOptionSelected="
                        (optionsObject) =>
                            (filters = { ...filters, ...optionsObject })
                    "
                />
                <drop-down-filter
                    :dropDownFilters="[
                        {
                            id: 'provider_credit_payment',
                            options: defaultOptions.providerCredit,
                        },
                    ]"
                    @dropDownOptionSelected="
                        (optionsObject) =>
                            (filters = { ...filters, ...optionsObject })
                    "
                />
                <drop-down-filter
                    :dropDownFilters="[
                        {
                            id: 'city_id',
                            label: 'city',
                            options: cityOptions,
                        },
                    ]"
                    @dropDownOptionSelected="
                        (optionsObject) =>
                            (filters = { ...filters, ...optionsObject })
                    "
                />
                <drop-down-filter
                    :dropDownFilters="[
                        {
                            id: 'status',
                            options: defaultOptions.userStatus,
                        },
                    ]"
                    @dropDownOptionSelected="
                        (optionsObject) =>
                            (filters = { ...filters, ...optionsObject })
                    "
                />

                <div>
                    <router-link :to="{ name: 'Provider Add' }">
                        <button
                            v-if="hasRole(9)"
                            type="button"
                            class="btn btn--new-order bg-primary text-white rounded-lg"
                        >
                            {{ $t("add_provider") }}
                        </button>
                    </router-link>
                </div>
            </div>
            <div class="d-flex mb-4 justify-content-start" style="gap: 20px">
                <drop-down-filter
                    :dropDownFilters="[
                        {
                            id: 'allow_business_order',
                            options: defaultOptions.allowBusinessOrderOption,
                        },
                        {
                            id: 'vehicle_type_id',
                            label: 'vehicle_type',
                            options: vehicleTypes,
                        },
                    ]"
                    @dropDownOptionSelected="
                        (optionsObject) =>
                            (filters = { ...filters, ...optionsObject })
                    "
                />
                <div>
                    <flat-picker
                        :config="{
                            allowInput: true,
                            dateFormat: 'd-m-Y',
                        }"
                        class="form-control datepicker"
                        :placeholder="$t('dashboard_page.start_date')"
                        v-model="filters.start_date"
                        @change="dateFilterChange"
                    />
                </div>
                <div>
                    <flat-picker
                        :config="{
                            allowInput: true,
                            dateFormat: 'd-m-Y',
                        }"
                        class="form-control datepicker"
                        :placeholder="$t('dashboard_page.end_date')"
                        v-model="filters.end_date"
                        @change="dateFilterChange"
                    />
                </div>
            </div>
            <!-- Filter Section End  -->
            <div class="row">
                <main
                    :class="
                        isFilter
                            ? 'col-md-9 col-xs-11 p-l-2 p-t-2'
                            : 'col-md-12 col-xs-11 p-l-2 p-t-2'
                    "
                >
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="providers"
                            cell-class-name="text-break"
                            v-loading="isLoading"
                            @row-click="rowClicked"
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('name')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.name }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('joined_date')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">
                                        {{ row.joined_date }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('mobile_no')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">
                                        +{{ row.country_code + " " }}
                                        {{ row.mobile_no }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('no_of_completed_orders')"
                                min-width="150px"
                            >
                                <template v-slot:header>
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('no_of_completed_orders') }}</span>
                                        <el-tooltip :content="$t('sort_by_orders')" placement="top">
                                            <img
                                                src="@/assets/svg/sort.svg"
                                                class="ml-2"
                                                style="cursor: pointer"
                                                @click="sortByCompletedOrders"
                                                alt="Sort"
                                            />
                                        </el-tooltip>
                                    </div>
                                </template>
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">
                                        {{ row.total_completed_trips }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('rating')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">
                                        {{ row.rating }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('credit')"
                                min-width="100px"
                                prop="credit"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0"
                                        >{{ row.credit }}
                                        {{
                                            row.credit != null ? $t("SAR") : ""
                                        }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('total_charge_credit')"
                                min-width="150px"
                                prop="total_charge_credit"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0"
                                        >{{ row.total_charge_credit }}
                                        {{
                                            row.total_charge_credit != null
                                                ? $t("SAR")
                                                : ""
                                        }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('allow_business_order')"
                                min-width="120px"
                                prop="allow_business_order"
                            >
                                <template v-slot="{ row }">
                                    <custom-label
                                        :label="row.allow_business_order"
                                    />
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('status')"
                                min-width="120px"
                                prop="status"
                            >
                                <template v-slot="{ row }">
                                    <custom-label :label="row.user_status" />
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('city')"
                                min-width="100px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">
                                        {{
                                            row.city
                                                ? $store.getters.getLang == "ar"
                                                    ? row.city.name_ar
                                                    : row.city.name
                                                : ""
                                        }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('actions')"
                                min-width="250px"
                            >
                                <template v-slot="{ row }">
                                    <div
                                        class="d-flex justify-content-between flex-wrap action-width"
                                    >
                                        <el-tooltip
                                            :content="$t('view')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                style="cursor: pointer"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="'/provider/' + row.id"
                                                >
                                                    <img
                                                        src="@/assets/svg/view.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>
                                        <!-- User Edit Modal  -->
                                        <el-tooltip
                                            :content="$t('edit_provider')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                style="cursor: pointer"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="
                                                        '/provider/' +
                                                        row.id +
                                                        '/edit'
                                                    "
                                                >
                                                    <img
                                                        src="@/assets/svg/edit-2.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>
                                        <!-- <user-edit-modal
                                            v-if="!hasRole(9)"
                                            :user="row"
                                            role="provider"
                                            :title="$t('edit_provider')"
                                            @userEditCompleted="getProviders"
                                        >
                                            <template
                                                v-slot:default="{ clickEvent }"
                                            >
                                                <el-tooltip
                                                    :content="$t('edit')"
                                                    placement="top"
                                                >
                                                    <badge
                                                        circle
                                                        size="lg"
                                                        class="bg-sky-dark text-secondary cursor-pointer"
                                                        :title="$t('edit')"
                                                        style="cursor: pointer"
                                                        @click="clickEvent"
                                                    >
                                                        <i
                                                            class="fas fa-pen mx-1"
                                                        ></i>
                                                    </badge>
                                                </el-tooltip>
                                            </template>
                                        </user-edit-modal> -->
                                        <el-tooltip
                                            :content="$t('wallet_history')"
                                            placement="top"
                                            v-if="
                                                hasRole(1) ||
                                                hasRole(6) ||
                                                hasRole(8)
                                            "
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                style="cursor: pointer"
                                                @click="
                                                    viewProviderWalletHistory(
                                                        row.id
                                                    )
                                                "
                                            >
                                                <img
                                                    src="@/assets/svg/credit-card.svg"
                                                />
                                            </badge>
                                        </el-tooltip>
                                        <!-- Custom Design for change status Icon with Badge display using SLots
                                Update User Status Component with props like user data  -->

                                        <update-user-status
                                            :key="`update-user-status-${row.id}`"
                                            v-if="!hasRole(9)"
                                            :user="row"
                                            @changeStatusCompleted="getProviders"
                                        >
                                            <template
                                                v-slot:default="{ clickEvent }"
                                            >
                                                <el-tooltip
                                                    :content="
                                                        $t('update_status')
                                                    "
                                                    placement="top"
                                                >
                                                    <badge
                                                        circle
                                                        size="lg"
                                                        class="bg-sky-dark text-secondary cursor-pointer"
                                                        style="cursor: pointer"
                                                        @click="clickEvent"
                                                    >
                                                        <i
                                                            class="fas fa-sync mx-1"
                                                        ></i>
                                                    </badge>
                                                </el-tooltip>
                                            </template>
                                        </update-user-status>
                                        <!--Alert  Modal with Delete Function -->
                                        <alert-modal
                                            v-if="!hasRole(9)"
                                            :id="row.id"
                                            :title="$t('providers')"
                                            :body="
                                                $t('provider_delete_confirm')
                                            "
                                            @alertModalCompleted="getProviders"
                                        ></alert-modal>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="providers.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
        <!--  End of Table -->
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getUsers, me } from "@/api/users";
import { get as getCityList } from "@/api/cities";
import { getVehicleTypes } from "@/api/vehicleType";
import AlertModal from "@/components/common/AlertModal.vue";
import UserEditModal from "@/components/common/UserEditModal.vue";
import UpdateUserStatus from "@/components/common/UpdateUserStatus.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
    data() {
        return {
            providers: [],
            cityOptions: [],
            isLoading: false,
            meta: {},
            user: null,
            currentPage: 1,
            params: {
                role: "provider",
                include: "city,credit_history,referral_order",
                store_id: this.userRole === 1 ? null : this.storeId,
                service_provider_company_id: this.$store.getters.user
                    ?.service_provider_company?.id,
                sort_by_orders: 0
            },
            totalData: null,
            isFilter: false,
            role: "",
            filters: {
                name: "",
                email: "",
                mobile_no: null,
                city_id: null,
                start_date: null,
                end_date: null,
            },
            vehicleTypes: [],
        };
    },
    components: {
        UpdateUserStatus,
        AlertModal,
        UserEditModal,
        flatPicker,
    },
    created() {
        this.getProviders();
        this.getCityOptions();
        this.getVehicleType();
        if (this.hasRole(9)) this.updateCredit();
    },
    mixins: [mixin],
    watch: {
        currentPage: {
            handler: function () {
                this.getProviders();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.currentPage = 1;
                this.getProviders();
            },
        },
    },
    methods: {
        getVehicleType() {
            getVehicleTypes({
                "filter[status]": "active",
                limit: -1,
            })
                .then((res) => {
                    res.data.forEach((item) => {
                        this.vehicleTypes.push({
                            value: item["id"],
                            label:
                                this.getLang == "ar"
                                    ? item["type_ar"]
                                    : item["type"],
                        });
                    });
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        updateCredit() {
            me().then((res) => {
                this.$store.dispatch(
                    "setStoreCredit",
                    res.user?.credit ? res.user?.credit : 0
                );
            });
        },
        toggleFilter() {
            this.isFilter = !this.isFilter;
            if (this.isFilter) {
                $("#togglebar").addClass("show");
            } else {
                $("#togglebar").removeClass("show");
            }
        },
        getProviders() {
            this.isLoading = true;
            getUsers("admin", {
                ...this.params,
                page: this.currentPage,
                filter: this.filters,
            })
                .then((res) => {
                    this.providers = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getCityOptions() {
            this.isLoading = true;
            getCityList({
                limit: -1,
            })
                .then((res) => {
                    res.data.forEach((item) => {
                        this.cityOptions.push({
                            value: item["id"],
                            label:
                                this.$store.getters.getLang == "ar"
                                    ? item["name_ar"]
                                    : item["name"],
                        });
                    });
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                name: "",
                email: "",
                mobile_no: null,
                city_id: null,
                start_date: null,
                end_date: null,
            };
            this.role = "";
        },
        viewProviderWalletHistory(userId) {
            let routeData = this.$router.resolve({
                name: "Wallet History List",
                params: { userId },
            });
            window.open(routeData.href, "_blank");
        },
        rowClicked(row, column, event) {
            if (column.label != this.$t("actions")) {
                this.$router.push({
                    name: "Provider Details",
                    params: { id: row.id },
                });
            }
            return;
        },
        dateFilterChange() {
            this.currentPage = 1;
            this.getProviders();
        },
        sortByCompletedOrders() {
            this.params.sort_by_orders === 1 ? this.params.sort_by_orders = 0 : this.params.sort_by_orders = 1; // Set the sorting parameter
            this.getProviders(); // Re-query data
        },
    },
};
</script>
<style></style>
