import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";

const firebaseApp = initializeApp({
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
});

navigator.serviceWorker
    .register(
        process.env.VUE_APP_IS_DEV ?
            "firebase-messaging-sw.js" :
            "firebase-messaging-sw-prod.js", {
        scope: "firebase-cloud-messaging-push-scope",
    }
    )
    .then((registration) => {
        //const messaging = getMessaging(firebaseApp);
        //messaging.useServiceWorker(registration);
    })
    .catch((err) => {
        console.log(err);
    });

export const firebaseMessaging = getMessaging(firebaseApp);
export const db = getFirestore(firebaseApp);
export default db;