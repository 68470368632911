import List from "@/views/Pages/Order/List";
import View from "@/views/Pages/Order/View";
import AddStoreOrder from '@/views/Pages/Order/AddStoreOrder.vue';
import Index from '@/views/Pages/PriceCalculator/Index.vue'
import AddOutOfTownOrder from '@/views/Pages/Order/AddOutOfTownOrder.vue';

export default [
    {
        path: "orders",
        components: { default: List },
        name: "Orders",
        meta: {
            title: "orders",
            role: [1, 4, 5, 6, 8],
        },
    },
    {
        path: "orders/:id",
        props: true,
        components: { default: View },
        name: "Order Details",
        meta: {
            title: "order_detail",
            role: [1, 4, 5, 6, 7, 8],
        },
    },
    {
        path: "store-orders",
        components: { default: List },
        name: "Store Orders",
        meta: {
            title: "store_orders",
            role: [1, 6, 7, 9],
        },
    },
    {
        path: "store-orders/:id",
        components: { default: View },
        props: true,
        name: "Store Order Detail",
        meta: {
            title: "store_order_detail",
            role: [1, 6, 7, 9],
        },
    },
    {
        path: '/add-store-order',
        name: 'Add Store Order',
        component: AddStoreOrder,
        meta: {
            title: "add_order",
            role: [4, 5],
        },
    },
    {
        path: '/price-calculator',
        name: 'Price Calculator',
        component: Index,
        meta: {
            title: "price_calculator",
            role: [4],
        },
    },
    {
        path: '/add-out-of-town-order',
        name: 'Add Out of Town Order',
        component: AddOutOfTownOrder,
        meta: {
            title: "out_of_town_order",
            role: [4, 5],
        },
    },
];
