<template>
    <div class="wrapper">
        <admin-sidebar v-if="hasRole(1) || hasRole(6)" @logout="logoutUser()" />
        <default-sidebar v-else @logout="logoutUser()" />
        <div class="main-content">
            <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
            <div @click="$sidebar.displaySidebar(false)">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>
<script>
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

import { setToken } from "@/plugins/customAxios";
import DashboardNavbar from "./DashboardNavbar.vue";
import { logout } from "@/api/auth";
import mixin from "@/mixins/common";
import { loadLanguage } from "@/plugins/changeLang";
import AdminSidebar from "@/components/Sidebar/AdminSidebar";
import DefaultSidebar from "@/components/Sidebar/DefaultSidebar";

export default {
    data() {
        return {};
    },
    mixins: [mixin],
    computed: {},
    components: {
        DashboardNavbar,
        AdminSidebar,
        DefaultSidebar,
    },
    created() {
        this.check();
    },
    mounted() {
        this.initScrollbar();
    },
    methods: {
        // below method is to allow access to certain parts of application based on role
        initScrollbar() {
            let isWindows = navigator.platform.startsWith("Win");
            if (isWindows) {
                initScrollbar("sidenav");
            }
        },
        logoutUser() {
            logout({ request_from: "web" }).then(() => {
                localStorage.removeItem("token");
                this.$store.dispatch("reset");
                this.$router.push({ name: "Login" });
            });
        },
        async check() {
            const token = window.localStorage.getItem("token");
            loadLanguage(localStorage.getItem("lang") || "ar");
            if (token) {
                setToken(token);
            }
        },
    },
};
</script>
<style lang="scss">
body.g-sidenav-hidden .sidenav.navbar {
    max-width: 100px;
}
body.g-sidenav-hidden .sidenav-toggler.d-none.d-md-block {
    margin-left: 30%;
}
body.g-sidenav-hidden .ml-auto {
    margin: 0 !important;
}
body.g-sidenav-hidden .nav-link .fas {
    margin-left: 35%;
}
body.g-sidenav-hidden .nav-link img {
    margin-left: 25%;
}
.user-profile-item .nav-link-text {
    margin-left: 10%;
}
.sidenav.navbar.fixed-right .nav-item {
    direction: rtl;
}
.nav {
    background-color: #fff !important;
}
</style>
