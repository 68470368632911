import axiosInstance from "@/plugins/customAxios";

export const getVehicleModels = (params = null) => {
    return axiosInstance
        .get("v2/vehicle-models", { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getVehicleModel = (id, params = null) => {
    return axiosInstance
        .get(`v2/admin/vehicle-models/${id}`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const editVehicleModel = (id, data, params = null) => {
    return axiosInstance
        .put(`v2/admin/vehicle-models/${id}`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};
export const addVehicleModel = (data) => {
    return axiosInstance
        .post("v2/admin/vehicle-models", data)
        .then((res) => {
            return res.data;
        });
};