<template>
    <div>
        <hr />
        <div class="p-5 mb-0 card rounded-md">
            <h3>{{ $t(addOrEdit) }}</h3>

            <Form @submit="onSubmit" v-loading="isLoading" class="">
                <div class="row">
                    <div class="col-md-6">
                        <label for="name">{{ $t("type") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            :placeholder="$t('type')"
                            :label="$t('type')"
                            name="type"
                            v-model="form.type"
                        />
                        <ErrorMessage name="type" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="">{{ $t("type_ar") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            :label="$t('type_ar')"
                            class="form-control"
                            :placeholder="$t('type_ar')"
                            name="type_ar"
                            v-model="form.type_ar"
                        />
                        <ErrorMessage name="type_ar" class="error" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="">{{ $t("desc") }}</label>
                        <Field
                            name="desc"
                            class="form-control"
                            v-slot="{ field }"
                            v-model="form.desc"
                            rules="required"
                            :label="$t('desc')"
                        >
                            <textarea
                                name="desc"
                                class="form-control"
                                :placeholder="$t('desc')"
                                rows="5"
                                v-bind="field"
                                v-model="form.desc"
                            ></textarea>
                        </Field>
                        <ErrorMessage name="desc" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="">{{ $t("desc_ar") }}</label>
                        <Field
                            name="desc_ar"
                            class="form-control"
                            v-slot="{ field }"
                            v-model="form.desc_ar"
                            rules="required"
                            :label="$t('desc_ar')"
                        >
                            <textarea
                                name="desc_ar"
                                class="form-control"
                                :placeholder="$t('desc_ar')"
                                rows="5"
                                v-bind="field"
                                v-model="form.desc_ar"
                            ></textarea>
                        </Field>
                        <ErrorMessage name="desc_ar" class="error" />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="status" class="form-control-label mt-3">{{
                            $t("status")
                        }}</label>
                        <Field
                            v-slot="{ field }"
                            name="status"
                            rules="required"
                            :label="$t('status')"
                            v-model="status"
                        >
                            <el-select
                                class="d-block"
                                name="status"
                                v-bind="field"
                                :placeholder="$t('select')"
                                v-model="status"
                                filterable
                            >
                                <el-option
                                    v-for="option in defaultOptions.shipmentTypeStatus"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="$t(option.label)"
                                    :key="option.label"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage name="status" class="error" />
                    </div>
                </div>

                <div class="w-full text-center mt-5">
                    <base-button
                        native-type="submit"
                        class="bg-primary text-white"
                        :loading="submitSpinner"
                    >
                        {{ $t("submit") }}
                    </base-button>
                    <base-button
                        native-type="button"
                        class="bg-primary text-white mr-2"
                        @click="goToList"
                    >
                        {{ $t("cancel") }}
                    </base-button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import {
    addShipmentType,
    editShipmentType,
    getShipmentType,
} from "@/api/shipmentType";
import mixin from "@/mixins/common";
export default {
    data() {
        return {
            addOrEdit: "",
            shipment: {},
            form: {
                type: null,
                type_ar: null,
                desc: null,
                desc_ar: null,
            },
            status: "",
            isLoading: false,
            submitSpinner: false,
        };
    },
    props: ["id"],
    mixins: [mixin],
    components: {},
    created() {
        if (this.id === "add") {
            this.addOrEdit = this.$t("add_shipment_type");
        } else {
            this.addOrEdit = this.$t("edit_shipment_type");
            this.getShipmentType();
        }
    },

    methods: {
        goToList() {
            this.$router.push({ name: "ShipmentType List" });
        },
        getShipmentType() {
            this.isLoading = true;
            getShipmentType(this.id, {})
                .then((res) => {
                    this.isLoading = false;
                    this.shipment = res;
                    this.form = res;
                    this.status = res.status;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        async onSubmit(data) {
            this.submitSpinner = true;
            if (this.id == "add") {
                addShipmentType(data)
                    .then((res) => {
                        this.submitSpinner = false;
                        this.runToast(res.message, "success");
                        this.$router.push({ name: "ShipmentType List" });
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            } else {
                editShipmentType(this.id, data, {
                    _method: "PATCH",
                })
                    .then((res) => {
                        this.submitSpinner = false;
                        this.runToast(res.message, "success");
                        this.$router.push({ name: "ShipmentType List" });
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            }
        },
    },
};
</script>

<style></style>
