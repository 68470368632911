<template>
    <div>
        <hr />
        <div class="row">
            <div class="col-md-4">
                <div class="card px-4 py-3 rounded-md">
                    <div class="nav-item user-profile mt-3">
                        <div class="user-profile-item p-2">
                            <div class="row align-items-baseline">
                                <div class="col-8">
                                    <div>
                                        <span
                                            v-if="storeData?.name"
                                            class="nav-link-text ml-2 font-weight-bold"
                                            >{{ storeData?.name }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <!-- End of row-->
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-between">
                        <div class="my-3">
                            <div class="mb-3 px-0 d-flex align-items-center">
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/routing.svg" />
                                </span>
                                <span
                                    class="font-weight-bold ml-2"
                                    v-if="storeData.source"
                                >
                                    {{ $t(storeData?.source) }}
                                </span>
                            </div>

                            <div class="mb-3 px-0 d-flex align-items-center">
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/clock.svg" />
                                </span>
                                <custom-label
                                    :label="storeData?.user?.user_status"
                                />
                            </div>

                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="storeData?.credit"
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/credit-card.svg" />
                                </span>
                                <span
                                    class="nav-link-text ml-2 font-weight-bold"
                                    >{{
                                        storeData.credit ? storeData.credit : 0
                                    }}
                                    {{ $t("SAR") }}</span
                                >
                            </div>

                            <div class="mb-3 px-0 d-flex align-items-center">
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/phone.svg" />
                                </span>
                                <span
                                    class="font-weight-bold ml-2"
                                    v-if="storeData?.user?.mobile_no"
                                >
                                    +{{ storeData?.user?.country_code + " " }}
                                    {{ storeData?.user?.mobile_no }}</span
                                >
                            </div>

                            <div
                                class="store-detail mb-3 px-0 d-flex align-items-center"
                                v-if="storeData?.source == 'zidship_store'"
                            >
                                <span class="title font-weight-700">
                                    {{ $t("zidship_status") }}:
                                </span>
                                <custom-label
                                    class="ml-2"
                                    :label="storeData?.zidship_status"
                                />
                            </div>

                            <div
                                class="store-detail mb-3 px-0 d-flex align-items-center"
                            >
                                <span class="title font-weight-700">
                                    {{ $t("postpaid_order_enabled") }}:
                                </span>
                                <custom-label
                                    class="ml-2"
                                    :label="storeData?.postpaid_order_enabled"
                                />
                            </div>
                            <div
                                class="store-detail mb-3 px-0 d-flex align-items-center"
                            >
                                <span class="title font-weight-700">
                                    {{ $t("add_order_allowed") }}:
                                </span>
                                <custom-label
                                    class="ml-2"
                                    :label="storeData?.add_order_allowed"
                                />
                            </div>
                            <div class="mb-3 px-0 d-flex align-items-center">
                                <span class="font-weight-700">
                                    {{ $t("max_postpaid_amount") }}:
                                </span>

                                <span class="font-weight-bold ml-2">
                                    {{
                                        storeData?.max_postpaid_amount
                                            ? storeData?.max_postpaid_amount
                                            : 0
                                    }}
                                    {{ $t("SAR") }}</span
                                >
                            </div>
                            <div class="mb-3 px-0 d-flex align-items-center">
                                <span class="font-weight-700">
                                    {{ $t("vehicle_type") }}:
                                </span>
                                <div
                                    v-for="(
                                        vehicle_type, index
                                    ) in storeData?.vehicle_types"
                                    :key="vehicle_type.id"
                                >
                                    <span v-if="index != 0">,</span>
                                    <span class="font-weight-bold ml-2">
                                        {{
                                            isAr
                                                ? vehicle_type.type_ar
                                                : vehicle_type.type
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 bg-white rounded-md">
                <tabs
                    fill
                    class="flex-column flex-md-row p-3"
                    tabNavClasses="mb-3"
                >
                    <card class="px-0 -mx-2">
                        <!-- Orders -->
                        <tab-pane :title="$t('orders')" class="bg-white">
                            <el-table
                                id="ordersTable"
                                class="table-responsive table-flush"
                                header-row-class-name="thead-light"
                                :data="orders"
                                v-loading="isLoading"
                                @row-click="orderRowClicked"
                                :empty-text="$t('no_data_found')"
                            >
                                <!-- Order No -->
                                <el-table-column
                                    :label="$t('order_no')"
                                    min-width="80px"
                                >
                                    <template v-slot="{ row }">
                                        <order-number-label
                                            :row="row"
                                        ></order-number-label>
                                    </template>
                                </el-table-column>
                                <!-- Total -->
                                <el-table-column
                                    :label="$t('total')"
                                    min-width="80px"
                                    prop="email"
                                >
                                    <template v-slot="{ row }">
                                        <span
                                            class="font-weight-600 name mb-0 text-nowrap"
                                            >{{ row.total }}</span
                                        >
                                    </template>
                                </el-table-column>
                                <!-- Status  -->
                                <el-table-column
                                    :label="$t('status')"
                                    min-width="150px"
                                    prop="status"
                                >
                                    <template v-slot="{ row }">
                                        <custom-label :label="row?.status" />
                                    </template>
                                </el-table-column>
                                <!-- Order Date -->
                                <el-table-column
                                    :label="$t('order_date')"
                                    min-width="150px"
                                >
                                    <template v-slot="{ row }">
                                        <span
                                            class="font-weight-600 name mb-0"
                                            >{{ row.order_date }}</span
                                        >
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    :label="$t('actions')"
                                    min-width="120px"
                                >
                                    <template v-slot="{ row }">
                                        <div
                                            class="d-flex justify-content-between flex-wrap action-width"
                                        >
                                            <el-tooltip
                                                :content="$t('view')"
                                                placement="top"
                                            >
                                                <badge
                                                    circle
                                                    size="lg"
                                                    class="bg-sky-dark"
                                                    style="cursor: pointer"
                                                >
                                                    <router-link
                                                        target="_blank"
                                                        class="nav-link"
                                                        :to="
                                                            '/store-orders/' +
                                                            row.id
                                                        "
                                                    >
                                                        <img
                                                            src="@/assets/svg/view.svg"
                                                        />
                                                    </router-link>
                                                </badge>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div
                                class="card-footer p-0 pt-5 pb-5"
                                v-if="orders.length > 0"
                            >
                                <base-pagination
                                    :meta="ordersListMeta"
                                    v-model="ordersCurrentPage"
                                    :total="ordersTotalData"
                                ></base-pagination>
                            </div>
                        </tab-pane>
                        <!--  Notes Section  -->
                        <tab-pane :title="$t('notes')">
                            <!-- Add Notes Button -->
                            <note
                                :id="storeData?.user_id"
                                v-if="showUserNote"
                            ></note>
                        </tab-pane>
                        <!-- End of Notes Section  -->
                        <!-- Complaints Section  -->
                        <tab-pane
                            :title="$t('complaints')"
                            class="card-body-margin"
                        >
                            <complaint-list :id="id"></complaint-list>
                        </tab-pane>
                        <tab-pane
                            :title="$t('address')"
                            class="card-body-margin"
                        >
                            <store-address-list-upsert-vue
                                v-if="storeData.id"
                                :store="storeData"
                            ></store-address-list-upsert-vue>
                            <!-- <div>
                                <el-table
                                    class="table-responsive table-flush"
                                    header-row-class-name="thead-light"
                                    :data="addresses"
                                    v-loading="isLoading"
                                >
                                    <el-table-column
                                        :label="$t('name')"
                                        min-width="150px"
                                    >
                                        <template v-slot="{ row }">
                                            <p
                                                class="font-weight-600 name mb-0 text-capitalize"
                                            >
                                                {{ row.address_line1 }}
                                            </p>
                                            <p
                                                class="font-weight-600 name mb-0 text-capitalize"
                                            >
                                                {{ row.address_line2 }}
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('district')"
                                        min-width="200px"
                                    >
                                        <template v-slot="{ row }">
                                            <span
                                                class="font-weight-600 name mb-0 text-nowrap"
                                                >{{ row.district?.name }}</span
                                            >
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                        :label="$t('actions')"
                                        min-width="210px"
                                    >
                                        <template v-slot="{ row }">
                                            <div
                                                class="d-flex justify-content-between"
                                            >
                                                <span
                                                    class="icon-wrap"
                                                    @click="showMap(row)"
                                                >
                                                    <img
                                                        src="@/assets/svg/location-pin.svg"
                                                    />
                                                </span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div
                                class="card-footer p-0 pt-5 pb-5"
                                v-if="addresses.length > 0"
                            >
                                <base-pagination
                                    :meta="addressesMeta"
                                    v-model="addressesCurrentPage"
                                    :total="addressesTotalData"
                                ></base-pagination>
                            </div> -->

                            <!--  End of Table -->
                        </tab-pane>
                    </card>
                </tabs>
            </div>
        </div>

        <!-- Modal  -->
        <!-- <modal
            :show="showMapModal"
            @close="showMapModal = false"
            size="lg"
            body-classes="p-0"
        >
            <template v-slot:header>
                <div class="font-weight-900 text-lg text-center mt-2 mb-1">
                    {{ $t("address") }}
                </div>
            </template>
            <div class="px-3 d-block">
                <google-maps
                    :lat="latitude"
                    :lon="longitude"
                    v-if="showMapModal === true"
                />
            </div>

            <div class="text-center my-4">
                <input
                    type="button"
                    :value="$t('close')"
                    @click="showMapModal = false"
                    class="btn bg-primary text-white"
                />
            </div>
        </modal> -->
    </div>
</template>

<script>
import mixin from "@/mixins/common";
// import { get } from "@/api/addresses";
import { getOrders } from "@/api/orders";
import Tabs from "@/components/Tabs/Tabs";
import Note from "@/components/common/Note";
import TabPane from "@/components/Tabs/TabPane";
import GoogleMaps from "@/components/GoogleMaps";
import { getStore, getAddress } from "@/api/store";
import ComplaintList from "@/components/common/ComplaintList";
import OrderNumberLabel from "@/components/common/OrderNumberLabel.vue";
import StoreAddressListUpsertVue from "../../../components/common/StoreAddressListUpsert.vue";

export default {
    data() {
        return {
            storeData: {},
            orders: [],
            addresses: [],
            addressesMeta: {},
            addressesTotalData: null,
            // showMapModal: false,
            addressesCurrentPage: 1,
            showUserNote: false,
            ordersListMeta: {},
            ordersTotalData: null,
            ordersCurrentPage: 1,
            isLoading: true,
            latitude: null,
            longitude: null,
            isAr: localStorage.getItem("lang") == "ar",
        };
    },
    watch: {
        ordersCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getOrders();
            },
        },
        addressesCurrentPage: {
            handler: function (oldVal, newVal) {
                // this.getAddresses();
            },
        },
    },
    components: {
        Tabs,
        Note,
        TabPane,
        GoogleMaps,
        ComplaintList,
        OrderNumberLabel,
        StoreAddressListUpsertVue,
    },
    created() {
        this.getStoreDetails();
        this.getOrders();
    },
    props: ["id"],
    mixins: [mixin],
    methods: {
        // showMap(addressData) {
        //     this.latitude = addressData.latitude;
        //     this.longitude = addressData.longitude;
        //     this.showMapModal = true;
        // },
        getStoreDetails() {
            getStore(this.id, {
                include: "user,vehicle_types",
            })
                .then((res) => {
                    this.storeData = res;
                    this.showUserNote = true;
                    this.$forceUpdate();
                    // this.getAddresses();
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getOrders() {
            getOrders({
                filter: {
                    store_id: this.id,
                },

                page: this.ordersCurrentPage,
            })
                .then((res) => {
                    this.isLoading = false;
                    this.orders = res.data;
                    this.ordersTotalData = res.meta.total;
                    this.ordersListMeta = res.meta;
                })
                .catch(({ response }) => {
                    this.isLoading = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getAddresses() {
            this.isLoading = true;
            getAddress({
                page: this.addressesCurrentPage,
                include: "district",
                store_id: this.id,
                filter: {
                    user_id: this.storeData.user_id,
                },
            })
                .then((res) => {
                    this.addresses = res.data;
                    this.addressesTotalData = res.meta.total;
                    this.addressesMeta = res.meta;
                    this.isLoading = false;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        orderRowClicked(row, column, event) {
            if (column.label != this.$t("actions")) {
                this.$router.push({
                    name: "Store Order Detail",
                    params: { id: row.id },
                });
            }
            return;
        },
    },
};
</script>
<style scoped lang="scss">
.user-profile {
    border: 2px;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #f6f9fc;
}
.user-profile-item {
    margin-top: 2px;
}
.avatar-sm {
    width: 50px !important;
    height: 50px !important;
}
.card-body-margin {
    margin: -50px !important;
}
</style>
