<template>
    <div>
        <hr />
        <div class="card mb-0 p-5 rounded-md">
            <div
                class="border-0 card-header px-0 d-flex justify-content-between align-items-center"
            >
                <router-link
                    :to="{
                        name: 'item',
                        params: { id: 'add' },
                    }"
                >
                    <button class="text-white btn btn-default btn-sm">
                        <i class="mr-2 text-white fas fa-plus-circle fa-lg">
                            <span>{{ $t("add_item") }}</span>
                        </i>
                    </button>
                </router-link>
            </div>

            <div class="d-flex mb-4 pt-2">
                <search-filter
                    :filters="[
                        {
                            id: 'name',
                        },
                    ]"
                    @search="(searchObject) => (filters = searchObject)"
                />
                <drop-down-filter
                    class="ml-3 mr-3"
                    :dropDownFilters="[
                        {
                            id: 'status',
                            label: $t('status'),
                            options: defaultOptions.status,
                        },
                        {
                            id: 'shipment_type_id',
                            label: $t('shipment_type'),
                            options: shipmentTypeOptions,
                        },
                    ]"
                    @dropDownOptionSelected="
                        (optionsObject) =>
                            (filters = { ...filters, ...optionsObject })
                    "
                />
            </div>
            <!-- End of Filter Div -->
            <div class="row">
                <main class="col-md-12 col-xs-11 p-l-2 p-t-2">
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="items"
                            v-loading="isLoading"
                            highlight-current-row
                            @row-click="rowClicked"
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('name')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.name }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('name_ar')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.name_ar }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('assembly_charge')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.assembly_charge }}
                                        {{ $t("SAR") }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('shipment_type')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{
                                            lang == "ar"
                                                ? row.shipment_type.type_ar
                                                : row.shipment_type.type
                                        }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('status')"
                                min-width="150px"
                                prop="status"
                            >
                                <template v-slot="{ row }">
                                    <custom-label :label="row.status" />
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('actions')"
                                min-width="210px"
                            >
                                <template v-slot="{ row }">
                                    <div
                                        class="d-flex align-items-center flex-wrap action-width"
                                    >
                                        <el-tooltip
                                            :content="$t('edit')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                :title="$t('edit')"
                                                style="cursor: pointer"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="'/items/' + row.id"
                                                >
                                                    <img
                                                        src="@/assets/svg/edit-2.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>
                                        <alert-modal
                                            :id="row.id"
                                            :title="$t('item')"
                                            alertCategory="item"
                                            :body="
                                                $t('item_delete_confirmation')
                                            "
                                            @alertModalCompleted="getItems"
                                        ></alert-modal>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- End of Table--->
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="items.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="itemsCurrentPage"
                            :total="itemsTotalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from "@/api/item";
import mixin from "@/mixins/common";
import { getShipmentTypes } from "@/api/shipmentType";
import AlertModal from "@/components/common/AlertModal.vue";
export default {
    data() {
        return {
            isLoading: false,
            showButtonLoader: false,
            items: [],
            meta: {},
            itemsCurrentPage: 1,
            filters: {
                name: "",
                shipment_type_id: null,
            },
            itemsTotalData: null,
            shipmentTypeOptions: [],
        };
    },
    async created() {
        this.getShipmentTypeOptions();
        this.getItems();
    },
    watch: {
        itemsCurrentPage: {
            handler: function () {
                this.getItems();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.itemsCurrentPage = 1;
                this.getItems();
            },
        },
    },
    mixins: [mixin],
    components: {
        AlertModal,
    },
    methods: {
        // editItem(id) {
        //     this.$router.push({ name: "item", params: { id } });
        // },
        getItems() {
            this.isLoading = true;
            get({
                page: this.itemsCurrentPage,
                filter: {
                    ...this.filters,
                },
                include: "shipmentType",
            })
                .then((res) => {
                    this.isLoading = false;
                    this.items = res.data;
                    this.itemsTotalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        getShipmentTypeOptions() {
            getShipmentTypes({
                limit: -1,
                filter: {
                    status: "active",
                },
            })
                .then((res) => {
                    res.data.forEach((item) => {
                        this.shipmentTypeOptions.push({
                            value: item["id"],
                            label:
                                this.$store.getters.getLang == "ar"
                                    ? item["type_ar"]
                                    : item["type"],
                        });
                    });
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                name: "",
                shipment_type_id: null,
            };
        },
        rowClicked(row, column, event) {
            if(column.label != this.$t("actions")){
                this.$router.push({
                    name: "item",
                    params: { id: row.id },
                });
            }
            return;
        },
    },
};
</script>

<style></style>
