<template>
    <div>
        <hr />

        <div class="row">
            <div class="col-md-3 text-center space-y-2">
                <div>
                    <badge type="primary" class="currentCreditBadge">
                        <img src="@/assets/svg/empty-wallet.svg" />
                        <h5>{{ $t("current_credit") }}</h5>
                        <h1>{{ storeCredit() }}</h1>
                        <span class="h1 text-black">{{ $t("SAR") }}</span>
                    </badge>
                </div>
                <div>
                    <badge type="primary" class="pendingCreditBadge">
                        <img src="@/assets/svg/empty-wallet-time.svg" />
                        <h5>{{ $t("pending_credit") }}</h5>
                        <h1>{{ storePendingCredit() }}</h1>
                        <span class="h1 text-black">{{ $t("SAR") }}</span>
                    </badge>
                </div>
            </div>
            <div class="col-md-9">
                <div class="card rounded-md store-credit-card">
                    <Form
                        @submit="submitPaymentOption"
                        ref="paymentRequestForm"
                    >
                        <label for="" class="mt-4 font-weight-600"
                            >{{ $t("number_of_credit_you_want_to_buy") }}
                        </label>
                        <Field
                            class="form-control"
                            v-model="credit"
                            :placeholder="storeCredit() < 0 ? '' : $t('credit')"
                            rules="required|min_value:99"
                            :label="$t('credit')"
                            name="credit"
                            type="number"
                        />
                        <ErrorMessage name="credit" class="error" />

                        <div>
                            <label class="form-control-label d-block mt-2">
                                {{ $t("payment_method") }}
                            </label>
                            <Field
                                rules="required"
                                v-model="paymentOption"
                                name="payment_option"
                                :label="$t('payment_method')"
                            >
                                <el-radio
                                    v-model="paymentOption"
                                    name="payment_option"
                                    class="w-100 mb-2"
                                    label="bank_transaction"
                                    border
                                    >{{ $t("bank_transaction") }}</el-radio
                                >
                                <el-radio
                                    v-model="paymentOption"
                                    name="payment_option"
                                    class="w-100 mb-2"
                                    label="mada"
                                    border
                                >
                                    {{ $t("mada") }}</el-radio
                                >
                            </Field>
                            <ErrorMessage name="payment_option" class="error" />
                        </div>
                        <div class="mt-3 form-btn">
                            <input
                                type="submit"
                                :value="$t('proceed')"
                                class="btn bg-primary text-white mb-2"
                            />
                        </div>
                    </Form>
                </div>
            </div>
        </div>

        <!-- Modal props show is for toggling Modal Display 
            @close child event is for closing modal -->
        <!-- Modal  -->
        <modal
            :show="showCreditCardTransactionModal"
            @close="showCreditCardTransactionModal = false"
            size="md"
            body-classes="p-0"
        >
            <template v-slot:header>
                <div>
                    <img src="@/assets/images/credit-card.png" alt="" />
                    <div class="text-bold text-lg text-center mt-3 mb-1">
                        {{ $t("total") }}
                        <span class="font-weight-700">
                            {{ creditAmount }} {{ $t("SAR") }}
                        </span>
                    </div>
                </div>
            </template>
            <div class="px-3 d-block">
                <Form @submit="payToMada">
                    <Field rules="required" :label="$t('name')" name="name">
                        <base-input
                            inputClasses="mb-2"
                            name="name"
                            type="text"
                            labelClasses="mt-4 font-weight-600"
                            :placeholder="$t('name')"
                        >
                        </base-input>
                    </Field>
                    <ErrorMessage name="name" class="error" />

                    <Field
                        rules="required"
                        :label="$t('card_number')"
                        name="number"
                    >
                        <base-input
                            inputClasses="mb-2"
                            name="number"
                            type="number"
                            labelClasses="mt-4 font-weight-600"
                            :placeholder="$t('card_number')"
                        >
                        </base-input>
                    </Field>
                    <ErrorMessage name="number" class="error" />
                    <div class="row">
                        <div class="col">
                            <Field
                                rules="required|length:2"
                                :label="$t('expiry_month')"
                                name="month"
                            >
                                <base-input
                                    inputClasses="mb-2"
                                    name="month"
                                    type="number"
                                    :placeholder="$t('expiry_month')"
                                >
                                </base-input>
                            </Field>
                            <ErrorMessage name="month" class="error" />
                        </div>

                        <div class="col">
                            <Field
                                rules="required|length:4"
                                :label="$t('expiry_year')"
                                name="year"
                            >
                                <base-input
                                    inputClasses="mb-2"
                                    name="year"
                                    type="number"
                                    :placeholder="$t('expiry_year')"
                                >
                                </base-input>
                            </Field>
                            <ErrorMessage name="year" class="error" />
                        </div>
                    </div>

                    <Field
                        rules="required|min:3|max:4"
                        :label="$t('cvv')"
                        name="cvc"
                    >
                        <base-input
                            inputClasses="mb-2"
                            name="cvc"
                            type="number"
                            :placeholder="$t('cvv')"
                        >
                        </base-input>
                    </Field>
                    <ErrorMessage name="cvc" class="error" />
                    <div class="mt-3 text-center">
                        <base-button
                            native-type="submit"
                            class="btn bg-primary text-white mb-2"
                            :loading="creditCardTransactionSubmitSpinner"
                        >
                            {{ $t("send") }}
                        </base-button>
                        <input
                            type="button"
                            :value="$t('cancel')"
                            @click="showCreditCardTransactionModal = false"
                            class="btn bg-primary text-white mb-2"
                        />
                    </div>
                </Form>
            </div>
        </modal>
        <!-- End of Modal  -->

        <!-- Modal props show is for toggling Modal Display 
            @close child event is for closing modal -->
        <!-- Modal  -->
        <modal
            :show="showBankTransactionModal"
            @close="showBankTransactionModal = false"
            size="md"
            body-classes="p-0"
        >
            <template v-slot:header>
                <div>
                    <img
                        :src="systemBankLogo"
                        alt=""
                        v-if="systemConfigs"
                        width="250"
                    />
                    <div class="text-bold text-lg text-center mt-3 mb-1">
                        {{ $t("total") }}
                        <span class="font-weight-700">
                            {{ creditAmount }} {{ $t("SAR") }}
                        </span>
                    </div>
                </div>
            </template>
            <div class="bank-transfer" v-if="systemConfigs">
                <h3>Bank transfer</h3>
                <div class="bank-transfer__detail">
                    <p>
                        Bank name:
                        <span class="font-weight-700"
                            >{{ systemBankName }}
                        </span>
                    </p>
                    <p>
                        Account holder name:
                        <span class="font-weight-700">{{
                            systemAccountName
                        }}</span>
                    </p>
                    <p>
                        IBAN Number:
                        <span class="font-weight-700"
                            >{{ systemBankNumber }}
                        </span>
                    </p>
                </div>
            </div>
            <div class="px-3 d-block">
                <Form @submit="paymentRequestToBank">
                    <label for="name">{{ $t("bank_name") }}</label>
                    <Field
                        type="text"
                        rules="required"
                        class="form-control"
                        :label="$t('bank_name')"
                        name="bank_name"
                        v-model="bank_name"
                        @change="handleBankName"
                    />
                    <ErrorMessage name="bank_name" class="error" />

                    <label for="name">{{
                        $t("settings_page.bank_account_name")
                    }}</label>
                    <Field
                        type="text"
                        rules="required"
                        class="form-control"
                        name="bank_account_name"
                        :v-model="bank_account_name"
                        :label="$t('settings_page.bank_account_name')"
                        @change="handleBankAccountName"
                    />
                    <ErrorMessage name="bank_account_name" class="error" />

                    <div class="mt-3 text-center">
                        <base-button
                            native-type="submit"
                            class="btn bg-primary text-white mb-2"
                            :loading="bankTransactionSubmitSpinner"
                        >
                            {{ $t("send") }}
                        </base-button>
                        <input
                            type="button"
                            :value="$t('cancel')"
                            @click="showBankTransactionModal = false"
                            class="btn bg-primary text-white mb-2"
                        />
                    </div>
                </Form>
            </div>
        </modal>
        <!-- End of Modal  -->

        <!-- End of card --->
    </div>
</template>

<script>
import { getSettings, getSystem } from "@/api/systemSetting";
import { madaTransaction, bankTransaction } from "@/api/credit";
import mixin from "@/mixins/common";
import { me } from "@/api/users";

export default {
    data() {
        return {
            currentCredit: this.$store.getters.user.store?.credit || 0,
            credit: '',
            bankTransactionSubmitSpinner: false,
            creditCardTransactionSubmitSpinner: false,
            paymentOption: "",
            creditAmount: null,
            showCreditCardTransactionModal: false,
            showBankTransactionModal: false,
            systemConfigs: null,
            systemBankLogo: false,
            systemBankName: false,
            systemAccountName: false,
            systemBankNumber: false,
            bank_account_name: "",
            bank_name: "",
        };
    },
    mixins: [mixin],
    created() {
        this.me();
        this.getSettings();
        this.initializeCredit();
    },
    methods: {
        me() {
            me({ request_from: "web" }).then((res) => {
                this.$store.dispatch("setUser", res.user); // setting user data in vuex store
                this.$store.dispatch(
                    "setStoreCredit",
                    res.user?.stores[0]?.credit
                        ? res.user?.stores[0]?.credit
                        : 0
                );
            });
            // Update the alert bar credit
            this.creditForPlaceOrder(this.storeId);
        },
        initializeCredit() {
            if (this.storeCredit() < 0) {
                this.credit = this.storeCredit() * -1;
            }
        },
        handleBankName(event) {
            this.bank_name = event.target.value;
        },
        handleBankAccountName(event) {
            this.bank_account_name = event.target.value;
        },
        getSettings() {
            getSettings({ limit: -1 })
                .then((res) => {
                    this.systemConfigs = res.data;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getSettingByKey(key) {
            let setting = this.systemConfigs.filter((config) => {
                return config.key == key;
            });
            return setting[0].value;
        },
        submitPaymentOption(data) {
            this.creditAmount = data.credit;
            this.paymentOption = data.payment_option;
            if (data.payment_option === "bank_transaction") {
                this.systemBankLogo = this.getSettingByKey("bank_logo");
                this.systemBankName = this.getSettingByKey("bank_name");
                this.systemAccountName = this.getSettingByKey(
                    "bank_account_name"
                );
                this.systemBankNumber = this.getSettingByKey(
                    "bank_account_number"
                );
                this.showBankTransactionModal = true;
            }

            if (data.payment_option === "mada") {
                this.showCreditCardTransactionModal = true;
            }
        },
        async paymentRequestToBank(data) {
            this.bankTransactionSubmitSpinner = true;
            let bankTransactionData = {};
            bankTransactionData.credit = this.creditAmount;
            bankTransactionData.bank_name = this.bank_name;
            bankTransactionData.bank_account_name = this.bank_account_name;
            await bankTransaction(bankTransactionData, {
                store_id: this.storeId,
            })
                .then((res) => {
                    this.bankTransactionSubmitSpinner = false;
                    this.showBankTransactionModal = false;
                    this.runToast(res.message, "success");
                    this.me();
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                    this.bankTransactionSubmitSpinner = false;
                    this.showBankTransactionModal = false;
                });
        },
        payToMada(data) {
            this.creditCardTransactionSubmitSpinner = true;
            let object = {};
            object["source"] = data;
            object.source["type"] = "creditcard";
            object["amount"] = this.creditAmount * 100;
            object["currency"] = "SAR";
            object["callback_url"] =
                process.env.VUE_APP_MOYASAR_PAYMENT_CALLBACK_URL;
            object["publishable_api_key"] =
                process.env.VUE_APP_MOYASAR_PUBLISHABLE_API_KEY;
            madaTransaction(object)
                .then((res) => {
                    this.creditCardTransactionSubmitSpinner = false;
                    this.showCreditCardTransactionModal = false;
                    window.location.href = res.source.transaction_url;
                })
                .catch(({ response }) => {
                    this.creditCardTransactionSubmitSpinner = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
    },
};
</script>

<style scoped>
.badge.currentCreditBadge {
    margin-bottom: 5px;
    background-color: #fef8ed;
    padding: 45px;
    border-radius: 20px;
    width: 100% !important;
}

.badge h5 {
    color: #a7adb7;
}

.badge h1 {
    color: black;
}

.badge.pendingCreditBadge {
    margin-bottom: 5px;
    background-color: #edecfc;
    padding: 45px;
    border-radius: 20px;
    width: 100% !important;
}

.form-group {
    margin-bottom: 2px !important;
}

.el-radio.is-bordered + .el-radio.is-bordered {
    margin-left: 0;
}
</style>
