<template>
    <div class="d-inline">
        <el-tooltip :content="$t('transfer_credit')" placement="top">
            <badge
                circle
                size="lg"
                class="bg-sky-dark text-secondary cursor-pointer"
                style="cursor: pointer"
                @click="optionModel()"
            >
                <i class="fas fa-sync mx-1"></i>
            </badge>
        </el-tooltip>
        <modal
            footerClasses="justify-content-center"
            :show="showOptionModal"
            @close="showOptionModal = false"
            size="sm"
            body-classes="p-0"
            v-loading="isLoading"
        >
            <template v-slot:header>
                <div
                    class="text-bold text-lg text-left border-b mt-2 mb-1"
                    style="text-align: left"
                >
                    {{ $t("creditPaymentOption") }}
                </div>
            </template>

            <!-- Credit option model -->
            <div class="px-3 d-block">
                <div>
                    <Form @submit="alertModal">
                        <label class="form-control-label d-block mt-2">
                            {{ $t("payment_method") }}
                        </label>
                        <Field
                            rules="required"
                            v-model="paymentOption"
                            name="payment_option"
                            :label="$t('payment_method')"
                        >
                            <el-radio
                                v-model="paymentOption"
                                name="payment_option"
                                class="w-100 mb-2"
                                label="online"
                                border
                                >{{ $t("transferCreditOnline") }}</el-radio
                            >
                            <el-radio
                                v-model="paymentOption"
                                name="payment_option"
                                class="w-100 mb-2 ml-0"
                                label="offline"
                                border
                            >
                                {{ $t("transferCreditOffline") }}</el-radio
                            >
                        </Field>
                        <ErrorMessage name="payment_option" class="error" />
                        <div class="w-full text-center mt-3">
                            <base-button
                                class="submit option-submit-btn"
                                native-type="submit"
                            >
                                {{ $t("confirm") }}</base-button
                            >

                            <base-button
                                @click.native="showOptionModal = false"
                            >
                                {{ $t("cancel") }}</base-button
                            >
                        </div>
                    </Form>
                </div>
            </div>
        </modal>

        <!-- Confirmation model -->
        <modal
            footerClasses="justify-content-center"
            :show="showModal"
            @close="showModal = false"
            size="sm"
            body-classes="p-0"
            v-loading="isLoading"
        >
            <template v-slot:header>
                <div
                    class="text-bold text-lg text-left border-b mt-2 mb-1"
                    style="text-align: left"
                >
                    {{ title }}
                </div>
            </template>

            <div class="px-3 d-block text-center">
                <h2 class="text-break credit-transfer-title font-weight-900">
                    {{
                        $t("providerCreditTransferAlert", {
                            amount: currentRow.order_credit,
                            name: currentRow.name,
                        })
                    }}
                </h2>
            </div>

            <template v-slot:footer>
                <div class="w-full text-center mt-3">
                    <base-button
                        @click.native="creditTransferData"
                        :loading="submitSpinner"
                    >
                        {{ $t("yes") }}</base-button
                    >

                    <base-button @click="optionModel()">
                        {{ $t("no") }}</base-button
                    >
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import { creditTransfer } from "@/api/users";
import mixin from "@/mixins/common";

export default {
    data() {
        return {
            showModal: false,
            isLoading: true,
            submitSpinner: false,
            showOptionModal: false,
            paymentOption: null,
        };
    },

    props: {
        title: {
            type: String,
        },
        body: {
            type: String,
            default: "Alert Body",
        },
        id: {
            type: [String, Number],
        },
        currentRow: {
            type: Object,
            default: () => {},
        },
    },

    mixins: [mixin],

    methods: {
        optionModel() {
            this.showOptionModal = true;
            this.showModal = false;
            this.isLoading = false;
        },
        alertModal(data) {
            this.showOptionModal = false;
            this.showModal = true;
            this.isLoading = false;
        },

        creditTransferData() {
            this.submitSpinner = true;
            creditTransfer(this.id, { payment_method: this.paymentOption })
                .then((res) => {
                    this.submitSpinner = false;
                    this.isLoading = false;
                    this.showModal = false;
                    this.$emit(
                        "creditTransferModalCompleted",
                        this.currentRow.credit,
                        this.currentRow.name
                    );
                    this.runToast(res.message, "success");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    this.apiError(response);
                });
        },
    },
};
</script>

<style lang="scss">
button.btn.base-button.btn-default.option-submit-btn {
    margin-left: 0px !important;
}
</style>
