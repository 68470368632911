<template>
    <div
        class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
        @mouseenter="$sidebar.onMouseEnter()"
        @mouseleave="$sidebar.onMouseLeave()"
    >
        <div class="scrollbar-inner" ref="sidebarScrollArea">
            <div class="sidenav-header d-flex align-items-center">
                <router-link class="navbar-brand" :to="imgLink">
                    <img
                        src="@/assets/images/brand/logo.png"
                        class="navbar-brand-img"
                        style="max-height: 100% !important; width: 80px"
                        alt="Sidebar logo"
                    />
                </router-link>
                <div class="ml-auto sidebar">
                    <!-- Sidenav toggler -->
                    <div
                        class="sidenav-toggler d-none d-md-block"
                        :class="{ active: !$sidebar.isMinimized }"
                        @click="minimizeSidebar"
                    >
                        <div class="sidenav-toggler-inner">
                            <i class="sidenav-toggler-line"></i>
                            <i class="sidenav-toggler-line"></i>
                            <i class="sidenav-toggler-line"></i>
                        </div>
                    </div>
                </div>
            </div>
            <slot></slot>
            <div class="navbar-inner">
                <ul class="navbar-nav">
                    <li class="nav-item user-profile" aria-current="page">
                        <div class="user-profile-item">
                            <img
                                src="@/assets/images/icons/common/user.png"
                                class="avatar-sm rounded-circle"
                                alt="Profile"
                            />
                            <span class="nav-link-text font-weight-bold">
                                {{ userName }}
                            </span>
                        </div>
                    </li>

                    <slot name="links">
                        <sidebar-item
                            v-for="(link, index) in sidebarLinks"
                            :key="link.name + index"
                            :link="link"
                        >
                            <sidebar-item
                                v-for="(subLink, index) in link.children"
                                :key="subLink.name + index"
                                :link="subLink"
                            >
                                {{ subLink }}
                            </sidebar-item>
                        </sidebar-item>
                    </slot>
                </ul>
                <slot name="links-after"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "sidebar",
    props: {
        userName: {
            type: String,
            default: "Admin",
            description: "Sidebar User Name",
        },
        title: {
            type: String,
            default: "Creative Tim",
            description: "Sidebar title",
        },
        shortTitle: {
            type: String,
            default: "CT",
            description: "Sidebar short title",
        },
        logo: {
            type: String,
            default:
                "https://demos.creative-tim.com/vue-argon-dashboard-pro/img/brand/green.png",
            description: "Sidebar app logo",
        },
        imgLink: {
            type: String,
            default: "/dashboard",
            description: "Sidebar Logo Link",
        },
        sidebarLinks: {
            type: Array,
            default: () => [],
            description:
                "List of sidebar links as an array if you don't want to use components for these.",
        },
        autoClose: {
            type: Boolean,
            default: true,
            description:
                "Whether sidebar should autoclose on mobile when clicking an item",
        },
    },
    provide() {
        return {
            autoClose: this.autoClose,
        };
    },
    methods: {
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
    },
    mounted() {
        this.$sidebar.isMinimized =
            this.$sidebar.breakpoint < window.innerWidth;
        this.minimizeSidebar();
    },
    beforeUnmount() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    },
};
</script>
<style scoped>
.user-profile {
    border: 2px;
    border-radius: 5px;
    height: 55px;
    margin: 10px 26px;
    background-color: var(--gray-lighter);
}
.user-profile-item {
    display: flex;
    align-items: center;
}
.avatar-sm {
    width: 50px !important;
    height: 50px !important;
}
</style>
