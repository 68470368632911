import List from "@/views/Pages/Customers/List";
import View from "@/views/Pages/Customers/View";

export default [{
        path: "customers",
        components: { default: List },
        name: "Customers List",
        meta: {
            title: "customers",
            role: [1, 6],
        },
    },
    {
        path: "customers/:id",
        components: { default: View },
        props: true,
        name: "Customer Details",
        meta: {
            title: "customer_details",
            role: [1, 6],
        },
    },
];