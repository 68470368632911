import List from "@/views/Pages/BroadcastNotification/List";

export default [
    {
        path: "broadcast-notifications",
        components: { default: List },
        name: "BroadcastNotification List",
        meta: {
            title: "broadcast_notification",
            role: [1, 6],
        },
    },
];
