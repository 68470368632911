<template>
    <div>
        <hr />
        <div class="card mb-0 p-5 rounded-md">
            <div class="d-flex mb-4 pt-2">
                <search-filter
                    :filters="[
                        {
                            id: 'order_no',
                        },
                    ]"
                    @search="(searchObject) => (filters = searchObject)"
                />
            </div>
            <!-- End of Filter Div -->
            <div class="row">
                <main class="col-md-12 col-xs-11 p-l-2 p-t-2">
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="orderRejects"
                            v-loading="isLoading"
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('order_no')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <order-number-label
                                        :row="row?.order"
                                    ></order-number-label>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('providerRejectOrderCount')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.reject_provider_count }}</span
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('order_city')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{
                                            row.order?.pickup_address?.city
                                        }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('rejectedAt')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.display_created_at }}</span
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('order_date')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.order?.order_date }}</span
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('actions')"
                                min-width="250px"
                            >
                                <template v-slot="{ row }">
                                    <div class="d-flex">
                                        <el-tooltip
                                            :content="$t('edit')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="cursor-pointer bg-sky-dark text-secondary"
                                                :title="$t('edit')"
                                                style="cursor: pointer"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="
                                                        '/orders/reject/' +
                                                        row.order_id
                                                    "
                                                >
                                                    <img
                                                        src="@/assets/svg/view.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- End of Table--->
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="orderRejects.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="CurrentPage"
                            :total="TotalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getOrderRejects } from "@/api/orders";
import OrderNumberLabel from "@/components/common/OrderNumberLabel.vue";

export default {
    data() {
        return {
            isLoading: false,
            showButtonLoader: false,
            orderRejects: [],
            meta: {},
            CurrentPage: 1,
            filters: {
                order_no: "",
            },
            TotalData: null,
        };
    },
    async created() {
        this.getOrderRejects();
    },
    watch: {
        CurrentPage: {
            handler: function () {
                this.getOrderRejects();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.CurrentPage = 1;
                this.getOrderRejects();
            },
        },
    },
    mixins: [mixin],
    components: {
        OrderNumberLabel,
    },
    methods: {
        getOrderRejects() {
            this.isLoading = true;
            getOrderRejects({
                page: this.CurrentPage,
                filter: {
                    ...this.filters,
                },
                include: "order",
            })
                .then((res) => {
                    this.isLoading = false;
                    this.orderRejects = res.data;
                    this.TotalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                order_no: "",
                provider_name: "",
            };
        },
    },
};
</script>

<style></style>
