<template>
    <div class="login items-center justify-center">
        <!-- Page content -->
        <div class="row">
            <div class="col-lg-6">
                <div class="login__content">
                    <img src="@/assets/images/login-img.svg" alt="Brand Logo" />
                    <div class="login__content--text">
                        <h3 class="font-bold text-xl uppercase text-white">
                            {{ $t("login_text_1") }}
                        </h3>
                        <p>
                            {{ $t("login_text_2") }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="login__img">
                    <!-- Card Body --->
                    <div
                        class="login-form"
                        style="flex: 1 1 auto;max-width:500px;  min-height: 1px; padding: 1.5rem; p-0"
                    >
                        <div class="text-muted login-logo mb-4">
                            <img
                                src="@/assets/images/brand/logo.png"
                                alt="Brand Logo"
                            />
                        </div>
                        <div class="">
                            <h1 class="login-title">
                                {{ $t("login_text_3") }}
                            </h1>
                            <p class="" style="color: #737373">
                                {{ $t("login_text_4") }}
                            </p>
                        </div>
                        <!-- Start of Login Form -->
                        <Form @submit="onSubmit">
                            <!-- Username / Mobile No Field  -->

                            <Field name="mobile_no" rules="required">
                                <base-input
                                    inputGroupClasses="form-wrap-bordered p-1"
                                    inputClasses="form-login-input"
                                    alternative
                                    name="mobile_no"
                                    addon-left-icon="ni ni-email-83"
                                    :placeholder="
                                        $t('username') + ' / ' + $t('mobile_no')
                                    "
                                >
                                </base-input>
                            </Field>

                            <ErrorMessage name="mobile_no" class="error mb-4" />
                            <!-- Password Field  -->
                            <Field name="password" rules="required">
                                <base-input
                                    alternative
                                    inputGroupClasses="form-wrap-bordered p-1"
                                    inputClasses="form-login-input"
                                    name="password"
                                    addon-left-icon="ni ni-lock-circle-open"
                                    type="password"
                                    :placeholder="$t('password')"
                                >
                                </base-input>
                            </Field>
                            <ErrorMessage name="password" class="error mb-4" />
                            <div class="text-rightm">
                                <router-link :to="{ name: 'Login With SMS' }">{{
                                    $t("login_with_sms")
                                }}</router-link>
                            </div>
                            <div class="text-center mt-4">
                                <!-- Submit -->
                                <base-button
                                    native-type="submit"
                                    class="my-1 py-3 mx-0 login-btn w-full spinner-login-btn"
                                    :loading="buttonSpinner"
                                >
                                    {{ $t("login") }}
                                </base-button>
                            </div>
                        </Form>
                        <!-- ENd of Login Form  -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { login } from "@/api/auth";
import mixin from "@/mixins/common";
import { saveUserDevice } from "@/api/users";
import { setToken } from "@/plugins/customAxios";
import { firebaseMessaging } from "@/firebase.js";
import { getMessaging, getToken } from "firebase/messaging";

export default {
    components: {},
    data() {
        return {
            buttonSpinner: false, // buttonSpinner is for showing loader animation while loading data
        };
    },
    mixins: [mixin],
    created() {},
    methods: {
        async onSubmit(values) {
            this.buttonSpinner = true;
            values["request_from"] = "web"; // additional parameter to api required
            values["role"] = "admin";
            values["type"] = 1;
            values["country_code"] = 966;
            login(values)
                .then((res) => {
                    this.buttonSpinner = false;
                    const token = res.token;
                    setToken(token);
                    this.$store.dispatch("setUser", res.user); // setting user data in vuex store

                    if (this.hasRole(4)) {
                        this.$store.dispatch(
                            "setStoreCredit",
                            res.user?.stores[0]?.credit
                                ? res.user?.stores[0]?.credit
                                : 0
                        );
                    }

                    if (this.hasRole(9)) {
                        this.$store.dispatch(
                            "setStoreCredit",
                            res.user?.credit ? res.user?.credit : 0
                        );
                    }
                    localStorage.setItem("token", token);
                    localStorage.setItem("lang", res.user.lang);
                    this.requestFirebaseMessagingToken();
                    this.$router.push({ name: "Dashboard" });
                })
                .catch(({ response }) => {
                    this.buttonSpinner = false;
                    // below apiError method is a common mixin method imported from mixin file which takes response error and notifies user using vue toastification
                    this.apiError(response);
                });
        },
        requestFirebaseMessagingToken() {
            try {
                const messaging = getMessaging();
                getToken(firebaseMessaging, {
                    vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
                })
                    .then((currentToken) => {
                        if (currentToken) {
                            this.saveFcmToken(currentToken);
                        } else {
                            console.log(
                                "No registration token available. Request permission to generate one."
                            );
                            firebaseMessaging.requestPermission().then(() => {
                                return getToken(firebaseMessaging, {
                                    vapidKey:
                                        process.env.VUE_APP_FIREBASE_VAPID_KEY,
                                })
                                    .then((token) => {
                                        this.saveFcmToken(token);
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(
                            "An error occurred while retrieving token. ",
                            err
                        );
                    });
            } catch (err) {
                console.log(err);
            }
        },
        saveFcmToken(token) {
            var data = {
                device_type: "web",
                device_id: token,
                token: token,
                version: "1",
            };
            saveUserDevice(data)
                .then((res) => {
                    this.$store.dispatch("setFirebaseToken", token);
                })
                .catch(({ response }) => {
                    // below apiError method is a common mixin method imported from mixin file which takes response error and notifies user using vue toastification
                    this.apiError(response);
                });
        },
    },
};
</script>
<style lang="scss">
body.bg-default {
    background-color: #7368fb !important;
}
.form-wrap-bordered {
    border: 1px solid rgba(234, 227, 227, 1) !important;
    border-radius: 10px !important;

    .form-login-input {
        padding: 10px 0;
    }
}
.login {
    .row {
        margin: 0;
        .col-lg-6 {
            &:last-child {
                height: 100vh;
                padding: 0;
            }
        }
    }
}
.login__img {
    background: #fff;
    margin-bottom: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 80px;
}

.login .btn:not(:last-child) {
    margin-right: 0;
}
.login-title {
    font-weight: 700;
    font-size: 40px;
    color: rgba(35, 31, 32, 1);
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
}
.rtl .login .input-group-prepend {
    margin-right: 1px;
}

.login__content {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.login__content--text {
    color: #fff;
    margin-top: 40px;
    h3 {
        text-transform: uppercase;
    }
}

.login-btn {
    width: 100% !important;
    border-radius: 10px !important;
}
.login-logo {
    img {
        max-width: 100px;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.login .form-group {
    margin-bottom: 1rem;
}
.login .form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef00;
    opacity: 1;
}
.login.row {
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.rtl {
    .spinner-login-btn {
        margin: 0 8px;
    }
    .login {
        .card-body {
            text-align: right;
        }
    }
}

.rtl .login-form,
.rtl .login__content {
    text-align: right;
}

@media only screen and (max-width: 991px) {
    .login.row {
        height: auto;
    }

    .login__content img {
        width: 100%;
    }

    .login.row {
        flex-direction: column;
    }
    .login__img {
        padding: 20px;
        height: 100%;
    }
    .login-form {
        padding: 0px !important;
    }
}
</style>
