<template>
    <div id="jq__map" class="map-canvas mt-3" style="height: 450px"></div>
</template>

<script>
import db from "@/firebase.js";
import { Loader } from "@googlemaps/js-api-loader";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
const loader = new Loader({
    apiKey: "AIzaSyCl4Y0sPXxSSj-DZ2PJa20-5fmOLaDn7HI",
});

export default {
    props: {
        lat: {
            type: Number,
            required: true,
            default: 24.774265,
        },
        lon: {
            type: Number,
            required: true,
            default: 46.738586,
        },
        locations: {
            type: Array,
            default: [],
        },
        zoom_level: {
            default: 12,
        },
        mapData: {
            default: null,
        },
        orderId: {
            default: null,
        },
        orderStatus: {
            default: null,
            type: String,
        },
        order: {
            type: [Array, Object],
            default: [],
        },
        providers: {
            type: Array,
            default: [],
        },
        settingMapRadiusRange: {
            type: [Number, String],
            default: 0,
        },
    },
    data() {
        return {
            map: "",
            providerCurrentLocation: null,
            directionsService: null,
            directionsDisplay: null,
            providerCurrentLocationMarker: null,
            isProviderCollectionExits: false,
        };
    },
    mounted() {
        loader.load().then(() => {
            this.initMap(this.locations);
        });
    },
    created() {
        this.interval = setInterval(
            () => this.getProviderCurrentLocation(),
            10000
        );
    },
    watch: {
        lat: {
            handler: function () {
                this.initMap(this.locations);
            },
        },
        providerCurrentLocation: {
            handler: function () {
                if (this.providerCurrentLocation != null)
                    this.updateProviderLocation();
            },
        },
    },
    methods: {
        async initMap(locations) {
            this.map = new google.maps.Map(document.getElementById("jq__map"), {
                zoom: 12,
                center: {
                    lat: locations[0].latitude,
                    lng: locations[0].longitude,
                },
            });

            google.maps.event.trigger(this.map, "resize", {});
            this.map.setCenter(
                new google.maps.LatLng(
                    locations[0].latitude,
                    locations[0].longitude
                )
            );
            this.map.setZoom(12);

            var bounds = new google.maps.LatLngBounds();

            locations.forEach((item) => {
                if (item.type == "pickup_address") {
                    var image = window.location.origin + "/img/pickup-icon.png";
                } else if (item.type == "dropoff_address") {
                    var image =
                        window.location.origin + "/img/dropoff-icon.png";
                }
                var marker = new google.maps.Marker({
                    position: { lat: item.latitude, lng: item.longitude },
                    map: this.map,
                    icon: image,
                    animation: google.maps.Animation.DROP,
                    title: item.address,
                });

                bounds.extend(marker.position);

                if (item.address != null) {
                    var infowindow = new google.maps.InfoWindow({
                        content: item.address,
                    });
                    marker.addListener("mouseover", function () {
                        infowindow.open(this.map, marker);
                    });
                    marker.addListener("mouseout", function () {
                        infowindow.close();
                    });
                }
            });
            //circle on the map and Near by provider location
            if (this.order && this.order?.provider_id == null) {
                this.providers.forEach((item) => {
                    var image = this.getMarkerImage(item.last_opened_at);
                    var marker = new google.maps.Marker({
                        position: {
                            lat: item.current_lat,
                            lng: item.current_long,
                        },
                        map: this.map,
                        icon: image,
                        animation: google.maps.Animation.DROP,
                    });

                    bounds.extend(marker.position);

                    if (item.name != null) {
                        var infowindow = new google.maps.InfoWindow({
                            content:
                                item.name +
                                " <br> +" +
                                item.country_code +
                                " " +
                                item.mobile_no +
                                "<br>" +
                                (item.location_updated_at != null
                                    ? item.location_updated_at
                                    : ""),
                        });
                        marker.addListener("mouseover", function () {
                            infowindow.open(this.map, marker);
                        });
                        google.maps.event.addListener(marker, "click", () => {
                            let routeData = this.$router.resolve({
                                name: "Provider Details",
                                params: { id: item.id },
                            });
                            window.open(routeData.href, "_blank");
                        });
                        marker.addListener("mouseout", function () {
                            infowindow.close();
                        });
                    }
                });

                new google.maps.Circle({
                    strokeColor: "",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillOpacity: 0.35,
                    fillColor: "",
                    map: this.map,
                    center: {
                        lat: locations[0].latitude,
                        lng: locations[0].longitude,
                    },
                    radius: this.settingMapRadiusRange * 1000, // 100000.0 mean 100 KM. Calc (100 * 1000 = 100000)
                });
            }

            this.directionsService = new google.maps.DirectionsService();
            this.directionsDisplay = new google.maps.DirectionsRenderer();
            this.getProviderCurrentLocation();
            this.map.fitBounds(bounds);
            this.map.panToBounds(bounds);
        },
        async updateProviderLocation() {
            // var directionsService = this.directionsService;
            // var directionsDisplay = this.directionsDisplay;

            // Here show the only for provider current location
            if (this.providerCurrentLocationMarker == null) {
                this.providerCurrentLocationMarker = new google.maps.Marker({
                    position: {
                        lat: this.providerCurrentLocation.latitude,
                        lng: this.providerCurrentLocation.longitude,
                    },
                    map: this.map,
                    icon: require("@/assets/images/location_car.png"),
                });
            } else {
                var newLatLng = new google.maps.LatLng(
                    this.providerCurrentLocation.latitude,
                    this.providerCurrentLocation.longitude
                );
                this.providerCurrentLocationMarker.setPosition(newLatLng);
            }

            // Map polyline hide as per client comment - 1-9-2022
            // var start = new google.maps.LatLng(
            //     this.providerCurrentLocation.latitude,
            //     this.providerCurrentLocation.longitude
            // );
            // var destinationLat =
            //     this.orderStatus == "On The Way"
            //         ? this.locations[0].latitude
            //         : this.locations[1].latitude;

            // var destinationLong =
            //     this.orderStatus == "On The Way"
            //         ? this.locations[0].longitude
            //         : this.locations[1].longitude;

            // var end = new google.maps.LatLng(destinationLat, destinationLong);
            // var bounds = new google.maps.LatLngBounds();

            // bounds.extend(start);
            // bounds.extend(end);
            // this.map.fitBounds(bounds);

            // var request = {
            //     origin: start,
            //     destination: end,
            //     travelMode: google.maps.TravelMode.DRIVING,
            // };
            // directionsService.route(request, (response, status) => {
            //     if (status == google.maps.DirectionsStatus.OK) {
            //         directionsDisplay.setDirections(response);
            //         directionsDisplay.setMap(this.map);
            //     } else {
            //         console.log("Not found the location route");
            //     }
            // });
        },
        async getProviderCurrentLocation() {
            var orderStatus = ["On The Way", "Picked Up", "Dropped Off"];
            if (
                this.orderId &&
                this.orderStatus &&
                orderStatus.includes(this.orderStatus)
            ) {
                // Before we storing the provider order collection in Order collection after 5-10-2022 we are storing the provider current location in Provider collection

                const locationRef = await collection(
                    db,
                    `provider/${this.order.provider_id}/location`
                );
                const querySnapshot = await getDocs(locationRef);
                querySnapshot.forEach((doc) => {
                    let data = doc.data();
                    let objKey = Object.keys(data);
                    if (objKey.includes("lat") && objKey.includes("long")) {
                        this.isProviderCollectionExits = true;
                        this.providerCurrentLocation = {
                            latitude: data.lat,
                            longitude: data.long,
                        };
                    }
                });

                if (!this.isProviderCollectionExits) {
                    const locationRef = await collection(
                        db,
                        `one8Order/${this.order.id}/order`
                    );
                    const querySnapshot = await getDocs(locationRef);
                    querySnapshot.forEach((doc) => {
                        let data = doc.data();
                        let objKey = Object.keys(data);
                        if (
                            objKey.includes("truckLat") &&
                            objKey.includes("truckLong")
                        ) {
                            this.providerCurrentLocation = {
                                latitude: data.truckLat,
                                longitude: data.truckLong,
                            };
                        }
                    });
                }
            }
        },
        getMarkerImage(lastOpenedAt) {
            const oneDay = 24 * 60 * 60 * 1000;
            const now = new Date();

            // Check if lastOpenedAt is null or undefined
            if (!lastOpenedAt) {
                console.log('lastOpenedAt is null or undefined, returning black marker');
                return window.location.origin + "/img/provider-location-pin-black.png";
            }

            try {
                const [datePart, timePart, period] = lastOpenedAt.split(' ');
                const [day, month, year] = datePart.split('/').map(Number);
                let [hour, minute] = timePart.split(':').map(Number);

                if (period === 'PM' && hour !== 12) {
                    hour += 12;
                } else if (period === 'AM' && hour === 12) {
                    hour = 0;
                }

                const lastOpenedDate = new Date(year, month - 1, day, hour, minute);
                
                if (isNaN(lastOpenedDate.getTime())) {
                    throw new Error('Invalid Date');
                }

                const diffDays = Math.round(Math.abs((now - lastOpenedDate) / oneDay));

                if (diffDays <= 7) {
                    return window.location.origin + "/img/provider-location-pin.png";
                } else if (diffDays <= 14) {
                    return window.location.origin + "/img/provider-location-pin-green.png";
                } else if (diffDays <= 30) {
                    return window.location.origin + "/img/provider-location-pin-yellow.png";
                } else {
                    return window.location.origin + "/img/provider-location-pin-black.png";
                }
            } catch (error) {
                return window.location.origin + "/img/provider-location-pin-black.png";
            }
        },
    },
};
</script>
