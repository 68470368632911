<template>
    <div>
        <hr />
        <div class="p-5 mb-0 card rounded-md">
            <h3>{{ $t(addOrEdit) }}</h3>

            <Form @submit="onSubmit" v-loading="isLoading" class="">
                <div class="mt-3 row">
                    <div class="col-md-6">
                        <label for="name">{{ $t("name") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            :label="$t('name')"
                            name="name"
                            v-model="form.name"
                        />
                        <ErrorMessage name="name" class="error" />
                    </div>
                </div>

                <div class="mt-3 row">
                    <div class="col-md-6">
                        <label for="">{{ $t("email") }}</label>
                        <Field
                            type="text"
                            rules="required|email"
                            :label="$t('email')"
                            class="form-control"
                            name="email"
                            v-model="form.email"
                        />
                        <ErrorMessage name="email" class="error" />
                    </div>

                    <div class="col-md-6">
                        <label for="">{{ $t("mobile_no") }}</label>
                        <Field
                            type="text"
                            rules="required|length:9"
                            :label="$t('mobile_no')"
                            class="form-control"
                            name="mobile_no"
                            v-model="form.mobile_no"
                            v-on:keyup="checkMobile"
                        />
                        <ErrorMessage name="mobile_no" class="error" />
                        <span class="error">{{ mobileErrorMsg }}</span>
                    </div>
                </div>

                <div class="mt-3 row" v-if="id == 'add'">
                    <div class="col-md-6">
                        <label for="">{{ $t("password") }}</label>
                        <Field
                            name="password"
                            class="form-control"
                            type="password"
                            v-model="form.password"
                            rules="required|min:8"
                            :label="$t('password')"
                        />
                        <ErrorMessage name="password" class="error" />
                    </div>

                    <div class="col-md-6">
                        <label for="">{{ $t("password_confirmation") }}</label>
                        <Field
                            name="password_confirmation"
                            class="form-control"
                            type="password"
                            v-model="form.password_confirmation"
                            rules="required|confirmed:@password"
                            :label="$t('password_confirmation')"
                        />
                        <ErrorMessage
                            name="password_confirmation"
                            class="error"
                        />
                    </div>
                </div>

                <div class="mt-1 row" v-if="hasRole(4)">
                    <div class="col-md-6">
                        <label for="status" class="my-1">{{
                            $t("addresses")
                        }}</label>
                        <Field
                            name="address_ids"
                            :label="$t('addresses')"
                            v-model="address_ids"
                        >
                            <el-select
                                multiple
                                class="d-block"
                                name="address_ids"
                                :placeholder="$t('addresses')"
                                v-model="address_ids"
                                filterable
                            >
                                <el-option
                                    v-for="option in addresses"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="option.address_line1"
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage name="address_ids" class="error" />
                    </div>
                </div>
                <div class="mt-1 row" v-if="hasRole(1)">
                    <div class="col-md-6">
                        <label for="role" class="mt-3 form-control-label"
                            >{{ $t("role") }}
                        </label>
                        <Field
                            v-slot="{ field }"
                            name="role"
                            rules="required"
                            :label="$t('role')"
                            v-model="form.role"
                        >
                            <el-select
                                class="d-block"
                                name="role"
                                v-bind="field"
                                v-model="form.role"
                                filterable
                            >
                                <el-option
                                    v-for="option in rolesOption"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.label"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage name="role" class="error" />
                    </div>
                </div>

                <div class="w-full mt-5 text-center">
                    <base-button
                        native-type="submit"
                        class="text-white bg-primary"
                        :loading="submitSpinner"
                    >
                        {{ $t("submit") }}
                    </base-button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import { addUser, editUser, getUser, uniqueMobileNo } from "@/api/users";
import { get } from "@/api/addresses";
import mixin from "@/mixins/common";
export default {
    data() {
        return {
            addOrEdit: "",
            user: {},
            addresses: [],
            address_ids: [],

            form: {
                name: null,
                email: null,
                mobile_no: null,
                password: null,
                password_confirmation: null,
                role: null,
            },
            roles: "",
            isLoading: false,
            submitSpinner: false,
            rolesOption: [
                {
                    label: this.$t("operator"),
                    value: "operator",
                },
                {
                    label: this.$t("store_account_manager"),
                    value: "store_account_manager",
                },
                {
                    label: this.$t("provider_account_manager"),
                    value: "provider_account_manager",
                },
            ],
            mobileValid: false,
            mobileErrorMsg: null,
        };
    },

    props: ["id"],

    mixins: [mixin],

    components: {},

    computed: {},
    created() {
        if (this.userRole == 4) this.getAddresses();
        if (this.id === "add") {
            this.addOrEdit = this.$t("add_user");
        } else {
            this.addOrEdit = this.$t("edit_user");
            this.getUser();
        }
    },

    methods: {
        checkMobile() {
            if (this.form.mobile_no.length == 9) {
                uniqueMobileNo({
                    country_code: 966,
                    mobile_no: this.form.mobile_no,
                    user_id: this.id != "add" ? this.id : null,
                    lang: localStorage.getItem("lang") == "en" ? "en" : "ar",
                }).then((res) => {
                    if (res.data.is_unique == true) {
                        this.mobileErrorMsg = null;
                        this.mobileValid = true;
                    } else {
                        this.mobileErrorMsg = res.data.message;
                        this.mobileValid = false;
                    }
                });
            } else {
                this.mobileErrorMsg = null;
                this.mobileValid = false;
            }
        },
        getAddresses() {
            get({
                store_id: this.storeId,
            })
                .then((res) => {
                    this.addresses = res.data;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        getAssignedAddresses() {
            get({
                store_id: this.storeId,
                limit: -1,
                filters: {
                    assigned_user: this.id,
                },
            })
                .then((res) => {
                    res.data.forEach((item) => {
                        this.address_ids.push(item.id);
                    });
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getUser() {
            this.isLoading = true;
            getUser(this.userRole === 1 ? "admin" : "store", this.id, {
                store_id: this.storeId,
            })
                .then((res) => {
                    this.isLoading = false;
                    this.user = res;
                    this.form = res;
                    this.mobileValid = true;
                    if (this.userRole == 4) this.getAssignedAddresses();
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },

        async onSubmit(data) {
            if (this.mobileValid == true) {
                this.submitSpinner = true;
                if (this.id == "add") {
                    addUser(this.userRole === 1 ? "admin" : "store", data, {
                        store_id: this.storeId,
                    })
                        .then((res) => {
                            this.submitSpinner = false;
                            this.$router.push({ name: "Users List" });
                        })
                        .catch(({ response }) => {
                            this.submitSpinner = false;

                            this.apiError(response);
                        });
                } else {
                    editUser(
                        this.userRole === 1 ? "admin" : "store",
                        this.id,
                        data,
                        {
                            _method: "PATCH",
                            store_id: this.storeId,
                        }
                    )
                        .then(() => {
                            this.isLoading = false;
                            this.$router.push({ name: "Users List" });
                        })
                        .catch(({ response }) => {
                            this.submitSpinner = false;

                            this.apiError(response);
                        });
                }
            }
        },
    },
};
</script>

<style></style>
