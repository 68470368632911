<template>
    <div>
        <hr />
        <div class="card mb-0 p-5 rounded-md">
            <div
                class="border-0 card-header px-0 d-flex justify-content-between align-items-center"
            >
                <router-link
                    :to="{
                        name: 'pickup-store',
                        params: { id: 'add' },
                    }"
                >
                    <button class="text-white btn btn-default btn-sm">
                        <i class="mr-2 text-white fas fa-plus-circle fa-lg">
                            <span>{{ $t("add_pickup_store") }}</span>
                        </i>
                    </button>
                </router-link>
            </div>

            <div class="d-flex mb-4 pt-2">
                <search-filter
                    :filters="[
                        {
                            id: 'name',
                        },
                    ]"
                    @search="(searchObject) => (filters = searchObject)"
                />
                <drop-down-filter
                    class="ml-3 mr-3"
                    :dropDownFilters="[
                        {
                            id: 'status',
                            label: $t('status'),
                            options: defaultOptions.status,
                        },
                    ]"
                    @dropDownOptionSelected="
                        (optionsObject) =>
                            (filters = { ...filters, ...optionsObject })
                    "
                />
            </div>
            <!-- End of Filter Div -->
            <div class="row">
                <main class="col-md-12 col-xs-11 p-l-2 p-t-2">
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="stores"
                            v-loading="isLoading"
                            highlight-current-row
                            :empty-text="$t('no_data_found')"
                            @row-click="rowClicked"
                        >
                            <el-table-column
                                :label="$t('store_name')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.name }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('working_hours')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.open_time }} -
                                        {{ row.close_time }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('status')"
                                min-width="150px"
                                prop="status"
                            >
                                <template v-slot="{ row }">
                                    <custom-label :label="row.status" />
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('logo')" prop="logo">
                                <template v-slot="{ row }">
                                    <img
                                        :src="row.logo"
                                        class="avatar rounded-circle"
                                    />
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('actions')"
                                min-width="210px"
                            >
                                <template v-slot="{ row }">
                                    <div
                                        class="d-flex align-items-center flex-wrap action-width"
                                    >
                                        <el-tooltip
                                            :content="$t('edit')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                :title="$t('edit')"
                                                style="cursor: pointer"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="
                                                        '/pickup-stores/' +
                                                        row.id
                                                    "
                                                >
                                                    <img
                                                        src="@/assets/svg/edit-2.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- End of Table--->
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="stores.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="storesCurrentPage"
                            :total="storesTotalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from "@/api/pickupStore";
import mixin from "@/mixins/common";
export default {
    data() {
        return {
            isLoading: false,
            showButtonLoader: false,
            stores: [],
            meta: {},
            storesCurrentPage: 1,
            filters: {
                name: "",
            },
            storesTotalData: null,
        };
    },
    created() {
        this.getStores();
    },
    watch: {
        storesCurrentPage: {
            handler: function () {
                this.getStores();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.storesCurrentPage = 1;
                this.getStores();
            },
        },
    },
    mixins: [mixin],

    methods: {
        // editStore(id) {
        //     this.$router.push({ name: "pickup-store", params: { id } });
        // },
        getStores() {
            this.isLoading = true;
            get({
                page: this.storesCurrentPage,
                filter: {
                    ...this.filters,
                },
            })
                .then((res) => {
                    this.isLoading = false;
                    this.stores = res.data;
                    this.storesTotalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                name: "",
            };
        },
        rowClicked(row, column, event) {
            if(column.label != this.$t("actions")){
                this.$router.push({
                    name: "pickup-store",
                    params: { id: row.id },
                });
            }
            return;
        },
    },
    components: {},
};
</script>

<style></style>
