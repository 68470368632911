import List from "@/views/Pages/Stores/List";
import View from "@/views/Pages/Stores/View";
import Upsert from "@/views/Pages/Stores/Upsert";
export default [
    {
        path: "stores",
        components: { default: List },
        name: "Stores List",
        meta: {
            title: "stores",
            role: [1, 6, 7],
        },
    },
    {
        path: "stores/:id/detail",
        components: { default: View },
        props: true,
        name: "Store Details",
        meta: {
            title: "store_details",
            role: [1, 6, 7],
        },
    },
    {
        path: "stores/:id",
        components: { default: Upsert },
        props: true,
        name: "Store Upsert",
        meta: {
            title: "store",
            role: [1, 6, 7],
        },
    },
];
