import axiosInstance from "@/plugins/customAxios";

export const getServiceProvider = (params = null) => {
    return axiosInstance
        .get(
            `v1/admin/service-provider-companies?limit=10&ascending=0&page=1&byColumn=1&request_from=web&include=providers%2Cuser`,
            { params: params }
        )
        .then((res) => {
            return res.data;
        });
};

export const getCompany = (id, params = null) => {
    return axiosInstance
        .get(`v1/admin/service-provider-companies/${id}?`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const assignProvider = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/admin/service-provider-companies/${id}/assign-providers`, data, {
            params: params,
        })
        .then((res) => {
            return res.data;
        });
};

export const editServiceProviderCompany = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/admin/service-provider-companies/${id}`, data, {
            params: params,
        })
        .then((res) => {
            return res.data;
        });
};

export const addServiceProviderCompany = (data, params = null) => {
    return axiosInstance
        .post(`v1/admin/service-provider-companies`, data, {
            params: params,
        })
        .then((res) => {
            return res.data;
        });
};
