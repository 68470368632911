<template>
    <div>
        <hr />
        <complaint-list
            :showAllComplaints="true"
            :businessComplaints="true"
        ></complaint-list>
    </div>
</template>

<script>
import ComplaintList from "@/components/common/ComplaintList";
export default {
    components: {
        ComplaintList,
    },
};
</script>

<style></style>
