import List from "@/views/Pages/CouponCode/List";
import Upsert from "@/views/Pages/CouponCode/Upsert";

export default [{
        path: "coupon-codes",
        components: { default: List },
        name: "CouponCode List",
        meta: {
            title: "coupon_codes",
            role: [1, 6],
        },
    },
    {
        path: "coupon-codes/:id",
        components: { default: Upsert },
        props: true,
        name: "CouponCode Upsert",
        meta: {
            title: "coupon_code",
            role: [1, 6],
        },
    },
];