<template>
    <div>
        <hr />
        <div class="card py-5 rounded-md">
            <div class="row">
                <main class="col-md-12 col-xs-11 p-l-2 p-t-2">
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="activityLog"
                            v-loading="isLoading"
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('activity_log.id')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="mb-0 font-weight-600 name text-capitalize"
                                        >{{ row.id }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('user')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="mb-0 font-weight-600 name text-nowrap"
                                        >{{ row.causer?.name }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('activity_log.log_name')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="mb-0 font-weight-600 name">
                                        {{ row.log_name }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('description')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="mb-0 font-weight-600 name">
                                        {{ row.description }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('activity_log.created_at')"
                                min-width="155px"
                            >
                                <template v-slot="{ row }">
                                    <span class="mb-0 font-weight-600 name">
                                        {{ row.created_at }}
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="activityLog.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
        <!--  End of Table -->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import mixin from "@/mixins/common";
import { getActivityLog } from "@/api/activityLog";
export default {
    data() {
        return {
            activityLog: [],
            currentPage: 1,
            isLoading: false,
            meta: {},
            totalData: null,
        };
    },

    mixins: [mixin],

    components: {},

    created() {
        this.getActivityLog();
    },

    watch: {
        currentPage: {
            handler: function () {
                this.getActivityLog();
            },
        },
    },

    computed: {},

    methods: {
        getActivityLog() {
            this.isLoading = true;
            getActivityLog({
                page: this.currentPage,
            })
                .then((res) => {
                    this.activityLog = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
    },
};
</script>
