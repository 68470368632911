import Badge from "../components/Badge";
import BaseButton from "../components/BaseButton";
import BaseCheckbox from "../components/Inputs/BaseCheckbox";
import BaseDropdown from "../components/BaseDropdown";
import BaseHeader from "../components/BaseHeader";
import BaseInput from "../components/Inputs/BaseInput";
import BaseNav from "../components/Navbar/BaseNav";
import BasePagination from "../components/BasePagination";
import BaseProgress from "../components/BaseProgress";
import BaseRadio from "../components/Inputs/BaseRadio";
import BaseSwitch from "../components/BaseSwitch";
import Card from "../components/Cards/Card";
import StatsCard from "../components/Cards/StatsCard";
import { Form, Field, ErrorMessage } from "vee-validate";
import Tabs from "@/components/Tabs/Tabs";
import TabPane from "@/components/Tabs/TabPane";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import Modal from "@/components/Modal";
import StatusFilterTabs from "@/components/common/StatusFilterTabs";
import SearchFilter from "@/components/common/SearchFilter";
import DropDownFilter from "@/components/common/DropDownFilter";
import CustomLabel from "@/components/common/CustomLabel";
import {
    ElTooltip,
    ElPopover,
    ElLoading,
    ElTable,
    ElTableColumn,
    ElDropdownMenu,
    ElDropdownItem,
    ElDropdown,
    ElButton,
    ElRadio,
    ElCheckbox,
    ElCheckboxGroup,
    ElSelect,
    ElOption,
    ElRadioButton,
    ElRadioGroup,
    ElCascader,
} from "element-plus";

// import ElTooltip from "element-plus/lib/el-tooltip";

const GlobalComponents = {
    install(app) {
        app.component("badge", Badge);
        app.component("base-button", BaseButton);
        app.component("base-checkbox", BaseCheckbox);
        app.component("base-dropdown", BaseDropdown);
        app.component("base-input", BaseInput);
        app.component("base-nav", BaseNav);
        app.component("base-header", BaseHeader);
        app.component("base-pagination", BasePagination);
        app.component("base-progress", BaseProgress);
        app.component("base-radio", BaseRadio);
        app.component("base-switch", BaseSwitch);
        app.component("card", Card);
        app.component("stats-card", StatsCard);
        app.component("RouteBreadCrumb", RouteBreadCrumb);
        app.component("Form", Form);
        app.component("Field", Field);
        app.component("ErrorMessage", ErrorMessage);
        app.component("Modal", Modal);
        app.component("Tabs", Tabs);
        app.component("TabPane", TabPane);
        app.component("StatusFilterTabs", StatusFilterTabs);
        app.component("SearchFilter", SearchFilter);
        app.component("custom-label", CustomLabel);
        app.component("DropDownFilter", DropDownFilter);

        // app.component("Tooltip", ElTooltip);

        app.use(ElTooltip);
        app.use(ElPopover);
        app.use(ElLoading);
        app.use(ElTable);
        app.use(ElTableColumn);
        app.use(ElDropdownMenu);
        app.use(ElDropdown);
        app.use(ElDropdownItem);
        app.use(ElButton);
        app.use(ElSelect);
        app.use(ElOption);
        app.use(ElRadio);
        app.use(ElCheckbox);
        app.use(ElCheckboxGroup);
        app.use(ElRadioGroup);
        app.use(ElRadioButton);
        app.use(ElCascader);
    },
};

export default GlobalComponents;
