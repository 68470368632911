<template>
    <div class="d-inline">
        <div class="col">
            <button
                v-if="hasRole(4)"
                type="button"
                class="btn btn-sm btn--new-order bg-primary text-white rounded-lg"
                @click="allowOnHoldOrderProcessCheck"
            >
                {{ $t("order_status_change_to_confirmed") }}
            </button>
        </div>

        <modal
            class="text-left"
            :show="showOrderNumberModal"
            @close="showOrderNumberModal = false"
            size="sm"
            v-loading="isOrderModalLoading"
        >
            <template v-slot:header>
                <div class="text-bold text-lg text-center mt-2 mb-0">
                    {{ $t("onHoldOrderNumbers") }}
                </div>
            </template>
            <div class="pt-0">
                <p
                    class="text-ld text-danger"
                    style="margin-top: -20px"
                    v-if="isShowOrderProcessNote"
                >
                    {{ this.orderProcessNote }}
                </p>
                <div v-for="number in orderNumbers" :key="number">
                    <p>{{ $t("order_no") }}: {{ number }}</p>
                </div>
                <span class="text-sm">
                    {{ $t("note") }}: {{ $t("onHoldOrderModelNote") }} <br />
                </span>
                <div class="w-full text-center order-process-btn-wrap mt-3">
                    <base-button
                        v-if="!isShowOrderProcessNote"
                        @click="
                            (showConfirmedModel = true),
                                (showOrderNumberModal = false)
                        "
                        native-type="button"
                        class="text-white px-4 bg-primary"
                    >
                        {{ $t("confirm") }}
                    </base-button>

                    <input
                        type="button"
                        class="btn btn-default text-white"
                        @click="
                            (showOrderNumberModal = false), (orderNumbers = [])
                        "
                        :value="$t('close')"
                    />
                </div>
            </div>
        </modal>
        <modal
            footerClasses="justify-content-center"
            :show="showConfirmedModel"
            @close="showConfirmedModel = false"
            size="sm"
            body-classes="p-0"
            v-loading="isOrderModalLoading"
        >
            <template v-slot:header>
                <div>
                    <img
                        src="@/assets/svg/confirm-img.svg"
                        alt=""
                        style="width: 150px; height: 150px"
                    />
                    <div class="text-bold text-lg text-center mt-2 mb-1">
                        {{ $t("onHoldOrderNumberConfirm") }}
                    </div>
                </div>
            </template>
            <div class="px-3 d-block text-center">
                <h2 class="text-break font-weight-900">
                    {{ $t("onHoldOrderNumberConfirmMsg") }}
                </h2>
            </div>
            <template v-slot:footer>
                <div class="w-full text-center mt-3">
                    <base-button
                        @click.native="submit()"
                        :loading="isOrderModalLoading"
                    >
                        {{ $t("yes") }}</base-button
                    >
                    <button
                        class="btn btn-default text-white"
                        data-dismiss="modal"
                        @click="
                            (showOrderNumberModal = true),
                                (showConfirmedModel = false)
                        "
                    >
                        {{ $t("no") }}
                    </button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import { me } from "@/api/users";
import store from "@/store/index";
import mixin from "@/mixins/common";
import { allowOnHoldOrderProcess, updateOnHoldOrderStatus } from "@/api/orders";

export default {
    data() {
        return {
            showOrderNumberModal: false,
            isOrderModalLoading: false,
            showConfirmedModel: false,
            orderNumbers: [],
            orderIds: [],
            orderProcessNote: null,
            isAllowOrderProcess: true,
            isShowOrderProcessNote: false,
        };
    },

    props: {
        onHoldOrders: {
            default: null,
        },
    },

    mixins: [mixin],

    methods: {
        allowOnHoldOrderProcessCheck() {
            if (this.onHoldOrders.length > 0) {
                this.isShowOrderProcessNote = false;

                this.orderNumbers = [];
                this.orderIds = [];
                this.onHoldOrders.forEach((order, index) => {
                    this.orderNumbers.push(order.order_no);
                    this.orderIds.push(order.id);
                });

                allowOnHoldOrderProcess({
                    store_id: store.getters.store?.id,
                    order_ids: this.orderIds,
                })
                    .then((res) => {
                        this.isShowOrderProcessNote = false;
                        if (res?.order_process_available_credit) {
                            this.orderProcessNote = this.$t(
                                "orderProcessNote",
                                {
                                    process_order_count:
                                        res?.order_process_available_credit,
                                }
                            );
                            this.isShowOrderProcessNote = true;
                        }
                        this.showOrderNumberModal = true;
                    })
                    .catch(({ response }) => {
                        this.isOrderModalLoading = false;
                        this.apiError(response);
                    });
            } else {
                this.runToast(this.$t("selectOnHold"), "error");
            }
        },
        // confirmedModelShow() {
        //     this.orderProcessNote = null;
        //     let storeCredit = this.storeCredit();
        //     let orderTotal = this.onHoldOrders.reduce(
        //         (acc, order) => acc + parseInt(order.total),
        //         0
        //     );
        //     // Check the all order selected is process or not
        //     if (storeCredit < orderTotal) {
        //         let orderBasePrice = this.onHoldOrders[0]?.sub_total;
        //         let orderProceedAvailableCredit = parseInt(
        //             storeCredit / orderBasePrice
        //         );

        //         if (orderProceedAvailableCredit < 1) {
        //             this.runToast(
        //                 this.$t("creditLowNotProcessOnHoldOrder"),
        //                 "error"
        //             );
        //             return;
        //         }

        //         this.orderProcessNote = this.$t("orderProcessNote", {
        //             process_order_count: orderProceedAvailableCredit,
        //         });
        //         this.isShowOrderProcessNote = true;
        //     } else {
        //         this.isShowOrderProcessNote = false;
        //         this.showConfirmedModel = true;
        //         this.showOrderNumberModal = false;
        //     }
        // },
        submit() {
            this.isOrderModalLoading = true;
            updateOnHoldOrderStatus({
                store_id: store.getters.store?.id,
                order_ids: this.orderIds,
            })
                .then((res) => {
                    this.updateStoreCredit();
                    this.isOrderModalLoading = false;
                    this.runToast(res.message, "success");
                    this.showConfirmedModel = false;
                    this.$emit("onHoldOrderStatusUpdated");
                    this.orderNumbers = [];
                    this.updateCredit();
                })
                .catch(({ response }) => {
                    this.updateStoreCredit();
                    this.isOrderModalLoading = false;
                    this.apiError(response);
                });
        },
        updateCredit() {
            me().then((res) => {
                this.$store.dispatch(
                    "setStoreCredit",
                    res.user?.stores[0]?.credit
                        ? res.user?.stores[0]?.credit
                        : 0
                );
            });
        },
    },
};
</script>

<style lang="scss">
.order-process-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-process-btn-wrap button,
.order-process-btn-wrap input {
    width: 140px;
}
.border-green {
    border-color: #2dce89 !important;
}
</style>
