<template>
    <div>
        <hr />
        <div class="card py-3 px-5 rounded-md">
            <div
                class="border-0 card-header px-0 d-flex justify- tent-between align-items-center"
            >
                <router-link
                    :to="{
                        name: 'CouponCode Upsert',
                        params: { id: 'add' },
                    }"
                >
                    <button class="text-white btn btn-default btn-sm">
                        <i class="mr-2 text-white fas fa-plus-circle fa-lg">
                            <span>{{ $t("add_coupon") }}</span>
                        </i>
                    </button>
                </router-link>
            </div>

            <!-- Filter Section Start -->
            <div class="d-flex mb-4">
                <search-filter
                    :filters="[
                        {
                            id: 'name',
                        },
                    ]"
                    @search="(searchObject) => (filters = searchObject)"
                />
                <div class="mx-2">
                    <flat-picker
                        :config="{
                            mode: 'range',
                            dateFormat: 'd-m-Y',
                            onClose: (dates, currentdatestring, picker) => {
                                dateFilterChange(
                                    dates,
                                    currentdatestring,
                                    picker
                                );
                            },
                        }"
                        class="form-control datepicker"
                        :placeholder="$t('start_end_date')"
                        v-model="startEndDateFilter"
                    >
                    </flat-picker>
                </div>
                <drop-down-filter
                    class="mr-3"
                    :dropDownFilters="[
                        {
                            id: 'visibility',
                            options: defaultOptions.couponCodeVisibility,
                        },
                    ]"
                    @dropDownOptionSelected="
                        (optionsObject) =>
                            (filters = { ...filters, ...optionsObject })
                    "
                />
            </div>
            <!-- Filter Section End  -->

            <!-- Table Section Starts -->
            <div class="row">
                <main
                    :class="
                        isFilter
                            ? 'col-md-9 col-xs-11 p-l-2 p-t-2'
                            : 'col-md-12 col-xs-11 p-l-2 p-t-2'
                    "
                >
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="couponCodes"
                            v-loading="isLoading"
                            @row-click="rowClicked"
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('name')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="mb-0 font-weight-600 name text-nowrap"
                                        >{{ row.name }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('visibility')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <custom-label
                                        :label="row.visibility"
                                    ></custom-label>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('discount_percentage')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="mb-0 font-weight-600 name"
                                        >{{ row.value }}%</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('start_date')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="mb-0 font-weight-600 name"
                                        v-if="row.visibility != 'automatic'"
                                    >
                                        {{ row.start_date }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('end_date')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="mb-0 font-weight-600 name"
                                        v-if="row.visibility != 'automatic'"
                                        >{{ row.end_date }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('useable_count')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <custom-label
                                        v-if="row.useable_count >= 10000"
                                        label="unlimited"
                                    />
                                    <span
                                        v-else
                                        class="mb-0 font-weight-600 name"
                                        >{{ row.useable_count }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('used_count')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span class="mb-0 font-weight-600 name">{{
                                        row.used_count
                                    }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('status')"
                                min-width="150px"
                                prop="status"
                            >
                                <template v-slot="{ row }">
                                    <custom-label :label="row.status" />
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('added_at')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span class="mb-0 font-weight-600 name">{{
                                        row.added_at
                                    }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('actions')"
                                min-width="210px"
                            >
                                <template v-slot="{ row }">
                                    <div class="d-flex">
                                        <el-tooltip
                                            :content="$t('edit')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                style="cursor: pointer"
                                                :title="$t('edit')"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="
                                                        '/coupon-codes/' +
                                                        row.id
                                                    "
                                                >
                                                    <img
                                                        src="@/assets/svg/note.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>
                                        <!--Alert  Modal with Delete Function -->
                                        <alert-modal
                                            v-if="row.visibility != 'automatic'"
                                            :id="row.id"
                                            :title="$t('coupon_code')"
                                            alertCategory="coupon_code"
                                            :body="
                                                $t(
                                                    'coupon_code_delete_confirmation'
                                                )
                                            "
                                            @alertModalCompleted="
                                                getCouponCodes
                                            "
                                        ></alert-modal>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="p-0 pt-5 pb-5 card-footer"
                        v-if="couponCodes.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
        <!--  End of Table -->
    </div>
</template>

<script>
import moment from "moment";
import mixin from "@/mixins/common";
import "flatpickr/dist/flatpickr.css";
import flatPicker from "vue-flatpickr-component";
import { getCouponCodes } from "@/api/couponCodes";
import AlertModal from "@/components/common/AlertModal.vue";
import DropDownFilter from "../../../components/common/DropDownFilter.vue";
import CustomLabel from "../../../components/common/CustomLabel.vue";

export default {
    data() {
        return {
            couponCodes: [],
            currentPage: 1,
            filters: {},
            providers: [],
            currentRow: {},
            isLoading: false,
            totalData: null,
            isFilter: false,
            meta: false,
            selectedDate: [],
            startEndDateFilter: null,
        };
    },
    mixins: [mixin],
    created() {
        this.getCouponCodes();
    },
    components: {
        AlertModal,
        flatPicker,
        CustomLabel,
        DropDownFilter,
    },
    watch: {
        currentPage: {
            handler: function () {
                this.getCouponCodes();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.currentPage = 1;
                this.getCouponCodes();
            },
        },
    },
    methods: {
        dateFilterChange(dates, currentdatestring, picker) {
            if (dates != null && dates.length == 2) {
                if (dates[0] != undefined) {
                    this.filters.start_date = moment(dates[0]).format(
                        "YYYY-MM-DD"
                    );
                }
                if (dates[1] != undefined) {
                    this.filters.end_date = moment(dates[1]).format(
                        "YYYY-MM-DD"
                    );
                }
            } else {
                this.filters.start_date = "";
                this.filters.end_date = "";
            }
        },
        getCouponCodes() {
            this.isLoading = true;
            getCouponCodes({
                page: this.currentPage,
                filter: this.filters,
            })
                .then((res) => {
                    this.couponCodes = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        rowClicked(row, column, event) {
            if (column.label != this.$t("actions")) {
                this.$router.push({
                    name: "CouponCode Upsert",
                    params: { id: row.id },
                });
            }
            return;
        },
    },
};
</script>
