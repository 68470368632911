<template>
    <div>
        <div class="d-flex" style="gap: 20px">
            <div v-for="filter in filters" :key="filter.id">
                <!-- Below if condition is for conditionally showing search filter like Store Name filter in order list if role id is 4  -->
                <input
                    v-if="filter.showSearchFilter == undefined ? true : false"
                    :type="filter.type || 'text'"
                    :placeholder="
                        filter.label ? $t(filter.label) : $t(filter.id)
                    "
                    class="form-control"
                    :id="filter.id"
                    v-model="filterElements[filter.id]"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            filterElements: {},
        };
    },
    props: {
        filters: {
            type: Array,
            required: true,
            description:
                "Search Filters options like id , types , how many, etc...",
        },
    },
    watch: {
        filterElements: {
            deep: true,
            handler() {
                this.$emit("search", this.filterElements);
            },
        },
    },
};
</script>

<style></style>
