<template>
    <div>
        <div>
            <el-tooltip :content="$t('invoice.title')" placement="top">
                <badge
                    circle
                    size="lg"
                    class="bg-sky-dark"
                    style="cursor: pointer"
                    @click="viewInvoiceModal"
                >
                    <img src="@/assets/svg/invoice.svg" class="h-small" />
                </badge>
            </el-tooltip>
        </div>

        <modal
            footerClasses="justify-content-center"
            :show="showInvoiceModal"
            @close="showInvoiceModal = false"
            size="lg"
            body-classes=""
            headerClasses="border-bottom-2"
        >
            <template v-slot:header>
                <div>
                    <div class="font-weight-700 text-lg text-center mt-2 mb-1">
                        {{ $t("invoice.title") }}
                    </div>
                </div>
            </template>
            <div class="px-3 d-block">
                <div class="row" style="border-bottom: 1px solid">
                    <div class="col-md-3">
                        <label for="">{{
                            $t("invoice.invoice_tax_number")
                        }}</label>

                        <h2 class="text-break font-weight-900">
                            {{ "302251248900003" }}
                        </h2>
                    </div>

                    <div class="col-md-3">
                        <label for="">{{ $t("invoice.invoice_no") }}</label>

                        <h2 class="text-break font-weight-900">
                            {{ order.invoice ? order.invoice.invoice_no : "" }}
                        </h2>
                    </div>

                    <div class="col-md-3">
                        <label for="">{{ $t("invoice.invoice_client") }}</label>
                        <div
                            v-if="
                                order.source == 'zidship_order' &&
                                (order.zidship_invoice_name != null ||
                                    order.zidship_invoice_mobile_no != null)
                            "
                        >
                            <h2 class="text-break font-weight-900">
                                {{ order.zidship_invoice_name }}
                            </h2>
                            <h2 class="text-break font-weight-900">
                                {{
                                    "+ " +
                                        order.zidship_invoice_country_code +
                                        " " +
                                        order.zidship_invoice_mobile_no || ""
                                }}
                            </h2>
                        </div>
                        <div v-else>
                            <h2
                                v-if="order.customer != null"
                                class="text-break font-weight-900"
                            >
                                {{ order.customer?.name }}
                            </h2>
                            <h2 class="text-break font-weight-900">
                                {{
                                    "+ " +
                                        order.customer?.country_code +
                                        " " +
                                        order.customer?.mobile_no || ""
                                }}
                            </h2>
                        </div>
                    </div>
                </div>

                <div class="row pt-2" style="border-bottom: 1px solid">
                    <div class="col-md-6">
                        <label for="">{{
                            $t("invoice.invoice_service")
                        }}</label>
                        <h2 class="text-break font-weight-900">
                            {{
                                $store.getters.getLang == "ar"
                                    ? order.vehicle_type != null
                                        ? order.vehicle_type.type_ar
                                        : ""
                                    : order.vehicle_type != null
                                    ? order.vehicle_type.type
                                    : ""
                            }}
                            {{ $t("vehicle") }}
                        </h2>
                    </div>
                    <div class="col-md-6">
                        <label for="">{{
                            $t("invoice.invoice_trip_detail")
                        }}</label>
                        <h2 class="text-break font-weight-900">
                            {{ order.customer_notes || "-" }}
                        </h2>
                    </div>

                    <div class="col-md-6">
                        <label for="">{{ $t("from") }}</label>
                        <h2 class="text-break font-weight-900">
                            {{
                                order.type == "Store_pickup" &&
                                order.pickup_store_id != null
                                    ? order?.pickup_store.name
                                    : order?.pickup_address?.address_line1
                            }}
                        </h2>
                    </div>
                    <div class="col-md-6">
                        <label for="">{{ $t("to") }}</label>
                        <h2 class="text-break font-weight-900">
                            {{ order?.dropoff_address?.address_line1 }}
                        </h2>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <div class="row pt-2">
                            <div class="col-md-6">
                                <label for="">{{
                                    $t("invoice.sub_total")
                                }}</label>
                            </div>

                            <div class="col-md-6">
                                <h2 class="text-break font-weight-900">
                                    {{
                                        order.invoice
                                            ? order.invoice.sub_total
                                            : 0
                                    }}
                                    {{ $t("SAR") }}
                                </h2>
                            </div>
                        </div>
                        <div
                            class="row pt-2"
                            v-if="
                                order.invoice &&
                                order.invoice.assembly_charge > 0
                            "
                        >
                            <div class="col-md-6">
                                <label for="">{{
                                    $t("assembly_charge")
                                }}</label>
                            </div>

                            <div class="col-md-6">
                                <h2 class="text-break font-weight-900">
                                    {{ order.invoice.assembly_charge }}
                                    {{ $t("SAR") }}
                                </h2>
                            </div>
                        </div>
                        <div
                            class="row"
                            v-if="order.invoice && order.invoice.discount > 0"
                        >
                            <div class="col-md-6">
                                <label for="">{{ $t("discount") }}</label>
                            </div>
                            <div class="col-md-6">
                                <h2 class="text-break font-weight-900">
                                    {{ order.invoice.discount }}
                                    {{ $t("SAR") }}
                                </h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="">{{ $t("invoice.vat") }}</label>
                            </div>
                            <div class="col-md-6">
                                <h2 class="text-break font-weight-900">
                                    {{ order.invoice ? order.invoice.vat : 0 }}
                                    {{ $t("SAR") }}
                                </h2>
                            </div>
                        </div>
                        <!-- <div
                            class="row"
                            v-if="order?.invoice?.order_cancel_price"
                        >
                            <div class="col-md-6">
                                <label for="">{{
                                    $t("cancelOrderPrice")
                                }}</label>
                            </div>
                            <div class="col-md-6">
                                <h2 class="text-break font-weight-900">
                                    {{
                                        order?.invoice?.order_cancel_price
                                            ? order?.invoice?.order_cancel_price
                                            : 0
                                    }}
                                    {{ $t("SAR") }}
                                </h2>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-md-6">
                                <label for=""> {{ $t("total") }}</label>
                            </div>
                            <div class="col-md-6">
                                <h2 class="text-break font-weight-900">
                                    {{ order.total }} {{ $t("SAR") }}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-slot:footer>
                <div class="w-full text-center mt-3">
                    <base-button
                        v-if="
                            order.status == 'Completed' ||
                            order.status == 'Cancelled'
                        "
                        native-type="submit"
                        class="my-4"
                        :loading="downloadInvoiceSpinner"
                        @click="downloadInvoice"
                    >
                        {{ $t("invoice_download_btn") }}
                    </base-button>
                    <!-- {{ order.status }} -->
                    <button
                        class="btn btn-default text-white"
                        @click="showInvoiceModal = false"
                    >
                        {{ $t("close") }}
                    </button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import mixin from "@/mixins/common";

export default {
    data() {
        return {
            showInvoiceModal: false,
            downloadInvoiceSpinner: false,
        };
    },

    created() {},

    mixins: [mixin],

    props: ["order"],

    methods: {
        downloadInvoice() {
            if (this.order?.invoice?.url) {
                window.open(this.order.invoice.url, "_blank");
            }
        },

        viewInvoiceModal() {
            this.showInvoiceModal = true;
        },
    },
};
</script>
