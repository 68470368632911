import List from "@/views/Pages/VehicleType/List";
import Upsert from "@/views/Pages/VehicleType/Upsert";

export default [
    {
        path: "vehicle-type",
        components: { default: List },
        name: "VehicleType List",
        meta: {
            title: "vehicle_type",
            role: [1],
        },
    },
    {
        path: "vehicle-type/:id",
        components: { default: Upsert },
        props: true,
        name: "VehicleType Upsert",
        meta: {
            title: "vehicle_type",
            role: [1],
        },
    },
];
