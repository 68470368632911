import List from "@/views/Pages/CreditHistory/List";
import FineList from "@/views/Pages/CreditHistory/FineList";
import BonusList from "@/views/Pages/CreditHistory/BonusList";
import CompensationList from "@/views/Pages/CreditHistory/CompensationList";

export default [{
        path: "users/:userId/wallet-histories",
        props: true,
        components: { default: List },
        name: "Wallet History List",
        meta: {
            title: "wallet_history",
            role: [1, 6, 8],
        },
    },
    {
        path: "compensation",
        components: { default: CompensationList },
        name: "Compensation List",
        meta: {
            title: "compensation",
            role: [1, 6],
        },
    },
    {
        path: "fine",
        components: { default: FineList },
        name: "Fine List",
        meta: {
            title: "fine",
            role: [1, 6],
        },
    },
    {
        path: "bonus",
        components: { default: BonusList },
        name: "Bonus List",
        meta: {
            title: "bonus",
            role: [1],
        },
    },
];