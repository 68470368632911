<template>
    <div>
        <hr />
        <div class="card py-3 px-5 rounded-md">
            <div
                class="border-0 card-header px-0 d-flex justify-content-between align-items-center"
            >
                <button
                    class="btn btn-default text-white btn-sm"
                    @click="vehicleModelModal()"
                >
                    <i class="fas fa-plus-circle fa-lg text-white mr-2">
                        <span>{{ $t("addVehicleModel") }}</span>
                    </i>
                </button>
            </div>

            <!-- Filter Section Start -->
            <div class="d-flex mb-4">
                <search-filter
                    :filters="[
                        {
                            id: 'name',
                        },
                    ]"
                    @search="(searchObject) => (filters = searchObject)"
                />
            </div>
            <!-- Filter Section End  -->

            <div class="row">
                <main
                    :class="
                        isFilter
                            ? 'col-md-9 col-xs-11 p-l-2 p-t-2'
                            : 'col-md-12 col-xs-11 p-l-2 p-t-2'
                    "
                >
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="vehicleModels"
                            v-loading="isLoading"
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('vehicle_type')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{
                                            isAr
                                                ? row.vehicle_brand
                                                      ?.vehicle_type?.type_ar
                                                : row.vehicle_brand
                                                      ?.vehicle_type?.type
                                        }}</span
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('vehicle_brand')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{
                                            isAr
                                                ? row.vehicle_brand?.name_ar
                                                : row?.vehicle_brand.name
                                        }}</span
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('name')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-nowrap"
                                        >{{ row.name }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('name_ar')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">{{
                                        $t(row.name_ar)
                                    }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('status')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <custom-label
                                        :label="row.status"
                                    ></custom-label>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('actions')"
                                min-width="210px"
                            >
                                <template v-slot="{ row }">
                                    <div class="d-flex justify-content-between">
                                        <!-- Edit Vehicle Type -->
                                        <el-tooltip
                                            :content="$t('edit')"
                                            placement="top"
                                        >
                                            <badge
                                                @click="vehicleModelModal(row)"
                                                circle
                                                size="lg"
                                                class="bg-sky-dark text-secondary cursor-pointer"
                                                :title="$t('edit')"
                                                style="cursor: pointer"
                                            >
                                                <i class="fas fa-pen mx-1"></i>
                                            </badge>
                                        </el-tooltip>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="vehicleModels.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
            <!-- Start of Send Notification Modal -->
            <!-- Modal  -->
            <modal
                :show="showModal"
                @close="showModal = false"
                size="sm"
                body-classes="p-0"
                v-loading="isModelLoading"
            >
                <template v-slot:header>
                    <div class="text-bold text-lg text-center mt-2 mb-1">
                        {{ addOrEdit }}
                    </div>
                </template>
                <div class="px-3 d-block">
                    <Form
                        ref="addVehicleModelRef"
                        @submit="addEditVehicleModel"
                    >
                        <Field
                            name="vehicle_type_id"
                            v-model="form.vehicle_type_id"
                            v-slot="{ field }"
                            :label="$t('vehicle_type')"
                            rules="required"
                        >
                            <label for="" class="d-block">{{
                                $t("vehicle_type")
                            }}</label>
                            <el-select
                                v-bind="field"
                                v-model="form.vehicle_type_id"
                                :placeholder="$t('select')"
                                name="vehicle_type_id"
                                filterable
                                style="width: 100% !important"
                                @change="getVehicleBrands(true)"
                            >
                                <el-option
                                    v-for="option in vehicleTypes"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="isAr ? option.type_ar : option.type"
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>
                            <ErrorMessage
                                name="vehicle_type_id"
                                class="error"
                            />
                        </Field>

                        <Field
                            name="vehicle_brand_id"
                            v-model="form.vehicle_brand_id"
                            v-slot="{ field }"
                            :label="$t('vehicle_brand')"
                            rules="required"
                        >
                            <label for="" class="d-block">{{
                                $t("vehicle_brand")
                            }}</label>
                            <el-select
                                v-bind="field"
                                v-model="form.vehicle_brand_id"
                                :placeholder="$t('select')"
                                name="vehicle_brand_id"
                                filterable
                                style="width: 100% !important"
                            >
                                <el-option
                                    v-for="option in vehicleBrands"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="isAr ? option.name_ar : option.name"
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>
                            <ErrorMessage
                                name="vehicle_brand_id"
                                class="error"
                            />
                        </Field>

                        <label for="" class="my-1">{{ $t("name") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            :label="$t('name')"
                            class="form-control"
                            :placeholder="$t('name')"
                            name="name"
                            value="test"
                            v-model="form.name"
                        />
                        <ErrorMessage name="name" class="error" />

                        <label for="" class="my-1">{{ $t("name_ar") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            :label="$t('name_ar')"
                            class="form-control"
                            :placeholder="$t('name_ar')"
                            name="name_ar"
                            value="test 1"
                            v-model="form.name_ar"
                        />
                        <ErrorMessage name="name_ar" class="error" />

                        <label for="" class="my-1">{{ $t("status") }}</label>
                        <Field
                            v-slot="{ field }"
                            name="status"
                            rules="required"
                            :label="$t('status')"
                            v-model="form.status"
                        >
                            <el-select
                                class="d-block"
                                name="status"
                                v-bind="field"
                                :placeholder="$t('select')"
                                v-model="form.status"
                                filterable
                            >
                                <el-option
                                    v-for="option in defaultOptions.vehicleModelStatus"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="$t(option.label)"
                                    :key="option.label"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage name="status" class="error" />

                        <div class="w-full text-center mt-3 mb-4">
                            <base-button
                                :loading="showButtonLoader"
                                native-type="submit"
                                class="text-white bg-primary"
                            >
                                {{ $t("submit") }}
                            </base-button>
                            <input
                                type="button"
                                class="btn btn-default text-white"
                                @click="
                                    $refs.addVehicleModelRef.resetForm();
                                    $emit('close');
                                    vehicleModelModalClose();
                                "
                                :value="$t('close')"
                            />
                        </div>
                    </Form>
                </div>
            </modal>
            <!-- End of Send Notification Modal -->
        </div>
        <!--  End of Table -->
    </div>
</template>

<script>
import {
    addVehicleModel,
    getVehicleModels,
    editVehicleModel,
} from "@/api/vehicleModel";
import mixin from "@/mixins/common";
import { cloneDeep } from "lodash-es";
import { getVehicleTypes } from "@/api/vehicleType";
import { getVehicleBrands } from "@/api/vehicleBrand";

export default {
    data() {
        return {
            vehicleModels: [],
            currentPage: 1,
            filters: {
                name: null,
            },
            form: {
                vehicle_type_id: "",
                vehicle_brand_id: "",
                name: "",
                name_ar: "",
                status: "active",
            },
            showButtonLoader: false,
            showModal: false,
            isLoading: false,

            isFilter: false,
            vehicleTypes: [],
            isAr: localStorage.getItem("lang") == "ar",
            id: null,
            addOrEdit: null,
            vehicleBrands: [],
            isModelLoading: false,
            editData: {},
            vehicle_brand_id: null,
        };
    },
    mixins: [mixin],
    created() {
        this.getVehicleModels();
    },
    watch: {
        currentPage: {
            handler: function () {
                this.getVehicleModels();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.currentPage = 1;
                this.getVehicleModels();
            },
        },
    },
    methods: {
        addEditVehicleModel(data) {
            this.showButtonLoader = true;
            if (this.id == null) {
                addVehicleModel(data)
                    .then(() => {
                        this.showButtonLoader = false;

                        this.getVehicleModels();
                        this.runToast(
                            this.$t("vehicleModelAddedSuccess"),
                            "success"
                        );
                        this.$refs.addVehicleModelRef.resetForm();
                        this.vehicleModelModalClose();
                    })
                    .catch(({ response }) => {
                        this.showButtonLoader = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            } else {
                editVehicleModel(this.id, data)
                    .then(() => {
                        this.showButtonLoader = false;
                        this.getVehicleModels();
                        this.runToast(
                            this.$t("vehicleModelUpdateSuccess"),
                            "success"
                        );
                        this.id = null;
                        this.$refs.addVehicleModelRef.resetForm();
                        this.vehicleModelModalClose();
                    })
                    .catch(({ response }) => {
                        this.showButtonLoader = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            }
        },
        vehicleModelModal(data = null) {
            this.showModal = true;

            if (data != null) {
                this.id = data.id;
                this.form = data;
                this.addOrEdit = this.$t("editVehicleModel");
                this.form.vehicle_type_id = data.vehicle_brand?.vehicle_type_id;
                this.form = cloneDeep(data);
                this.getVehicleBrands();
                this.data = data;
                this.vehicle_brand_id = data.vehicle_brand_id;
            } else {
                this.addOrEdit = this.$t("addVehicleModel");
                this.id = null;
                this.vehicle_brand_id = null;
            }

            this.isModelLoading = true;
            getVehicleTypes({
                limit: -1,
            })
                .then((res) => {
                    this.vehicleTypes = res.data;
                    this.isModelLoading = false;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        vehicleModelModalClose() {
            this.form = {
                vehicle_type_id: "",
                vehicle_brand_id: "",
                name: "",
                name_ar: "",
                status: "active",
            };
            this.showModal = false;
            this.vehicle_brand_id = null;
        },
        getVehicleModels() {
            this.isLoading = true;
            getVehicleModels({
                include: "vehicle_brand.vehicle_type",
                page: this.currentPage,
                filter: this.filters,
                query: { ...this.filters },
            })
                .then((res) => {
                    this.vehicleModels = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getVehicleBrands(isChangeFromField = false) {
            if (this.id == null || isChangeFromField) {
                this.form.vehicle_brand_id = "";
            }
            getVehicleBrands({
                limit: -1,
                filter: {
                    vehicle_type_id: this.form.vehicle_type_id,
                },
            })
                .then((res) => {
                    this.vehicleBrands = res.data;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                name: "",
            };
        },
    },
};
</script>
