import List from "@/views/Pages/OrderReport/List";

export default [{
    path: "order-reports",
    components: { default: List },
    name: "OrderReport List",
    meta: {
        title: "order_report",
        role: [1],
    },
}
];