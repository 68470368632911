import axiosInstance from "@/plugins/customAxios";

export const get = (params = null) => {
    return axiosInstance
        .get("v1/pickup-stores", { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getStore = (id, params = null) => {
    return axiosInstance
        .get(`v1/pickup-stores/${id}`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const edit = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/pickup-stores/${id}?_method=PATCH`, data, {
            params: params,
        })
        .then((res) => {
            return res.data;
        });
};

export const add = (data, params = null) => {
    return axiosInstance
        .post(`v1/pickup-stores`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};