import $i18n from "@/plugins/lang/i18n.js";

export default {
    userStatus: [{
        label: "approved",
        value: "approved",
    },
    {
        label: "pending",
        value: "pending",
    },
    {
        label: "rejected",
        value: "rejected",
    },
    {
        label: "inactive",
        value: "inactive",
    },
    {
        label: "suspended",
        value: "suspended",
    },
    ],
    offerStatus: [{
        label: "opened",
        value: "opened",
    },
    {
        label: "accepted",
        value: "accepted",
    },
    {
        label: "rejected",
        value: "rejected",
    },
    ],
    paymentStatus: [{
        label: "pending",
        value: "pending",
    },
    {
        label: "paid",
        value: "paid",
    },
    {
        label: "failed",
        value: "failed",
    },
    {
        label: "initiated",
        value: "initiated",
    },
    ],
    orderStatus: [{
        label: "requested",
        value: "requested",
    },
    {
        label: "confirmed",
        value: "confirmed",
    },
    {
        label: "accepted",
        value: "accepted",
    },
    {
        label: "on_the_way",
        value: "on_the_way",
    },
    {
        label: "picked_up",
        value: "picked_up",
    },
    {
        label: "dropped_off",
        value: "dropped_off",
    },
    {
        label: "completed",
        value: "completed",
    },
    {
        label: "cancelled",
        value: "cancelled",
    },
    {
        label: "returned",
        value: "returned",
    },
    {
        label: "on_hold",
        value: "on_hold"
    }
    ],
    orderTypeList: [{
        label: "orders",
        value: "normal",
    },
    {
        label: "packages",
        value: "package",
    },
    ],
    status: [{
        label: "active",
        value: "active",
    },
    {
        label: "inactive",
        value: "inactive",
    },
    ],
    complaintStatus: [{
        label: "pending",
        value: "pending",
    },
    {
        label: "completed",
        value: "completed",
    },
    ],
    complaintReason: [{
        label: "no_response",
        value: "no_response",
    },
    {
        label: "lateness",
        value: "lateness",
    },
    {
        label: "payment_issue",
        value: "payment_issue",
    },
    {
        label: "low_rating",
        value: "low_rating",
    },
    {
        label: "other",
        value: "other",
    },
    ],
    notificationForOptions: [{
        label: "provider",
        value: "provider",
    },
    {
        label: "specific_user",
        value: "specific_user",
    },
    {
        label: "customer_provider",
        value: "customer_provider",
    },
    {
        label: "customer",
        value: "customer",
    },
    ],
    stcUpdateOption: [{
        label: "all",
        value: null,
    },
    {
        label: "true",
        value: true,
    },
    {
        label: "false",
        value: false,
    },
    ],
    storePaymentTypeOptions: [{
        label: "customer_payment",
        value: "customer_payment",
    },
    {
        label: "store_credit",
        value: "store_credit",
    },
    ],
    vehicleTypeStatus: [{
        label: "active",
        value: "active",
    },
    {
        label: "inactive",
        value: "inactive",
    },
    ],
    vehicleBrandStatus: [{
        label: "active",
        value: "active",
    },
    {
        label: "inactive",
        value: "inactive",
    },
    ],
    vehicleModelStatus: [{
        label: "active",
        value: "active",
    },
    {
        label: "inactive",
        value: "inactive",
    },
    ],
    shipmentTypeStatus: [{
        label: "active",
        value: "active",
    },
    {
        label: "inactive",
        value: "inactive",
    },
    ],
    storeCreditPaymentMethod: [{
        label: "mada",
        value: "mada",
    },
    {
        label: "bank_transaction",
        value: "bank_transaction",
    },
    {
        label: "credit_add_by_admin",
        value: "credit_add_by_admin"
    }
    ],
    providerCredit: [{
        label: "Pay to provider",
        value: "pay_to_provider",
    },
    {
        label: "Pay to one8",
        value: "pay_to_one8",
    },
    ],
    storeSource: [{
        label: "one8_store",
        value: "one8_store",
    },
    {
        label: "zidship_store",
        value: "zidship_store",
    },
    ],
    storeStatus: [{
        label: "active",
        value: "active",
    },
    {
        label: "pending",
        value: "pending",
    },
    {
        label: "inactive",
        value: "inactive",
    },
    ],
    allowBusinessOrderOption: [{
        label: "all",
        value: null,
    },
    {
        label: "true",
        value: 1,
    },
    {
        label: "false",
        value: 0,
    },
    ],
    // Cancellation order admin reason
    cancelOrderReason: [
        {
            label: "customer_need_change_location",
            value: "customer_need_change_location",
        }, {
            label: "not_available_service",
            value: "not_available_service",
        }, {
            label: "outside_coverage",
            value: "outside_coverage",
        }, {
            label: "too_expensive",
            value: "too_expensive",
        }, {
            label: "item_bigger_than_one8",
            value: "item_bigger_than_one8",
        }, {
            label: "customer_like_order_another_time",
            value: "customer_like_order_another_time",
        }, {
            label: "exceeded_order_time",
            value: "exceeded_order_time",
        }, {
            label: "other",
            value: "other",
        }
    ],
    // All cancellation order reason
    cancelOrderAllReason: [
        {
            label: "customer_need_change_location",
            value: "customer_need_change_location",
        }, {
            label: "not_available_service",
            value: "not_available_service",
        }, {
            label: "outside_coverage",
            value: "outside_coverage",
        }, {
            label: "too_expensive",
            value: "too_expensive",
        }, {
            label: "item_bigger_than_one8",
            value: "item_bigger_than_one8",
        }, {
            label: "customer_like_order_another_time",
            value: "customer_like_order_another_time",
        }, {
            label: "exceeded_order_time",
            value: "exceeded_order_time",
        }, {
            label: "trying_the_app",
            value: "trying_the_app",
        },
        {
            label: "would_like_order_another_time",
            value: "would_like_order_another_time",
        }, {
            label: "edit_my_order",
            value: "edit_my_order",
        }, {
            label: "not_received_offer",
            value: "not_received_offer",
        }, {
            label: "other",
            value: "other",
        }
    ],
    couponCodeVisibility: [
        {
            label: "public",
            value: "public"
        },
        {
            label: "private",
            value: "private"
        },
        {
            label: "automatic",
            value: "automatic"
        },
    ],
    one8OrderType: [
        {
            label: "express_order",
            value: "normal"
        }, {
            label: "store_pickup_order",
            value: "store_pickup"
        }, {
            label: "store_express_order",
            value: "store_normal"
        }, {
            label: "store_package_order",
            value: "package"
        }
    ],
    normalOrderType: [
        {
            label: "active_order",
            value: "active_order"
        }, {
            label: "in_active_order",
            value: "in_active_order"
        }
    ],
    sendNotificationType: [
        {
            label: "send_sms_message",
            value: "send_sms_message"
        }, {
            label: "send_whatsapp_message",
            value: "send_whatsapp_message"
        }, {
            label: "send_notification",
            value: "send_notification"
        }
    ]
};
