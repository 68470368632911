<template>
    <tabs
        fill
        class="flex-column flex-md-row"
        tabNavClasses="mb-3 mt-4"
        @tabClicked="tabClicked"
    >
        <tab-pane :title="$t('all_statuses')" :id="''" class="bg-white">
        </tab-pane>
        <tab-pane
            v-for="status in defaultOptions[statusCategory]"
            :key="status.value"
            :title="$t(status.label)"
            :id="status.value"
            class="bg-white"
        >
        </tab-pane>
    </tabs>
</template>

<script>
import mixin from "@/mixins/common";

export default {
    props: {
        statusCategory: {
            type: String,
            required: true,
            description:
                "Status Filters Category , like Order Status , Complaint Status etc..",
        },
    },
    methods: {
        tabClicked(status) {
            this.$emit("statusChanged", status);
        },
    },
    mixins: [mixin],
};
</script>

<style></style>
