<template>
    <div>
        <hr />
        <div class="">
            <div class="card mb-0 p-5 rounded-md">
                <div class="d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap mb-4">
                    <div class="d-flex align-items-center">
                        <el-checkbox 
                            v-model="selectAll" 
                            @change="handleSelectAll"
                            :disabled="providerCredits.length === 0"
                            class="mx-3"
                        >
                            {{ $t('select_all_on_page') }}
                        </el-checkbox>
                    </div>

                    <div v-if="selectedProviders.length > 0" class="mt-2 mt-md-0">
                        <button
                            class="text-white btn btn-success btn-sm"
                            :class="{ 'btn-responsive': true }"
                            @click="showBulkTransferModal"
                            :disabled="isPaymentLocked"
                        >
                            {{ $t('bulk_transfer_credit') }} ({{ selectedProviders.length }})
                            <span v-if="isPaymentLocked">({{ countdownTimer }}s)</span>
                        </button>
                    </div>
                </div>


                <!-- Filter Section -->
                <div class="d-flex mb-4">
                    <div class="d-flex" style="gap: 20px">
                        <div>
                            <Field
                                type="text"
                                :placeholder="$t('name')"
                                class="form-control"
                                name="name"
                                v-model="filters.name"
                            />
                        </div>
                        <div>
                            <Field
                                :placeholder="$t('email')"
                                type="text"
                                class="form-control"
                                name="email"
                                v-model="filters.email"
                            />
                        </div>
                        <div>
                            <Field
                                :placeholder="$t('mobile_no')"
                                type="number"
                                class="form-control"
                                name="mobile_no"
                                v-model="filters.mobile_no"
                            />
                        </div>
                        <div>
                            <Field
                                v-slot="{ field }"
                                v-model="filters.stc_updated"
                                name="stc_updated"
                                rules="required"
                                :label="$t('stc_updated')"
                            >
                                <el-select
                                    class="d-block"
                                    name="stc_updated"
                                    v-bind="field"
                                    v-model="filters.stc_updated"
                                    :placeholder="$t('stc_updated')"
                                >
                                    <el-option
                                        v-for="option in stcUpdateOption"
                                        class="select-danger"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="option.label"
                                    >
                                    </el-option>
                                </el-select>
                            </Field>
                        </div>
                        <div>
                            <Field
                                v-slot="{ field }"
                                v-model="filters.has_service_provider_company"
                                name="has_service_provider_company"
                                rules="required"
                                :label="$t('has_service_provider_company')"
                            >
                                <el-select
                                    class="d-block"
                                    name="has_service_provider_company"
                                    v-bind="field"
                                    v-model="filters.has_service_provider_company"
                                    :placeholder="$t('has_service_provider_company')"
                                >
                                    <el-option
                                        v-for="option in serviceProviderOption"
                                        class="select-danger"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="option.label"
                                    >
                                    </el-option>
                                </el-select>
                            </Field>
                        </div>
                    </div>
                </div>

                <!-- Table Section -->
                <div class="row">
                    <main
                        :class="
                            isFilter
                                ? 'col-md-9 col-xs-11  p-t-2'
                                : 'col-md-12 col-xs-11  p-t-2'
                        "
                    >
                        <div>
                            <el-table
                                ref="table"
                                class="table-responsive table-flush"
                                header-row-class-name="thead-light"
                                :data="providerCredits"
                                cell-class-name="text-break"
                                v-loading="isLoading"
                                :empty-text="$t('no_data_found')"
                                @selection-change="handleSelectionChange"
                            >
                                <el-table-column
                                    type="selection"
                                    width="55">
                                </el-table-column>
                                <el-table-column
                                    :label="$t('name')"
                                    min-width="150px"
                                >
                                    <template v-slot="{ row }">
                                        <badge
                                            size="lg"
                                            class="bg-sky-dark cell p-2"
                                            style="cursor: pointer"
                                            @click="viewProviderDetail(row.id)"
                                        >
                                            <span class="mb-0 font-weight-600 name text-capitalize">{{ row.name }}</span>
                                        </badge>
                                    </template>
                                </el-table-column>
                                
                                <el-table-column
                                    :label="$t('email')"
                                    min-width="200px"
                                    prop="email"
                                >
                                    <template v-slot="{ row }">
                                        <span class="mb-0 font-weight-600 name text-nowrap">{{ row.email }}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('mobile_no')"
                                    min-width="160px"
                                >
                                    <template v-slot="{ row }">
                                        <span class="mb-0 font-weight-600 name">
                                            +{{ row.country_code + " " }}{{ row.mobile_no }}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('credit')"
                                    min-width="180px"
                                    prop="credit"
                                >
                                    <template v-slot="{ row }">
                                        <span class="mb-0 font-weight-600 name">{{ row.order_credit }}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('stc_updated')"
                                    min-width="150px"
                                    prop="stcUpdated"
                                >
                                    <template v-slot="{ row }">
                                        <custom-label :label="row.stc_updated" />
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('stcpay_mobile_no')"
                                    min-width="180px"
                                    prop="stcpay_mobile_no"
                                >
                                    <template v-slot="{ row }">
                                        <span class="mb-0 font-weight-600 name" v-if="row?.stcpay_mobile_no">
                                            +{{ row.country_code + " " }}
                                        </span>
                                        <span class="mb-0 font-weight-600 name">{{ row.stcpay_mobile_no }}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('actions')"
                                    min-width="210px"
                                >
                                    <template v-slot="{ row }">
                                        <el-tooltip
                                            :content="$t('wallet_history')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark text-secondary cursor-pointer"
                                                @click="viewProviderWalletHistory(row.id)"
                                            >
                                                <i class="fas fa-wallet mx-1"></i>
                                            </badge>
                                        </el-tooltip>
                                        <CreditTransferModal
                                            :id="row.id"
                                            :currentRow="row"
                                            :title="$t('transfer_credit')"
                                            :disabled="isPaymentLocked"
                                            @creditTransferModalCompleted="getProviderCredits"
                                        >
                                            <template v-if="isPaymentLocked" #default>
                                                <div class="text-danger">
                                                    {{ $t('payment_locked') }} ({{ countdownTimer }}s)
                                                </div>
                                            </template>
                                        </CreditTransferModal>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>

                        <div
                            class="py-4 card-footer"
                            v-if="providerCredits?.length > 0"
                        >
                            <base-pagination
                                :meta="meta"
                                v-model="currentPage"
                                :total="totalData"
                            ></base-pagination>
                        </div>
                    </main>
                </div>
            </div>
            <!-- Bulk Transfer Modal Component -->
            <BulkTransferModal
                v-if="showBulkModal"
                v-model:show="showBulkModal"
                :selected-providers="selectedProviders"
                :selection-mode="selectionMode"
                @transfer-completed="handleTransferCompleted"
            />

        </div>
    </div>
</template>

<script>
import { getUsers } from "@/api/users";
import mixin from "@/mixins/common";
import { paymentLockMixin } from '@/mixins/paymentLockMixin';
import CreditTransferModal from "@/components/common/CreditTransferModal.vue";
import BulkTransferModal from "@/components/common/BulkTransferModal.vue";

export default {
    data() {
        return {
            providerCredits: [],
            isLoading: false,
            currentPage: 1,
            params: {
                role: "provider",
            },
            totalData: null,
            currentRow: {},
            isFilter: false,
            filters: {
                name: "",
                email: "",
                mobile_no: null,
                stc_updated: "",
                has_service_provider_company: "",
            },
            stcUpdateOption: [
                { value: null, label: this.$t("all") },
                { value: true, label: this.$t("true") },
                { value: false, label: this.$t("false") },
            ],
            serviceProviderOption: [
                { value: null, label: this.$t("all") },
                { value: true, label: this.$t("has_service_provider_company") },
                { value: false, label: this.$t("independent") },
            ],
            meta: null,
            selectedProviders: [],
            selectAll: false,
            showBulkModal: false,
            selectionMode: 'page'
        };
    },

    mixins: [mixin, paymentLockMixin],

    emits: ['update:showBulkModal', 'transfer-completed'],

    components: {
        CreditTransferModal,
        BulkTransferModal
    },

    watch: {
        currentPage: {
            handler() {
                this.getProviderCredits();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.currentPage = 1;
                this.getProviderCredits();
            },
        },
    },

    created() {
        this.getProviderCredits();
    },

    methods: {
        async getProviderCredits() {
            this.isLoading = true;
            try {
                const response = await getUsers("admin", {
                    limit: 10,
                    ascending: 0,
                    page: this.currentPage,
                    byColumn: 1,
                    ...this.params,
                    filter: {
                        ...this.filters,
                        order_credit_available: true,
                    },
                });
                
                this.providerCredits = response.data;
                this.meta = response.meta;
                this.totalData = response.meta.total;
            } catch (error) {
                this.apiError(error.response);
            } finally {
                this.isLoading = false;
            }
        },

        handleSelectAll(val) {
            if (val) {
                this.$refs.table.toggleAllSelection();
            } else {
                this.$refs.table.clearSelection();
            }
        },

        handleSelectionChange(val) {
            this.selectedProviders = val;
            this.selectAll = val.length === this.providerCredits.length;
        },

        showBulkTransferModal() {
            if (this.selectedProviders.length === 0) {
                this.runToast(this.$t('please_select_providers'), 'error');
                return;
            }
            this.showBulkModal = true;
        },

        handleTransferCompleted() {
            this.selectedProviders = [];
            this.selectAll = false;
            this.getProviderCredits();
        },

        viewProviderDetail(id) {
            this.$router.push({ name: "Provider Details", params: { id } });
        },

        viewProviderWalletHistory(userId) {
            let routeData = this.$router.resolve({
                name: "Wallet History List",
                params: { userId },
            });
            window.open(routeData.href, "_blank");
        }
    },
};
</script>
<style>
    .btn-responsive {
        font-size: 1rem;
    }

    @media (max-width: 768px) {
        .btn-responsive {
            font-size: 0.8rem;
        }
    }

    @media (max-width: 576px) {
        .btn-responsive {
            font-size: 0.7rem;
        }
    }
</style>
