import axiosInstance from "@/plugins/customAxios";

export const getVehicleBrands = (params = null) => {
    return axiosInstance
        .get("v2/vehicle-brands", { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getVehicleBrand = (id, params = null) => {
    return axiosInstance
        .get(`v2/admin/vehicle-brands/${id}`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const editVehicleBrand = (id, data, params = null) => {
    return axiosInstance
        .put(`v2/admin/vehicle-brands/${id}`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};
export const addVehicleBrand = (data) => {
    return axiosInstance
        .post("v2/admin/vehicle-brands", data)
        .then((res) => {
            return res.data;
        });
};