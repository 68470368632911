import Add from "@/views/Pages/Provider/Add";
import List from "@/views/Pages/Provider/List";
import View from "@/views/Pages/Provider/View";
import Upsert from "@/views/Pages/Provider/Upsert";

export default [
    {
        path: "providers",
        components: { default: List },
        name: "Provider List",
        meta: {
            title: "provider",
            role: [1, 6, 8, 9],
        },
    },
    {
        path: "provider/add",
        components: { default: Add },
        name: "Provider Add",
        meta: {
            title: "add_provider",
            role: [9],
        },
    },
    {
        path: "provider/:id",
        components: { default: View },
        props: true,
        name: "Provider Details",
        meta: {
            title: "provider",
            role: [1, 6, 8, 9],
        },
    },
    {
        path: "provider/:id/edit",
        components: { default: Upsert },
        props: true,
        name: "Provider Update",
        meta: {
            title: "provider_update",
            role: [1, 6, 8, 9],
        },
    },
];
