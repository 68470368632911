<template>
    <div>
        <hr />
        <div class="row user-setting">
            <div class="col-md-9">
                <tabs fill class="flex-column flex-md-row bg-white rounded-md p-3 px-4" tabNavClasses="mb-3">
                    <card class="px-0">
                        <!-- User Profile Start -->
                        <tab-pane :title="$t('profile')" class="bg-white border-default">
                            <h1>
                                {{ $t("profile_information") }}
                            </h1>
                            <div class="m-3">
                                <div class="mt-3 row" v-if="hasRole(4) || hasRole(5)">
                                    <div class="w-100">
                                        <label>
                                            <b>{{ $t("joined_date") }}</b> :
                                            {{
                                                $store.getters.user.joined_date
                                            }}
                                        </label>
                                    </div>
                                </div>
                                <Form @submit="updateUserSetting">
                                    <div class="mt-3 row">
                                        <div class="w-100">
                                            <label for="name">{{
                                                $t("name")
                                            }}</label>
                                            <Field type="text" rules="required" class="form-control"
                                                :placeholder="$t('name')" :label="$t('name')" name="name"
                                                v-model="user.name" />
                                            <ErrorMessage name="name" class="error" />
                                        </div>
                                    </div>
                                    <div class="mt-3 row" v-if="!hasRole(8) && !hasRole(6)">
                                        <div class="w-100">
                                            <label for="">{{
                                                $store.getters.user.role_id ===
                                                    1
                                                    ? $t("username")
                                                    : $t("email")
                                            }}</label>
                                            <Field :type="$store.getters.user
                                                .role_id === 1
                                                ? 'text'
                                                : 'email'
                                                " rules="required" :label="$store.getters.user
                                                    .role_id === 1
                                                    ? $t('username')
                                                    : $t('email')
                                                    " class="form-control" :placeholder="$store.getters.user
                                                        .role_id === 1
                                                        ? $t('username')
                                                        : $t('email')
                                                        " :name="$store.getters.user
                                                        .role_id === 1
                                                        ? 'mobile_no'
                                                        : 'email'
                                                        " :value="$store.getters.user
                                                        .role_id === 1
                                                        ? user.mobile_no
                                                        : user.email
                                                        " />
                                            <ErrorMessage :name="$store.getters.user
                                                .role_id === 1
                                                ? 'mobile_no'
                                                : 'email'
                                                " class="error" />
                                        </div>
                                    </div>
                                    <div class="w-full mt-3 text-left" style="margin-left: -12px">
                                        <base-button native-type="submit" class="text-white bg-primary"
                                            :loading="updateUserSettingsSpinner">
                                            {{ $t("update") }}
                                        </base-button>
                                    </div>
                                </Form>
                            </div>
                        </tab-pane>
                        <!-- End of User Profile -->

                        <!-- User Password Section Start -->
                        <tab-pane :title="$t('password')">
                            <h1 class="text-left">
                                {{ $t("change_password") }}
                            </h1>
                            <div class="m-3">
                                <Form ref="updatePasswordForm" @submit="updateUserPasswordData" class="">
                                    <div class="mt-3 row" v-if="
                                        $store.getters.user.is_password_set
                                    ">
                                        <div class="w-100">
                                            <label for="old_password">{{
                                                $t("old_password")
                                            }}</label>
                                            <Field type="password" rules="required" class="form-control"
                                                :label="$t('old_password')" name="old_password"
                                                v-model="old_password" />
                                            <ErrorMessage name="old_password" class="error" />
                                        </div>
                                    </div>

                                    <div class="mt-3 row">
                                        <div class="w-100">
                                            <label for="">{{
                                                $t("password")
                                            }}</label>
                                            <Field type="password" rules="required|min:8" :label="$t('password')"
                                                class="form-control" name="new_password" v-model="new_password" />
                                            <ErrorMessage name="new_password" class="error" />
                                        </div>
                                    </div>

                                    <div class="mt-3 row">
                                        <div class="w-100">
                                            <label for="">{{
                                                $t("password_confirmation")
                                            }}</label>
                                            <Field type="password" rules="confirmed:@new_password" :label="$t('password_confirmation')
                                                " class="form-control" name="new_password_confirmation" v-model="new_password_confirmation
                                                    " />
                                            <ErrorMessage name="new_password_confirmation" class="error" />
                                        </div>
                                    </div>
                                    <div class="w-full mt-3 text-left" style="margin-left: -12px">
                                        <base-button native-type="submit" class="text-white bg-primary"
                                            :loading="updateUserPasswordSpinner">
                                            {{ $t("update") }}
                                        </base-button>
                                    </div>
                                </Form>
                            </div>
                        </tab-pane>
                        <!-- End of User Password Section Start -->

                        <!-- API Token Section Start -->
                        <tab-pane v-if="hasRole(4) || hasRole(5)" :title="$t('api_token')">
                            <h1 class="text-left">{{ $t("api_token") }}</h1>
                            <div class="m-3">
                                <div class="mt-3 row">
                                    <div class="w-100">
                                        <label>{{ $t("store_id") }} : {{ $store.getters.store?.id }}</label>
                                    </div>
                                </div>

                                <div class="mt-3">
                                    <base-button class="text-white bg-primary mb-4" :loading="generatingToken"
                                        @click="generateToken" :disabled="activeTokens.length >= 1">
                                        {{ $t("generate_new_token") }}
                                    </base-button>

                                    <p v-if="activeTokens.length >= 3" class="text-danger">
                                        {{ $t("max_keys_warning") }}
                                    </p>

                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t("token") }}</th>
                                                    <th>{{ $t("expires_at") }}</th>
                                                    <th>{{ $t("actions") }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="!activeTokens.length">
                                                    <td colspan="3" class="text-center">
                                                        {{ $t("no_active_tokens") }}
                                                    </td>
                                                </tr>
                                                <tr v-for="token in activeTokens" :key="token.raw_token">
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <input :type="text"
                                                                class="form-control" :value="token.token" readonly
                                                                :class="{ 'blurred': !token.showToken }" />
                                                            <button class="btn btn-sm btn-outline-primary ml-2 copy-btn"
                                                                @click="toggleTokenVisibility(token)">
                                                                {{ token.showToken ? $t("hide") : $t("show") }}
                                                            </button>
                                                            <button v-if="token.showToken"
                                                                class="btn btn-sm btn-outline-secondary ml-2 copy-btn"
                                                                @click="copyToken(token.token)">
                                                                {{ $t("copy") }}
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td>{{ formatDate(token.expires_at) }}</td>
                                                    <td>
                                                        <div class="d-flex gap-2">
                                                            <button class="btn btn-warning mr-2"
                                                                :disabled="extendingTokens[token.token_id]"
                                                                @click="extendToken(token)">
                                                                {{ extendingTokens[token.token_id] ? $t("extending") : $t("extend") }}
                                                            </button>
                                                            <button class="btn btn-danger"
                                                                :disabled="revokingTokens[token.token_id]"
                                                                @click="revokeToken(token)">
                                                                {{ revokingTokens[token.token_id] ? $t("revoking") : $t("revoke") }}
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </tab-pane>
                        <!-- End of API Token Section Start -->
                    </card>
                </tabs>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import mixin from "@/mixins/common";
import "flatpickr/dist/flatpickr.css";
import Tabs from "@/components/Tabs/Tabs";
import TabPane from "@/components/Tabs/TabPane";
import flatPicker from "vue-flatpickr-component";
import { updateUserProfile, updateUserPassword, me } from "@/api/users";
import {
    generateIntegrationToken,
    revokeIntegrationToken,
    extendIntegrationToken,
    getActiveIntegrationTokens
} from "@/api/store";

export default {
    data() {
        return {
            old_password: "",
            new_password: "",
            new_password_confirmation: "",
            token: "",
            updateUserSettingsSpinner: false,
            updateUserPasswordSpinner: false,
            profileForm: {},
            activeTokens: [],
            generatingToken: false,
            revokingTokens: {},
            extendingTokens: {},
        };
    },

    props: ["id"],
    mixins: [mixin],

    components: {
        Tabs,
        TabPane,
        flatPicker,
    },

    created() {
        if (this.userRole == 4 || this.userRole == 5) {
            this.fetchActiveTokens();
        }
    },

    computed: {
        ...mapGetters(["user"]),
    },

    methods: {
        updateCredit() {
            me().then((res) => {
                this.$store.dispatch(
                    "setStoreCredit",
                    res.user?.credit ? res.user?.credit : 0
                );
            });
        },

        async updateUserSetting(data) {
            this.updateUserSettingsSpinner = true;
            updateUserProfile(data)
                .then((res) => {
                    this.updateUserSettingsSpinner = false;
                    this.runToast(this.$t("success.user_update"), "success");
                })
                .catch(({ response }) => {
                    this.updateUserSettingsSpinner = false;
                    this.apiError(response);
                });
        },
        async updateUserPasswordData(data) {
            this.updateUserPasswordSpinner = true;
            await updateUserPassword(data)
                .then(() => {
                    this.$refs.updatePasswordForm.resetForm();
                    this.updateUserPasswordSpinner = false;
                    this.runToast(
                        this.$t("password_change_success"),
                        "success"
                    );
                    this.updateProfile();
                })
                .catch(({ response }) => {
                    this.updateUserPasswordSpinner = false;
                    this.apiError(response);
                });
        },
        updateProfile() {
            me().then((res) => {
                this.$store.dispatch("setUser", res.user);
            });
        },


        async fetchActiveTokens() {
            try {
                const response = await getActiveIntegrationTokens();
                this.activeTokens = response.active_tokens.map(token => ({
                    ...token,
                    showToken: false,
                })) || [];
            } catch (error) {
                this.apiError(error.response);
            }
        },

        toggleTokenVisibility(token) {
            token.showToken = !token.showToken;
        },

        async generateToken() {
            if (this.generatingToken || this.activeTokens.length >= 1) return;
            this.generatingToken = true;
            try {
                const response = await generateIntegrationToken();
                await this.fetchActiveTokens();
                this.runToast(response.message, "success");
            } catch (error) {
                this.apiError(error.response);
            } finally {
                this.generatingToken = false;
            }
        },

        copyToken(token) {
            navigator.clipboard.writeText(token)
                .then(() => {
                    this.runToast(this.$t("token_copied"), "success");
                })
                .catch(() => {
                    this.runToast(this.$t("copy_failed"), "error");
                });
        },

        formatDate(date) {
            return new Date(date).toLocaleString();
        },

        async revokeToken(token) {
            if (!token || !token.token_id) {
                console.error('Invalid token or token ID');
                return;
            }

            try {
                const response = await revokeIntegrationToken({
                    token_id: token.token_id
                });
                this.runToast(response.message || 'Token revoked successfully', 'success');
                await this.fetchActiveTokens();
            } catch (error) {
                console.error('Error revoking token:', error);
                this.apiError(error.response);
            }
        },

        async extendToken(token) {
            if (!token || !token.token_id) {
                console.error('Invalid token or token ID');
                return;
            }

            try {
                const response = await extendIntegrationToken({
                    token_id: token.token_id
                });
                this.runToast(response.message || 'Token extended successfully', 'success');
                await this.fetchActiveTokens();
            } catch (error) {
                console.error('Error extending token:', error);
                this.apiError(error.response);
            }
        },
    },
};
</script>

<style>
.gap-2 {
    gap: 0.5rem;
}

.btn:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}

.btn-warning {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.d-flex {
    display: flex;
}

.mr-2 {
    margin-right: 0.5rem;
}

.blurred {
    filter: blur(5px);
}

.copy-btn {
    font-size: 0.85rem;
    padding: 0.25rem 0.5rem;
}
</style>
