import axiosInstance from "@/plugins/customAxios";

export const getCouponCodes = (params = null) => {
    return axiosInstance
        .get(`v1/admin/coupon-codes`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getCouponCode = (id, params = null) => {
    return axiosInstance
        .get(`v1/admin/coupon-codes/${id}?`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const editCouponCode = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/admin/coupon-codes/${id}`, data, {
            params: params,
        })
        .then((res) => {
            return res.data;
        });
};

export const addCouponCode = (data, params = null) => {
    return axiosInstance
        .post(`v1/admin/coupon-codes`, data, {
            params: params,
        })
        .then((res) => {
            return res.data;
        });
};

export const deleteCouponCode = (id) => {
    return axiosInstance.delete(`v1/admin/coupon-codes/${id}`).then((res) => {
        return res.data;
    });
};