import List from "@/views/Pages/OrderReject/List";
import RejectOrderProviderList from "@/views/Pages/OrderReject/RejectOrderProviderList";

export default [{
    path: "orders/rejects",
    components: { default: List },
    name: "orderReject",
    meta: {
        title: "order_rejects",
        role: [1, 6],
    },
}, {
    path: "orders/reject/:order_id",
    components: { default: RejectOrderProviderList },
    name: "rejectedOrderProviders",
    props: true,
    meta: {
        title: "rejected_provider_list",
        role: [1, 6],
    },
},
];