<template>
    <div>
        <div class="card card-date-wrap mx-0 mb-3">
            <div class="col-2">
                <label for="storeDropDown">
                    {{ $t("dashboard_page.start_date") }}</label
                >
                <flat-picker
                    :config="{
                        height: '40px',
                        allowInput: true,
                        dateFormat: 'd-m-Y',
                    }"
                    class="form-control datepicker"
                    :placeholder="$t('select')"
                    v-model="filters.start_date"
                >
                </flat-picker>
            </div>

            <div class="col-2">
                <label for="storeDropDown">
                    {{ $t("dashboard_page.end_date") }}</label
                >
                <flat-picker
                    :config="{
                        allowInput: true,
                        dateFormat: 'd-m-Y',
                    }"
                    class="form-control datepicker"
                    :placeholder="$t('select')"
                    v-model="filters.end_date"
                >
                </flat-picker>
            </div>
        </div>
        <div class="row dashboard-counters" v-if="orderIndicatorData">
            <div class="row">
                <div class="col">
                    <div
                        :class="[
                            'card',
                            'rounded-md',
                            'p-2',
                            getIndividualCompletingOrderClass,
                        ]"
                        style="cursor: pointer"
                    >
                        <h3 class="card-title text-uppercase mt-2 mb-0">
                            {{ $t("completing_order_rate") }} (
                            {{ $t("individual_order") }} )
                        </h3>
                        <span
                            class="h1"
                            v-if="
                                orderIndicatorData.completing_order_rate
                                    .individual_order
                            "
                        >
                            {{
                                orderIndicatorData.completing_order_rate
                                    .individual_order_format.hours +
                                " " +
                                $t("hours") +
                                " " +
                                orderIndicatorData.completing_order_rate
                                    .individual_order_format.minutes +
                                " " +
                                $t("minutes")
                            }}
                        </span>
                        <span class="h1" v-else>
                            {{
                                orderIndicatorData.completing_order_rate
                                    .individual_order
                            }}
                            {{ $t("hours") }}</span
                        >
                    </div>
                </div>
                <div class="col">
                    <div
                        :class="[
                            'card',
                            'rounded-md',
                            'p-2',
                            getIndividualBusinessCompletingOrderClass,
                        ]"
                        style="cursor: pointer"
                    >
                        <h3 class="card-title text-uppercase mt-2 mb-0">
                            {{ $t("completing_order_rate") }} (
                            {{ $t("single_business_order") }} )
                        </h3>
                        <span
                            class="h1"
                            v-if="
                                orderIndicatorData.completing_order_rate
                                    .individual_business_order
                            "
                        >
                            {{
                                orderIndicatorData.completing_order_rate
                                    .individual_business_order_format.hours +
                                " " +
                                $t("hours") +
                                " " +
                                orderIndicatorData.completing_order_rate
                                    .individual_business_order_format.minutes +
                                " " +
                                $t("minutes")
                            }}
                        </span>
                        <span class="h1" v-else>
                            {{
                                orderIndicatorData.completing_order_rate
                                    .individual_business_order
                            }}
                            {{ $t("hours") }}</span
                        >
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div
                        :class="[
                            'card',
                            'rounded-md',
                            'p-2',
                            getIndividualAcceptingOrderClass,
                        ]"
                        style="cursor: pointer"
                    >
                        <h3 class="card-title text-uppercase mt-2 mb-0">
                            {{ $t("accepting_order_rate") }} (
                            {{ $t("individual_order") }} )
                        </h3>
                        <span
                            class="h1"
                            v-if="
                                orderIndicatorData.accepting_order_rate
                                    .individual_order
                            "
                        >
                            {{
                                orderIndicatorData.accepting_order_rate
                                    .individual_order_format.hours +
                                " " +
                                $t("hours") +
                                " " +
                                orderIndicatorData.accepting_order_rate
                                    .individual_order_format.minutes +
                                " " +
                                $t("minutes")
                            }}
                        </span>
                        <span class="h1" v-else>
                            {{
                                orderIndicatorData.accepting_order_rate
                                    .individual_order
                            }}
                            {{ $t("minutes") }}</span
                        >
                    </div>
                </div>
                <div class="col">
                    <div
                        :class="[
                            'card',
                            'rounded-md',
                            'p-2',
                            getIndividualBusinessAcceptingOrderClass,
                        ]"
                        style="cursor: pointer"
                    >
                        <h3 class="card-title text-uppercase mt-2 mb-0">
                            {{ $t("accepting_order_rate") }} (
                            {{ $t("single_business_order") }} )
                        </h3>
                        <span
                            class="h1"
                            v-if="
                                orderIndicatorData.accepting_order_rate
                                    .individual_business_order
                            "
                        >
                            {{
                                orderIndicatorData.accepting_order_rate
                                    .individual_business_order_format.hours +
                                " " +
                                $t("hours") +
                                " " +
                                orderIndicatorData.accepting_order_rate
                                    .individual_business_order_format.minutes +
                                " " +
                                $t("minutes")
                            }}
                        </span>
                        <span class="h1" v-else>
                            {{
                                orderIndicatorData.accepting_order_rate
                                    .individual_business_order
                            }}
                            {{ $t("minutes") }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixin from "@/mixins/common";
import "flatpickr/dist/flatpickr.css";
import flatPicker from "vue-flatpickr-component";
import { geOrderIndicator } from "@/api/dashboard";

export default {
    data() {
        return {
            orderIndicatorData: null,
            filters: {
                start_date: null,
                end_date: null,
            },
        };
    },
    mixins: [mixin],
    components: {
        flatPicker,
    },
    watch: {
        filters: {
            handler() {
                this.geOrderIndicatorData();
            },
            deep: true,
        },
    },
    created() {
        this.geOrderIndicatorData();
    },
    computed: {
        getIndividualCompletingOrderClass: function () {
            var averageRate = this.orderIndicatorData.completing_order_rate
                .individual_order;
            if (averageRate <= 2) {
                return "card-green";
            } else if (averageRate > 2 && averageRate <= 2.3) {
                return "card-yellow";
            }
            return "card-red";
        },
        getIndividualBusinessCompletingOrderClass: function () {
            var averageRate = this.orderIndicatorData.completing_order_rate
                .individual_business_order;
            if (averageRate <= 2) {
                return "card-green";
            } else if (averageRate > 2 && averageRate <= 2.3) {
                return "card-yellow";
            }
            return "card-red";
        },
        getIndividualAcceptingOrderClass: function () {
            var averageRate = this.orderIndicatorData.accepting_order_rate
                .individual_order;
            if (averageRate <= 5) {
                return "card-green";
            } else if (averageRate > 5 && averageRate <= 8) {
                return "card-yellow";
            }
            return "card-red";
        },
        getIndividualBusinessAcceptingOrderClass: function () {
            var averageRate = this.orderIndicatorData.accepting_order_rate
                .individual_business_order;
            if (averageRate <= 5) {
                return "card-green";
            } else if (averageRate > 5 && averageRate <= 8) {
                return "card-yellow";
            }
            return "card-red";
        },
    },
    methods: {
        geOrderIndicatorData() {
            geOrderIndicator({
                filter: this.filters,
            })
                .then((res) => {
                    this.orderIndicatorData = res;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
    },
};
</script>
<style scoped>
.card {
    margin: 10px;
}
.card-green {
    background-color: green;
}
.card-yellow {
    background-color: yellow;
}
.card-red {
    background-color: red;
}
.card-title {
    color: white !important;
}
.h1 {
    color: white !important;
}
</style>
