import axiosInstance from "@/plugins/customAxios";

export const get = (params = null) => {
    return axiosInstance.get("v1/admin/stores", { params: params }).then((res) => {
        return res.data;
    });
};

export const getStore = (id, params = null) => {
    return axiosInstance
        .get(`v1/admin/stores/${id}`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const edit = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/admin/stores/${id}?_method=PATCH`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const add = (data, params = null) => {
    return axiosInstance
        .post(`v1/admin/stores`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getAddress = (params = null) => {
    return axiosInstance.get("v1/admin/addresses", { params: params }).then((res) => {
        return res.data;
    });
};

export const addCredit = (id, data) => {
    return axiosInstance
        .post(`v1/admin/stores/${id}/add-credit`, data)
        .then((res) => {
            return res.data;
        });
};

export const changeStatus = (id, data) => {
    return axiosInstance
        .post(`v1/admin/stores/${id}/status`, data)
        .then((res) => {
            return res.data;
        })
}

export const creditForPlaceOrder = (params = null) => {
    return axiosInstance.get("v1/store/check-credit-for-place-order", { params: params }).then((res) => {
        return res.data;
    });
};

export const generateIntegrationToken = (data) => {
    return axiosInstance
        .post("v1/store/integration-token", data)
        .then((res) => {
            return res.data;
        });
};

export const revokeIntegrationToken = (data) => {
    return axiosInstance
      .post("v1/store/integration-token/revoke", {
        token_id: data.token_id
      })
      .then((res) => {
        return res.data;
      });
  };
  
  export const extendIntegrationToken = (data) => {
    return axiosInstance
      .post("v1/store/integration-token/extend", {
        token_id: data.token_id
      })
      .then((res) => {
        return res.data;
      });
  };

export const getActiveIntegrationTokens = (params = null) => {
    return axiosInstance
        .get(`v1/store/integration-token/active`, { params: params })
        .then((res) => {
            return res.data;
        });
};
