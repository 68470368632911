<template>
    <div>
        <hr />
        <div class="card mb-0 p-5 rounded-md">
            <div
                class="border-0 card-header px-0 d-flex justify-content-between align-items-center"
            >
                <button
                    class="text-white btn btn-default btn-sm"
                    @click="exporPaymentReport()"
                >
                    <i class="mr-2 text-white fas fa-download fa-lg">
                        <span>{{ $t("export") }}</span>
                    </i>
                </button>
            </div>
            <div class="d-flex mb-4 pt-2">
                <div class="col-2" v-if="showStoreFilter">
                    <label for="storeDropDown">
                        {{ $t("store_name") }}
                    </label>
                    <el-select
                    v-model="filters.store_id"
                    class="d-block"
                    filterable
                    remote
                    :remote-method="remoteSearchStores"
                    :loading="isSearchingStores"
                    :placeholder="$t('select_store')"
                    clearable
                >
                    <el-option
                        v-for="store in stores"
                        :key="store.id"
                        :value="store.id"
                        :label="store.name"
                    >
                        <span>{{ store.name }}</span>
                        <span class="text-gray-500 text-xs ml-2">
                            ({{ store.user?.mobile_no }})
                        </span>
                    </el-option>
                </el-select>
                </div>

                <div class="col-2">
                    <label for="storeDropDown">
                        {{ $t("dashboard_page.start_date") }}</label
                    >
                    <flat-picker
                        :config="{
                            allowInput: true,
                            dateFormat: 'd-m-Y',
                        }"
                        class="form-control datepicker"
                        :placeholder="$t('select')"
                        v-model="filters.start_date"
                    >
                    </flat-picker>
                </div>

                <div class="col-2">
                    <label for="storeDropDown">
                        {{ $t("dashboard_page.end_date") }}</label
                    >
                    <flat-picker
                        :config="{
                            allowInput: true,
                            dateFormat: 'd-m-Y',
                        }"
                        class="form-control datepicker"
                        :placeholder="$t('select')"
                        v-model="filters.end_date"
                    >
                    </flat-picker>
                </div>
                <div class="col-2">
                    <label for="storeDropDown"> {{ $t("type") }}</label>
                    <drop-down-filter
                        class=""
                        :dropDownFilters="[
                            {
                                id: 'payment_method',
                                options:
                                    defaultOptions.storeCreditPaymentMethod,
                            },
                        ]"
                        @dropDownOptionSelected="
                            (optionsObject) =>
                                (filters = { ...filters, ...optionsObject })
                        "
                    />
                </div>
            </div>
            <!-- End of Filter Div -->
            <div class="row">
                <main class="col-md-12 col-xs-11 p-l-2 p-t-2">
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="creditHistory"
                            v-loading="isLoading"
                            highlight-current-row
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('store_name')"
                                min-width="200px"
                                v-if="hasRole(1)"
                            >
                                <template v-slot="{ row }">
                                    <badge
                                        size="lg"
                                        class="bg-sky-dark cell p-2"
                                        style="cursor: pointer"
                                        @click="viewStoreDetail(row.store?.id)"
                                    >
                                        <span
                                            class="font-weight-600 name mb-0 text-capitalize"
                                            >{{ row?.store?.name }}</span
                                        >
                                    </badge>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('transaction_id')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.payment_ref_id }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('type')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ $t(row.payment_method) }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('status')"
                                min-width="180px"
                            >
                                <template v-slot="{ row }">
                                    <custom-label :label="row.status" />
                                    <!-- <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                    >
                                        {{ row.status }}
                                    </span> -->
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('amount')"
                                min-width="180px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.amount }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('date_and_time')"
                                min-width="180px"
                                prop="credit"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0"
                                        >{{ row.created_at }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('actions')"
                                min-width="210px"
                            >
                                <template v-slot="{ row }">
                                    <div
                                        class="d-flex align-items-center flex-wrap action-width"
                                    >
                                        <el-tooltip
                                            :content="$t('view')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                style="cursor: pointer"
                                                @click="printReceipt(row)"
                                            >
                                                <img
                                                    src="@/assets/svg/view.svg"
                                                />
                                            </badge>
                                        </el-tooltip>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- End of Table--->
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="creditHistory.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="creditHistoryPage"
                            :total="storesTotalData"
                        ></base-pagination>
                    </div>

                    <modal
                        footerClasses="justify-content-center"
                        :show="showPrintReceiptModal"
                        @close="$emit('close')"
                        size="lg"
                        body-classes="p-0"
                    >
                        <template v-slot:header>
                            <div class="deliver">
                                <h2 class="block text-center">
                                    {{ $t("receipt") }}
                                </h2>
                                <div class="deliver__header">
                                    <h3 class="deliver__header--name">
                                        <span
                                            >{{
                                                $t(
                                                    "proof_of_delivery.welcome_customer"
                                                ) +
                                                ", " +
                                                receiptData?.store?.name
                                            }}
                                        </span>
                                    </h3>
                                </div>
                                <div class="deliver__content">
                                    <div class="deliver__content-header">
                                        <h3 class="deliver__subtitle">
                                            {{ $t("payment_information") }}
                                        </h3>
                                    </div>
                                    <div class="deliver__content-detail">
                                        <ul class="deliver__list">
                                            <li class="deliver__list-item">
                                                <p class="deliver__text">
                                                    {{ $t("mobile_no") }}
                                                </p>
                                            </li>
                                            <li class="deliver__list-item">
                                                <p
                                                    class="deliver__text"
                                                    v-if="
                                                        receiptData?.store?.user
                                                            ?.mobile_no
                                                    "
                                                >
                                                    {{ "+" }}
                                                    {{
                                                        receiptData?.store?.user
                                                            ?.country_code || ""
                                                    }}
                                                    {{
                                                        receiptData?.store?.user
                                                            ?.mobile_no
                                                    }}
                                                </p>
                                            </li>
                                            <li class="deliver__list-item">
                                                <p class="deliver__text">
                                                    {{ $t("payment_method") }}
                                                </p>
                                            </li>
                                            <li class="deliver__list-item">
                                                <p
                                                    class="deliver__text"
                                                    v-if="
                                                        receiptData?.payment_method
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            receiptData?.payment_method
                                                        )
                                                    }}
                                                </p>
                                            </li>
                                        </ul>
                                        <ul class="deliver__list">
                                            <li class="deliver__list-item">
                                                <p class="deliver__text">
                                                    {{ $t("amount") }}
                                                </p>
                                            </li>
                                            <li class="deliver__list-item">
                                                <p class="deliver__text">
                                                    {{ receiptData.amount }}
                                                </p>
                                            </li>
                                            <li class="deliver__list-item">
                                                <p class="deliver__text">
                                                    {{ $t("payment_status") }}
                                                </p>
                                            </li>

                                            <li class="deliver__list-item">
                                                <p
                                                    class="deliver__text"
                                                    v-if="receiptData?.status"
                                                >
                                                    <!-- {{ $t(receiptData.status) }} -->
                                                    {{
                                                        $t(receiptData?.status)
                                                    }}
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="deliver__content-footer">
                                        <base-button
                                            native-type="submit"
                                            class=""
                                            :loading="downloadReceiptSpinner"
                                            @click="downloadReceipt"
                                        >
                                            {{
                                                $t(
                                                    "proof_of_delivery.download_btn"
                                                )
                                            }}
                                        </base-button>
                                        <button
                                            class="btn btn-default text-white"
                                            @click="closeReceiptModal()"
                                        >
                                            {{ $t("close") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </modal>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import {
    creditHistory,
    downloadReceipt,
    downloadReceiptExcel,
} from "@/api/credit";
import mixin from "@/mixins/common";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import store from "@/store/index";
import CustomLabel from "../../../components/common/CustomLabel.vue";
import { debounce } from 'lodash';
import { get as getStores } from "@/api/store";

export default {
    data() {
        return {
            selectedDate: [],
            isLoading: false,
            creditHistory: [],
            meta: {},
            creditHistoryPage: 1,
            filters: {
                type: store.getters.user.role_id === 9 ? "" : "store_credit_payment",
                payment_method: "",
                start_date: "",
                end_date: "",
                store_id: null
            },
            showPrintReceiptModal: false,
            receiptData: [],
            downloadReceiptSpinner: false,
        };
    },
    created() {
        this.getCreditHistory();
    },
    computed: {
        showStoreFilter() {
            const userRole = this.$store.getters.user.role_id;
            return userRole === 1 || userRole === 6;
        }
    },

    watch: {
        creditHistoryPage: {
            handler: function () {
                this.getCreditHistory();
            },
        },
        filters: {
            deep: true,
            handler: debounce(function() {
                this.creditHistoryPage = 1;
                this.getCreditHistory();
            }, 700)
        },
    },
    mixins: [mixin],

    methods: {
        getCreditHistory() {
            this.isLoading = true;
            creditHistory({
                page: this.creditHistoryPage,
                store_id:
                    store.getters.user.role_id === 1 &&
                    this.storeOrder === false
                        ? null
                        : store.getters.store?.id,
                query: {
                    ...this.filters,
                },
                include: "store,store.user",
                service_provider_company_id: this.$store.getters.user
                    ?.service_provider_company?.id,
            })
                .then((res) => {
                    this.isLoading = false;
                    this.creditHistory = res.data;
                    this.storesTotalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                type: "store_credit_payment",
                payment_type,
            };
        },

        printReceipt(data) {
            this.receiptData = data;
            this.showPrintReceiptModal = true;
        },
        closeReceiptModal() {
            this.receiptData = [];
            this.showPrintReceiptModal = false;
        },
        downloadReceipt() {
            this.downloadReceiptSpinner = true;
            downloadReceipt(this.receiptData.id, {
                include: "store,store.user",
                store_id:
                    store.getters.user.role_id === 1 &&
                    this.storeOrder === false
                        ? null
                        : store.getters.store?.id,
                    service_provider_company_id: this.$store.getters.user
                    ?.service_provider_company?.id,
            })
                .then((res) => {
                    this.downloadReceiptSpinner = false;
                    if (res.payment_receipt !== null) {
                        window.open(res.payment_receipt, "_blank");
                    }
                })
                .catch(({ response }) => {
                    this.downloadReceiptSpinner = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        exporPaymentReport() {
            downloadReceiptExcel({
                include: "store,store.user",
                store_id:
                    store.getters.user.role_id === 1 &&
                    this.storeOrder === false
                        ? null
                        : store.getters.store?.id,
                service_provider_company_id: this.$store.getters.user
                ?.service_provider_company?.id,
                query: {
                    ...this.filters,
                },
            })
                .then((res) => {
                    this.downloadReceiptSpinner = false;
                    if (res.excel_url !== null) {
                        window.open(res.excel_url, "_blank");
                    }
                })
                .catch(({ response }) => {
                    this.downloadReceiptSpinner = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        viewStoreDetail(id) {
            this.$router.push({ name: "Store Details", params: { id } });
        },
        async remoteSearchStores(query) {
        if (query.length < 2) {
            this.stores = [];
            return;
        }

        this.isSearchingStores = true;
        try {
            const response = await getStores({
                query: {
                    name: query,
                },
                include: "user",
                limit: 25,
            });
            this.stores = response.data;
        } catch (error) {
            this.apiError(error.response);
        } finally {
            this.isSearchingStores = false;
        }
    },
    },
    components: {
        flatPicker,
        CustomLabel,
    },
};
</script>

<style scoped lang="scss">
.deliver li.deliver__list-item {
    width: 25%;
}
</style>
