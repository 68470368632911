import axiosInstance from "@/plugins/customAxios";
// import store from "@/store/index";
// let baseUrl;
// if (
//     store.getters.user.role_id === 1 ||
//     store.getters.user.role_id === 9 ||
//     store.getters.user?.role_id === 8 ||
//     store.getters.user?.role_id === 6
// )
//     baseUrl = "admin";
// else baseUrl = "store";
export const getUsers = (baseUrl, params = null) => {
    return axiosInstance
        .get(`v1/${baseUrl}/users`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getUser = (baseUrl, id, params = null) => {
    return axiosInstance
        .get(`v1/${baseUrl}/users/${id}`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const changeStatus = (baseUrl, data, params = null) => {
    // let baseUrl;
    // if (store.getters.user.role_id === 1) baseUrl = "admin";
    // else baseUrl = "store";
    return axiosInstance
        .post(`v1/${baseUrl}/users/status`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const deleteUser = (id) => {
    return axiosInstance.delete(`v1/admin/users/${id}`).then((res) => {
        return res.data;
    });
};

export const editUser = (baseUrl, id, data, params = null) => {
    // let baseUrl;
    // if (store.getters.user.role_id === 1) baseUrl = "admin";
    // else baseUrl = "store";
    return axiosInstance
        .post(`v1/${baseUrl}/users/${id}`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const addUser = (baseUrl, data, params = null) => {
    // let baseUrl;
    // if ([1, 9].includes(store.getters.user.role_id)) baseUrl = "admin";
    // else baseUrl = "store";
    return axiosInstance
        .post(`v1/${baseUrl}/users`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

// Update User Profile Setting Method //
export const updateUserProfile = (data, params = null) => {
    return axiosInstance
        .post(`v1/profile?request_from=web`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

// Update User Password Setting Method
export const updateUserPassword = (data, params = null) => {
    return axiosInstance
        .post(`v1/change-password?request_from=web`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const creditTransfer = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/admin/users/${id}/credit-transfer`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const me = (params = null) => {
    return axiosInstance
        .get(`v1/me`, { params: params }).then((res) => {
            return res.data;
        });
};

export const saveUserDevice = (data) => {
    return axiosInstance.post(`v1/user-devices`, data).then((res) => {
        return res;
    });
};

export const uniqueMobileNo = (data) => {
    return axiosInstance.post(`v1/unique-mobile`, data).then((res) => {
        return res;
    });
};

export const referralCreditTransfer = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/admin/users/${id}/referral-credit-transfer`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const updateProvider = (id, data) => {
    return axiosInstance.post(`v2/admin/provider/${id}/update`, data).then((res) => {
        return res.data;
    });
};

export const getOrderReport = (params = null) => {
    return axiosInstance
        .get(`v2/admin/users/order-report`, { params: params }).then((res) => {
            return res.data;
        });
};
export const sendUserOrderReportNotification = (params, data) => {
    return axiosInstance.post(`v2/admin/users/send-order-report-notification`, data, { params: params }).then((res) => {
        return res.data;
    });
};

export const operationsCheckIn = (baseUrl, params = null) => {
    return axiosInstance
        .post(`v1/${baseUrl}/operations/check-in`, params)
        .then((res) => {
            return res.data;
        });
};

export const operationsCheckOut = (baseUrl, params = null) => {
    return axiosInstance
        .post(`v1/${baseUrl}/operations/check-out`, params)
        .then((res) => {
            return res.data;
        });
};

export const operationsGetCurrentShift = (baseUrl, params = null) => {
    return axiosInstance
        .get(`v1/${baseUrl}/operations/current-shift`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const bulkCreditTransfer = (data) => {
    return axiosInstance
        .post(`v1/admin/users/bulk-credit-transfer`, data)
        .then((res) => {
            return res.data;
        });
};