import List from "@/views/Pages/ActivityLog/List";

export default [
    {
        path: "activity-log",
        components: { default: List },
        name: "Activity Log",
        meta: {
            title: "activity_log_title",
            role: [1, 6],
        },
    },
];
