<template>
    <div>
        <hr />
        <div v-if="hasDashboardAccess">
            <tabs
                fill
                class="flex-column block flex-md-row"
                tabNavClasses="mb-3 mt-4  dash-tabs"
                @tabClicked="tabClicked"
            >
                <tab-pane
                    :title="$t('dashboard_page.administrator')"
                    class=""
                    id="admin"
                >
                    <div v-if="showAdminTab == true">
                        <!-- <dashcard @statusChanged="changeOrderStatus"></dashcard> -->
                        <order-list
                            :orderStatus="orderStatus"
                            :storeOrder="hasRole(1) ? true : null"
                        ></order-list>
                    </div>
                </tab-pane>
                <tab-pane
                    :title="$t('dashboard_page.information')"
                    class=""
                    id="information"
                >
                    <information-cards v-if="showInformationTab == true" />
                </tab-pane>

                <tab-pane
                    :title="$t('dashboard_page.statistics')"
                    class=""
                    id="statistics"
                    v-if="userRole != 9" 
                >
                    <statistics v-if="showStatisticsTab == true" />
                </tab-pane>

                <tab-pane
                    v-if="userRole == 1"
                    :title="$t('dashboard_page.store_statistics')"
                    id="storeStatistics"
                    class=""
                >
                    <store-statistics v-if="showStoreStatisticsTab == true" />
                </tab-pane>

                <tab-pane
                    v-if="userRole == 1 || userRole == 6"
                    :title="$t('order_indicator')"
                    id="orderIndicator"
                    class=""
                >
                    <order-indicator v-if="showOrderIndicatorTab == true" />
                </tab-pane>
            </tabs>
        </div>
    </div>
</template>

<script>
import Dashcard from "./Dashcard";
import OrderList from "@/components/common/OrderList.vue";
import mixin from "@/mixins/common";
import InformationCards from "@/components/common/InformationCards.vue";
import StoreStatistics from "./StoreStatistics.vue";
import Statistics from "./Statistics.vue";
import OrderIndicator from "./OrderIndicator.vue";
import { me } from "@/api/users";
export default {
    data() {
        return {
            orderStatus: "live",
            showAdminTab: true,
            showInformationTab: false,
            showStatisticsTab: false,
            showStoreStatisticsTab: false,
            showOrderIndicatorTab: false,
        };
    },
    created() {
        if (this.hasRole(9)) this.updateCredit();
    },
    computed: {
        hasDashboardAccess() {
            let accessibleUserRoles = [1, 4, 5, 6, 7, 8, 9];
            return accessibleUserRoles.includes(this.userRole);
        },
    },
    mixins: [mixin],
    methods: {
        updateCredit() {
            me().then((res) => {
                this.$store.dispatch(
                    "setStoreCredit",
                    res.user?.credit ? res.user?.credit : 0
                );
            });
        },
        changeOrderStatus(status) {
            this.orderStatus = status;
        },

        tabClicked(tabName) {
            switch (tabName) {
                case "admin":
                    this.showAdminTab = true;
                    break;
                case "information":
                    this.showInformationTab = true;
                    break;
                case "statistics":
                    this.showStatisticsTab = true;
                    break;
                case "storeStatistics":
                    this.showStoreStatisticsTab = true;
                    break;
                case "orderIndicator":
                    this.showOrderIndicatorTab = true;
                    break;
            }
        },
    },
    components: {
        Dashcard,
        OrderList,
        InformationCards,
        StoreStatistics,
        Statistics,
        OrderIndicator,
    },
};
</script>
