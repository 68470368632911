<template>
    <div>
        <div class="">
            <div class="row">
                <div class="col-md-4">
                    <div class="px-3 card">
                        <div class="mt-3 nav-item user-profile">
                            <div
                                class="user-profile-item d-flex justify-content-between align-items-center"
                            >
                                <div
                                    class="ml-2 nav-link-text font-weight-bold"
                                >
                                    {{ user.name }}
                                </div>

                                <div>
                                    <i
                                        class="mx-2 fas fa-pen fa-lg text-default"
                                        style="cursor: pointer"
                                        @click="editUserModal"
                                    ></i>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-column justify-content-between">
                            <div class="mb-3">
                                <h3 class="mr-3 d-inline-block">
                                    {{ $t("roles") }}
                                </h3>
                                <badge rounded type="info">{{
                                    user.role
                                }}</badge>
                            </div>

                            <hr class="my-0" />
                            <div class="mb-3">
                                <i
                                    class="mr-2 text-lg fas fa-mobile-alt text-default"
                                ></i>
                                <span class="text-lg font-weight-bold">
                                    {{ user.mobile_no }}
                                </span>
                            </div>

                            <hr class="my-0" />
                            <div class="mb-3">
                                <h3 class="mr-2 d-inline-block">
                                    {{ $t("joined_date") }}
                                </h3>
                                <span class="text-lg font-weight-bold">{{
                                    user?.joined_date
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Start of Edit User Modal  -->
            <div
                class="modal fade"
                ref="editUserModal"
                id="editUserModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="p-0 pl-4 modal-header custom-modal-header">
                            <h5 class="my-3 modal-title" id="exampleModalLabel">
                                {{ $t("edit_user") }}
                            </h5>
                            <button
                                type="button"
                                class="mr-2 close close-button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="pt-0 modal-body">
                            <Form @submit="editUser" v-loading="submitSpinner">
                                <label for="" class="my-1">{{
                                    $t("name")
                                }}</label>
                                <Field
                                    type="text"
                                    rules="required"
                                    :label="$t('name')"
                                    class="form-control"
                                    :placeholder="$t('name')"
                                    name="name"
                                    v-model="user.name"
                                />
                                <ErrorMessage name="name" class="error" />
                                <label for="" class="my-1">{{
                                    $t("email")
                                }}</label>

                                <Field
                                    type="email"
                                    rules="required|email"
                                    :label="$t('email')"
                                    class="form-control"
                                    :placeholder="$t('email')"
                                    name="email"
                                    id="email"
                                    v-model="user.email"
                                />
                                <ErrorMessage name="email" class="error" />
                                <label for="" class="my-1">{{
                                    $t("mobile_no")
                                }}</label>

                                <Field
                                    type="number"
                                    rules="required|max:10|min:0"
                                    :label="$t('mobile_no')"
                                    class="form-control"
                                    :placeholder="$t('mobile_no')"
                                    name="mobile_no"
                                    v-model="user.mobile_no"
                                />
                                <ErrorMessage name="mobile_no" class="error" />

                                <div class="w-full mt-5 text-center">
                                    <input
                                        type="submit"
                                        :value="$t('submit')"
                                        class="text-white btn bg-primary"
                                    />
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End of Edit user modal -->
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getUser, editUser } from "@/api/users";

export default {
    data() {
        return {
            user: {
                name: "",
                email: "",
                mobile_no: "",
            },
            roles: "",
            rolesList: [
                {
                    label: this.$t("operator"),
                    value: "operator",
                },
                {
                    label: this.$t("store_account_manager"),
                    value: "store_account_manager",
                },
                {
                    label: this.$t("provider_account_manager"),
                    value: "provider_account_manager",
                },
            ],

            userCurrentPage: 1,
            submitSpinner: true,
        };
    },
    watch: {
        userCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getUser();
            },
        },
    },

    created() {
        this.getUser();
    },

    props: ["id"],

    mixins: [mixin],

    methods: {
        editUserModal() {
            this.userId = this.user.id;
            this.user = this.user;
            $("#editUserModal").modal("show");
        },
        editUser(data) {
            this.submitSpinner = true;
            editUser(
                this.userRole === 1 ? "admin" : "store",
                this.userId,
                data,
                { _method: "PATCH" }
            )
                .then(() => {
                    this.submitSpinner = false;
                    $("#editUserModal").modal("hide");
                    this.runToast(this.$t("success.user_update"), "success");
                    this.getUser();
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        getUser() {
            getUser(this.userRole === 1 ? "admin" : "store", this.id, {})
                .then((res) => {
                    this.user = res;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
    },
};
</script>

<style scoped lang="scss">
$primary-color: #7368fb;

.user-profile {
    border: 2px;
    border-radius: 5px;
    height: 55px;
    margin-bottom: 10px;
    background-color: #f6f9fc;
}
.user-profile-item {
    margin-top: 2px;
}
.avatar-sm {
    width: 50px !important;
    height: 50px !important;
}
</style>
