<template>
    <div>
        <hr />
        <div class="row">
            <div class="col-md-4">
                <div class="card px-4 py-2 rounded-md">
                    <div class="col-md-12 edit-note">
                        <h2 class="mb-0">{{ $t("provider_detail") }}</h2>
                    </div>
                    <div
                        class="col-md-12 compensation-btn"
                        v-if="
                            (userRole == 1 || userRole == 6) &&
                            order.provider_id != null
                        "
                    >
                        <div class="compensation-btn-wrap">
                            <base-button
                                class="text-primary bg-transparent border-primary"
                                @click="addCompensation('provider')"
                            >
                                {{ $t("add_compensation") }}
                            </base-button>
                        </div>
                        <div class="compensation-btn-wrap">
                            <base-button
                                class="text-danger bg-transparent border-danger"
                                @click="deductFine"
                            >
                                {{ $t("deduct_fine") }}
                            </base-button>
                        </div>
                    </div>
                    <div class="nav-item user-profile mt-3">
                        <div class="user-profile-item align-items-center p-3">
                            <div
                                v-if="order.provider"
                                role="button"
                                @click="providerView()"
                            >
                                <img
                                    :src="
                                        // below logic checks if profile image from api is not there i.e null, then setting static image user.png
                                        provider?.profile_image ||
                                        require('@/assets/images/icons/common/user.png')
                                    "
                                    alt=""
                                    class="avatar-sm rounded-circle"
                                    style="
                                        width: 40px !important;
                                        height: 40px !important;
                                        border-radius: 50px;
                                    "
                                />
                                <span
                                    class="nav-link-text ml-2 font-weight-bold"
                                    >{{ order.provider?.name || "" }}</span
                                >
                            </div>
                            <div
                                v-else-if="
                                    !order.provider &&
                                    (order.status == 'Accepted' ||
                                        order.status == 'On The Way' ||
                                        order.status == 'Picked Up' ||
                                        order.status == 'Dropped Off' ||
                                        order.status == 'Completed')
                                "
                            >
                                <span
                                    class="nav-link-text ml-2 font-weight-bold text-danger"
                                    >{{ $t("providerDeleted") }}</span
                                >
                            </div>
                            <div v-else>
                                <span class="nav-link-text font-weight-bold">{{
                                    $t("provider_not_assigned_to_order")
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div
                        class="mt-3 d-flex flex-column justify-content-between"
                        v-if="order.provider"
                    >
                        <div class="mb-3">
                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="order.provider?.email"
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/email.svg" />
                                </span>
                                <span
                                    class="nav-link-text ml-2 font-weight-bold"
                                    >{{ order.provider.email }}</span
                                >
                            </div>

                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="order.provider?.mobile_no"
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/phone.svg" />
                                </span>
                                <span class="font-weight-bold ml-2">
                                    +{{ order.provider.country_code + " " }}
                                    {{ order.provider.mobile_no }}</span
                                >
                            </div>
                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="order.is_provider_eligible_for_bonus"
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/bonus-add.svg" />
                                </span>
                                <badge
                                    class="status-badge"
                                    rounded
                                    style="background-color: #7368fb"
                                    size="md"
                                >
                                    <span style="color: white !important">{{
                                        $t("eligible_for_bonus")
                                    }}</span>
                                </badge>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- customer detail -->
                <div class="card px-4 py-2 rounded-md">
                    <div class="col-md-12 edit-note">
                        <h2 class="mb-0">{{ $t("customer_detail") }}</h2>
                    </div>
                    <div
                        class="col-md-12 compensation-btn"
                        v-if="
                            order.customer && (userRole == 1 || userRole == 6)
                        "
                    >
                        <div class="col-md-6">
                            <base-button
                                class="text-primary bg-transparent border-primary"
                                @click="addCompensation('customer')"
                            >
                                {{ $t("add_compensation") }}
                            </base-button>
                        </div>
                        <div class="col-md-6" v-if="order.status != 'Completed' && order.status != 'Cancelled'">
                            <base-button
                                class="text-primary bg-transparent border-primary"
                                @click="confirmModal"
                            >
                                {{ $t("resend_code.resend_sms") }}
                            </base-button>
                        </div>
                    </div>
                    <div v-if="order.customer">
                        <div class="nav-item user-profile mt-3">
                            <div
                                class="user-profile-item align-items-center p-3"
                                @click="customerView()"
                                role="button"
                            >
                                <div v-if="order.customer">
                                    <img
                                        :src="
                                            // below logic checks if profile image from api is not there i.e null, then setting static image user.png
                                            customer?.profile_image ||
                                            require('@/assets/images/icons/common/user.png')
                                        "
                                        alt=""
                                        class="avatar-sm rounded-circle"
                                        style="
                                            width: 40px !important;
                                            height: 40px !important;
                                            border-radius: 50px;
                                        "
                                    />
                                    <span
                                        class="nav-link-text ml-2 font-weight-bold"
                                        >{{ order.customer?.name || "" }}</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div
                            class="mt-3 d-flex flex-column justify-content-between"
                            v-if="order.customer"
                        >
                            <div class="mb-3">
                                <div
                                    class="mb-3 px-0 d-flex align-items-center"
                                    v-if="order.customer?.email"
                                >
                                    <span class="icon-wrap">
                                        <img src="@/assets/svg/email.svg" />
                                    </span>
                                    <span
                                        class="nav-link-text ml-2 font-weight-bold"
                                        >{{ order.customer.email }}</span
                                    >
                                </div>

                                <div
                                    class="mb-3 px-0 d-flex align-items-center"
                                    v-if="order.customer?.mobile_no"
                                >
                                    <span class="icon-wrap">
                                        <img src="@/assets/svg/phone.svg" />
                                    </span>
                                    <span class="font-weight-bold ml-2">
                                        +{{
                                            order.customer.country_code + " "
                                        }}
                                        {{ order.customer.mobile_no }}</span
                                    >
                                </div>
                            </div>

                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="
                                    order?.pickup_address?.address_line1 ||
                                    false
                                "
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/location-pin.svg" />
                                </span>
                                <span class="font-weight-bold ml-2">
                                    {{
                                        order?.pickup_address?.address_line1 ||
                                        ""
                                    }}
                                </span>
                            </div>
                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="
                                    order?.dropoff_address?.address_line1 ||
                                    false
                                "
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/location-pin.svg" />
                                </span>
                                <span class="font-weight-bold ml-2">
                                    {{
                                        order?.dropoff_address?.address_line1 ||
                                        ""
                                    }}
                                </span>
                            </div>
                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="order?.display_dropoff_time || false"
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/clock.svg" />
                                </span>
                                <span class="font-weight-bold ml-2">
                                    {{ order?.display_dropoff_time || "" }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- Show the user deleted text -->
                    <div v-else class="nav-item user-profile mt-3">
                        <div class="user-profile-item align-items-center p-3">
                            <span
                                class="nav-link-text ml-2 font-weight-bold text-danger"
                                >{{ $t("customerDeleted") }}</span
                            >
                        </div>
                    </div>
                </div>

                <!-- Store order tracking link -->
                <div
                    class="card p-4 rounded-md"
                    v-if="order.store_order_tracking_link"
                >
                    <div class="order-notes-wrap">
                        <div class="col-md-8 edit-note">
                            <h2 class="mb-0">{{ $t("orderTrackingLink") }}</h2>
                        </div>
                    </div>
                    <hr />
                    <a
                        :href="order.store_order_tracking_link"
                        target="_blank"
                        >{{ $t("orderTrackingLink") }}</a
                    >
                </div>

                <!-- customer notes -->
                <div class="card p-4 rounded-md" v-if="order.customer_notes">
                    <div class="order-notes-wrap">
                        <div class="col-md-8 edit-note">
                            <h2 class="mb-0">{{ $t("customer_notes") }}</h2>
                        </div>
                    </div>
                    <hr />
                    <p class="pl-3">{{ order.customer_notes }}</p>
                </div>

                <!-- cancel notes -->
                <div class="card p-4 rounded-md" v-if="order.status_reason">
                    <div class="order-notes-wrap">
                        <div class="col-md-8 edit-note">
                            <h2 class="mb-0" v-if="order.status == 'On Hold'">
                                {{ $t("onHoldStatusReason") }}
                            </h2>
                            <h2 class="mb-0" v-else>
                                {{ $t("cancelOrderReason") }}
                            </h2>
                        </div>
                    </div>
                    <hr />
                    <p class="pl-3" v-if="order.status == 'Cancelled'">
                        <span class="font-weight-bold ml-2"
                            >{{ $t("cancelledBy") }}: </span
                        >{{ order.cancelled_by?.name }}
                    </p>
                    <p class="pl-3">
                        <span class="font-weight-bold ml-2"
                            >{{ $t("reason") }}: </span
                        >{{ order.display_status_reason }}
                    </p>
                </div>

                <div class="card px-0 rounded-md">
                    <ul class="list-group">
                        <li class="list-group-item">
                            <h2>{{ $t("requested_service") }}</h2>
                        </li>
                        <li class="list-group-item">
                            {{ $t("load_floor") }} :
                            {{ order.load_floor }}
                        </li>
                        <li class="list-group-item">
                            {{ $t("upload_floor") }} :
                            {{ order.upload_floor }}
                        </li>
                        <li class="list-group-item">
                            {{ $t("install_service") }} :
                            {{ order.install ? $t("yes") : $t("no") }}
                        </li>
                        <li class="list-group-item">
                            {{ $t("uninstall_service") }} :
                            {{ order.uninstall ? $t("yes") : $t("no") }}
                        </li>
                        <li class="list-group-item">
                            {{ $t("helper_service") }} :
                            {{ order.helper ? $t("yes") : $t("no") }}
                        </li>
                        <li class="list-group-item">
                            {{ $t("elevator_service") }} :
                            {{ order.is_elevator ? $t("yes") : $t("no") }}
                        </li>
                        <li class="list-group-item">
                            {{ $t("wrapping_service") }} :
                            {{ order.is_wrapping ? $t("yes") : $t("no") }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8" :loading="orderLoadingSpinner">
                <normal-order-detail
                    :order="order"
                    :mapData="mapData"
                    v-if="
                        (order && order.type == 'Normal') ||
                        order.type == 'Store_pickup'
                    "
                    @getOrder="getOrder"
                    :providers="providers"
                    :settingMapRadiusRange="settingMapRadiusRange"
                ></normal-order-detail>
                <normal-order-detail
                    :order="order"
                    :mapData="mapData"
                    v-if="
                        order &&
                        order.type == 'Package' &&
                        order.parent_id != null
                    "
                    @getOrder="getOrder"
                    :providers="providers"
                    :settingMapRadiusRange="settingMapRadiusRange"
                ></normal-order-detail>
                <package-order-detail
                    :order="order"
                    :mapData="mapData"
                    v-if="
                        order &&
                        order.type == 'Package' &&
                        order.parent_id == null
                    "
                    @getOrder="getOrder"
                    @goToPayload="goToPayload"
                    :providers="providers"
                    :settingMapRadiusRange="settingMapRadiusRange"
                ></package-order-detail>
            </div>
        </div>
        <add-compensation-modal
            :showModal="showAddCompensationModal"
            :orderId="order.id"
            type="compensation"
            :compensationTo="compensationTo"
            :title="
                compensationTo == 'provider'
                    ? $t('add_compensation_to_provider')
                    : $t('add_compensation_to_customer')
            "
            @close="showAddCompensationModal = false"
        />
        <add-fine-modal
            :showModal="showDeductFineModal"
            :orderId="order.id"
            type="fine"
            :title="$t('deduct_fine')"
            @close="showDeductFineModal = false"
        />
        <!-- confirm modal -->
        <modal
            :show="showConfirmModal"
            @close="showConfirmModal = false"
            size="ls"
            v-loading="isLoading"
            headerClasses="pb-0"
        >
            <template v-slot:header>
                <div class="text-bold text-lg text-center mt-2 mb-1">
                    {{ $t("resend_code.resend_sms_modal") }}
                </div>
            </template>
            <div class="text-center my-4">
                <base-button
                    @click.native="resendVerification"
                    :loading="buttonSpinner"
                    :disabled="resendDisabled"
                >
                    {{ resendDisabled ? `${$t("confirm")} (${countdown})` : $t("confirm") }}
                </base-button>
                <button
                    class="btn btn-default"
                    @click="showConfirmModal = false"
                >
                    {{ $t("close") }}
                </button>
            </div>
        </modal>

        <!-- End of row -->
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getUsers } from "@/api/users";
import { getOrder, cancelOrder, resendVerificationCode } from "@/api/orders";
import AlertModal from "@/components/common/AlertModal";
import NormalOrderDetail from "./NormalOrderDetail.vue";
import PackageOrderDetail from "./PackageOrderDetail.vue";
import { getSettings } from "@/api/systemSetting";
import AddFineModal from "@/components/common/AddFineCompensationModal.vue";
import MapForOrderLocation from "@//components/common/MapForOrderLocation.vue";
import AddCompensationModal from "@/components/common/AddFineCompensationModal.vue";

export default {
    data() {
        return {
            customer: {},
            order: {},
            statusHistories: [],
            mapData: [],
            cancelOrderSpinner: false,
            orderLoadingSpinner: false,
            showAddCompensationModal: false,
            showDeductFineModal: false,
            showConfirmModal: false,
            compensationTo: null,
            providers: [],
            settingMapRadiusRange: null,
            resendDisabled: false,
            countdown: 60,
            countdownInterval: null,
        };
    },
    watch: {
        ordersCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getOrder();
            },
        },

        ratingsCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getRatings();
            },
        },

        id: {
            handler: function (oldVal, newVal) {
                this.getOrder();
            },
        },
    },
    components: {
        AddFineModal,
        MapForOrderLocation,
        AlertModal,
        NormalOrderDetail,
        PackageOrderDetail,
        AddCompensationModal,
    },
    async created() {
        this.cleanExpiredResendTimes();
        await this.getOrder();

        try {
            const storedEndTime = localStorage.getItem(`resendEndTime_${this.id}`);
            if (storedEndTime && storedEndTime !== 'undefined' && !isNaN(storedEndTime)) {
                const endTime = parseInt(storedEndTime, 10);
                if (Date.now() < endTime) {
                    this.resendDisabled = true;
                    this.startCountdown(endTime);
                } else {
                    localStorage.removeItem(`resendEndTime_${this.id}`);
                }
            }
        } catch (error) {
            console.error('Error during created lifecycle hook:', error);
        }
    },
    props: ["id"],
    mixins: [mixin],
    methods: {
        goToPayload(orderId) {
            this.$router.push({
                name: "Order Details",
                params: { id: orderId },
            });
        },
        async cancelOrder() {
            this.cancelOrderSpinner = true;
            cancelOrder(this.order.id, { status: "cancelled" })
                .then((res) => {
                    this.cancelOrderSpinner = false;
                    this.runToast(this.$t("success.order_cancel"), "success");
                })
                .catch((error) => {
                    this.cancelOrderSpinner = false;
                });
        },
        async getLocations(order) {
            this.mapData = [];
            let pickup_address = {};
            pickup_address.type = "pickup_address";
            pickup_address.latitude =
                order.type == "Store_pickup" && order.pickup_store_id != null
                    ? order?.pickup_store?.latitude
                    : order?.pickup_address?.latitude;
            pickup_address.longitude =
                order.type == "Store_pickup" && order.pickup_store_id != null
                    ? order?.pickup_store?.longitude
                    : order?.pickup_address?.longitude;
            pickup_address.address =
                order.type == "Store_pickup" && order.pickup_store_id != null
                    ? order?.pickup_store?.name
                    : order?.pickup_address?.address_line1;
            if (
                typeof pickup_address.latitude !== "undefined" &&
                pickup_address.latitude !== null &&
                typeof pickup_address.longitude !== "undefined" &&
                pickup_address.longitude !== null
            ) {
                await this.mapData.push(pickup_address);
            }

            if (
                order.type == "Package" &&
                order.packages.length > 0 &&
                order.parent_id == null
            ) {
                order.packages.forEach((element) => {
                    let dropoff_address = {};
                    dropoff_address.type = "dropoff_address";
                    dropoff_address.latitude =
                        element.dropoff_address?.latitude;
                    dropoff_address.longitude =
                        element.dropoff_address?.longitude;
                    dropoff_address.address =
                        element.dropoff_address?.address_line1;
                    if (
                        typeof dropoff_address.latitude !== "undefined" &&
                        dropoff_address.latitude !== null &&
                        typeof dropoff_address.longitude !== "undefined" &&
                        !dropoff_address.longitude !== null
                    ) {
                        this.mapData.push(dropoff_address);
                    }
                });
            } else {
                let dropoff_address = {};
                dropoff_address.type = "dropoff_address";
                dropoff_address.latitude = order?.dropoff_address?.latitude;
                dropoff_address.longitude = order?.dropoff_address?.longitude;
                dropoff_address.address = order?.dropoff_address?.address_line1;
                if (
                    typeof dropoff_address.latitude !== "undefined" &&
                    dropoff_address.latitude !== null &&
                    typeof dropoff_address.longitude !== "undefined" &&
                    !dropoff_address.longitude !== null
                ) {
                    this.mapData.push(dropoff_address);
                }
            }
        },
        async getOrder() {
            this.orderLoadingSpinner = true;
            await getOrder(this.id, {
                include:
                    "vehicle_type,shipment_type,vehicle,provider,pickup_address,dropoff_address,customer,invoice,status_histories,payments,complaints,complaints.complaintBy,packages,items,pickup_store,cancelled_by,coupon_code,offer",
                service_provider_company_id: this.$store.getters.user
                    ?.service_provider_company?.id
                    ? this.$store.getters.user?.service_provider_company?.id
                    : null,
                store_id: this.storeId ? this.storeId : null,
            })
                .then(async (res) => {
                    if (
                        res.provider_id == null &&
                        (this.hasRole(1) || this.hasRole(6))
                    ) {
                        await this.getProvider(res);
                        await this.getSystemSetting();
                    }
                    this.orderLoadingSpinner = false;
                    this.order = res;
                    this.customer = res.customer;
                    this.statusHistories = res.status_histories;
                    this.getLocations(this.order);

                    // Check resend end time for the current order
                    const storedEndTime = localStorage.getItem(`resendEndTime_${this.order.id}`);
                    if (storedEndTime && !isNaN(storedEndTime)) {
                        const endTime = parseInt(storedEndTime, 10);
                        if (Date.now() < endTime) {
                            this.resendDisabled = true;
                            this.startCountdown(endTime);
                        } else {
                            localStorage.removeItem(`resendEndTime_${this.order.id}`);
                        }
                    }
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        async getProvider(order) {
            let providerFilters = {
                status: "approved",
            };

            providerFilters["vehicle_type_id"] = order.vehicle_type_id;
            providerFilters["active_within_months"] = 6;
            if (order.store_id != null) {
                providerFilters["allow_business_order"] = true;
            }
            await getUsers("admin", {
                role: "provider",
                limit: 250,
                order_pickup_latitude:
                    order.type == "Store_pickup" &&
                    order.pickup_store_id != null
                        ? order?.pickup_store.latitude
                        : order?.pickup_address?.latitude,
                order_pickup_longitude:
                    order.type == "Store_pickup" &&
                    order.pickup_store_id != null
                        ? order?.pickup_store.longitude
                        : order?.pickup_address?.longitude,
                filter: providerFilters,
            }).then(async (res) => {
                this.providers = res.data;
            });
        },
        async getSystemSetting() {
            await getSettings({
                limit: -1,
            })
                .then(async (res) => {
                    let setting = res.data.filter((config) => {
                        return config.key == "provider_order_range_km";
                    });
                    this.settingMapRadiusRange = setting[0].value;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        providerView() {
            if (this.order.provider_id != null) {
                this.$router.push({
                    name: "Provider Details",
                    params: { id: this.order.provider_id },
                });
            }
        },
        customerView() {
            if (this.order.customer_id != null) {
                this.$router.push({
                    name: "Customer Details",
                    params: { id: this.order.customer_id },
                });
            }
        },
        addCompensation(compensationTo) {
            this.compensationTo = compensationTo;
            this.showAddCompensationModal = true;
        },
        deductFine() {
            this.showDeductFineModal = true;
        },
        confirmModal() {
            this.showConfirmModal = true;
        },
        async resendVerification() {
            try {
                this.resendDisabled = true;
                this.showConfirmModal = false;
                const endTime = Date.now() + 60000; // 60 seconds from now
                localStorage.setItem(`resendEndTime_${this.order.id}`, endTime);

                await resendVerificationCode(this.order.id);
                this.runToast(this.$t("success.resend_code"), "success");

                // Start the countdown
                this.startCountdown(endTime);
            } catch (error) {
                console.error('Error in resendVerification:', error);
                this.runToast(this.$t("apiError"), "error");
            }
        },
        startCountdown(endTime) {
            if (this.countdownInterval) {
                clearInterval(this.countdownInterval);
            }

            const updateCountdown = () => {
                const remainingTime = Math.max(0, endTime - Date.now());
                this.countdown = Math.ceil(remainingTime / 1000);

                if (remainingTime <= 0) {
                    this.resendDisabled = false;
                    clearInterval(this.countdownInterval);
                    localStorage.removeItem(`resendEndTime_${this.order.id}`);
                }
            };

            updateCountdown();
            this.countdownInterval = setInterval(updateCountdown, 1000);
        },
        async cancelOrder(order) {
            let activeOrderStatuses = [
                "On The Way",
                "Picked Up",
                "Dropped Off",
            ];

            if (
                order.store_id != null &&
                activeOrderStatuses.includes(order.status)
            ) {
                let setting = await this.getSystemSettings(
                    "business_order_cancel_price"
                );
                this.orderCancelMessage = this.$t(
                    "cancel_order_amount_confirmation",
                    {
                        amount: setting?.value,
                    }
                );
            } else {
                this.orderCancelMessage = this.$t("order_cancel_confirm");
            }
        },
        getSystemSettings(key = null) {
            return new Promise((resolve) => {
                getSettings({
                    limit: -1,
                })
                    .then((res) => {
                        if (key) {
                            res.data.forEach(function (item) {
                                if (item.key == key) {
                                    resolve(item);
                                }
                            });

                            resolve(null);
                        }
                    })
                    .catch(({ response }) => {
                        this.apiError(response);
                    });
            });
        },
        cleanExpiredResendTimes() {
            Object.keys(localStorage).forEach((key) => {
                if (key.startsWith('resendEndTime_')) {
                    const storedEndTime = localStorage.getItem(key);
                    if (storedEndTime && !isNaN(storedEndTime)) {
                        const endTime = parseInt(storedEndTime, 10);
                        if (Date.now() > endTime) {
                            localStorage.removeItem(key);
                        }
                    }
                }
            });
        }
    },
    beforeUnmount() {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
        }
    },
};
</script>
<style scoped lang="scss">
.user-profile {
    border: 2px;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #f6f9fc;
}
.user-profile-item {
    margin-top: 2px;
}
.avatar-sm {
    width: 50px !important;
    height: 50px !important;
}
.compensation-btn {
    display: flex;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    padding: 0 !important;
    justify-content: space-between;
}
.compensation-btn button {
    width: 175px;
    padding: 10px 20px !important;
    border-radius: 50px !important;
}
.compensation-btn-wrap {
    margin-left: 5px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
@media screen and (max-width: 1024px) {
    .compensation-btn-wrap {
        flex: 100% !important;
        max-width: 100% !important;
        padding: 0 !important;
    }
    .compensation-btn-wrap button {
        width: 100%;
    }
}
</style>
