import List from "@/views/Pages/Complaint/List";

export default [
    {
        path: "complaints",
        name: "complaints",
        components: { default: List },
        meta: {
            title: "complaints",
            role: [1, 6, 8],
        },
    },
];
