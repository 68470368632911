<template>
    <div>
        <!-- Add Notes Button -->
        <slot :clickEvent="notesModal">
            <div class="note-wrap">
                <button class="btn bg-sky-dark rounded-sm" @click="notesModal">
                    {{ $t("add_notes") }}
                </button>
            </div>
        </slot>
        <!-- Notes Card -->
        <div v-if="notes.length > 0">
            <card
                class="mb-0"
                type="frame"
                v-for="noteData in notes"
                :key="noteData.id"
            >
                <div class="d-flex justify-content-between">
                    <div class="font-weight-bold">
                        <p>{{ noteData.notes }}</p>
                        <p>{{ noteData.date_time }}</p>
                    </div>
                    <div class="note-author font-weight-bold">
                        {{ $t("added_by") }} {{ noteData.note_by }}
                    </div>
                </div>
            </card>
        </div>
        <div v-else>
            <p class="text-center mt-5">{{ $t("no_notes") }}</p>
        </div>

        <!-- Modal  -->
        <modal
            :show="showModal"
            @close="showModal = false"
            size="sm"
            body-classes="p-0"
        >
            <template v-slot:header>
                <div class="font-weight-900 text-lg text-center mt-2 mb-1">
                    {{ $t("add_notes") }}
                </div>
            </template>
            <div class="px-3 d-block">
                <Form @submit="addNotes" ref="addNotesModalRef">
                    <Field
                        as="textarea"
                        name="notes"
                        class="form-control"
                        rules="required"
                        :label="$t('notes')"
                        rows="5"
                        :placeholder="$t('notes')"
                        v-model="text"
                    >
                    </Field>
                    <ErrorMessage name="notes" class="error"></ErrorMessage>
                    <div class="w-full text-center my-4">
                        <base-button
                            native-type="submit"
                            class="btn bg-primary text-white"
                            :loading="submitSpinner"
                        >
                            {{ $t("save") }}
                        </base-button>
                        <input
                            type="button"
                            :value="$t('close')"
                            @click="(showModal = false), (text = '')"
                            class="btn bg-primary text-white"
                        />
                    </div>
                </Form>
            </div>
        </modal>
        <!-- End of Notes Section  -->
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import {
    getUserNotes,
    getOrderNotes,
    addUserNotes,
    addOrderNotes,
} from "@/api/notes";

export default {
    data() {
        return {
            showModal: false,
            notes: [],
            text: "",
            submitSpinner: false,
            isLoading: false,
        };
    },
    components: {},
    props: {
        type: {
            type: String,
            default: "user",
        },
        id: [String, Number],
    },
    mixins: [mixin],
    created() {
        this.getNotes();
    },
    mounted() {},
    methods: {
        notesModal() {
            this.showModal = true;
            this.$refs.addNotesModalRef.resetForm();
        },
        addNotes(data) {
            this.submitSpinner = true;
            if (this.type == "order") {
                addOrderNotes(this.id, data, {
                    // In Store Admin User Account , Store Id is required
                    store_id:
                        this.$store.getters.user.role_id === 1
                            ? null
                            : this.$store.getters.store?.id,
                    service_provider_company_id:
                        this.$store.getters.serviceProviderCompany?.id || null,
                })
                    .then((res) => {
                        this.$refs.addNotesModalRef.resetForm();
                        this.text = "";
                        this.submitSpinner = false;
                        this.showModal = false;
                        this.runToast(res.message, "success");
                        this.$emit("notesModalCompleted");
                        this.getNotes();
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            } else {
                addUserNotes(this.id, data)
                    .then((res) => {
                        this.text = "";
                        this.$refs.addNotesModalRef.resetForm();
                        this.submitSpinner = false;
                        this.showModal = false;
                        this.runToast(res.message, "success");
                        this.getNotes();
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            }
        },
        getNotes() {
            if (this.type == "order") {
                getOrderNotes(this.id, {
                    limit: -1,
                    store_id:
                        this.$store.getters.user.role_id === 1
                            ? null
                            : this.$store.getters.store?.id,
                    service_provider_company_id:
                        this.$store.getters.serviceProviderCompany?.id || null,
                })
                    .then((res) => {
                        this.notes = res.data;
                    })
                    .catch(({ response }) => {
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            } else {
                getUserNotes(this.id)
                    .then((res) => {
                        this.notes = res.data;
                    })
                    .catch(({ response }) => {
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin-top: 10px;
    padding: 0px !important;
}
.note-author {
    font-size: 12px;
}
.wrapper.rtl .note-date {
    text-align: left;
}
.wrapper.rtl .note-author {
    text-align: right;
}
</style>
