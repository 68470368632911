<template>
    <div>
        <hr />
        <div class="card py-3 px-5 rounded-md">
            <!-- Filter Section Start -->
            <div class="d-flex mb-4">
                <!-- <search-filter
                    :filters="[
                        {
                            id: 'user_name',
                            label: $t('name'),
                        },
                    ]"
                    @search="(searchObject) => (filters = searchObject)"
                /> -->
                <drop-down-filter
                    class="ml-3 mr-3"
                    :dropDownFilters="[
                        {
                            id: 'type',
                            label: $t('process_type'),
                            options: processTypeOptions,
                        },
                    ]"
                    @dropDownOptionSelected="
                        (optionsObject) =>
                            (filters = { ...filters, ...optionsObject })
                    "
                />
            </div>
            <!-- Filter Section End  -->

            <div class="row">
                <main
                    :class="
                        isFilter
                            ? 'col-md-9 col-xs-11 p-l-2 p-t-2'
                            : 'col-md-12 col-xs-11 p-l-2 p-t-2'
                    "
                >
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="credit_histories"
                            v-loading="isLoading"
                            :empty-text="$t('no_data_found')"
                        >
                            <!-- <el-table-column
                                :label="$t('user')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.user?.name }}</span
                                    >
                                </template>
                            </el-table-column> -->
                            <el-table-column
                                :label="$t('amount_of_the_process')"
                                min-width="100px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-nowrap"
                                    >
                                        {{ row.operated_credit }} SAR
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('credit_amount_after_process')"
                                min-width="100px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0"
                                        >{{ row.updated_credit }} SAR</span
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('process_type')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <custom-label :label="row.type" />
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('payment_status')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <custom-label
                                        v-if="
                                            row.type == 'credit_transfer' ||
                                            row.type ==
                                                'referral_credit_transfer'
                                        "
                                        :label="row.payment?.status"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('reason')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">{{
                                        row.reason
                                    }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('dateTime')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">{{
                                        row.created_at
                                    }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="credit_histories.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
        <!--  End of Table -->
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { collection } from "@/api/creditHistory";
import processTypeOptions from "@/data/walletHistoryProcessType";
import processType from "@/components/common/WalletHistoryProcessType";

export default {
    data() {
        return {
            credit_histories: [],
            notification_for: "",
            currentPage: 1,
            filters: {
                //user_name: null,
                type: null,
            },
            userOptions: [],
            showButtonLoader: false,
            showUsersDropdown: false,
            showModal: false,
            isLoading: false,
            meta: {},
            totalData: null,
            isFilter: false,
            processTypeOptions,
        };
    },
    props: ["userId"],
    mixins: [mixin],
    created() {
        this.getCreditHistories();
    },
    components: {
        processType,
    },
    watch: {
        currentPage: {
            handler: function () {
                this.getCreditHistories();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.currentPage = 1;
                this.getCreditHistories();
            },
        },
    },
    methods: {
        toggleFilter() {
            this.isFilter = !this.isFilter;
            if (this.isFilter) {
                $("#togglebar").addClass("show");
            } else {
                $("#togglebar").removeClass("show");
            }
        },
        getCreditHistories() {
            this.isLoading = true;
            this.filters.user_id = this.userId;
            collection({
                include: "user,payment",
                page: this.currentPage,
                filter: this.filters,
            })
                .then((res) => {
                    this.credit_histories = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                //user_name: "",
                type: "",
            };
        },
    },
};
</script>
