import axiosInstance from "@/plugins/customAxios";

export const getShipmentTypes = (params = null) => {
    return axiosInstance
        .get("v1/shipment-types", { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getShipmentType = (id, params = null) => {
    return axiosInstance
        .get(`v1/admin/shipment-types/${id}`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const editShipmentType = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/admin/shipment-types/${id}`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};
export const addShipmentType = (data, params = null) => {
    return axiosInstance
        .post("v1/admin/shipment-types", data, { params: params })
        .then((res) => {
            return res.data;
        });
};
