import List from "@/views/Pages/ShipmentType/List";
import Upsert from "@/views/Pages/ShipmentType/Upsert";

export default [
    {
        path: "shipment-type",
        components: { default: List },
        name: "ShipmentType List",
        meta: {
            title: "shipment_type",
            role: [1],
        },
    },
    {
        path: "shipment-type/:id",
        components: { default: Upsert },
        props: true,
        name: "ShipmentType Upsert",
        meta: {
            title: "shipment_type",
            role: [1],
        },
    },
];
