import { configure, defineRule } from "vee-validate";
import en from "@vee-validate/i18n/dist/locale/en.json";
import ar from "@vee-validate/i18n/dist/locale/ar.json";
import { localize, setLocale } from "@vee-validate/i18n";
import {
    required,
    email,
    min,
    length,
    confirmed,
    regex,
    max,
    max_value,
    min_value,
    digits,
    numeric,
    integer,
} from "@vee-validate/rules";
import $i18n from "@/plugins/lang/i18n";
defineRule("required", required);
defineRule("email", email);
defineRule("min", min);
defineRule("max", max);
defineRule("max_value", max_value);
defineRule("min_value", min_value);
defineRule("length", length);
defineRule("numeric", numeric);
defineRule("confirmed", confirmed);
defineRule("digits", digits);

defineRule("regex", regex);
defineRule("passwordRule", (value) => {
    // pattern of at least one number one alphabel and min 8 and max 20 characters
    const pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9@]{8,20}$/;
    if (pattern.test(value)) {
        return true;
    } else return $i18n.global.t("passwordRule");
});

defineRule("integer", integer);

defineRule("minMax", (value, [min, max]) => {
    // The field is empty so it should pass
    if (!value || !value.length) {
        return true;
    }
    const numericValue = Number(value);
    if (numericValue < min) {
        return `This field must be greater than ${min}`;
    }
    if (numericValue > max) {
        return `This field must be less than ${max}`;
    }
    return true;
});

defineRule("decimal", (value, [decimals = "*", separator = "."]) => {
    // The field is empty so it should pass
    if (value === null || value === undefined || value === "") {
        return {
            valid: false,
        };
    }
    if (Number(decimals) === 0) {
        return {
            valid: /^-?\d*$/.test(value),
        };
    }
    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
    const regex = new RegExp(
        `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    );

    return {
        valid: regex.test(value),
    };
});

configure({
    validateOnInput: true,
    generateMessage: localize({
        en,
        ar,
    }),
});

setLocale(localStorage.getItem("lang") || "ar");