import List from "@/views/Pages/Item/List";
import Upsert from "@/views/Pages/Item/Upsert";
export default [{
        path: "items",
        components: { default: List },
        name: "items",
        meta: {
            title: "items",
            role: [1, 6],
        },
    },
    {
        path: "items/:id",
        components: { default: Upsert },
        props: true,
        name: "item",
        meta: {
            title: "items",
            role: [1, 6],
        },
    },
];