<template>
    <div>
        <el-table
            class="table-responsive table-flush"
            header-row-class-name="thead-light table-header-wrap"
            :data="ratings"
            v-loading="isLoading"
            :empty-text="$t('no_data_found')"
        >
            <el-table-column
                :label="$t('order_no')"
                min-width="140px"
                v-if="ratingType == 'provider'"
            >
                <template v-slot="{ row }">
                    <order-number-label :row="row?.order"></order-number-label>
                </template>
            </el-table-column>
            <el-table-column :label="$t('rating')" min-width="80px">
                <template v-slot="{ row }">
                    <!-- Show the star rating - New design -->
                    <div v-if="row?.rating_tag.length > 0">
                        <span
                            class="font-weight-600 name mb-0"
                            v-if="ratingType == 'order' && row.order_experience"
                        >
                            {{ row.order_experience }}/5</span
                        >
                        <span
                            class="font-weight-600 name mb-0"
                            v-if="
                                ratingType == 'provider' && row.provider_service
                            "
                        >
                            {{ row.provider_service }}/5</span
                        >
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                :label="$t('rating_answer')"
                min-width="200px"
                prop="email"
            >
                <template v-slot="{ row }">
                    <div v-if="row?.rating_tag.length > 0">
                        <span
                            class="font-weight-600 name mb-1 word-break"
                            v-for="tag in row?.rating_tag"
                            :key="tag.tag"
                        >
                            <p>{{ $t(tag.tag) }}</p>
                        </span>
                    </div>
                    <div v-else>
                        <span class="font-weight-600 name mb-1">
                            {{ $t("orderExperienceQuestion") }}
                            :
                            {{ row.order_experience }}/5
                        </span>

                        <span class="d-flex font-weight-600 name mb-1">
                            {{ $t("providerServiceQuestion") }}
                            :
                            {{ row.provider_service }}/5
                        </span>

                        <span class="d-flex font-weight-600 name mb-1">
                            {{ $t("recommendAppQuestion") }}
                            :
                            {{ row.app_recommendation }}/5
                        </span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                :label="$t('average_rating')"
                min-width="90px"
                prop="email"
                v-if="ratingType == 'provider'"
            >
                <template v-slot="{ row }">
                    <span
                        class="font-weight-600 name mb-1"
                        v-if="row?.rating_tag.length <= 0"
                    >
                        {{ row.average_rating }}/5
                    </span>
                </template>
            </el-table-column>

            <el-table-column
                :label="$t('review')"
                min-width="150px"
                prop="status"
            >
                <template v-slot="{ row }">
                    <span class="font-weight-600 name mb-0">{{
                        row.review
                    }}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$t('rating_by')" min-width="150px">
                <template v-slot="{ row }">
                    <span
                        class="font-weight-600 name mb-0"
                        v-if="row.rating_by"
                        >{{ row.rating_by?.name }}</span
                    >
                    <span
                        class="font-weight-600 name mb-0 text-danger"
                        v-else
                        >{{ $t("customerDeleted") }}</span
                    >
                </template>
            </el-table-column>
        </el-table>
        <div class="card-footer p-0 pt-5 pb-5" v-if="ratings.length > 0">
            <base-pagination
                :meta="ratingsListMeta"
                v-model="ratingsCurrentPage"
                :total="ratingsTotalData"
            ></base-pagination>
        </div>
        <div v-if="ratingType == 'provider'">
            <span class="font-weight-400 name mb-0"
                >{{ $t("average_rating") }}: {{ averageRating }}</span
            >
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getRatings } from "@/api/ratings";
import OrderNumberLabel from "@/components/common/OrderNumberLabel.vue";

export default {
    data() {
        return {
            ratingsCurrentPage: 1,
            ratings: [],
            isLoading: true,
            ratingsListMeta: {},
            filters: {
                order_id: "",
                rating_to: "",
            },
        };
    },
    mixins: [mixin],
    watch: {
        ratingsCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getRatings();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.ratingsCurrentPage = 1;
                this.getRatings();
            },
        },
    },
    components: {
        OrderNumberLabel,
    },
    created() {
        this.getRatings();
    },
    props: ["id", "ratingType", "averageRating"],
    methods: {
        getRatings() {
            getRatings({
                page: this.ratingsCurrentPage,

                filter: {
                    rating_to: this.ratingType == "provider" ? this.id : null,
                    order_id: this.ratingType == "order" ? this.id : null,
                },
                include: "order,ratingBy,ratingTag",
            })
                .then((res) => {
                    this.isLoading = false;

                    this.ratings = res.data;
                    this.ratingsTotalData = res.meta.total;
                    this.ratingsListMeta = res.meta;
                })
                .catch(({ response }) => {
                    this.isLoading = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
    },
};
</script>

<style lang="scss">
.word-break {
    word-break: break-word !important;
}
</style>
