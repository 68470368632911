// Vue 3 New Syntax and style of Creating App Instance
import { createApp } from "vue";
import App from "./App.vue";

// i18n is a translation library which provides translation service in project in different languages
import $i18n from "@/plugins/lang/i18n.js";

// Vue Router for having Project work as Single Page Application Wihtout Refreshing and making separate request to Server
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
// Element Plus UI Library for Vue JS
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";

// Toast library shows Rich Beautiful Notifications in admin panel
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// Store is a global application storage of Admin panel data
import store from "./store/index";
// Vee Validate Plugin is a configuration file for Form Validation and showing Error Messages in different Languages
import "@/plugins/vee-validate.js";

import mitt from 'mitt'
const emitter = mitt()

const appInstance = createApp(App);
appInstance.config.globalProperties.$emitter = emitter

// jquery support
window.$ = window.jQuery = require("jquery");
// it is for setting devtools function to work on browser
appInstance.config.devtools = true;

appInstance.use(store); // store is for managing global app state single source of truth
appInstance.use($i18n); // i18n is for providing multiple languages support
appInstance.use(router); // router is for routing functions

appInstance.use(Toast); // toast is for custom notifications
appInstance.use(ArgonDashboard);
appInstance.use(ElementPlus);
appInstance.mount("#app");

// Vue.config.devtools = true;

export { appInstance };