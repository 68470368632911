<template>
    <div class="d-inline">
        <modal
            footerClasses="justify-content-center"
            :show="showModal"
            @close="$emit('close')"
            size="md"
            body-classes="p-0"
        >
            <template v-slot:header>
                <div class="position-relative">
                    <button class="back-button" @click="$emit('back')">←</button>
                    <div class="font-weight-700 text-lg mt-2 mb-1 text-center text-black">
                        {{ $t("select_order_type") }}
                    </div>
                    <p class="font-weight-300 text-sm mt-2 mb-1 text-center text-black">
                        {{ $t("select_oot_order_type_description") }}
                    </p>
                </div>
            </template>

            <div class="pt-0 px-4 order-type">
                <div class="card-deck">
                    <card>
                        <template v-slot:image>
                            <img class="card-img-top" src="@/assets/svg/express-service.svg" />
                        </template>
                        <h2 class="card-title text-center">
                            {{ $t("in_town_order") }}
                        </h2>
                        <base-button class="text-white bg-primary rounded-lg p-2 mt-3" @click="selectOrderType('inTown')">
                            {{ $t("place_now") }}
                        </base-button>
                    </card>
                    <card>
                        <template v-slot:image>
                            <img class="card-img-top" src="@/assets/svg/express-service.svg" />
                        </template>
                        <h2 class="card-title text-center">
                            {{ $t("out_of_town_order") }}
                        </h2>
                        <base-button class="text-white bg-primary rounded-lg p-2 mt-3" @click="selectOrderType('outOfTown')">
                            {{ $t("place_now") }}
                        </base-button>
                    </card>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    props: ["showModal"],
    emits: ["close", "openInTownOrderPopup", "openOutOfTownOrderPopup", "back"],
    methods: {
        selectOrderType(orderType) {
            if (orderType === "inTown") {
                this.$router.push({ name: 'Add In Town Order' });
            } else {
                this.$router.push({ name: 'Add Out of Town Order' });
            }
            this.$emit("close");
        },
    },
};
</script>

<style>
.back-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

.package-order-card .card-title,
.package-order-card .card__content,
.package-order-card base-button {
    opacity: 0.5;
    pointer-events: none;
}
</style>
