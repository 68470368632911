import axiosInstance from "@/plugins/customAxios";

export const collection = (params = null) => {
    return axiosInstance
        .get("v1/credit-histories", { params: params })
        .then((res) => {
            return res.data;
        });
};

export const addCreditHistory = (data, params = null) => {
    return axiosInstance
        .post(`v1/credit-histories`, data, {
            params: params,
        })
        .then((res) => {
            return res.data;
        });
};