<template>
    <modal
        :show="showNoteModal"
        @close="showNoteModal = false"
        size="sm"
        body-classes="p-0"
    >
        <template v-slot:header>
            <div class="font-weight-900 text-lg text-center mt-2 mb-1">
                {{ $t("add_payment_change_reason") }}
            </div>
        </template>
        <div class="px-3 d-block">
            <Form @submit="submitPaymentChange" ref="noteFormRef">
                <div class="form-group">
                    <label for="note">{{ $t("change_payment_method") }}</label>
                    <textarea
                        id="note"
                        name="note"
                        class="form-control"
                        rows="5"
                        v-model="noteText"
                        :placeholder="$t('payment_change_reason')"
                        required
                    ></textarea>
                </div>
                <ErrorMessage name="note" class="error"></ErrorMessage>
                <div class="w-full text-center my-4">
                    <base-button
                        native-type="submit"
                        class="btn bg-primary text-white"
                        :loading="submitSpinner"
                    >
                        {{ $t("save") }}
                    </base-button>
                    <input
                        type="button"
                        :value="$t('close')"
                        @click="(showNoteModal = false), (noteText = '')"
                        class="btn bg-primary text-white"
                    />
                </div>
            </Form>
        </div>
    </modal>
    <div>
        <div class="bg-white rounded-md p-3 position-relative">
            <div class="btn-cancel">
                <alert-modal
                    @click="cancelOrder(order)"
                    alertCategory="view_cancel_order"
                    v-if="cancelOrderAllowed(order.status)"
                    :id="order.id"
                    :body="orderCancelMessage"
                    :order="order"
                    @alertModalCompleted="cancelOrderCallback"
                >
                    <template v-slot:orderNo>
                        <h3>
                            {{ $t("order_no") }} :
                            {{ order.order_no }}
                        </h3>
                    </template>
                </alert-modal>
            </div>
            <tabs
                fill
                class="flex-column flex-md-row px-3 order-tab-title"
                tabNavClasses="mb-3"
            >
                <card class="px-0 -mx-2">
                    <!-- Order Details section -->
                    <tab-pane :title="$t('order_detail')" class="bg-white">
                        <div class="d-flex">
                            <ul
                                class="list-group col-md-6 order-detail detail-table ml-2"
                            >
                                <li class="list-group-item detail-table__title">
                                    <h2 class="mb-0">{{ $t("order_no") }}</h2>
                                    <h2 class="mb-0 ml-2">
                                        {{ order.order_no }}
                                    </h2>
                                </li>
                                <li
                                    class="list-group-item"
                                    v-if="order.source == 'zidship_order'"
                                >
                                    <b class="font-weight-500"
                                        >{{ $t("zidship_order_no") }}
                                    </b>
                                    <div>
                                        <span>{{
                                            order.zidship_order_id
                                        }}</span>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <b class="font-weight-500"
                                        >{{ $t("status") }}
                                    </b>
                                    <div>
                                        <custom-label :label="order.status" />
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <b>{{ $t("vehicle") }} </b>
                                    <span>{{ order.vehicle?.name }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b>{{ $t("vehicle_type") }} </b>
                                    <span>{{ order.vehicle_type?.type }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b>{{ $t("shipment_type") }} </b>
                                    <span>{{ order.shipment_type?.type }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b>{{ $t("no_of_pieces") }} </b>
                                    <span>{{ order.no_of_pieces }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b>{{ $t("order_time") }} </b>
                                    <span>{{ order.order_date }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b
                                        >{{
                                            $t("package_order_messages.helper")
                                        }}
                                    </b>
                                    <span>{{
                                        order.helper === true ? "Yes" : "No"
                                    }}</span>
                                </li>
                                <li
                                    class="list-group-item"
                                    v-if="order.proof_of_delivery_url"
                                >
                                    <b>{{ $t("proof_of_delivery.title") }}</b>
                                    <base-button
                                        native-type="button"
                                        class="btn btn-sm btn--new-order bg-primary text-white rounded-lg deliver__button"
                                        @click="openProofOfDeliveryModal"
                                    >
                                        {{ $t("click_here") }}
                                    </base-button>
                                </li>
                                <li
                                    class="list-group-item"
                                    v-if="order.airway_bill_url"
                                >
                                    <b>{{ $t("airWaybill") }}</b>
                                    <base-button
                                        native-type="button"
                                        class="btn btn-sm btn--new-order bg-primary text-white rounded-lg deliver__button"
                                    >
                                        <a
                                            :href="order.airway_bill_url"
                                            class="text-white"
                                            target="_blank"
                                        >
                                            {{ $t("click_here") }}
                                        </a>
                                    </base-button>
                                </li>
                            </ul>
                            <ul class="list-group col-md-6 detail-table">
                                <li class="list-group-item">
                                    <div class="d-flex col-md-12 p-0">
                                        <div class="col-md-6 p-0">
                                            <h2 class="mb-0">
                                                {{ $t("invoice_detail") }}
                                            </h2>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <b class="font-weight-500"
                                        >{{ $t("invoice_number") }}
                                    </b>
                                    <span>{{
                                        order.invoice?.invoice_no || ""
                                    }}</span>
                                </li>

                                <li class="list-group-item d-flex flex-column align-items-start justify-content-between">
                                    <div class="d-flex w-100 justify-content-between">
                                        <div class="d-flex w-100 justify-content-between">
                                            <b>{{ $t("credit_statements.payment_type") }}</b>
                                            <span>{{ $t("credit_statements." + order.payment_method) }}</span>
                                        </div>
                                    </div>
                                    <div v-if="(userRole == 1 || userRole == 6) && (order.payment?.status !== 'paid') && (order.payment_method == 'online' || order.payment_method == 'cash')" class="mt-4 w-100 d-flex justify-content-center">
                                        <base-button
                                            class="text-red bg-transparent border-warning"
                                            @click="changePaymentType"
                                        >
                                            {{ $t("change_payment_method") }}
                                        </base-button>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <b>{{ $t("invoice.sub_total") }} </b>
                                    <span
                                        >{{ order.invoice?.sub_total || "0" }}
                                        {{ " " + $t("SAR") }}</span
                                    >
                                </li>
                                <li
                                    class="list-group-item"
                                    v-if="order.source == 'one8_order'"
                                >
                                    <b>{{ $t("extra_service_price") }} </b>
                                    <span
                                        >{{
                                            order.invoice
                                                ?.extra_service_price || "0"
                                        }}
                                        {{ " " + $t("SAR") }}</span
                                    >
                                </li>
                                <li
                                    class="list-group-item"
                                    v-if="order.type == 'Store_pickup'"
                                >
                                    <b>{{ $t("assembly_charge") }} </b>
                                    <span
                                        >{{
                                            order.invoice?.assembly_charge ||
                                            "0"
                                        }}
                                        {{ " " + $t("SAR") }}</span
                                    >
                                </li>
                                <li
                                    class="list-group-item"
                                    v-if="order.source == 'one8_order'"
                                >
                                    <b>{{ $t("discount") }} </b>
                                    <span
                                        >{{ order.invoice?.discount || "0" }}
                                        {{ " " + $t("SAR") }}</span
                                    >
                                </li>
                                <li class="list-group-item">
                                    <b>{{ $t("total") }} </b>
                                    <span>
                                        {{ order.invoice?.total }}
                                        {{
                                            order.invoice?.total != null
                                                ? $t("SAR")
                                                : ""
                                        }}</span
                                    >
                                </li>
                                <li
                                    class="list-group-item"
                                    v-if="
                                        order?.store_id &&
                                        order.type == 'Normal'
                                    "
                                >
                                    <b>{{ $t("invoice_ref_no") }} </b>
                                    <span>
                                        {{ order.invoice_ref_no }}
                                    </span>
                                </li>
                                <li
                                    class="list-group-item"
                                    v-if="
                                        order?.store_id &&
                                        order.type == 'Normal'
                                    "
                                >
                                    <b>{{ $t("invoice_total_price") }} </b>
                                    <span>
                                        {{ order.invoice_total_price }}
                                    </span>
                                </li>
                                <!-- <li
                                    class="list-group-item"
                                    v-if="order?.store_id"
                                ></li> -->
                                <li
                                    class="list-group-item"
                                    v-if="order?.coupon_code"
                                >
                                    <b>{{ $t("coupon_code") }} </b>
                                    <span>{{ order?.coupon_code?.name }}</span>
                                </li>
                            </ul>
                        </div>

                        <div class="">
                            <ul
                                class="list-group row pickup-address detail-table ml-2 mr-2 mt-3"
                            >
                                <li class="list-group-item col-md-12">
                                    <div class="d-flex p-0">
                                        <div class="col-md-6 p-0">
                                            <h2 class="mb-0">
                                                {{
                                                    $t(
                                                        "pickup_and_dropoff_address"
                                                    )
                                                }}
                                            </h2>
                                        </div>
                                    </div>
                                </li>
                                <li
                                    class="list-group-item address-list col-md-2 h-auto"
                                >
                                    <b class="font-weight-500"
                                        >{{ $t("distance") }}
                                    </b>
                                    <br />
                                    <span
                                        >{{
                                            order.distance ? order.distance : 0
                                        }}
                                        {{ $t("kms") }}</span
                                    >
                                </li>
                                <li
                                    class="list-group-item address-list col-md-5 h-auto"
                                >
                                    <b class="font-weight-500"
                                        >{{ $t("pickup_address") }}
                                    </b>
                                    <br />
                                    <span>{{
                                        order.type == "Store_pickup" &&
                                        order.pickup_store_id != null
                                            ? order.pickup_store.name
                                            : order.pickup_address
                                                  ?.address_line1 || ""
                                    }}</span>
                                </li>
                                <li
                                    class="list-group-item address-list col-md-5 h-auto"
                                >
                                    <b class="font-weight-500"
                                        >{{ $t("dropoff_address") }}
                                    </b>
                                    <br />
                                    <span>{{
                                        order.dropoff_address?.address_line1 ||
                                        ""
                                    }}</span>
                                </li>
                            </ul>
                        </div>
                    </tab-pane>

                    <!-- Store pickup order invoice image -->
                    <tab-pane
                        :title="$t('invoiceImage')"
                        class="bg-white"
                        v-if="
                            (hasRole(1) || hasRole(6)) &&
                            order.type == 'Store_pickup'
                        "
                    >
                        <div class="main-order-image">
                            <div class="order-image">
                                <img
                                    :src="order.invoice_image"
                                    alt=""
                                    srcset=""
                                    width="250"
                                />
                            </div>
                        </div>
                    </tab-pane>

                    <!-- Zidship products section -->
                    <tab-pane
                        :title="$t('zidshipProduct')"
                        class="bg-white"
                        v-if="order.source == 'zidship_order'"
                    >
                        <div>
                            <div
                                style="
                                    border: 1px solid #f0f0f0;
                                    border-radius: 13px;
                                "
                                class="payload"
                                v-for="(item, index) in order.items"
                                :key="index"
                            >
                                <ul
                                    class="payload__content"
                                    style="
                                        border: none !important;
                                        margin-bottom: 0 !important;
                                    "
                                >
                                    <li>
                                        <p class="payload__subtitle">
                                            {{ $t("name") }}
                                        </p>
                                    </li>
                                    <li>
                                        <p class="payload__subtitle">
                                            {{ $t("quantity") }}
                                        </p>
                                    </li>
                                    <li>
                                        <p class="payload__subtitle">
                                            {{ $t("zidship_price") }}
                                        </p>
                                    </li>
                                    <li>
                                        <p class="payload__subtitle">
                                            {{ $t("image") }}
                                        </p>
                                    </li>
                                </ul>
                                <ul
                                    class="payload__content"
                                    style="
                                        border: none !important;
                                        margin-bottom: 0 !important;
                                        padding-top: 0 !important;
                                    "
                                >
                                    <li>
                                        <p class="payload__text">
                                            {{
                                                $store.getters.lang == "ar"
                                                    ? item.name_ar
                                                    : item.name
                                            }}
                                        </p>
                                    </li>
                                    <li>
                                        <p class="payload__text">
                                            {{ item.quantity }}
                                        </p>
                                    </li>
                                    <li>
                                        <p class="payload__text">
                                            {{ item.zidship_total }}
                                        </p>
                                    </li>
                                    <li>
                                        <img
                                            :src="item.zidship_image"
                                            alt=""
                                            srcset=""
                                            class="w-50 mb-2"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </tab-pane>

                    <!-- Items Section(Store Order Pickup) -->
                    <tab-pane
                        :title="$t('items')"
                        class="bg-white"
                        v-if="order.type == 'Store_pickup'"
                    >
                        <div>
                            <div
                                style="
                                    border: 1px solid #f0f0f0;
                                    border-radius: 13px;
                                "
                                class="payload"
                                v-for="(item, index) in order.items"
                                :key="index"
                            >
                                <ul
                                    class="payload__content"
                                    style="
                                        border: none !important;
                                        margin-bottom: 0 !important;
                                    "
                                >
                                    <li>
                                        <p class="payload__subtitle">
                                            {{ $t("name") }}
                                        </p>
                                    </li>
                                    <li>
                                        <p class="payload__subtitle">
                                            {{ $t("quantity") }}
                                        </p>
                                    </li>
                                    <li>
                                        <p class="payload__subtitle">
                                            {{ $t("assemble_price") }}
                                        </p>
                                    </li>
                                    <li>
                                        <p class="payload__subtitle">
                                            {{ $t("total_assembly_price") }}
                                        </p>
                                    </li>
                                </ul>
                                <ul
                                    class="payload__content"
                                    style="
                                        border: none !important;
                                        margin-bottom: 0 !important;
                                        padding-top: 0 !important;
                                    "
                                >
                                    <li>
                                        <p class="payload__text">
                                            {{
                                                isAr ? item.name_ar : item.name
                                            }}
                                        </p>
                                    </li>
                                    <li>
                                        <p class="payload__text">
                                            {{ item.quantity }}
                                        </p>
                                    </li>
                                    <li>
                                        <p class="payload__text">
                                            {{ item.assembly_charge }}
                                            {{ $t("SAR") }}
                                        </p>
                                    </li>
                                    <li>
                                        <p class="payload__text">
                                            {{ item.total_assembly_charge }}
                                            {{ $t("SAR") }}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </tab-pane>

                    <!-- Chats Section -->
                    <tab-pane
                        :title="$t('chats')"
                        class="bg-white"
                        v-if="
                            (hasRole(1) || hasRole(6)) && order.store_id == null
                        "
                    >
                        <div class="chat-tab" v-if="chats.length !== 0">
                            <div
                                class="d-flex order-chat"
                                v-for="(chat, index) in chats"
                                :key="index"
                                :class="orderChatCssClass(chat)"
                            >
                                <div>
                                    <div>
                                        <b
                                            v-if="
                                                chat.sender != order.customer.id
                                            "
                                        >
                                            {{
                                                order.provider
                                                    ? order.provider.name
                                                    : $t("providerDeleted")
                                            }}</b
                                        >
                                        <b v-else>
                                            {{ order.customer.name }}
                                        </b>
                                        <br />
                                    </div>
                                    <div>
                                        <p
                                            class="new-line font-weight-400"
                                            v-if="chat.text !== null"
                                        >
                                            {{
                                                chat.text ==
                                                    "invoice_generated" ||
                                                chat.text ==
                                                    "amount_has_been_paid" ||
                                                chat.text == "on_the_way" ||
                                                chat.text == "picked_up" ||
                                                chat.text == "dropped_off" ||
                                                chat.text == "accepted" ||
                                                chat.text ==
                                                    "dropoff_location" ||
                                                chat.text ==
                                                    "pickup_location" ||
                                                chat.text == "invoice_generated"
                                                    ? $t(
                                                          "orderChat." +
                                                              chat.text
                                                      )
                                                    : chatMsgStringReplace(chat)
                                            }}
                                        </p>
                                    </div>
                                    <div
                                        class="mt-3"
                                        v-if="chat.image || chat?.audio"
                                    >
                                        <img
                                            v-if="chat.image !== null"
                                            :src="chat.image"
                                            alt=""
                                            width="200"
                                        />
                                        <audio
                                            controls
                                            v-if="
                                                chat?.audio &&
                                                chat?.audio !== null
                                            "
                                        >
                                            <source
                                                :src="chat.audio"
                                                type="audio/mpeg"
                                            />
                                        </audio>
                                    </div>
                                    <div>
                                        <b class="text-black text-xs mt-5">{{
                                            getFormattedTime(chat.time)
                                        }}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p v-else class="no-offer">
                            {{ $t("message.noChatsAvailable") }}
                        </p>
                    </tab-pane>

                    <!-- Images -->
                    <tab-pane
                        :title="$t('images')"
                        class="bg-white"
                        v-if="
                            hasRole(1) || hasRole(6) || hasRole(4) || hasRole(5)
                        "
                    >
                        <div v-if="images.length !== 0">
                            <div class="main-order-image">
                                <div
                                    class="order-image"
                                    v-for="(image, index) in images"
                                    :key="index"
                                >
                                    <img
                                        :src="image.image"
                                        alt=""
                                        srcset=""
                                        width="250"
                                    />
                                    <div class="main-order-text">
                                        <b class="text-black text-xs mt-5">{{
                                            getFormattedTime(image.time)
                                        }}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="no-offer">
                            {{ $t("message.noOrderImagesFound") }}
                        </div>
                    </tab-pane>

                    <!-- Notes -->
                    <tab-pane :title="$t('notes')">
                        <note :id="order.id" type="order"></note>
                    </tab-pane>

                    <!-- Offers Section  -->
                    <tab-pane
                        v-if="order.store_id == null"
                        :title="$t('offers')"
                        class="bg-white"
                    >
                        <div class="no-offer" v-if="offers.length == 0">
                            {{ $t("no_offers") }}
                        </div>
                        <div
                            style="
                                border: 1px solid #f0f0f0;
                                border-radius: 13px;
                            "
                            v-else
                            class="payload"
                            v-for="(item, index) in offers"
                            :key="index"
                        >
                            <ul
                                class="payload__content"
                                style="
                                    border: none !important;
                                    margin-bottom: 0 !important;
                                "
                            >
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("provider") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("offer_price") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("helper_price") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__subtitle">
                                        {{
                                            order.coupon_code_id
                                                ? $t("total_before_discount")
                                                : $t("total")
                                        }}
                                    </p>
                                </li>
                                <li v-if="order.coupon_code_id">
                                    <p class="payload__subtitle">
                                        {{ $t("total_after_discount") }}
                                    </p>
                                </li>
                                <li v-if="order.helper">
                                    <p class="payload__subtitle">
                                        {{ $t("helper_available") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("status") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("created_at") }}
                                    </p>
                                </li>
                            </ul>
                            <ul
                                class="payload__content"
                                style="
                                    border: none !important;
                                    margin-bottom: 0 !important;
                                    padding-top: 0 !important;
                                "
                            >
                                <li>
                                    <p
                                        class="payload__text"
                                        v-if="item.provider_id != null"
                                    >
                                        {{ item.provider?.name }}
                                    </p>
                                    <p class="payload__text text-danger" v-else>
                                        {{ $t("providerDeleted") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__text">
                                        {{ item.base_price }} {{ $t("SAR") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__text">
                                        {{ item.helper_price }}
                                        {{ $t("SAR") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__text">
                                        {{ item.price_v2 }} {{ $t("SAR") }}
                                    </p>
                                </li>
                                <li
                                    v-if="
                                        order.coupon_code_id &&
                                        item.discount_price
                                    "
                                >
                                    <p
                                        class="payload__text"
                                        :class="
                                            item.discount_price
                                                ? 'offer-margin-left-10'
                                                : ''
                                        "
                                    >
                                        {{ item.price_after_discount }}
                                        {{ $t("SAR") }}
                                    </p>
                                </li>
                                <li v-if="order.helper">
                                    <custom-label
                                        :class="
                                            item.discount_price
                                                ? 'offer-margin-left-10'
                                                : ''
                                        "
                                        :label="
                                            item.is_helper_available
                                                ? 'yes'
                                                : 'no'
                                        "
                                    />
                                </li>
                                <li>
                                    <custom-label :label="item.offer_status" />
                                </li>
                                <li>
                                    <p
                                        class="payload__text"
                                        :class="
                                            item.discount_price
                                                ? 'offer-margin-left'
                                                : ''
                                        "
                                    >
                                        {{ item.created_at }}
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <!-- Show the offer range -->
                        <h2 class="mt-5">
                            {{ $t("maximumAndMinimumOfferRange") }}
                        </h2>
                        <div class="border rounded">
                            <ul class="payload__content border-0 mb-0">
                                <li>
                                    <b>{{ $t("minimumOfferPrice") }}</b
                                    >:
                                    <p class="payload__text">
                                        {{
                                            offerMinPriceWithoutHelper.toFixed(
                                                2
                                            )
                                        }}
                                        {{ $t("SAR") }}
                                    </p>
                                </li>
                                <li>
                                    <b>{{ $t("maximumOfferPrice") }}</b
                                    >:
                                    <p class="payload__text">
                                        {{
                                            offerMaxPriceWithoutHelper.toFixed(
                                                2
                                            )
                                        }}
                                        {{ $t("SAR") }}
                                    </p>
                                </li>
                                <li v-if="order.helper">
                                    <b
                                        >{{ $t("minimumOfferPrice") }} ({{
                                            $t("withHelper")
                                        }})</b
                                    >:
                                    <p class="payload__text">
                                        {{ offerMinPriceWithHelper }}
                                        {{ $t("SAR") }}
                                    </p>
                                </li>
                                <li v-if="order.helper">
                                    <b
                                        >{{ $t("maximumOfferPrice") }} ({{
                                            $t("withHelper")
                                        }})</b
                                    >:
                                    <p class="payload__text">
                                        {{ offerMaxPriceWithHelper }}
                                        {{ $t("SAR") }}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </tab-pane>

                    <!-- Delivery Verification Details Tab  -->
                    <tab-pane
                        :title="$t('delivery_verification_detail')"
                        v-if="
                            order.type == 'Package' &&
                            order.parent_id != null &&
                            order.store_id != null
                        "
                        class="bg-white"
                    >
                        <div class="payload">
                            <ul class="payload__content payload__content-2">
                                <li class="payload__content-li">
                                    <p class="payload__subtitle">
                                        {{ $t("is_check_number_no_of_pieces") }}
                                    </p>
                                    <p class="payload__text">
                                        <custom-label
                                            v-if="
                                                order.is_check_number_no_of_pieces !=
                                                null
                                            "
                                            :label="
                                                order.is_check_number_no_of_pieces
                                            "
                                        />
                                    </p>
                                </li>
                                <li class="payload__content-li">
                                    <p class="payload__subtitle">
                                        {{ $t("is_damage") }}
                                    </p>
                                    <p class="payload__text">
                                        <custom-label
                                            v-if="order.is_damage != null"
                                            :label="order.is_damage"
                                        />
                                    </p>
                                </li>

                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("images") }}
                                    </p>
                                    <div class="payload-image-wrapper">
                                        <div
                                            class="d-flex"
                                            v-for="(
                                                image, index
                                            ) in order?.delivery_verification_image"
                                            :key="index"
                                        >
                                            <img
                                                class="ml-2 w-50"
                                                :src="
                                                    image.delivery_verification_image
                                                "
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </tab-pane>

                    <!-- Status history section -->
                    <tab-pane :title="$t('status_histories')">
                        <status-history-table
                            :status_histories="order.status_histories"
                        ></status-history-table>
                    </tab-pane>

                    <!-- Payment Section  -->
                    <tab-pane
                        v-if="
                            order.payments &&
                            order.payments.length > 0 &&
                            (userRole == 1 ||
                                userRole == 6 ||
                                userRole == 7 ||
                                userRole == 8)
                        "
                        :title="$t('payment')"
                        class="bg-white"
                    >
                        <div class="no-offer" v-if="order.payments.length == 0">
                            {{ $t("no_payment") }}
                        </div>
                        <div
                            style="
                                border: 1px solid #f0f0f0;
                                border-radius: 13px;
                            "
                            v-else
                            class="payload"
                            v-for="(item, index) in order.payments"
                            :key="index"
                        >
                            <ul
                                class="payload__content"
                                style="
                                    border: none !important;
                                    margin-bottom: 0 !important;
                                "
                            >
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("payment_type") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("payment_status") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("payment_ref_id") }}
                                    </p>
                                </li>
                            </ul>
                            <ul
                                class="payload__content"
                                style="
                                    border: none !important;
                                    margin-bottom: 0 !important;
                                    padding-top: 0 !important;
                                "
                            >
                                <li>
                                    <p class="payload__text">
                                        {{ item.payment_method }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__text">
                                        <custom-label :label="item.status" />
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__text">
                                        {{ item.payment_ref_id }}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </tab-pane>

                    <!-- Complaint Section  -->
                    <tab-pane
                        v-if="
                            order.complaints && (userRole == 1 || userRole == 6)
                        "
                        :title="$t('complaints')"
                        class="bg-white"
                    >
                        <div
                            class="no-offer"
                            v-if="order.complaints.length == 0"
                        >
                            {{ $t("complaint_not_found") }}
                        </div>
                        <div
                            style="
                                border: 1px solid #f0f0f0;
                                border-radius: 13px;
                            "
                            class="payload"
                            v-else
                            v-for="(item, index) in order.complaints"
                            :key="index"
                        >
                            <ul
                                class="payload__content"
                                style="
                                    border: none !important;
                                    margin-bottom: 0 !important;
                                "
                            >
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("complaint_no") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("complaint_by") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("reason") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("complaint") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("response") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("date_and_time") }}
                                    </p>
                                </li>
                                <li>
                                    <p class="payload__subtitle">
                                        {{ $t("status") }}
                                    </p>
                                </li>
                            </ul>
                            <ul
                                class="payload__content"
                                style="
                                    border: none !important;
                                    margin-bottom: 0 !important;
                                    padding-top: 0 !important;
                                "
                            >
                                <li class="complaint-text">
                                    <p class="payload__text">
                                        {{ item.complaint_no }}
                                    </p>
                                </li>
                                <li class="complaint-text">
                                    <p
                                        class="payload__text"
                                        v-if="item.complaint_by"
                                    >
                                        {{ item.complaint_by?.name }}
                                    </p>
                                    <p class="payload__text text-danger" v-else>
                                        {{ $t("customerDeleted") }}
                                    </p>
                                </li>
                                <li class="complaint-text">
                                    <p class="payload__text">
                                        {{ item.reason }}
                                    </p>
                                </li>
                                <li class="complaint-text">
                                    <p class="payload__text">
                                        {{ item.complaint }}
                                    </p>
                                </li>
                                <li class="complaint-text">
                                    <p class="payload__text">
                                        {{ item.response }}
                                    </p>
                                </li>
                                <li class="complaint-text">
                                    <p class="payload__text">
                                        {{ item.created_at }}
                                    </p>
                                </li>
                                <li class="complaint-text">
                                    <p class="payload__text">
                                        <custom-label :label="item.status" />
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </tab-pane>

                    <!-- Rating section -->
                    <tab-pane
                        :title="$t('ratings')"
                        class="bg-white"
                        v-if="hasRole(1) || hasRole(6) || hasRole(8)"
                    >
                        <rating-list
                            :id="order?.id"
                            ratingType="order"
                            averageRating=""
                        ></rating-list>
                    </tab-pane>
                </card>
            </tabs>
            <div
                class="d-flex px-4 mt-4 map-lebel"
                v-if="mapData && mapData.length > 0"
            >
                <div class="d-flex pl-4">
                    <img
                        width="24"
                        height="24"
                        :src="require('@/assets/images/dropoff-icon.png')"
                    />
                    <h4 class="font-weight-normal">
                        {{ $t("dropoff_location") }}
                    </h4>
                </div>
                <div class="d-flex pl-4">
                    <img
                        width="24"
                        height="24"
                        :src="require('@/assets/images/pickup-icon.png')"
                    />
                    <h4 class="font-weight-normal">
                        {{ $t("pickup_location") }}
                    </h4>
                </div>
            </div>
            <card class="px-0">
                <div class="-mt-2">
                    <map-for-order-location
                        v-if="mapData && mapData.length > 0"
                        :locations="mapData"
                        :orderStatus="order.status"
                        :orderId="order.id"
                        :order="order"
                        :providers="providers"
                        :settingMapRadiusRange="settingMapRadiusRange"
                    />
                </div>
            </card>
        </div>
        <proof-of-delivery
            :showModal="proofOfDelivery"
            @close="proofOfDelivery = false"
            :order="order"
        >
        </proof-of-delivery>
    </div>
</template>
<script>
import moment from "moment";
import db from "@/firebase.js";
import mixin from "@/mixins/common";
import Note from "@/components/common/Note";
import { getSettings } from "@/api/systemSetting";
import AlertModal from "@/components/common/AlertModal";
import RatingList from "@/components/common/RatingList.vue";
import ProofOfDelivery from "@/components/ProofOfDelivery.vue";
import { getOrder, changeStatus, getOffers, changePaymentMethod } from "@/api/orders";
import { addOrderNotes } from "@/api/notes";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import StatusHistoryTable from "@/components/common/StatusHistoryTable.vue";
import MapForOrderLocation from "@//components/common/MapForOrderLocation.vue";

export default {
    data() {
        return {
            cancelOrderSpinner: false,
            offers: [],
            chats: [],
            images: [],
            proofOfDelivery: false,
            orderCancelMessage: null,
            offerMinPriceWithoutHelper: 0,
            offerMaxPriceWithoutHelper: 0,
            offerMinPriceWithHelper: 0,
            offerMaxPriceWithHelper: 0,
            isAr: localStorage.getItem("lang") == "ar",
            showNoteModal: false,
            noteText: "",
            submitSpinner: false,
        };
    },
    props: ["order", "mapData", "providers", "settingMapRadiusRange"],
    watch: {
        ordersCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getOrder();
            },
        },
        ratingsCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getRatings();
            },
        },
    },
    components: {
        Note,
        AlertModal,
        RatingList,
        ProofOfDelivery,
        StatusHistoryTable,
        MapForOrderLocation,
    },
    created() {
        if (this.order.store_id == null) this.listOffers();
        if (
            this.hasRole(1) ||
            this.hasRole(6) ||
            this.hasRole(4) ||
            this.hasRole(5)
        ) {
            this.getOrderChat();
            this.getOrderImages();
        }
        this.offerRangeHelperPrice();
    },
    mixins: [mixin],

    methods: {
        chatMsgStringReplace(chat) {
            let message;
            if (this.isAr && chat.text_ar) {
                message = chat.text_ar;
            } else {
                message = chat.text;
            }
            // string replace \\n to \n
            return message.replace(/\\n/g, "\n");
        },
        orderChatCssClass(chat) {
            let className = "";
            if (chat.sender == this.order.customer.id) {
                className += " order-chat-right ";
            }
            if (chat.is_automated == true) {
                className += " automatic-chat-border ";
            }
            return className;
        },
        async changePaymentType() {
            this.showNoteModal = true;
        },

        async submitPaymentChange() {
            this.submitSpinner = true;
            try {
                const params = { payment_method: this.order.payment_method };
                await changePaymentMethod(this.order.id, params);

                if (!this.noteText) {
                    this.runToast(this.$t("notesRequired"), "error");
                    this.submitSpinner = false;
                    return;
                }

                const noteData = { 
                    notes: `${this.$t("payment_change_reason")} ${this.noteText}`
                };

                await addOrderNotes(this.order.id, noteData, {
                    store_id: this.$store.getters.user.role_id === 1 ? null : this.$store.getters.store?.id,
                    service_provider_company_id: this.$store.getters.serviceProviderCompany?.id || null,
                });

                this.runToast(this.$t("success.change_payment_method"), "success");
                this.showNoteModal = false;
                this.noteText = "";
                this.$refs.noteFormRef.resetForm();
                this.submitSpinner = false;
                this.$emit("getOrder");
            } catch (error) {
                console.error('Error in submitPaymentChange:', error);
                this.runToast(this.$t("apiError"), "error");
                this.submitSpinner = false;
            }
        },
        async getOrderChat() {
            let chats = [];
            const chatsRef = collection(db, `one8Order/${this.order.id}/chats`);
            // const chatsRef = collection(db, `one8Order/2897/chats`);
            const sortedQuery = query(chatsRef, orderBy("time", "asc"));
            const querySnapshot = await getDocs(sortedQuery);
            querySnapshot.forEach((doc) => {
                let chat = doc.data();
                chat.id = doc.id;
                chats.push(chat);
            });
            this.chats = chats;
        },
        getFormattedTime(time) {
            if (time) return moment(time).format("DD/MM/YYYY h:mm A");
        },
        async getOrderImages() {
            let images = [];
            const querySnapshot = await getDocs(
                collection(db, `one8Order/${this.order.id}/order/order/images`)
            );
            querySnapshot.forEach((doc) => {
                let image = doc.data();
                image.id = doc.id;
                images.push(image);
            });
            this.images = images;
        },
        listOffers() {
            getOffers({
                query: { order_id: this.order.id },
                include: "provider",
                limit: -1,
            })
                .then((res) => (this.offers = res.data))
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        async cancelOrder(order) {
            let activeOrderStatuses = [
                "On The Way",
                "Picked Up",
                "Dropped Off",
            ];

            if (
                order.store_id != null &&
                activeOrderStatuses.includes(order.status)
            ) {
                let setting = await this.getSystemSettings(
                    "business_order_cancel_price"
                );
                this.orderCancelMessage = this.$t(
                    "cancel_order_amount_confirmation",
                    {
                        amount: setting?.value,
                    }
                );
            } else {
                this.orderCancelMessage = this.$t("order_cancel_confirm");
            }
        },
        getSystemSettings(key = null) {
            return new Promise((resolve) => {
                getSettings({
                    limit: -1,
                })
                    .then((res) => {
                        if (key) {
                            res.data.forEach(function (item) {
                                if (item.key == key) {
                                    resolve(item);
                                }
                            });
                            resolve(null);
                        }
                    })
                    .catch(({ response }) => {
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            });
        },
        cancelOrderCallback() {
            this.$emit("getOrder");
        },
        async offerRangeHelperPrice() {
            this.offerMinPriceWithoutHelper = this.order.offer_suggested_price;
            this.offerMaxPriceWithoutHelper = this.order.offer_max_price;

            if (this.order.helper) {
                let helperPrice = 0;
                helperPrice = this.order?.offer?.helper_price;

                if (helperPrice == 0 || helperPrice == undefined) {
                    let settingHelperPrice = await this.getSystemSettings(
                        "individual_order_helper_price"
                    );
                    helperPrice = settingHelperPrice["value"];
                }

                // Order suggested price with helper
                this.offerMinPriceWithHelper = this.order.offer_suggested_price;
                this.offerMaxPriceWithHelper = this.order.offer_max_price;

                // Remove the helper price
                this.offerMinPriceWithoutHelper =
                    Number(this.order.offer_suggested_price) -
                    Number(helperPrice);
                this.offerMaxPriceWithoutHelper =
                    Number(this.order.offer_max_price) - Number(helperPrice);
            }
        },
        openProofOfDeliveryModal() {
            this.proofOfDelivery = true;
        },
    },
};
</script>
<style scoped lang="scss">
.automatic-chat-border {
    border: 2px solid #f9c622;
}
.new-line {
    white-space: pre-wrap;
}
.user-profile {
    border: 2px;
    border-radius: 5px;
    // height: 55px;
    margin-bottom: 10px;
    background-color: #f6f9fc;
}
.user-profile-item {
    margin-top: 2px;
}
.avatar-sm {
    width: 50px !important;
    height: 50px !important;
}

.payload-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;

    .d-flex {
        width: 45%;
        margin: 5px;
    }
    .payload__content.payload__content-2 {
        justify-content: left;
    }

    .payload__content li:last-child {
        width: auto;
        max-width: auto;
        padding: 0 20px;
    }
}
.payload__content li.payload__content-li {
    max-width: 195px !important;
    padding: 0 20px;
}

.no-offer {
    text-align: center;
}

.order-chat {
    padding: 10px;
    background-color: #ecf5fa;
    margin: 20px 0;
    width: fit-content;
    position: relative;
    border-radius: 10px 10px 10px 10px;
}
// .order-chat:before {
//     content: "";
//     width: 10px;
//     height: 10px;
//     position: absolute;
//     top: 3px;
//     left: -5px;
//     background-color: #ecf5fa;
//     transform: rotate(33deg);
// }
.order-image {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
    width: 50%;
    border: 1px solid #eaeaea;
}
.order-image img {
    width: 100%;
    height: 300px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    background-color: #eaeaea;
}
.main-order-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.main-order-text {
    padding: 20px;
}
.chat-tab {
    height: 515px;
    overflow: auto;
}
.rtl {
    .main-order-text {
        float: right;
    }
    .order-chat {
        text-align: right;
    }
    .order-chat-right {
        margin: 20px auto 0 10px;
    }
}
.order-chat-right {
    margin: 20px 10px 0 auto;
}
.complaint-text {
    max-width: 17% !important;
}

.pickup-address {
    &.list-group {
        flex-direction: row;
    }

    &.detail-table {
        .list-group-item {
            max-width: 50%;

            &:first-child {
                display: block !important;

                max-width: 100%;
            }
        }
    }
}
.address-list {
    display: initial !important;
}
// .order-chat-right:before {
//     right: -5px;
// }
.offer-margin-left {
    margin-left: -15px !important;
}
.offer-margin-left-10 {
    margin-left: 10px !important;
}
.list-group-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.list-group-item > .d-flex.w-100.justify-content-between {
    width: 100%;
}

.base-button {
    margin-left: 0;  // Remove the margin-left added previously
}

/* Additional styling if needed for the button */
.base-button.text-warning {
    color: red;
    border-color: red;
}

.base-button.text-warning:hover {
    background-color: red;
    color: #fff;
}
</style>
