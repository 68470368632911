<template>
    <div>
        <hr />
        <div v-if="hasDashboardAccess">
            <tabs
                fill
                class="flex-column block flex-md-row"
                tabNavClasses="mb-3 mt-4  dash-tabs"
                @tabClicked="tabClicked"
            >
                <tab-pane
                    :title="$t('dashboard_page.administrator')"
                    class=""
                    id="admin"
                >
                    <div v-if="showAdminTab == true">
                        <dashcard 
                            :selectedOperatorId="selectedOperatorId"
                            :selectedDate="selectedDate"
                            :vehicleType="vehicleType"
                            @statusChanged="changeOrderStatus"
                        ></dashcard>
                        <order-list
                            :orderStatus="orderStatus"
                            :storeOrder="hasRole(1) ? true : null"
                            :operators="operators"
                            @dateChanged="onDateChanged"
                            @vehicleTypeChanged="onVehicleTypeChanged"
                            @operatorChanged="onOperatorSelected"
                        ></order-list>
                    </div>
                </tab-pane>
            </tabs>
        </div>
    </div>
</template>

<script>
import Dashcard from "./Dashcard";
import OrderList from "@/components/common/OrderList.vue";
import mixin from "@/mixins/common";
import InformationCards from "@/components/common/InformationCards.vue";
import StoreStatistics from "./StoreStatistics.vue";
import Statistics from "./Statistics.vue";
import OrderIndicator from "./OrderIndicator.vue";
import { me, getUsers } from "@/api/users";
import { operationsCheckOut } from "../../api/users";
export default {
    data() {
        return {
            orderStatus: "live",
            showAdminTab: true,
            operators: null,
            selectedOperatorId: null,
            selectedDate: [],
            vehicleType: null,
            filters: {
                status: "live",
                operator_id: null,
                vehicle_type_id: null,
                start_date: null,
                end_date: null,
            }
        };
    },
    created() {
        if (this.hasRole(9)) this.updateCredit();
        this.getOperators();
    },
    computed: {
        hasDashboardAccess() {
            let accessibleUserRoles = [1, 4, 5, 6, 7, 8, 9];
            return accessibleUserRoles.includes(this.userRole);
        },
    },
    mixins: [mixin],
    methods: {
        updateCredit() {
            me().then((res) => {
                this.$store.dispatch(
                    "setStoreCredit",
                    res.user?.credit ? res.user?.credit : 0
                );
            });
        },
        changeOrderStatus(status) {
            this.orderStatus = status;
        },
        async getOperators() {
            try {
                const res = await getUsers("admin", {
                    role: "operator",
                    limit: -1,
                    filter: {
                        status: "approved",
                    },
                });
                this.operators = res.data;
            } catch (error) {
                this.apiError(error.res);
            }
        },
        tabClicked(tabName) {
            switch (tabName) {
                case "admin":
                    this.showAdminTab = true;
                    break;
                case "information":
                    this.showInformationTab = true;
                    break;
                case "statistics":
                    this.showStatisticsTab = true;
                    break;
                case "storeStatistics":
                    this.showStoreStatisticsTab = true;
                    break;
                case "orderIndicator":
                    this.showOrderIndicatorTab = true;
                    break;
            }
        },
        onOperatorSelected(operatorId) {
            this.selectedOperatorId = operatorId;
        },
        onDateChanged(newDateRange) {
            this.selectedDate = newDateRange;
        },
        onVehicleTypeChanged(vehicleTypeId) {
            this.vehicleType = vehicleTypeId;
        },
    },
    components: {
        Dashcard,
        OrderList,
        InformationCards,
        StoreStatistics,
        Statistics,
        OrderIndicator,
    },
};
</script>