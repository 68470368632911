<template>
    <div>
        <hr />
        <div class="card mb-0 p-5 rounded-md">
            <div>
                <div class="d-flex mb-4">
                    <search-filter
                        :filters="[
                            {
                                id: 'name',
                            },
                            {
                                id: 'email',
                                type: 'email',
                            },
                            {
                                id: 'mobile_no',
                                type: 'number',
                            },
                        ]"
                        @search="(searchObject) => (filters = searchObject)"
                    />
                </div>
            </div>

            <div class="row">
                <main class="col-md-12 col-xs-11 p-l-2 p-t-2">
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="customers"
                            v-loading="isLoading"
                            @row-click="rowClicked"
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('name')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.name }}</span
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('email')"
                                min-width="200px"
                                prop="email"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-nowrap"
                                        >{{ row.email }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('mobile_no')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">
                                        +{{ row.country_code + " " }}
                                        {{ row.mobile_no }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('status')"
                                min-width="150px"
                                prop="status"
                            >
                                <template v-slot="{ row }">
                                    <custom-label :label="row.user_status" />
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('actions')"
                                min-width="210px"
                            >
                                <template v-slot="{ row }">
                                    <div
                                        class="d-flex justify-content-between flex-wrap action-width"
                                    >
                                        <el-tooltip
                                            :content="$t('view')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                style="cursor: pointer"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="'/customers/' + row.id"
                                                >
                                                    <img
                                                        src="@/assets/svg/view.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>
                                        <!-- User Edit Modal -->
                                        <user-edit-modal
                                            :user="row"
                                            role="customer"
                                            :title="$t('edit_customer')"
                                            @userEditCompleted="getCustomers"
                                        >
                                            <!-- below v-slot:default = {} is object Destructuring without which clickevent cannot be accessed -->
                                            <template
                                                v-slot:default="{ clickEvent }"
                                            >
                                                <el-tooltip
                                                    :content="$t('edit')"
                                                    placement="top"
                                                >
                                                    <badge
                                                        circle
                                                        size="lg"
                                                        class="bg-sky-dark"
                                                        style="cursor: pointer"
                                                        :title="$t('edit')"
                                                        @click="clickEvent"
                                                    >
                                                        <img
                                                            src="@/assets/svg/note.svg"
                                                        />
                                                    </badge>
                                                </el-tooltip>
                                            </template>
                                        </user-edit-modal>
                                        <!-- Custom Design for change status Icon with Badge display using SLots 
                                Update User Status Component with props like user data  -->
                                        <el-tooltip
                                            :content="$t('wallet_history')"
                                            placement="top"
                                            v-if="hasRole(1) || hasRole(6)"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                style="cursor: pointer"
                                                @click="
                                                    viewCustomerWalletHistory(
                                                        row.id
                                                    )
                                                "
                                            >
                                                <img
                                                    src="@/assets/svg/credit-card.svg"
                                                />
                                            </badge>
                                        </el-tooltip>
                                        <update-user-status
                                            :user="row"
                                            @changeStatusCompleted="
                                                getCustomers
                                            "
                                        >
                                            <template
                                                v-slot:default="{ clickEvent }"
                                            >
                                                <el-tooltip
                                                    :content="
                                                        $t('update_status')
                                                    "
                                                    placement="top"
                                                >
                                                    <badge
                                                        circle
                                                        size="lg"
                                                        class="bg-sky-dark"
                                                        style="cursor: pointer"
                                                        @click="clickEvent"
                                                    >
                                                        <img
                                                            src="@/assets/svg/refresh.svg"
                                                        />
                                                    </badge>
                                                </el-tooltip>
                                            </template>
                                        </update-user-status>
                                        <!--Alert  Modal with Delete Function -->
                                        <alert-modal
                                            :id="row.id"
                                            :title="$t('customers')"
                                            :body="
                                                $t('customer_delete_confirm')
                                            "
                                            @alertModalCompleted="getCustomers"
                                        ></alert-modal>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="customers.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
        <!--  End of Table -->
    </div>
</template>

<script>
import { getUsers } from "@/api/users";
import mixin from "@/mixins/common";
import UpdateUserStatus from "@/components/common/UpdateUserStatus.vue";
import AlertModal from "@/components/common/AlertModal.vue";
import UserEditModal from "@/components/common/UserEditModal.vue";
export default {
    data() {
        return {
            customers: [],

            isLoading: false,
            meta: {},
            user: null,
            customer: {},
            currentPage: 1,
            params: {
                role: "customer",
            },
            totalData: null,
            customerId: null,
            filters: {
                name: "",
                email: "",
                mobile_no: null,
                status: "",
            },
            userStatusOption: [
                {
                    value: null,
                    label: this.$t("all"),
                },
                {
                    value: "approved",
                    label: this.$t("approved"),
                },
                {
                    value: "pending",
                    label: this.$t("pending"),
                },
                {
                    value: "rejected",
                    label: this.$t("rejected"),
                },
            ],
        };
    },
    components: {
        UpdateUserStatus,
        AlertModal,
        UserEditModal,
    },
    created() {
        this.getCustomers();
    },
    mounted() {},
    mixins: [mixin],
    watch: {
        currentPage: {
            handler: function () {
                this.getCustomers();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.currentPage = 1;
                this.getCustomers();
            },
        },
    },
    methods: {
        getCustomers() {
            this.isLoading = true;
            getUsers("admin", {
                ...this.params,
                page: this.currentPage,
                filter: this.filters,
            })
                .then((res) => {
                    this.customers = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                name: "",
                email: "",
                mobile_no: null,
                status: "",
            };
        },
        viewCustomerWalletHistory(userId) {
            let routeData = this.$router.resolve({
                name: "Wallet History List",
                params: { userId },
            });
            window.open(routeData.href, "_blank");
        },
        rowClicked(row, column, event) {
            if(column.label != this.$t("actions")){
                this.$router.push({
                    name: "Customer Details",
                    params: { id: row.id },
                });
            }
            return;
        },
    },
};
</script>
<style lang="scss">
.nav-pills .nav-link {
    box-shadow: none !important;
    color: var(--secondary) !important;
    border-radius: 0 !important;
    &:hover {
        color: var(--primary) !important;
    }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: var(--primary) !important;
}
</style>
