<template>
    <div>
        <modal 
            :show="show" 
            @close="handleClose" 
            size="md"
        >
            <template v-slot:header>
                <div class="text-bold text-lg border-b mt-2 mb-1">
                    {{ $t('bulk_credit_transfer') }}
                </div>
            </template>

            <div class="px-4">
                <p>{{ $t('bulk_transfer_confirmation', { count: selectedProviders.length }) }}</p>
                
                <!-- Payment Method Selection -->
                <div class="mt-3">
                    <label class="form-control-label d-block">{{ $t('payment_method') }}</label>
                    <div class="mt-2">
                        <el-radio-group v-model="bulkPaymentMethod" class="w-100">
                            <el-radio 
                                label="online" 
                                border 
                                class="w-100 mb-2"
                            >{{ $t('transferCreditOnline') }}</el-radio>
                            <el-radio 
                                label="offline" 
                                border 
                                class="w-100 mb-2 ml-0"
                            >{{ $t('transferCreditOffline') }}</el-radio>
                        </el-radio-group>
                    </div>
                </div>

                <!-- Selected Providers List -->
                <div class="mt-3">
                    <strong>{{ $t('selected_providers') }}:</strong>
                    <div class="mt-2 max-h-60 overflow-auto">
                        <div 
                            v-for="provider in selectedProviders" 
                            :key="provider.id" 
                            class="mb-2 d-flex justify-content-between align-items-center"
                        >
                            <span>{{ provider.name }} - {{ provider.order_credit }} SAR</span>
                            <el-tag 
                                v-if="bulkPaymentMethod === 'online' && !provider.stcpay_mobile_no"
                                type="danger" 
                                size="small"
                            >
                                {{ $t('no_stcpay_number') }}
                            </el-tag>
                        </div>
                    </div>
                </div>

                <!-- Summary -->
                <div class="mt-4 pt-2 border-t">
                    <div class="d-flex justify-content-between">
                        <strong>{{ $t('total_amount') }}:</strong>
                        <span>{{ totalAmount }} SAR</span>
                    </div>
                    <div 
                        v-if="bulkPaymentMethod === 'online' && hasProvidersWithoutStcPay" 
                        class="mt-2 text-danger"
                    >
                        <small>{{ $t('some_providers_missing_stcpay') }}</small>
                    </div>
                </div>
            </div>

            <template v-slot:footer>
                <base-button
                    @click.native="executeBulkTransfer"
                    :loading="bulkTransferLoading"
                    :disabled="isTransferDisabled"
                >
                    {{ $t('confirm') }}
                </base-button>
                <base-button @click.native="handleClose">
                    {{ $t('cancel') }}
                </base-button>
            </template>
        </modal>
    </div>
</template>

<script>
import { bulkCreditTransfer } from "@/api/users";
import mixin from "@/mixins/common";

export default {
    name: 'BulkTransferModal',
    
    emits: ['update:show', 'transfer-completed'],
    mixins: [mixin],

    props: {
        show: {
            type: Boolean,
            required: true
        },
        selectedProviders: {
            type: Array,
            required: true
        },
        selectionMode: {
            type: String,
            required: true,
            validator: value => ['page', 'all'].includes(value)
        }
    },

    data() {
        return {
            bulkTransferLoading: false,
            bulkPaymentMethod: 'online'
        };
    },

    computed: {
        totalAmount() {
            return this.selectedProviders.reduce((sum, provider) => sum + provider.order_credit, 0);
        },
        
        hasProvidersWithoutStcPay() {
            return this.selectedProviders.some(provider => !provider.stcpay_mobile_no);
        },

        isTransferDisabled() {
            return this.bulkTransferLoading || 
                   (this.bulkPaymentMethod === 'online' && this.hasProvidersWithoutStcPay);
        }
    },

    methods: {
        handleClose() {
            this.$emit('update:show', false);
            this.bulkPaymentMethod = 'online';
        },

        async executeBulkTransfer() {
            this.bulkTransferLoading = true;
            try {
                this.$parent.lockPayments();
                const response = await bulkCreditTransfer({
                    providers: this.selectedProviders.map(p => p.id),
                    payment_method: this.bulkPaymentMethod,
                    selection_mode: this.selectionMode
                });
                
                this.$emit('update:show', false);
                this.$emit('transfer-completed');
                this.runToast(response.message, 'success');
            } catch (error) {
                this.apiError(error.response);
            } finally {
                this.bulkTransferLoading = false;
            }
        }
    }
};
</script>

<style scoped>
.el-radio.is-bordered {
    margin-left: 0;
}
</style>