<template>
    <div>
        <hr />
        <div class="card py-3 px-5 rounded-md">
            <div class="border-0 card-header px-0 d-flex justify-content-between align-items-center">
                <button class="text-white btn btn-default btn-sm" @click="exporInvoiceReport()">
                    <i class="mr-2 text-white fas fa-download fa-lg">
                        <span>{{ $t("export") }}</span>
                    </i>
                </button>
            </div>
            <!-- Filter Section Start -->
            <div class="d-flex mb-4" style="gap: 20px">
                <el-select
                v-model="filters.store_id"
                class="d-block"
                filterable
                remote
                :remote-method="remoteSearchStores"
                :loading="isSearchingStores"
                :placeholder="$t('select_store')"
                clearable
            >
                <el-option
                    v-for="store in stores"
                    :key="store.id"
                    :value="store.id"
                    :label="store.name"
                >
                    <span>{{ store.name }}</span>
                    <span class="text-gray-500 text-xs ml-2">
                        ({{ store.user?.mobile_no }})
                    </span>
                </el-option>
            </el-select>
                <div>
                    <Field v-slot="{ field }" v-model="filters.status" name="status" rules="required"
                        :label="$t('status')">
                        <el-select class="d-block" name="stc_updated" v-bind="field" v-model="filters.status"
                            :placeholder="$t('status')">
                            <el-option v-for="option in statusOption" class="select-danger" :value="option.value"
                                :label="option.label" :key="option.label">
                            </el-option>
                        </el-select>
                    </Field>
                </div>
                <div>
                    <flat-picker :config="{
                        allowInput: true,
                        dateFormat: 'd-m-Y',
                    }" class="form-control datepicker" :placeholder="$t('dashboard_page.start_date')"
                        v-model="filters.start_date" @change="dateFilterChange" />
                </div>
                <div>
                    <flat-picker :config="{
                        allowInput: true,
                        dateFormat: 'd-m-Y',
                    }" class="form-control datepicker" :placeholder="$t('dashboard_page.end_date')"
                        v-model="filters.end_date" @change="dateFilterChange" />
                </div>
            </div>
            <!-- Filter Section End -->
            <div class="row">
                <main class="col-md-12 col-xs-11 p-l-2 p-t-2">
                    <div>
                        <el-table class="table-responsive table-flush" header-row-class-name="thead-light"
                            :data="invoices" v-loading="isLoading" :empty-text="$t('no_data_found')">
                            <el-table-column :label="$t('invoice.invoice_no')" min-width="100px">
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">
                                        {{ row.invoice_no }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('order_no')" min-width="100px">
                                <template v-slot="{ row }">
                                    <router-link :to="getOrderLink(row.order.id)" class="font-weight-600 name mb-0">
                                        {{ row.order.order_no }}
                                    </router-link>
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('invoice.sub_total')" min-width="100px">
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">
                                        {{ row.sub_total }} SAR
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('total')" min-width="100px">
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">
                                        {{ row.total }} SAR
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('status')" min-width="100px">
                                <template v-slot="{ row }">
                                    <custom-label :label="row.status" />
                                </template>
                            </el-table-column>

                            <el-table-column :label="$t('created_at')" min-width="150px">
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">
                                        {{ row.created_at }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('actions')" min-width="210px">
                                <template v-slot="{ row }">
                                    <div class="d-flex align-items-center flex-wrap action-width">
                                        <!-- Invoice Button -->
                                        <order-invoice v-if="
                                            row.order.invoice_ref_no != null &&
                                            (userRole == 1 || userRole == 6 || userRole == 4)
                                        " :order="row.order"></order-invoice>
                                    </div>
                                </template>
                            </el-table-column>

                        </el-table>
                    </div>
                    <div class="card-footer p-0 pt-5 pb-5" v-if="invoices.length > 0">
                        <base-pagination :meta="meta" v-model="currentPage" :total="totalData"></base-pagination>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getInvoiceHistory, downloadInvoiceExcel } from "@/api/orders";
import { get as getStores } from "@/api/store";
import flatPicker from "vue-flatpickr-component";
import OrderInvoice from "@/components/common/OrderInvoice.vue";
import "flatpickr/dist/flatpickr.css";
import { debounce } from 'lodash';

export default {
    data() {
        return {
            invoices: [],
            currentPage: 1,
            storeId: this.$store.getters.user.role_id !== 4
                ? null
                : this.$store.getters.store?.id,
            filters: {
                order_no: null,
                status: null,
                start_date: null,
                end_date: null,
                store_id: null,
            },
            isLoading: false,
            meta: {},
            totalData: null,
            downloadInvoiceSpinner: false,
        };
    },
    mixins: [mixin],
    components: {
        flatPicker,
        OrderInvoice
    },
    created() {
        this.getInvoices();
    },
    watch: {
        currentPage: {
            handler: function () {
                this.getInvoices();
            },
        },
        filters: {
            deep: true,
            handler: debounce(function() {
                this.currentPage = 1;
                this.getInvoices();
            }, 700)
        }
    },
    computed: {
        statusOption() {
            return [
                {
                    value: null,
                    label: this.$t("all"),
                },
                {
                    value: 'paid',
                    label: this.$t("paid"),
                },
                {
                    value: 'unpaid',
                    label: this.$t("unpaid"),
                },
            ];
        },
        getOrderLink() {
            return (orderId) => {
                const userRole = this.$store.getters.user.role_id;
                return userRole === 4 ? `/orders/${orderId}` : `/store-orders/${orderId}`;
            };
        },
        showStoreFilter() {
            const userRole = this.$store.getters.user.role_id;
            return userRole === 1 || userRole === 6;
        }
    },
    methods: {
        async getInvoices() {
            this.isLoading = true;
            try {
                const res = await getInvoiceHistory({
                    include: "order, order.customer, order.invoice",
                    page: this.currentPage,
                    store_id: this.storeId,
                    query: this.filters,
                    service_provider_company_id: this.$store.getters.user
                        ?.service_provider_company?.id
                        ? this.$store.getters.user?.service_provider_company?.id
                        : null
                });

                this.invoices = res.data;
                this.isLoading = false;
                this.totalData = res.meta.total;
                this.meta = res.meta;
            } catch (error) {
                this.isLoading = false;
                this.apiError(error.response);
            }
        },
        dateFilterChange() {
            this.currentPage = 1;
            this.getInvoices();
        },
        exporInvoiceReport() {
            downloadInvoiceExcel({
                include: "order",
                store_id:
                    this.storeId,
                query: {
                    ...this.filters,
                },
            })
                .then((res) => {
                    this.downloadInvoiceSpinner = false;
                    if (res.excel_url !== null) {
                        window.open(res.excel_url, "_blank");
                    }
                })
                .catch(({ response }) => {
                    this.downloadInvoiceSpinner = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        async remoteSearchStores(query) {
        if (query.length < 2) {
            this.stores = [];
            return;
        }

        this.isSearchingStores = true;
        try {
            const response = await getStores({
                query: {
                    name: query,
                },
                include: "user",
                limit: 25,
            });
            this.stores = response.data;
        } catch (error) {
            this.apiError(error.response);
        } finally {
            this.isSearchingStores = false;
        }
    },
    },
};
</script>