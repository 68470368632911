<template>
    <div>
        <hr />
        <div class="card py-3 px-5 rounded-md">
            <div
                class="border-0 card-header px-0 d-flex justify-content-between align-items-center"
            >
                <button
                    class="btn btn-default text-white btn-sm"
                    @click="notificationModal"
                >
                    <i class="fas fa-plus-circle fa-lg text-white mr-2">
                        <span>{{ $t("send_notification") }}</span>
                    </i>
                </button>
            </div>

            <!-- Filter Section Start -->
            <div class="d-flex mb-4">
                <search-filter
                    :filters="[
                        {
                            id: 'title',
                        },
                    ]"
                    @search="(searchObject) => (filters = searchObject)"
                />
            </div>
            <!-- Filter Section End  -->

            <div class="row">
                <main
                    :class="
                        isFilter
                            ? 'col-md-9 col-xs-11 p-l-2 p-t-2'
                            : 'col-md-12 col-xs-11 p-l-2 p-t-2'
                    "
                >
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="notifications"
                            v-loading="isLoading"
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('notification_title')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.title }}</span
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('notification_content')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-nowrap"
                                        >{{ row.content }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('notification_for')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">{{
                                        $t(row.notification_for)
                                    }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('user')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <p
                                        v-if="
                                            row.notification_for ==
                                            'specific_user'
                                        "
                                    >
                                        <span
                                            class="font-weight-600 name mb-0 d-block"
                                        >
                                            {{ row.user?.name }}
                                        </span>
                                        <span
                                            class="font-weight-600 name mb-0 text-nowrap"
                                        >
                                            {{ row.user?.mobile_no }}
                                        </span>
                                    </p>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('city')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <p
                                        v-if="
                                            row.notification_for ==
                                                'provider' &&
                                            row.city_id != null
                                        "
                                    >
                                        <span
                                            class="font-weight-600 name mb-0 d-block"
                                        >
                                            {{
                                                isAr
                                                    ? row.city?.name_ar
                                                    : row.city?.name
                                            }}
                                        </span>
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('vehicle_type')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <p
                                        v-if="
                                            row.notification_for ==
                                                'provider' &&
                                            row.vehicle_type_id != null
                                        "
                                    >
                                        <span
                                            class="font-weight-600 name mb-0 d-block"
                                        >
                                            {{
                                                isAr
                                                    ? row.vehicle_type?.type_ar
                                                    : row.vehicle_type?.type
                                            }}
                                        </span>
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('created_at')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">{{
                                        row.created_at
                                    }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="notifications.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
            <!-- Start of Send Notification Modal -->
            <!-- Modal  -->
            <modal
                :show="showModal"
                @close="showModal = false"
                size="sm"
                body-classes="p-0"
                v-loading="isLoading"
            >
                <template v-slot:header>
                    <div class="text-bold text-lg text-center mt-2 mb-1">
                        {{ $t("send_notification") }}
                    </div>
                </template>
                <div class="px-3 d-block">
                    <Form
                        ref="sendNotificationRef"
                        @submit="sendNotification"
                        v-loading="isLoading"
                    >
                        <label for="" class="my-1">{{
                            $t("notification_title")
                        }}</label>
                        <Field
                            type="text"
                            rules="required"
                            :label="$t('notification_title')"
                            class="form-control"
                            :placeholder="$t('notification_title')"
                            name="title"
                        />
                        <ErrorMessage name="title" class="error" />
                        <label for="" class="my-1">{{
                            $t("notification_content")
                        }}</label>

                        <Field
                            rules="required"
                            :label="$t('notification_content')"
                            class="form-control"
                            v-slot="{ field }"
                            :placeholder="$t('notification_content')"
                            name="content"
                        >
                            <textarea
                                name="content"
                                class="form-control"
                                rows="5"
                                :placeholder="$t('notification_content')"
                                v-bind="field"
                            ></textarea>
                        </Field>
                        <ErrorMessage name="content" class="error" />
                        <Field
                            name="notification_for"
                            rules="required"
                            v-model="notification_for"
                            v-slot="{ field }"
                            :label="$t('notification_for')"
                        >
                            <label for="" class="d-block">{{
                                $t("notification_for")
                            }}</label>
                            <el-select
                                v-bind="field"
                                class="d-block"
                                :placeholder="$t('select')"
                                name="notification_for"
                                v-model="notification_for"
                                filterable
                            >
                                <el-option
                                    v-for="option in defaultOptions.notificationForOptions"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="$t(option.label)"
                                    :key="option.label"
                                >
                                </el-option>
                            </el-select>
                            <ErrorMessage
                                name="notification_for"
                                class="error"
                            />
                        </Field>

                        <Field
                            v-if="showUsersDropdown"
                            name="user_id"
                            v-model="user_id"
                            v-slot="{ field }"
                            :label="$t('user')"
                        >
                            <label for="" class="d-block">{{
                                $t("user")
                            }}</label>
                            <el-select
                                v-bind="field"
                                :placeholder="$t('select')"
                                name="user_id"
                                v-model="user_id"
                                filterable
                                clearable
                                style="width: 100% !important"
                                @keyup="getUser(user_id)"
                            >
                                <el-option
                                    v-for="option in userOptions"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="option.name"
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>
                            <ErrorMessage name="user_id" class="error" />
                        </Field>

                        <Field
                            v-if="showCityDropdown"
                            name="city_id"
                            v-model="city_id"
                            v-slot="{ field }"
                            :label="$t('city')"
                        >
                            <label for="" class="d-block">{{
                                $t("city")
                            }}</label>
                            <el-select
                                v-bind="field"
                                :placeholder="$t('select')"
                                name="city_id"
                                v-model="city_id"
                                filterable
                                clearable
                                style="width: 100% !important"
                            >
                                <el-option
                                    class="select-danger"
                                    value=""
                                    :label="$t('all')"
                                    key=""
                                >
                                </el-option>
                                <el-option
                                    v-for="option in cities"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="option.title"
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>
                            <ErrorMessage
                                name="notification_for"
                                class="error"
                            />
                        </Field>
                        <Field
                            v-if="showVehicleTypeDropdown"
                            name="vehicle_type_id"
                            v-model="vehicle_type_id"
                            v-slot="{ field }"
                            :label="$t('vehicle_type')"
                        >
                            <label for="" class="d-block">{{
                                $t("vehicle_type")
                            }}</label>
                            <el-select
                                v-bind="field"
                                :placeholder="$t('select')"
                                name="vehicle_type_id"
                                v-model="vehicle_type_id"
                                filterable
                                clearable
                                style="width: 100% !important"
                            >
                                <el-option
                                    class="select-danger"
                                    value=""
                                    :label="$t('all')"
                                    key=""
                                >
                                </el-option>
                                <el-option
                                    v-for="option in vehicleTypes"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="option.title"
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>
                        </Field>

                        <div class="w-full text-center mt-3 mb-4">
                            <base-button
                                :loading="showButtonLoader"
                                native-type="submit"
                                class="text-white bg-primary"
                            >
                                {{ $t("submit") }}
                            </base-button>
                            <input
                                type="button"
                                class="btn btn-default text-white"
                                @click="showModal = false"
                                :value="$t('close')"
                            />
                        </div>
                    </Form>
                </div>
            </modal>
            <!-- End of Send Notification Modal -->
        </div>
        <!--  End of Table -->
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getUsers } from "@/api/users";
import { get as getCityList } from "@/api/cities";
import { getVehicleTypes } from "@/api/vehicleType";
import { get, send } from "@/api/broadcastNotification";

export default {
    data() {
        return {
            notifications: [],
            notification_for: "",
            currentPage: 1,
            filters: {
                title: null,
            },
            userOptions: [],
            showButtonLoader: false,
            showUsersDropdown: false,
            showCityDropdown: false,
            showVehicleTypeDropdown: false,
            showModal: false,
            isLoading: false,
            meta: {},

            totalData: null,
            isFilter: false,
            cities: [],
            isAr: localStorage.getItem("lang") == "ar",
            vehicleTypes: [],
        };
    },
    mixins: [mixin],
    created() {
        this.getNotifications();
    },
    watch: {
        currentPage: {
            handler: function () {
                this.getNotifications();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.currentPage = 1;
                this.getNotifications();
            },
        },

        notification_for: {
            handler(newVal) {
                if (newVal == "specific_user") {
                    this.showUsersDropdown = true;
                } else {
                    this.showUsersDropdown = false;
                }

                if (newVal == "provider") {
                    this.showCityDropdown = true;
                    this.showVehicleTypeDropdown = true;
                } else {
                    this.showCityDropdown = false;
                    this.showVehicleTypeDropdown = false;
                }
            },
        },
    },
    methods: {
        toggleFilter() {
            this.isFilter = !this.isFilter;
            if (this.isFilter) {
                $("#togglebar").addClass("show");
            } else {
                $("#togglebar").removeClass("show");
            }
        },

        sendNotification(data) {
            this.showButtonLoader = true;
            send(data)
                .then(() => {
                    this.showButtonLoader = false;
                    this.$refs.sendNotificationRef.resetForm();

                    this.showModal = false;
                    this.getNotifications();
                })
                .catch(({ response }) => {
                    this.showButtonLoader = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        notificationModal() {
            this.showModal = true;
            this.getUser();
            // Get the cities
            getCityList({ limit: -1 })
                .then((res) => {
                    this.cities = res.data.map((item) => {
                        return {
                            title: this.isAr ? item.name_ar : item.name,
                            value: item.id,
                        };
                    });
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });

            getVehicleTypes({ limit: -1 })
                .then((res) => {
                    this.vehicleTypes = res.data.map((item) => {
                        return {
                            title: this.isAr ? item.type_ar : item.type,
                            value: item.id,
                        };
                    });
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        getUser(name = null) {
            getUsers(
                this.userRole === 1 || this.userRole === 6 ? "admin" : "store",
                {
                    role: ["customer", "provider"],
                    filter: {
                        name: name,
                    },
                    // limit: -1,
                }
            )
                .then((res) => {
                    this.userOptions = res.data;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        getNotifications() {
            this.isLoading = true;
            get({
                include: "user,city,vehicle_type",
                page: this.currentPage,
                filter: this.filters,
                query: { ...this.filters },
            })
                .then((res) => {
                    this.notifications = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                title: "",
            };
        },
    },
};
</script>
