import List from "@/views/Pages/ServiceProviderCompanies/List";
import Upsert from "@/views/Pages/ServiceProviderCompanies/Upsert";

export default [
    {
        path: "service-provider-companies",
        components: { default: List },
        name: "ServiceProviderCompanies List",
        meta: {
            title: "service_provider_company",
            role: [1, 6, 7, 8],
        },
    },
    {
        path: "service-provider-companies/:id",
        components: { default: Upsert },
        props: true,
        name: "ServiceProviderCompanies Upsert",
        meta: {
            title: "service_provider_company",
            role: [1, 6, 7, 8],
        },
    },
];
