<template>
    <div class="add-in-town-order">
        <div class="progress-bar-container">
            <vertical-progress-bar :current-step="getCurrentStep" :total-steps="4" />
        </div>
        <div class="form-container">
            <Form @submit="addOrder" ref="addOrderRef" style="width: 100%;">
                <div class="order-cards">
                    <!-- Customer Information Card -->
                    <div class="order-card" :class="{ 'active': activeCard === 'customer' }"
                        @click="setActiveCard('customer')">
                        <h3>{{ $t("customer_information") }}</h3>
                        <div v-if="activeCard === 'customer'" class="d-flex justify-content-between">
                            <div class="" style="width: 60%;">
                                <div class="field-wrap">
                                    <Field type="text" rules="required|max:20" :label="$t('name')"
                                        :class="form.customer_name && 'notEmpty'" class="form-control" name="name"
                                        v-model="form.customer_name" />
                                    <label class="label-modal">{{ $t("customer_name") }}</label>
                                    <ErrorMessage name="name" class="error" />
                                </div>
                                <div class="field-wrap">
                                    <Field type="number" rules="required|digits:9" :label="$t('customer_mobile_no')"
                                        :class="form.customer_mobile_no && 'notEmpty'" class="form-control"
                                        :placeholder="$t('customer_mobile_no')" name="mobile_no"
                                        v-model="form.customer_mobile_no" />
                                    <label class="label-modal">{{ $t("customer_mobile_no") }}</label>
                                    <ErrorMessage name="mobile_no" class="error" />
                                </div>
                            </div>
                            <div class="text-right d-flex align-items-end">
                                <base-button @click="(event) => nextCard('address', event)"
                                    :disabled="!isCustomerInfoValid" class="btn-next">
                                    {{ $t("proceed_to_address") }}
                                </base-button>
                            </div>
                        </div>
                    </div>

                    <!-- Address Card -->
                    <div class="order-card" :class="{ 'active': activeCard === 'address' }"
                        @click="setActiveCard('address')">
                        <h3>{{ $t("address_information") }}</h3>
                        <div v-if="activeCard === 'address'" class="d-flex justify-content-between">
                            <div class="" style="width: 60%;">
                                <div class="d-flex flex-column mb-3" style="gap: 5px !important;">
                                    <Field v-slot="{ field }" name="pickup_address_id" rules="required"
                                        :label="$t('pickup_address')" v-model="form.pickup_address">
                                        <el-select class="d-block" name="pickup_address_id" v-bind="field"
                                            :placeholder="$t('pickup_address')" v-model="form.pickup_address"
                                            @change="handlePickupAddressChange" filterable>
                                            <el-option v-for="option in addressesOptions" class="select-danger"
                                                :value="option.value" :label="option.label" :city="option.city_id"
                                                :key="option.label">
                                            </el-option>
                                        </el-select>
                                    </Field>
                                    <ErrorMessage name="pickup_address_id" class="error" />
                                </div>

                                <div class="d-flex flex-column mb-3">
                                    <Field name="dropoff_time" rules="required" :label="$t('dropoff_time')"
                                        v-model="form.dropoff_time">
                                        <el-select class="d-block" name="dropoff_time" :placeholder="$t('dropoff_time')"
                                            v-model="form.dropoff_time" filterable>
                                            <el-option v-for="option in dropOffTimeOptions" class="select-danger"
                                                :value="option.value" :label="option.label" :key="option.label">
                                            </el-option>
                                        </el-select>
                                    </Field>
                                    <ErrorMessage name="dropoff_time" class="error" />
                                </div>

                                <div class="d-flex flex-column mb-3">
                                    <Field rules="required" v-model="orderStatus" name="order_status"
                                        :label="$t('order_status')">
                                        <div class="order-form-wrap">
                                            <el-radio v-model="orderStatus" name="order_status"
                                                class="mb-2 add-order-radio" label="out_of_town" border
                                                @change="handleOrderStatusChange">
                                                {{ $t("out_of_town_order") }}
                                            </el-radio>
                                            <el-radio v-model="orderStatus" name="order_status"
                                                class="mb-2 add-order-radio" label="in_town" border
                                                @change="handleOrderStatusChange">
                                                {{ $t("in_town_order") }}
                                            </el-radio>
                                        </div>
                                    </Field>
                                    <ErrorMessage name="order_status" class="error" />
                                </div>

                                <div class="d-flex flex-column mb-3" v-if="orderStatus === 'out_of_town'">
                                    <Field name="dropoff_address" :rules="dropoffAddressRules" :label="$t('dropoff_address')">
                                        <div class="field-wrap">
                                            <label for="city_id" class="form-control-label">
                                                {{ $t("city") }}
                                            </label>
                                            <el-select 
                                                v-model="dropoffCityId" 
                                                class="d-block mb-3" 
                                                :placeholder="$t('select')"
                                                @change="handleDropoffCityChange">
                                                <el-option 
                                                    v-for="city in cities" 
                                                    :key="city.id"
                                                    :label="lang === 'ar' ? city.name_ar : city.name" 
                                                    :value="city.id" 
                                                />
                                            </el-select>
                                        </div>

                                        <div class="field-wrap" v-if="districtOptions && districtOptions.length">
                                            <label for="district_id" class="form-control-label">
                                                {{ $t("district") }}
                                            </label>
                                            <el-select 
                                                v-model="dropoffDistrictId" 
                                                class="d-block" 
                                                :placeholder="$t('select')"
                                                @change="handleDropoffDistrictChange">
                                                <el-option 
                                                    v-for="district in districtOptions" 
                                                    :key="district.id"
                                                    :label="lang === 'ar' ? district.name_ar : district.name" 
                                                    :value="district.id" 
                                                />
                                            </el-select>
                                        </div>

                                        <div class="form-control dropoff-text-container mt-3" style="background-color: #f5f5f5; cursor: default;">
                                            {{ form.dropoff_address || $t('dropoff_address') }}
                                        </div>
                                    </Field>
                                    <ErrorMessage name="dropoff_address" class="error" />
                                </div>
                            </div>
                            <div class="text-right d-flex align-items-end">
                                <base-button @click="(event) => nextCard('details', event)" :disabled="!isAddressValid"
                                    class="btn-next">
                                    {{ $t("proceed_to_order_details") }}
                                </base-button>
                            </div>
                        </div>
                    </div>

                    <!-- Order Details Card -->
                    <div class="order-card" :class="{ 'active': activeCard === 'details' }"
                        @click="setActiveCard('details')">
                        <h3>{{ $t("order_details") }}</h3>
                        <div v-if="activeCard === 'details'" class="d-flex justify-content-between">
                            <div class="" style="width: 60%;">
                                <div class="field-wrap">
                                    <Field v-slot="{ field }" name="vehicle_type_id" rules="required"
                                        :label="$t('vehicle_type')" :value="vehicleType">
                                        <div class="radio-wrap radio-wrap--custom vehicle-selection">
                                            <el-radio-group v-model="vehicleType" class="vehicle-type-box"
                                                v-bind="field">
                                                <div v-for="option in vehicleTypes" :key="option.id">
                                                    <el-radio-button :label="option.value" name="vehicle_type_id">
                                                        <span style="white-space: normal">{{ option.label }}</span>
                                                        <img :src="option.vehicleImage" v-if="option.vehicleImage" />
                                                        <img v-else src="@/assets/svg/Truck.svg" />
                                                    </el-radio-button>
                                                </div>
                                            </el-radio-group>
                                        </div>
                                    </Field>
                                    <ErrorMessage name="vehicle_type_id" class="error" />
                                </div>
                                <div class="field-wrap">
                                    <Field rules="required" v-model="payload_helper" name="payload_helper"
                                        :label="$t('package_order_messages.helper')">
                                        <div class="order-form-wrap">
                                            <el-radio v-model="payload_helper" name="payload_helper"
                                                class="mb-2 add-order-radio" label="false" border
                                                :disabled="!vehicleType">
                                                {{ $t("no_helper") }}
                                            </el-radio>
                                            <el-radio v-model="payload_helper" name="payload_helper"
                                                class="mb-2 add-order-radio" label="true" border
                                                :disabled="!vehicleType">
                                                {{ $t("package_order_messages.helper") }}
                                            </el-radio>
                                        </div>
                                    </Field>
                                    <ErrorMessage name="payload_helper" class="error" />
                                </div>
                            </div>
                            <div class="" style="width: 35%;">
                                <!-- Moved from Payment section -->
                                <div class="field-wrap">
                                    <Field type="number" rules="min_value:0" :label="$t('invoice_ref_no')"
                                        :class="form.invoice_ref_no && 'notEmpty'" class="form-control"
                                        :placeholder="$t('invoice_ref_no')" name="invoice_ref_no"
                                        v-model="form.invoice_ref_no" />
                                    <ErrorMessage name="invoice_ref_no" class="error" />
                                </div>
                                <div class="field-wrap">
                                    <Field type="number" rules="min_value:0" :label="$t('invoice_total_price')"
                                        :class="form.invoice_total_price && 'notEmpty'" class="form-control"
                                        :placeholder="$t('invoice_total_price')" name="invoice_total_price"
                                        v-model="form.invoice_total_price" />
                                    <ErrorMessage name="invoice_total_price" class="error" />
                                </div>
                                <div class="field-wrap">
                                    <h3>{{ $t("selectImages") }} ({{ $t("optional") }})</h3>
                                    <input type="file" @change="previewImage" accept="image/*" name="images"
                                        class="form-control" multiple />
                                </div>
                                <div class="field-wrap">
                                    <Field type="number" rules="min_value:0" :label="$t('no_of_pieces')"
                                        class="form-control" :placeholder="$t('no_of_pieces')" name="no_of_pieces"
                                        v-model="form.no_of_pieces" />
                                    <ErrorMessage name="no_of_pieces" class="error" />
                                </div>
                                <div class="text-right">
                                    <base-button @click="(event) => nextCard('payment', event)"
                                        :disabled="!isOrderDetailsValid" class="btn-next">
                                        {{ $t("proceed_to_payment") }}
                                    </base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Payment Card -->
                    <div class="order-card" :class="{ 'active': activeCard === 'payment' }"
                        @click="setActiveCard('payment')">
                        <h3>{{ $t("payment_information") }}</h3>
                        <div v-if="activeCard === 'payment'" class="d-flex justify-content-between">
                            <div class="" style="width: 60%;">
                                <div class="field-wrap">
                                    <Field rules="required" v-model="paymentOption" name="payment_method"
                                        :label="$t('order_payment_method')">
                                        <div class="order-form-wrap">
                                            <el-radio v-for="option in paymentOptions" :key="option.value"
                                                v-model="paymentOption" name="payment_method"
                                                class="mb-2 add-order-radio" :label="option.value" border
                                                @change="handlePaymentOption(option.value)">
                                                {{ option.label }}
                                            </el-radio>
                                        </div>
                                    </Field>
                                </div>
                                <ErrorMessage name="payment_method" class="error" />
                            </div>
                            <div class="text-right d-flex align-items-end">
                                <base-button :loading="submitSpinner" native-type="submit" class="btn-submit">
                                    {{ $t("create") }}
                                </base-button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>

        <!-- Payment Modal -->
        <modal :show="showPaymentModal" @close="showPaymentModal = false" size="md" body-classes="p-0">
            <template v-slot:header>
                <div class="font-weight-700 text-lg mt-2 mb-1">
                    {{ $t("enter_card_details") }}
                </div>
            </template>

            <div class="pt-0 px-4">
                <Form @submit="submitPayment" ref="paymentFormRef">
                    <div class="field-wrap">
                        <label for="name_on_card">{{ $t('name_on_card') }}</label>
                        <Field type="text" rules="required" class="form-control" name="name_on_card"
                            v-model="cardDetail.name" />
                        <ErrorMessage name="name_on_card" class="error" />
                    </div>

                    <div class="field-wrap">
                        <label for="cardNumber">{{ $t('card_number') }}</label>
                        <Field type="text" rules="required" class="form-control" name="cardNumber"
                            v-model="cardDetail.cardNumber" />
                        <ErrorMessage name="cardNumber" class="error" />
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <label for="expiryMonth">{{ $t('expiry_month') }}</label>
                            <Field type="text" rules="required|digits:2" class="form-control" name="expiryMonth"
                                v-model="cardDetail.expiryMonth" />
                            <ErrorMessage name="expiryMonth" class="error" />
                        </div>
                        <div class="col-md-6">
                            <label for="expiryYear">{{ $t('expiry_year') }}</label>
                            <Field type="text" rules="required|digits:4" class="form-control" name="expiryYear"
                                v-model="cardDetail.expiryYear" />
                            <ErrorMessage name="expiryYear" class="error" />
                        </div>
                    </div>

                    <div class="field-wrap">
                        <label for="cvv">{{ $t('cvv') }}</label>
                        <Field type="text" rules="required|digits:3" class="form-control" name="cvv"
                            v-model="cardDetail.cvv" />
                        <ErrorMessage name="cvv" class="error" />
                    </div>

                    <div class="w-full text-center mt-3">
                        <base-button :loading="submitSpinner" native-type="submit" class="text-white bg-primary">
                            {{ $t("submit") }}
                        </base-button>
                    </div>
                </Form>
            </div>
        </modal>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import modal from "@/components/Modal.vue";
import baseButton from "@/components/BaseButton.vue";
import VerticalProgressBar from '@/components/VerticalProgressBar.vue'
import db from "@/firebase.js";
import { me } from "@/api/users";
import mixin from "@/mixins/common";
import { get, getDistricts as getDistrictList } from "@/api/addresses";
import { getVehicleTypes } from "@/api/vehicleType";
import { createOrder, calculateInTownOrderPrice, offerSuggestedPrice } from "@/api/orders";
import { collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { get as getCityList } from "@/api/cities";
import axios from "axios";

export default {
    components: {
        Form,
        Field,
        ErrorMessage,
        modal,
        baseButton,
        VerticalProgressBar
    },
    data() {
        return {
            submitSpinner: false,
            showPaymentModal: false,
            previousActiveCard: null,
            cardStates: {
                address: {
                    dropoffAddress: null,
                    orderStatus: null
                },
                details: {
                    vehicleType: null
                }
            },
            form: {
                customer_name: null,
                customer_mobile_no: null,
                pickup_address: '',
                dropoff_address: '',
                dropoff_time: '',
                invoice_ref_no: null,
                invoice_total_price: null,
                no_of_pieces: null,
                assigned_locations: {
                    dropoff_address: {
                        latitude: null,
                        longitude: null,
                        address_line1: null,
                        city_id: null,
                        district_id: null,
                        city_latitude: null,
                        city_longitude: null
                    },
                    pickup_address: {
                        latitude: null,
                        longitude: null,
                        address_line1: null,
                        city_id: null,
                        district_id: null,
                        city_latitude: null,
                        city_longitude: null
                    },
                },
            },
            vehicleType: null,
            vehicleTypes: [],
            dropOffTimeOptions: [
                {
                    value: "morning",
                    label: this.$t("morning"),
                },
                {
                    value: "evening",
                    label: this.$t("evening"),
                },
                {
                    value: "default",
                    label: this.$t("unspecified"),
                },
            ],
            pickupAddressOptions: [],
            paymentOption: null,
            paymentOptions: [
                {
                    value: "customer_payment",
                    label: this.$t("customer_payment")
                },
                {
                    value: "store_credit",
                    label: this.$t("store_credit")
                }
            ],
            addressesOptions: [],
            extraServicePrice: false,
            orderImages: [],
            payload_helper: "false",
            helperPrice: 0,
            totalPrice: 0,
            paymentPrice: 0,
            orderStatus: "pending",
            latitude: null,
            longitude: null,
            pickupCityId: null,
            dropoffCityId: null,
            dropoffDistrictId: null,
            cities: [],
            districtOptions: [],
            lang: localStorage.getItem("lang"),
            cardDetail: {
                name: null,
                cardNumber: null,
                expiryMonth: null,
                expiryYear: null,
                cvv: null,
            },
            activeCard: 'customer',
        };
    },
    computed: {
        isCustomerInfoValid() {
            return this.form.customer_name && this.form.customer_mobile_no;
        },

        isAddressValid() {
            return this.form.pickup_address && this.form.dropoff_time && this.orderStatus &&
                (this.orderStatus !== 'out_of_town' || this.form.dropoff_address);
        },

        isOrderDetailsValid() {
            return this.vehicleType && this.payload_helper;
        },
        getCurrentStep() {
            const steps = ['customer', 'address', 'details', 'payment'];
            return steps.indexOf(this.activeCard) + 1;
        },
        isStoreCreditEligible() {
            const storePostPaidOrder = this.$store.getters.store;
            const storeCredit = this.$store.getters.storeCredit;

            if (storePostPaidOrder.postpaid_order_enabled && storeCredit < 1) {
                return Math.abs(storeCredit) < storePostPaidOrder.max_postpaid_amount;
            }
            return storeCredit >= 1;
        }
    },
    mixins: [mixin],
    watch: {
        paymentOption(newVal) {
            if (newVal == "store_credit") {
                var storePostPaidOrder = this.$store.getters.storePostpaidOrder;
                var storeCredit = this.$store.getters.storeCredit;

                if (storePostPaidOrder.is_enabled && storeCredit < 1) {
                    if (Math.abs(storeCredit) >= storePostPaidOrder.max_amount) {
                        this.store_credit_alert = true;
                        this.storeCreditAddMsg = this.$t(
                            "postpaid_credit_reached_msg"
                        );
                    }
                } else if (storeCredit < 1) {
                    this.store_credit_alert = true;
                    this.storeCreditAddMsg = this.$t("store_credit_msg");
                }
            } else {
                this.store_credit_alert = false;
            }
        },
        vehicleType(newValue) {
            const vehicleType = this.vehicleTypes.find(
                (item) => item.value === newValue
            );
            if (vehicleType) {
                this.helperPrice = vehicleType.helper_price || 0;
                // this.totalPrice = this.form.invoice_total_price || 0;
                if (this.payload_helper === "true") {
                    this.totalPrice += this.helperPrice;
                }
            }
        },
        form: {
            handler(newValue) {
                // this.totalPrice = newValue.invoice_total_price || 0;
                if (this.payload_helper === "true") {
                    this.totalPrice += this.helperPrice;
                }
            },
            deep: true,
        },
    },
    created() {
        this.getAllVehicleTypes();
        this.getAddresses();
        this.getCities();
    },
    methods: {
        async handlePaymentOption(value) {
            if (value === "store_credit" && !this.isStoreCreditEligible) {
                // Show payment modal immediately if store credit conditions aren't met
                var priceResponse = 0
                try {
                    if (this.orderStatus == 'out_of_town') {
                        priceResponse = await offerSuggestedPrice({
                            pickup_address: JSON.stringify(this.form.assigned_locations.pickup_address),
                            dropoff_address: JSON.stringify(this.form.assigned_locations.dropoff_address),
                            vehicle_type_id: this.vehicleType,
                            helper: this.payload_helper == "false" ? 0 : 1,
                            out_of_town: true
                        });
                        priceResponse = priceResponse.min_delivery_price;
                    }
                    else {
                        priceResponse = await calculateInTownOrderPrice(this.getOrderData(), {
                            store_id: this.storeId
                        });
                    }
                    this.paymentPrice = priceResponse;
                    // localStorage.setItem('submittedOrder', JSON.stringify(this.getOrderData()));
                    // if (this.orderImages?.length) {
                    //     await this.storeFilesInLocalStorage(this.orderImages);
                    // }
                    // this.showPaymentModal = true;
                } catch (error) {
                    this.apiError(error.response);
                }
            }
            this.paymentOption = value;
        },
        getVehicleInTownPrice(id) {
            const vehicleType = this.vehicleTypes.find(
                (item) => item.value === id
            );
            if (vehicleType) {
                console.log('yeah man am here btw', vehicleType)
                var price = vehicleType.order_base_price;
                if (this.payload_helper === "true"){
                    price += vehicleType.helper_price;
                }
                return price;
            }
        },
        getOrderData() {
            return {
                name: this.form.customer_name,
                mobile_no: this.form.customer_mobile_no,
                pickup_address_id: this.form.pickup_address,
                dropoff_address: this.form.dropoff_address,
                dropoff_time: this.form.dropoff_time,
                vehicle_type_id: this.vehicleType,
                helper: this.payload_helper === "true" ? 1 : 0,
                no_of_pieces: this.form.no_of_pieces,
                invoice_ref_no: this.form.invoice_ref_no,
                invoice_total_price: this.form.invoice_total_price,
                payment_method: this.paymentOption ,
                extra_service_price: this.extraServicePrice,
                total_price: this.paymentPrice + (this.payload_helper == "false" ? 0 : this.helperPrice),
                out_of_town: this.orderStatus == 'out_of_town' ? true : false,
                assigned_locations: this.form.dropoff_address ? {
                    dropoff_address: {
                        ...this.form.assigned_locations.dropoff_address
                    },
                    pickup_address: {
                        ...this.form.assigned_locations.pickup_address
                    },
                } : undefined
            };
        },
        async previewImage(event) {
            this.orderImages = event.target.files;
        },
        getAllVehicleTypes() {
            getVehicleTypes({
                filter: {
                    store_id: this.storeId,
                    is_allow_business_order: true,
                },
            })
                .then((res) => {
                    const vehicleTypeList = res.data.map((item) => {
                        return {
                            value: item["id"],
                            label:
                                localStorage.getItem("lang") == "ar"
                                    ? item["type_ar"]
                                    : item["type"],
                            vehicleImage: item["vehicle_image"],
                            helper_price: item["helper_price"],
                            order_base_price: item["order_base_price"]

                        };
                    });
                    this.vehicleTypes = [...vehicleTypeList];
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        setActiveCard(card) {
            const steps = ['customer', 'address', 'details', 'payment'];
            const currentIndex = steps.indexOf(this.activeCard);
            const newIndex = steps.indexOf(card);

            if (this.activeCard === card) {
                return;
            }
            this.saveCardState(this.activeCard);

            if (newIndex <= currentIndex) {
                this.activeCard = card;
                this.restoreCardState(card);
            } else {
                const isValid = this.validatePreviousStep(currentIndex);
                if (isValid) {
                    this.activeCard = card;
                    this.restoreCardState(card);
                } else {
                    this.showErrorMessage(this.$t('complete_current_step'));
                }
            }

            this.previousActiveCard = this.activeCard;
        },

        saveCardState(card) {
            if (card === 'address') {
                this.cardStates.address = {
                    dropoffAddress: this.form.dropoff_address,
                    orderStatus: this.orderStatus
                };
            } else if (card === 'details') {
                this.cardStates.details = {
                    vehicleType: this.vehicleType
                };
            }
        },

        restoreCardState(card) {
            if (card === 'address' && this.cardStates.address) {
                this.$nextTick(() => {
                    this.form.dropoff_address = this.cardStates.address.dropoffAddress;
                    this.orderStatus = this.cardStates.address.orderStatus;
                });
            } else if (card === 'details' && this.cardStates.details) {
                this.$nextTick(() => {
                    this.vehicleType = this.cardStates.details.vehicleType;
                });
            }
        },

        validatePreviousStep(stepIndex) {
            switch (stepIndex) {
                case 0: return this.isCustomerInfoValid;
                case 1: return this.isAddressValid;
                case 2: return this.isOrderDetailsValid;
                default: return true;
            }
        },

        nextCard(card, event) {
            if (event) {
                event.stopPropagation();
            }
            this.setActiveCard(card);
        },

        showErrorMessage(message) {
            this.runToast(this.$t(message), "error");
        },

        handlePickupAddressChange(value) {
            const selectedOption = this.addressesOptions.find(option => option.value === value);
            if (selectedOption) {
                const selectedCity = this.cities.find(city => city.id === selectedOption.city_id);
                this.pickupCityId = selectedOption.city_id;
                this.form.pickup_address = selectedOption.value;
                this.form.assigned_locations.pickup_address.latitude = selectedOption.lat;
                this.form.assigned_locations.pickup_address.longitude = selectedOption.lng;
                this.form.assigned_locations.pickup_address.address_line1 = selectedOption.label;
                this.form.assigned_locations.pickup_address.city_id = selectedOption.city_id;
                this.form.assigned_locations.pickup_address.city_latitude = selectedCity.latitude;
                this.form.assigned_locations.pickup_address.city_longitude = selectedCity.longitude;
            }
        },
        handleDropoffDistrictChange(districtId) {
            const selectedDistrict = this.districtOptions.find(district => district.id === districtId);
            const selectedCity = this.cities.find(city => city.id === this.dropoffCityId);
            
            if (!selectedDistrict || !selectedCity) return;

            // Update district ID in locations
            this.form.assigned_locations.dropoff_address.district_id = districtId;

            // Update address line to include both city and district
            const cityName = this.lang === 'ar' ? selectedCity.name_ar : selectedCity.name;
            const districtName = this.lang === 'ar' ? selectedDistrict.name_ar : selectedDistrict.name;
            this.form.dropoff_address = `${cityName}, ${districtName}`;
        },
        handleDropoffCityChange(cityId) {
            const selectedCity = this.cities.find(city => city.id === cityId);
            
            if (!selectedCity) return;

            if (selectedCity.id === this.pickupCityId) {
                this.showErrorMessage(this.$t('not_out_of_town_error'));
                this.dropoffCityId = null;
                return;
            }

            // Update dropoff address information
            this.form.assigned_locations.dropoff_address = {
                latitude: selectedCity.latitude,
                longitude: selectedCity.longitude,
                city_id: selectedCity.id,
                district_id: null,
                city_latitude: selectedCity.latitude,
                city_longitude: selectedCity.longitude
            };

            // Update address line with city name
            this.form.dropoff_address = this.lang === 'ar' ? selectedCity.name_ar : selectedCity.name;

            // Clear district selection and fetch new districts
            this.dropoffDistrictId = null;
            this.getDistricts(cityId);
        },
        helperChange() {
            if (this.payload_helper === "true") {
                this.totalPrice += this.helperPrice;
            } else {
                this.totalPrice -= this.helperPrice;
            }
        },
        async fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve({ name: file.name, base64: reader.result });
                reader.onerror = error => reject(error);
            });
        },

        async storeFilesInLocalStorage(files) {
            const filesArray = Array.from(files);
            const base64Files = await Promise.all(filesArray.map(file => this.fileToBase64(file)));
            localStorage.setItem('submittedOrderImages', JSON.stringify(base64Files));
        },
        async addOrder(values) {
            try {
                if (!this.validateAllSteps()) {
                    this.showErrorMessage(this.$t('please_complete_all_required_fields'));
                    return;
                }

                if (this.orderImages.length > 4) {
                    this.runToast(this.$t("select4Images"), "error");
                    return;
                }

                if (this.form.assigned_locations.dropoff_address.latitude && !this.form.assigned_locations.dropoff_address.city_id) {
                    this.runToast(this.$t("city_not_selected_error"), "error");
                    return;
                }

                var orderData = this.getOrderData();

                // If using store credit but not eligible, process as immediate payment
                if (this.paymentOption === "store_credit") {
                    var priceResponse = 0

                    if (this.orderStatus == 'out_of_town') {
                        priceResponse = await offerSuggestedPrice({
                            pickup_address: JSON.stringify(this.form.assigned_locations.pickup_address),
                            dropoff_address: JSON.stringify(this.form.assigned_locations.dropoff_address),
                            vehicle_type_id: this.vehicleType,
                            helper: this.payload_helper == "false" ? 0 : 1,
                            out_of_town: true
                        });
                        priceResponse = priceResponse.min_delivery_price;
                    }
                    else {
                        orderData.total_price = this.getVehicleInTownPrice(this.vehicleType);
                        priceResponse = await calculateInTownOrderPrice(orderData, {
                            store_id: this.storeId
                        });
                    }

                    this.paymentPrice = priceResponse;
                    orderData.total_price = this.paymentPrice;
                    if (this.payload_helper == "true"){
                        orderData.total_price += this.helperPrice; 
                    }
                    // localStorage.setItem('submittedOrder', JSON.stringify(orderData));
                    // if (this.orderImages?.length) {
                    //     await this.storeFilesInLocalStorage(this.orderImages);
                    // }
                    // this.showPaymentModal = true;
                    // return;
                }

                // Submit the order
                this.submitSpinner = true;
                const response = await createOrder(orderData, { store_id: this.storeId });

                if (this.orderImages?.length) {
                    await this.uploadImageInFirestore(response.id);
                }

                this.submitSpinner = false;
                this.updateCredit();
                this.$refs.addOrderRef.resetForm();
                this.$router.push({ name: 'Dashboard' });

            } catch (error) {
                this.submitSpinner = false;
                this.apiError(error.response);
            }

        },

        validateAllSteps() {
            // Validate customer information
            if (!this.isCustomerInfoValid) {
                this.setActiveCard('customer');
                return false;
            }

            // Validate address information
            if (!this.isAddressValid) {
                this.setActiveCard('address');
                return false;
            }

            // Validate order details
            if (!this.isOrderDetailsValid) {
                this.setActiveCard('details');
                return false;
            }

            // Validate payment information
            if (!this.paymentOption) {
                this.setActiveCard('payment');
                return false;
            }

            return true;
        },
        async submitPayment() {
            const isValid = await this.$refs.paymentFormRef.validate();
            if (!isValid) {
                return;
            }

            const data = {
                publishable_api_key:
                    process.env.VUE_APP_MOYASAR_PUBLISHABLE_API_KEY,
                amount: Math.round(this.paymentPrice * 100),
                currency: "SAR",
                callback_url: process.env.VUE_APP_MOYASAR_PAYMENT_CALLBACK_URL,
                source: {
                    type: "creditcard",
                    name: this.cardDetail.name,
                    number: this.cardDetail.cardNumber,
                    month: this.cardDetail.expiryMonth,
                    year: this.cardDetail.expiryYear,
                    cvc: this.cardDetail.cvv,
                },
            };



            try {
                const response = await axios.post(
                    "https://api.moyasar.com/v1/payments",
                    data
                );
                this.showPaymentModal = false;
                this.form.invoice_total_price = response.data.amount / 100;
                this.paymentOption = "store_credit";
                this.addOrder();

                window.location.href = response.data.source.transaction_url;
            } catch (error) {
                this.apiError(error.response);
            }
        },
        getAddresses() {
            if (this.storeId !== null) {
                get({
                    store_id: this.storeId,
                }).then((res) => {
                    res.data.forEach((item) => {
                        this.addressesOptions.push({
                            label: item.address_line1,
                            value: item.id,
                            lat: item.latitude,
                            lng: item.longitude,
                            city_id: item.city_id,
                        });
                    });
                });
            }
        },
        updateCredit() {
            me().then((res) => {
                this.$store.dispatch(
                    "setStoreCredit",
                    res.user?.stores[0]?.credit
                        ? res.user?.stores[0]?.credit
                        : 0
                );
            });
            this.creditForPlaceOrder(this.storeId);
        },
        uploadImageInFirestore(orderId) {
            let i;
            for (i = 0; i < this.orderImages.length; i++) {
                const file = this.orderImages[i];
                const storage = getStorage();
                const storageRef = ref(
                    storage,
                    `orders/${orderId}/images/` +
                    Math.ceil(Math.random() * 1000000) +
                    "-" +
                    file.name
                );
                uploadBytes(storageRef, file).then((snapshot) => {
                    getDownloadURL(storageRef)
                        .then((url) => {
                            const dbRef = addDoc(
                                collection(
                                    db,
                                    `one8Order/${orderId}/order/order/images`
                                ),
                                {
                                    image: url,
                                    time: Math.round(+new Date() / 1000),
                                }
                            );
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                });
            }
            this.orderImages = [];
        },
        dropoffAddressRules() {
            return [
                {
                    required: true,
                    message: this.$t('dropoff_address_required')
                },
                {
                    validator: () => {
                        if (this.orderStatus === 'out_of_town' && 
                            this.dropoffCityId === this.pickupCityId) {
                            return this.$t('dropoff_city_must_be_different');
                        }
                        return true;
                    }
                }
            ];
        },
        getCities() {
            getCityList({ limit: -1 })
                .then((res) => {
                    this.cities = res.data.map(city => ({
                        id: city.id,  // Keep the original id
                        name: city.name,
                        name_ar: city.name_ar,
                        latitude: city.latitude,
                        longitude: city.longitude
                    }));
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        getDistricts(cityId) {
            this.districtLoading = true;
            this.districtOptions = [];
            this.dropoffDistrictId = null;
            
            getDistrictList({
                limit: -1,
                filter: { city_id: cityId },
            })
                .then((res) => {
                    this.districtOptions = res.data.map(district => ({
                        id: district.id,
                        name: district.name,
                        name_ar: district.name_ar
                    }));
                })
                .catch(({ response }) => {
                    this.apiError(response);
                })
                .finally(() => {
                    this.districtLoading = false;
                });
        },
        handleOrderStatusChange() {
            if (this.orderStatus === "out_of_town") {
                this.form.dropoff_address = "";
                this.dropoffCityId = null;
                this.dropoffDistrictId = null;
                this.form.assigned_locations.dropoff_address = {
                    latitude: null,
                    longitude: null,
                    city_id: null,
                    district_id: null,
                    city_latitude: null,
                    city_longitude: null
                };
            } else {
                this.form.dropoff_address = null;
                this.form.assigned_locations.dropoff_address = {};
            }
        },
    },
};
</script>

<style scoped>
.add-in-town-order {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
}

.progress-bar-container {
    width: auto;
    margin-right: 20px;
}

.form-container {
    flex: 1;
    max-width: 100%;
}

.order-cards {
    width: 100%;
}

.order-card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    width: 95%;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
}

.order-card h3 {
    margin-bottom: 20px;
}

.order-card.active {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.order-card:not(.active)>div {
    display: none;
}

.order-card .d-flex {
    display: flex;
    gap: 20px;
}

.order-card .d-flex>div:first-child {
    width: 60%;
}

.order-card .d-flex>div:last-child {
    width: 35%;
}

.field-wrap {
    margin-bottom: 15px;
}

.btn-next,
.btn-submit,
.btn-select-map {
    margin-top: 15px;
    padding: 12px 24px;
    font-size: 16px;
    transition: all 0.3s ease;
}

.btn-select-map {
    margin-top: 5px;
}

.text-right {
    text-align: right;
}

.column-left,
.column-right {
    flex: 1;
    padding: 10px;
}

.radio-wrap label.el-radio-button {
    height: 100%;
}

.radio-wrap--custom input[type="radio"].el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background: #fff !important;
    border: 2px solid #7368fb !important;
    box-shadow: none !important;
}

.vehicle-selection :deep(.el-radio-button.is-active) img {
    filter: none !important;
}

.radio-wrap .el-radio-group {
    align-items: stretch !important;
}

.vehicle-type-box .vehicle-type-box,
.vehicle-type-box .el-radio-button__inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropoff-address-container {
    display: flex;
    flex-direction: column;
}

.heading-with-note {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.heading-with-note h3 {
    margin-right: 10px;
}

.optional-note {
    font-size: 0.875em;
    color: #6c757d;
    margin: 0;
}

.bottom-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.customer-section {
    padding-top: 55px;
}

.dropoff-text-container {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: calc(1.2em * 3);
    line-height: 1.2em;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .add-in-town-order {
        padding: 10px;
    }

    .order-card {
        width: 98%;
    }

    .order-card .d-flex {
        gap: 15px;
    }

    .column-left,
    .column-right {
        padding: 5px;
    }

    .bottom-container {
        padding: 5px;
    }

    .customer-section {
        padding-top: 50px;
    }
}

@media (max-width: 768px) {
    .add-in-town-order {
        flex-direction: column;
        padding: 5px;
    }

    .progress-bar-container {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .form-container {
        width: 100%;
    }

    .order-card {
        width: 100%;
        padding: 15px;
    }

    /* Make all content stack and take full width */
    .order-card .d-flex {
        flex-direction: column;
        gap: 15px;
    }

    .order-card .d-flex>div:first-child,
    .order-card .d-flex>div:last-child,
    .order-card .d-flex>div {
        width: 100% !important;
    }

    /* Ensure form elements take full width */
    .field-wrap,
    .el-select,
    .el-input,
    .form-control {
        width: 100% !important;
    }

    .column-left,
    .column-right {
        flex: 1 1 100%;
        padding: 5px;
    }

    .btn-next,
    .btn-submit,
    .btn-select-map {
        padding: 10px 20px;
        font-size: 14px;
        width: 100%;
        min-width: unset;
        margin: 8px 0;
    }

    .customer-section {
        padding-top: 30px;
    }

    .heading-with-note {
        flex-direction: column;
        align-items: flex-start;
    }

    .text-right {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
    }

    /* Fix radio buttons and groups */
    .order-form-wrap,
    .radio-wrap .el-radio-group {
        flex-direction: column !important;
        width: 100%;
    }

    .order-form-wrap .el-radio,
    .order-form-wrap .el-radio-button,
    .vehicle-type-box .el-radio-button {
        width: 100%;
        margin-bottom: 8px;
    }

    .rtl .label-modal{
        width: fit-content !important;
    }
}

@media (max-width: 480px) {
    .add-in-town-order {
        padding: 5px;
    }

    .order-card {
        padding: 10px;
    }

    .order-card .d-flex {
        gap: 10px;
    }

    .field-wrap {
        margin-bottom: 10px;
    }

    .btn-next,
    .btn-submit,
    .btn-select-map {
        padding: 8px 16px;
        font-size: 11px !important;
        width: 100%;
    }

    .order-form-wrap,
    .radio-wrap .el-radio-group {
        gap: 4px;
    }

    .el-radio__label {
        font-size: 11px;
    }

    .customer-section .field-wrap {
        margin-bottom: 5px;
    }

    .buttons {
        gap: 4px;
        display: flex;
        flex-direction: column;
    }

    .label-modal,
    .field-wrap .form-control{
        font-size: 10px !important;
    }

    .rtl .label-modal{
        width: fit-content !important;
    }
}
</style>
