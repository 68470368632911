<template>
  <div class="vertical-progress-bar">
    <div v-for="step in totalSteps" :key="step" class="step-container">
      <div :class="['step-circle', { 'active': step <= currentStep }]">
        {{ step }}
      </div>
      <div v-if="step < totalSteps" :class="['step-line', { 'active': step < currentStep, 'current': step === currentStep }]"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerticalProgressBar',
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
.vertical-progress-bar {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  height: 80%;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.step-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  transition: all 0.3s ease;
}

.step-circle.active {
  background-color: #7368fb;
}

.step-line {
  height: 50px;
  width: 2px;
  background-color: #ccc;
  transition: all 0.3s ease;
}

.step-line.active {
  background-color: #7368fb;
}

.step-line.current {
  height: 260px;
}

@media (max-width: 768px) {
  .vertical-progress-bar {
    right: 10px;
  }

  .step-circle {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }

  .step-line {
    height: 50px;
  }

  .step-line.current {
    height: 260px;
  }
}
</style>