import axiosInstance from "@/plugins/customAxios";

export const get = (params = null) => {
    return axiosInstance.get("v1/complaints", { params: params }).then((res) => {
        return res.data;
    });
};

export const close = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/admin/complaints/${id}/status`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};
