<template>
    <badge rounded :style="`background-color: ${colors[value]}`" size="md">
        <span class="status status-bold">{{ $t(`${labels[value]}`) }}</span>
    </badge>
</template>

<script>
import mixin from "@/mixins/common";
export default {
    mixins: [mixin],
    name: "custom-label",
    data() {
        return {
            colors: {
                order_delivery: "#7368FB22",
                credit_transfer: "#B0A11522",
                bonus: "#00929B22",
                fine: "#FF000022",
                compensation: "#689F3822",
            },
            labels: {
                order_delivery: this.$t("order_delivery"),
                credit_transfer: this.$t("credit_transfer"),
                bonus: this.$t("bonus"),
                fine: this.$t("fine"),
                compensation: this.$t("compensation"),
            },
        };
    },
    props: {
        value: {
            type: [String, Boolean],
            required: true,
            default: "Label",
        },
    },
};
</script>

<style></style>
