<template>
    <!-- without giving d-inline , icon was moving to next line since div is a block level element 
 therefore below class d-inline is given intentionally so that it does not disturb the design when used with other elements  -->
    <div class="d-inline">
        <!-- below clickEvent is a prop to slot in which we are binding the changestatusmodal method with slot 
        so that slot content can access it , slot scoping -->
        <slot :clickEvent="changeStatusModal">
            <el-tooltip :content="$t('update_status')" placement="top">
                <i
                    class="fas fa-sync fa-md text-default mr-2"
                    style="cursor: pointer"
                    @click="changeStatusModal"
                ></i>
            </el-tooltip>
        </slot>

        <!-- Modal props show is for toggling Modal Display 
        @close child event is for closing modal -->
        <modal
            :show="showModal"
            @close="showModal = false"
            size="sm"
            body-classes="p-0"
            v-loading="isLoading"
        >
            <template v-slot:header>
                <div>
                    <img
                        src="@/assets/images/icons/common/changeUserStatus.png"
                        alt=""
                    />
                </div>
            </template>
            <div class="px-5">
                <el-select
                    :placeholder="$t('select')"
                    v-model="status"
                    class="d-block"
                    filterable
                >
                    <!-- below defaulOptions.userStatus is a global mixin data in which all default options are present that are used in all dropdowns -->
                    <el-option
                        v-for="option in defaultOptions.userStatus"
                        class="select-danger"
                        :value="option.value"
                        :label="$t(option.label)"
                        :key="option.label"
                    >
                    </el-option>
                </el-select>
            </div>

            <div class="text-center my-4">
                <base-button
                    @click.native="changeStatusFormSubmit"
                    :loading="submitSpinner"
                >
                    {{ $t("submit") }}</base-button
                >
                <base-button @click.native="showModal = false">
                    {{ $t("close") }}</base-button
                >
            </div>
        </modal>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { changeStatus } from "@/api/users";
export default {
    data() {
        return {
            status: "",
            isLoading: true,
            submitSpinner: false,
            showModal: false,
        };
    },
    props: ["user"],
    components: {},
    methods: {
        changeStatusModal() {
            this.showModal = true;
            this.status = this.user.user_status;
            this.isLoading = false;
        },

        changeStatusFormSubmit() {
            this.submitSpinner = true;
            changeStatus(
                this.userRole === 1 ||
                    this.userRole === 9 ||
                    this.userRole === 7 ||
                    this.userRole === 8 ||
                    this.userRole === 6
                    ? "admin"
                    : "store",
                { user_id: this.user.id, status: this.status },
                {
                    store_id: this.userRole === 1 ? null : this.storeId,
                }
            )
                .then((res) => {
                    this.submitSpinner = false;
                    this.runToast(res.message, "success");
                    this.showModal = false;
                    // below event emit to parent is for having parent component to further control what next to do after Status has been changed
                    // Like Calling the getCustomers Api when status is changed and Refreshing Table
                    this.$emit("changeStatusCompleted");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;

                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
    },
    mixins: [mixin],
};
</script>

<style></style>
