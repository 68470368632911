import List from "@/views/Pages/Addresses/List";
export default [
    {
        path: "addresses",
        components: { default: List },
        name: "Addresses",
        meta: {
            title: "addresses",
            role: [4],
        },
    },
];
