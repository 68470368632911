import axiosInstance from "@/plugins/customAxios";

export const get = (params = null) => {
    return axiosInstance
        .get("v1/admin/broadcast-notifications", { params: params })
        .then((res) => {
            return res.data;
        });
};

export const send = (data) => {
    return axiosInstance
        .post("v1/admin/broadcast-notifications", data)
        .then((res) => {
            return res.data;
        });
};
