<template>
    <div>
        <hr />
        <div class="card py-3 px-5 rounded-md">
            <div
                class="border-0 card-header px-0 d-flex justify-content-between align-items-center"
            >
                <router-link
                    :to="{
                        name: 'Users Upsert',
                        params: { id: 'add' },
                    }"
                >
                    <button
                        class="text-white btn btn-default btn-sm font-primary"
                    >
                        <i
                            class="mr-2 text-white fas fa-plus-circle fa-lg btn-icon"
                        >
                            <span>{{ $t("add_user") }}</span>
                        </i>
                    </button>
                </router-link>
            </div>

            <div>
                <div class="d-flex mb-4">
                    <search-filter
                        :filters="[
                            {
                                id: 'name',
                            },
                            {
                                id: 'email',
                                type: 'email',
                            },
                            {
                                id: 'mobile_no',
                                type: 'number',
                            },
                            {
                                id: 'role_name',
                            },
                        ]"
                        @search="(searchObject) => (filters = searchObject)"
                    />
                </div>
            </div>

            <div class="row">
                <main
                    :class="
                        isFilter
                            ? 'col-md-9 col-xs-11 p-l-2 p-t-2'
                            : 'col-md-12 col-xs-11 p-l-2 p-t-2'
                    "
                >
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="users"
                            v-loading="isLoading"
                            :empty-text="$t('no_data_found')"
                            @row-click="rowClicked"
                        >
                            <el-table-column
                                :label="$t('name')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="mb-0 font-weight-600 name text-capitalize"
                                        >{{ row.name }}</span
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('email')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="mb-0 font-weight-600 name text-nowrap"
                                        >{{ row.email }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('mobile_no')"
                                min-width="160px"
                            >
                                <template v-slot="{ row }">
                                    <span class="mb-0 font-weight-600 name">
                                        +{{ row.country_code + " " }}
                                        {{ row.mobile_no }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('status')"
                                min-width="150px"
                                prop="status"
                            >
                                <template v-slot="{ row }">
                                    <custom-label :label="row.user_status" />
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('role')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="mb-0 font-weight-600 name">
                                        <!-- {{ rolesUpercase(row.role) }} -->
                                        {{ $t(row.role) }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('actions')"
                                min-width="210px"
                            >
                                <template v-slot="{ row }">
                                    <div class="d-flex">
                                        <el-tooltip
                                            :content="$t('edit')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="cursor-pointer bg-sky-dark text-secondary"
                                                :title="$t('edit')"
                                                style="cursor: pointer"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="'/users/' + row.id"
                                                >
                                                    <img
                                                        src="@/assets/svg/edit-2.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>

                                        <!-- User Update STATUS Modal -->
                                        <update-user-status
                                            :user="row"
                                            @changeStatusCompleted="getUsers"
                                        >
                                            <template
                                                v-slot:default="{ clickEvent }"
                                            >
                                                <el-tooltip
                                                    :content="
                                                        $t('update_status')
                                                    "
                                                    placement="top"
                                                >
                                                    <badge
                                                        circle
                                                        size="lg"
                                                        class="cursor-pointer bg-sky-dark text-secondary"
                                                        style="cursor: pointer"
                                                        @click="clickEvent"
                                                    >
                                                        <i
                                                            class="mx-1 fas fa-sync"
                                                        ></i>
                                                    </badge>
                                                </el-tooltip>
                                            </template>
                                        </update-user-status>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="users.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>

                <!-- User Filter Sidebar start  -->
                <div
                    class="col-md-3 col-xs-1 p-l-0 p-r-0 collapse"
                    id="togglebar"
                >
                    <div>
                        <label for="name" class="mt-3 form-control-label">{{
                            $t("name")
                        }}</label>
                        <Field
                            type="text"
                            class="form-control"
                            name="type"
                            v-model="filters.name"
                        />

                        <label for="" class="mt-3 form-control-label">{{
                            $t("email")
                        }}</label>
                        <Field
                            type="text"
                            class="form-control"
                            name="email"
                            v-model="filters.email"
                        />

                        <label for="" class="mt-3 form-control-label">{{
                            $t("mobile_no")
                        }}</label>
                        <Field
                            type="text"
                            class="form-control"
                            name="type_ar"
                            v-model="filters.mobile_no"
                        />

                        <label for="" class="mt-3 form-control-label">{{
                            $t("role")
                        }}</label>
                        <Field
                            type="text"
                            class="form-control"
                            name="role"
                            v-model="filters.role_name"
                        />

                        <div class="mt-5 text-center">
                            <button
                                @click="resetFilter()"
                                class="btn btn-md text-danger reset-button"
                            >
                                <img
                                    src="@/assets/images/icons/common/reset-filter.png"
                                    style="width: 20px"
                                />
                                {{ $t("reset") }}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- End of Filter sidebar -->
            </div>
        </div>
        <!--  End of Table -->
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getUsers } from "@/api/users";
import { mapGetters } from "vuex";
import UpdateUserStatus from "@/components/common/UpdateUserStatus.vue";
export default {
    data() {
        return {
            users: [],
            currentPage: 1,
            filters: {
                name: "",
                email: "",
                mobile_no: "",
                role_name: "",
            },
            isLoading: false,
            meta: {},
            totalData: null,
            isFilter: false,
        };
    },
    mixins: [mixin],
    components: {
        UpdateUserStatus,
    },
    created() {
        this.getUsers();
    },
    watch: {
        currentPage: {
            handler: function () {
                this.getUsers();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.currentPage = 1;
                this.getUsers();
            },
        },
    },
    computed: {
        ...mapGetters(["user"]),
    },
    methods: {
        rolesUpercase(role) {
            var i,
                roles = role.split("_");
            for (i = 0; i < roles.length; i++) {
                roles[i] = roles[i].charAt(0).toUpperCase() + roles[i].slice(1);
            }
            return roles.join(" ");
        },
        toggleFilter() {
            this.isFilter = !this.isFilter;
            if (this.isFilter) {
                $("#togglebar").addClass("show");
            } else {
                $("#togglebar").removeClass("show");
            }
        },
        getUsers() {
            this.isLoading = true;
            getUsers(this.userRole === 1 ? "admin" : "store", {
                role:
                    this.userRole === 1
                        ? [
                              "operator",
                              "store_account_manager",
                              "provider_account_manager",
                          ]
                        : null,
                store_id: this.userRole === 1 ? null : this.storeId,

                query: {
                    role_name: this.filters.role_name,
                    ...this.filters,
                },

                page: this.currentPage,

                filter: {
                    name: this.filters.name,
                    email: this.filters.email,
                    mobile_no: this.filters.mobile_no,
                },
            })
                .then((res) => {
                    this.users = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                name: "",
                email: "",
                mobile_no: "",
                role_name: "",
            };
        },
        rowClicked(row, column, event) {
            if(column.label != this.$t("actions")){
                this.$router.push({
                    name: "Users Upsert",
                    params: { id: row.id },
                });
            }
            return;
        },
    },
};
</script>
