import List from "@/views/Pages/BusinessComplaints/List";

export default [
    {
        path: "business-complaints",
        name: "Business Complaints",
        components: { default: List },
        meta: {
            title: "business_complaints",
            role: [1, 6, 7],
        },
    },
];
