<template>
    <div class="price-claculator">
        <div class="pt-4 px-4 w-100">
            <Form @submit="calculatePrice" ref="calculatePriceRef">
                <div class="d-flex flex-column">
                    <div class="head-container d-flex justify-content-center flex-column align-items-center pt-2 pb-4">
                        <h1>{{ $t("calculate_delivery_title") }}</h1>
                        <p>{{ $t("calculate_delivery_desc") }}</p>
                        <!-- <card class="rounded-md bg-primary" style="width: 80%; max-height: 1px">
                            <h1 class="text-white d-flex justify-content-center">{{ $t("pickup_address") }}</h1>
                        </card> -->
                    </div>

                    <div class="top-container d-flex flex-row">
                        <!-- Left Column -->
                        <div class="flex-column column-left">
                            <!-- Pickup Address Section -->
                            <div class="">
                                <h3>{{ $t("pickup_address") }}</h3>
                                <Field v-slot="{ field }" name="pickup_address_id" rules="required"
                                    :label="$t('pickup_address')" v-model="form.pickup_address">
                                    <el-select class="d-block" name="pickup_address_id" v-bind="field"
                                        :placeholder="$t('pickup_address')" v-model="form.pickup_address"
                                        @change="updatePickupLocation" filterable>
                                        <el-option v-for="option in addressesOptions" class="select-danger"
                                            :value="option.value" :label="option.label" :key="option.label">
                                        </el-option>
                                    </el-select>
                                </Field>
                                <ErrorMessage name="pickup_address_id" class="error" />
                            </div>
                            <!-- Vehicle Type Section -->
                            <div class="">
                                <h3>{{ $t("vehicle_type") }}</h3>
                                <Field v-slot="{ field }" name="vehicle_type_id" rules="required"
                                    :label="$t('vehicle_type')" v-model="vehicleType">
                                    <div class="radio-wrap radio-wrap--custom">
                                        <el-radio-group class="vehicle-type-box">
                                            <div v-for="option in vehicleTypes" :key="option.id">
                                                <el-radio-button v-bind="field" :label="option.value"
                                                    name="vehicle_type_id">
                                                    <span style="white-space: normal">{{ option.label }}</span>
                                                    <img :src="option.vehicleImage" v-if="option.vehicleImage" />
                                                    <img v-else src="@/assets/svg/Truck.svg" />
                                                </el-radio-button>
                                            </div>
                                        </el-radio-group>
                                    </div>
                                </Field>
                                <ErrorMessage name="vehicle_type_id" class="error" />
                            </div>

                            <!-- Helper Section -->
                            <div class="">
                                <Field rules="required" v-model="payload_helper" name="payload_helper"
                                    :label="$t('package_order_messages.helper')">
                                    <div class="order-form-wrap">
                                        <el-radio v-model="payload_helper" name="payload_helper"
                                            class="mb-2 add-order-radio" label="false" border :disabled="!vehicleType">
                                            {{ $t("no_helper") }}
                                        </el-radio>
                                        <el-radio v-model="payload_helper" name="payload_helper"
                                            class="mb-2 add-order-radio" label="true" border :disabled="!vehicleType">
                                            {{ $t("package_order_messages.helper") }}
                                        </el-radio>
                                    </div>
                                </Field>
                                <ErrorMessage name="payload_helper" class="error" />
                            </div>
                        </div>

                        <!-- Right Column -->
                        <div class="flex-column column-right px-5">
                            <div class="dropoff-address-container">
                                <div class="heading-with-note">
                                    <h3>{{ $t("dropoff_address") }}</h3>
                                </div>
                                <!-- Dropoff Location Section -->
                                <div class="field-wrap">
                                    <!-- <label for="city_id" class="form-control-label">
                                        {{ $t("city") }}
                                    </label> -->
                                    <Field rules="required" :label="$t('city')" v-model="dropoffCityId" name="city_id">
                                        <el-select v-model="dropoffCityId" class="d-block" :placeholder="$t('city')"
                                            @change="handleCityChange">
                                            <el-option v-for="option in cities" :key="option.id"
                                                :label="lang == 'ar' ? option.name_ar : option.name"
                                                :value="option.id" />
                                        </el-select>
                                    </Field>
                                    <ErrorMessage name="city_id" class="error" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Container for bottom side elements -->
                    <div class="bottom-card-container">
                        <card class="price-summary-card rounded-md">
                            <h2 class="text-xl font-bold text-right mb-4 d-flex justify-content-center">{{
                                $t("delivery_price") }}</h2>
                            <div class="space-y-2 px-4">
                                <div class="d-flex justify-content-between">
                                    <span>{{ $t("distance") }}</span>
                                    <span class="font-semibold">{{ priceCard.distance }} {{ $t("kms") }}</span>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <span>{{ $t("base_price") }}</span>
                                    <span class="font-semibold">{{ priceCard.basePrice }} {{ $t("SAR") }}</span>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <span>{{ $t("helper_price") }}</span>
                                    <span class="font-semibold">
                                        <span v-if="payload_helper === 'true'">{{ helperPrice }} {{ $t("SAR") }}</span>
                                        <span v-else>0 {{ $t("SAR") }}</span> 
                                    </span>
                                </div>
                                <div class="d-flex justify-content-between font-bold">
                                    <span>{{ $t("invoice.sub_total") }}</span>
                                    <span>{{ priceCard.subtotal }} {{ $t("SAR") }}</span>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <span>{{ $t("settings_page.vat_percentage") }}</span>
                                    <span class="font-semibold">+{{ priceCard.vat }}%</span>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div style="width: 10%;">
                                        <hr style="border-top: 3px solid black; width: 100%;">
                                    </div>
                                    <div style="width: 10%;">
                                        <hr style="border-top: 3px solid black;">
                                    </div>
                                </div>

                                <!-- Total section -->
                                <div class="mt-1 d-flex justify-content-between">
                                    <span class="font-bold">{{ $t("total") }}</span>
                                    <span class="font-semibold">{{ priceCard.displayPrice }} {{ $t("SAR") }}</span>
                                </div>
                            </div>
                        </card>


                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { get, getDistricts as getDistrictList } from "@/api/addresses";
import { getVehicleTypes } from "@/api/vehicleType";
import { inTownPrice, offerSuggestedPrice } from "@/api/orders";
import { get as getCityList } from "@/api/cities";
import mixin from "@/mixins/common";

export default {
    components: {
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            submitSpinner: false,
            showPaymentModal: false,
            form: {
                customer_name: null,
                customer_mobile_no: null,
                pickup_address: null,
                dropoff_address: null,
                dropoff_time: null,
                invoice_ref_no: null,
                invoice_total_price: null,
                no_of_pieces: null,
                assigned_locations: {
                    dropoff_address: {
                        latitude: null,
                        longitude: null,
                        address_line1: null,
                        city_id: null,
                        district_id: null,
                    },
                    pickup_address: {
                        latitude: null,
                        longitude: null,
                        address_line1: null,
                        city_id: null,
                        district_id: null,
                    },
                },
            },
            vehicleType: null,
            vehicleTypes: [],
            dropOffTimeOptions: [
                {
                    value: "morning",
                    label: this.$t("morning"),
                },
                {
                    value: "evening",
                    label: this.$t("evening"),
                },
                {
                    value: "default",
                    label: this.$t("unspecified"),
                },
            ],
            pickupAddressOptions: [],
            paymentOption: null,
            addressesOptions: [],
            extraServicePrice: false,
            orderImages: [],
            payload_helper: "false",
            helperPrice: 0,
            totalPrice: 0,
            dropoffCityId: null,
            dropoffDistrictId: null,
            cities: [],
            districtOptions: [],
            lang: localStorage.getItem("lang"),
            outOfTownOrder: true,
            suggestedPrice: null,
            priceCard: {
                distance: 0,
                commission: 0,
                total: 0,
                vat: 0,
                basePrice: 0,
                subtotal: 0,
                displayPrice: 0,
            },
            originalPickupLocation: {
                latitude: null,
                longitude: null,
                address_line1: null,
            },
        };
    },
    props: ["showModal"],
    mixins: [mixin],
    watch: {
        vehicleType(newValue) {
            const vehicleType = this.vehicleTypes.find((item) => item.value === newValue);
            if (vehicleType) {
                this.helperPrice = vehicleType.helper_price || 0;
                this.updatePrice();
                if (this.payload_helper === "true") {
                    this.totalPrice += this.helperPrice;
                }
            }
            if (vehicleType && this.form.assigned_locations.dropoff_address.address_line1 && this.form.assigned_locations.pickup_address.address_line1) {
                this.fetchSuggestedPrice();
            }
        },
        form: {
            handler(newValue) {
                this.totalPrice = newValue.invoice_total_price || 0;
                if (this.payload_helper === "true") {
                    this.totalPrice += this.helperPrice;
                }
            },
            deep: true,
        },
        payload_helper(newValue) {
            this.updatePrice();
        },
    },
    created() {
        this.getAllVehicleTypes();
        this.getAddresses();
        this.getCities();
    },
    methods: {
        getAllVehicleTypes() {
            getVehicleTypes({
                filter: {
                    store_id: this.storeId,
                    is_allow_business_order: true,
                },
            })
                .then((res) => {
                    const vehicleTypeList = res.data.map((item) => {
                        return {
                            value: item["id"],
                            label: localStorage.getItem("lang") == "ar" ? item["type_ar"] : item["type"],
                            vehicleImage: item["vehicle_image"],
                            helper_price: item["helper_price"],
                        };
                    });
                    this.vehicleTypes = [...vehicleTypeList];
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        helperChange() {
            this.updatePrice();
        },
        getAddresses() {
            if (this.storeId !== null) {
                get({
                    store_id: this.storeId,
                }).then((res) => {
                    res.data.forEach((item) => {
                        this.addressesOptions.push({
                            label: item.address_line1,
                            value: item.id,
                            lat: item.latitude,
                            lng: item.longitude,
                            city_id: item.city_id,
                        });
                    });
                });
            }
        },

        updatePickupLocation(value) {
            const selectedAddress = this.addressesOptions.find((option) => option.value === value);
            if (selectedAddress) {
                // Store original pickup location
                this.originalPickupLocation = {
                    latitude: selectedAddress.lat,
                    longitude: selectedAddress.lng,
                    address_line1: selectedAddress.label,
                    city_id: selectedAddress.city_id,
                };

                // Set initial pickup location
                this.form.assigned_locations.pickup_address = {
                    latitude: selectedAddress.lat,
                    longitude: selectedAddress.lng,
                    address_line1: selectedAddress.label,
                    city_id: selectedAddress.city_id,
                };

                // Check if this is an out-of-town delivery
                if (this.form.assigned_locations.dropoff_address.city_id &&
                    selectedAddress.city_id !== this.form.assigned_locations.dropoff_address.city_id) {
                    // Out of town delivery - find and use pickup city's coordinates
                    const pickupCity = this.cities.find(city => city.id === selectedAddress.city_id);
                    if (pickupCity) {
                        this.form.assigned_locations.pickup_address.latitude = pickupCity.latitude;
                        this.form.assigned_locations.pickup_address.longitude = pickupCity.longitude;
                    }
                }

                this.handleOutOfTownOrderChange();
            }
        },

        async handleOutOfTownOrderChange() {
            if (this.form.pickup_address && this.form.dropoff_address && this.vehicleType) {
                const isOutOfTown =
                    this.form.assigned_locations.pickup_address.city_id !==
                    this.form.assigned_locations.dropoff_address.city_id;

                if (isOutOfTown) {
                    const pickupCity = this.cities.find(city =>
                        city.id === this.form.assigned_locations.pickup_address.city_id
                    );
                    if (pickupCity) {
                        this.form.assigned_locations.pickup_address.latitude = pickupCity.latitude;
                        this.form.assigned_locations.pickup_address.longitude = pickupCity.longitude;
                    }
                } else {
                    // For in-town, restore original pickup coordinates
                    this.form.assigned_locations.pickup_address.latitude = this.originalPickupLocation.latitude;
                    this.form.assigned_locations.pickup_address.longitude = this.originalPickupLocation.longitude;
                }

                this.fetchSuggestedPrice();
            } else {
                this.updatePrice();
            }
        },
        async handleCityChange(cityId) {
            const selectedCity = this.cities.find(city => city.id === cityId);
            if (selectedCity) {
                this.selectedCity = selectedCity;
                this.form.assigned_locations.dropoff_address.city_id = cityId;
                this.form.assigned_locations.dropoff_address.latitude = selectedCity.latitude;
                this.form.assigned_locations.dropoff_address.longitude = selectedCity.longitude;

                // Update pickup location if out of town
                if (this.form.assigned_locations.pickup_address.city_id) {
                    const pickupCity = this.cities.find(city =>
                        city.id === this.form.assigned_locations.pickup_address.city_id
                    );

                    if (pickupCity && pickupCity.id !== cityId) {
                        // Out of town delivery - use pickup city's coordinates
                        this.form.assigned_locations.pickup_address.latitude = pickupCity.latitude;
                        this.form.assigned_locations.pickup_address.longitude = pickupCity.longitude;
                    } else {
                        // In-town delivery - restore original coordinates
                        this.form.assigned_locations.pickup_address.latitude = this.originalPickupLocation.latitude;
                        this.form.assigned_locations.pickup_address.longitude = this.originalPickupLocation.longitude;
                    }
                }

                this.updateDropoffAddress();
                this.handleOutOfTownOrderChange();
            }
        },

        updateDropoffAddress() {
            let address = '';
            if (this.selectedCity) {
                address = this.lang === 'ar' ? this.selectedCity.name_ar : this.selectedCity.name;
                if (this.selectedDistrict) {
                    address += ', ' + (this.lang === 'ar' ? this.selectedDistrict.name_ar : this.selectedDistrict.name);
                }
            }
            this.form.dropoff_address = address;
            this.form.assigned_locations.dropoff_address.address_line1 = address;
        },
        getCities() {
            getCityList({
                limit: -1,
            })
                .then((res) => {
                    this.cities = res.data;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        handleOutOfTownOrderChange() {
            if (this.form.pickup_address && this.form.dropoff_address && this.vehicleType) {
                this.fetchSuggestedPrice();
            } else {
                this.updatePrice();
            }
        },
        async fetchSuggestedPrice() {
            try {

                var response;
                if (this.form.assigned_locations.dropoff_address.city_id == null) {
                    this.suggestedPrice = null;
                    return;
                }
                else if (this.form.assigned_locations.pickup_address.city_id === this.form.assigned_locations.dropoff_address.city_id) {
                    response = await inTownPrice({
                        pickup_address: JSON.stringify(this.form.assigned_locations.pickup_address),
                        dropoff_address: JSON.stringify(this.form.assigned_locations.dropoff_address),
                        vehicle_type_id: this.vehicleType,
                        store_id: this.storeId,
                        helper: 0,
                    });

                    this.suggestedPrice = response.total;
                    this.priceCard.vat = response.vat_percentage;
                    this.priceCard.distance = response.distance;
                    this.priceCard.commission = response.commission;
                    this.priceCard.subtotal = response.total + response.commission;
                    this.priceCard.basePrice = response.base_price - response.vat;

                } else {
                    response = await offerSuggestedPrice({
                        pickup_address: JSON.stringify(this.form.assigned_locations.pickup_address),
                        dropoff_address: JSON.stringify(this.form.assigned_locations.dropoff_address),
                        vehicle_type_id: this.vehicleType,
                        helper: 0,
                    });

                    this.suggestedPrice = response.min_delivery_price;
                    this.priceCard.vat = response.vat_percentage;
                    this.priceCard.distance = response.distance;
                    this.priceCard.commission = response.commission;
                    this.priceCard.subtotal = response.commission + response.offer_suggested_price;
                    this.priceCard.basePrice = response.offer_suggested_price + response.commission;

                }
                this.updatePrice();
            } catch (error) {
                console.error(error);
                this.suggestedPrice = null;
                this.updatePrice();
            }
        },
        updatePrice() {
            let price = this.suggestedPrice || 0;
            let subtotal = this.priceCard.subtotal;

            // Reset subtotal to base price without helper
            subtotal = this.priceCard.basePrice;

            if (this.payload_helper === "true") {
                price += this.helperPrice;
                subtotal += this.helperPrice;
            }

            this.priceCard.displayPrice = parseFloat(price.toFixed(2));
            this.priceCard.subtotal = parseFloat(subtotal.toFixed(2));
        }
    },
};
</script>

<style>
.price-claculator {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.column-left,
.column-right {
    flex: 1;
    padding: 10px;
}

.radio-wrap label.el-radio-button {
    height: 100%;
}

.radio-wrap--custom input[type="radio"].el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background: #fff !important;
    border: 2px solid #7368fb !important;
    color: #7368fb !important;
    box-shadow: none !important;
}

.radio-wrap .el-radio-group {
    align-items: stretch !important;
}

.vehicle-type-box .vehicle-type-box,
.vehicle-type-box .el-radio-button__inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropoff-address-container {
    display: flex;
    flex-direction: column;
}

.heading-with-note {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.heading-with-note h3 {
    margin-right: 10px;
}

.optional-note {
    font-size: 0.875em;
    color: #6c757d;
    margin: 0;
}

.field-wrap {
    margin-bottom: 15px;
}

.column-left .field-wrap,
.column-right .field-wrap,
.column-left .radio-wrap,
.column-right .order-form-wrap,
.column-left .order-form-wrap,
.column-left .helper-checkbox,
.column-right {
    margin-bottom: 18px;
}

.bottom-card-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
}

.customer-section {
    padding-top: 0px;
}

.price-summary-card {
    width: 60%;
}

.custom-total {
    width: 40%;
    /* Set width to 40% */
    padding-left: 0;
    /* Remove left padding */
    padding-right: 0;
    /* Remove right padding */
    border-radius: 0 9999px 9999px 0;
    /* Rounded on right side */
    margin-left: right;
    /* Stick to the right */
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .price-claculator {
        padding: 10px;
    }

    .column-left,
    .column-right {
        padding: 5px;
    }

    .bottom-card-container {
        padding: 5px;
    }

    .customer-section {
        padding-top: 50px;
    }
}

@media (max-width: 768px) {
    .price-claculator {
        padding: 5px;
    }

    .column-left,
    .column-right {
        flex: 1 1 100%;
        padding: 5px;
    }

    .bottom-card-container {
        padding: 5px;
    }

    .customer-section {
        padding-top: 30px;
    }

    .top-container {
        flex-direction: column;
    }

    .heading-with-note {
        flex-direction: column;
        align-items: flex-start;
    }

    .price-summary-card {
        width: 100%;
    }

    .order-form-wrap,
    .radio-wrap .el-radio-group {
        flex-direction: column;
    }

    .order-form-wrap .el-radio,
    .order-form-wrap .el-radio-button {
        width: 100%;
        margin-bottom: 5px;
    }
}

@media (max-width: 480px) {
    .price-claculator {
        padding: 5px;
    }

    .column-left,
    .column-right {
        flex: 1 1 100%;
        padding: 5px;
    }

    .bottom-card-container {
        padding: 5px;
    }

    .customer-section {
        padding-top: 20px;
    }

    .heading-with-note {
        flex-direction: column;
        align-items: flex-start;
    }

    .order-form-wrap,
    .radio-wrap .el-radio-group {
        flex-direction: column !important;
        gap: 2px;
    }

    .order-form-wrap .el-radio,
    .order-form-wrap .el-radio-button {
        width: 100%;
        margin-bottom: 5px;
    }

    .buttons {
        gap: 4px;
        display: flex;
        flex-direction: column;
    }

    .el-radio__label {
        font-size: 11px !important;
    }

    .customer-section .field-wrap {
        margin-bottom: 5px;
    }
}
</style>
