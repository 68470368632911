<template>
    <div>
        <div
            class="alert alert-purple alert-dismissible fade show p-2"
            role="alert"
            v-if="isShowAddressAlert"
        >
            <div class="d-flex alert-box">
                <h3 color="text-white">
                    <span>{{ $t("addOneAddress") }}</span>
                </h3>
                <button class="btn text-white btn-sm rounded-lg alert-btn mx-2">
                    <router-link class="nav-link" :to="{ name: 'Addresses' }">
                        <span>{{ $t("add_address") }}</span>
                    </router-link>
                </button>
            </div>

            <button
                type="button"
                class="close alert-nav-close-btn"
                data-dismiss="alert"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div
            class="alert alert-purple alert-dismissible fade show p-2"
            role="alert"
            v-if="isShowCreditAlert"
        >
            <div class="d-flex alert-box">
                <h3 color="text-white">
                    <span>{{ creditAlertMessage }}</span>
                </h3>

                <button class="btn text-white btn-sm rounded-lg alert-btn mx-2">
                    <router-link class="nav-link" to="/credit/add">
                        <span>{{ $t("make_payment") }}</span>
                    </router-link>
                </button>
            </div>

            <button
                type="button"
                class="close alert-nav-close-btn"
                data-dismiss="alert"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            isShowCreditAlert: false,
            isShowAddressAlert: false,
            creditAlertMessage: null,
        };
    },
    mounted() {
        this.$emitter.on("show-address-alert", this.showAddressAlert);
        this.$emitter.on("hide-address-alert", this.hideAddressAlert);
        this.$emitter.on("show-credit-alert", (data) => {
            this.isShowCreditAlert = true;
            this.creditAlertMessage = data.message;
        });
        this.$emitter.on("hide-credit-alert", this.hideCreditAlert);
    },
    unMounted() {
        this.$emitter.off("hide-address-alert", this.hideAddressAlert);
        this.$emitter.on("hide-credit-alert", this.hideCreditAlert);
    },
    computed: {
        ...mapGetters(["storeCredit"]),
    },
    methods: {
        showAddressAlert() {
            this.isShowAddressAlert = true;
        },
        hideAddressAlert() {
            this.isShowAddressAlert = false;
        },

        showCreditAlert(type) {
            console.log(type);
            this.isShowCreditAlert = true;
        },
        hideCreditAlert() {
            this.isShowCreditAlert = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.alert-btn {
    border: 1px solid #fff;
    background: #fff;
    color: black !important;
    margin-left: 10px;
    a {
        padding: 0;
        font-size: 14px;
        font-weight: 900;
        color: #6a5eff;
    }
}
.alert-box {
    align-items: center;
    h3 {
        color: #fff;
    }
}
.rtl {
    .alert-nav-close-btn {
        right: 97%;
    }
}
.alert-purple {
    color: #fff;
    border-color: #7368fb;
    background-color: #7368fb;
}
</style>
