<template>
    <div>
        <hr />
        <div class="card mb-0 px-5 rounded-md">
            <div
                class="border-0 card-header px-0 d-flex justify-content-between align-items-center"
            >
                <!-- Add Vehicle Type -->
                <router-link
                    :to="{
                        name: 'Banner Upsert',
                        params: { id: 'add' },
                    }"
                >
                    <button
                        class="btn btn-default text-white btn-sm font-display"
                    >
                        <i class="fas fa-plus-circle fa-lg text-white mr-2">
                            <span>{{ $t("add_banner") }}</span>
                        </i>
                    </button>
                </router-link>
            </div>

            <div class="row">
                <main class="col-md-12 col-xs-11 p-l-2 p-t-2">
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="banner"
                            v-loading="isLoading"
                            :empty-text="$t('no_data_found')"
                            @row-click="rowClicked"
                        >
                            <!-- Vehicle Type -->
                            <el-table-column
                                :label="$t('title')"
                                min-width="300px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.title }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('title_ar')"
                                min-width="300px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-nowrap vehicle-ar-text"
                                        >{{ row.title_ar }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('banner_image')"
                                min-width="500px"
                            >
                                <template v-slot="{ row }">
                                    <img
                                        :src="row.banner_image"
                                        alt=""
                                        srcset=""
                                        style="width: 50%"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('actions')"
                                min-width="210px"
                            >
                                <template v-slot="{ row }">
                                    <div class="d-flex">
                                        <el-tooltip
                                            :content="$t('edit')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark text-secondary cursor-pointer"
                                                :title="$t('edit')"
                                                style="cursor: pointer"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="'/banner/' + row.id"
                                                >
                                                    <img
                                                        src="@/assets/svg/edit-2.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>
                                        <alert-modal
                                            :id="row.id"
                                            :title="$t('banner_delete')"
                                            alertCategory="banner_delete"
                                            :body="
                                                $t('banner_delete_confirmation')
                                            "
                                            @alertModalCompleted="getBanners"
                                        ></alert-modal>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="banner.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
        <!--  End of Table -->
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getBanners } from "@/api/banner";
import AlertModal from "@/components/common/AlertModal.vue";

export default {
    data() {
        return {
            banner: [],
            currentPage: 1,
            isLoading: false,
            meta: {},
            totalData: null,
        };
    },
    mixins: [mixin],
    created() {
        this.getBanners();
    },
    components: {
        AlertModal,
    },
    watch: {
        currentPage: {
            handler: function () {
                this.getBanners();
            },
        },
    },
    methods: {
        getBanners() {
            this.isLoading = true;
            getBanners({
                page: this.currentPage,
            })
                .then((res) => {
                    this.banner = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        rowClicked(row, column, event) {
            if(column.label != this.$t("actions")){
                this.$router.push({
                    name: "Banner Upsert",
                    params: { id: row.id },
                });
            }
            return;
        },
    },
};
</script>
