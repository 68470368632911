import axiosInstance from "@/plugins/customAxios";
import store from "@/store/index";

export const getOrders = (params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 1 ||
        store.getters.user?.role_id === 9 ||
        store.getters.user?.role_id === 7 ||
        store.getters.user?.role_id === 8 ||
        store.getters.user?.role_id === 6
    ) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }

    return axiosInstance
        .get(`v1/${baseUrl}/orders`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getOrder = (id, params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 1 ||
        store.getters.user?.role_id === 9 ||
        store.getters.user?.role_id === 7 ||
        store.getters.user?.role_id === 8 ||
        store.getters.user?.role_id === 6
    ) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }

    return axiosInstance
        .get(`v1/${baseUrl}/orders/${id}`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const changeStatus = (id, data, params = null) => {
    let baseUrl;

    if (
        store.getters.user?.role_id === 1 ||
        store.getters.user?.role_id === 9 ||
        store.getters.user?.role_id === 7 ||
        store.getters.user?.role_id === 8 ||
        store.getters.user?.role_id === 6
    ) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }

    return axiosInstance
        .post(`v1/${baseUrl}/orders/${id}/status`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const downloadInvoice = (id, params = null) => {
    return axiosInstance
        .get(`v1/orders/${id}/invoice`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const createOrder = (data, params = null) => {
    return axiosInstance
        .post(`v1/store/orders`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getOrdersDetails = (data, params = null) => {
    return axiosInstance
        .get(`v1/store/orders`, data, { params: params })
        .then((res) => {
            return res.data;
        })
};

export const getProofOfDelivery = (id, hash, params = null) => {
    return axiosInstance
        .get(`v1/orders/${id}/${hash}/proof-of-delivery`, { params: params })
        .then((res) => {
            return res.data;
        })
};
export const getProofOfDeliveryDetail = (id, hash, params = null) => {
    return axiosInstance
        .get(`v1/orders/${id}/${hash}/proof-of-delivery-detail`, { params: params })
        .then((res) => {
            return res.data;
        })
};

export const getVehicles = () => {
    return axiosInstance.get("v1/vehicle-types").then(res => res.data);
}

export const assignProviderToOrder = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/admin/orders/${id}/assign-provider`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};
export const getOffers = (params) => {
    return axiosInstance
        .get("v1/admin/offers", { params: params })
        .then((res) => res.data);
};

export const makePublicOrder = (id, params) => {
    return axiosInstance
        .post(`v1/admin/orders/${id}/make-public`, { params: params })
        .then((res) => {
            return res.data
        });
};

export const allowOnHoldOrderProcess = (params) => {
    return axiosInstance
        .get(`v1/store/order/allow-on-hold-order-process`, { params: params })
        .then((res) => {
            return res.data
        });
};

export const updateOnHoldOrderStatus = (data) => {
    return axiosInstance
        .post(`v1/store/order/update-onhold-status`, data)
        .then((res) => {
            return res.data
        });
};

export const getOrderCount = (params) => {
    return axiosInstance
        .get(`v1/store/order/count`, { params: params })
        .then((res) => {
            return res.data
        });
};

export const getOrderRejects = (params) => {
    return axiosInstance
        .get(`v2/admin/orders/rejects`, { params: params })
        .then((res) => {
            return res.data
        });
};

export const getRejectOrderDetails = (id, params) => {
    return axiosInstance
        .get(`v2/admin/orders/${id}/rejects`, { params: params })
        .then((res) => {
            return res.data
        });
};

export const resendVerificationCode = (id) => {
    return axiosInstance
        .post(`v1/admin/orders/${id}/verification-code-resend`)
        .then((res) => {
            return res.data
        });
};

export const offerSuggestedPrice = (params) => {
    return axiosInstance
        .get(`v2/offer-suggested-price`, { params: params })
        .then((res) => {
            return res.data
        });
}

export const inTownPrice = (params) => {
    return axiosInstance
        .get(`v1/offer-suggested-price/in-town`, { params: params })
        .then((res) => {
            return res.data
        });
}

export const changePaymentMethod = (id, params) => {
    return axiosInstance
        .put(`v1/admin/orders/${id}/payment-method`, params)
        .then((res) => {
            return res.data
        });
}

export const calculateInTownOrderPrice = (data, params = null) => {
    return axiosInstance
        .post(`v1/store/orders/in-town-price`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getInvoiceHistory = (params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 1 || store.getters.user?.role_id === 9
    ) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }
    return axiosInstance
        .get(`v1/${baseUrl}/invoice-history`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getInvoice = (id, params = null) => {
    return axiosInstance
        .get(`v1/admin/orders/${id}/invoice`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const downloadInvoiceExcel = (params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 1 || store.getters.user?.role_id === 9
    ) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }

    return axiosInstance
        .get(`v1/${baseUrl}/invoice-history/excel-download`, { params: params })
        .then((res) => {
            return res.data;
        });
};