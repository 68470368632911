<template>
    <div v-if="order">
        <div class="bg-white rounded-md p-3 position-relative">
            <div class="btn-cancel">
                <!-- <base-button
                    v-if="
                        order.status != 'Cancelled' &&
                        order.status != 'Completed'
                    "
                    native-type="submit"
                    class="text-danger bg-transparent border-danger"
                    :loading="cancelOrderSpinner"
                    @click="cancelOrder"
                >
                    {{ $t("cancel_order") }}
                </base-button> -->
                <alert-modal
                    @click="cancelOrder(order)"
                    alertCategory="view_cancel_order"
                    v-if="cancelOrderAllowed(order.status)"
                    :id="order.id"
                    :body="orderCancelMessage"
                    :order="order"
                    @alertModalCompleted="cancelOrderCallback"
                >
                    <template v-slot:orderNo>
                        <h3>
                            {{ $t("order_no") }} :
                            {{ order.order_no }}
                        </h3>
                    </template>
                </alert-modal>
            </div>
            <tabs
                fill
                class="flex-column flex-md-row px-3"
                tabNavClasses="mb-3"
            >
                <card class="px-0 -mx-2">
                    <!-- Order Details section -->
                    <tab-pane :title="$t('package_details')" class="bg-white">
                        <div class="d-flex">
                            <ul
                                class="list-group col-md-6 order-detail detail-table ml-2"
                            >
                                <li class="list-group-item detail-table__title">
                                    <h2 class="mb-0">{{ $t("order_no") }}</h2>
                                    <h2 class="mb-0 ml-2">
                                        {{ order.order_no }}
                                    </h2>
                                </li>
                                <li class="list-group-item">
                                    <b class="font-weight-500"
                                        >{{ $t("status") }}
                                    </b>
                                    <div>
                                        <custom-label :label="order.status" />
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <b>{{ $t("vehicle") }} </b>
                                    <span>{{ order.vehicle?.name }}</span>
                                </li>
                                <!-- ID proof Image -->
                                <li class="list-group-item text-wrap">
                                    <b>{{ $t("vehicle_type") }} </b>
                                    <span>{{ order.vehicle_type?.type }}</span>
                                </li>
                                <!-- Registration Licence Image -->
                                <li class="list-group-item text-wrap">
                                    <b>{{ $t("shipment_type") }} </b>
                                    <span>{{ order.shipment_type?.type }}</span>
                                </li>
                                <li class="list-group-item text-wrap">
                                    <b>{{ $t("invoice_ref_no") }} </b>
                                    <span>{{ order.order_date }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b
                                        >{{
                                            $t("package_order_messages.helper")
                                        }}
                                    </b>
                                    <span>{{
                                        order.helper === true ? "Yes" : "No"
                                    }}</span>
                                </li>
                            </ul>
                            <ul class="list-group col-md-6 detail-table">
                                <li class="list-group-item">
                                    <div class="d-flex col-md-12 p-0">
                                        <div class="col-md-6 p-0">
                                            <h2 class="mb-0">
                                                {{ $t("invoice_detail") }}
                                            </h2>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <b class="font-weight-500"
                                        >{{ $t("invoice_number") }}
                                    </b>
                                    <span>{{
                                        order.invoice?.invoice_no || ""
                                    }}</span>
                                </li>
                                <li class="list-group-item">
                                    <b>{{ $t("total") }} </b>
                                    <span
                                        >{{ order.invoice?.total || "0" }}
                                        {{ " " + $t("SAR") }}</span
                                    >
                                </li>
                                <li class="list-group-item">
                                    <b>{{ $t("extra_service_price") }} </b>
                                    <span
                                        >{{
                                            order.invoice
                                                ?.extra_service_price || "0"
                                        }}
                                        {{ " " + $t("SAR") }}</span
                                    >
                                </li>
                                <li class="list-group-item">
                                    <b>{{ $t("discount") }} </b>
                                    <span
                                        >{{ order.invoice?.discount || "0" }}
                                        {{ " " + $t("SAR") }}</span
                                    >
                                </li>
                                <li class="list-group-item">
                                    <b>{{ $t("total") }} </b>
                                    <span>
                                        {{ order.invoice?.total }}
                                        {{
                                            order.invoice?.total != null
                                                ? $t("SAR")
                                                : ""
                                        }}</span
                                    >
                                </li>
                            </ul>
                        </div>
                        <div
                            class="d-flex px-4 mt-4 map-lebel"
                            v-if="mapData && mapData.length > 0"
                        >
                            <div class="d-flex pl-4">
                                <img
                                    width="24"
                                    height="24"
                                    :src="
                                        require('@/assets/images/dropoff-icon.png')
                                    "
                                />
                                <h4 class="font-weight-normal">
                                    {{ $t("dropoff_location") }}
                                </h4>
                            </div>
                            <div class="d-flex pl-4">
                                <img
                                    width="24"
                                    height="24"
                                    :src="
                                        require('@/assets/images/pickup-icon.png')
                                    "
                                />
                                <h4 class="font-weight-normal">
                                    {{ $t("pickup_location") }}
                                </h4>
                            </div>
                        </div>
                        <card class="px-0">
                            <div class="-mt-2">
                                <map-for-order-location
                                    v-if="mapData && mapData.length > 0"
                                    :lat="latitude"
                                    :lon="longitude"
                                    :locations="mapData"
                                    :providerLocationEnabled="
                                        providerLiveLocationEnabled
                                    "
                                    :providers="providers"
                                    :settingMapRadiusRange="
                                        settingMapRadiusRange
                                    "
                                />
                            </div>
                        </card>
                    </tab-pane>

                    <!-- Payload  section -->
                    <tab-pane :title="$t('orders')" class="bg-white">
                        <div
                            class="payload"
                            v-for="(item, index) in order.packages"
                            :key="index"
                        >
                            <h2 class="payload__title">
                                {{ $t("orders") }} {{ index + 1 }}
                            </h2>

                            <ul class="payload__content">
                                <li @click="goToPayload(item.id)">
                                    <p class="payload__subtitle">
                                        {{ $t("name") }}
                                    </p>
                                    <p class="payload__text">
                                        {{ item.customer.name }}
                                    </p>
                                </li>
                                <li @click="goToPayload(item.id)">
                                    <p class="payload__subtitle">
                                        {{ $t("contact_mobile_no") }}
                                    </p>
                                    <p class="payload__text">
                                        {{ item.customer.mobile_no }}
                                    </p>
                                </li>
                                <li @click="goToPayload(item.id)">
                                    <p class="payload__subtitle">
                                        {{ $t("district") }}
                                    </p>
                                    <p
                                        v-if="lang == 'ar'"
                                        class="payload__text"
                                    >
                                        {{ item.customer.district?.name_ar }}
                                    </p>
                                    <p
                                        v-if="lang == 'en'"
                                        class="payload__text"
                                    >
                                        {{ item.customer.district?.name }}
                                    </p>
                                </li>
                                <li @click="goToPayload(item.id)">
                                    <p class="payload__subtitle">
                                        {{
                                            $t(
                                                "proof_of_delivery.delivery_time"
                                            )
                                        }}
                                    </p>
                                    <p class="payload__text">
                                        {{ $t(item.display_dropoff_time) }}
                                    </p>
                                </li>
                                <li @click="goToPayload(item.id)">
                                    <p class="payload__subtitle">
                                        {{ $t("delivery_flag.title") }}
                                    </p>
                                    <p
                                        class="payload__text"
                                        v-if="item.delivery_flag"
                                    >
                                        {{
                                            $t(
                                                "delivery_flag." +
                                                    item.delivery_flag
                                            )
                                        }}
                                    </p>
                                </li>
                                <!-- 29th dec --->
                                <!-- Button will be added here -->
                                <li>
                                    <!---here goes vif condition -->
                                    <base-button
                                        v-if="
                                            item.status == 'Completed' ||
                                            item.status == 'Dropped Off'
                                        "
                                        native-type="button"
                                        class="btn btn-sm btn--new-order bg-primary text-white rounded-lg deliver__button"
                                        @click="openProofOfDeliveryModal(item)"
                                    >
                                        {{ $t("proof_of_delivery.title") }}
                                    </base-button>
                                </li>
                                <li>
                                    <base-button
                                        v-if="item.airway_bill_url"
                                        native-type="button"
                                        class="btn btn-sm btn--new-order bg-primary text-white rounded-lg deliver__button"
                                    >
                                        <a
                                            :href="item.airway_bill_url"
                                            class="text-white"
                                            target="_blank"
                                        >
                                            {{ $t("downloadAirwayBill") }}
                                        </a>
                                    </base-button>
                                </li>
                            </ul>
                        </div>
                    </tab-pane>

                    <!-- Order Logs section -->
                    <tab-pane :title="$t('package_history')" class="bg-white">
                        <status-history-table
                            :status_histories="order.status_histories"
                        ></status-history-table>
                        <!-- <div
                            class="logsorder"
                            v-for="(item, index) in order.status_histories"
                            :key="index"
                        >
                            <ul class="logsorder__content">
                                <li>
                                    <p class="logsorder__date">
                                        {{ item.created_at }}
                                    </p>
                                </li>
                                <li>
                                    <custom-label
                                        :label="item.display_status"
                                    />
                                </li>
                            </ul>
                        </div> -->
                    </tab-pane>

                    <!-- Notes -->
                    <tab-pane :title="$t('notes')">
                        <note :id="order.id" type="order"></note>
                    </tab-pane>
                </card>
            </tabs>
        </div>
        <proof-of-delivery
            :showModal="proofOfDelivery"
            @close="proofOfDelivery = false"
            :order="order"
            :packageOrderId="packageOrder?.id"
        >
        </proof-of-delivery>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import Note from "@/components/common/Note";
import { getSettings } from "@/api/systemSetting";
import { getOrder, changeStatus } from "@/api/orders";
import AlertModal from "@/components/common/AlertModal";
import ProofOfDelivery from "@/components/ProofOfDelivery.vue";
import StatusHistoryTable from "@/components/common/StatusHistoryTable.vue";
import MapForOrderLocation from "@//components/common/MapForOrderLocation.vue";

export default {
    data() {
        return {
            cancelOrderSpinner: false,
            proofOfDelivery: false,
            lang: localStorage.getItem("lang"),
            orderCancelMessage: null,
            packageOrder: null,
        };
    },
    props: ["order", "mapData", "providers", "settingMapRadiusRange"],
    watch: {
        ordersCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getOrder();
            },
        },

        ratingsCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getRatings();
            },
        },
    },
    computed: {
        packages() {
            return this.order?.packages;
        },
    },
    components: {
        Note,
        MapForOrderLocation,
        AlertModal,
        ProofOfDelivery,
        StatusHistoryTable,
    },
    async mounted() {
        // await this.getOrder();
    },
    mixins: [mixin],
    methods: {
        goToPayload(orderId) {
            this.$emit("goToPayload", orderId);
            // this.$router.push({ name : 'Order Details',  params: { id: orderId } } )
            // window.location.reload();
        },
        openProofOfDeliveryModal(packageOrder) {
            this.packageOrder = packageOrder;
            this.proofOfDelivery = true;
        },
        providerLiveLocationEnabled() {
            return (
                this.order.status == "Accepted" ||
                this.order.status == "On The Way" ||
                this.order.status == "Picked Up" ||
                this.order.status == "Dropped Off"
            );
        },
        async cancelOrder() {
            this.cancelOrderSpinner = true;

            /*
             *  This process will be latorly updated.
             *
             */

            changeStatus(this.order.id, {
                store_id: this.storeId,
                status: "cancelled",
                service_provider_company_id: this.$store.getters.user
                    ?.service_provider_company?.id
                    ? this.$store.getters.user?.service_provider_company?.id
                    : null,
            })
                .then((res) => {
                    this.cancelOrderSpinner = false;
                    this.runToast(this.$t("success.order_cancel"), "success");
                    this.$emit("getOrder");
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                    this.cancelOrderSpinner = false;
                });
        },

        async getOrder() {
            await getOrder(this.id, {
                include:
                    "vehicle_type,shipment_type,vehicle,provider,pickup_address,dropoff_address,customer,invoice,status_histories,payments,complaints,complaints.complaintBy",

                store_id: this.storeId ? this.storeId : null,
            })
                .then(async (res) => {
                    this.order = res;
                    this.customer = res.customer;
                    this.statusHistories = res.status_histories;
                    let pickup_address = {};
                    pickup_address.type = "pickup_address";
                    pickup_address.latitude = this.order?.pickup_address?.latitude;
                    pickup_address.longitude = this.order?.pickup_address?.longitude;
                    pickup_address.address = this.order?.pickup_address?.address_line1;
                    let dropoff_address = {};
                    dropoff_address.type = "dropoff_address";
                    dropoff_address.latitude = this.order?.dropoff_address?.latitude;
                    dropoff_address.longitude = this.order?.dropoff_address?.longitude;
                    dropoff_address.address = this.order?.dropoff_address?.address_line1;
                    await this.mapData.push(pickup_address);
                    await this.mapData.push(dropoff_address);
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        async cancelOrder(order) {
            let activeOrderStatuses = [
                "On The Way",
                "Picked Up",
                "Dropped Off",
            ];

            if (
                order.store_id != null &&
                activeOrderStatuses.includes(order.status)
            ) {
                let setting = await this.getSystemSettings(
                    "business_order_cancel_price"
                );
                this.orderCancelMessage = this.$t(
                    "cancel_order_amount_confirmation",
                    {
                        amount: setting?.value,
                    }
                );
            } else {
                this.orderCancelMessage = this.$t("order_cancel_confirm");
            }
        },
        getSystemSettings(key = null) {
            return new Promise((resolve) => {
                getSettings({
                    limit: -1,
                })
                    .then((res) => {
                        if (key) {
                            res.data.forEach(function (item) {
                                if (item.key == key) {
                                    resolve(item);
                                }
                            });
                            resolve(null);
                        }
                    })
                    .catch(({ response }) => {
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            });
        },
        cancelOrderCallback() {
            this.$emit("getOrder");
        },
    },
};
</script>
<style scoped lang="scss">
.user-profile {
    border: 2px;
    border-radius: 5px;
    // height: 55px;
    margin-bottom: 10px;
    background-color: #f6f9fc;
}
.user-profile-item {
    margin-top: 2px;
}
.avatar-sm {
    width: 50px !important;
    height: 50px !important;
}
</style>
