<template>
    <div class="d-inline">
        <slot :clickEvent="userEditModal">
            <el-tooltip :content="$t('edit')" placement="top">
                <i
                    class="fas fa-pen fa-md mx-3 text-default"
                    style="cursor: pointer"
                    @click="userEditModal"
                ></i>
            </el-tooltip>
        </slot>

        <!-- Modal props show is for toggling Modal Display 
        @close child event is for closing modal -->
        <!-- Modal  -->
        <modal
            class="text-left"
            :show="showModal"
            @close="showModal = false"
            size="sm"
            v-loading="isLoading"
        >
            <template v-slot:header>
                <div class="text-bold text-lg text-center mt-2 mb-1">
                    {{ title }}
                </div>
            </template>
            <div class="pt-0">
                <Form @submit="userEdit" v-loading="isLoading">
                    <!-- below customer-edit-form Component contains the Form elements of edit form 
                    if role = customer then the component will be used in modal -->
                    <customer-edit-form
                        @close="showModal = false"
                        :customer="user"
                        v-if="showModal && role === 'customer'"
                    ></customer-edit-form>

                    <provider-edit-form
                        @close="showModal = false"
                        :provider="user"
                        :cityOptions="cityOptions"
                        v-if="showModal && role === 'provider'"
                    ></provider-edit-form>

                    <div class="w-full text-center edit-provider-btn-wrap mt-3">
                        <base-button
                            :loading="submitSpinner"
                            native-type="submit"
                            class="text-white px-4 bg-primary"
                        >
                            {{ $t("submit") }}
                        </base-button>
                        <input
                            type="button"
                            class="btn btn-default text-white"
                            @click="showModal = false"
                            :value="$t('close')"
                        />
                    </div>
                </Form>
            </div>
        </modal>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { editUser } from "@/api/users";
import { get as getCityList } from "@/api/cities";
import ProviderEditForm from "./ProviderEditForm";
import CustomerEditForm from "./CustomerEditForm.vue";

export default {
    data() {
        return {
            cityOptions: [],
            isLoading: false,
            showModal: false,
            submitSpinner: false,
        };
    },
    props: ["user", "role", "title"],
    mixins: [mixin],
    components: {
        CustomerEditForm,
        ProviderEditForm,
    },
    methods: {
        userEditModal() {
            this.showModal = true;
            if (this.role == "provider") this.getCityOptions();
        },
        userEdit(data) {
            this.submitSpinner = true;
            editUser(
                this.userRole === 1 ||
                    this.userRole === 9 ||
                    this.userRole === 7 ||
                    this.userRole === 8 ||
                    this.userRole === 6
                    ? "admin"
                    : "store",
                this.user.id,
                data,
                { _method: "PATCH", role: this.role }
            )
                .then(() => {
                    this.submitSpinner = false;
                    this.showModal = false;
                    this.runToast(
                        this.role === "customer"
                            ? this.$t("customer_update")
                            : this.$t("success.provider_update"),
                        "success"
                    );
                    this.$emit("userEditCompleted");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getCityOptions() {
            getCityList({
                limit: -1,
            })
                .then((res) => {
                    res.data.forEach((item) => {
                        this.cityOptions.push({
                            value: item["id"],
                            label:
                                this.$store.getters.getLang == "ar"
                                    ? item["name_ar"]
                                    : item["name"],
                        });
                    });
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
    },
};
</script>

<style lang="scss">
.edit-provider-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit-provider-btn-wrap button,
.edit-provider-btn-wrap input {
    width: 140px;
}
</style>
