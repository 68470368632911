<template>
    <div>
        <hr />
        <div class="">
            <div class="card mb-0 p-5 rounded-md">
                <!-- Filter Section Start -->
                <div class="d-flex">
                    <div class="d-flex" style="gap: 20px">
                        <div>
                            <Field
                                type="text"
                                :placeholder="$t('name')"
                                class="form-control"
                                name="name"
                                v-model="filters.name"
                            />
                        </div>
                        <div>
                            <Field
                                :placeholder="$t('email')"
                                type="text"
                                class="form-control"
                                name="email"
                                v-model="filters.email"
                            />
                        </div>
                        <div>
                            <Field
                                :placeholder="$t('mobile_no')"
                                type="number"
                                class="form-control"
                                name="mobile_no"
                                v-model="filters.mobile_no"
                            />
                        </div>
                    </div>
                </div>
                <!-- Filter Section End  -->
                <div class="mt-4 row">
                    <main
                        :class="
                            isFilter
                                ? 'col-md-9 col-xs-11  p-t-2'
                                : 'col-md-12 col-xs-11  p-t-2'
                        "
                    >
                        <div>
                            <el-table
                                class="table-responsive table-flush"
                                header-row-class-name="thead-light"
                                :data="userReferralCredits"
                                cell-class-name="text-break"
                                v-loading="isLoading"
                                :empty-text="$t('no_data_found')"
                            >
                                <el-table-column
                                    :label="$t('name')"
                                    min-width="150px"
                                >
                                    <template v-slot="{ row }">
                                        <badge
                                            size="lg"
                                            class="bg-sky-dark cell p-2"
                                            style="cursor: pointer"
                                            @click="viewUserDetail(row)"
                                        >
                                            <span
                                                class="mb-0 font-weight-600 name text-capitalize"
                                                >{{ row.name }}</span
                                            >
                                        </badge>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    :label="$t('email')"
                                    min-width="200px"
                                    prop="email"
                                >
                                    <template v-slot="{ row }">
                                        <span
                                            class="mb-0 font-weight-600 name text-nowrap"
                                            >{{ row.email }}</span
                                        >
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('mobile_no')"
                                    min-width="160px"
                                >
                                    <template v-slot="{ row }">
                                        <span class="mb-0 font-weight-600 name">
                                            +{{ row.country_code + " " }}
                                            {{ row.mobile_no }}</span
                                        >
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('referral_credit')"
                                    min-width="180px"
                                    prop="referral_credit"
                                >
                                    <template v-slot="{ row }">
                                        <span class="mb-0 font-weight-600 name">
                                            {{ row.referral_credit }}
                                        </span>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('actions')"
                                    min-width="210px"
                                >
                                    <template v-slot="{ row }">
                                        <el-tooltip
                                            :content="$t('wallet_history')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark text-secondary cursor-pointer"
                                                style="cursor: pointer"
                                                @click="
                                                    viewWalletHistory(row.id)
                                                "
                                            >
                                                <i
                                                    class="fas fa-wallet mx-1"
                                                ></i>
                                            </badge>
                                        </el-tooltip>
                                        <!--Credit Transfer Alert Modal with Transaction Function -->
                                        <ReferralCreditTransferModal
                                            :id="row.id"
                                            :currentRow="row"
                                            :title="$t('transfer_credit')"
                                            @creditTransferModalCompleted="
                                                getReferralCredits
                                            "
                                        >
                                        </ReferralCreditTransferModal>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div
                            class="py-4 card-footer"
                            v-if="userReferralCredits?.length > 0"
                        >
                            <base-pagination
                                :meta="meta"
                                v-model="currentPage"
                                :total="totalData"
                            ></base-pagination>
                        </div>
                    </main>
                </div>
            </div>
            <!--  End of Table -->
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getUsers } from "@/api/users";
import { getSettingKeyValue } from "@/api/systemSetting";
import ReferralCreditTransferModal from "@/components/common/ReferralCreditTransferModal.vue";

export default {
    data() {
        return {
            userReferralCredits: [],
            isLoading: false,
            user: null,
            currentPage: 1,
            params: {
                role: ["provider", "customer"],
            },
            totalData: null,
            currentRow: {},
            isFilter: false,
            role: "",
            filters: {
                name: "",
                email: "",
                mobile_no: null,
            },
            meta: null,
            userMinimumCredit: null,
        };
    },
    mixins: [mixin],
    components: {
        ReferralCreditTransferModal,
    },
    computed: {},
    watch: {
        currentPage: {
            handler: function () {
                this.getReferralCredits();
            },
        },

        filters: {
            deep: true,
            handler() {
                this.currentPage = 1;
                this.getReferralCredits();
            },
        },
    },

    created() {
        this.getReferralCredits();
    },

    methods: {
        async getReferralCredits() {
            this.isLoading = true;
            this.userMinimumCredit = 1;
            // if (this.userMinimumCredit == null) {
            //     await this.getSetting();
            // }
            await getUsers("admin", {
                ...this.params,
                page: this.currentPage,
                filter: {
                    ...this.filters,
                    referral_credit_available: this.userMinimumCredit,
                },
            })
                .then((res) => {
                    this.userReferralCredits = res.data;

                    this.meta = res.meta;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        viewUserDetail(user) {
            let id = user.id;
            if (user.role == "provider")
                this.$router.push({ name: "Provider Details", params: { id } });
            else
                this.$router.push({ name: "Customer Details", params: { id } });
        },
        viewWalletHistory(userId) {
            let routeData = this.$router.resolve({
                name: "Wallet History List",
                params: { userId },
            });
            window.open(routeData.href, "_blank");
        },
        async getSetting() {
            await getSettingKeyValue({
                filter: {
                    key: "referral_stcpay_transfer_minimum_credit",
                },
            }).then((res) => {
                this.userMinimumCredit =
                    res?.referral_stcpay_transfer_minimum_credit;
            });
        },
    },
};
</script>
<style></style>
