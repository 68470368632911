<template>
    <div class="d-flex justify-content-between tbl-pagination">
        <div>
            <ul
                class="pagination"
                :class="
                    ([
                        size && `pagination-${size}`,
                        align && `justify-content-${align}`,
                    ],
                    paginationClass)
                "
            >
                <li
                    class="page-item prev-page"
                    :class="{ disabled: modelValue === 1 }"
                >
                    <a
                        class="page-link"
                        aria-label="Previous"
                        @click="prevPage"
                    >
                        <i class="fa fa-angle-left"></i>
                    </a>
                </li>
                <li
                    class="page-item"
                    v-for="item in range(minPage, maxPage)"
                    :key="item"
                    :class="{ active: modelValue === item }"
                >
                    <a class="page-link" @click="changePage(item)">{{
                        item
                    }}</a>
                </li>
                <li
                    class="page-item page-pre next-page"
                    :class="{ disabled: modelValue === totalPages }"
                >
                    <a class="page-link" aria-label="Next" @click="nextPage">
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>

        <div>
            <h3 class="mt-1 ml-2 total-line">
                {{
                    $t("count", {
                        from: from,
                        to: to,
                        total: total,
                        tableName: tableName,
                    })
                }}
            </h3>
        </div>
    </div>
</template>
<script>
export default {
    name: "n-pagination",
    emits: ["update:modelValue"],
    data() {
        return {
            defaultPagesToDisplay: 5,
            tableName: "Table",
            from: 0,
            to: 0,
        };
    },

    created() {
        this.from = this.meta?.from;
        this.to = this.meta?.to;
        this.getTableName();
    },

    props: {
        type: {
            type: String,
            default: "primary",
            validator: (value) => {
                return [
                    "default",
                    "primary",
                    "danger",
                    "success",
                    "warning",
                    "info",
                ].includes(value);
            },
        },
        pageCount: {
            type: Number,
            default: 0,
        },
        perPage: {
            type: Number,
            default: 10,
        },
        total: {
            type: Number,
            default: 0,
        },
        meta: {
            type: Object,
            required: true,
        },
        modelValue: {
            type: Number,
            default: 1,
        },
        size: {
            type: String,
            default: "",
            description: "Pagination size",
        },
        align: {
            type: String,
            default: "",
            description: "Pagination alignment (e.g center|start|end)",
        },
    },
    computed: {
        paginationClass() {
            return `pagination-${this.type}`;
        },
        totalPages() {
            if (this.pageCount > 0) return this.pageCount;
            if (this.total > 0) {
                return Math.ceil(this.total / this.perPage);
            }
            return 1;
        },
        pagesToDisplay() {
            if (
                this.totalPages > 0 &&
                this.totalPages < this.defaultPagesToDisplay
            ) {
                return this.totalPages;
            }
            return this.defaultPagesToDisplay;
        },
        minPage() {
            if (this.modelValue >= this.pagesToDisplay) {
                const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
                const newMaxPage = pagesToAdd + this.modelValue;
                if (newMaxPage > this.totalPages) {
                    return this.totalPages - this.pagesToDisplay + 1;
                }
                return this.modelValue - pagesToAdd;
            } else {
                return 1;
            }
        },
        maxPage() {
            if (this.modelValue >= this.pagesToDisplay) {
                const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
                const newMaxPage = pagesToAdd + this.modelValue;
                if (newMaxPage < this.totalPages) {
                    return newMaxPage;
                } else {
                    return this.totalPages;
                }
            } else {
                return this.pagesToDisplay;
            }
        },
    },

    methods: {
        getTableName() {
            if (this.$route.name === "Dashboard") {
                this.tableName = this.$t("orders");
            } else {
                this.tableName = this.$t(
                    this.$route.meta.title ? this.$route.meta.title : "Table"
                );
            }
        },
        range(min, max) {
            let arr = [];
            for (let i = min; i <= max; i++) {
                arr.push(i);
            }
            return arr;
        },
        changePage(item) {
            this.$emit("update:modelValue", item);
        },
        nextPage() {
            if (this.modelValue < this.totalPages) {
                this.$emit("update:modelValue", this.modelValue + 1);
            }
        },
        prevPage() {
            if (this.modelValue > 1) {
                this.$emit("update:modelValue", this.modelValue - 1);
            }
        },
    },
    watch: {
        meta() {
            this.from = this.meta?.from;
            this.to = this.meta?.to;
        },
        perPage() {
            this.$emit("update:modelValue", 1);
        },
        total() {
            this.$emit("update:modelValue", 1);
        },
    },
};
</script>
