import List from "@/views/Pages/PendingTransaction/List";

export default [
    {
        path: "pending-transaction",
        components: { default: List },
        name: "Pending Transaction",
        meta: {
            title: "pending_transactions",
            role: [1, 6],
        },
    },
];
