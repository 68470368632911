<template>
    <div>
        <hr />
        <div class="p-5 mb-0 card rounded-md">
            <h3 class="mb-0">{{ $t(addOrEdit) }}</h3>

            <Form @submit="onSubmit" v-loading="pageSpinner" class="">
                <div class="mt-3 row">
                    <div class="col-md-6">
                        <label for="name">{{ $t("coupon_code") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            :label="$t('coupon_code')"
                            name="name"
                            v-model="form.name"
                            :disabled="isAutomaticCode"
                        />
                        <ErrorMessage name="name" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="">{{ $t("discount_percentage") }}</label>
                        <Field
                            type="text"
                            rules="required|numeric|min_value:1|max_value:99"
                            :label="$t('discount_percentage')"
                            class="form-control"
                            name="discount_percentage"
                            v-model="form.value"
                        />
                        <ErrorMessage
                            name="discount_percentage"
                            class="error"
                        />
                    </div>
                </div>

                <div class="mt-3 row" v-if="!isAutomaticCode">
                    <div class="col-md-6">
                        <label for="">{{ $t("start_date") }}</label>
                        <Field
                            v-model="form.start_date"
                            class="form-control"
                            name="start_date"
                            :label="$t('start_date')"
                            rules="required"
                        >
                            <flat-picker
                                :config="{
                                    altInput: true,
                                    altFormat: 'd/m/Y',
                                    enableTime: false,
                                    dateFormat: 'd/m/Y',
                                }"
                                v-model="form.start_date"
                                class="form-control datepicker"
                                name="start_date"
                                :placeholder="$t('select')"
                            >
                            </flat-picker>
                        </Field>
                        <ErrorMessage name="start_date" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="">{{ $t("end_date") }}</label>
                        <Field
                            v-model="form.end_date"
                            class="form-control"
                            name="end_date"
                            :label="$t('end_date')"
                            rules="required"
                        >
                            <flat-picker
                                :config="{
                                    altInput: true,
                                    altFormat: 'd/m/Y',
                                    enableTime: false,
                                    dateFormat: 'd/m/Y',
                                }"
                                v-model="form.end_date"
                                class="form-control datepicker"
                                name="end_date"
                                :placeholder="$t('select')"
                            >
                            </flat-picker>
                        </Field>
                        <ErrorMessage name="end_date" class="error" />
                    </div>
                </div>

                <div class="mt-3 row">
                    <div class="col-md-3" v-if="!isAutomaticCode">
                        <label class="form-control-label d-block mt-2">
                            {{ $t("selectVisibility") }}
                        </label>
                        <div class="d-flex">
                            <Field
                                rules="required"
                                v-model="form.visibility"
                                name="visibility"
                                :label="$t('selectVisibility')"
                                class="d-block"
                            >
                                <el-radio
                                    v-model="form.visibility"
                                    name="visibility"
                                    label="public"
                                    >{{ $t("public") }}</el-radio
                                >
                                <el-radio
                                    v-model="form.visibility"
                                    name="visibility"
                                    label="private"
                                >
                                    {{ $t("private") }}</el-radio
                                >
                            </Field>
                            <ErrorMessage name="visibility" class="error" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label for="">{{ $t("status") }}</label>
                        <el-select
                            :placeholder="$t('select')"
                            v-model="form.status"
                            class="d-block"
                        >
                            <el-option
                                v-for="option in defaultOptions.status"
                                class="select-danger"
                                :value="option.value"
                                :label="$t(option.label)"
                                :key="option.label"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-md-3" v-if="!isAutomaticCode">
                        <label class="form-control-label d-block mt-2">
                            {{ $t("is_unlimited_coupon_code") }}
                        </label>
                        <div class="d-flex">
                            <Field
                                rules="required"
                                v-model="form.is_unlimited"
                                name="is_unlimited"
                                :label="$t('is_unlimited_coupon_code')"
                                class="d-block"
                            >
                                <el-radio
                                    v-model="form.is_unlimited"
                                    name="is_unlimited"
                                    label="1"
                                    @change="isUnlimited(event)"
                                    >{{ $t("yes") }}</el-radio
                                >
                                <el-radio
                                    v-model="form.is_unlimited"
                                    name="is_unlimited"
                                    label="0"
                                    @change="isUnlimited(event)"
                                >
                                    {{ $t("no") }}</el-radio
                                >
                            </Field>
                            <ErrorMessage name="is_unlimited" class="error" />
                        </div>
                    </div>

                    <div
                        class="col-md-3"
                        v-if="!isUnlimitedUse && !isAutomaticCode"
                    >
                        <label for="">{{
                            $t("coupon_code_useable_count")
                        }}</label>
                        <Field
                            type="number"
                            rules="required|numeric|min_value:1|max_value:9999"
                            :label="$t('coupon_code_useable_count')"
                            class="form-control"
                            name="useable_count"
                            v-model="form.useable_count"
                        />
                        <ErrorMessage name="useable_count" class="error" />
                    </div>
                </div>
                <div class="w-full mt-5 text-center">
                    <base-button
                        native-type="submit"
                        class="text-white bg-primary-color"
                        :loading="buttonSpinner"
                    >
                        {{ $t("save") }}
                    </base-button>
                    <base-button
                        class="text-white bg-primary-color"
                        @click="
                            $router.push({
                                name: 'CouponCode List',
                            })
                        "
                    >
                        {{ $t("cancel") }}
                    </base-button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import {
    getCouponCode,
    addCouponCode,
    editCouponCode,
} from "@/api/couponCodes";
import mixin from "@/mixins/common";
import "flatpickr/dist/flatpickr.css";
import flatPicker from "vue-flatpickr-component";

export default {
    data() {
        return {
            addOrEdit: "",
            couponCode: {},
            form: {
                name: "",
                value: "",
                start_date: "",
                end_date: "",
                status: "active",
                useable_count: "",
                is_unlimited: false,
                visibility: "private",
            },
            buttonSpinner: false,
            pageSpinner: false,
            isUnlimitedUse: true,
            isAutomaticCode: false,
        };
    },
    props: ["id"],
    mixins: [mixin],
    components: {
        flatPicker,
    },
    computed: {},
    created() {
        if (this.id === "add") {
            this.addOrEdit = this.$t("add_coupon");
        } else {
            this.addOrEdit = this.$t("edit_coupon");
            this.getCouponCode();
        }
    },
    methods: {
        isUnlimited() {
            if (this.form.is_unlimited == 1) {
                this.isUnlimitedUse = true;
                this.form.useable_count = 10000; // Means unlimited coupon code
            } else {
                this.form.useable_count = "";
                this.isUnlimitedUse = false;
            }
        },
        getCouponCode(data) {
            this.pageSpinner = true;
            getCouponCode(this.id)
                .then((res) => {
                    this.pageSpinner = false;
                    this.couponCode = res;
                    this.form = res;
                    this.isAutomaticCode =
                        res.visibility == "automatic" ? true : false;
                    if (this.form.useable_count >= 10000) {
                        // Unlimited coupon code
                        this.form.is_unlimited = "1";
                        this.isUnlimitedUse = true;
                    } else {
                        this.form.is_unlimited = "0";
                        this.isUnlimitedUse = false;
                    }
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },

        async onSubmit() {
            this.buttonSpinner = true;
            if (this.id == "add") {
                let data = this.form;
                addCouponCode(data)
                    .then(() => {
                        this.buttonSpinner = false;
                        this.runToast(
                            this.$t("coupon_code_add_success"),
                            "success"
                        );
                        this.$router.push({
                            name: "CouponCode List",
                        });
                    })
                    .catch(({ response }) => {
                        this.buttonSpinner = false;
                        this.apiError(response);
                    });
            } else {
                let data = this.form;
                editCouponCode(this.id, data, {
                    _method: "PATCH",
                })
                    .then((res) => {
                        this.buttonSpinner = false;
                        this.runToast(
                            this.$t("coupon_code_update_success"),
                            "success"
                        );

                        this.$router.push({
                            name: "CouponCode List",
                        });
                    })
                    .catch(({ response }) => {
                        this.buttonSpinner = false;
                        this.apiError(response);
                    });
            }
        },
    },
};
</script>

<style></style>
