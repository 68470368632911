export default [
    {
        label: "all",
        value: null,
    },
    {
        label: "order_delivery",
        value: "order_delivery",
    },
    {
        label: "credit_transfer",
        value: "credit_transfer",
    },
    {
        label: "bonus",
        value: "bonus",
    },
    {
        label: "fine",
        value: "fine",
    },
    {
        label: "compensation",
        value: "compensation",
    },
    {
        label: "charge_credit",
        value: "charge_credit"
    },
    {
        label: "dues_payment",
        value: "dues_payment"
    },
    {
        label: "referral",
        value: "referral"
    },
    {
        label: "referral_credit_transfer",
        value: "referral_credit_transfer"
    },
    {
        label: "individual_order_cancel",
        value: "individual_order_cancel"
    },
    {
        label: "store_order_cancel",
        value: "store_order_cancel"
    },
    {
        label: "place_order",
        value: "place_order"
    }
];