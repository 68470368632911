<template>
    <div>
        <div class="px-3">
            <div class="card rounded-md row mb-3 flex-row p-3">
                <div class="col-2">
                    <label for="storeDropDown">
                        {{ $t("dashboard_page.start_date") }}</label
                    >
                    <flat-picker
                        :config="{
                            allowInput: true,
                            dateFormat: 'd-m-Y',
                            // defaultDate: new Date(),
                        }"
                        class="form-control datepicker"
                        :placeholder="$t('select')"
                        v-model="selectedDate[0]"
                        @change="getChartDataApi()"
                    >
                    </flat-picker>
                </div>

                <div class="col-2">
                    <label for="storeDropDown">
                        {{ $t("dashboard_page.end_date") }}</label
                    >
                    <flat-picker
                        :config="{
                            allowInput: true,
                            dateFormat: 'd-m-Y',
                            // defaultDate: new Date(),
                        }"
                        class="form-control datepicker"
                        :placeholder="$t('select')"
                        v-model="selectedDate[1]"
                        @change="getChartDataApi()"
                    >
                    </flat-picker>
                </div>

                <div class="col-2" v-if="showFilter('status') && statusOptions">
                    <label for=""> {{ $t("status") }}</label>
                    <el-select
                        clearable
                        class="d-block"
                        :placeholder="$t('select')"
                        v-model="filters.order_status"
                        filterable
                    >
                        <el-option
                            v-for="option in statusOptions"
                            class="select-danger"
                            :value="option.value"
                            :label="$t(option.label)"
                            :key="option.value"
                        >
                        </el-option>
                    </el-select>
                </div>

                <div
                    class="col-2"
                    v-if="showFilter('address') && addressOptions"
                >
                    <label for=""> {{ $t("address") }}</label>
                    <el-select
                        clearable
                        class="d-block"
                        :placeholder="$t('select')"
                        v-model="filters.pickup_address_id"
                        filterable
                    >
                        <el-option
                            v-for="option in addressOptions"
                            class="select-danger"
                            :value="option.id"
                            :label="option.address_line1"
                            :key="option.id"
                        >
                        </el-option>
                    </el-select>
                </div>

                <div
                    class="col-2"
                    v-if="showFilter('vehicle_type') && vehicleTypeOptions"
                >
                    <label for=""> {{ $t("vehicle_type") }}</label>
                    <el-select
                        clearable
                        class="d-block"
                        :placeholder="$t('select')"
                        v-model="filters.vehicle_type_id"
                        @click="getLang()"
                        filterable
                    >
                        <el-option
                            v-for="option in vehicleTypeOptions"
                            class="select-danger"
                            :value="option.id"
                            :label="lang == 'ar' ? option.type_ar : option.type"
                            :key="option.id"
                        >
                        </el-option>
                    </el-select>
                </div>

                <div
                    class="col-2"
                    v-if="
                        showFilter('payment_type') &&
                        defaultOptions.storePaymentTypeOptions
                    "
                >
                    <label for=""> {{ $t("order_payment_method") }}</label>
                    <el-select
                        clearable
                        class="d-block"
                        :placeholder="$t('select')"
                        v-model="filters.payment_method"
                    >
                        <el-option
                            v-for="option in defaultOptions.storePaymentTypeOptions"
                            class="select-danger"
                            :value="option.value"
                            :label="$t(option.label)"
                            :key="option.value"
                        >
                        </el-option>
                    </el-select>
                </div>

                <div class="col-2" v-if="showFilter('user') && userOptions">
                    <label for=""> {{ $t("user") }}</label>
                    <el-select
                        clearable
                        class="d-block"
                        :placeholder="$t('select')"
                        v-model="filters.created_by"
                    >
                        <el-option
                            v-for="option in userOptions"
                            class="select-danger"
                            :value="option.id"
                            :label="option.name"
                            :key="option.id"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row chart-wrap">
                <!-- Order Bra Chart -->
                <div
                    class="rounded-md p-4 card"
                    :class="{
                        'col-12': userRole == 1 || userRole == 6,
                        'col-7':
                            userRole == 4 || userRole == 5 || userRole == 7,
                    }"
                >
                    <div class="card-header">
                        <h2>{{ $t("order_bar_chart") }}</h2>
                    </div>
                    <div class="card-body" v-loading="isLoadingBar">
                        <!-- Orders Chart -->
                        <canvas height="350" :id="orderBarChartId"></canvas>
                    </div>
                </div>

                <!-- Order Pie Chart -->
                <div
                    class="card rounded-md p-4 chart-rounded col-4"
                    v-if="userRole == 4 || userRole == 5"
                >
                    <div class="card-header">
                        <h2>{{ $t("order_pie_chart") }}</h2>
                    </div>
                    <div class="card-body" v-loading="isLoadingPie">
                        <!-- Orders Pie Chart  -->
                        <canvas height="350" :id="orderPieChartId"></canvas>
                    </div>
                </div>

                <!-- Order average -->
                <div
                    class="rounded-md mx-auto p-4 card col-4"
                    v-if="userRole == 7"
                >
                    <div class="card-header">
                        <h2>{{ $t("orderAverage") }}</h2>
                    </div>
                    <div class="card-body" v-loading="isLoadingBar">
                        <div class="d-flex">
                            <p class="mr-auto font-weight-bold">
                                {{ $t("orderAverageInWeek") }}
                            </p>
                            <p class="font-weight-bold">
                                {{ orderAverage.current_week_order_average }}
                            </p>
                        </div>
                        <div class="d-flex">
                            <p class="mr-auto font-weight-bold">
                                {{ $t("orderAverageInMonth") }}
                            </p>
                            <p class="font-weight-bold">
                                {{ orderAverage.current_month_order_average }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div
                    class="card rounded-md p-4 col-12"
                    v-if="userRole == 1 || userRole == 6"
                >
                    <div class="card-header">
                        <h2>{{ $t("revenue_bar_chart") }}</h2>
                    </div>
                    <div class="card-body" v-loading="isLoadingBar">
                        <!-- Revenue Chart -->
                        <canvas height="350" :id="revenueBarChartId"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import "flatpickr/dist/flatpickr.css";
import { get } from "@/api/addresses";
import { getUsers } from "@/api/users";
import flatPicker from "vue-flatpickr-component";
import { getVehicleTypes } from "@/api/vehicleType";
import { pieChart } from "@/components/Charts/Chart.js";
import { getChartData, getOrderAverage } from "@/api/dashboard";
import { orderBarChart, revenueBarChart } from "@/components/Charts/Chart.js";

export default {
    data() {
        return {
            selectedDate: [],
            revenueChartData: {},
            orderChartData: {},
            orderBarChartId: this.$t("order_bar_chart"),
            orderPieChartId: this.$t("order_pie_chart"),
            revenueBarChartId: this.$t("revenue_bar_chart"),
            filters: {
                order_status: null,
                vehicle_type_id: null,
                pickup_address_id: null,
                created_by: null,
                payment_method: null,
            },
            statusOptions: [],
            addressOptions: [],
            vehicleTypeOptions: [],
            userOptions: [],
            isLoadingPie: false,
            isLoadingBar: false,
            paymentTypeOptions: [
                {
                    value: "customer_payment",
                    label: this.$t("customer_payment"),
                },
                {
                    value: "store_credit",
                    label: this.$t("store_credit"),
                },
            ],
            lang: localStorage.getItem("lang"),
            isChartLoad: false,
            orderAverage: {},
        };
    },
    mixins: [mixin],

    watch: {
        filters: {
            handler() {
                this.getChartDataApi();
            },
            deep: true,
        },
    },

    created() {
        this.getChartDataApi();
        this.userRole == 7 ? this.getOrderAverage() : null;
    },

    mounted() {
        if (this.showFilter("status")) {
            this.getStatusOptions();
        }
        if (this.showFilter("address")) {
            this.getAddressOptions();
        }
        if (this.showFilter("vehicle_type")) {
            this.getVehicleTypeOptions();
        }
        if (this.showFilter("user")) {
            this.getUserOptions();
        }
    },

    methods: {
        showFilter(filterFor) {
            if (
                filterFor == "status" ||
                filterFor == "address" ||
                filterFor == "vehicle_type" ||
                filterFor == "payment_type"
            ) {
                return this.userRole == 4 || this.userRole == 5 ? true : false;
            }
            if (filterFor == "user") {
                return this.userRole == 4 ? true : false;
            }
            return false;
        },

        getStatusOptions() {
            this.statusOptions = this.defaultOptions.orderStatus;
        },

        getAddressOptions() {
            get({
                store_id: this.storeId,
            }).then((res) => {
                this.addressOptions = res.data;
            });
        },

        getVehicleTypeOptions() {
            getVehicleTypes().then((res) => {
                this.vehicleTypeOptions = res.data;
            });
        },

        getUserOptions() {
            getUsers(this.userRole === 1 ? "admin" : "store", {
                store_id: this.storeId,
            }).then((res) => {
                this.userOptions = res.data;
            });
        },

        getChartDataApi() {
            if (
                (this.selectedDate[0] != null &&
                    this.selectedDate[1] != null) ||
                !this.isChartLoad
            ) {
                this.isChartLoad = true;
                let start_date = this.selectedDate[0];
                let end_date = this.selectedDate[1];
                this.isLoadingPie = true;
                this.isLoadingBar = true;
                getChartData({
                    start_date: start_date,
                    end_date: end_date,

                    filter: this.filters,
                    store_id: this.storeId,
                })
                    .then((res) => {
                        if (this.userRole === 1 || this.userRole === 6) {
                            this.isLoadingPie = false;
                            this.isLoadingBar = false;
                            this.revenueChartData = res;

                            revenueBarChart.createChart(
                                this.revenueBarChartId,
                                res.revenue_dates,
                                res.revenue
                            );
                            this.orderChartData = res;
                            orderBarChart.createChart(
                                this.orderBarChartId,
                                res.order_dates,
                                res.order_count
                            );
                        } else {
                            this.isLoadingPie = false;
                            this.isLoadingBar = false;
                            this.orderChartData = res;
                            orderBarChart.createChart(
                                this.orderBarChartId,
                                res.order_dates,
                                res.order_count
                            );

                            pieChart.createChart(
                                this.orderPieChartId,
                                res.order_dates,
                                res.order_count
                            );
                        }
                    })
                    .catch(({ response }) => {
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.isLoadingPie = false;
                        this.isLoadingBar = false;
                        this.apiError(response);
                    });
            }
        },
        getLang() {
            this.lang = localStorage.getItem("lang");
        },
        getOrderAverage() {
            getOrderAverage().then((res) => {
                this.orderAverage = res;
            });
        },
    },
    components: {
        flatPicker,
    },
};
</script>

<style></style>
