<template>
    <div>
        <label for="" class="my-1">{{ $t("name") }}</label>
        <Field
            type="text"
            rules="required|max:20"
            :label="$t('name')"
            class="form-control"
            :placeholder="$t('name')"
            name="name"
            v-model="formState.name"
        />
        <ErrorMessage name="name" class="error" />
        <label for="" class="my-1">{{ $t("email") }}</label>

        <Field
            type="email"
            rules="required|email"
            :label="$t('email')"
            class="form-control"
            :placeholder="$t('email')"
            name="email"
            id="email"
            v-model="formState.email"
        />
        <ErrorMessage name="email" class="error" />
        <label for="" class="my-1">{{ $t("mobile_no") }}</label>

        <Field
            type="number"
            rules="required|length:9"
            :label="$t('mobile_no')"
            class="form-control"
            :placeholder="$t('mobile_no')"
            name="mobile_no"
            v-model="formState.mobile_no"
        />
        <ErrorMessage name="mobile_no" class="error" />
    </div>
</template>

<script>
import { cloneDeep } from "lodash-es";

export default {
    data() {
        return {
            formState: {},
        };
    },
    props: ["customer"],
    created() {
        if (this.customer) this.cloneData(this.customer);
    },
    methods: {
        cloneData(customer) {
            this.formState = cloneDeep(customer);
        },
    },
};
</script>

<style></style>
