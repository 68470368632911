import axiosInstance from "@/plugins/customAxios";

export const getVehicleTypes = (params = null) => {
    return axiosInstance
        .get("v1/vehicle-types", { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getVehicleType = (id, params = null) => {
    return axiosInstance
        .get(`v1/admin/vehicle-types/${id}`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const editVehicleType = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/admin/vehicle-types/${id}`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};
export const addVehicleType = (data, params = null) => {
    return axiosInstance
        .post("v1/admin/vehicle-types", data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getDitsrictOptions = (params = null) => {
    return axiosInstance.get("v1/districts", { params: params }).then(res => res.data);
}


export const savePackageDetails = (data, store_id) => {
    return axiosInstance.post(`v1/store/orders?store_id=${store_id}`, data).then(res => res.data);
}