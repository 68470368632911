<template>
    <div class="d-inline">
        <slot :clickEvent="changeStatusModal">
            <el-tooltip :content="$t('update_status')" placement="top">
                <i
                    class="fas fa-sync fa-md text-default mr-2"
                    style="cursor: pointer"
                    @click="changeStatusModal"
                ></i>
            </el-tooltip>
        </slot>

        <modal
            :show="showModal"
            @close="showModal = false"
            size="sm"
            body-classes="p-0"
            v-loading="isLoading"
        >
            <template v-slot:header>
                <div>
                    <img
                        src="@/assets/images/icons/common/changeUserStatus.png"
                        alt=""
                    />
                </div>
            </template>
            <div class="px-5">
                <p></p>
                <el-select
                    :placeholder="$t('select')"
                    v-model="status"
                    class="d-block"
                    filterable
                >
                    <el-option
                        v-for="option in defaultOptions.storeStatus"
                        class="select-danger"
                        :value="option.value"
                        :label="$t(option.label)"
                        :key="option.label"
                    >
                    </el-option>
                </el-select>
            </div>

            <div class="text-center my-4">
                <base-button @click.native="confimationModel">
                    {{ $t("confirm") }}</base-button
                >
                <base-button @click.native="showModal = false">
                    {{ $t("close") }}</base-button
                >
            </div>
        </modal>

        <!-- Confirmation popup -->
        <modal
            footerClasses="justify-content-center"
            :show="showConfirmationModel"
            @close="showConfirmationModel = false"
            size="sm"
            body-classes="p-0"
        >
            <template v-slot:header>
                <div>
                    <img
                        src="@/assets/svg/confirm-img.svg"
                        alt=""
                        style="width: 150px; height: 150px"
                    />
                    <div class="text-bold text-lg text-center mt-2 mb-1">
                        {{ $t("confirmation") }}
                    </div>
                </div>
            </template>
            <div class="px-3 d-block text-center">
                <h2 class="text-break font-weight-900">
                    {{ $t("storeStatusUpdateConfirmationDesc") }}
                </h2>
            </div>
            <template v-slot:footer>
                <div class="w-full text-center mt-3">
                    <base-button
                        @click.native="changeStatusFormSubmit()"
                        :loading="submitSpinner"
                    >
                        {{ $t("yes") }}</base-button
                    >
                    <button
                        class="btn btn-default text-white"
                        data-dismiss="modal"
                        @click="
                            (showModal = true), (showConfirmationModel = false)
                        "
                    >
                        {{ $t("no") }}
                    </button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { changeStatus } from "@/api/store";
export default {
    data() {
        return {
            status: "",
            isLoading: true,
            submitSpinner: false,
            showModal: false,
            showConfirmationModel: false,
        };
    },
    props: ["store"],
    components: {},
    methods: {
        changeStatusModal() {
            this.showModal = true;
            this.status = this.store.status;
            this.isLoading = false;
        },
        confimationModel() {
            this.showConfirmationModel = true;
            this.showModal = false;
        },
        changeStatusFormSubmit() {
            this.submitSpinner = true;
            changeStatus(this.store.id, { status: this.status })
                .then((res) => {
                    this.submitSpinner = false;
                    this.runToast(
                        this.$t("storeStatusUpdatedSuccess"),
                        "success"
                    );
                    this.showConfirmationModel = false;
                    this.$emit("changeStatusCompleted");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    this.apiError(response);
                });
        },
    },
    mixins: [mixin],
};
</script>

<style></style>
