<template>
    <div class="d-inline">
        <slot :clickEvent="assignProviderModal">
            <i
                class="fas fa-sync fa-md text-default mr-4"
                style="cursor: pointer"
                @click="assignProviderModal"
            ></i>
        </slot>

        <modal
            :show="showModal"
            @close="showModal = false"
            size="md"
            body-classes="p-0"
            v-loading="isLoading"
        >
            <!-- Modal Header Content -->
            <template v-slot:header>
                <div class="text-bold text-lg text-center mt-2 mb-3">
                    {{ $t("assign_provider") }}
                </div>
            </template>

            <!-- Modal Main Content -->
            <div class="px-5">
                <form action="" @click="sumbitAssignProvider">
                    <div>
                        <label for="provider" class="d-block">{{
                            $t("providers")
                        }}</label>
                            <el-select
                                v-if="isMultiple"
                                v-model="provider_ids"
                                :multiple="isMultiple"
                                class="d-block"
                                filterable
                                remote
                                :remote-method="remoteSearch"
                                :loading="isSearching"
                            >
                            <el-option
                                v-for="option in providers"
                                class="select-danger"
                                :value="option.id"
                                :label="
                                    option.name + '(' + option.mobile_no + ')'
                                "
                                :key="option.name"
                            >
                            </el-option>
                        </el-select>
                        <el-select
                            v-else
                            v-model="provider_id"
                            class="d-block"
                            filterable
                            remote
                            :remote-method="remoteSearch"
                            :loading="isSearching"
                        >
                            <el-option
                                v-for="option in providers"
                                class="select-danger"
                                :value="option.id"
                                :label="
                                    option.name + '(' + option.mobile_no + ')'
                                "
                                :key="option.name"
                            >
                            </el-option>
                        </el-select>
                    </div>

                    <div class="text-center my-4">
                        <base-button
                            :loading="submitSpinner"
                            native-type="submit"
                            class="text-white bg-primary"
                        >
                            {{ $t("submit") }}
                        </base-button>
                    </div>
                </form>
            </div>
        </modal>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { assignProvider } from "@/api/serviceProviderCompanies";
import { getUsers } from "@/api/users";
import { assignProviderToOrder } from "@/api/orders";

export default {
    props: {
        currentRow: {
            type: Object,
            default: () => {},
        },
        type: {
            type: String,
            default: "order",
        },
        orderId: {
            type: Number,
            default: null,
        },
        isMultiple: {
            type: Boolean,
            default: false,
        },
        store: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isLoading: true,
            provider_ids: [],
            provider_id: null,
            submitSpinner: false,
            providers: [],
            showModal: false,
            isSearching: false,
        };
    },

    created() {
        if (this?.currentRow) {
            this.provider_ids = this?.currentRow?.providers?.map((pr) => {
                return pr.id;
            });
        }
    },
    watch: {
        showModal: function (newVal, oldVal) {
            if (newVal === true) {
                this.remoteSearch("");
            }
        },
    },
    methods: {
        assignProviderModal() {
            this.provider_id = this.currentRow?.provider_id
                ? this.currentRow?.provider_id
                : null;
            this.showModal = true;
            this.isLoading = false;
        },

        remoteSearch(query) {
      if (query.length < 2) {
        this.providers = [];
        return;
      }

      this.isSearching = true;
      getUsers(
        this.userRole === 1 ||
          this.userRole === 6 ||
          this.userRole === 7 ||
          this.userRole === 8 ||
          this.userRole === 9
          ? "admin"
          : "store",
        {
          role: "provider",
          store_id: this.store?.id || null,
          service_provider_company_id:
            this.$store.getters.serviceProviderCompany?.id || null,
          limit: 25,
          search: query,
        }
      )
        .then((res) => {
          this.providers = res.data;
          this.isSearching = false;
        })
        .catch(({ response }) => {
          this.isSearching = false;
          this.apiError(response);
        });
      },

        sumbitAssignProvider() {
            if (this.type == "serviceProviderCompany") {
                this.assignProviderToSPC();
            }

            if (this.type == "order") {
                this.assignProviderToOrder();
            }
        },
        assignProviderToOrder() {
            this.submitSpinner = true;
            let data = {
                provider_id: this.provider_id,
                service_provider_company_id:
                    this.$store.getters.serviceProviderCompany?.id || null,
            };
            assignProviderToOrder(this.orderId, data)
                .then((res) => {
                    this.submitSpinner = false;
                    this.showModal = false;
                    this.provider_id = null;
                    this.$emit("getOrders");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    this.apiError(response);
                });
        },
        assignProviderToSPC() {
            this.submitSpinner = true;
            let data = {
                provider_ids: this.provider_ids,
            };
            assignProvider(this.currentRow.id, data, {})
                .then((res) => {
                    this.submitSpinner = false;
                    this.runToast(res.message, "success");
                    this.showModal = false;
                    this.$emit("assignProvideCompleted");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    this.apiError(response);
                });
        },
    },
    mixins: [mixin],
};
</script>

<style scoped>
.el-select .el-input .el-input__inner {
    min-height: 200px !important;
    overflow: auto;
}
</style>
