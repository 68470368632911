<template>
    <div>
        <hr />
        <order-list v-if="$route.path === '/orders'"></order-list>
        <order-list v-else :storeOrder="true"></order-list>
    </div>
</template>

<script>
import OrderList from "@/components/common/OrderList.vue";
export default {
    created() {},
    components: {
        OrderList,
    },
};
</script>

<style></style>
