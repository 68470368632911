<template>
    <div>
        <router-view> </router-view>
    </div>
</template>

<script>
import { loadLanguage } from "@/plugins/changeLang";
import { firebaseMessaging } from "@/firebase.js";
import { onMessage } from "firebase/messaging";
import { useToast } from "vue-toastification";

export default {
    created() {
        this.receiveFcmMessage();
    },
    data() {
        return {
            useToast,
        };
    },
    mounted() {
        loadLanguage(localStorage.getItem("lang") || "ar");
    },
    methods: {
        receiveFcmMessage() {
            try {
                onMessage(firebaseMessaging, (payload) => {
                    const toast = useToast();
                    toast(payload.data.title, {
                        hideProgressBar: true,
                        type: "info",
                        timeout: 3000,
                    });
                    this.$store.dispatch("setUnreadNotificationCount", true);
                });
            } catch (e) {
                console.log(e);
            }
        },
        getNotificationRedirectLink(data) {
            console.log("get notification redirect link");
        },
    },
};
</script>

<style lang="scss">
@font-face {
    font-family: "sst_arabic_roman";
    src: local("sst_arabic_roman"),
        url("./assets/fonts/sst_arabic_roman.ttf") format("truetype");
}
html,
body {
    font-family: "sst_arabic_roman" !important;
    background-color: #f6f6f6 !important;
}
// error messages style for forms etc
.error {
    text-transform: capitalize !important;
    display: block !important;
    width: 100%;
    color: #f5365c !important;
    font-size: 13px !important;
}

.page-item.active .page-link {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.btn-default {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.bg-gradient-default {
    background: var(--primary) !important;
}

.btn.table-icons {
    color: var(--primary);
    box-shadow: none !important;
}

.reset-button {
    background-color: rgb(252, 199, 180) !important;
    border: none !important;
    color: red !important;
}
.close-button {
    box-shadow: none !important;
}

.custom-modal-header {
    align-items: center !important;
}

.modal-content {
    border-radius: 1.4375rem !important;
}

.modal-header {
    justify-content: center !important;
}

.btn {
    padding: 0.625rem 3.25rem !important;
    border-radius: 1.25rem !important;
}
.fixed-right .nav-link-text {
    padding-right: 10%;
}
.arrow-none:after {
    display: none !important;
}
// Top Main Tabs
.nav-wrapper .nav {
    flex-wrap: nowrap !important;
    max-width: 100% !important;
    overflow: auto;
    border-bottom: 1px solid #f0f0f0;
    li {
        flex: none !important;
        min-width: 150px;
        a {
            padding-bottom: 20px;
        }
    }
}
.nav-wrapper .nav::-webkit-scrollbar {
    display: none;
}
.scrollbar-inner a.nav-link img {
    height: 20px;
}
.top-nav-btn {
    position: relative;
    .nav-wrapper {
        border-bottom: 1px solid #f0f0f0;
        .nav {
            border-bottom: none;
            max-width: 80% !important;
        }
    }
    .btn--new-order {
        position: absolute;
        top: 22px;
        right: 0;
    }
}
</style>
