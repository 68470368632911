<template>
    <badge
        class="status-badge"
        rounded
        :style="`background-color: ${getBgColor(label)}`"
        size="md"
    >
        <span class="status status-bold">{{ $t(`${label}`) }}</span>
    </badge>
</template>

<script>
import mixin from "@/mixins/common";
export default {
    mixins: [mixin],
    name: "custom-label",
    data() {
        return {
            statusColor: {
                approved: "#7368fb",
                rejected: "#EF2323",
                suspended: "#7368FB",
                requested: "#FEA001",
                accepted: "#197EF4",
                confirmed: "#459788",
                "on the way": "#7368FB",
                "picked up": "#B6A82D",
                "dropped off": "#EF6C23",
                "to the store": "#7368FB",
                "at the store": "#7368FB",
                returned: "#FA6800",
                cancelled: "#FF0000",
                Opened: "#FFA000",
                Accepted: "#007bff",
                Rejected: "#FF0000",
                active: "#689F38",
                inactive: "#FF0000",
                completed: "#689F38",
                initiated: "#FFA000",
                paid: "#689F38",
                unpaid: "#FF0000",
                pending: "#FEA001",
                failed: "#FF0000",
                insured: "#689F38",
                not_insured: "#FF0000",
                allow: "#689F38",
                disallow: "#FF0000",
                true: "#689F38",
                false: "#FF0000",
                opened: "#B0A115",
                bonus: "#689F38",
                fine: "#FF0000",
                credit_transfer: "#7368fb",
                order_delivery: "#007bff",
                compensation: "#FA6800",
                complaint: "#FF0000",
                yes: "#689F38",
                no: "#FF0000",
                charge_credit: "#FA6800",
                dues_payment: "#689F38",
                "on hold": "#B0A115",
                new: "#7368FB",
                ready: "#B0A115",
                indelivery: "#007bff",
                delivered: "#689F38",
                unlimited: "#FF0000",
                referral: "#689F38",
                referral: "#B0A115",
                referral_credit_transfer: "#7368fb",
                public: "#FFA000",
                private: "#689F38",
                automatic: "#007bff",
                individual_order_cancel: "#FF0000",
                store_order_cancel: "#689F38",
                place_order: "#689F38",
            },
        };
    },
    props: {
        label: {
            type: [String, Boolean],
            required: true,
            default: "Label",
        },
    },
    methods: {
        getBgColor(badgeValue) {
            // below line if for converting boolean type to string type if any to handle error of lowercase conversion
            badgeValue = badgeValue + "";
            for (let statusColorKey in this.statusColor) {
                if (statusColorKey == badgeValue.toLowerCase()) {
                    return this.statusColor[statusColorKey];
                }
            }
            // If Status Key not found in given object, then send default color white
            return "white";
        },
    },
};
</script>

<style scoped lang="scss">
.badge span {
    color: #fff !important;
    text-transform: capitalize !important;
}
.status-badge {
    border-radius: 5px !important;
}
</style>
