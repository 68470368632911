import axiosInstance from "@/plugins/customAxios";
import store from "@/store/index";

export const getUserNotes = (id, params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 1 ||
        store.getters.user?.role_id === 9 ||
        store.getters.user?.role_id === 7 ||
        store.getters.user?.role_id === 8 ||
        store.getters.user?.role_id === 6
    ) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }
    return axiosInstance
        .get(`v1/${baseUrl}/users/${id}/notes`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getOrderNotes = (id, params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 1 ||
        store.getters.user?.role_id === 9 ||
        store.getters.user?.role_id === 7 ||
        store.getters.user?.role_id === 8 ||
        store.getters.user?.role_id === 6
    ) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }
    return axiosInstance
        .get(`v1/${baseUrl}/orders/${id}/notes`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const addUserNotes = (id, data, params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 1 ||
        store.getters.user?.role_id === 9 ||
        store.getters.user?.role_id === 7 ||
        store.getters.user?.role_id === 8 ||
        store.getters.user?.role_id === 6
    ) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }
    return axiosInstance
        .post(`v1/${baseUrl}/users/${id}/notes`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const addOrderNotes = (id, data, params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 1 ||
        store.getters.user?.role_id === 9 ||
        store.getters.user?.role_id === 7 ||
        store.getters.user?.role_id === 8 ||
        store.getters.user?.role_id === 6
    ) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }
    return axiosInstance
        .post(`v1/${baseUrl}/orders/${id}/notes`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};
