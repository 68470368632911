<template>
    <div>
        <hr />
        <div class="p-5 mb-0 card rounded-md">
            <h3>{{ $t("add_provider") }}</h3>

            <Form @submit="onSubmit" class="">
                <div class="mt-3 row">
                    <div class="col-md-6">
                        <label for="name">{{ $t("name") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            :label="$t('name')"
                            name="name"
                            v-model="form.name"
                        />
                        <ErrorMessage name="name" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="">{{ $t("email") }}</label>
                        <Field
                            type="text"
                            rules="required|email"
                            :label="$t('email')"
                            class="form-control"
                            name="email"
                            v-model="form.email"
                        />
                        <ErrorMessage name="email" class="error" />
                    </div>
                </div>

                <div class="mt-3 row">
                    <div class="col-md-6">
                        <label for="">{{ $t("mobile_no") }}</label>
                        <Field
                            type="text"
                            rules="required|length:9"
                            :label="$t('mobile_no')"
                            class="form-control"
                            name="mobile_no"
                            v-model="form.mobile_no"
                        />
                        <ErrorMessage name="mobile_no" class="error" />
                    </div>

                    <div class="col-md-6">
                        <label for="">{{ $t("password") }}</label>
                        <Field
                            name="password"
                            class="form-control"
                            type="password"
                            rules="required|min:8"
                            v-model="form.password"
                            :label="$t('password')"
                        />
                        <ErrorMessage name="password" class="error" />
                    </div>
                </div>

                <div class="mt-3 row">
                    <div class="col-md-6">
                        <label for="">{{ $t("password_confirmation") }}</label>
                        <Field
                            name="password_confirmation"
                            class="form-control"
                            type="password"
                            rules="confirmed:@password"
                            v-model="form.password_confirmation"
                            :label="$t('password_confirmation')"
                        />
                        <ErrorMessage
                            name="password_confirmation"
                            class="error"
                        />
                    </div>

                    <div class="col-md-6">
                        <label for="city" class="my-1">{{ $t("city") }}</label>
                        <Field
                            name="city_id"
                            :label="$t('city')"
                            :placeholder="$t('city')"
                            rules="required"
                            v-model="form.city_id"
                        >
                            <el-select
                                class="d-block"
                                v-model="form.city_id"
                                name="city_id"
                                :placeholder="$t('city')"
                                filterable
                            >
                                <el-option
                                    v-for="option in city"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="option.title"
                                    :key="option.value"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage name="city_id" class="error" />
                    </div>
                </div>

                <h2 class="mt-3">{{ $t("vehicle_detail") }}</h2>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="vehicle_name">{{
                            $t("vehicle_name")
                        }}</label>
                        <Field
                            type="text"
                            class="form-control"
                            rules="required"
                            :label="$t('vehicle_name')"
                            name="vehicle.name"
                            v-model="form.vehicle.name"
                        />
                        <ErrorMessage name="vehicle.name" class="error" />
                    </div>

                    <div class="col-md-6">
                        <label for="vehicle_type" class="my-1">{{
                            $t("vehicle_type")
                        }}</label>
                        <Field
                            name="vehicle.vehicle_type_id"
                            :label="$t('vehicle_type')"
                            :placeholder="$t('vehicle_type')"
                            rules="required"
                            v-model="form.vehicle.vehicle_type_id"
                        >
                            <el-select
                                class="d-block"
                                v-model="form.vehicle.vehicle_type_id"
                                name="vehicle.vehicle_type_id"
                                :placeholder="$t('vehicle_type')"
                                filterable
                            >
                                <el-option
                                    v-for="option in vehicleTypes"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="option.type"
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage
                            name="vehicle.vehicle_type_id"
                            class="error"
                        />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="id_proof_image" class="my-1 mr-2">{{
                            $t("id_proof_image")
                        }}</label>

                        <dropzone-file-upload
                            required
                            @thumbnail="idProofUploadEvent"
                        ></dropzone-file-upload>
                        <p class="error" v-if="showIdProofValidateMessage">
                            {{ $t("id_proof_required") }}
                        </p>
                    </div>
                    <div class="col-md-6">
                        <label
                            for="registration_licence_image"
                            class="my-1 mr-2"
                            >{{ $t("registration_licence_image") }}</label
                        >
                        <dropzone-file-upload
                            @thumbnail="licenceUploadEvent"
                        ></dropzone-file-upload>
                        <p class="error" v-if="showLicenceValidateMessage">
                            {{ $t("vehicle_registration_licence_required") }}
                        </p>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="vehicle_image" class="my-1 mr-2">{{
                            $t("vehicle_image")
                        }}</label>
                        <dropzone-file-upload
                            @thumbnail="vehicleImageUploadEvent"
                        ></dropzone-file-upload>
                        <p class="error" v-if="showImageValidateMessage">
                            {{ $t("vehicle_image_required") }}
                        </p>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <div>
                            <Field
                                name="vehicle.install_service"
                                v-model="form.vehicle.install_service"
                            >
                                <input
                                    type="checkbox"
                                    v-model="form.vehicle.install_service"
                                    name="vehicle.install_service"
                                />
                                <label for="" class="my-1 ml-2">{{
                                    $t("install_service_available")
                                }}</label>
                            </Field>
                        </div>

                        <div>
                            <Field
                                name="vehicle.load_service"
                                v-model="form.vehicle.load_service"
                            >
                                <input
                                    type="checkbox"
                                    v-model="form.vehicle.load_service"
                                    name="vehicle.load_service"
                                />
                                <label for="" class="my-1 ml-2">{{
                                    $t("load_service")
                                }}</label>
                            </Field>
                        </div>
                    </div>
                </div>

                <div class="w-full mt-5 text-center">
                    <base-button
                        native-type="submit"
                        class="text-white bg-primary"
                        :loading="submitSpinner"
                    >
                        {{ $t("create") }}
                    </base-button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import { getVehicleTypes } from "@/api/vehicleType";
import { addUser } from "@/api/users";
import { get } from "@/api/cities";
import mixin from "@/mixins/common";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import { serialize } from "object-to-formdata";

export default {
    data() {
        return {
            vehicleTypes: [],
            form: {
                name: null,
                mobile_no: null,
                email: null,
                city_id: null,

                vehicle: {
                    load_service: 0,
                    install_service: 0,
                },
            },
            submitSpinner: false,
            showIdProofValidateMessage: false,
            showImageValidateMessage: false,
            showLicenceValidateMessage: false,
            id_proof_image: null,
            city: [],
        };
    },
    mixins: [mixin],

    created() {
        this.getVehicleType();
        this.getCity();
    },

    components: {
        DropzoneFileUpload,
    },

    methods: {
        vehicleImageUploadEvent(data) {
            this.form.vehicle.vehicle_image = data;
        },
        idProofUploadEvent(data) {
            this.form.vehicle.id_proof_image = data;
        },
        licenceUploadEvent(data) {
            this.form.vehicle.registration_license_image = data;
        },
        getCity( city ) {
            const isAr = localStorage.getItem("lang") == "ar"
            get({
                limit: -1,
                filter:{
                    name : city
                }
            })
                .then((res) => {
                    this.city = res.data.map((item) => {
                        return {
                            title:
                                isAr
                                    ? item.name_ar
                                    : item.name,
                            value: item.id,
                        };
                    });
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getVehicleType() {
            getVehicleTypes({
                service_provider_company_id: this.$store.getters.user
                    ?.service_provider_company?.id,
            })
                .then((res) => {
                    this.vehicleTypes = res.data;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        onSubmit(data) {
            this.submitSpinner = true;
            if (!this.form.vehicle.id_proof_image) {
                this.showIdProofValidateMessage = true;
                this.submitSpinner = false;
                return false;
            } else {
                this.showIdProofValidateMessage = false;
            }

            if (!this.form.vehicle.registration_license_image) {
                this.showLicenceValidateMessage = true;
                this.submitSpinner = false;
                return false;
            } else {
                this.showLicenceValidateMessage = false;
            }

            if (!this.form.vehicle.vehicle_image) {
                this.showImageValidateMessage = true;
                this.submitSpinner = false;
                return false;
            } else {
                this.showImageValidateMessage = false;
            }

            if (this.form.vehicle.load_service) data.vehicle.load_service = 1;
            else data.vehicle.load_service = 0;

            if (this.form.vehicle.install_service)
                data.vehicle.install_service = 1;
            else data.vehicle.install_service = 0;

            const formData = serialize(data);

            formData.append(
                "vehicle[id_proof_image]",
                this.form.vehicle.id_proof_image
            );
            formData.append(
                "vehicle[registration_license_image]",
                this.form.vehicle.registration_license_image
            );
            formData.append(
                "vehicle[vehicle_image]",
                this.form.vehicle.vehicle_image
            );

            addUser("admin", formData, {
                service_provider_company_id: this.$store.getters.user
                    ?.service_provider_company?.id,
            })
                .then(() => {
                    this.submitSpinner = false;
                    this.$router.replace({ name: "Provider List" });
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
    },
};
</script>

<style></style>
