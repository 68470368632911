import axiosInstance from "@/plugins/customAxios";

export const getPendingTransaction = (params = null) => {
    return axiosInstance
        .get("v1/admin/bank-transactions", { params: params })
        .then((res) => {
            return res.data;
        });
};

export const confirmTransaction = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/admin/bank-transactions/${id}/status`, data)
        .then((res) => {
            return res.data;
        });
};
