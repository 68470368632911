<template>
    <div>
        <hr />
        <div class="card py-3 px-5 rounded-md">
            <div class="row">
                <!-- User signup date filter -->
                <div class="col-md-2 mb-2">
                    <label for="">{{ $t("user_signup_date") }}</label>
                    <flat-picker
                        :config="{
                            mode: 'range',
                            dateFormat: 'd-m-Y',
                            allowInput: true,
                            onClose: (dates, currentdatestring, picker) => {
                                dateFilterChange(
                                    dates,
                                    currentdatestring,
                                    picker,
                                    'signup_date_filter'
                                );
                            },
                        }"
                        class="form-control datepicker"
                        :placeholder="$t('user_signup_date')"
                        v-model="userSignUpDate"
                    >
                    </flat-picker>
                </div>
                <!-- User place order date filter -->
                <div class="col-md-2 mb-2">
                    <label for="">{{ $t("order_date") }}</label>

                    <flat-picker
                        :config="{
                            mode: 'range',
                            dateFormat: 'd-m-Y',
                            allowInput: true,
                            onClose: (dates, currentdatestring, picker) => {
                                dateFilterChange(
                                    dates,
                                    currentdatestring,
                                    picker,
                                    'order_date_filter'
                                );
                            },
                        }"
                        class="form-control datepicker"
                        :placeholder="$t('order_date')"
                        v-model="orderDateFilter"
                    >
                    </flat-picker>
                </div>
                <!-- User place order or not -->
                <div class="col-md-2 mt-4">
                    <input
                        type="checkbox"
                        v-model="order_filters.is_order_added"
                        name="is_order_added"
                        id="is_order_added"
                    />
                    <label class="mx-2" for="is_order_added">{{
                        $t("isOrderAdded")
                    }}</label>
                </div>
                <!-- Order No -->
                <div class="col-md-3 mb-2">
                    <label for="">{{ $t("order_no") }}</label>

                    <search-filter
                        :filters="[
                            {
                                id: 'order_no',
                            },
                        ]"
                        @search="
                            (searchObject) => (order_filters = searchObject)
                        "
                    />
                </div>
                <!-- Choose first order status -->
                <div class="col-md-3 mb-2">
                    <label for="">{{ $t("choose_first_order") }}</label>

                    <el-select
                        clearable
                        class="d-block"
                        :placeholder="$t('choose_first_order')"
                        v-model="order_filters.first_order_status"
                        filterable
                    >
                        <el-option
                            v-for="option in firstOrderOptions"
                            class="select-danger"
                            :value="option.value"
                            :label="$t(option.label)"
                            :key="option.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <!-- Order cancellation reason -->
                <div class="col-md-6 mb-2">
                    <label for="">{{ $t("choose_cancellation_reason") }}</label>

                    <el-select
                        clearable
                        class="d-block"
                        :placeholder="$t('choose_cancellation_reason')"
                        v-model="order_filters.cancellation_reason"
                        filterable
                        multiple
                    >
                        <el-option
                            v-for="option in defaultOptions.cancelOrderAllReason"
                            class="select-danger"
                            :value="option.value"
                            :label="$t(option.label)"
                            :key="option.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <!-- Is place order after cancelled order -->
                <div class="col-md-6 mt-4">
                    <input
                        type="checkbox"
                        v-model="
                            order_filters.is_placed_order_after_cancelled_order
                        "
                        name="is_placed_order_after_cancelled_order"
                        id="is_placed_order_after_cancelled_order"
                    />
                    <label
                        class="mx-2"
                        for="is_placed_order_after_cancelled_order"
                        >{{
                            $t("is_placed_order_after_cancelled_order")
                        }}</label
                    >
                </div>
                <!-- Pickup city -->
                <div class="col-md-6 mb-2">
                    <label for="">{{ $t("choose_cities") }}</label>

                    <el-select
                        clearable
                        class="d-block"
                        :placeholder="$t('choose_cities')"
                        v-model="order_filters.pickup_address_city"
                        filterable
                        multiple
                    >
                        <el-option
                            v-for="option in cities"
                            class="select-danger"
                            :value="option.value"
                            :label="option.title"
                            :key="option.id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <!-- Order Status -->
                <div class="col-md-6 mb-2">
                    <label for="">{{ $t("choose_order_status") }}</label>

                    <el-select
                        clearable
                        class="d-block"
                        :placeholder="$t('choose_order_status')"
                        v-model="order_filters.order_status"
                        filterable
                        multiple
                    >
                        <el-option
                            v-for="option in defaultOptions.orderStatus"
                            class="select-danger"
                            :value="option.value"
                            :label="$t(option.label)"
                            :key="option.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <!-- Choose order type -->
                <div class="col-md-6 mb-2">
                    <label for="">{{ $t("choose_order_type") }}</label>

                    <el-select
                        clearable
                        class="d-block"
                        :placeholder="$t('choose_order_type')"
                        v-model="order_filters.order_type"
                        filterable
                        multiple
                    >
                        <el-option
                            v-for="option in defaultOptions.one8OrderType"
                            class="select-danger"
                            :value="option.value"
                            :label="$t(option.label)"
                            :key="option.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <!-- Choose abnormal status -->
                <div class="col-md-3 mb-2">
                    <label for="">{{ $t("choose_abnormal_type") }}</label>

                    <el-select
                        clearable
                        class="d-block"
                        :placeholder="$t('choose_abnormal_type')"
                        v-model="filters.abnormal_status"
                        filterable
                        @change="abnormalStatusChange()"
                    >
                        <el-option
                            v-for="option in abNormalStatusOptions"
                            class="select-danger"
                            :value="option.value"
                            :label="$t(option.label)"
                            :key="option.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <!-- App last used at date filter -->
                <div class="col-md-3 mb-2" v-if="isShowAppLastOpenedDateFilter">
                    <label for="">{{ $t("last_opened_at_date") }}</label>

                    <flat-picker
                        :config="{
                            mode: 'range',
                            dateFormat: 'd-m-Y',
                            allowInput: true,
                            onClose: (dates, currentdatestring, picker) => {
                                dateFilterChange(
                                    dates,
                                    currentdatestring,
                                    picker,
                                    'last_opened_at_date_filter'
                                );
                            },
                        }"
                        class="form-control datepicker"
                        :placeholder="$t('last_opened_at_date')"
                        v-model="lastOpenedAtDate"
                    >
                    </flat-picker>
                </div>
                <!-- App last not used at date filter -->
                <div
                    class="col-md-3 mb-2"
                    v-if="isShowAppLastNotOpenedDateFilter"
                >
                    <label for="">{{ $t("last_opened_not_at_date") }}</label>

                    <flat-picker
                        :config="{
                            mode: 'range',
                            dateFormat: 'd-m-Y',
                            allowInput: true,
                            onClose: (dates, currentdatestring, picker) => {
                                dateFilterChange(
                                    dates,
                                    currentdatestring,
                                    picker,
                                    'last_opened_not_at_date_filter'
                                );
                            },
                        }"
                        class="form-control datepicker"
                        :placeholder="$t('last_opened_not_at_date')"
                        v-model="lastNotOpenedAtDate"
                    >
                    </flat-picker>
                </div>
            </div>
            <!-- Buttons  -->
            <div class="border-0 card-header px-0">
                <button
                    class="text-white btn btn-default btn-sm"
                    @click="sendNotificationModel()"
                    v-if="isShowTable"
                >
                    <i class="mr-2 text-white fas fa-plus-circle fa-lg">
                        <span>{{ $t("send_notification") }}</span>
                    </i>
                </button>

                <button
                    class="text-white btn btn-default btn-sm float-right"
                    @click="getOrderReport()"
                >
                    <i class="mr-2 text-white fas fa-file fa-lg">
                        <span>{{ $t("generateReport") }}</span>
                    </i>
                </button>
            </div>
            <!-- Table Section Starts -->
            <div class="row mt-2" v-if="isShowTable">
                <main
                    :class="
                        isFilter
                            ? 'col-md-9 col-xs-11 p-l-2 p-t-2'
                            : 'col-md-12 col-xs-11 p-l-2 p-t-2'
                    "
                >
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="usersOrders"
                            v-loading="isLoading"
                            @row-click="rowClicked"
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('name')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="mb-0 font-weight-600 name text-nowrap"
                                        >{{ row.name }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('mobile_no')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="mb-0 font-weight-600 name text-nowrap"
                                    >
                                        {{ row.country_code }}
                                        {{ row.mobile_no }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('total_no_of_order')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="mb-0 font-weight-600 name text-nowrap"
                                    >
                                        {{ row.order_count }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('actions')"
                                min-width="210px"
                            >
                                <template v-slot="{ row }">
                                    <div class="d-flex">
                                        <el-tooltip
                                            :content="$t('view')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                style="cursor: pointer"
                                                :title="$t('view')"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="'/customers/' + row.id"
                                                >
                                                    <img
                                                        src="@/assets/svg/view.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="p-0 pt-5 pb-5 card-footer"
                        v-if="usersOrders.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
        <!--  End of Table -->

        <!-- Modal  -->
        <modal
            :show="showModal"
            @close="showModal = false"
            size="sm"
            body-classes="p-0"
            v-loading="isLoading"
        >
            <template v-slot:header>
                <div class="text-bold text-lg text-center mt-2 mb-1">
                    {{ $t("send_notification") }}
                </div>
            </template>
            <div class="px-3 d-block">
                <Form
                    ref="sendNotificationRef"
                    @submit="sendNotification"
                    v-loading="isLoading"
                >
                    <div class="mb-3">
                        <span>
                            <strong>{{ $t("note") }}:</strong>
                            {{ $t("orderReportSendMessageNote") }}
                        </span>
                    </div>
                    <Field
                        name="notification_type"
                        rules="required"
                        v-model="notification_type"
                        v-slot="{ field }"
                        :label="$t('notification_type')"
                    >
                        <label for="" class="d-block">{{
                            $t("notification_type")
                        }}</label>
                        <el-select
                            v-bind="field"
                            class="d-block"
                            :placeholder="$t('select')"
                            name="notification_type"
                            v-model="notification_type"
                            filterable
                        >
                            <el-option
                                v-for="option in defaultOptions.sendNotificationType"
                                class="select-danger"
                                :value="option.value"
                                :label="$t(option.label)"
                                :key="option.label"
                            >
                            </el-option>
                        </el-select>
                        <ErrorMessage name="notification_type" class="error" />
                    </Field>
                    <label for="" class="my-1">{{
                        $t("notification_title")
                    }}</label>
                    <Field
                        type="text"
                        rules="required"
                        :label="$t('notification_title')"
                        class="form-control"
                        :placeholder="$t('notification_title')"
                        name="title"
                    />
                    <ErrorMessage name="title" class="error" />
                    <label for="" class="my-1">{{
                        $t("notification_content")
                    }}</label>

                    <Field
                        rules="required"
                        :label="$t('notification_content')"
                        class="form-control"
                        v-slot="{ field }"
                        :placeholder="$t('notification_content')"
                        name="content"
                    >
                        <textarea
                            name="content"
                            class="form-control"
                            rows="5"
                            :placeholder="$t('notification_content')"
                            v-bind="field"
                        ></textarea>
                    </Field>
                    <ErrorMessage name="content" class="error" />

                    <div class="w-full text-center mt-3 mb-4">
                        <base-button
                            :loading="showButtonLoader"
                            native-type="submit"
                            class="text-white bg-primary"
                        >
                            {{ $t("submit") }}
                        </base-button>
                        <input
                            type="button"
                            class="btn btn-default text-white"
                            @click="showModal = false"
                            :value="$t('close')"
                        />
                    </div>
                </Form>
            </div>
        </modal>
        <!-- End of Send Notification Modal -->
    </div>
</template>

<script>
import moment from "moment";
import mixin from "@/mixins/common";
import "flatpickr/dist/flatpickr.css";
import { get as getCities } from "@/api/cities";
import flatPicker from "vue-flatpickr-component";
import AlertModal from "@/components/common/AlertModal.vue";
import CustomLabel from "../../../components/common/CustomLabel.vue";
import DropDownFilter from "../../../components/common/DropDownFilter.vue";
import { getOrderReport, sendUserOrderReportNotification } from "@/api/users";

export default {
    data() {
        return {
            isShowTable: false,
            usersOrders: [],
            currentPage: 1,
            filters: {},
            order_filters: {
                order_date_filter: null,
                cancellation_reason: [],
                is_order_added: null,
                pickup_address_city: [],
                order_status: [],
                order_type: [],
                type: null,
                is_placed_order_after_cancelled_order: null,
                first_order_status: null,
            },
            providers: [],
            currentRow: {},
            isLoading: false,
            totalData: null,
            isFilter: false,
            meta: false,
            selectedDate: [],
            userSignUpDate: null,
            orderDateFilter: null,
            cities: [],
            isAr: localStorage.getItem("lang") == "ar",
            showModal: false,
            isShowAppLastOpenedDateFilter: false,
            isShowAppLastNotOpenedDateFilter: false,
            firstOrderOptions: [
                {
                    label: "completed",
                    value: "completed",
                },
                {
                    label: "cancelled",
                    value: "cancelled",
                },
            ],
            abNormalStatusOptions: [
                {
                    label: "active",
                    value: "active",
                },
                {
                    label: "inactive",
                    value: "inactive",
                },
                {
                    label: "notification_is_off",
                    value: "notification_is_off",
                },
            ],
        };
    },
    mixins: [mixin],
    created() {
        this.getCities();
    },
    components: {
        AlertModal,
        flatPicker,
        CustomLabel,
        DropDownFilter,
    },
    watch: {
        currentPage: {
            handler: function () {
                this.getOrderReport();
            },
        },
    },
    methods: {
        abnormalStatusChange() {
            if (this.filters.abnormal_status == "active") {
                this.isShowAppLastOpenedDateFilter = true;
            } else {
                this.isShowAppLastOpenedDateFilter = false;
                this.filters.last_opened_at_start_date = null;
                this.filters.last_opened_at_end_date = null;
            }

            if (this.filters.abnormal_status == "inactive") {
                this.isShowAppLastNotOpenedDateFilter = true;
            } else {
                this.isShowAppLastNotOpenedDateFilter = false;
                this.filters.last_opened_not_at_date = [];
            }
        },
        dateFilterChange(dates, currentdatestring, picker, type) {
            let start_date = "";
            let end_date = "";
            if (dates != null && dates.length == 2) {
                if (dates[0] != undefined) {
                    start_date = moment(dates[0]).format("YYYY-MM-DD");
                }
                if (dates[1] != undefined) {
                    end_date = moment(dates[1]).format("YYYY-MM-DD");
                }
            } else {
                start_date = "";
                end_date = "";
            }
            if (type == "order_date_filter") {
                this.order_filters.start_date = start_date;
                this.order_filters.end_date = end_date;
            }

            if (type == "signup_date_filter") {
                this.filters.start_date = start_date;
                this.filters.end_date = end_date;
            }

            if (type == "last_opened_at_date_filter") {
                this.filters.last_opened_at_start_date = start_date;
                this.filters.last_opened_at_end_date = end_date;
            }

            if (type == "last_opened_not_at_date_filter") {
                this.filters.last_opened_not_at_date =
                    start_date + " " + end_date;
            }
        },
        getOrderReport() {
            this.isShowTable = true;
            this.isLoading = true;
            getOrderReport({
                page: this.currentPage,
                filter: this.filters,
                order_filter: this.order_filters,
            })
                .then((res) => {
                    this.usersOrders = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        getCities() {
            getCities({ limit: -1 }).then((res) => {
                this.cities = res.data.map((item) => {
                    return {
                        title: this.isAr ? item.name_ar : item.name,
                        value: item.name_ar,
                        id: item.id,
                    };
                });
            });
        },
        sendNotificationModel() {
            this.showModal = true;
        },
        sendNotification(data) {
            if (this.usersOrders.length > 0) {
                this.showButtonLoader = true;
                sendUserOrderReportNotification(
                    { filter: this.filters, order_filter: this.order_filters },
                    data
                )
                    .then((res) => {
                        this.showButtonLoader = false;
                        this.$refs.sendNotificationRef.resetForm();

                        this.showModal = false;
                        this.runToast(res.message, "success");
                    })
                    .catch(({ response }) => {
                        this.showButtonLoader = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            } else {
                this.runToast(this.$t("orderReportUserNotFound"), "error");
            }
        },
    },
};
</script>
<style scoped lang="scss">
label {
    font-size: 15px;
}
</style>
