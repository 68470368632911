<template>
    <div>
        <hr />
        <div class="card py-3 px-5 rounded-md">
            <!-- Filter Section Start -->
            <div class="d-flex mb-4">
                <search-filter
                    :filters="[
                        {
                            id: 'user_name',
                            label: $t('name'),
                        },
                    ]"
                    @search="(searchObject) => (filters = searchObject)"
                />
            </div>
            <!-- Filter Section End  -->

            <div class="row">
                <main
                    :class="
                        isFilter
                            ? 'col-md-9 col-xs-11 p-l-2 p-t-2'
                            : 'col-md-12 col-xs-11 p-l-2 p-t-2'
                    "
                >
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="credit_histories"
                            v-loading="isLoading"
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('name')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <badge
                                        size="lg"
                                        class="bg-sky-dark cell"
                                        style="cursor: pointer"
                                        @click="viewUserDetail(row.user)"
                                    >
                                        <span
                                            class="font-weight-600 name mb-0 text-capitalize"
                                            >{{ row.user?.name }}</span
                                        >
                                    </badge>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('amount')"
                                min-width="100px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-nowrap"
                                    >
                                        {{ row.operated_credit }} SAR
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('reason')"
                                min-width="100px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-nowrap"
                                    >
                                        {{ row.reason }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('dateTime')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">{{
                                        row.created_at
                                    }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="credit_histories.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
        <!--  End of Table -->
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { collection } from "@/api/creditHistory";

export default {
    data() {
        return {
            credit_histories: [],
            notification_for: "",
            currentPage: 1,
            filters: {
                user_name: null,
                order_id: null,
            },
            showButtonLoader: false,
            isLoading: false,
            meta: {},
            totalData: null,
            isFilter: false,
        };
    },
    mixins: [mixin],
    created() {
        this.getCreditHistories();
    },
    components: {},
    watch: {
        currentPage: {
            handler: function () {
                this.getCreditHistories();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.currentPage = 1;
                this.getCreditHistories();
            },
        },
    },
    methods: {
        toggleFilter() {
            this.isFilter = !this.isFilter;
            if (this.isFilter) {
                $("#togglebar").addClass("show");
            } else {
                $("#togglebar").removeClass("show");
            }
        },
        getCreditHistories() {
            this.isLoading = true;
            let filters = this.filters;
            filters.type = "bonus";
            collection({
                include: "user",
                page: this.currentPage,
                filter: this.filters,
            })
                .then((res) => {
                    this.credit_histories = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                user_name: "",
                order_id: null,
                type: "bonus",
            };
        },
        viewUserDetail(user) {
            let routeData;
            if (user.role == "provider") {
                routeData = this.$router.resolve({
                    name: "Provider Details",
                    params: { id: user.id },
                });
            } else {
                routeData = this.$router.resolve({
                    name: "Customer Details",
                    params: { id: user.id },
                });
            }
            window.open(routeData.href, "_blank");
        },
    },
};
</script>
