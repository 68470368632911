<template>
    <div id="jq__map" class="map-canvas mt-3" style="height: 450px"></div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
const loader = new Loader({
    apiKey: "AIzaSyCl4Y0sPXxSSj-DZ2PJa20-5fmOLaDn7HI",
});

export default {
    props: {
        provider: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            map: "",
            directionsService: null,
            directionsDisplay: null,
        };
    },
    mounted() {
        loader.load().then(() => {
            this.initMap(this.provider);
        });
    },
    methods: {
        async initMap(location) {
            this.map = new google.maps.Map(document.getElementById("jq__map"), {
                zoom: 12,
                center: {
                    lat: location.current_lat,
                    lng: location.current_long,
                },
            });

            google.maps.event.trigger(this.map, "resize", {});
            this.map.setCenter(
                new google.maps.LatLng(
                    location.current_lat,
                    location.current_long
                )
            );
            this.map.setZoom(12);
            var bounds = new google.maps.LatLngBounds();

            var image =
                window.location.origin + "/img/provider-location-pin.png";
            var marker = new google.maps.Marker({
                position: {
                    lat: location.current_lat,
                    lng: location.current_long,
                },
                map: this.map,
                icon: image,
                animation: google.maps.Animation.DROP,
            });

            var infowindow = new google.maps.InfoWindow({
                content: location.name + "<br>" + location.location_updated_at,
            });
            marker.addListener("mouseover", function () {
                infowindow.open(this.map, marker);
            });
            marker.addListener("mouseout", function () {
                infowindow.close();
            });

            bounds.extend(marker.position);

            this.directionsService = new google.maps.DirectionsService();
            this.directionsDisplay = new google.maps.DirectionsRenderer();
            this.map.fitBounds(bounds);
            this.map.panToBounds(bounds);
        },
    },
};
</script>
