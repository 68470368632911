import Index from "@/views/Pages/StoreCredit/Index";
import CreditPaymentCallback from "@/views/Pages/StoreCredit/CreditPaymentCallback.vue";
import StoreCreditHistory from "@/views/Pages/StoreCreditHistory/List.vue";
import InvoiceHistory from "@/views/Pages/InvoiceHistory/List.vue";

export default [
    {
        path: "credit/add",
        components: { default: Index },
        name: "Add Credit",
        meta: {
            title: "add_credit",
            role: [4, 5],
        },
    },
    {
        path: "credit-payment/callback",
        component: CreditPaymentCallback,
        meta: {
            title: "credit_payment",
            role: [4, 5],
        },
    },
    {
        path: "credit-history",
        component: StoreCreditHistory,
        meta: {
            title: "credit_history",
            role: [1, 4, 9],
        },
    },
    {
        path: "invoice-history",
        component: InvoiceHistory,
        meta: {
            title: "invoice_history",
            role: [1, 4, 9],
        },
    },
];
