<template>
    <div>
        <hr />
        <div class="p-5 mb-0 card rounded-md">
            <p class="font-weight-600" v-if="this.id != 'add'">
                {{ $t("credit") }} : {{ credit }}
            </p>

            <h3>{{ $t(addOrEdit) }}</h3>

            <Form @submit="onSubmit" v-loading="isLoading" class="">
                <div class="row">
                    <div class="col-md-6">
                        <label for="">{{ $t("store_name") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            :placeholder="$t('store_name')"
                            :label="$t('store_name')"
                            name="name"
                            v-model="form.name"
                        />
                        <ErrorMessage name="name" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="status" class="form-control-label">{{
                            $t("shipment_type")
                        }}</label>
                        <Field
                            v-slot="{ field }"
                            name="shipment_type_id"
                            rules="required"
                            :label="$t('shipment_type')"
                            v-model="form.shipment_type_id"
                        >
                            <el-select
                                class="d-block"
                                name="shipment_type_id"
                                v-bind="field"
                                :placeholder="$t('select')"
                                v-model="form.shipment_type_id"
                                filterable
                            >
                                <el-option
                                    v-for="option in shipmentTypes"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.value"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage name="shipment_type_id" class="error" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="">{{ $t("contact_name") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            :placeholder="$t('contact_name')"
                            :label="$t('contact_name')"
                            name="contact_name"
                            v-model="form.contact_name"
                        />
                        <ErrorMessage name="contact_name" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="" class="my-1">{{ $t("mobile_no") }}</label>

                        <Field
                            type="number"
                            rules="required|length:9"
                            :label="$t('mobile_no')"
                            class="form-control"
                            :placeholder="$t('mobile_no')"
                            name="mobile_no"
                            v-model="form.mobile_no"
                        />
                        <ErrorMessage name="mobile_no" class="error" />
                    </div>
                </div>

                <div class="row mt-3" v-if="id === 'add'">
                    <div class="col-md-6">
                        <div>
                            <label for="">{{ $t("password") }}</label>
                            <Field
                                type="password"
                                rules="required|min:8"
                                :label="$t('password')"
                                class="form-control"
                                name="password"
                                v-model="form.password"
                            />
                            <ErrorMessage name="password" class="error" />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div>
                            <label for="">{{
                                $t("password_confirmation")
                            }}</label>
                            <Field
                                type="password"
                                rules="required|confirmed:@password"
                                :label="$t('password_confirmation')"
                                class="form-control"
                                name="password_confirmation"
                                v-model="password_confirmation"
                            />
                            <ErrorMessage
                                name="password_confirmation"
                                class="error"
                            />
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="" class="my-1">{{
                            $t("discount_percentage")
                        }}</label>

                        <Field
                            type="number"
                            rules="required|min_value:0|max_value:100"
                            :label="$t('discount_percentage')"
                            class="form-control"
                            :placeholder="$t('discount_percentage')"
                            name="discount_percentage"
                            v-model="form.discount_percentage"
                        />
                        <ErrorMessage
                            name="discount_percentage"
                            class="error"
                        />
                    </div>

                    <div class="col-md-6">
                        <label for="status" class="my-1">{{
                            $t("service_provider_company")
                        }}</label>
                        <Field
                            name="service_provider_company_ids"
                            :label="$t('service_provider_company')"
                            v-model="service_provider_company_ids"
                        >
                            <el-select
                                multiple
                                class="d-block el-multi-select-padding"
                                name="service_provider_company_ids"
                                :placeholder="$t('select')"
                                v-model="service_provider_company_ids"
                                filterable
                            >
                                <el-option
                                    v-for="option in serviceProviderCompanies"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.value"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage
                            name="service_provider_company_ids"
                            class="error"
                        />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6" v-if="id != 'add'">
                        <label for="status" class="my-1">{{
                            $t("status")
                        }}</label>
                        <Field
                            v-slot="{ field }"
                            name="status"
                            rules="required"
                            :label="$t('status')"
                            v-model="form.status"
                        >
                            <el-select
                                class="d-block"
                                name="status"
                                v-bind="field"
                                :placeholder="$t('select')"
                                v-model="form.status"
                                filterable
                            >
                                <el-option
                                    v-for="option in defaultOptions.storeStatus"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="$t(option.label)"
                                    :key="option.label"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage name="status" class="error" />
                    </div>

                    <!-- <div class="col-md-6">
                        <label for="" class="my-1 mr-2">{{
                            $t("order_pick_up_time")
                        }}</label>
                        <Field
                            v-model="form.order_pick_up_time"
                            class="form-control"
                            name="order_pick_up_time"
                            :label="$t('order_pick_up_time')"
                        >
                            <flat-picker
                                :config="{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: 'h:i K',
                                    defaultDate:
                                        form.order_pick_up_time || '5:00 PM',
                                }"
                                v-model="form.order_pick_up_time"
                                class="form-control datepicker"
                                name="order_pick_up_time"
                                :placeholder="$t('select')"
                            >
                            </flat-picker>
                        </Field>
                        <ErrorMessage name="order_pick_up_time" class="error" />
                    </div> -->
                </div>

                <div class="row mt-3">
                    <div class="col-md-3">
                        <label for="" class="my-1 mr-2">{{
                            $t("add_order_allowed")
                        }}</label>
                        <Field
                            name="add_order_allowed"
                            v-model="form.add_order_allowed"
                        >
                            <input
                                type="checkbox"
                                v-model="form.add_order_allowed"
                                name="add_order_allowed"
                            />
                        </Field>
                    </div>
                    <div class="col-md-3">
                        <label for="" class="my-1 mr-2">{{
                            $t("postpaid_order_enabled")
                        }}</label>
                        <Field
                            name="postpaid_order_enabled"
                            v-model="form.postpaid_order_enabled"
                        >
                            <input
                                type="checkbox"
                                v-model="form.postpaid_order_enabled"
                                name="postpaid_order_enabled"
                            />
                        </Field>
                    </div>

                    <div
                        class="col-md-6"
                        v-if="form.postpaid_order_enabled === true"
                    >
                        <label for="" class="my-1">{{
                            $t("max_postpaid_amount")
                        }}</label>

                        <Field
                            type="number"
                            rules="required"
                            :label="$t('max_postpaid_amount')"
                            class="form-control"
                            :placeholder="$t('max_postpaid_amount')"
                            name="max_postpaid_amount"
                            v-model="form.max_postpaid_amount"
                        />
                        <ErrorMessage
                            name="max_postpaid_amount"
                            class="error"
                        />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="" class="my-1">{{
                            $t("vehicle_type")
                        }}</label>

                        <Field
                            name="vehicle_type_ids"
                            :label="$t('vehicle_type')"
                            v-model="vehicle_type_ids"
                            rules="required"
                        >
                            <el-select
                                multiple
                                class="d-block el-multi-select-padding"
                                name="vehicle_type_ids"
                                :placeholder="$t('select')"
                                v-model="vehicle_type_ids"
                                filterable
                            >
                                <el-option
                                    v-for="option in vehicleTypes"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.value"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage name="vehicle_type_ids" class="error" />
                    </div>
                </div>

                <div class="w-full text-center mt-5">
                    <base-button
                        native-type="submit"
                        class="bg-primary text-white"
                        :loading="submitSpinner"
                    >
                        {{ id === "add" ? $t("create") : $t("update") }}
                    </base-button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import store from "@/store/index";
import mixin from "@/mixins/common";
// import "flatpickr/dist/flatpickr.css";
// import flatPicker from "vue-flatpickr-component";
import { add, edit, getStore } from "@/api/store";
import { getVehicleTypes } from "@/api/vehicleType";
import { getShipmentTypes } from "@/api/shipmentType";
import { getServiceProvider } from "@/api/serviceProviderCompanies";

export default {
    data() {
        return {
            addOrEdit: "",
            store: {},
            form: {},
            shipmentTypes: [],
            password_confirmation: null,
            serviceProviderCompanies: [],
            credit: null,
            status: "",
            isLoading: false,
            submitSpinner: false,
            service_provider_company_ids: [],
            selected_service_provider_companies: [],
            vehicleTypes: [],
            vehicle_type_ids: [],
        };
    },
    props: ["id"],
    mixins: [mixin],
    components: {
        // flatPicker,
    },
    created() {
        this.getShipmentTypeList();
        if (this.id === "add") {
            this.addOrEdit = this.$t("add_store");
            this.getVehicleType();
        } else {
            this.addOrEdit = this.$t("edit_store");
            this.getStoreDetails();
        }
        this.getServiceProviderCompanies();
    },

    methods: {
        getServiceProviderCompanies() {
            getServiceProvider({})
                .then((res) => {
                    res.data.forEach((item) => {
                        this.serviceProviderCompanies.push({
                            label: item.name,
                            value: item.id,
                        });
                    });
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getStoreDetails() {
            this.isLoading = true;
            getStore(this.id, {
                include:
                    "user,shipment_type,service_provider_companies,vehicle_types",
            })
                .then((res) => {
                    this.isLoading = false;
                    this.store = res;
                    this.credit = res.credit;
                    this.form = res;
                    this.form.contact_name = res.user?.name;
                    this.form.mobile_no = res.user?.mobile_no;
                    this.status = res.status;

                    res.service_provider_companies.forEach((item, index) => {
                        this.service_provider_company_ids.push(item.id);
                        // this.selected_service_provider_companies.push({
                        //     currentLabel: item.name,
                        //     value: item.id,
                        // });
                    });

                    res.vehicle_types.forEach((item, index) => {
                        this.vehicle_type_ids.push(item.id);
                    });
                    this.getVehicleType();
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getShipmentTypeList() {
            getShipmentTypes({
                limit: -1,
                filter: {
                    status: "active",
                },
            })
                .then((res) => {
                    res.data.forEach((item) => {
                        this.shipmentTypes.push({
                            value: item.id,
                            label:
                                this.$store.getters.getLang === "ar"
                                    ? item.type_ar
                                    : item.type,
                        });
                    });
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getVehicleType() {
            getVehicleTypes({
                limit: -1,
                filter: {
                    status: "active",
                },
            })
                .then((res) => {
                    res.data.forEach((item) => {
                        this.vehicleTypes.push({
                            value: item.id,
                            label:
                                this.$store.getters.getLang === "ar"
                                    ? item.type_ar
                                    : item.type,
                        });
                    });
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        async onSubmit(data) {
            data[
                "service_provider_company_ids"
            ] = this.service_provider_company_ids;
            data["vehicle_type_ids"] = this.vehicle_type_ids;

            this.submitSpinner = true;
            if (this.id == "add") {
                data["postpaid_order_enabled"] = 0;
                add(data)
                    .then((res) => {
                        this.submitSpinner = false;
                        this.$router.push({ name: "Stores List" });
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            } else {
                edit(this.id, data)
                    .then((res) => {
                        this.submitSpinner = false;
                        this.$router.push({ name: "Stores List" });
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            }
        },
    },
};
</script>

<style lang="scss">
.el-select__tags-text {
    overflow: visible !important;
}
span.el-tag.el-tag--info.el-tag--small.el-tag--light {
    margin-left: 3px !important;
    // padding: 5px;
    height: auto;
}
.el-multi-select-padding .select-trigger .el-select__tags {
    margin: -2px;
}
</style>
