<template>
    <div>
        <div class="bg-white rounded-md p-3 position-relative">
            <modal
                footerClasses="justify-content-center"
                :show="showModal"
                @close="$emit('close')"
                size="lg"
                body-classes="p-0"
            >
                <template v-slot:header>
                    <div class="deliver">
                        <h2 class="block text-center">
                            {{ $t("proof_of_delivery.title") }}
                        </h2>
                        <div class="deliver__header">
                            <h3 class="deliver__header--name">
                                <span
                                    >{{
                                        $t(
                                            "proof_of_delivery.welcome_customer"
                                        ) +
                                            ", " +
                                            packageOrder?.customer?.name || ""
                                    }}
                                </span>
                            </h3>
                            <p class="deliver__text deliver__header-text">
                                <span>
                                    {{
                                        $t(
                                            "proof_of_delivery.proof_of_delivery_text"
                                        ) + ": #"
                                    }}
                                    {{ packageOrder?.order_no || "" }}
                                </span>
                            </p>
                        </div>
                        <div class="deliver__content">
                            <div class="deliver__content-header">
                                <h3 class="deliver__subtitle">
                                    {{
                                        $t(
                                            "proof_of_delivery.delivery_information"
                                        )
                                    }}
                                </h3>
                            </div>
                            <div class="deliver__content-detail">
                                <ul class="deliver__list">
                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{
                                                $t(
                                                    "proof_of_delivery.customer_mobile"
                                                )
                                            }}
                                        </p>
                                    </li>
                                    <li class="deliver__list-item">
                                        <p
                                            class="deliver__text"
                                            v-if="
                                                packageOrder?.customer
                                                    ?.mobile_no
                                            "
                                        >
                                            {{ "+" }}
                                            {{
                                                packageOrder?.customer
                                                    ?.country_code || ""
                                            }}
                                            {{
                                                packageOrder?.customer
                                                    ?.mobile_no
                                            }}
                                        </p>
                                    </li>
                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{ $t("customer_district") }}
                                        </p>
                                    </li>
                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{
                                                packageOrder?.customer
                                                    ?.get_district?.name || ""
                                            }}
                                        </p>
                                    </li>
                                </ul>
                                <ul class="deliver__list">
                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{
                                                $t(
                                                    "proof_of_delivery.no_of_pieces"
                                                )
                                            }}
                                        </p>
                                    </li>
                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{ packageOrder?.no_of_pieces }}
                                        </p>
                                    </li>

                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{
                                                $t(
                                                    "proof_of_delivery.order_status_label"
                                                )
                                            }}
                                        </p>
                                    </li>
                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{
                                                $t(
                                                    "proof_of_delivery.order_status"
                                                )
                                            }}
                                        </p>
                                    </li>
                                </ul>
                                <ul class="deliver__list">
                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{
                                                $t(
                                                    "proof_of_delivery.delivery_date"
                                                )
                                            }}
                                        </p>
                                    </li>
                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{
                                                packageOrder?.delivery_date ||
                                                ""
                                            }}
                                        </p>
                                    </li>
                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{
                                                $t(
                                                    "proof_of_delivery.delivery_time"
                                                )
                                            }}
                                        </p>
                                    </li>
                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{ packageOrder?.delivery_time }}
                                        </p>
                                    </li>
                                </ul>
                                <ul class="deliver__list">
                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{
                                                $t(
                                                    "proof_of_delivery.provider_name"
                                                )
                                            }}
                                        </p>
                                    </li>
                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{
                                                orderDetail?.provider?.name ||
                                                ""
                                            }}
                                        </p>
                                    </li>
                                    <li class="deliver__list-item">
                                        <p class="deliver__text">
                                            {{
                                                $t(
                                                    "proof_of_delivery.provider_mobile"
                                                )
                                            }}
                                        </p>
                                    </li>
                                    <li class="deliver__list-item">
                                        <p
                                            class="deliver__text"
                                            v-if="
                                                orderDetail?.provider?.mobile_no
                                            "
                                        >
                                            {{ "+" }}
                                            {{
                                                orderDetail?.provider
                                                    ?.country_code || ""
                                            }}
                                            {{
                                                orderDetail?.provider?.mobile_no
                                            }}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div class="deliver__content-footer">
                                <base-button
                                    native-type="submit"
                                    class=""
                                    @click="download"
                                >
                                    {{ $t("proof_of_delivery.download_btn") }}
                                </base-button>
                                <button
                                    class="btn btn-default text-white"
                                    @click="$emit('close')"
                                >
                                    {{ $t("close") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </modal>
        </div>
        <!-- 29th dec --->
    </div>
</template>
<script>
import mixin from "@/mixins/common";
import { getOrder } from "@/api/orders";
export default {
    data() {
        return {
            showButtonLoader: false,
            packageOrder: {},
            orderDetail: {},
            downloadIsLoading: false,
        };
    },
    mixins: [mixin],
    props: ["showModal", "order", "packageOrderId"],
    watch: {
        showModal: {
            handler: function (newVal, oldVal) {
                if (newVal === true) {
                    this.getProofOfDeliveryDetail();
                }
            },
        },
    },
    methods: {
        async getProofOfDeliveryDetail() {
            console.log("Inside proof of delivery");
            var orderId = this.order.id;
            var packageOrderId = this.packageOrderId;
            if (this.order.type == "Package" && this.order.parent_id != null) {
                orderId = this.order.parent_id;
                packageOrderId = this.order.id;
            }
            await getOrder(orderId, {
                include: "provider,customer,packages,packages.customer",
                store_id: this.storeId ? this.storeId : null,
            })
                .then(async (res) => {
                    this.orderDetail = res;
                    if (res.type == "Normal") {
                        this.packageOrder = res;
                    } else {
                        this.packageOrder = res.packages.filter(
                            (packageOrder) => {
                                return packageOrder.id == packageOrderId;
                            }
                        )[0];
                    }
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        download() {
            if (this.packageOrder.proof_of_delivery_url) {
                window.open(this.packageOrder.proof_of_delivery_url, "_blank");
            }
        },
    },
};
</script>
