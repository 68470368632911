<template>
    <side-bar :userName="user.name">
        <template v-slot:links>
            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/dashboard-icon.svg')"
                :link="{
                    name: $t('dashboard'),
                    path: '/dashboard?status=live',
                }"
            />
            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/shopping-cart.svg')"
                v-if="hasRole(4) || hasRole(5) || hasRole(8)"
                :link="{
                    name: $t('orders'),
                    path: '/orders',
                }"
            />
            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/location.svg')"
                v-if="hasRole(4)"
                :link="{
                    name: $t('addresses'),
                    // below path has / in start because without it like if url was /credit/add before then when navigatiing inside addreess menu url gets /credit/addresses that is wrong therefore specifying absolute url
                    path: '/addresses',
                }"
            />
            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/message-question.svg')"
                v-if="hasRole(8)"
                :link="{
                    name: $t('complaints'),
                    path: '/complaints',
                }"
            />

            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/shop.svg')"
                v-if="hasRole(7)"
                :link="{
                    name: $t('stores'),
                    path: '/stores',
                }"
            />
            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/shopping-cart.svg')"
                v-if="hasRole(7) || hasRole(9)"
                :link="{
                    name: $t('store_orders'),
                    path: '/store-orders',
                }"
            />

            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/message-question.svg')"
                v-if="hasRole(7)"
                :link="{
                    name: $t('business_complaints'),
                    path: '/business-complaints',
                }"
            />

            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/users.svg')"
                v-if="hasRole(8) || hasRole(9)"
                :link="{
                    name: $t('providers'),
                    path: '/providers',
                }"
            />

            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/people.svg')"
                v-if="hasRole(4) && !isZidshipStore()"
                :link="{
                    name: $t('users'),
                    path: '/users',
                }"
            />
            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/receipt.svg')"
                v-if="hasRole(4)"
                :link="{
                    name: $t('price_calculator'),
                    path: '/price-calculator',
                }"
            />
            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/wallet-add.svg')"
                v-if="hasRole(4) || hasRole(5)"
                :link="{
                    name: $t('charge_credit'),
                    path: '/credit/add',
                }"
            />
            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/invoice.svg')"
                v-if="hasRole(4) || hasRole(9)"
                :link="{
                    name: $t('invoice_history'),
                    path: '/invoice-history',
                }"
            />
            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/receipt.svg')"
                v-if="hasRole(4) || hasRole(9)"
                :link="{
                    name: $t('credit_history'),
                    path: '/credit-history',
                }"
            />
            <sidebar-item
                v-if="hasRole(8)"
                :svg="true"
                :svgPath="require('@/assets/svg/credit-card.svg')"
                :link="{
                    name: $t('wallet_history'),
                    path: '/wallet-histories',
                }"
            />

            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/setting-2.svg')"
                :link="{
                    name: $t('user_settings'),
                    icon: 'fas fa-cog text-default',
                    path: '/user-setting',
                }"
            />
            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/logout.svg')"
                :link="{
                    name: $t('logout'),
                    icon: 'fas fa-sign-out-alt text-default',
                    path: '/log-out',
                }"
                @click.prevent="$emit('logout')"
            />
        </template>
    </side-bar>
</template>
<script>
import { mapGetters } from "vuex";
import mixin from "@/mixins/common";

export default {
    data() {
        return {};
    },
    mixins: [mixin],
    computed: {
        ...mapGetters(["user"]),
    },
    components: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>
