import { me } from "@/api/users";
import { useToast } from "vue-toastification";
import { creditForPlaceOrder } from "@/api/store";
import defaultOptions from "@/data/defaultOptions";
export default {
    data() {
        return {
            defaultOptions,
            getLang: this.$store.getters.getLang,
            userRole: this.$store.getters.user.role_id,
            storeId: this.$store.getters.store
                ? this.$store.getters.store.id
                : null,
            serviceProviderCompanyId: this.$store.getters.serviceProviderCompany
                ? this.$store.getters.serviceProviderCompany.id
                : null,
            isShowNavAlertBar: false,
            statusColor: {
                user: {
                    approved: "#00929B",
                    pending: "#B0A115",
                    rejected: "#EF2323",
                    suspended: "#7368FB",
                },
                order: {
                    Requested: "#B0A115",
                    Accepted: "#197EF4",
                    Confirmed: "#197EF4",
                    "On The Way": "#7368FB",
                    "to the store": "#7368FB",
                    "at the store": "#7368FB",
                    "Picked Up": "#B6A82D",
                    "Dropped Off": "#EF6C23",
                    Returned: "#FA6800",
                    Completed: "#00929B",
                    Cancelled: "#FF0000",
                },
                payment: {
                    pending: "#FFA000",
                    completed: "#689F38",
                },
                offer: {
                    Opened: "#FFA000",
                    Accepted: "#007bff",
                    Rejected: "#FF0000",
                },
                status: {
                    active: "#689F38",
                    inactive: "#FF0000",
                },
                complaint: {
                    pending: "#FFA000",
                    completed: "#689F38",
                },
                paymentStatus: {
                    initiated: "#FFA000",
                    paid: "#689F38",
                    pending: "#689F38",
                    failed: "#FF0000",
                },
                businessOrder: {
                    allow: "#689F38",
                    disallow: "#FF0000",
                    true: "#689F38",
                    false: "#FF0000",
                },
                stcUpdated: {
                    true: "#689F38",
                    false: "#FF0000",
                },
            },
        };
    },
    created() { },
    methods: {
        // general method for notifying user with Toast Notification, can be accessed into entire project without importing it.
        runToast(text, type) {
            const toast = useToast();
            toast(text, { hideProgressBar: true, type: type });
        },
        // Below Method returns different color based on status value used for applying background color to badges
        getUserStatusColor(status) {
            return this.statusColor.user[status];
        },
        getComplaintStatusColor(status) {
            return this.statusColor.complaint[status];
        },
        getOrdersStatusColor(status) {
            return this.statusColor.order[status];
        },
        getStatusBgColor(hex) {
            let c;
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split("");
                if (c.length == 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = "0x" + c.join("");
                return (
                    "rgba(" +
                    [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
                    ",0.08)"
                );
            }
        },
        getStcUpdatedColor(status) {
            status = status == true ? "true" : "false";
            return this.statusColor.stcUpdated[status];
        },

        getBusinessOrderColor(status) {
            status = status == true ? "true" : "false";
            return this.statusColor.businessOrder[status];
        },
        // apiError is a method that takes errorObject from axios api and handles the catch block , notifies all the errors got from api
        apiError(errorObject) {
            if (errorObject) {
                this.isLoading = false;
                let error = errorObject.data.errors;

                if (!error) {
                    this.runToast(this.$t("apiError"), "error");
                    return true;
                }
                let err = "";
                for (let key in error) {
                    err += error[key] + "\n";
                }

                this.runToast(err, "error");
            }
        },
        cancelOrderAllowed(status) {
            let userRole = this.$store.getters.user.role_id;
            return (
                status != "Cancelled" &&
                status != "Completed" &&
                (userRole == 1 ||
                    userRole == 6 ||
                    userRole == 4 ||
                    userRole == 5)
            );
        },
        markOrderStatusToPendingAllowed(status) {
            return (
                (this.$store.getters.user.role_id == 1 ||
                    this.$store.getters.user.role_id == 6) &&
                status == "Confirmed"
            );
        },
        getStatusColor(status) {
            return this.statusColor.status[status];
        },
        storeCredit() {
            let credit = 0;
            if (
                this.$store.getters.user.role_id == 4 ||
                this.$store.getters.user.role_id == 5
            ) {
                credit = this.$store.getters.store?.credit || 0;
            }
            if (
                this.$store.getters.user.role_id == 8 ||
                this.$store.getters.user.role_id == 9
            ) {
                credit = this.$store.getters.user?.credit;
            }
            return credit;
        },
        hasRole(role) {
            return this.$store.getters.user.role_id == role;
        },
        getLang() {
            return this.$store.getters.lang;
        },
        storePendingCredit() {
            let credit = 0;
            if (
                this.$store.getters.user.role_id == 4 ||
                this.$store.getters.user.role_id == 5
            ) {
                credit = this.$store.getters.store?.pending_credit || 0;
            }
            if (
                this.$store.getters.user.role_id == 8 ||
                this.$store.getters.user.role_id == 9
            ) {
                credit = this.$store.getters.user?.credit;
            }
            return credit;
        },
        updateStoreCredit() {
            me().then((res) => {
                this.$store.dispatch(
                    "setStoreCredit",
                    res.user?.stores[0]?.credit
                        ? res.user?.stores[0]?.credit
                        : 0
                );
            });
        },
        isZidshipStore() {
            let userRole = this.$store.getters.user.role_id;
            if (userRole == 4 && this.$store.getters.store.source == 'zidship_store') {
                return true;
            } else {
                return false;
            }
        },
        // Check the credit is available for the 2 orders other wise show alert
        creditForPlaceOrder(storeId) {
            if (this.hasRole(4)) {
                creditForPlaceOrder({
                    store_id: storeId,
                })
                    .then((data) => {
                        if (data.message) {
                            this.$emitter.emit("show-credit-alert", { message: data.message });
                        }
                    })
                    .catch(({ response }) => {
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            }
        },
    },
};
