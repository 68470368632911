<template>
    <div>
        <hr />
        <div class="card py-5 rounded-md">
            <div class="row">
                <main
                    :class="
                        isFilter
                            ? 'col-md-9 col-xs-11 p-l-2 p-t-2'
                            : 'col-md-12 col-xs-11 p-l-2 p-t-2'
                    "
                >
                    <el-table
                        class="table-responsive table-flush"
                        header-row-class-name="thead-light"
                        :data="systemSetting"
                        cell-class-name="text-break"
                        v-loading="isLoading"
                        :empty-text="$t('no_data_found')"
                    >
                        <el-table-column :label="$t('name')" min-width="300px">
                            <template v-slot="{ row }">
                                <span class="mb-0 font-weight-600 name">{{
                                    $t(`settings_page.${row.key}`)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            :label="$t('settings_page.value')"
                            min-width="150px"
                            prop="value"
                        >
                            <template v-slot="{ row }">
                                <span
                                    class="mb-0 font-weight-600 name text-nowrap"
                                    v-if="row.key == 'order_enabled'"
                                    >{{
                                        row.key ? $t("true") : $t("false")
                                    }}</span
                                >
                                <span
                                    class="mb-0 font-weight-600 name text-nowrap"
                                    v-else
                                    >{{ row.value }}</span
                                >
                            </template>
                        </el-table-column>

                        <el-table-column min-width="150px"> </el-table-column>

                        <el-table-column
                            :label="$t('actions')"
                            min-width="210px"
                        >
                            <template v-slot="{ row }">
                                <system-setting-edit-modal
                                    :title="$t('update_setting')"
                                    :id="row.id"
                                    @click="modalUpdateHandler(row)"
                                    @systemSettingEditCompleted="
                                        getSystemSettings
                                    "
                                >
                                    <template v-slot:default="{ clickEvent }">
                                        <el-tooltip
                                            :content="$t('edit')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="cursor-pointer bg-sky-dark text-secondary"
                                                :title="$t('edit')"
                                                style="cursor: pointer"
                                                @click="clickEvent"
                                            >
                                                <i class="mx-1 fas fa-pen"></i>
                                            </badge>
                                        </el-tooltip>
                                    </template>
                                </system-setting-edit-modal>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="p-0 pt-5 pb-5 card-footer">
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import { getSettings, getSystem } from "@/api/systemSetting";
import SystemSettingEditModal from "@/components/common/SystemSettingEditModal.vue";

export default {
    data() {
        return {
            systemSetting: [],
            user: null,
            isLoading: false,
            meta: {},
            currentPage: 1,
            isOpen: false,
            params: {
                role: "settings",
            },
            currentRow: {},
            totalData: null,
            isFilter: false,
            role: "",
            filters: {
                // name: "",
                // value: "",
            },
        };
    },

    components: {
        SystemSettingEditModal,
    },

    created() {
        this.getSystemSettings();
    },

    watch: {
        currentPage: {
            handler: function () {
                this.getSystemSettings();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.currentPage = 1;
                this.getSystemSettings();
            },
        },
    },

    methods: {
        modalUpdateHandler(row) {
            // getSystem(row.id).then((res) => {
            //     this.currentRow = res;
            this.isOpen = true;
            //});
        },

        nameUppercase(key) {
            var i,
                key = key.split("_");
            for (i = 0; i < key.length; i++) {
                key[i] = key[i].charAt(0).toUpperCase() + key[i].slice(1);
            }
            return key.join(" ");
        },

        toggleFilter() {
            this.isFilter = !this.isFilter;
            if (this.isFilter) {
                $("#togglebar").addClass("show");
            } else {
                $("#togglebar").removeClass("show");
            }
        },

        getSystemSettings() {
            this.isLoading = true;
            getSettings({
                ...this.params,
                page: this.currentPage,
                filter: this.filters,
            })
                .then((res) => {
                    this.systemSetting = res.data;
                    this.meta = res.meta;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },

        resetFilter() {
            this.filters = {
                name: "",
                value: "",
            };
            this.role = "";
        },
    },
};
</script>
