<template>
    <div
        id="jq__address-map"
        class="map-canvas mt-3"
        style="height: 450px"
    ></div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
const loader = new Loader({
    apiKey: "AIzaSyCl4Y0sPXxSSj-DZ2PJa20-5fmOLaDn7HI",
});

export default {
    props: {
        lat: {
            type: [Number, String],
            required: true,
            default: 24.774265,
        },
        lon: {
            type: [Number, String],
            required: true,
            default: 46.738586,
        },
        zoom_level: {
            default: 11,
        },
    },
    data() {
        return {
            map: "",
        };
    },
    mounted() {
        loader.load().then(() => {
            this.initMap(this.lat, this.lon);
        });
    },
    watch: {
        lat: {
            handler: function () {
                this.initMap(this.lat, this.lon);
            },
        },
    },
    methods: {
        initMap(latitude, longitude) {
            let lat = latitude;
            let long = longitude;
            if (lat == null || lat == "") lat = 24.774265;
            if (long == null || long == "") long = 46.738586;

            this.map = new google.maps.Map(
                document.getElementById("jq__address-map"),
                {
                    zoom: this.zoom_level,
                    center: {
                        lat: lat,
                        lng: long,
                    },
                    disableDoubleClickZoom: true,
                    // restriction: {
                    //     latLngBounds: {
                    //         north: 25.1564724,
                    //         south: 24.2939113,
                    //         east: 47.34695430000001,
                    //         west: 46.2981033,
                    //     },
                    //     strictBounds: false,
                    // },
                }
            );

            var marker;
            marker = new google.maps.Marker({
                position: {
                    lat: parseFloat(lat),
                    lng: parseFloat(long),
                },
                map: this.map,
                draggable: this.readonly ? false : true,
            });

            if (!this.readonly) {
                new google.maps.event.addListener(
                    marker,
                    "dragend",
                    (event) => {
                        this.$emit("locationChanged", {
                            latitude: event.latLng.lat(),
                            longitude: event.latLng.lng(),
                        });
                    }
                );

                new google.maps.event.addListener(this.map, "drag", () => {
                    this.$emit("locationChanged", {
                        latitude: marker.getPosition().lat(),
                        longitude: marker.getPosition().lng(),
                    });
                });

                new google.maps.event.addListener(
                    this.map,
                    "dragend",
                    function () {
                        marker.setPosition(this.getCenter());
                    }
                );

                new google.maps.event.addListener(
                    this.map,
                    "click",
                    (event) => {
                        marker.setMap(null);
                        marker = new google.maps.Marker({
                            position: {
                                lat: event.latLng.lat(),
                                lng: event.latLng.lng(),
                            },
                            map: this.map,
                        });
                        this.$emit("locationChanged", {
                            latitude: event.latLng.lat(),
                            longitude: event.latLng.lng(),
                        });
                    }
                );
            }
        },
    },
};
</script>
