import axiosInstance from "@/plugins/customAxios";

export const get = (params = null) => {
    return axiosInstance
        .get("v1/notifications", { params: params })
        .then((res) => {
            return res.data;
        });
};

export const read = (id = '') => {
    return axiosInstance
        .put(`v1/notifications/read/${id}`)
        .then((res) => {
            return res.data
        });
};

export const unreadCount = () => {
    return axiosInstance
        .get(`v1/notifications/unread-count`)
        .then((res) => {
            return res.data
        });
};

