<template>
    <div class="d-inline">
        <div>
            <modal
                :show="showModal"
                @close="$emit('close')"
                size="sm"
                body-classes="p-0"
            >
                <template v-slot:header>
                    <div class="mt-2 mb-1 text-lg text-center text-bold">
                        {{ title }}
                    </div>
                </template>

                <div class="px-3 pb-3 d-block">
                    <Form @submit="showConfirmModel" ref="fineCompensationForm">
                        <div>
                            <label for="amount" class="my-1">{{
                                $t("amount")
                            }}</label>
                            <Field
                                name="amount"
                                :label="$t('amount')"
                                v-model="amount"
                                rules="required"
                            >
                                <el-select
                                    class="d-block"
                                    name="amount"
                                    :placeholder="$t('select')"
                                    v-model="amount"
                                >
                                    <el-option
                                        v-for="option in amountOptions"
                                        class="select-danger"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="option.value"
                                    >
                                    </el-option>
                                </el-select>
                            </Field>
                            <ErrorMessage name="amount" class="error" />
                        </div>
                        <div>
                            <label for="reason">{{ $t("reason") }}</label>
                            <Field
                                name="reason"
                                rules="required"
                                :label="$t('reason')"
                                v-slot="{ field }"
                            >
                                <textarea
                                    name="reason"
                                    id="reason"
                                    :placeholder="$t('reason')"
                                    class="form-control mb-3"
                                    rows="3"
                                    v-bind="field"
                                ></textarea>
                            </Field>
                            <ErrorMessage name="reason" class="error" />
                        </div>
                        <div class="w-full mt-5 text-center">
                            <base-button
                                native-type="submit"
                                class="text-white bg-primary"
                            >
                                {{ $t("submit") }}
                            </base-button>
                            <input
                                type="button"
                                class="btn btn-default text-white"
                                @click="$emit('close')"
                                :value="$t('close')"
                            />
                        </div>
                    </Form>
                </div>
            </modal>
            <modal
                footerClasses="justify-content-center"
                :show="displayConfirmModel"
                @close="displayConfirmModel = false"
                size="sm"
                body-classes="p-0"
                v-loading="submitSpinner"
            >
                <template v-slot:header>
                    <div>
                        <img
                            src="@/assets/svg/confirm-img.svg"
                            alt=""
                            style="width: 150px; height: 150px"
                        />
                        <div class="text-bold text-lg text-center mt-2 mb-1">
                            {{
                                type == "compensation"
                                    ? $t("compensation_confirm_title")
                                    : $t("fine_confirm_title")
                            }}
                        </div>
                    </div>
                </template>
                <div class="px-3 d-block text-center">
                    <h2 class="text-break font-weight-900">
                        {{
                            type == "compensation"
                                ? $t("compensation_confirm_body")
                                : $t("fine_confirm_body")
                        }}
                    </h2>
                </div>
                <template v-slot:footer>
                    <div class="w-full text-center mt-3">
                        <base-button
                            @click.native="submitForm()"
                            :loading="submitSpinner"
                        >
                            {{ $t("yes") }}</base-button
                        >
                        <button
                            class="btn btn-default text-white"
                            data-dismiss="modal"
                            @click="close()"
                        >
                            {{ $t("no") }}
                        </button>
                    </div>
                </template>
            </modal>
        </div>
    </div>
</template>

<script>
import { addCreditHistory } from "@/api/creditHistory";
import mixin from "@/mixins/common";

export default {
    props: {
        orderId: {
            type: Number,
            default: () => {},
        },
        type: {
            type: String,
            default: () => {},
        },
        title: {
            type: String,
            default: () => {},
        },
        showModal: {
            type: Boolean,
            default: () => {},
        },
        compensationTo: {
            type: String,
            default: () => {},
        },
    },
    data() {
        return {
            amount: null,
            reason: null,
            submitSpinner: false,
            displayConfirmModel: false,
            amountOptions: [
                {
                    label: "10 SAR",
                    value: 10,
                },
                {
                    label: "15 SAR",
                    value: 15,
                },
                {
                    label: "25 SAR",
                    value: 25,
                },
            ],
            formData: null,
        };
    },
    mixins: [mixin],
    computed: {},
    watch: {
        showModal(val) {
            this.amount = null;
            this.$refs.fineCompensationForm.resetForm();
        },
    },
    created() {},
    methods: {
        showConfirmModel(data) {
            this.formData = data;
            this.displayConfirmModel = true;
        },
        submitForm() {
            var data = this.formData;
            data.type = this.type;
            data.order_id = this.orderId;
            data.compensation_to = this.compensationTo;
            this.submitSpinner = true;
            addCreditHistory(data)
                .then(() => {
                    this.submitSpinner = false;
                    this.$emit("close");
                    this.displayConfirmModel = false;
                    this.runToast(
                        this.type == "compensation"
                            ? this.$t("compensation_add_success")
                            : this.$t("fine_deduct_success"),
                        "success"
                    );
                })
                .catch(({ response }) => {
                    this.displayConfirmModel = false;
                    this.submitSpinner = false;
                    this.apiError(response);
                });
        },
        close() {
            this.displayConfirmModel = false;
            this.$emit("close");
        },
    },
};
</script>
