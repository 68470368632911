<template>
    <div class="card rounded-md">
        <tabs
            fill
            class="flex-column flex-md-row rounded-md"
            tabNavClasses="mb-3 mt-4 "
            @tabClicked="setDate"
        >
            <tab-pane
                id="today"
                :title="$t('information_dashboard.today')"
                class=""
            >
            </tab-pane>
            <tab-pane
                id="yesterday"
                :title="$t('information_dashboard.yesterday')"
                class=""
            >
            </tab-pane>
            <tab-pane
                id="this_week"
                :title="$t('information_dashboard.this_week')"
                class=""
            >
            </tab-pane>
            <tab-pane
                id="this_month"
                :title="$t('information_dashboard.this_month')"
                class=""
            >
            </tab-pane>
            <tab-pane
                id="this_year"
                :title="$t('information_dashboard.this_year')"
                class=""
            >
            </tab-pane>
            <tab-pane
                id="all"
                :title="$t('information_dashboard.all')"
                class=""
            >
            </tab-pane>
            <div class="row">
                <!-- Order status -->
                <div class="col dashbord-cards">
                    <div
                        class="card cards-border-bottom p-2"
                        style="cursor: pointer"
                    >
                        <h3
                            class="card-title text-uppercase text-muted mt-2 mb-0"
                        >
                            {{ $t("orders") }}
                        </h3>
                        <span class="font-weight-700 h1">
                            {{ ordersCount?.total_order || 0 }}</span
                        >
                    </div>

                    <div
                        class="card rounded-md p-2"
                        v-if="ordersCount.total_order > 0"
                    >
                        <ul class="list-group">
                            <li
                                class="list-group-item list-card-item"
                                v-for="(
                                    statusCount, index
                                ) in ordersCount.status_count"
                                :key="index"
                            >
                                <div class="row">
                                    <div class="col">
                                        {{ statusCount.title }}
                                    </div>
                                    <div class="col">
                                        {{ statusCount.value }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Revenue -->
                <div class="col dashbord-cards" v-if="cardVisible('revenue')">
                    <div
                        class="card cards-border-bottom p-2"
                        style="cursor: pointer"
                    >
                        <h3
                            class="card-title text-uppercase text-muted mt-2 mb-0"
                        >
                            {{ $t("dashboard_page.revenue") }}
                        </h3>
                        <span class="font-weight-700 h1">
                            {{ totalRevenueCount?.revenue || 0 }}
                            {{ $t("SAR") }}</span
                        >
                    </div>
                    <div
                        class="card rounded-md p-2"
                        v-if="totalRevenueCount.revenue > 0"
                    >
                        <ul class="list-group">
                            <h3 class="card-title">
                                {{ $t("revenueBreakdown") }}
                            </h3>
                            <li
                                class="list-group-item list-card-item"
                                v-for="(
                                    item, key
                                ) in totalRevenueCount?.payment_revenue"
                                :key="key"
                            >
                                <div class="row">
                                    <div class="col">
                                        {{ $t(key) }}
                                    </div>
                                    <div class="col">
                                        {{ item.toFixed(2) || 0 }}
                                        {{ $t("SAR") }}
                                    </div>
                                </div>
                            </li>
                            <div class="row font-weight-700 mt-2">
                                <div class="col total-revenue">
                                    {{ $t("total") }}
                                </div>
                                <div class="col text-right">
                                    {{ totalRevenueCount?.revenue || 0 }}
                                    {{ $t("SAR") }}
                                </div>
                            </div>
                        </ul>

                        <ul class="list-group mt-5">
                            <h3 class="card-title">
                                {{ $t("topVehicle") }}
                            </h3>
                            <li
                                class="list-group-item list-card-item"
                                v-for="(item, index) in totalRevenueCount
                                    .top_data?.Vehicle"
                                :key="index"
                            >
                                <div class="row">
                                    <div class="col">
                                        {{
                                            item.title != ""
                                                ? item.title
                                                : item.vehicle_brand_name
                                        }}
                                    </div>
                                    <div class="col" v-if="item.value">
                                        {{ item.value.toFixed(2) }}
                                        {{ $t("SAR") }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Customer -->
                <div
                    class="col dashbord-cards"
                    v-if="
                        cardVisible(
                            userRole == 1 || userRole == 6 ? 'customer' : 'user'
                        )
                    "
                >
                    <div
                        class="card cards-border-bottom p-2"
                        style="cursor: pointer"
                    >
                        <h3
                            class="card-title text-uppercase text-muted mt-2 mb-0"
                        >
                            {{ $t("customers") }}
                        </h3>
                        <span class="font-weight-700 h1" v-if="userRole == 4">
                            {{ customerCount?.total_users || 0 }}</span
                        >
                        <span class="font-weight-700 h1" v-else>
                            {{ customerCount?.total_customer || 0 }}</span
                        >
                    </div>

                    <div
                        class="card rounded-md p-2"
                        v-if="customerCount.total_customer > 0"
                    >
                        <div class="row">
                            <div class="col">
                                <h3 class="card-title">
                                    {{
                                        $t("information_dashboard.new_customer")
                                    }}
                                </h3>
                            </div>
                            <div class="col">
                                <h4>
                                    {{
                                        customerCount.total_count?.new_customer
                                    }}
                                </h4>
                            </div>
                        </div>
                        <ul class="list-group">
                            <li
                                class="list-group-item list-card-item"
                                v-for="(item, index) in customerCount.top_data
                                    ?.top_customer"
                                :key="index"
                            >
                                <div class="row">
                                    <div class="col">
                                        {{ item.title }}
                                    </div>
                                    <div class="col" v-if="item.value">
                                        {{ item.value.toFixed(2) }}
                                        {{ $t("SAR") }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Credit payment -->
                <div
                    class="col dashbord-cards"
                    v-if="cardVisible('credit_payment')"
                >
                    <div
                        class="card cards-border-bottom p-2"
                        style="cursor: pointer"
                    >
                        <h3
                            class="card-title text-uppercase text-muted mt-2 mb-0"
                        >
                            {{ $t("credit_payment") }}
                        </h3>
                        <span class="font-weight-700 h1">
                            {{ creditPaymentCount?.total_credit_payment || 0 }}
                        </span>
                    </div>
                </div>
                <!-- Providers & Address -->
                <div
                    class="col dashbord-cards"
                    v-if="cardVisible('provider_and_address')"
                >
                    <div class="card border-bottom p-2" style="cursor: pointer">
                        <h3
                            class="card-title text-uppercase text-muted mt-2 mb-0"
                        >
                            {{
                                this.userRole != 4 && this.userRole != 5
                                    ? this.$t("providers")
                                    : this.$t("addresses")
                            }}
                        </h3>
                        <span class="font-weight-700 h1">
                            {{
                                this.userRole != 4 && this.userRole != 5
                                    ? providerCount?.total_providers || 0
                                    : addressCount?.total_address || 0
                            }}</span
                        >
                    </div>

                    <div
                        class="card rounded-md p-2"
                        v-if="providerCount.total_providers > 0"
                    >
                        <div class="row">
                            <div class="col">
                                <h3 class="card-title">
                                    {{
                                        $t("information_dashboard.new_provider")
                                    }}
                                </h3>
                            </div>
                            <div class="col">
                                <h4>
                                    {{
                                        providerCount.total_count?.new_provider
                                    }}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- New stores -->
                <div class="col dashbord-cards" v-if="cardVisible('store')">
                    <div
                        class="card cards-border-bottom p-2"
                        style="cursor: pointer"
                    >
                        <h3
                            class="card-title text-uppercase text-muted mt-2 mb-0"
                        >
                            {{ $t("store") }}
                        </h3>
                        <span class="font-weight-700 h1">
                            {{ storeCount?.total_stores || 0 }}</span
                        >
                    </div>
                    <div
                        class="card rounded-md p-2"
                        v-if="storeCount.new_stores > 0"
                    >
                        <div class="row">
                            <div class="col">
                                <h3 class="card-title">
                                    {{ $t("new_business_store") }}
                                </h3>
                            </div>
                            <div class="col text-right">
                                <h4>
                                    {{ storeCount.new_stores }}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Store complaint -->
                <div class="col dashbord-cards" v-if="cardVisible('complaint')">
                    <div
                        class="card cards-border-bottom p-2"
                        style="cursor: pointer"
                    >
                        <h3
                            class="card-title text-uppercase text-muted mt-2 mb-0"
                        >
                            {{ $t("complaints") }}
                        </h3>
                        <span class="font-weight-700 h1">
                            {{ complaintCount?.total_complaints || 0 }}</span
                        >
                    </div>
                    <div
                        class="card rounded-md p-2"
                        v-if="complaintCount.new_complaints > 0"
                    >
                        <div class="row">
                            <div class="col">
                                <h3 class="card-title">
                                    {{ $t("new_complaint") }}
                                </h3>
                            </div>
                            <div class="col text-right">
                                <h4>
                                    {{ complaintCount.new_complaints }}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tabs>
    </div>
</template>

<script>
import {
    getUserCount,
    getStoreCount,
    getOrderCount,
    getAddressCount,
    getProviderCount,
    getCustomerCount,
    getComplaintCount,
    getTotalRevenueCount,
    getCreditPaymentCount,
} from "@/api/dashboard";
import mixin from "@/mixins/common";
import moment from "moment";
export default {
    data() {
        return {
            providerCount: {},
            creditPaymentCount: {},
            customerCount: {},
            addressCount: {},
            totalRevenueCount: {},
            ordersCount: {},
            storeCount: {},
            complaintCount: {},
            startDate: moment().format("DD-MM-YYYY"),
            endDate: moment().format("DD-MM-YYYY"),
        };
    },
    created() {
        this.getCounter();
    },

    mixins: [mixin],

    methods: {
        setDate(value) {
            switch (value) {
                case "today":
                    this.startDate = moment().format("DD-MM-YYYY");
                    this.endDate = moment().format("DD-MM-YYYY");
                    break;
                case "yesterday":
                    this.startDate = moment()
                        .add(-1, "days")
                        .format("DD-MM-YYYY");
                    this.endDate = moment()
                        .add(-1, "days")
                        .format("DD-MM-YYYY");
                    break;
                case "this_week":
                    this.startDate = moment()
                        .startOf("week")
                        .format("DD-MM-YYYY");
                    this.endDate = moment().endOf("week").format("DD-MM-YYYY");
                    break;
                case "this_month":
                    this.startDate = moment()
                        .startOf("month")
                        .format("DD-MM-YYYY");
                    this.endDate = moment().endOf("month").format("DD-MM-YYYY");
                    break;
                case "this_year":
                    this.startDate = moment()
                        .startOf("year")
                        .format("DD-MM-YYYY");
                    this.endDate = moment().endOf("year").format("DD-MM-YYYY");
                    break;
                case "all":
                    this.startDate = "";
                    this.endDate = "";
                    break;
            }

            this.getCounter();
        },

        getCounter() {
            let storeId = null;
            if (this.userRole == 4 || this.userRole == 5) {
                storeId = this.storeId;
            }

            this.setOrderCount(storeId);
            if (this.cardVisible("revenue")) {
                this.setTotalRevenue(storeId);
            }

            if (this.cardVisible("credit_payment")) {
                this.setCreditPayment(storeId);
            }

            if (this.userRole != 4 && this.userRole != 5) {
                this.setProviderCount(storeId);
                if (this.cardVisible("customer")) {
                    this.setCustomerCount(storeId);
                }
            } else {
                this.setAddressCount(storeId);
                if (this.cardVisible("user")) {
                    this.setUserCount(storeId);
                }
            }

            if (this.cardVisible("store")) {
                this.setStoreCount();
            }

            if (this.cardVisible("complaint")) {
                this.setComplaintCount();
            }
        },
        setOrderCount(storeId) {
            getOrderCount({
                start_date: this.startDate,
                end_date: this.endDate,
                store_id: storeId,
                service_provider_company_id: this.serviceProviderCompanyId,
                query: {},
                lang: localStorage.getItem("lang"),
            })
                .then((res) => {
                    this.ordersCount = res;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        setProviderCount(storeId) {
            getProviderCount({
                start_date: this.startDate,
                end_date: this.endDate,
                store_id: storeId,
                service_provider_company_id: this.serviceProviderCompanyId,
                limit: 2,
                query: {},
            })
                .then((res) => {
                    this.providerCount = res;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        setAddressCount(storeId) {
            getAddressCount({
                start_date: this.startDate,
                end_date: this.endDate,
                store_id: storeId,
                service_provider_company_id: this.serviceProviderCompanyId,
                limit: 2,
                query: {},
            })
                .then((res) => {
                    this.addressCount = res;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        setTotalRevenue(storeId) {
            getTotalRevenueCount({
                start_date: this.startDate,
                end_date: this.endDate,
                service_provider_company_id: this.serviceProviderCompanyId,
                store_id: storeId,
                limit: 5,
                query: {},
            })
                .then((res) => {
                    this.totalRevenueCount = res;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        setCreditPayment(storeId) {
            getCreditPaymentCount({
                start_date: this.startDate,
                end_date: this.endDate,
                store_id: storeId,
                limit: 2,
                query: {},
            })
                .then((res) => {
                    this.creditPaymentCount = res;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        async setCustomerCount(storeId) {
            await getCustomerCount({
                start_date: this.startDate,
                end_date: this.endDate,
                store_id: storeId,
                service_provider_company_id: this.serviceProviderCompanyId,
                limit: 2,
                query: {},
                lang: localStorage.getItem("lang"),
            })
                .then((res) => {
                    this.customerCount = res;
                })
                .catch(({ response }) => {
                    console.log("error");
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        setUserCount(storeId) {
            getUserCount({
                start_date: this.startDate,
                end_date: this.endDate,
                store_id: storeId,
                limit: 2,
                query: {},
            })
                .then((res) => {
                    this.customerCount = res;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        setStoreCount() {
            getStoreCount({
                start_date: this.startDate,
                end_date: this.endDate,
                query: {},
            })
                .then((res) => {
                    this.storeCount = res;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        setComplaintCount() {
            getComplaintCount({
                start_date: this.startDate,
                end_date: this.endDate,
            })
                .then((res) => {
                    this.complaintCount = res;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        cardVisible(type) {
            if (type == "revenue") {
                return this.userRole == 1 ||
                    this.userRole == 4 ||
                    this.userRole == 7
                    ? true
                    : false;
            }
            if (type == "customer") {
                return this.userRole == 1 || this.userRole == 6 ? true : false;
            }
            if (type == "credit_payment" || type == "user") {
                return this.userRole == 4 ? true : false;
            }
            if (type == "store") {
                return this.userRole == 1 ||
                    this.userRole == 6 ||
                    this.userRole == 7
                    ? true
                    : false;
            }
            if (type == "provider_and_address") {
                return this.userRole == 1 ||
                    this.userRole == 6 ||
                    this.userRole == 4 ||
                    this.userRole == 5 ||
                    this.userRole == 8
                    ? true
                    : false;
            }

            if (type == "complaint") {
                return this.userRole == 7 ? true : false;
            }
            return false;
        },
    },
};
</script>

<style></style>
