<template>
    <div>
        <hr />
        <store-address-list-upsert
            :store="storeData"
        ></store-address-list-upsert>
        <!-- <div class="">
            <div class="card mb-0 px-5 rounded-md">
                <div
                    v-if="isZidshipStore() && addresses.length < 1"
                    class="border-0 card-header d-flex justify-content-between align-items-center"
                >
                    <button
                        class="btn btn-default text-white btn-sm"
                        @click="addAddressModal"
                    >
                        <span>{{ $t("add_address") }}</span>
                    </button>
                </div>
                <div
                    v-else-if="!isZidshipStore()"
                    class="border-0 card-header d-flex justify-content-between align-items-center"
                >
                    <button
                        class="btn btn-default text-white btn-sm"
                        @click="addAddressModal"
                    >
                        <span>{{ $t("add_address") }}</span>
                    </button>
                </div>
                <div class="mt-4" v-else></div>

                <div>
                    <el-table
                        class="table-responsive table-flush"
                        header-row-class-name="thead-light"
                        :data="addresses"
                        v-loading="isLoading"
                        :empty-text="$t('no_data_found')"
                    >
                        <el-table-column :label="$t('id')" min-width="150px">
                            <template v-slot="{ row }">
                                <p
                                    class="font-weight-600 name mb-0 text-capitalize"
                                >
                                    {{ row.id }}
                                </p>
                            </template>
                        </el-table-column>

                        <el-table-column :label="$t('name')" min-width="150px">
                            <template v-slot="{ row }">
                                <p
                                    class="font-weight-600 name mb-0 text-capitalize"
                                >
                                    {{ row.address_line1 }}
                                </p>
                                <p
                                    class="font-weight-600 name mb-0 text-capitalize"
                                >
                                    {{ row.address_line2 }}
                                </p>
                            </template>
                        </el-table-column>

                        <el-table-column :label="$t('city')" min-width="200px">
                            <template v-slot="{ row }">
                                <span
                                    v-if="lang == 'ar'"
                                    class="font-weight-600 name mb-0 text-nowrap"
                                    >{{ row.address_city?.name_ar }}</span
                                >
                                <span
                                    v-else
                                    class="font-weight-600 name mb-0 text-nowrap"
                                    >{{ row.address_city?.name }}</span
                                >
                            </template>
                        </el-table-column>

                        <el-table-column
                            :label="$t('district')"
                            min-width="200px"
                        >
                            <template v-slot="{ row }">
                                <span
                                    v-if="lang == 'ar'"
                                    class="font-weight-600 name mb-0 text-nowrap"
                                    >{{ row.district?.name_ar }}</span
                                >
                                <span
                                    v-else
                                    class="font-weight-600 name mb-0 text-nowrap"
                                    >{{ row.district?.name }}</span
                                >
                            </template>
                        </el-table-column>

                        <el-table-column
                            :label="$t('actions')"
                            min-width="210px"
                        >
                            <template v-slot="{ row }">
                                <div class="d-flex">
                                    <el-tooltip
                                        :content="$t('edit')"
                                        placement="top"
                                    >
                                        <badge
                                            circle
                                            size="lg"
                                            class="mt-2 bg-sky-dark"
                                            style="cursor: pointer"
                                            @click="editAddressModal(row)"
                                        >
                                            <img src="@/assets/svg/note.svg" />
                                        </badge>
                                    </el-tooltip>
                                    <alert-modal
                                        class="mt-2"
                                        :id="row.id"
                                        :title="$t('address')"
                                        :body="$t('address_delete_confirm')"
                                        alertCategory="address"
                                        @alertModalCompleted="getAddresses"
                                    ></alert-modal>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div
                    class="card-footer p-0 pt-5 pb-5"
                    v-if="addresses.length > 0"
                >
                    <base-pagination
                        :meta="meta"
                        v-model="currentPage"
                        :total="totalData"
                    ></base-pagination>
                </div>

                
            </div>


            <modal
                :show="showModal"
                @close="showModal = false"
                size="lg"
                body-classes="p-0"
            >
                <template v-slot:header>
                    <div class="font-weight-900 text-lg text-center mt-2 mb-1">
                        {{ addOrEditAddress }}
                    </div>
                </template>
                <div class="px-3 d-block">
                    <Form @submit="submitAddress" ref="addressForm">
                        <label for="name" class="form-control-label d-block">{{
                            $t("name")
                        }}</label>
                        <Field
                            name="address_line1"
                            type="text"
                            class="form-control"
                            rules="required"
                            v-model="form.address_line1"
                            :label="$t('name')"
                        />

                        <ErrorMessage
                            name="address_line1"
                            class="error"
                        ></ErrorMessage>

                        <label for="city_id" class="form-control-label mt-3">{{
                            $t("city")
                        }}</label>
                        <Field
                            rules="required"
                            :label="$t('city')"
                            v-model="form.city_id"
                            name="city_id"
                        >
                            <el-select
                                :placeholder="$t('select')"
                                v-model="form.city_id"
                                class="d-block"
                                name="city_id"
                                filterable
                                rules="required"
                                @change="changeCity(form.city_id)"
                            >
                                <el-option
                                    v-for="option in cities"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="
                                        lang == 'ar'
                                            ? option.name_ar
                                            : option.name
                                    "
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage
                            name="city_id"
                            class="error"
                        ></ErrorMessage>

                        <div v-if="districtOptions && districtOptions.length">
                            <label
                                for="district_id"
                                class="form-control-label mt-3"
                                >{{ $t("district") }}</label
                            >
                            <Field
                                rules="required"
                                :label="$t('district')"
                                v-model="form.district_id"
                                name="district_id"
                            >
                                <el-select
                                    :placeholder="$t('select')"
                                    v-model="form.district_id"
                                    class="d-block"
                                    name="district_id"
                                    filterable
                                    rules="required"
                                    @change="changeDistrict(form.district_id)"
                                >
                                    <el-option
                                        v-for="option in districtOptions"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="
                                            lang == 'ar'
                                                ? option.name_ar
                                                : option.name
                                        "
                                        :key="option.id"
                                    >
                                    </el-option>
                                </el-select>
                            </Field>
                            <ErrorMessage
                                name="district_id"
                                class="error"
                            ></ErrorMessage>
                        </div>

                        <google-maps
                            :lat="form.latitude"
                            :lon="form.longitude"
                            :zoom_level="zoom_level"
                            v-if="showModal === true"
                            @locationChanged="locationChanged($event)"
                        />

                        <div class="w-full text-center my-4">
                            <base-button
                                native-type="submit"
                                class="text-white bg-primary"
                                :loading="buttonSpinner"
                            >
                                {{ $t("save") }}
                            </base-button>
                            <input
                                type="button"
                                :value="$t('close')"
                                @click="
                                    (showModal = false),
                                        (this.form = {}),
                                        (this.districtOptions = [])
                                "
                                class="btn bg-primary text-white"
                            />
                        </div>
                    </Form>
                </div>
            </modal>
        </div> -->
    </div>
</template>

<script>
// import {
//     get,
//     getDistricts as getDistrictList,
//     add,
//     edit,
// } from "@/api/addresses";
import store from "@/store/index";
import mixin from "@/mixins/common";
import StoreAddressListUpsert from "@/components/common/StoreAddressListUpsert.vue";
// import GoogleMaps from "@/components/GoogleMaps";
// import { get as getCityList } from "@/api/cities";
// import AlertModal from "@/components/common/AlertModal.vue";

export default {
    data() {
        return {
            // lang: localStorage.getItem("lang"),
            // isLoading: false,
            // buttonSpinner: false,
            // districtLoading: false,
            // meta: {},
            // addresses: [],
            // showModal: false,
            // totalData: null,
            // storeId: null,
            // addressId: null,
            // districtOptions: [],
            // form: {
            //     address_line1: "",
            //     district_id: null,
            //     latitude: "",
            //     longitude: "",
            //     city_id: null,
            //     city: null,
            // },
            // currentPage: 1,
            // addOrEditAddress: "",
            // cities: [],
            // zoom_level: 11,
            // latitude: null,
            // longitude: null,
            storeData: store.getters.store,
        };
    },
    created() {
        // this.getAddresses();
        // this.getCities();
    },

    mixins: [mixin],
    components: {
        StoreAddressListUpsert,
        // GoogleMaps,
        // AlertModal,
    },
    watch: {
        // currentPage: {
        //     handler: function () {
        //         this.getAddresses();
        //     },
        // },
    },
    methods: {
        //     getAddresses() {
        //         this.isLoading = true;
        //         get({
        //             page: this.currentPage,
        //             store_id: store.getters.store?.id,
        //             include: "district,address_city",
        //             filter: {
        //                 user_id: store.getters.user?.id,
        //             },
        //         })
        //             .then((data) => {
        //                 this.addresses = data.data;
        //                 this.storeId = store.getters.store?.id;
        //                 this.totalData = data.meta.total;
        //                 this.meta = data.meta;
        //                 this.isLoading = false;
        //             })
        //             .catch(({ response }) => {
        //                 // Below method is a common Mixin method that shows error on screen through toast Notification
        //                 this.apiError(response);
        //             });
        //     },
        //     changeDistrict(district_id) {
        //         this.zoom_level = 18;
        //         var districtName = this.districtOptions.filter(
        //             (district) => district.id === district_id
        //         );
        //         const geocoder = new google.maps.Geocoder();
        //         const address = districtName[0]["name"]
        //             ? districtName[0]["name"] + "," + this.form.city
        //             : "";
        //         geocoder.geocode({ address }, (results, status) => {
        //             if (status === "OK") {
        //                 this.form.latitude = results[0].geometry.location.lat();
        //                 this.form.longitude = results[0].geometry.location.lng();
        //             }
        //         });
        //     },
        //     changeCity(city_id) {
        //         this.zoom_level = 11;
        //         var cityName = this.cities.filter((city) => city.id === city_id);
        //         const geocoder = new google.maps.Geocoder();
        //         const address = cityName[0]["name"] ? cityName[0]["name"] : "";
        //         geocoder.geocode({ address }, (results, status) => {
        //             if (status === "OK") {
        //                 this.form.latitude = results[0].geometry.location.lat();
        //                 this.form.longitude = results[0].geometry.location.lng();
        //             }
        //         });
        //         this.getDistricts();
        //         this.form.city = cityName[0]["name_ar"]
        //             ? cityName[0]["name_ar"]
        //             : "";
        //     },
        //     editAddressModal(row) {
        //         this.showModal = true;
        //         this.addressId = row.id;
        //         this.form.latitude = row.latitude;
        //         this.form.longitude = row.longitude;
        //         this.form.address_line1 = row.address_line1;
        //         this.addOrEditAddress = this.$t("edit_address");
        //         this.form.city_id = row.city_id;
        //         this.form.city = row.city;
        //         if (this.form.city_id) {
        //             this.getDistricts();
        //             this.form.district_id = row.district_id;
        //             this.zoom_level = 18;
        //         } else {
        //             this.zoom_level = 11;
        //             this.districtOptions = null;
        //         }
        //     },
        //     addAddressModal() {
        //         (this.lang = localStorage.getItem("lang")), (this.showModal = true);
        //         this.$refs.addressForm.resetForm();
        //         this.addOrEditAddress = this.$t("add_address");
        //     },
        //     getDistricts() {
        //         this.districtLoading = true;
        //         this.form.district_id = null;
        //         getDistrictList({
        //             limit: -1,
        //             filter: {
        //                 city_id: this.form.city_id,
        //             },
        //         })
        //             .then((res) => {
        //                 this.districtLoading = false;
        //                 this.districtOptions = res.data;
        //             })
        //             .catch(({ response }) => {
        //                 this.districtLoading = false;
        //                 // Below method is a common Mixin method that shows error on screen through toast Notification
        //                 this.apiError(response);
        //             });
        //     },
        //     getCities() {
        //         getCityList({
        //             limit: -1,
        //         })
        //             .then((res) => {
        //                 this.cities = res.data;
        //             })
        //             .catch(({ response }) => {
        //                 // Below method is a common Mixin method that shows error on screen through toast Notification
        //                 this.apiError(response);
        //             });
        //     },
        //     submitAddress(data) {
        //         data["latitude"] = this.form.latitude;
        //         data["longitude"] = this.form.longitude;
        //         data["city"] = this.form.city;
        //         this.buttonSpinner = true;
        //         if (this.addressId === null) {
        //             add(data, {
        //                 store_id: this.storeId,
        //             })
        //                 .then(() => {
        //                     this.buttonSpinner = false;
        //                     this.showModal = false;
        //                     this.$refs.addressForm.resetForm();
        //                     this.getAddresses();
        //                     this.form = {};
        //                     this.runToast(
        //                         this.$t("address_add_success"),
        //                         "success"
        //                     );
        //                     this.form = {};
        //                     this.districtOptions = [];
        //                 })
        //                 .catch(({ response }) => {
        //                     this.buttonSpinner = false;
        //                     // Below method is a common Mixin method that shows error on screen through toast Notification
        //                     this.apiError(response);
        //                 });
        //         } else {
        //             edit(this.addressId, data, {
        //                 store_id: this.storeId,
        //                 _method: "PATCH",
        //             })
        //                 .then(() => {
        //                     this.$refs.addressForm.resetForm();
        //                     this.buttonSpinner = false;
        //                     this.showModal = false;
        //                     this.getAddresses();
        //                     this.runToast(
        //                         this.$t("address_add_success"),
        //                         "success"
        //                     );
        //                     this.form = {};
        //                     this.districtOptions = [];
        //                 })
        //                 .catch(({ response }) => {
        //                     this.buttonSpinner = false;
        //                     // Below method is a common Mixin method that shows error on screen through toast Notification
        //                     this.apiError(response);
        //                 });
        //         }
        //     },
        //     locationChanged(event) {
        //         this.form.latitude = event.latitude;
        //         this.form.longitude = event.longitude;
        //     },
    },
};
</script>

<style></style>
