<template>
    <div>
        <hr />
        <div class="card py-3 px-5 rounded-md">
            <div
                class="border-0 card-header px-0 d-flex justify- tent-between align-items-center"
            >
                <router-link
                    :to="{
                        name: 'ServiceProviderCompanies Upsert',
                        params: { id: 'add' },
                    }"
                >
                    <button class="text-white btn btn-default btn-sm">
                        <i class="mr-2 text-white fas fa-plus-circle fa-lg">
                            <span>{{
                                $t("add_service_provider_company")
                            }}</span>
                        </i>
                    </button>
                </router-link>
            </div>

            <!-- Filter Section Start -->
            <div class="d-flex mb-4">
                <search-filter
                    :filters="[
                        {
                            id: 'name',
                        },
                        {
                            id: 'contact_name',
                        },
                        {
                            id: 'contact_mobile_no',
                            type: 'number',
                        },
                    ]"
                    @search="(searchObject) => (filters = searchObject)"
                />
            </div>
            <!-- Filter Section End  -->

            <!-- Table Section Starts -->
            <div class="row">
                <main
                    :class="
                        isFilter
                            ? 'col-md-9 col-xs-11 p-l-2 p-t-2'
                            : 'col-md-12 col-xs-11 p-l-2 p-t-2'
                    "
                >
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="serviceProviderCompanies"
                            v-loading="isLoading"
                            @row-click="rowClicked"
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('name')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="mb-0 font-weight-600 name text-nowrap"
                                        >{{ row.name }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('contact_name')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="mb-0 font-weight-600 name">{{
                                        row.user.name
                                    }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('contact_no')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="mb-0 font-weight-600 name"
                                        v-if="row?.user?.mobile_no"
                                    >
                                        +{{ row.user.country_code + "" }}
                                    </span>
                                    <span class="mb-0 font-weight-600 name">
                                        {{ row.user.mobile_no }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('the_insurance')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="mb-0 font-weight-600 name"
                                        v-if="row?.insurance_status"
                                    >
                                        <custom-label :label="row.insurance_status" />
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('no_of_providers')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span class="mb-0 font-weight-600 name">{{
                                        row.providers.length
                                    }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('actions')"
                                min-width="210px"
                            >
                                <template v-slot="{ row }">
                                    <div class="d-flex">
                                        <el-tooltip
                                            :content="$t('edit')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                style="cursor: pointer"
                                                :title="$t('edit')"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="
                                                        '/service-provider-companies/' +
                                                        row.id
                                                    "
                                                >
                                                    <img
                                                        src="@/assets/svg/note.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>

                                        <!-- Assigne Provider Modal -->
                                        <ProviderAssignModal
                                            :currentRow="row"
                                            @click="assignProviderHandller(row)"
                                            @assignProvideCompleted="
                                                getServiceProvider
                                            "
                                            :type="'serviceProviderCompany'"
                                            :isMultiple="true"
                                        >
                                            <template
                                                v-slot:default="{ clickEvent }"
                                            >
                                                <el-tooltip
                                                    :content="
                                                        $t('assign_provider')
                                                    "
                                                    placement="top"
                                                >
                                                    <badge
                                                        circle
                                                        size="lg"
                                                        class="bg-sky-dark"
                                                        style="cursor: pointer"
                                                        @click="clickEvent"
                                                    >
                                                        <img
                                                            src="@/assets/svg/users.svg"
                                                        />
                                                    </badge>
                                                </el-tooltip>
                                            </template>
                                        </ProviderAssignModal>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="p-0 pt-5 pb-5 card-footer"
                        v-if="serviceProviderCompanies.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
        <!--  End of Table -->
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import ProviderAssignModal from "@/components/common/ProviderAssignModal.vue";
import { getServiceProvider } from "@/api/serviceProviderCompanies";
import { getUsers } from "@/api/users";

export default {
    data() {
        return {
            serviceProviderCompanies: [],
            currentPage: 1,
            filters: {},
            providers: [],
            currentRow: {},
            isLoading: false,
            totalData: null,
            isFilter: false,
            meta: false,
        };
    },
    components: { ProviderAssignModal },

    mixins: [mixin],

    created() {
        this.getServiceProviderCompanies();
    },

    watch: {
        currentPage: {
            handler: function () {
                this.getServiceProviderCompanies();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.currentPage = 1;
                this.getServiceProviderCompanies();
            },
        },
    },
    methods: {
        assignProviderHandller(row) {
            getUsers("admin", {
                role: "provider",
                limit: -1,
                filter: {
                    status: "approved",
                },
            })
                .then((res) => {
                    getUsers("admin", {
                        limit: -1,
                        filter: {
                            service_provider_company: row.id,
                        },
                    });
                    this.currentRow = res;
                    this.isOpen = true;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getServiceProviderCompanies() {
            this.isLoading = true;
            getServiceProvider({
                page: this.currentPage,
                filter: this.filters,
            })
                .then((res) => {
                    this.serviceProviderCompanies = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        rowClicked(row, column, event) {
            if(column.label != this.$t("actions")){
                this.$router.push({
                    name: "ServiceProviderCompanies Upsert",
                    params: { id: row.id },
                });
            }
            return;
        },
    },
};
</script>
