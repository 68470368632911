<template>
    <div>
        <el-table
            class="table-responsive table-flush"
            header-row-class-name="thead-light"
            :data="status_histories"
            :empty-text="$t('no_data_found')"
        >
            <el-table-column :label="$t('status')" min-width="100px">
                <template v-slot="{ row }">
                    <custom-label :label="row.status" />
                </template>
            </el-table-column>
            <el-table-column
                :label="$t('date_and_time')"
                min-width="100px"
                prop="email"
            >
                <template v-slot="{ row }">
                    <span class="font-weight-600 name mb-0 text-nowrap">{{
                        row.created_at
                    }}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import mixin from "@/mixins/common";

export default {
    data() {
        return {};
    },
    mixins: [mixin],

    props: ["status_histories"],
};
</script>
