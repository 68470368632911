<template>
    <div class="d-inline">
        <!-- Modal props show is for toggling Modal Display 
        @close child event is for closing modal -->
        <!-- Modal  -->
        <modal
            footerClasses="justify-content-center"
            :show="showModal"
            size="lg"
            body-classes="p-0"
        >
            <template v-slot:header>
                <div>
                    <div class="font-weight-700 text-lg mt-2 mb-1">
                        {{ $t("package_order_messages.add_package_order") }}
                    </div>
                </div>
            </template>

            <div class="pt-0 px-4">
                <Form @submit="addOrder" ref="addOrderRef">
                    <!-- below customer-edit-form Component contains the Form elements of edit form 
                    if role = customer then the component will be used in modal -->
                    <div class="d-flex flex-column space-y">
                        <div class="row">
                            <div class="col-md-6">
                                <card class="p-0 border h-100">
                                    <div>
                                        <h3 class="mb-0">
                                            {{
                                                $t(
                                                    "package_order_messages.price_summary"
                                                )
                                            }}
                                        </h3>
                                        <small class="text-gary-100">{{
                                            $t(
                                                "package_order_messages.unit_price_message"
                                            )
                                        }}</small>
                                        <ul class="summary-list">
                                            <li>
                                                <p>
                                                    <b>{{
                                                        $t(
                                                            "package_order_messages.orders"
                                                        )
                                                    }}</b>
                                                </p>
                                                <p>
                                                    {{ packages.length }}
                                                    {{
                                                        $t(
                                                            "package_order_messages.orders"
                                                        )
                                                    }}
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>{{
                                                        $t(
                                                            "package_order_messages.price_per_package"
                                                        )
                                                    }}</b>
                                                </p>
                                                <p>
                                                    {{
                                                        parseFloat(
                                                            pricePerService
                                                        ).toFixed(2)
                                                    }}
                                                    {{ $t("SAR") }}
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>{{
                                                        $t(
                                                            "package_order_messages.total_price_message"
                                                        )
                                                    }}</b>
                                                </p>
                                                <p>
                                                    {{
                                                        parseFloat(
                                                            totalPrice
                                                        ).toFixed(2)
                                                    }}
                                                    {{ $t("SAR") }}
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </card>
                            </div>
                            <div class="col-md-6">
                                <card class="p-0 border h-100">
                                    <div>
                                        <h3 class="mb-0">
                                            {{
                                                $t(
                                                    "package_order_messages.information"
                                                )
                                            }}
                                        </h3>
                                        <ul
                                            class="summary-list border-top pt-3 border-gray-lightest"
                                        >
                                            <li>
                                                <p>
                                                    <b>{{
                                                        $t(
                                                            "package_order_messages.receiving_from"
                                                        )
                                                    }}</b>
                                                </p>
                                                <div>
                                                    <el-select
                                                        clearable
                                                        class="d-block"
                                                        :placeholder="
                                                            $t('select')
                                                        "
                                                        v-model="order_from"
                                                        filterable
                                                    >
                                                        <el-option
                                                            v-for="option in orderTypeList"
                                                            class="select-danger"
                                                            :value="
                                                                option.value
                                                            "
                                                            :label="
                                                                option.title
                                                            "
                                                            :key="option.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>{{
                                                        $t("vehicle_type")
                                                    }}</b>
                                                </p>
                                                <div>
                                                    <el-select
                                                        clearable
                                                        class="d-block"
                                                        :placeholder="
                                                            $t('select')
                                                        "
                                                        v-model="order_type"
                                                        name="order_type"
                                                        filterable
                                                    >
                                                        <el-option
                                                            v-for="option in vehicleTypeList"
                                                            class="select-danger"
                                                            :value="
                                                                option.value
                                                            "
                                                            :label="
                                                                option.title
                                                            "
                                                            :key="option.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                    <ErrorMessage
                                                        name="order_type"
                                                        class="error"
                                                    />
                                                </div>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>
                                                        {{
                                                            $t(
                                                                "package_order_messages.maximum_load"
                                                            )
                                                        }}
                                                    </b>
                                                </p>
                                                <p>
                                                    {{ maximum_load || 0 }}
                                                    {{
                                                        $t(
                                                            "package_order_messages.loads"
                                                        )
                                                    }}
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>{{
                                                        $t(
                                                            "package_order_messages.helper"
                                                        )
                                                    }}</b>
                                                </p>
                                                <p>
                                                    <el-checkbox
                                                        class="text-right"
                                                        name="payload_helper"
                                                        v-model="payload_helper"
                                                        @change="helperChange"
                                                    ></el-checkbox>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </card>
                            </div>
                            <div
                                class="col-md-12 mt-4"
                                v-if="order_from && order_type"
                            >
                                <card class="p-0 border">
                                    <div
                                        class="d-flex align-items-center justify-content-between"
                                    >
                                        <div>
                                            <h3 class="mb-0">
                                                <h3 class="mb-0">
                                                    {{
                                                        $t(
                                                            "package_order_messages.packages"
                                                        )
                                                    }}
                                                </h3>
                                            </h3>
                                            <small class="text-gary-100">{{
                                                $t(
                                                    "package_order_messages.maximum_load_allowed_alert",
                                                    maximum_load || 0
                                                )
                                            }}</small>
                                        </div>
                                        <div>
                                            <div class="bg-sky p-2 rounded-sm">
                                                <p class="mb-0">
                                                    <b> {{ packages.length }}</b
                                                    >/
                                                    {{ maximum_load || 0 }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <Form
                                            id="packageForm"
                                            ref="packageForm"
                                            class="packageForm"
                                            method="post"
                                            @submit="submitPayload"
                                        >
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <Field
                                                        rules="required"
                                                        :label="$t('name')"
                                                        type="text"
                                                        :placeholder="
                                                            $t('name')
                                                        "
                                                        class="form-control"
                                                        name="payload_name"
                                                        v-model="payload_name"
                                                    />
                                                    <ErrorMessage
                                                        class="error"
                                                        name="payload_name"
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <Field
                                                        rules="required|length:9"
                                                        type="number"
                                                        :placeholder="
                                                            $t('mobile_no')
                                                        "
                                                        :label="$t('mobile_no')"
                                                        class="form-control"
                                                        name="payload_phone"
                                                        v-model="payload_phone"
                                                    />
                                                    <ErrorMessage
                                                        class="error"
                                                        name="payload_phone"
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <el-select
                                                        class="d-block"
                                                        :placeholder="
                                                            $t(
                                                                'package_order_messages.payload_district'
                                                            )
                                                        "
                                                        name="payload_district"
                                                        v-model="
                                                            payload_district
                                                        "
                                                        filterable
                                                    >
                                                        <el-option
                                                            v-for="option in district_options"
                                                            class="select-danger"
                                                            :value="
                                                                option.value
                                                            "
                                                            :label="
                                                                option.title
                                                            "
                                                            :key="option.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                    <ErrorMessage
                                                        class="error"
                                                        name="payload_district"
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <el-select
                                                        rules="required"
                                                        clearable
                                                        class="d-block"
                                                        :placeholder="
                                                            $t('dropoff_time')
                                                        "
                                                        name="payload_dropoff_time"
                                                        v-model="
                                                            payload_dropoff_time
                                                        "
                                                        filterable
                                                    >
                                                        <el-option
                                                            v-for="option in dropOffTimeOptions"
                                                            class="select-danger"
                                                            :value="
                                                                option.value
                                                            "
                                                            :label="
                                                                option.label
                                                            "
                                                            :key="option.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                                <div class="col-md-4">
                                                    <Field
                                                        rules="required|min_value:1"
                                                        :label="
                                                            $t('no_of_pieces')
                                                        "
                                                        type="number"
                                                        :placeholder="
                                                            $t('no_of_pieces')
                                                        "
                                                        class="form-control"
                                                        name="payload_no_of_pieces"
                                                        v-model="
                                                            payload_no_of_pieces
                                                        "
                                                    />
                                                    <ErrorMessage
                                                        class="error"
                                                        name="payload_no_of_pieces"
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <base-button
                                                        @click="onPackageSubmit"
                                                        :disabled="
                                                            isPayloadDisable
                                                        "
                                                    >
                                                        <i
                                                            class="mr-2 text-white fas fa-plus-circle fa-lg"
                                                        ></i>
                                                    </base-button>
                                                </div>
                                            </div>
                                        </Form>
                                        <div class="package-detail-table mt-4">
                                            <el-table
                                                class="table-responsive table-flush"
                                                header-row-class-name="thead-light"
                                                :data="packages"
                                                :empty-text="
                                                    $t('no_data_found')
                                                "
                                            >
                                                <el-table-column
                                                    :label="$t('name')"
                                                    min-width="150px"
                                                >
                                                    <template v-slot="{ row }">
                                                        <span
                                                            class="font-weight-600 name mb-0 text-capitalize"
                                                            >{{
                                                                row.payload_name
                                                            }}</span
                                                        >
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    :label="
                                                        $t('contact_mobile_no')
                                                    "
                                                    min-width="150px"
                                                >
                                                    <template v-slot="{ row }">
                                                        <span
                                                            class="font-weight-600 name mb-0 text-nowrap"
                                                            >{{
                                                                row.payload_phone
                                                            }}</span
                                                        >
                                                    </template>
                                                </el-table-column>

                                                <el-table-column
                                                    :label="$t('district')"
                                                    min-width="150px"
                                                >
                                                    <template v-slot="{ row }">
                                                        <span
                                                            class="font-weight-600 name mb-0"
                                                            >{{
                                                                getRowDistrict(
                                                                    row.payload_district
                                                                )
                                                            }}</span
                                                        >
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    :label="$t('dropoff_time')"
                                                    min-width="150px"
                                                >
                                                    <template v-slot="{ row }">
                                                        <span
                                                            class="font-weight-600 name mb-0"
                                                            >{{
                                                                $t(
                                                                    row.dropoff_time
                                                                )
                                                            }}</span
                                                        >
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    :label="$t('no_of_pieces')"
                                                    min-width="150px"
                                                >
                                                    <template v-slot="{ row }">
                                                        <span
                                                            class="font-weight-600 name mb-0"
                                                            >{{
                                                                $t(
                                                                    row.payload_no_of_pieces
                                                                )
                                                            }}</span
                                                        >
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    :label="$t('actions')"
                                                    min-width="150px"
                                                >
                                                    <template v-slot="{ row }">
                                                        <div
                                                            class="d-flex align-items-center flex-wrap action-width"
                                                        >
                                                            <badge
                                                                circle
                                                                size="lg"
                                                                class="bg-sky-dark"
                                                                style="
                                                                    cursor: pointer;
                                                                "
                                                                @click="
                                                                    editPackageRow(
                                                                        row
                                                                    )
                                                                "
                                                            >
                                                                <img
                                                                    src="@/assets/svg/edit-2.svg"
                                                                />
                                                            </badge>
                                                            <badge
                                                                circle
                                                                size="lg"
                                                                class="bg-sky-dark"
                                                                style="
                                                                    cursor: pointer;
                                                                "
                                                                @click="
                                                                    deletePackageRow(
                                                                        row
                                                                    )
                                                                "
                                                            >
                                                                <img
                                                                    src="@/assets/svg/trash.svg"
                                                                />
                                                            </badge>
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </div>
                                </card>
                            </div>
                        </div>
                    </div>

                    <div class="w-full text-center mt-3">
                        <base-button
                            :loading="submitSpinner"
                            native-type="submit"
                            class="text-white bg-primary"
                        >
                            {{ $t("create") }}
                        </base-button>
                        <input
                            type="button"
                            class="btn btn-default text-white"
                            @click="
                                $refs.addOrderRef.resetForm();
                                closeModal();
                            "
                            :value="$t('close')"
                        />
                    </div>
                </Form>
            </div>
        </modal>
    </div>
</template>

<script>
import { me } from "@/api/users";
import mixin from "@/mixins/common";
import { get } from "@/api/addresses";
import { getSettingKeyValue } from "@/api/systemSetting";
import {
    createOrder,
    getOrders,
    getVehicles,
    getOrderCount,
} from "@/api/orders";

// import GoogleMaps from "@/components/GoogleMaps";
import {
    getVehicleTypes,
    getDitsrictOptions,
    savePackageDetails,
} from "@/api/vehicleType";
export default {
    data() {
        return {
            submitSpinner: false,
            form: {
                customer_name: null,
                customer_mobile_no: null,
            },
            vehicleType: null,
            vehicleTypes: [],
            filters: {
                type: "",
                order_no: null,
                provider_id: null,
                status: "",
                store_name: null,
                order_status: this.$route.name == "Dashboard" ? "live" : null,
            },
            pickupAddressOptions: [],
            paymentOption: null,
            addressesOptions: [],
            extraServicePrice: null,

            // new
            order_type: "",
            order_from: "",
            maximum_load: 0,
            orderTypeList: [],
            vehicleTypeList: [],
            payload_name: "",
            payload_phone: "",
            payload_district: "",
            payload_dropoff_time: "",
            payload_no_of_pieces: "",
            payload_helper: false,
            packages: [],
            pricePerService: 0,
            totalPrice: 0,
            helperOrderCount: 0,
            vehicleInformation: {},
            dropOffTimeOptions: [
                {
                    value: "morning",
                    label: this.$t("morning"),
                },
                {
                    value: "evening",
                    label: this.$t("evening"),
                },
                {
                    value: "default",
                    label: this.$t("unspecified"),
                },
            ],
            district_options: [],
            orderCount: 0,
            discountedOrders: 0,
            discountOrderPercentage: 0,
        };
    },
    props: ["showModal"],
    components: {
        // GoogleMaps,
    },
    computed: {
        isPayloadDisable() {
            if (this?.packages?.length === this.maximum_load) {
                return true;
            }
            if (
                !this.payload_name ||
                !(this.payload_phone && this.payload_phone.length == 9) ||
                !this.payload_district ||
                !this.payload_dropoff_time ||
                !this.payload_no_of_pieces
            ) {
                return true;
            }
            return false;
        },
    },
    mixins: [mixin],
    watch: {
        showModal(newVal, oldVal) {
            if (newVal === true) {
                this.packages = [];
            }
        },
        order_type: function (newValue, oldValue) {
            const vehicleTypeList = JSON.parse(
                JSON.stringify(this.vehicleTypeList)
            );
            if (newValue != oldValue) {
                const vehicleType = vehicleTypeList?.find((item) => {
                    if (newValue === item?.value) {
                        return item;
                    }
                });
                this.packages = [];
                this.pricePerService = 0;
                this.totalPrice = 0;
                this.vehicleInformation = vehicleType;
                this.maximum_load = vehicleType?.maximum_orders;
            }
        },
        vehicleType(newValue) {
            let vehicleType = this.vehicleTypes.filter((vehicleType) => {
                return vehicleType.value == newValue;
            });
            this.extraServicePrice = vehicleType ? true : false;
        },
        paymentOption(newVal) {
            if (newVal == "store_credit") {
                var storePostPaidOrder = this.$store.getters.storePostpaidOrder;
                var storeCredit = this.$store.getters.storeCredit;

                if (storePostPaidOrder.is_enabled && storeCredit < 1) {
                    if (
                        Math.abs(storeCredit) >= storePostPaidOrder.max_amount
                    ) {
                        this.store_credit_alert = true;
                        this.storeCreditAddMsg = this.$t(
                            "postpaid_credit_reached_msg"
                        );
                    }
                } else if (storeCredit < 1) {
                    this.store_credit_alert = true;
                    this.storeCreditAddMsg = this.$t("store_credit_msg");
                }
            } else {
                this.store_credit_alert = false;
            }
        },
        payload_district(newVal, oldVal) {},
    },
    created() {
        this.getAllVehicleTypes();
        this.getAddresses();
        this.getDistricts();
        this.getOrderCount();
        this.getSystemSetting();
    },
    methods: {
        helperChange() {
            let orderTypeList = JSON.parse(
                JSON.stringify(this.vehicleInformation)
            );
            this.helperOrderCount = 0;
            if (
                typeof orderTypeList !== "undefined" &&
                orderTypeList.helper_price &&
                this.packages.length > 0
            ) {
                if (this.packages?.length > orderTypeList?.number_of_orders) {
                    this.helperOrderCount =
                        parseInt(this.packages?.length) -
                        orderTypeList?.number_of_orders;
                    if (this.payload_helper === true) {
                        this.totalPrice =
                            this.totalPrice +
                            orderTypeList.helper_price *
                                parseInt(this.helperOrderCount);
                    } else {
                        this.totalPrice =
                            this.totalPrice -
                            orderTypeList.helper_price *
                                parseInt(this.helperOrderCount);
                    }
                }

                this.pricePerService = this.totalPrice / this.packages?.length;
            } else {
                this.totalPrice = 0;
                this.pricePerService = 0;
            }
        },
        closeModal() {
            this.order_type = "";
            this.order_from = "";
            this.payload_name = "";
            this.payload_phone = "";
            this.payload_district = "";
            this.payload_dropoff_time = "";
            this.payload_no_of_pieces = "";
            this.totalPrice = 0;
            this.$emit("close");
        },
        deletePackageRow(row) {
            this.helperOrderCount = 0;
            let currentRow = JSON.parse(JSON.stringify(row));
            let packages = JSON.parse(JSON.stringify(this.packages));
            this.packages = packages?.filter(
                (item) => item.id != currentRow.id
            );
            let orderTypeList = JSON.parse(
                JSON.stringify(this.vehicleInformation)
            );

            this.totalPrice =
                orderTypeList?.initial_price +
                (this.packages?.length - orderTypeList?.number_of_orders) *
                    ((orderTypeList?.threshold * orderTypeList?.initial_price) /
                        100);

            if (this.payload_helper === true) {
                this.helperOrderCount =
                    parseInt(this.packages?.length) -
                    orderTypeList?.number_of_orders;
                this.totalPrice =
                    this.totalPrice +
                    orderTypeList.helper_price *
                        parseInt(this.helperOrderCount);
            }

            if (this.packages?.length <= orderTypeList?.number_of_orders) {
                this.totalPrice = orderTypeList.initial_price;
            }

            if (orderTypeList?.maximum_orders == 0) {
                this.totalPrice = 0;
            }

            if (this.packages?.length == 0) {
                this.pricePerService = 0;
                this.totalPrice = 0;
                return true;
            } else {
                this.pricePerService = this.totalPrice / this.packages?.length;
            }
        },
        editPackageRow(row) {
            let currentRow = JSON.parse(JSON.stringify(row));

            let packages = JSON.parse(JSON.stringify(this.packages));
            this.packages = packages?.filter(
                (item) => item.id != currentRow.id
            );
            this.payload_name = currentRow.payload_name;
            this.payload_phone = currentRow.payload_phone;
            this.payload_district = currentRow.payload_district;
            this.payload_dropoff_time = currentRow.dropoff_time;
            this.payload_no_of_pieces = currentRow.payload_no_of_pieces;
        },
        getRowDistrict(selected) {
            let district_options = JSON.parse(
                JSON.stringify(this.district_options)
            );
            let currentDistrict = district_options?.find(
                (item) => item.value == selected
            );
            if (currentDistrict) {
                return currentDistrict?.title;
            }
            return selected;
        },
        getDistricts() {
            getDitsrictOptions({
                limit: -1,
                filter: {
                    city_id: 3,
                },
            }).then((res) => {
                this.district_options = res?.data?.map((item) => {
                    return {
                        title:
                            localStorage.getItem("lang") == "ar"
                                ? item.name_ar
                                : item.name,
                        value: item.id,
                    };
                });
            });
        },
        checkUniqueCustomer() {
            let result = true;
            if (this.packages.length > 0) {
                this.packages.forEach((element) => {
                    if (element.payload_phone == this.payload_phone) {
                        result = false;
                    }
                });
            }
            if (result == false) {
                this.runToast(
                    this.$t("package_order_messages.unique_mobile"),
                    "error"
                );
            }
            return result;
        },
        addCustomer() {
            this.packages.push({
                id: this.packages.length + 1,
                payload_name: this.payload_name,
                payload_phone: this.payload_phone,
                payload_district: this.payload_district,
                dropoff_time: this.payload_dropoff_time,
                payload_no_of_pieces: this.payload_no_of_pieces,
            });
        },
        onPackageSubmit() {
            // let price = this.maximum_load
            if (this.checkUniqueCustomer() == true) {
                this.addCustomer();
            }

            this.payload_name = "";
            this.payload_phone = "";
            this.payload_district = "";
            this.payload_dropoff_time = "";
            this.payload_no_of_pieces = "";
            this.helperOrderCount = 0;
            this.$refs.packageForm.resetForm();

            let orderTypeList = JSON.parse(
                JSON.stringify(this.vehicleInformation)
            );

            // Calculation of the package order
            this.totalPrice =
                orderTypeList?.initial_price +
                (this.packages?.length - orderTypeList?.number_of_orders) *
                    ((orderTypeList?.threshold * orderTypeList?.initial_price) /
                        100);

            if (this.payload_helper === true) {
                this.helperOrderCount =
                    parseInt(this.packages?.length) -
                    orderTypeList?.number_of_orders;
                if (this.helperOrderCount > 0) {
                    this.totalPrice =
                        this.totalPrice +
                        orderTypeList.helper_price *
                            parseInt(this.helperOrderCount);
                }
            }

            if (this.packages?.length <= orderTypeList?.number_of_orders) {
                this.totalPrice = orderTypeList.initial_price;
            }

            if (this.orderCount < this.discountedOrders) {
                // Order discounted price
                let orderDiscountPrice =
                    (this.totalPrice * this.discountOrderPercentage) / 100;
                this.totalPrice -= orderDiscountPrice;
            }

            this.pricePerService = this.totalPrice / this.packages?.length;
        },
        getAllVehicleTypes() {
            getVehicleTypes({
                filter: {
                    is_allow_package_order: true,
                    store_id: this.storeId,
                },
            })
                .then((res) => {
                    let response = JSON.parse(JSON.stringify(res));
                    const vehicleTypeList = response.data.map((item) => {
                        return {
                            title:
                                localStorage.getItem("lang") == "ar"
                                    ? item?.type_ar
                                    : item?.type,
                            value: item?.id,
                            extra_service_price: item?.extra_service_price,
                            maximum_orders: item?.maximum_orders,
                            ...item,
                        };
                    });
                    this.vehicleTypeList = [...vehicleTypeList];
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        addOrder(data) {
            if (!this.order_type) {
                this.runToast(
                    this.$t("package_order_messages.select_order_type_prompt"),
                    "error"
                );
                return;
            } else if (!this.order_from) {
                this.runToast(
                    this.$t("package_order_messages.select_receiving_from"),
                    "error"
                );
                return;
            } else if (!this.packages?.length) {
                this.runToast(
                    this.$t("package_order_messages.package_details_prompt"),
                    "error"
                );
                return;
            }

            let packages = JSON.parse(JSON.stringify(this.packages));

            let finalData = {
                vehicle_type_id: this.order_type,
                pickup_address_id: this.order_from,
                type: "package",
                helper: this.payload_helper === true ? 1 : 0,
                package_order: packages?.map((item) => ({
                    name: item.payload_name,
                    mobile_no: item.payload_phone,
                    district: item.payload_district,
                    helper: this.payload_helper === true ? 1 : 0,
                    dropoff_time: item.dropoff_time,
                    no_of_pieces: item.payload_no_of_pieces,
                })),
            };
            this.submitSpinner = true;
            savePackageDetails(finalData, this.storeId)
                .then((res) => {
                    this.submitSpinner = false;
                    this.order_type = "";
                    this.order_from = "";
                    this.payload_name = "";
                    this.payload_phone = "";
                    this.payload_district = "";
                    this.payload_dropoff_time = "";
                    this.payload_no_of_pieces = "";
                    this.totalPrice = 0;
                    this.$refs.packageForm.resetForm();
                    this.$refs.addOrderRef.resetForm();
                    this.updateCredit();
                    this.$emit("close");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    this.apiError(response);
                });
            // createOrder(data, {
            //     store_id: this.storeId,
            // })
            //     .then((res) => {
            //         this.submitSpinner = false;
            //         this.$emit("close");
            //         this.$refs.addOrderRef.resetForm();
            //     })
            //     .catch((err) => {
            //         this.submitSpinner = false;
            //         this.$emit("close");
            //         this.$refs.addOrderRef.resetForm();
            //     });
        },
        getAddresses() {
            let storeId = this.storeId || null;
            if (!storeId) {
                return;
            }
            get({
                store_id: storeId,
            }).then((res) => {
                let response = JSON.parse(JSON.stringify(res));
                this.orderTypeList = response?.data?.map((item) => {
                    return {
                        title: item?.address_line1,
                        value: item?.id,
                    };
                });
            });
        },
        updateCredit() {
            me().then((res) => {
                this.$store.dispatch(
                    "setStoreCredit",
                    res.user?.stores[0]?.credit
                        ? res.user?.stores[0]?.credit
                        : 0
                );
            });
            this.creditForPlaceOrder(this.storeId);
        },
        getOrderCount() {
            if (this.storeId !== null) {
                getOrderCount({
                    store_id: this.storeId,
                }).then((res) => {
                    this.orderCount = res.order_count;
                });
            }
        },
        getSystemSetting() {
            getSettingKeyValue({
                filter: {
                    key:
                        "store_initial_discounted_orders,store_initial_discounted_order_percentage",
                },
            }).then((res) => {
                this.discountedOrders = res.store_initial_discounted_orders;
                this.discountOrderPercentage =
                    res.store_initial_discounted_order_percentage;
            });
        },
        discountedOrderTotalCalculation() {
            if (this.orderCount <= this.discountedOrders) {
                this.totalPrice = 100;
            }
        },
    },
};
</script>

<style lang="scss">
.modal {
    overflow: scroll !important;
    .packageForm {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .col-md-4 {
            margin-bottom: 10px;
        }
    }
    .package-col {
        max-width: 18%;
        margin: 0 7px;

        &:last-child {
            max-width: 18%;
            flex: 0 0 18%;
            margin-right: 0;
        }
        &:first-child {
            margin-left: 0;
        }
        .btn.base-button {
            width: 100%;
            margin-left: 0px !important;
        }
        .btn.btn-default {
            width: 100%;
            margin-left: 0px !important;
        }
    }
}
</style>
