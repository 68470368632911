<template>
    <div class="d-inline">
        <el-tooltip :content="$t('add_credit')" placement="top">
            <badge
                circle
                size="lg"
                class="bg-sky-dark text-secondary cursor-pointer"
                style="cursor: pointer"
                @click="optionModel()"
            >
                <img src="@/assets/svg/credit-card.svg" height="20" />
            </badge>
        </el-tooltip>
        <modal
            footerClasses="justify-content-center"
            :show="creditAddModel"
            @close="creditAddModel = false"
            size="sm"
            body-classes="p-0"
            v-loading="isLoading"
        >
            <template v-slot:header>
                <div
                    class="text-bold text-lg text-left border-b mt-2 mb-1"
                    style="text-align: left"
                >
                    {{ $t("addStoreCredit") }}
                </div>
            </template>

            <!-- Credit option model -->
            <div class="px-3 d-block">
                <div>
                    <div class="order-form-wrap">
                        <div class="w-100 mb-2">
                            <div class="order-form-wrap">
                                <el-radio
                                    v-model="creditOption"
                                    name="payment_method"
                                    class="mb-2 add-order-radio"
                                    label="add_credit"
                                    border
                                    @click="isShowAddCreditForm = true"
                                >
                                    {{ $t("add_credit") }}
                                </el-radio>
                                <el-radio
                                    v-model="creditOption"
                                    name="payment_method"
                                    class="mb-2 add-order-radio"
                                    label="add_transaction"
                                    border
                                    @click="isShowAddCreditForm = false"
                                >
                                    {{ $t("add_transaction") }}
                                </el-radio>
                            </div>
                        </div>
                    </div>
                    <Form @submit="showConfirmModel">
                        <!-- Bank details -->
                        <!-- <div
                            class="bank-transfer mx-0 my-2"
                            v-if="bankDetails && !isShowAddCreditForm"
                        >
                            <h3>{{ $t("bank_transfer") }}</h3>
                            <div
                                class="bank-detail-text-dark bank-transfer__detail"
                            >
                                <div class="d-flex">
                                    <p>{{ $t("bank_name") }} :</p>
                                    <b class="font-weight-700">
                                        {{ bankDetails?.bank_name }}
                                    </b>
                                </div>
                                <div>
                                    <p>{{ $t("account_holder_name") }}:</p>
                                    <b class="font-weight-700">
                                        {{ bankDetails?.bank_account_name }}</b
                                    >
                                </div>
                                <div class="d-flex">
                                    <p>{{ $t("iban_number") }} :</p>
                                    <b class="font-weight-700"
                                        >{{ bankDetails?.bank_account_number }}
                                    </b>
                                </div>
                            </div>
                        </div> -->

                        <!-- Add credit form -->
                        <label for="" class="my-1">{{ $t("amount") }}</label>
                        <Field
                            type="number"
                            rules="required|min_value:1|numeric"
                            :label="$t('amount')"
                            class="form-control"
                            :placeholder="$t('amount')"
                            name="credit"
                            v-model="credit"
                        />
                        <ErrorMessage name="credit" class="error" />

                        <div v-if="!isShowAddCreditForm">
                            <label for="name">{{ $t("bank_name") }}</label>
                            <Field
                                type="text"
                                rules="required"
                                class="form-control"
                                :label="$t('bank_name')"
                                :placeholder="$t('bank_name')"
                                name="bank_name"
                                v-model="bank_name"
                                @change="handleBankName"
                            />
                            <ErrorMessage name="bank_name" class="error" />

                            <label for="name">{{
                                $t("settings_page.bank_account_name")
                            }}</label>
                            <Field
                                type="text"
                                rules="required"
                                class="form-control"
                                name="bank_account_name"
                                v-model="bank_account_name"
                                :placeholder="
                                    $t('settings_page.bank_account_name')
                                "
                                :label="$t('settings_page.bank_account_name')"
                                @change="handleBankAccountName"
                            />
                            <ErrorMessage
                                name="bank_account_name"
                                class="error"
                            />
                        </div>
                        <div class="w-full text-center mt-3">
                            <base-button
                                class="submit option-submit-btn"
                                native-type="submit"
                            >
                                {{ $t("confirm") }}</base-button
                            >

                            <base-button @click.native="creditAddModel = false">
                                {{ $t("cancel") }}</base-button
                            >
                        </div>
                    </Form>
                </div>
            </div>
        </modal>

        <!-- Confirmation model -->
        <modal
            footerClasses="justify-content-center"
            :show="showModal"
            @close="showModal = false"
            size="sm"
            body-classes="p-0"
            v-loading="isLoading"
        >
            <template v-slot:header>
                <div
                    class="text-bold text-lg text-left border-b mt-2 mb-1"
                    style="text-align: left"
                >
                    {{ $t("confirmStoreCredit") }}
                </div>
            </template>

            <div class="px-3 d-block text-center">
                <h2 class="text-break credit-transfer-title font-weight-900">
                    {{
                        $t("providerCreditTransferAlert", {
                            amount: credit,
                            name: currentRow.name,
                        })
                    }}
                </h2>
            </div>

            <template v-slot:footer>
                <div class="w-full text-center mt-3">
                    <base-button
                        @click.native="creditTransferData"
                        :loading="submitSpinner"
                    >
                        {{ $t("yes") }}</base-button
                    >

                    <base-button @click="optionModel()">
                        {{ $t("no") }}</base-button
                    >
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { addCredit } from "@/api/store";
import { bankTransaction } from "@/api/credit";
import { getSettingKeyValue } from "@/api/systemSetting";

export default {
    data() {
        return {
            showModal: false,
            isLoading: true,
            submitSpinner: false,
            creditAddModel: false,
            credit: null,
            creditOption: "add_credit",
            isShowAddCreditForm: true,
            bankDetails: null,
            bank_account_name: "",
            bank_name: "",
        };
    },

    props: {
        id: {
            type: [String, Number],
        },
        currentRow: {
            type: Object,
            default: () => {},
        },
    },

    mixins: [mixin],

    methods: {
        optionModel() {
            this.creditAddModel = true;
            this.showModal = false;
            this.isLoading = false;
        },
        showConfirmModel(data) {
            this.creditAddModel = false;
            this.showModal = true;
            this.isLoading = false;
        },

        creditTransferData() {
            this.submitSpinner = true;
            if (this.creditOption == "add_transaction") {
                let bankTransactionData = {
                    credit: this.credit,
                    bank_name: this.bank_name,
                    bank_account_name: this.bank_account_name,
                    store_id: this.id,
                };
                bankTransaction(bankTransactionData)
                    .then((res) => {
                        this.submitSpinner = false;
                        this.runToast(res.message, "success");
                        this.showModal = false;
                        console.log(this.hasRole(1));
                        if (this.hasRole(1)) {
                            this.$router.push({ name: "Pending Transaction" });
                        } else {
                            this.$emit("creditAddModalCompleted");
                            this.credit = null;
                            this.bank_name = null;
                            this.bank_account_name = null;
                        }
                    })
                    .catch(({ response }) => {
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                        this.submitSpinner = false;
                    });
            } else {
                addCredit(this.id, { credit: this.credit })
                    .then((res) => {
                        this.submitSpinner = false;
                        this.isLoading = false;
                        this.showModal = false;
                        this.$emit(
                            "creditAddModalCompleted",
                            this.currentRow.credit,
                            this.currentRow.name
                        );
                        this.credit = null;
                        this.runToast(res.message, "success");
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        this.apiError(response);
                    });
            }
        },
        // getBankDetails() {
        //     getSettingKeyValue({
        //         filter: {
        //             key:
        //                 "bank_logo,bank_name,bank_account_name,bank_account_number",
        //         },
        //     })
        //         .then((res) => {
        //             this.bankDetails = res;
        //         })
        //         .catch(({ response }) => {
        //             // Below method is a common Mixin method that shows error on screen through toast Notification
        //             this.apiError(response);
        //         });
        // },
    },
};
</script>

<style lang="scss">
button.btn.base-button.btn-default.option-submit-btn {
    margin-left: 0px !important;
}
.bank-detail-text-dark p {
    color: #000 !important;
}
</style>
