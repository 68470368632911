<template>
    <div class="d-inline ml-2" style="position: absolute; margin-top: -3px">
        <el-tooltip :content="$t('status_info')" placement="top">
            <template #content>
                <p :class="$store.getters.getLang == 'ar' ? 'tooltip-rtl' : ''">
                    {{ $t("zidship_status") }}:
                    {{ $t(`${order.zidship_order_status}`) }}
                </p>
                <p
                    :class="$store.getters.getLang == 'ar' ? 'tooltip-rtl' : ''"
                    style="width: 350px"
                >
                    {{ $t("status_info_content." + order.status) }}
                </p>
            </template>
            <badge
                circle
                size="md"
                class="cursor-pointer bg-sky-dark text-secondary"
                style="cursor: pointer"
            >
                <i class="mx-1 fas fa-info"></i>
            </badge>
        </el-tooltip>
    </div>
</template>

<script>
import mixin from "@/mixins/common";

export default {
    data() {
        return {
            isShowStatusInfoModal: false,
            statusReasonContent: "",
        };
    },
    props: ["order"],
    mixins: [mixin],

    methods: {},
};
</script>
