<template>
    <div class="d-inline">
        <!-- Modal props show is for toggling Modal Display 
        @close child event is for closing modal -->
        <!-- Modal  -->
        <modal
            footerClasses="justify-content-center"
            :show="showModal"
            @close="$emit('close')"
            size="md"
            body-classes="p-0"
        >
            <template v-slot:header>
                <div>
                    <div class="font-weight-700 text-lg mt-2 mb-1">
                        {{ $t("add_in_town_order") }}
                    </div>
                </div>
            </template>

            <div class="pt-0 px-4">
                <Form @submit="addOrder" ref="addOrderRef">
                    <!-- below customer-edit-form Component contains the Form elements of edit form 
                    if role = customer then the component will be used in modal -->
                    <div class="d-flex flex-column space-y">
                        <div class="">
                            <h3>{{ $t("vehicle_type") }}</h3>
                            <Field
                                v-slot="{ field }"
                                name="vehicle_type_id"
                                rules="required"
                                :label="$t('vehicle_type')"
                                v-model="vehicleType"
                            >
                                <div class="radio-wrap radio-wrap--custom">
                                    <el-radio-group class="vehicle-type-box">
                                        <div
                                            v-for="option in vehicleTypes"
                                            :key="option.id"
                                        >
                                            <el-radio-button
                                                v-bind="field"
                                                :label="option.value"
                                                name="vehicle_type_id"
                                            >
                                                <span
                                                    style="white-space: normal"
                                                    >{{ option.label }}</span
                                                >
                                                <img
                                                    :src="option.vehicleImage"
                                                    v-if="option.vehicleImage"
                                                />
                                                <img
                                                    v-else
                                                    src="@/assets/svg/Truck.svg"
                                                />
                                            </el-radio-button>
                                        </div>
                                    </el-radio-group>
                                </div>
                            </Field>
                            <ErrorMessage
                                name="vehicle_type_id"
                                class="error"
                            />
                        </div>

                        <div class="">
                            <h3>{{ $t("package_order_messages.helper") }}</h3>
                            <el-checkbox
                                class="text-right helper-checkbox"
                                name="payload_helper"
                                v-model="payload_helper"
                                :disabled="!vehicleType"
                                @change="helperChange"
                            >
                            </el-checkbox>
                            <span v-if="payload_helper" class="pl-2">
                                {{ $t('helper_note', { amount: helperPrice }) }}
                            </span>
                        </div>
                        
                        <div class="field-wrap">
                            <Field
                                type="text"
                                rules="required|max:20"
                                :label="$t('name')"
                                :class="form.customer_name && 'notEmpty'"
                                class="form-control"
                                name="name"
                                v-model="form.customer_name"
                            />
                            <label class="label-modal">
                                {{ $t("customer_name") }}</label
                            >
                            <ErrorMessage name="name" class="error" />
                        </div>

                        <div class="field-wrap">
                            <Field
                                type="number"
                                rules="required|digits:9"
                                :label="$t('customer_mobile_no')"
                                :class="form.customer_mobile_no && 'notEmpty'"
                                class="form-control"
                                :placeholder="$t('customer_mobile_no')"
                                name="mobile_no"
                                v-model="form.customer_mobile_no"
                            />
                            <label class="label-modal">
                                {{ $t("customer_mobile_no") }}</label
                            >

                            <ErrorMessage name="mobile_no" class="error" />
                        </div>

                        <h3>{{ $t("invoice_and_payment_info") }}</h3>
                        <div class="order-form-wrap">
                            <div class="w-100 field-wrap add-order-radio">
                                <Field
                                    type="number"
                                    rules="min_value:0"
                                    :label="$t('invoice_ref_no')"
                                    :class="form.invoice_ref_no && 'notEmpty'"
                                    class="form-control"
                                    :placeholder="$t('invoice_ref_no')"
                                    name="invoice_ref_no"
                                    v-model="form.invoice_ref_no"
                                />
                                <label for="" class="label-modal">{{
                                    $t("invoice_ref_no")
                                }}</label>
                                <ErrorMessage
                                    name="invoice_ref_no"
                                    class="error"
                                />
                            </div>

                            <div class="w-100 field-wrap add-order-radio">
                                <Field
                                    type="number"
                                    rules="min_value:0"
                                    :label="$t('invoice_total_price')"
                                    :class="
                                        form.invoice_total_price && 'notEmpty'
                                    "
                                    class="form-control"
                                    :placeholder="$t('invoice_total_price')"
                                    name="invoice_total_price"
                                    v-model="form.invoice_total_price"
                                />
                                <label for="" class="label-modal">{{
                                    $t("invoice_total_price")
                                }}</label>
                                <ErrorMessage
                                    name="invoice_total_price"
                                    class="error"
                                />
                            </div>
                        </div>

                        <div class="">
                            <Field
                                rules="required"
                                v-model="paymentOption"
                                name="payment_method"
                                :label="$t('order_payment_method')"
                            >
                                <div class="order-form-wrap">
                                    <el-radio
                                        v-model="paymentOption"
                                        name="payment_method"
                                        class="mb-2 add-order-radio"
                                        label="customer_payment"
                                        border
                                        >{{ $t("customer_payment") }}
                                    </el-radio>
                                    <el-radio
                                        v-model="paymentOption"
                                        name="payment_method"
                                        class="mb-2 add-order-radio"
                                        label="store_credit"
                                        border
                                    >
                                        {{ $t("store_credit") }}
                                    </el-radio>
                                    <el-radio
                                        v-model="paymentOption"
                                        name="payment_method"
                                        class="mb-2 add-order-radio"
                                        label="pay_now"
                                        border
                                    >
                                        {{ $t("pay_now") }}
                                    </el-radio>
                                </div>
                            </Field>
                            <ErrorMessage name="payment_option" class="error" />
                        </div>

                        <div class="">
                            <Field
                                rules="required"
                                v-model="orderStatus"
                                name="order_status"
                                :label="$t('order_status')"
                            >
                                <div class="order-form-wrap">
                                    <el-radio
                                        v-model="orderStatus"
                                        name="order_status"
                                        class="mb-2 add-order-radio"
                                        label="confirmed_directly"
                                        border
                                        @change="handleOrderStatusChange"
                                    >
                                        {{ $t("order_status_confirmed") }}
                                    </el-radio>
                                    <el-radio
                                        v-model="orderStatus"
                                        name="order_status"
                                        class="mb-2 add-order-radio"
                                        label="pending"
                                        border
                                        @change="handleOrderStatusChange"
                                    >
                                        {{ $t("order_status_pending") }}
                                    </el-radio>
                                </div>
                            </Field>
                            <ErrorMessage name="order_status" class="error" />
                        </div>
                        <div class="">
                            <h3>{{ $t("pickup_address") }}</h3>
                            <Field
                                v-slot="{ field }"
                                name="pickup_address_id"
                                rules="required"
                                :label="$t('pickup_address')"
                                v-model="form.pickup_address"
                            >
                                <el-select
                                    class="d-block"
                                    name="pickup_address_id"
                                    v-bind="field"
                                    :placeholder="$t('pickup_address')"
                                    v-model="form.pickup_address"
                                    @change="handlePickupCityChange"
                                    filterable
                                >
                                    <el-option
                                        v-for="option in addressesOptions"
                                        class="select-danger"
                                        :value="option.value"
                                        :label="option.label"
                                        :city="option.city_id"
                                        :key="option.label"
                                    >
                                    </el-option>
                                </el-select>
                            </Field>
                            <ErrorMessage name="pickup_address_id" class="error" />
                        </div>


                        <div
                            class="dropoff-address-container"
                            v-if="orderStatus === 'confirmed_directly'"
                        >
                            <div class="heading-with-note">
                                <h3>{{ $t("dropoff_address") }}</h3>
                            </div>
                            <div class="d-flex">
                                <Field
                                    v-slot="{ field }"
                                    name="dropoff_address"
                                    :rules="dropoffAddressRules"
                                    :label="$t('dropoff_address')"
                                    v-model="form.dropoff_address"
                                >
                                    <el-input
                                        class="d-block"
                                        name="dropoff_address"
                                        v-bind="field"
                                        :placeholder="$t('dropoff_address')"
                                        v-model="form.dropoff_address"
                                        disabled
                                    />
                                </Field>
                                <el-button @click="openGoogleMap">{{$t('select_on_map')}}</el-button>
                            </div>
                            <ErrorMessage
                                name="dropoff_address"
                                class="error"
                            />
                        </div>

                        <div class="">
                            <h3>{{ $t("dropoff_time") }}</h3>
                            <Field
                                name="dropoff_time"
                                rules="required"
                                :label="$t('dropoff_time')"
                                v-model="form.dropoff_time"
                            >
                                <el-select
                                    class="d-block"
                                    name="dropoff_time"
                                    :placeholder="$t('dropoff_time')"
                                    v-model="form.dropoff_time"
                                    filterable
                                >
                                    <el-option
                                        v-for="option in dropOffTimeOptions"
                                        class="select-danger"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="option.label"
                                    >
                                    </el-option>
                                </el-select>
                            </Field>
                            <ErrorMessage name="dropoff_time" class="error" />
                        </div>

                        <!-- Image upload -->
                        <div>
                            <h3>{{ $t("selectImages") }}</h3>
                            <input
                                type="file"
                                @change="previewImage"
                                accept="image/*"
                                name="images"
                                class="rounded-0"
                                multiple
                            />
                        </div>

                        <div class="">
                            <Field
                                type="number"
                                rules="required|min_value:0"
                                :label="$t('no_of_pieces')"
                                class="form-control"
                                :placeholder="$t('no_of_pieces')"
                                name="no_of_pieces"
                                v-model="form.no_of_pieces"
                            />
                            <ErrorMessage name="no_of_pieces" class="error" />
                        </div>
                    </div>

                    <div class="w-full text-center mt-3">
                        <base-button
                            :loading="submitSpinner"
                            native-type="submit"
                            class="text-white bg-primary"
                        >
                            {{ $t("create") }}
                        </base-button>
                        <input
                            type="button"
                            class="btn btn-default text-white"
                            @click="
                                $refs.addOrderRef.resetForm();
                                $emit('close');
                            "
                            :value="$t('close')"
                        />
                    </div>
                </Form>
            </div>
        </modal>

        <!-- Payment Modal -->
        <modal
            :show="showPaymentModal"
            @close="showPaymentModal = false"
            size="md"
            body-classes="p-0"
        >
            <template v-slot:header>
                <div class="font-weight-700 text-lg mt-2 mb-1">
                    {{ $t("enter_card_details") }}
                </div>
            </template>

            <div class="pt-0 px-4">
                <Form @submit="submitPayment" ref="paymentFormRef">
                    <div class="field-wrap">
                        <label for="name_on_card">{{ $t('name_on_card') }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            name="name_on_card"
                            v-model="cardDetail.name"
                        />
                        <ErrorMessage name="name_on_card" class="error" />
                    </div>

                    <div class="field-wrap">
                        <label for="cardNumber">{{ $t('card_number') }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            name="cardNumber"
                            v-model="cardDetail.cardNumber"
                        />
                        <ErrorMessage name="cardNumber" class="error" />
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <label for="expiryMonth">{{ $t('expiry_month') }}</label>
                            <Field
                                type="text"
                                rules="required|digits:2"
                                class="form-control"
                                name="expiryMonth"
                                v-model="cardDetail.expiryMonth"
                            />
                            <ErrorMessage name="expiryMonth" class="error" />
                        </div>
                        <div class="col-md-6">
                            <label for="expiryYear">{{ $t('expiry_year') }}</label>
                            <Field
                                type="text"
                                rules="required|digits:4"
                                class="form-control"
                                name="expiryYear"
                                v-model="cardDetail.expiryYear"
                            />
                            <ErrorMessage name="expiryYear" class="error" />
                        </div>
                    </div>

                    <div class="field-wrap">
                        <label for="cvv">{{ $t('cvv') }}</label>
                        <Field
                            type="text"
                            rules="required|digits:3"
                            class="form-control"
                            name="cvv"
                            v-model="cardDetail.cvv"
                        />
                        <ErrorMessage name="cvv" class="error" />
                    </div>

                    <div class="w-full text-center mt-3">
                        <base-button
                            :loading="submitSpinner"
                            native-type="submit"
                            class="text-white bg-primary"
                        >
                            {{ $t("submit") }}
                        </base-button>
                    </div>
                </Form>
            </div>
        </modal>

        <!-- Google Map Modal -->
        <modal
            :show="showGoogleMap"
            @close="showGoogleMap = false"
            size="lg"
            body-classes="p-0"
        >
            <template v-slot:header>
                <div class="font-weight-900 text-lg text-center mt-2 mb-1">
                    {{ $t("select_dropoff_address") }}
                </div>
            </template>
            <div class="px-3 d-block">
                <div class="field-wrap">
                    <label for="city_id" class="form-control-label mt-3">
                        {{ $t("city") }}
                    </label>
                    <Field
                        v-slot="{ field }"
                        name="city_id"
                        rules="required" 
                        :label="$t('city')"
                    >
                        <el-select
                            v-model="dropoffCityId"
                            class="d-block"
                            :placeholder="$t('select')"
                            @change="changeCity(dropoffCityId)"
                            v-bind="field" 
                        >
                            <el-option
                                v-for="option in cities"
                                :key="option.id"
                                :label="lang == 'ar' ? option.name_ar : option.name"
                                :value="option.id"
                            />
                        </el-select>
                    </Field>
                    <ErrorMessage name="city_id" class="error" />
                </div>
                <div class="field-wrap" v-if="districtOptions && districtOptions.length">
                    <label for="district_id" class="form-control-label mt-3">{{
                        $t("district")
                    }}</label>
                    <el-select
                        v-model="dropoffDistrictId"
                        class="d-block"
                        :placeholder="$t('select')"
                        @change="changeDistrict(dropoffDistrictId)"
                    >
                    <el-option
                        v-for="option in districtOptions"
                        :key="option.id"
                        :label="lang == 'ar' ? option.name_ar : option.name"
                        :value="option.id"
                    />
                    </el-select>
                </div>
                <GoogleMaps
                    :lat="latitude"
                    :lon="longitude"
                    :zoom_level="zoom_level"
                    @locationChanged="locationChanged"
                />
                <div class="w-full text-center my-4">
                    <base-button
                        native-type="button"
                        class="text-white bg-primary"
                        @click="confirmDropoffAddress"
                    >
                        {{ $t("confirm") }}
                    </base-button>
                    <input
                        type="button"
                        :value="$t('close')"
                        @click="showGoogleMap = false"
                        class="btn bg-primary text-white"
                    />
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import modal from '@/components/Modal.vue';
import baseButton from '@/components/BaseButton.vue';
import GoogleMaps from '@/components/GoogleMaps.vue';
import db from "@/firebase.js";
import { me } from "@/api/users";
import mixin from "@/mixins/common";
import { get, getDistricts as getDistrictList } from "@/api/addresses";
import { getVehicleTypes } from "@/api/vehicleType";
import { createOrder, calculateInTownOrderPrice } from "@/api/orders";
import { collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { get as getCityList } from "@/api/cities";
import axios from 'axios';

export default {
    components: {
        Form,
        Field,
        ErrorMessage,
        modal,
        baseButton,
        GoogleMaps
    },
    data() {
        return {
            submitSpinner: false,
            showPaymentModal: false,
            form: {
                customer_name: null,
                customer_mobile_no: null,
                pickup_address: null,
                dropoff_address: null,
                dropoff_time: null,
                invoice_ref_no: null,
                invoice_total_price: null,
                no_of_pieces: null,
                assigned_locations: {
                    dropoff_address: {
                        latitude: null,
                        longitude: null,
                        address_line1: null,
                        city_id: null, 
                        district_id: null
                    },
                },
            },
            vehicleType: null,
            vehicleTypes: [],
            dropOffTimeOptions: [
                {
                    value: "morning",
                    label: this.$t("morning"),
                },
                {
                    value: "evening",
                    label: this.$t("evening"),
                },
                {
                    value: "default",
                    label: this.$t("unspecified"),
                },
            ],
            pickupAddressOptions: [],
            paymentOption: null,
            addressesOptions: [],
            extraServicePrice: false,
            orderImages: [],
            payload_helper: false,
            helperPrice: 0,
            totalPrice: 0,
            paymentPrice: 0,
            orderStatus: "pending",
            showGoogleMap: false,
            latitude: null,
            longitude: null,
            pickupCityId: null,
            dropoffCityId: null,
            submittedOrder: null,
            dropoffDistrictId: null,
            cities: [],
            districtOptions: [],
            lang: localStorage.getItem("lang"),
            cardDetail: {
                name: null,
                cardNumber: null,
                expiryMonth: null,
                expiryYear: null,
                cvv: null,
            },
        };
    },
    computed: {
        dropoffAddressRules() {
            if (this.orderStatus === 'confirmed_directly') {
                return 'required';
            }
            return '';
        },
    },
    props: ["showModal"],
    mixins: [mixin],
    watch: {
        paymentOption(newVal) {
            if (newVal == "store_credit") {
                var storePostPaidOrder = this.$store.getters.storePostpaidOrder;
                var storeCredit = this.$store.getters.storeCredit;

                if (storePostPaidOrder.is_enabled && storeCredit < 1) {
                    if (
                        Math.abs(storeCredit) >= storePostPaidOrder.max_amount
                    ) {
                        this.store_credit_alert = true;
                        this.storeCreditAddMsg = this.$t(
                            "postpaid_credit_reached_msg"
                        );
                    }
                } else if (storeCredit < 1) {
                    this.store_credit_alert = true;
                    this.storeCreditAddMsg = this.$t("store_credit_msg");
                }
            } else {
                this.store_credit_alert = false;
            }
        },
        vehicleType(newValue) {
            const vehicleType = this.vehicleTypes.find(
                (item) => item.value === newValue
            );
            if (vehicleType) {
                this.helperPrice = vehicleType.helper_price || 0;
                this.totalPrice = this.form.invoice_total_price || 0;
                if (this.payload_helper) {
                    this.totalPrice += this.helperPrice;
                }
            }
        },
        form: {
            handler(newValue) {
                this.totalPrice = newValue.invoice_total_price || 0;
                if (this.payload_helper) {
                    this.totalPrice += this.helperPrice;
                }
            },
            deep: true,
        },
    },
    created() {
        this.getAllVehicleTypes();
        this.getAddresses();
        this.getCities();
    },
    methods: {
        async previewImage(event) {
            this.orderImages = event.target.files;
        },
        getAllVehicleTypes() {
            getVehicleTypes({
                filter: {
                    store_id: this.storeId,
                    is_allow_business_order: true,
                },
            })
                .then((res) => {
                    const vehicleTypeList = res.data.map((item) => {
                        return {
                            value: item["id"],
                            label:
                                localStorage.getItem("lang") == "ar"
                                    ? item["type_ar"]
                                    : item["type"],
                            vehicleImage: item["vehicle_image"],
                            helper_price: item["helper_price"],
                        };
                    });
                    this.vehicleTypes = [...vehicleTypeList];
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        handlePickupCityChange(value) {
            const selectedOption = this.addressesOptions.find(option => option.value === value);
            if (selectedOption) {
                this.pickupCityId = selectedOption.city_id;
            }
        },
        helperChange() {
            if (this.payload_helper) {
                this.totalPrice += this.helperPrice;
            } else {
                this.totalPrice -= this.helperPrice;
            }
        },
        async addOrder(data) {
            if (this.orderImages.length > 4) {
                this.runToast(this.$t("select4Images"), "error");
                return;
            }
            if(this.form.assigned_locations.dropoff_address.latitude && this.form.assigned_locations.dropoff_address.city_id == null){
                this.runToast(this.$t("city_not_selected_error"), "error");
                return;
            }
            if (this.form.assigned_locations.dropoff_address.latitude && this.form.assigned_locations.dropoff_address.city_id !== this.pickupCityId){
                this.runToast(this.$t("out_of_town_error"), "error");
                return;
            }
            data["extra_service_price"] = this.extraServicePrice;
            data["helper"] = this.payload_helper ? 1 : 0;
            data["total_price"] = this.totalPrice;
            if (this.orderStatus === "confirmed_directly") {
                data["is_confirmed"] = true;
            }
            if (this.form.dropoff_address){
                if (!data["assigned_locations"]) {
                    data["assigned_locations"] = {};
                }
                data["assigned_locations"]["dropoff_address"] = {
                    latitude: this.form.assigned_locations.dropoff_address.latitude,
                    longitude: this.form.assigned_locations.dropoff_address.longitude,
                    address_line1: this.form.assigned_locations.dropoff_address.address_line1,
                    city_id: this.form.assigned_locations.dropoff_address.city_id,
                    district_id: this.form.assigned_locations.dropoff_address.district_id,
                };
                data["assigned_locations"] = JSON.stringify(data["assigned_locations"]);
            }

            if (this.paymentOption === 'pay_now') {
                calculateInTownOrderPrice(data, {
                    store_id: this.storeId,
                }).then(value => {
                    this.paymentPrice = value;
                    this.submittedOrder = data;
                });
                this.showPaymentModal = true;
                return;
            }
            
            this.submitSpinner = true;

            createOrder(data, {
                store_id: this.storeId,
            })
                .then((res) => {
                    if (
                        this.orderImages != "undefined" &&
                        this.orderImages.length > 0
                    ) {
                        this.uploadImageInFirestore(res.id);
                    }
                    this.submitSpinner = false;
                    this.$emit("close");
                    this.updateCredit();
                    this.$refs.addOrderRef.resetForm();
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    this.apiError(response);
                });
        },
        async submitPayment() {
            const isValid = await this.$refs.paymentFormRef.validate();
            if (!isValid) {
                return;
            }

            const data = {
                publishable_api_key: process.env.VUE_APP_MOYASAR_PUBLISHABLE_API_KEY,
                amount: Math.round(this.paymentPrice * 100),
                currency: "SAR",
                callback_url: process.env.VUE_APP_MOYASAR_PAYMENT_CALLBACK_URL,
                source: {
                    type: "creditcard",
                    name: this.cardDetail.name,
                    number: this.cardDetail.cardNumber,
                    month: this.cardDetail.expiryMonth,
                    year: this.cardDetail.expiryYear,
                    cvc: this.cardDetail.cvv,
                },
                order: this.submittedOrder
            };

            try {
                const response = await axios.post('https://api.moyasar.com/v1/payments', data);
                this.showPaymentModal = false;
                this.form.invoice_total_price = response.data.amount / 100;
                this.paymentOption = "store_credit";
                this.addOrder();

                window.location.href = response.data.source.transaction_url;
            } catch (error) {
                this.apiError(error.response);
            }
        },
        getAddresses() {
            if (this.storeId !== null) {
                get({
                    store_id: this.storeId,
                }).then((res) => {
                    res.data.forEach((item) => {
                        this.addressesOptions.push({
                            label: item.address_line1,
                            value: item.id,
                            city_id: item.city_id
                        });
                    });
                });
            }
        },
        updateCredit() {
            me().then((res) => {
                this.$store.dispatch(
                    "setStoreCredit",
                    res.user?.stores[0]?.credit
                        ? res.user?.stores[0]?.credit
                        : 0
                );
            });
            this.creditForPlaceOrder(this.storeId);
        },
        uploadImageInFirestore(orderId) {
            let i;
            for (i = 0; i < this.orderImages.length; i++) {
                const file = this.orderImages[i];
                const storage = getStorage();
                const storageRef = ref(
                    storage,
                    `orders/${orderId}/images/` +
                        Math.ceil(Math.random() * 1000000) +
                        "-" +
                        file.name
                );
                uploadBytes(storageRef, file).then((snapshot) => {
                    getDownloadURL(storageRef)
                        .then((url) => {
                            const dbRef = addDoc(
                                collection(
                                    db,
                                    `one8Order/${orderId}/order/order/images`
                                ),
                                {
                                    image: url,
                                    time: Math.round(+new Date() / 1000),
                                }
                            );
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                });
            }
            this.orderImages = [];
        },
        openGoogleMap() {
            this.showGoogleMap = true;
        },
        locationChanged(event) {
            this.latitude = event.latitude;
            this.longitude = event.longitude;
        },
        confirmDropoffAddress() {
            const geocoder = new google.maps.Geocoder();
            const latlng = {
                lat: this.latitude,
                lng: this.longitude,
            };

            geocoder.geocode({ location: latlng }, (results, status) => {
                if (status === "OK" && results[0]) {
                    this.form.dropoff_address = results[0].formatted_address;
                    this.form.assigned_locations.dropoff_address.latitude = this.latitude;
                    this.form.assigned_locations.dropoff_address.longitude = this.longitude;
                    this.form.assigned_locations.dropoff_address.address_line1 = results[0].formatted_address;
                    this.form.assigned_locations.dropoff_address.city_id = this.dropoffCityId;
                    this.form.assigned_locations.dropoff_address.district_id = this.dropoffDistrictId;
                    this.showGoogleMap = false;
                }
            });
        },
        getCities() {
            getCityList({
                limit: -1,
            })
                .then((res) => {
                    this.cities = res.data;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        changeCity(city_id) {
            this.zoom_level = 11;
            var cityName = this.cities.filter((city) => city.id === city_id);

            const geocoder = new google.maps.Geocoder();
            const address = cityName[0]["name"] ? cityName[0]["name"] : "";

            geocoder.geocode({ address }, (results, status) => {
                if (status === "OK") {
                    this.form.latitude = results[0].geometry.location.lat();
                    this.form.longitude = results[0].geometry.location.lng();

                    this.latitude = results[0].geometry.location.lat();
                    this.longitude = results[0].geometry.location.lng();
                }
            });
            this.getDistricts(city_id);
            this.form.city = cityName[0]["name_ar"]
                ? cityName[0]["name_ar"]
                : "";
        },
        getDistricts(city_id) {
            this.districtLoading = true;
            this.form.district_id = null;
            getDistrictList({
                limit: -1,
                filter: {
                    city_id: city_id,
                },
            })
                .then((res) => {
                    this.districtLoading = false;
                    this.districtOptions = res.data;
                })
                .catch(({ response }) => {
                    this.districtLoading = false;
                    this.apiError(response);
                });
        },
        changeDistrict(district_id) {
            this.zoom_level = 18;
            var districtName = this.districtOptions.filter(
                (district) => district.id === district_id
            );
            const geocoder = new google.maps.Geocoder();
            const address = districtName[0]["name"]
                ? districtName[0]["name"] + "," + this.form.city
                : "";

            geocoder.geocode({ address }, (results, status) => {
                if (status === "OK") {
                    this.form.latitude = results[0].geometry.location.lat();
                    this.form.longitude = results[0].geometry.location.lng();
                    this.latitude = results[0].geometry.location.lat();
                    this.longitude = results[0].geometry.location.lng();
                }
            });
        },
        handleOrderStatusChange() {
            if (this.orderStatus === 'confirmed_directly') {
                this.form.dropoff_address = '';
            } else {
                this.form.dropoff_address = null;
            }
        },
    },
};
</script>

<style>
.radio-wrap label.el-radio-button {
    height: 100%;
}
.radio-wrap--custom
    input[type="radio"].el-radio-button__orig-radio:checked
    + .el-radio-button__inner {
    background: #fff !important;
    border: 2px solid #7368fb !important;
    color: #7368fb !important;
    box-shadow: none !important;
}
.radio-wrap .el-radio-group {
    align-items: stretch !important;
}
.vehicle-type-box .vehicle-type-box,
.vehicle-type-box .el-radio-button__inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dropoff-address-container {
    display: flex;
    flex-direction: column;
}
.heading-with-note {
    display: flex;
    align-items: end;
    justify-content: space-between;
}
.heading-with-note h3 {
    margin-right: 10px; 
}
.optional-note {
    font-size: 0.875em; 
    color: #6c757d; 
    margin: 0;
}
.field-wrap {
    margin-bottom: 15px;
}
</style>
