<template>
    <div class="container-fluid">
        <NavAlertBar />
        <div class="d-flex w-100 align-items-center justify-content-between">
            <div>
                <h1 class="mb-0 text-secondary">
                    {{ $t($route.meta?.title) }}
                </h1>
            </div>
            <div class="d-flex align-items-center top-navbar-section">
                <!-- Navbar links -->
                <ul class="navbar-nav align-items-center ml-md-auto">
                    <li class="nav-item d-xl-none">
                        <!-- Sidenav toggler -->
                        <div
                            class="pr-3 sidenav-toggler"
                            :class="{
                                active: $sidebar.showSidebar,
                                'sidenav-toggler-dark': type === 'default',
                                'sidenav-toggler-light': type === 'light',
                            }"
                            @click="toggleSidebar"
                        >
                            <div class="sidenav-toggler-inner">
                                <i class="sidenav-toggler-line"></i>
                                <i class="sidenav-toggler-line"></i>
                                <i class="sidenav-toggler-line"></i>
                            </div>
                        </div>
                    </li>
                </ul>

                <!-- Avatar DropDown -->
                <base-dropdown
                    title-classes="arrow-none p-0 badge badge-primary badge-pill badge-sm bg-sky border-0"
                >
                    <template v-slot:title>
                        <span class="avatar avatar-sm rounded-circle">
                            <img
                                src="@/assets/images/icons/common/user.png"
                                class="avatar-sm rounded-circle"
                                alt="Profile"
                            />
                        </span>
                    </template>
                    <a @click="logoutUser" class="dropdown-item">
                        <i class="ni ni-user-run"></i>
                        <span>{{ $t("logout") }}</span>
                    </a>
                </base-dropdown>

                <base-dropdown
                    title-classes="arrow-none p-0 badge badge-primary badge-pill badge-sm bg-sky border-0"
                    menu-classes="dropdown-menu-xl dropdown-menu-right py-0 notification-list"
                    @change="getNotification($event)"
                >
                    <template v-slot:title>
                        <i
                            class="notification-top"
                            :class="
                                unReadNotificationCount
                                    ? 'notification-dot'
                                    : ''
                            "
                        >
                            <img
                                src="@/assets/svg/notification.svg"
                                style="opacity: 0.5; width: 20px"
                                alt="notification"
                                data-action="search-show"
                                data-target="#navbar-search-main"
                            />
                        </i>
                    </template>
                    <div v-loading="isNotificationLoading"></div>
                    <div
                        v-if="notifications.length > 0"
                        class="notification-listing"
                    >
                        <div>
                            <div
                                class="list-group list-group-flush px-2 py-1"
                                v-for="(notification, index) in notifications"
                                :key="index"
                            >
                                <a
                                    class="list-group-item list-group-item-action"
                                    :class="
                                        notification.read_at != null
                                            ? 'unread-notification'
                                            : ''
                                    "
                                    @click="notificationClick(notification)"
                                >
                                    <div class="row align-items-center">
                                        <div class="col ml--2">
                                            <div
                                                class="d-flex justify-content-between align-items-center"
                                            >
                                                <div>
                                                    <h4 class="mb-0 text-sm">
                                                        {{
                                                            currentLang == "ar"
                                                                ? notification.title_ar
                                                                : notification.title
                                                        }}
                                                    </h4>
                                                </div>
                                                <div
                                                    class="text-right text-muted"
                                                >
                                                    <small>{{
                                                        notification.display_created_at
                                                    }}</small>
                                                </div>
                                            </div>
                                            <p class="text-sm mb-0">
                                                {{
                                                    currentLang == "ar"
                                                        ? notification.content_ar
                                                        : notification.content
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="notification-read-btn">
                            <a
                                @click="notificationAllRead()"
                                class="dropdown-item text-center text-primary font-weight-bold py-3"
                                >{{ $t("readAllNotification") }}
                            </a>
                        </div>
                    </div>
                    <div v-else class="text-center mt-5">
                        <p>{{ $t("notificationNotFound") }}</p>
                    </div>
                </base-dropdown>

                <base-dropdown
                    title-classes="badge badge-pill badge-lg bg-sky text-gray border border-gray py-2 px-4"
                >
                    <template v-slot:title>
                        <i class="fas fa-language text-lg"></i>
                    </template>
                    <a class="dropdown-item" @click="changeLanguage('en')">{{
                        $t("english")
                    }}</a>
                    <a class="dropdown-item" @click="changeLanguage('ar')">{{
                        $t("arabic")
                    }}</a>
                </base-dropdown>

                <!-- Badges -->
                <badge
                    v-if="hasRole(4) || hasRole(9)"
                    rounded
                    type="primary"
                    class="bg-sky text-gray px-4 badge-dash"
                    size="lg"
                    style="
                        border: 1px solid #ececec;
                        margin-left: 8px;
                        padding-top: 14px;
                        padding-bottom: 14px;
                    "
                    >{{ $t("balance") }}
                    <span>{{ storeCredit + " " + $t("SAR") }}</span>
                </badge>
                <div class="d-flex align-items-center top-navbar-section">
                    <!-- Check-in/Check-out Button -->
                    <div v-if="userRole === 6" class="d-flex align-items-center">
                        <button 
                            v-if="!isCheckedIn" 
                            @click="checkInAction"
                            class="btn btn-success">
                            {{ $t("start_shift") }}
                        </button>
                        <button 
                            v-if="isCheckedIn" 
                            @click="checkOutAction"
                            class="btn btn-danger">
                            {{ $t("end_shift") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store/index";
import mixin from "@/mixins/common";
import { setLocale } from "@vee-validate/i18n";
import { getAddressCount } from "@/api/dashboard";
import BaseNav from "@/components/Navbar/BaseNav";
import NavAlertBar from "@/components/NavAlertBar";
import { logout, changeLanguage } from "@/api/auth";
import { operationsCheckIn, operationsCheckOut, operationsGetCurrentShift } from "@/api/users";
import { loadLanguage } from "@/plugins/changeLang";
import { get, read, unreadCount } from "@/api/notification";
export default {
    components: {
        BaseNav,
        NavAlertBar,
    },
    props: {
        type: {
            type: String,
            default: "default", // default|light
        },
    },
    computed: {
        ...mapGetters(["storeCredit", "unReadNotificationCount"]),
    },
    data() {
        return {
            activeNotifications: false,
            showMenu: false,
            searchModalVisible: false,
            notifications: [],
            isNotificationLoading: false,
            notificationRedirectRoute: null,
            currentLang: localStorage.getItem("lang"),
            isCheckedIn: false,
        };
    },
    mixins: [mixin],

    created() {
        this.unReadNotification();
        this.storeAddress();
        if (this.userRole === 6) {
            this.checkIfCheckedIn();
        }
    },
    mounted() {},
    methods: {
        storeAddress() {
            if (this.hasRole(4)) {
                this.creditForPlaceOrder(store.getters.store?.id);
                getAddressCount({
                    store_id: store.getters.store?.id,
                })
                    .then((data) => {
                        if (data.total_address == 0) {
                            this.$emitter.emit("show-address-alert");
                        } else {
                            this.$emitter.emit("hide-address-alert");
                        }
                    })
                    .catch(({ response }) => {
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            }
        },
        changeLanguage(lang) {
            localStorage.setItem("lang", lang);
            setLocale(lang);
            loadLanguage(lang);
            this.$store.dispatch("setLang", lang);

            changeLanguage({ lang: lang }).then((res) => {
                this.runToast(res.message, "success");
            });
            this.currentLang = lang;
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        logoutUser() {
            logout({ request_from: "web" }).then((res) => {
                localStorage.removeItem("token");
                this.$store.dispatch("reset");
                this.$router.push({ name: "Login" });
            });
        },
        getNotification(isOpen) {
            if (isOpen) {
                this.isNotificationLoading = true;
                get({ limit: -1 }).then((res) => {
                    this.notifications = res.data;
                    this.isNotificationLoading = false;
                });
            }
        },
        notificationClick(data) {
            this.isNotificationLoading = true;
            if (data.type == "complaint_created") {
                this.notificationRedirectRoute = {
                    name: "complaints",
                };
            } else if (data.type == 'bulk_transfer_complete'){
                this.notificationRedirectRoute = {
                    name: "Provider Credits",
                };
            } else {
                this.notificationRedirectRoute = {
                    name: "Order Details",
                    params: { id: data.notifiable_id },
                };
            }
            if (data.read_at == null) {
                read(data.id).then((res) => {
                    this.isNotificationLoading = false;
                    this.$router.push(this.notificationRedirectRoute);
                    this.unReadNotification();
                });
            } else {
                this.isNotificationLoading = false;
                this.$router.push(this.notificationRedirectRoute);
            }
        },
        notificationAllRead() {
            this.isNotificationLoading = true;
            read().then((res) => {
                this.isNotificationLoading = false;
                this.runToast(res.message, "success");
                this.$store.dispatch("setUnreadNotificationCount", false);
            });
        },

        unReadNotification() {
            unreadCount().then((res) => {
                if (res.unread_notification_count > 0) {
                    this.$store.dispatch("setUnreadNotificationCount", true);
                } else {
                    this.$store.dispatch("setUnreadNotificationCount", false);
                }
            });
        },

        checkIfCheckedIn() {
            operationsGetCurrentShift('admin').then((response) => {
                // Check the response to determine if the user is checked in
                this.isCheckedIn = response?.status =='active' && response?.user_id === this.$store.getters.user.id;
            }).catch((error) => {
                console.error('Error fetching current shift:', error);
            });
        },

        // Handle check-in action
        checkInAction() {
            operationsCheckIn('admin').then((response) => {
                this.isCheckedIn = true;  // Change button state to show check-out
                this.runToast(response.message, "success");
            }).catch((error) => {
                console.error('Error during check-in:', error);
                this.runToast(error.message, "error");
            });
        },

        // Handle check-out action
        checkOutAction() {
            operationsCheckOut('admin').then((response) => {
                this.isCheckedIn = false;  // Change button state to show check-in
                this.runToast(response.message, "success");
            }).catch((error) => {
                console.error('Error during check-out:', error);
                this.runToast(error.message, "error");
            });
        },

        runToast(message, type) {
            // Assuming you have a toast utility method to display success/error messages
            this.$toast[type](message);
        }
    },
};
</script>
<style lang="scss" scoped>
.main-content .container-fluid,
.main-content .container-sm,
.main-content .container-md,
.main-content .container-lg,
.main-content .container-xl {
    padding-left: 0 !important;
}
.sidenav-toggler-dark .sidenav-toggler-line {
    background-color: var(--primary) !important;
}
.language-dropdown-color:hover {
    color: #000 !important;
    background-color: transparent !important;
    border-color: #000 !important;
}

.language.active {
    background-color: transparent;
}

.language.active a img {
    width: 40px !important;
    height: 40px !important;
}
.notification-top {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ececec;
    border-radius: 50px;
    background: transparent !important;
    margin: 0 15px;
}

.badge-dash {
    font-size: 14px;
}

.unread-notification {
    z-index: 1;
    color: #525f7f !important;
    text-decoration: none;
    background-color: #f6f6f6;
    border-radius: 5px !important;
}
.notification-read-btn {
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 20;
    background: #fff;
}
.notification-listing {
    position: relative;
}
.alert-purple {
    color: #fff;
    border-color: #7368fb;
    background-color: #7368fb;
}
.btn-success {
    background-color: green;
    border-color: green;
}

.btn-danger {
    background-color: red;
    border-color: red;
}
</style>
