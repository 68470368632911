<template>
    <!-- without giving d-inline , icon was moving to next line since div is a block level element
 therefore below class d-inline is given intentionally so that it does not disturb the design when used with other elements  -->
    <div class="d-inline">
        <!-- below clickEvent is a prop to slot in which we are binding the changestatusmodal method with slot
        so that slot content can access it , slot scoping -->
        <slot :clickEvent="changeStatusModal">
            <el-tooltip :content="$t('update_status')" placement="top">
                <i
                    class="fas fa-sync fa-md text-default mr-2"
                    style="cursor: pointer"
                    @click="changeStatusModal"
                ></i>
            </el-tooltip>
        </slot>

        <!-- Modal props show is for toggling Modal Display
        @close child event is for closing modal -->
        <modal
            :show="showModal"
            @close="closeModal"
            size="sm"
            body-classes="px-4"
        >
            <div>
                <div
                    class="mt-2 mb-1 text-lg text-center text-bold font-weight-900"
                >
                    {{ $t("complete_order") }}
                </div>
                <div class="px-3 d-block text-center">
                    <h2 class="text-break font-weight-500">
                        {{ $t("order_no") }} :{{ order?.order_no }}
                    </h2>
                </div>
            </div>
            <Form
                ref="formRef"
                @submit="handleFormSubmit"
                v-loading="isLoading"
            >
                <div v-if="!hasRole(1)">
                    <label for="" class="mt-3">{{
                        $t("verification_code")
                    }}</label>
                    <Field
                        rules="numeric|max:4"
                        v-model="verification_code"
                        class="form-control"
                        name="verification_code"
                        label="verification code"
                    >
                    </Field>
                    <ErrorMessage name="verification_code" class="error" />
                </div>
                <div class="d-flex justify-content-center">
                    <div class="w-full text-center mt-3">
                        <base-button
                            native-type="submit"
                            class="bg-primary text-white"
                        >
                            {{ $t("confirm") }}
                        </base-button
                        >
                        <button
                            class="btn btn-default text-white"
                            data-dismiss="modal"
                            @click.native="closeModal"
                        >
                            {{ $t("close") }}
                        </button>
                    </div>
                </div>
            </Form>
        </modal>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { changeStatus } from "@/api/orders";
export default {
    data() {
        return {
            status: "",
            isLoading: true,
            submitSpinner: false,
            showModal: false,
            verification_code: this.hasRole(1) ? process.env.VUE_APP_SUPER_ADMIN_VERIFICATION_CODE : null,
        };
    },
    props: ["order"],
    components: {},
    methods: {
        changeStatusModal() {
            this.showModal = true;
            this.isLoading = false;
        },
        closeModal() {
            this.showModal = false;
            this.$refs.formRef.resetForm();
            this.isLoading = false;
        },
        handleFormSubmit(event) {
            if (!this.showModal) {
                console.log(event)
                event.preventDefault();
                return;
            }
            this.updateOrderStatus(event);
        },
        updateOrderStatus(data) {
            this.isLoading = true;
            data["status"] = "completed";
            if(this.hasRole(1)) {data["verification_code"] = this.verification_code;}
            changeStatus(this.order.id, data)
                .then((res) => {
                    this.$emit("orderStatusChange");
                    this.runToast(this.$t("orderCompleteSuccess"), "success");
                    this.closeModal();
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
    },
    mixins: [mixin],
};
</script>

<style></style>
