<template>
    <div>
        <hr />
        <div class="p-5 mb-0 card rounded-md">
            <h3>{{ $t("edit_provider") }}</h3>

            <Form @submit="onSubmit" class="">
                <div class="mt-3 row">
                    <div class="col-md-6">
                        <label for="name">{{ $t("name") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            :label="$t('name')"
                            name="name"
                            v-model="form.name"
                        />
                        <ErrorMessage name="name" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="">{{ $t("email") }}</label>
                        <Field
                            type="text"
                            rules="email"
                            :label="$t('email')"
                            class="form-control"
                            name="email"
                            v-model="form.email"
                        />
                        <ErrorMessage name="email" class="error" />
                    </div>
                </div>

                <div class="mt-3 row">
                    <div class="col-md-6">
                        <label for="">{{ $t("mobile_no") }}</label>
                        <Field
                            type="text"
                            rules="required|length:9"
                            :label="$t('mobile_no')"
                            class="form-control"
                            name="mobile_no"
                            v-model="form.mobile_no"
                        />
                        <ErrorMessage name="mobile_no" class="error" />
                    </div>

                    <div class="col-md-6">
                        <label for="">{{ $t("stcpay_mobile_no") }}</label>
                        <Field
                            type="text"
                            rules="required|length:9"
                            :label="$t('stcpay_mobile_no')"
                            class="form-control"
                            name="stcpay_mobile_no"
                            v-model="form.stcpay_mobile_no"
                        />
                        <ErrorMessage name="stcpay_mobile_no" class="error" />
                    </div>
                </div>

                <div class="mt-3 row">
                    <div class="col-md-3 m-auto">
                        <label for="allow_business_order" class="my-1 mr-2">{{
                            $t("allow_business_order")
                        }}</label>
                        <Field
                            name="allow_business_order"
                            v-model="form.allow_business_order"
                        >
                            <input
                                type="checkbox"
                                v-model="form.allow_business_order"
                                name="allow_business_order"
                                value="1"
                            />
                        </Field>
                    </div>

                    <div class="col-md-3 m-auto">
                        <label for="allow_all_order" class="my-1 mr-2">{{
                            $t("allow_all_order")
                        }}</label>
                        <Field
                            name="is_allow_all_order"
                            v-model="form.is_allow_all_order"
                        >
                            <input
                                type="checkbox"
                                v-model="form.is_allow_all_order"
                                name="is_allow_all_order"
                            />
                        </Field>
                    </div>

                    <div class="col-md-6">
                        <label for="city" class="my-1">{{ $t("city") }}</label>
                        <Field
                            name="city_id"
                            :label="$t('city')"
                            :placeholder="$t('city')"
                            rules="required"
                            v-model="form.city_id"
                        >
                            <el-select
                                class="d-block"
                                v-model="form.city_id"
                                name="city_id"
                                :placeholder="$t('city')"
                                filterable
                            >
                                <el-option
                                    v-for="option in city"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="option.title"
                                    :key="option.value"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage name="city_id" class="error" />
                    </div>
                </div>

                <h2 class="mt-3">{{ $t("vehicle_detail") }}</h2>

                <div class="row mt-3">
                    <div class="col-md-4">
                        <label for="vehicle_type" class="my-1">{{
                            $t("vehicle_type")
                        }}</label>
                        <Field
                            name="vehicle.vehicle_type_id"
                            :label="$t('vehicle_type')"
                            :placeholder="$t('vehicle_type')"
                            rules="required"
                            v-model="form.vehicle.vehicle_type_id"
                        >
                            <el-select
                                class="d-block"
                                v-model="form.vehicle.vehicle_type_id"
                                name="vehicle.vehicle_type_id"
                                :placeholder="$t('vehicle_type')"
                                filterable
                                @change="getVehicleBrands(true)"
                            >
                                <el-option
                                    v-for="option in vehicleTypes"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="isAr ? option.type_ar : option.type"
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage
                            name="vehicle.vehicle_type_id"
                            class="error"
                        />
                    </div>

                    <div class="col-md-4">
                        <label for="vehicle_brand" class="my-1">{{
                            $t("vehicle_brand")
                        }}</label>
                        <Field
                            name="vehicle.vehicle_brand_id"
                            :label="$t('vehicle_brand')"
                            :placeholder="$t('vehicle_brand')"
                            v-model="form.vehicle.vehicle_brand_id"
                        >
                            <el-select
                                class="d-block"
                                v-model="form.vehicle.vehicle_brand_id"
                                name="vehicle.vehicle_brand_id"
                                :placeholder="$t('vehicle_brand')"
                                filterable
                                @change="getVehicleModels(true)"
                            >
                                <el-option
                                    v-for="brand in vehicleBrands"
                                    class="select-danger"
                                    :value="brand.id"
                                    :label="isAr ? brand.name_ar : brand.name"
                                    :key="brand.id"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                    </div>

                    <div class="col-md-4">
                        <label for="vehicle_model" class="my-1">{{
                            $t("vehicle_model")
                        }}</label>
                        <Field
                            name="vehicle.vehicle_model_id"
                            :label="$t('vehicle_model')"
                            :placeholder="$t('vehicle_model')"
                            v-model="form.vehicle.vehicle_model_id"
                        >
                            <el-select
                                class="d-block"
                                name="vehicle.vehicle_model_id"
                                :placeholder="$t('vehicle_model')"
                                v-model="form.vehicle.vehicle_model_id"
                                filterable
                            >
                                <el-option
                                    v-for="option in vehicleModels"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="isAr ? option.name_ar : option.name"
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                    </div>
                </div>

                <div class="mt-3 row">
                    <div class="col-md-4">
                        <label for="">{{ $t("productionDate") }}</label>
                        <Field
                            name="vehicle.year"
                            :placeholder="$t('year')"
                            v-model="form.vehicle.year"
                        >
                            <el-select
                                class="d-block"
                                v-model="form.vehicle.year"
                                name="vehicle.year"
                                :placeholder="$t('year')"
                                filterable
                            >
                                <el-option
                                    v-for="year in years"
                                    :key="year"
                                    class="select-danger"
                                    :value="year"
                                    :label="year"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                    </div>

                    <div class="col-md-4">
                        <label for="">{{ $t("plate_number") }}</label>
                        <Field
                            type="text"
                            :label="$t('plate_number')"
                            class="form-control"
                            name="vehicle.plate_number"
                            v-model="form.vehicle.plate_number"
                        />
                    </div>

                    <div class="col-md-4">
                        <label for="">{{ $t("plate_letter") }}</label>
                        <Field
                            type="text"
                            rules="numeric|min:3,max:4"
                            :label="$t('plate_letter')"
                            class="form-control"
                            name="vehicle.plate_letter"
                            v-model="form.vehicle.plate_letter"
                        />
                        <ErrorMessage
                            name="vehicle.plate_letter"
                            class="error"
                        />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-4">
                        <label for="id_proof_image" class="my-1 mr-2">{{
                            $t("id_proof_image")
                        }}</label>

                        <Field
                            type="file"
                            :label="$t('id_proof_image')"
                            class="form-control"
                            name="id_proof_image"
                            accept="image/x-png,image/gif,image/jpeg"
                            @change="uploadImage"
                        />
                        <!-- <dropzone-file-upload
                            @thumbnail="idProofUploadEvent"
                        ></dropzone-file-upload> -->
                    </div>
                    <div class="col-md-2">
                        <img
                            style="width: inherit"
                            :src="vehicle_id_proof_image"
                        />
                    </div>
                    <div class="col-md-4">
                        <label
                            for="registration_licence_image"
                            class="my-1 mr-2"
                            >{{ $t("registration_licence_image") }}</label
                        >
                        <Field
                            type="file"
                            :label="$t('registration_license_image')"
                            class="form-control"
                            name="registration_license_image"
                            v-model="form.vehicle.registration_license_image"
                            accept="image/x-png,image/gif,image/jpeg"
                            @change="uploadImage"
                        />
                        <!-- <dropzone-file-upload
                            @thumbnail="licenceUploadEvent"
                        ></dropzone-file-upload> -->
                    </div>

                    <div class="col-md-2">
                        <img
                            style="width: inherit"
                            :src="vehicle_registration_licence_image"
                        />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-4">
                        <label for="vehicle_image" class="my-1 mr-2">{{
                            $t("vehicle_image")
                        }}</label>
                        <Field
                            type="file"
                            :label="$t('vehicle_image')"
                            class="form-control"
                            name="vehicle_image"
                            v-model="form.vehicle.vehicle_image"
                            accept="image/x-png,image/gif,image/jpeg"
                            @change="uploadImage"
                        />
                        <!-- <dropzone-file-upload
                            @thumbnail="vehicleImageUploadEvent"
                        ></dropzone-file-upload> -->
                    </div>
                    <div class="col-md-2">
                        <img style="width: inherit" :src="vehicle_image" />
                    </div>

                    <div class="col-md-4">
                        <label for="driving_license_image" class="my-1 mr-2">{{
                            $t("driving_license_image")
                        }}</label>
                        <Field
                            type="file"
                            :label="$t('driving_license_image')"
                            class="form-control"
                            name="driving_license_image"
                            v-model="form.vehicle.driving_license_image"
                            accept="image/x-png,image/gif,image/jpeg"
                            @change="uploadImage"
                        />
                        <!-- <dropzone-file-upload
                            @thumbnail="drivingLicenseImageUploadEvent"
                        ></dropzone-file-upload> -->
                    </div>

                    <div class="col-md-2">
                        <img
                            style="width: inherit"
                            :src="vehicle_driving_license_image"
                        />
                    </div>
                </div>

                <div class="w-full mt-5 text-center">
                    <base-button
                        native-type="submit"
                        class="text-white bg-primary"
                        :loading="submitSpinner"
                    >
                        {{ $t("update") }}
                    </base-button>
                    <router-link class="" to="/providers">
                        <base-button
                            native-type="button"
                            class="text-white bg-primary"
                        >
                            {{ $t("cancel") }}
                        </base-button>
                    </router-link>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import { get } from "@/api/cities";
import mixin from "@/mixins/common";
import { serialize } from "object-to-formdata";
import { getVehicleTypes } from "@/api/vehicleType";
import { getUser, updateProvider } from "@/api/users";
import { getVehicleBrands } from "@/api/vehicleBrand";
import { getVehicleModels } from "@/api/vehicleModel";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";

export default {
    data() {
        return {
            vehicleTypes: [],
            vehicleBrands: [],
            vehicleModels: [],
            form: {
                name: null,
                mobile_no: null,
                email: null,
                city_id: null,
                stcpay_mobile_no: null,
                allow_business_order: false,
                is_allow_all_order: false,
                vehicle: {
                    vehicle_type_id: null,
                    vehicle_brand_id: null,
                    vehicle_model_id: null,
                    year: null,
                    plate_number: null,
                    plate_letter: null,
                    id_proof_image: null,
                    registration_license_image: null,
                    vehicle_image: null,
                    driving_license_image: null,
                },
            },
            submitSpinner: false,
            showIdProofValidateMessage: false,
            showImageValidateMessage: false,
            showLicenceValidateMessage: false,
            city: [],
            isAr: localStorage.getItem("lang") == "ar",
            vehicle_id_proof_image: null,
            vehicle_registration_licence_image: null,
            vehicle_image: null,
            vehicle_driving_license_image: null,
        };
    },
    mixins: [mixin],
    props: ["id"],

    created() {
        this.getProvider();
    },

    components: {
        DropzoneFileUpload,
    },

    computed: {
        years() {
            const year = new Date().getFullYear();
            return Array.from(
                { length: year - 1984 },
                (value, index) => 1985 + index
            );
        },
    },

    methods: {
        async getProvider() {
            getUser("admin", this.id, {
                service_provider_company_id: this.$store.getters.user
                    ?.service_provider_company?.id,
                include: "vehicle",
            })
                .then((res) => {
                    this.vehicle_id_proof_image = res.vehicle?.id_proof_image;
                    this.vehicle_registration_licence_image =
                        res?.vehicle?.registration_licence_image;
                    this.vehicle_image = res?.vehicle?.vehicle_image;
                    this.vehicle_driving_license_image =
                        res?.vehicle?.vehicle_driving_license_image;

                    this.form = res;
                    this.form.vehicle.vehicle_image = null;
                    this.form.vehicle.id_proof_image = null;
                    this.form.vehicle.registration_license_image = null;
                    this.form.vehicle.driving_license_image = null;

                    this.getCity();
                    this.getVehicleType().then(() => {
                        this.getVehicleBrands().then(() => {
                            this.getVehicleModels();
                        });
                    });
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        goToList() {
            this.$router.push({ name: "VehicleType List" });
        },
        vehicleImageUploadEvent(data) {
            this.form.vehicle.vehicle_image = data;
        },
        idProofUploadEvent(data) {
            this.form.vehicle.id_proof_image = data;
        },
        licenceUploadEvent(data) {
            this.form.vehicle.registration_license_image = data;
        },
        drivingLicenseImageUploadEvent(data) {
            this.form.vehicle.driving_license_image = data;
        },
        getCity(city) {
            const isAr = localStorage.getItem("lang") == "ar";
            get({
                limit: -1,
                filter: {
                    name: city,
                },
            })
                .then((res) => {
                    this.city = res.data.map((item) => {
                        return {
                            title: isAr ? item.name_ar : item.name,
                            value: item.id,
                        };
                    });
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                    return Promise.reject(false);
                });
        },
        getVehicleType(isChange = false) {
            if (isChange) {
                this.form.vehicle.vehicle_brand_id = null;
                this.form.vehicle.vehicle_model_id = null;
            }
            return getVehicleTypes({
                service_provider_company_id: this.$store.getters.user
                    ?.service_provider_company?.id,
                filter: {
                    status: "active",
                },
            })
                .then((res) => {
                    this.vehicleTypes = res.data;
                    return Promise.resolve(true);
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                    return Promise.reject(false);
                });
        },
        getVehicleBrands(isChange = false) {
            if (this.form.vehicle.vehicle_type_id) {
                if (isChange) {
                    this.form.vehicle.vehicle_brand_id = null;
                    this.form.vehicle.vehicle_model_id = null;
                }
                this.isLoading = true;
                return getVehicleBrands({
                    limit: -1,
                    filter: {
                        status: "active",
                        vehicle_type_id: this.form.vehicle.vehicle_type_id,
                    },
                })
                    .then((res) => {
                        this.vehicleBrands = res.data;
                        return Promise.resolve(true);
                    })
                    .catch(({ response }) => {
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                        return Promise.reject(false);
                    });
            }
        },
        getVehicleModels() {
            if (this.form.vehicle.vehicle_brand_id) {
                this.isLoading = true;
                getVehicleModels({
                    limit: -1,
                    filter: {
                        status: "active",
                        vehicle_brand_id: this.form.vehicle.vehicle_brand_id,
                    },
                })
                    .then((res) => {
                        this.vehicleModels = res.data;
                    })
                    .catch(({ response }) => {
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            }
        },
        uploadImage(e) {
            let img = e.target.files[0];
            switch (e.target.name) {
                case "id_proof_image":
                    this.form.vehicle.id_proof_image = img;
                    break;
                case "registration_license_image":
                    this.form.vehicle.registration_license_image = img;
                    break;
                case "vehicle_image":
                    this.form.vehicle.vehicle_image = img;
                    break;
                case "driving_license_image":
                    this.form.vehicle.driving_license_image = img;
                    break;
            }
        },
        onSubmit(data) {
            this.submitSpinner = true;

            const formData = serialize(data);
            formData.append(
                "allow_business_order",
                data.allow_business_order ? 1 : 0
            );
            formData.append(
                "is_allow_all_order",
                data.is_allow_all_order ? 1 : 0
            );
            formData.append(
                "vehicle[id_proof_image]",
                this.form.vehicle.id_proof_image
                    ? this.form.vehicle.id_proof_image
                    : ""
            );
            formData.append(
                "vehicle[registration_license_image]",
                this.form.vehicle.registration_license_image
                    ? this.form.vehicle.registration_license_image
                    : ""
            );
            formData.append(
                "vehicle[vehicle_image]",
                this.form.vehicle.vehicle_image
                    ? this.form.vehicle.vehicle_image
                    : ""
            );
            formData.append(
                "vehicle[driving_license_image]",
                this.form.vehicle.driving_license_image
                    ? this.form.vehicle.driving_license_image
                    : ""
            );
            formData.append("service_provider_company_id", this.$store.getters.user
                    ?.service_provider_company?.id)

            updateProvider(this.id, formData)
                .then((res) => {
                    this.submitSpinner = false;
                    this.runToast(res.message, "success");
                    this.$router.push({
                        path: "/provider/" + this.id,
                    });
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
    },
};
</script>

<style></style>
