<template>
    <div>
        <el-table
            class="table-responsive table-flush"
            header-row-class-name="thead-light"
            :data="notifications"
            v-loading="isLoading"
            :empty-text="$t('no_data_found')"
        >
            <el-table-column :label="$t('title')" min-width="100px">
                <template v-slot="{ row }">
                    <span class="font-weight-600 name mb-0 text-capitalize">{{
                        row.title
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                :label="$t('content')"
                min-width="200px"
                prop="email"
            >
                <template v-slot="{ row }">
                    <span class="font-weight-600 name mb-0 text-nowrap">{{
                        row.content
                    }}</span>
                </template>
            </el-table-column>

            <el-table-column
                :label="$t('created_at')"
                min-width="150px"
                prop="status"
            >
                <template v-slot="{ row }">
                    <span class="font-weight-600 name mb-0 text-nowrap">{{
                        row.created_at
                    }}</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="card-footer py-4" v-if="notifications.length > 0">
            <base-pagination
                :meta="meta"
                v-model="notificationsCurrentPage"
                :total="notificationsTotalData"
            ></base-pagination>
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/common";

import { get } from "@/api/broadcastNotification";
export default {
    data() {
        return {
            notificationsCurrentPage: 1,
            notifications: [],
            notificationsTotalData: null,
            meta: {},
            isLoading: true,
        };
    },
    watch: {
        notificationsCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getNotifications();
            },
        },
    },
    created() {
        this.getNotifications();
    },
    props: ["id"],
    mixins: [mixin],
    methods: {
        getNotifications() {
            get({
                page: this.notificationsCurrentPage,
                filter: {
                    user_id: this.id,
                },
            })
                .then((res) => {
                    this.isLoading = false;
                    this.notifications = res.data;
                    this.notificationsTotalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
    },
};
</script>

<style></style>
