import List from "@/views/Pages/VehicleModel/List";

export default [
    {
        path: "vehicle-model",
        components: { default: List },
        name: "VehicleModel List",
        meta: {
            title: "vehicle_model",
            role: [1],
        },
    }
];
