import $i18n from "@/plugins/lang/i18n.js";

export function loadLanguage(lang) {
    $i18n.global.locale = lang;
    document.querySelector("html").setAttribute("lang", lang);
    // document.ready function is for handling problem that sidebar does not get its
    // Desired position when page reloads because When Page reloads , the jquery does not get dom nodes
    $(document).ready(async () => {
        if (lang == "ar") {
            await $(".navbar-vertical.navbar-expand-xs").removeClass(
                "fixed-left"
            );
            await $(".navbar-vertical.navbar-expand-xs").addClass(
                "fixed-right"
            );
            await $(".wrapper").addClass("rtl");
            await $(".wrapper.rtl .modal .modal-body label").addClass(
                "text-right"
            );

            await $(".el-select-dropdown__item").addClass("text-right");
            await $(".el-select-dropdown").css("text-align", "right");

            await $(".wrapper label").addClass("d-block text-right");
            await $(".wrapper h1").addClass("text-right");
            await $(".wrapper h2").addClass("text-right");
            await $(".wrapper h3").addClass("text-right");
            await $(".wrapper h4").addClass("text-right");
            await $(".wrapper h5").addClass("text-right");
            await $(".wrapper h6").addClass("text-right");

            await $(
                ".wrapper .modal .modal-body button[type='submit']"
            ).addClass("ml-2");
            await $(".wrapper .sidebar")
                .removeClass("ml-auto")
                .addClass("mr-auto");
        } else {
            await $(".wrapper").removeClass("rtl");
            await $(".wrapper .modal .modal-body label").removeClass(
                "text-right"
            );
            await $(".el-select-dropdown__item").removeClass("text-right");
            await $(".el-select-dropdown__item").css("text-align", "left");

            await $(".wrapper label").removeClass("d-block text-right");
            await $(".wrapper h1").removeClass("text-right");
            await $(".wrapper h2").removeClass("text-right");
            await $(".wrapper h3").removeClass("text-right");
            await $(".wrapper h4").removeClass("text-right");
            await $(".wrapper h5").removeClass("text-right");
            await $(".wrapper h6").removeClass("text-right");

            await $(
                ".wrapper .modal .modal-body button[type='submit']"
            ).removeClass("ml-2");
            await $(".navbar-vertical.navbar-expand-xs").removeClass(
                "fixed-right"
            );
            await $(".navbar-vertical.navbar-expand-xs").addClass("fixed-left");
            await $(".wrapper .sidebar")
                .addClass("ml-auto")
                .removeClass("mr-auto");
        }
    });
    $(document).click(async () => {
        if (lang == "ar") {
            await $(".wrapper.rtl .modal .modal-body label").addClass(
                "text-right"
            );
            await $(".el-select-dropdown__item").addClass("text-right");
            await $(".el-select-dropdown__item").css("text-align", "right");

            await $(".wrapper.rtl label").addClass("d-block text-right");
            await $(".wrapper.rtl h1").addClass("text-right");
            await $(".wrapper.rtl h2").addClass("text-right");
            await $(".wrapper.rtl h3").addClass("text-right");
            await $(".wrapper.rtl h4").addClass("text-right");
            await $(".wrapper.rtl h5").addClass("text-right");
            await $(".wrapper.rtl h6").addClass("text-right");
            await $(
                ".wrapper.rtl .modal .modal-body button[type='submit']"
            ).addClass("ml-2");
            await $(".wrapper .sidebar")
                .removeClass("ml-auto")
                .addClass("mr-auto");
        } else {
            await $(".wrapper.rtl .modal .modal-body label").removeClass(
                "text-right"
            );
            await $(".el-select-dropdown__item").removeClass("text-right");
            await $(".el-select-dropdown__item").css("text-align", "left");

            await $(".wrapper.rtl label").removeClass("d-block text-right");
            await $(".wrapper.rtl h1").removeClass("text-right");
            await $(".wrapper.rtl h2").removeClass("text-right");
            await $(".wrapper.rtl h3").removeClass("text-right");
            await $(".wrapper.rtl h4").removeClass("text-right");
            await $(".wrapper.rtl h5").removeClass("text-right");
            await $(".wrapper.rtl h6").removeClass("text-right");
            await $(
                ".wrapper.rtl .modal .modal-body button[type='submit']"
            ).removeClass("ml-2");
            await $(".wrapper .sidebar")
                .addClass("ml-auto")
                .removeClass("mr-auto");
        }
    });
    return lang;
}
