import axiosInstance from "@/plugins/customAxios";
import store from "@/store/index";

export const get = (params = null) => {
    let baseUrl = "store";
    if (store.getters.user?.role_id == 1 || store.getters.user?.role_id == 7 || store.getters.user?.role_id == 6) {
        baseUrl = "admin";
    }
    return axiosInstance
        .get(`v1/${baseUrl}/addresses`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const add = (data, params = null) => {
    let baseUrl = "store";
    if (store.getters.user?.role_id == 1 || store.getters.user?.role_id == 7 || store.getters.user?.role_id == 6) {
        baseUrl = "admin";
    }
    return axiosInstance
        .post(`v1/${baseUrl}/addresses`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const edit = (id, data, params = null) => {
    let baseUrl = "store";
    if (store.getters.user?.role_id == 1 || store.getters.user?.role_id == 7 || store.getters.user?.role_id == 6) {
        baseUrl = "admin";
    }
    return axiosInstance
        .post(`v1/${baseUrl}/addresses/${id}`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getDistricts = (params = null) => {
    return axiosInstance
        .get("v1/districts", { params: params }).then((res) => {
            return res.data;
        });
};

export const deleteAddress = (id, params = null) => {
    let baseUrl = "store";
    if (store.getters.user?.role_id == 1 || store.getters.user?.role_id == 7 || store.getters.user?.role_id == 6) {
        baseUrl = "admin";
    }
    return axiosInstance.delete(`v1/${baseUrl}/addresses/${id}`, { params: params }).then((res) => {
        return res.data;
    });
};
