<template>
    <div>
        <hr />
        <div class="text-screen-center">
            {{ $t("please_wait") }}
        </div>
    </div>
</template>
<script>
import mixin from "@/mixins/common";
import { madaTransactionCallBack } from "@/api/credit";

export default {
    mixins: [mixin],
    data() {
        return {
            payment_ref_id: null,
            payment_status: null,
            amount: null,
            loader_show: true,
            error_show: false,
            error_message: this.$t("message.paymentFailed"),
            redirect_url: null,
        };
    },
    created() {},
    mounted() {
        this.payment_ref_id = this.$route.query.payment_ref_id;
        this.payment_status = this.$route.query.status;
        this.redirect_url = this.$route.query.redirect_url;
        this.amount = this.$route.query.amount;
        // if (this.payment_status == "paid") {
        this.addCredit();
        // } else if (this.payment_status == "failed") {
        //     this.error_message = this.$route.query.message;
        // }
    },
    computed: {},
    methods: {
        addCredit() {
            var _this = this;
            let params = {};
            (params.payment_ref_id = _this.payment_ref_id),
                (params.status = _this.payment_status);
            params.payment_method = "mada";
            params.amount = _this.amount;
            params.store_id = _this.storeId;
            madaTransactionCallBack(params)
                .then((res) => {
                    this.runToast(res.message, "success");
                    _this.creditForPlaceOrder(_this.storeId);
                    if (_this.redirect_url) {
                        _this.$router.push(
                            _this.redirect_url +
                                "?payment_ref_id=" +
                                _this.payment_ref_id +
                                "&status=" +
                                _this.status
                        );
                    } else {
                        _this.$router.push("/credit/add");
                    }
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    // this.apiError(response);
                    this.runToast(_this.$t("storeCreditAddFiled"), "error");
                    _this.$router.push("/credit/add");
                });
        },
    },
};
</script>

<style>
.page--loader {
    background-position: center;
    background-color: rgba(255, 255, 255, 0.7);
    background-repeat: no-repeat;
    background-size: 100px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-image: url("~Images/loader.svg"); */
}
.loader-text {
    text-align: center;
    height: 100vh;
    margin-top: 200px;
}
</style>
