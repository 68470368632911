<template>
    <div>
        <hr />
        <div class="row">
            <div class="col-md-4">
                <div class="card py-3 px-4 rounded-md">
                    <add-bonus-modal
                        :id="id"
                        v-if="hasRole(1) && customer.status != 'Rejected'"
                        @addBonusComplete="getCustomer"
                    />
                    <div class="nav-item user-profile mt-3">
                        <div class="user-profile-item p-2">
                            <div class="row align-items-baseline">
                                <div class="col-8">
                                    <div>
                                        <img
                                            :src="
                                                // below logic checks if profile image from api is not there i.e null, then setting static image user.png

                                                require('@/assets/images/icons/common/user.png')
                                            "
                                            alt=""
                                            class="avatar-sm rounded-circle"
                                            style="width: 40px"
                                        />
                                        <span
                                            class="nav-link-text ml-2 font-weight-bold"
                                            >{{ customer.name }}</span
                                        >
                                    </div>
                                </div>
                                <!-- customer?.profile_image || -->
                                <div class="col-4 p-0 text-right">
                                    <div>
                                        <!-- Edit user Modal  -->

                                        <user-edit-modal
                                            :user="customer"
                                            role="customer"
                                            :title="$t('edit_customer')"
                                            @userEditCompleted="getCustomer"
                                        />

                                        <!-- End  -->
                                        <!-- Change Status Modal component -->
                                        <update-user-status
                                            :user="customer"
                                            @changeStatusCompleted="getCustomer"
                                        ></update-user-status>

                                        <!-- <add-bonus-modal
                                            :id="id"
                                            v-if="hasRole(1)"
                                            :title="$t('add_wallet')"
                                            @addBonusComplete="getCustomer"
                                        /> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-column justify-content-between">
                        <div class="my-3">
                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="customer.status || false"
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/clock.svg" />
                                </span>

                                <custom-label
                                    :label="customer.status"
                                    class="ml-2"
                                />
                            </div>

                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="customer?.email"
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/email.svg" />
                                </span>
                                <span
                                    class="nav-link-text ml-2 font-weight-bold"
                                    >{{ customer.email }}</span
                                >
                            </div>

                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="customer?.mobile_no"
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/phone.svg" />
                                </span>
                                <span class="font-weight-bold ml-2">
                                    +{{ customer.country_code + " " }}
                                    {{ customer.mobile_no }}</span
                                >
                            </div>
                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="customer.joined_date || false"
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/calendar.svg" />
                                </span>
                                <span class="font-weight-bold ml-2">
                                    {{ customer.joined_date }}
                                </span>
                            </div>
                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="hasRole(1) || hasRole(6)"
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/credit-card.svg" />
                                </span>
                                <span class="">
                                    <base-button
                                        class="wallet-history-btn text-primary bg-transparent border-primary"
                                        @click="viewCustomerWalletHistory"
                                    >
                                        {{ $t("wallet_history") }}
                                    </base-button>
                                </span>
                            </div>
                            <div class="mb-3 user-profile">
                                <h3>
                                    {{ $t("credit") }} : {{ customer.credit }}
                                    {{
                                        customer.credit != null ? $t("SAR") : ""
                                    }}
                                </h3>
                            </div>
                            <div class="mb-3 user-profile">
                                <h3>
                                    {{ $t("referralCode") }} :
                                    {{ customer.referral_code }}
                                </h3>
                            </div>

                            <div class="mb-3 user-profile">
                                <h3>
                                    {{ $t("totalReferralCodeUsed") }} :
                                    {{ customer.referral_code_used_count }}
                                </h3>
                            </div>

                            <div class="mb-3 user-profile">
                                <h3>
                                    {{ $t("referralCashback") }} :
                                    {{
                                        (customer
                                            ? customer.referral_cashback
                                            : 0) +
                                        " " +
                                        $t("SAR")
                                    }}
                                </h3>
                            </div>
                            <div class="mb-3 user-profile">
                                <h3>
                                    {{ $t("notificationEnabled") }} :
                                    <custom-label
                                        :label="customer?.notification_enabled"
                                    />
                                </h3>
                            </div>
                            <div class="mb-3 user-profile">
                                <h3>
                                    {{ $t("lastOpenedAt") }} :
                                    {{ customer?.last_opened_at }}
                                </h3>
                            </div>
                            <div class="mb-3 user-profile">
                                <h3>
                                    {{ $t("appVersion") }} :
                                    {{ customer?.app_version }}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="bg-white rounded-md p-3">
                    <tabs
                        fill
                        class="flex-column flex-md-row px-3"
                        tabNavClasses="mb-3"
                    >
                        <card class="card mb-0 p-0 -mx-2">
                            <tab-pane :title="$t('orders')" class="bg-white">
                                <el-table
                                    id="ordersTable"
                                    class="table-responsive table-flush"
                                    header-row-class-name="thead-light"
                                    :data="orders"
                                    v-loading="isLoading"
                                    @row-click="orderRowClicked"
                                    :empty-text="$t('no_data_found')"
                                >
                                    <el-table-column
                                        :label="$t('order_no')"
                                        min-width="120px"
                                    >
                                        <template v-slot="{ row }">
                                            <order-number-label
                                                :row="row"
                                            ></order-number-label>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                        :label="$t('total')"
                                        min-width="80px"
                                        prop="email"
                                    >
                                        <template v-slot="{ row }">
                                            <span
                                                class="font-weight-600 name mb-0 text-nowrap"
                                                >{{ row.total }}</span
                                            >
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                        :label="$t('status')"
                                        min-width="150px"
                                        prop="status"
                                    >
                                        <template v-slot="{ row }">
                                            <custom-label :label="row.status" />
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                        :label="$t('order_date')"
                                        min-width="150px"
                                    >
                                        <template v-slot="{ row }">
                                            <span
                                                class="font-weight-600 name mb-0"
                                                >{{ row.order_date }}</span
                                            >
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                        :label="$t('actions')"
                                        min-width="120px"
                                    >
                                        <template v-slot="{ row }">
                                            <div
                                                class="d-flex justify-content-between flex-wrap action-width"
                                            >
                                                <el-tooltip
                                                    :content="$t('view')"
                                                    placement="top"
                                                >
                                                    <badge
                                                        circle
                                                        size="lg"
                                                        class="bg-sky-dark"
                                                        style="cursor: pointer"
                                                    >
                                                        <router-link
                                                            target="_blank"
                                                            class="nav-link"
                                                            :to="
                                                                row.store_id !=
                                                                null
                                                                    ? '/store-orders/' +
                                                                      row.id
                                                                    : '/orders/' +
                                                                      row.id
                                                            "
                                                        >
                                                            <img
                                                                src="@/assets/svg/view.svg"
                                                            />
                                                        </router-link>
                                                    </badge>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div
                                    class="card-footer px-0"
                                    v-if="orders.length > 0"
                                >
                                    <base-pagination
                                        :meta="ordersListMeta"
                                        v-model="ordersCurrentPage"
                                        :total="ordersTotalData"
                                    ></base-pagination>
                                </div>
                            </tab-pane>
                            <!--  Notes Section  -->
                            <tab-pane :title="$t('notes')">
                                <!-- Add Notes Button -->
                                <note :id="id" type="user"></note>
                            </tab-pane>
                            <!-- End of Notes Section  -->
                            <!-- Complaints Section  -->
                            <tab-pane :title="$t('complaints')">
                                <complaint-list :id="id"></complaint-list>
                            </tab-pane>
                            <tab-pane :title="$t('broadcast_notification')">
                                <!-- Broadcast Notification Modal -->
                                <!-- below @notificationSent code is for processing after notification has been sent 
                                    then simply calling broadcast notification list method getNotificaitons so that table gets updated -->
                                <div class="pb-3">
                                    <broadcast-notification-modal
                                        :id="id"
                                        @notificationSent="
                                            $refs.broadcastNotificationList.getNotifications()
                                        "
                                    ></broadcast-notification-modal>
                                </div>
                                <!-- Broadcast Notification List  -->
                                <broadcast-notification-list
                                    ref="broadcastNotificationList"
                                    :id="id"
                                ></broadcast-notification-list>
                            </tab-pane>
                            <!-- <tab-pane :title="$t('ratings')">
                                <el-table
                                    class="table-responsive table-flush"
                                    header-row-class-name="thead-light"
                                    :data="ratings"
                                    v-loading="isLoading"
                                >
                                    <el-table-column
                                        :label="$t('order_no')"
                                        min-width="100px"
                                    >
                                        <template v-slot="{ row }">
                                            <span
                                                class="font-weight-600 name mb-0 text-capitalize"
                                                >{{ row.order?.order_no }}</span
                                            >
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        :label="$t('rating')"
                                        min-width="200px"
                                        prop="email"
                                    >
                                        <template v-slot="{ row }">
                                            <span
                                                class="font-weight-600 name mb-0"
                                                >{{
                                                    $t(
                                                        "clientExperienceQuestion"
                                                    )
                                                }}
                                                :
                                                {{
                                                    row.client_experience
                                                }}/5</span
                                            >
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                        :label="$t('average_rating')"
                                        min-width="80px"
                                        prop="email"
                                    >
                                        <template v-slot="{ row }">
                                            <span
                                                class="font-weight-600 name mb-1"
                                            >
                                                {{ row.average_rating }}/5
                                            </span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                        :label="$t('review')"
                                        min-width="150px"
                                        prop="status"
                                    >
                                        <template v-slot="{ row }">
                                            <span
                                                class="font-weight-600 name mb-0"
                                                >{{ row.review }}</span
                                            >
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                        :label="$t('rating_by')"
                                        min-width="150px"
                                    >
                                        <template v-slot="{ row }">
                                            <span
                                                class="font-weight-600 name mb-0"
                                                >{{ row.rating_by?.name }}</span
                                            >
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div
                                    class="card-footer py-4"
                                    v-if="ratings.length > 0"
                                >
                                    <base-pagination
                                        :meta="ratingsListMeta"
                                        v-model="ratingsCurrentPage"
                                        :total="ratingsTotalData"
                                    ></base-pagination>
                                </div>
                            </tab-pane> -->
                        </card>
                    </tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getUser } from "@/api/users";
import { getOrders } from "@/api/orders";
import Tabs from "@/components/Tabs/Tabs";
import { getRatings } from "@/api/ratings";
import TabPane from "@/components/Tabs/TabPane";
import Note from "@/components/common/Note";
import ComplaintList from "@/components/common/ComplaintList";
import UserEditModal from "@/components/common/UserEditModal.vue";
import AddBonusModal from "@/components/common/AddBonusModal.vue";
import UpdateUserStatus from "@/components/common/UpdateUserStatus.vue";
import BroadcastNotificationList from "@/components/common/BroadcastNotificationList";
import BroadcastNotificationModal from "@/components/common/BroadcastNotificationModal";
import OrderNumberLabel from "@/components/common/OrderNumberLabel.vue";

export default {
    data() {
        return {
            customer: {},
            orders: [],
            ordersTotalData: null,
            ratingsTotalData: null,
            ordersCurrentPage: 1,
            ratingsCurrentPage: 1,
            ratings: [],
            isLoading: true,
            ratingsListMeta: {},
            ordersListMeta: {},
        };
    },
    watch: {
        ordersCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getOrders();
            },
        },

        ratingsCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getRatings();
            },
        },
    },
    components: {
        Tabs,
        TabPane,
        Note,
        UpdateUserStatus,
        BroadcastNotificationList,
        BroadcastNotificationModal,
        ComplaintList,
        UserEditModal,
        AddBonusModal,
        OrderNumberLabel,
    },
    created() {
        this.getCustomer();
        this.getOrders();
        // this.getRatings();
    },
    props: ["id"],
    mixins: [mixin],
    methods: {
        getCustomer() {
            getUser("admin", this.id, {})
                .then((res) => {
                    this.customer = res;
                })
                .catch(({ response }) => {
                    this.isLoading = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        getRatings() {
            getRatings({
                page: this.ratingsCurrentPage,

                filter: {
                    rating_to: this.id,
                },
                include: "order,ratingBy",
            })
                .then((res) => {
                    this.isLoading = false;

                    this.ratings = res.data;
                    this.ratingsTotalData = res.meta.total;
                    this.ratingsListMeta = res.meta;
                })
                .catch(({ response }) => {
                    this.isLoading = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },

        getOrders() {
            getOrders({
                filter: {
                    customer_id: this.id,
                },

                page: this.ordersCurrentPage,
            })
                .then((res) => {
                    this.isLoading = false;
                    this.orders = res.data;
                    this.ordersTotalData = res.meta.total;
                    this.ordersListMeta = res.meta;
                })
                .catch(({ response }) => {
                    this.isLoading = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        viewCustomerWalletHistory() {
            let routeData = this.$router.resolve({
                name: "Wallet History List",
                params: { userId: this.customer.id },
            });
            window.open(routeData.href, "_blank");
        },
        orderRowClicked(row, column, event) {
            if (column.label != this.$t("actions")) {
                if (row.store_id != null) {
                    this.$router.push({
                        name: "Store Order Detail",
                        params: { id: row.id },
                    });
                } else {
                    this.$router.push({
                        name: "Order Details",
                        params: { id: row.id },
                    });
                }
            }
            return;
        },
    },
};
</script>
<style scoped lang="scss">
.user-profile {
    border: 2px;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #f6f9fc;
}
.user-profile-item {
    margin-top: 2px;
}
.avatar-sm {
    width: 50px !important;
    height: 50px !important;
}
.wallet-history-btn {
    padding: 6px !important;
}
</style>
