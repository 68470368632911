<template>
    <div class="d-inline">
        <!-- Modal props show is for toggling Modal Display 
        @close child event is for closing modal -->
        <!-- Modal  -->
        <modal
            footerClasses="justify-content-center"
            :show="showModal"
            @close="$emit('close')"
            size="md"
            body-classes="p-0"
        >
            <template v-slot:header>
                <div>
                    <div
                        class="font-weight-700 text-lg mt-2 mb-1 text-center text-black"
                    >
                        {{ $t("select_order_type") }}
                    </div>
                    <p
                        class="font-weight-300 text-sm mt-2 mb-1 text-center text-black"
                    >
                        {{ $t("select_order_type_description") }}
                    </p>
                </div>
            </template>

            <div class="pt-0 px-4 order-type">
                <div class="card-deck">
                    <card>
                        <template v-slot:image>
                            <img
                                class="card-img-top notes"
                                src="@/assets/svg/express-service.svg"
                            />
                        </template>
                        <h2 class="card-title text-center">
                            {{ $t("normal_order") }}
                        </h2>
                        <div class="card__content"></div>
                        <base-button
                            class="text-white bg-primary rounded-lg p-2 mt-3"
                            @click="selectOrderType('normal')"
                        >
                            {{ $t("place_now") }}
                        </base-button>
                    </card>
                    <card
                        class="package-order-card"
                        :class="{ disabled: isPackageDisabled }"
                    >
                        <template v-slot:image>
                            <img
                                class="card-img-top"
                                src="@/assets/svg/package-goods.svg"
                            />
                        </template>
                        <h2 class="card-title text-center">
                            {{ $t("package_order_messages.package_order") }}
                        </h2>
                        <div class="card__content"></div>
                        <base-button
                            class="text-white bg-primary rounded-lg p-2 mt-3"
                            @click="selectOrderType('package')"
                            :disabled="isPackageDisabled"
                        >
                            {{ $t("place_now") }}
                        </base-button>
                    </card>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import mixin from "@/mixins/common";

export default {
    data() {
        return {
            showButtonLoader: false,
            form: {
                order_type: null,
            },
            isPackageDisabled: true, // Set to true to disable package orders
        };
    },
    props: ["showModal"],
    mixins: [mixin],
    emits: ["openPackageOrderPopup", "openNormalOrderPopup", "close"],
    methods: {
        selectOrderType(orderType) {
            if (orderType === "package" && !this.isPackageDisabled) {
                this.$emit("openPackageOrderPopup");
                this.$emit("close");
                return;
            } else if (orderType === "normal") {
                this.$router.push({ name: 'Add Store Order' });
            }
            this.$emit("close");
        },
    },
};
</script>

<style>
.package-order-card .card-title,
.package-order-card .card__content,
.package-order-card base-button {
    opacity: 0.5; 
    pointer-events: none; 
}

</style>
