<template>
    <div>
        <hr />
        <div class="row">
            <div class="col-md-4">
                <div class="card px-4 py-3 rounded-md">
                    <div class="col-md-12 compensation-btn">
                        <add-bonus-modal
                            :id="id"
                            v-if="hasRole(1) && provider.status != 'Rejected'"
                            @addBonusComplete="getProvider"
                        />
                    </div>
                    <div class="nav-item user-profile mt-3">
                        <div class="user-profile-item p-2">
                            <div class="row align-items-baseline">
                                <div class="col-8">
                                    <div>
                                        <img
                                            :src="
                                                // below logic checks if profile image from api is not there i.e null, then setting static image user.png
                                                provider?.profile_image ||
                                                require('@/assets/images/icons/common/user.png')
                                            "
                                            alt=""
                                            class="avatar-sm rounded-circle"
                                            style="
                                                width: 40px !important;
                                                height: 40px !important;
                                                border-radius: 50px;
                                            "
                                        />
                                        <span
                                            class="nav-link-text ml-2 font-weight-bold"
                                            >{{ provider.name }}</span
                                        >
                                    </div>
                                </div>

                                <div class="col-4 p-0 text-right">
                                    <!-- User Edit Modal  -->
                                    <!-- <user-edit-modal
                                        v-if="!hasRole(9)"
                                        :user="provider"
                                        role="provider"
                                        :title="$t('edit_provider')"
                                        @userEditCompleted="getProvider"
                                    /> -->
                                    <div class="provider-edit-icon">
                                        <el-tooltip
                                            :content="$t('edit')"
                                            placement="top"
                                        >
                                            <router-link
                                                target="_blank"
                                                class="nav-link"
                                                :to="
                                                    '/provider/' +
                                                    provider.id +
                                                    '/edit'
                                                "
                                            >
                                                <i
                                                    class="fas fa-pen fa-md mx-3 text-default"
                                                    style="cursor: pointer"
                                                ></i>
                                            </router-link>
                                        </el-tooltip>
                                        <update-user-status
                                            v-if="!hasRole(9)"
                                            :user="provider"
                                            @changeStatusCompleted="getProvider"
                                        ></update-user-status>
                                    </div>
                                </div>
                            </div>
                            <!-- End of row-->
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-between">
                        <div class="my-3">
                            <div class="mb-3 px-0 d-flex align-items-center">
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/clock.svg" />
                                </span>

                                <custom-label :label="provider.user_status" />
                            </div>

                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="provider?.email"
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/email.svg" />
                                </span>
                                <span
                                    class="nav-link-text ml-2 font-weight-bold"
                                    >{{ provider.email }}</span
                                >
                            </div>

                            <div class="mb-3 px-0 d-flex align-items-center">
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/phone.svg" />
                                </span>
                                <span class="font-weight-bold ml-2">
                                    +{{ provider.country_code + " " }}
                                    {{ provider.mobile_no }}</span
                                >
                            </div>

                            <div class="mb-3 px-0 d-flex align-items-center">
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/calendar.svg" />
                                </span>
                                <span class="font-weight-bold ml-2">{{
                                    provider?.joined_date
                                }}</span>
                            </div>

                            <div class="mb-3 px-0 d-flex align-items-center">
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/location.svg" />
                                </span>
                                <span class="font-weight-bold ml-2">{{
                                    lang == "ar"
                                        ? provider?.city?.name_ar
                                        : provider?.city?.name
                                }}</span>
                            </div>
                            <div
                                class="mb-3 px-0 d-flex align-items-center"
                                v-if="hasRole(1) || hasRole(6) || hasRole(8)"
                            >
                                <span class="icon-wrap">
                                    <img src="@/assets/svg/credit-card.svg" />
                                </span>
                                <span class="">
                                    <base-button
                                        class="wallet-history-btn text-primary bg-transparent border-primary"
                                        @click="viewProviderWalletHistory"
                                    >
                                        {{ $t("wallet_history") }}
                                    </base-button>
                                </span>
                            </div>
                        </div>

                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("trips") }} :
                                {{ provider.total_completed_trips }}
                            </h3>
                        </div>

                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("amount") }} :
                                {{ provider.completed_trips_amount }}
                            </h3>
                        </div>

                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("stc_updated") }} :
                                <custom-label :label="provider.stc_updated" />
                            </h3>
                        </div>

                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("stcpay_mobile_no") }} :
                                <span v-if="provider.stcpay_mobile_no"
                                    >+{{ provider.country_code + " " }}
                                    {{ provider.stcpay_mobile_no }}</span
                                >
                            </h3>
                        </div>

                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("credit") }} : {{ provider.credit }}
                                {{ provider.credit != null ? $t("SAR") : "" }}
                            </h3>
                        </div>

                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("orderCredit") }} :
                                {{ provider.order_credit }}
                                {{
                                    provider.order_credit != null
                                        ? $t("SAR")
                                        : ""
                                }}
                            </h3>
                        </div>

                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("total_charge_credit") }} :
                                {{ provider.total_charge_credit }}
                                {{
                                    provider.total_charge_credit != null
                                        ? $t("SAR")
                                        : ""
                                }}
                            </h3>
                        </div>

                        <div class="mb-3 user-profile">
                            <h3>{{ $t("service_provider_company") }} :</h3>
                            <h3
                                v-for="sp_company in provider.service_provider_companies"
                                :key="sp_company.id"
                            >
                                {{ sp_company.name }}
                            </h3>
                        </div>
                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("locationUpdatedAt") }} :
                                {{ provider.location_updated_at }}
                            </h3>
                        </div>
                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("referralCode") }} :
                                {{ provider.referral_code }}
                            </h3>
                        </div>

                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("totalReferralCodeUsed") }} :
                                {{ provider.referral_code_used_count }}
                            </h3>
                        </div>

                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("referralCashback") }} :
                                {{
                                    provider.referral_cashback + " " + $t("SAR")
                                }}
                            </h3>
                        </div>
                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("allow_all_order") }} :
                                <custom-label
                                    :label="
                                        provider.is_allow_all_order
                                            ? 'yes'
                                            : 'no'
                                    "
                                ></custom-label>
                            </h3>
                        </div>
                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("notificationEnabled") }} :
                                <custom-label
                                    :label="provider?.notification_enabled"
                                />
                            </h3>
                        </div>
                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("lastOpenedAt") }} :
                                {{ provider?.last_opened_at }}
                            </h3>
                        </div>
                        <div class="mb-3 user-profile">
                            <h3>
                                {{ $t("appVersion") }} :
                                {{ provider?.app_version }}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 bg-white rounded-md">
                <tabs
                    fill
                    class="flex-column flex-md-row p-3"
                    tabNavClasses="mb-3"
                >
                    <card class="px-0 -mx-2">
                        <!--Provider Vehicle Details  -->
                        <tab-pane
                            :title="$t('vehicle_detail')"
                            class="bg-white"
                        >
                            <ul class="list-group">
                                <!-- Vehicle Name -->
                                <li class="list-group-item">
                                    {{ $t("vehicle_name") }} :
                                    {{ vehicle.name }}
                                </li>
                                <li class="list-group-item">
                                    {{ $t("description") }} :
                                    {{ vehicle.description }}
                                </li>
                                <!-- Vehicle Type -->
                                <li class="list-group-item">
                                    {{ $t("vehicle_type") }} :
                                    <!-- getVehicleType function is for getting type according to lang , if language is set t arabic then displaying type in arabic -->
                                    {{ getVehicleType() }}
                                </li>
                                <li class="list-group-item">
                                    {{ $t("vehicle_brand") }} :
                                    {{
                                        lang == "ar"
                                            ? vehicle.vehicle_brand?.name_ar
                                            : vehicle.vehicle_brand?.name
                                    }}
                                </li>
                                <li class="list-group-item">
                                    {{ $t("vehicle_model") }} :
                                    {{
                                        lang == "ar"
                                            ? vehicle.vehicle_model?.name_ar
                                            : vehicle.vehicle_model?.name
                                    }}
                                </li>
                                <li class="list-group-item">
                                    {{ $t("year") }} :
                                    {{ vehicle.year }}
                                </li>
                                <li class="list-group-item">
                                    {{ $t("plateLetter") }} :
                                    {{ vehicle.plate_number }}
                                </li>
                                <li class="list-group-item">
                                    {{ $t("plateNumber") }} :
                                    {{ vehicle.plate_letter }}
                                </li>
                                <!-- Vehicle Starting price -->
                                <li class="list-group-item">
                                    {{ $t("starting_price") }} :
                                    {{ vehicle.starting_price }}
                                </li>
                                <!-- Vehicle Registration ID -->
                                <li class="list-group-item">
                                    {{ $t("vehicle_registration_id") }} :
                                    {{ vehicle.registration_id }}
                                </li>
                                <!-- ID proof Image -->
                                <li class="list-group-item">
                                    {{ $t("id_proof_image") }} :
                                    <img
                                        :src="vehicle.id_proof_image"
                                        alt=""
                                        class="ml-2 w-50"
                                    />
                                </li>
                                <!-- Registration Licence Image -->
                                <li class="list-group-item">
                                    {{ $t("registration_licence_image") }} :
                                    <img
                                        :src="
                                            vehicle.registration_licence_image
                                        "
                                        alt=""
                                        class="ml-2 w-50"
                                    />
                                </li>
                                <!-- Vehicle Image -->
                                <li class="list-group-item">
                                    {{ $t("vehicle_image") }} :
                                    <img
                                        :src="vehicle.vehicle_image"
                                        alt=""
                                        class="ml-2 w-50"
                                    />
                                </li>
                                <li class="list-group-item">
                                    {{ $t("vehicleDrivingLicenseImage") }} :
                                    <img
                                        :src="
                                            vehicle.vehicle_driving_license_image
                                        "
                                        alt=""
                                        class="ml-2 w-50"
                                    />
                                </li>
                            </ul>
                        </tab-pane>
                        <!-- Orders -->

                        <tab-pane :title="$t('orders')" class="bg-white">
                            <!-- Providers Orders table  -->
                            <el-table
                                id="ordersTable"
                                class="table-responsive table-flush"
                                header-row-class-name="thead-light"
                                :data="orders"
                                v-loading="isLoading"
                                @row-click="orderRowClicked"
                                :empty-text="$t('no_data_found')"
                            >
                                <!-- Order No -->
                                <el-table-column
                                    :label="$t('order_no')"
                                    min-width="80px"
                                >
                                    <template v-slot="{ row }">
                                        <order-number-label
                                            :row="row"
                                        ></order-number-label>
                                    </template>
                                </el-table-column>
                                <!-- Total -->
                                <el-table-column
                                    :label="$t('total')"
                                    min-width="80px"
                                    prop="email"
                                >
                                    <template v-slot="{ row }">
                                        <span
                                            class="font-weight-600 name mb-0 text-nowrap"
                                            >{{ row.total }}</span
                                        >
                                    </template>
                                </el-table-column>
                                <!-- Status  -->
                                <el-table-column
                                    :label="$t('status')"
                                    min-width="150px"
                                    prop="status"
                                >
                                    <template v-slot="{ row }">
                                        <custom-label :label="row.status" />
                                    </template>
                                </el-table-column>
                                <!-- Order Date -->
                                <el-table-column
                                    :label="$t('order_date')"
                                    min-width="150px"
                                >
                                    <template v-slot="{ row }">
                                        <span
                                            class="font-weight-600 name mb-0"
                                            >{{ row.order_date }}</span
                                        >
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    :label="$t('actions')"
                                    min-width="120px"
                                >
                                    <template v-slot="{ row }">
                                        <div
                                            class="d-flex justify-content-between flex-wrap action-width"
                                        >
                                            <el-tooltip
                                                :content="$t('view')"
                                                placement="top"
                                            >
                                                <badge
                                                    circle
                                                    size="lg"
                                                    class="bg-sky-dark"
                                                    style="cursor: pointer"
                                                >
                                                    <router-link
                                                        target="_blank"
                                                        class="nav-link"
                                                        :to="
                                                            row.store_id != null
                                                                ? '/store-orders/' +
                                                                  row.id
                                                                : '/orders/' +
                                                                  row.id
                                                        "
                                                    >
                                                        <img
                                                            src="@/assets/svg/view.svg"
                                                        />
                                                    </router-link>
                                                </badge>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div
                                class="card-footer p-0 pt-5 pb-5"
                                v-if="orders.length > 0"
                            >
                                <base-pagination
                                    :meta="ordersListMeta"
                                    v-model="ordersCurrentPage"
                                    :total="ordersTotalData"
                                ></base-pagination>
                            </div>
                        </tab-pane>
                        <!--  Notes Section  -->
                        <tab-pane :title="$t('notes')" v-if="!hasRole(9)">
                            <!-- Add Notes Button -->
                            <note :id="id" type="user"></note>
                        </tab-pane>
                        <!-- End of Notes Section  -->
                        <!-- Complaints Section  -->
                        <tab-pane :title="$t('complaints')" v-if="!hasRole(9)">
                            <complaint-list :id="id"></complaint-list>
                        </tab-pane>
                        <tab-pane
                            :title="$t('broadcast_notification')"
                            v-if="!hasRole(9)"
                        >
                            <!-- Broadcast Notification Modal -->
                            <!-- below @notificationSent code is for processing after notification has been sent 
                                then simply calling broadcast notification list method getNotifications so that table gets updated -->
                            <broadcast-notification-modal
                                :id="id"
                                @notificationSent="
                                    $refs.broadcastNotificationList.getNotifications()
                                "
                            ></broadcast-notification-modal>
                            <!-- Broadcast Notification List  -->
                            <broadcast-notification-list
                                ref="broadcastNotificationList"
                                :id="id"
                            ></broadcast-notification-list>
                        </tab-pane>
                        <tab-pane
                            :title="$t('ratings')"
                            v-if="hasRole(1) || hasRole(6) || hasRole(8)"
                        >
                            <rating-list
                                :id="id"
                                ratingType="provider"
                                :averageRating="provider.rating"
                            ></rating-list>
                        </tab-pane>
                        <tab-pane
                            :title="$t('device_types')"
                            v-if="deviceTypes.length > 0 && !hasRole(9)"
                        >
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="row">
                                        <div class="col">
                                            {{ $t("device_type") }}
                                        </div>
                                        <div class="col">
                                            {{ $t("device_version") }}
                                        </div>
                                    </div>
                                </li>
                                <li
                                    class="list-group-item"
                                    v-for="device in deviceTypes"
                                    :key="device.logged_in_at"
                                >
                                    <div class="row">
                                        <div class="col">
                                            {{ device.device_type }}
                                        </div>
                                        <div class="col">
                                            {{ device.device_version }}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </tab-pane>
                    </card>
                    <card
                        class="px-0"
                        v-if="provider.current_lat && provider.current_long"
                    >
                        <div class="mt-2">
                            <map-for-provider-location :provider="provider" />
                        </div>
                    </card>
                </tabs>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getUser } from "@/api/users";
import { getOrders } from "@/api/orders";
import Tabs from "@/components/Tabs/Tabs";
import { getRatings } from "@/api/ratings";
import { getVehicle } from "@/api/vehicle";
import TabPane from "@/components/Tabs/TabPane";
import { getDeviceTypes } from "@/api/deviceType";
import Note from "@/components/common/Note";
import RatingList from "@/components/common/RatingList.vue";
import ComplaintList from "@/components/common/ComplaintList";
import UserEditModal from "@/components/common/UserEditModal.vue";
import AddBonusModal from "@/components/common/AddBonusModal.vue";
import UpdateUserStatus from "@/components/common/UpdateUserStatus.vue";
import OrderNumberLabel from "@/components/common/OrderNumberLabel.vue";
import MapForProviderLocation from "@//components/common/MapForProviderLocation.vue";
import BroadcastNotificationList from "@/components/common/BroadcastNotificationList";
import BroadcastNotificationModal from "@/components/common/BroadcastNotificationModal";

export default {
    data() {
        return {
            provider: {},
            orders: [],
            vehicle: {},
            ordersListMeta: {},
            ratingsListMeta: {},
            ordersTotalData: null,
            ratingsTotalData: null,
            ordersCurrentPage: 1,
            ratingsCurrentPage: 1,
            ratings: [],
            isLoading: true,
            deviceTypes: [],
            lang: localStorage.getItem("lang"),
        };
    },
    watch: {
        ordersCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getOrders();
            },
        },

        ratingsCurrentPage: {
            handler: function (oldVal, newVal) {
                this.getRatings();
            },
        },
    },
    components: {
        Tabs,
        TabPane,
        Note,
        RatingList,
        ComplaintList,
        UserEditModal,
        AddBonusModal,
        UpdateUserStatus,
        OrderNumberLabel,
        MapForProviderLocation,
        BroadcastNotificationList,
        BroadcastNotificationModal,
    },
    created() {
        this.getProvider();
        this.getOrders();
        // if (!this.hasRole(9)) this.getRatings();
        this.getVehicleDetails();
    },
    props: ["id"],
    mixins: [mixin],
    methods: {
        getVehicleType() {
            let vehicleType =
                localStorage.getItem("lang") === "en"
                    ? this.vehicle.vehicle_type?.type
                    : this.vehicle.vehicle_type?.type_ar;
            return vehicleType;
        },
        getVehicleDetails() {
            getVehicle({
                provider_id: this.id,

                include: "vehicle_type,vehicle_brand,vehicle_model",
                service_provider_company_id: this.$store.getters.user
                    ?.service_provider_company?.id,
            })
                .then((res) => {
                    this.vehicle = res;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getProvider() {
            getUser("admin", this.id, {
                include: "service_provider_companies,city",
                service_provider_company_id: this.$store.getters.user
                    ?.service_provider_company?.id,
            })
                .then((res) => {
                    this.provider = res;
                    getDeviceTypes(this.id, {}).then((res) => {
                        this.deviceTypes = res;
                    });
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getRatings() {
            getRatings({
                page: this.ratingsCurrentPage,

                filter: {
                    rating_to: this.id,
                },
                include: "order,ratingBy",
            })
                .then((res) => {
                    this.isLoading = false;

                    this.ratings = res.data;
                    this.ratingsTotalData = res.meta.total;
                    this.ratingsListMeta = res.meta;
                })
                .catch(({ response }) => {
                    this.isLoading = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        getOrders() {
            getOrders({
                filter: {
                    provider_id: this.id,
                },

                page: this.ordersCurrentPage,
                service_provider_company_id: this.$store.getters.user
                    ?.service_provider_company?.id,
            })
                .then((res) => {
                    this.isLoading = false;
                    this.orders = res.data;
                    this.ordersTotalData = res.meta.total;
                    this.ordersListMeta = res.meta;
                })
                .catch(({ response }) => {
                    this.isLoading = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        viewProviderWalletHistory() {
            let routeData = this.$router.resolve({
                name: "Wallet History List",
                params: { userId: this.provider.id },
            });
            window.open(routeData.href, "_blank");
        },
        orderRowClicked(row, column, event) {
            if (column.label != this.$t("actions")) {
                if (row.store_id != null) {
                    this.$router.push({
                        name: "Store Order Detail",
                        params: { id: row.id },
                    });
                } else {
                    this.$router.push({
                        name: "Order Details",
                        params: { id: row.id },
                    });
                }
            }
            return;
        },
    },
};
</script>
<style scoped lang="scss">
.user-profile {
    border: 2px;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #f6f9fc;
}
.user-profile-item {
    margin-top: 2px;
}
.avatar-sm {
    width: 50px !important;
    height: 50px !important;
}
.wallet-history-btn {
    padding: 6px !important;
}
.provider-edit-icon {
    display: flex;
    align-items: center;
    justify-content: end;
}
</style>
