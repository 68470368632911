<template>
    <div class="d-flex" style="gap: 20px">
        <el-select
            v-for="filter in dropDownFilters"
            :key="filter.id"
            class="d-block"
            :name="filter.id"
            v-model="filters[filter.id]"
            :placeholder="filter.label ? $t(filter.label) : $t(filter.id)"
            clearable
            filterable
        >
            <el-option
                v-for="option in filter.options"
                class="select-danger"
                :value="option.value"
                :label="$t(option.label) || $t(option.title)"
                :key="option.label"
            >
            </el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    data() {
        return {
            filters: {},
        };
    },
    created() {},
    props: {
        dropDownFilters: {
            type: Array,
            required: true,
            description: "DropDown Filter Array",
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.$emit("dropDownOptionSelected", this.filters);
            },
        },
    },
};
</script>

<style></style>
