import axiosInstance from "@/plugins/customAxios";
import store from "@/store/index";

export const madaTransaction = (data, params = null) => {
    return axiosInstance
        .post("https://api.moyasar.com/v1/payments", data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const bankTransaction = (data, params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 1 || store.getters.user?.role_id === 7 || store.getters.user?.role_id === 6
    ) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }
    return axiosInstance
        .post(`v1/${baseUrl}/bank-transactions`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};


export const madaTransactionCallBack = (params = null) => {
    return axiosInstance
        .post(`v1/store/credit-payment`, params)
        .then((res) => {
            return res.data;
        });
};

export const creditHistory = (params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 1 || store.getters.user?.role_id === 9 
    ) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }
    return axiosInstance
        .get(`v1/${baseUrl}/payments`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const downloadReceipt = (id, params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 1 || store.getters.user?.role_id === 9
    ) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }

    return axiosInstance
        .get(`v1/${baseUrl}/payments/${id}/receipt`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const downloadReceiptExcel = (params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 1 || store.getters.user?.role_id === 9
    ) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }

    return axiosInstance
        .get(`v1/${baseUrl}/payments/excel-download`, { params: params })
        .then((res) => {
            return res.data;
        });
};