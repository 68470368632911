import axiosInstance from "@/plugins/customAxios";

export const login = (data) => {
    return axiosInstance.post(`v1/login`, data).then((res) => {
        return res.data;
    });
};

export const loginWithSms = (data) => {
    return axiosInstance.post("v1/verify-login-code", data).then((res) => {
        return res.data;
    });
};

export const getResendCode = (data) => {
    return axiosInstance.post("v1/resend-code", data).then((res) => {
        return res.data;
    });
};

export const logout = (data) => {
    return axiosInstance.post("v1/logout", data).then((res) => {
        return res.data;
    });
};

export const changeLanguage = (data) => {
    return axiosInstance.post("v1/change-language", data).then((res) => {
        return res.data;
    });
};
