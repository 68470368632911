export const paymentLockMixin = {
    data() {
        return {
            isPaymentLocked: false,
            countdownTimer: 0
        }
    },

    created() {
        this.checkPaymentLock();
    },

    methods: {
        checkPaymentLock() {
            const lockEndTime = localStorage.getItem('paymentLockEndTime');
            if (lockEndTime) {
                const remainingTime = Math.ceil((parseInt(lockEndTime) - Date.now()) / 1000);
                if (remainingTime > 0) {
                    this.isPaymentLocked = true;
                    this.startCountdown(remainingTime);
                } else {
                    localStorage.removeItem('paymentLockEndTime');
                }
            }
        },

        lockPayments() {
            const lockEndTime = Date.now() + 60000; // 1 minute
            localStorage.setItem('paymentLockEndTime', lockEndTime.toString());
            this.isPaymentLocked = true;
            this.startCountdown(60);
        },

        startCountdown(seconds) {
            this.countdownTimer = seconds;
            const timer = setInterval(() => {
                this.countdownTimer--;
                if (this.countdownTimer <= 0) {
                    clearInterval(timer);
                    this.isPaymentLocked = false;
                    localStorage.removeItem('paymentLockEndTime');
                    window.location.reload();
                }
            }, 1000);
        }
    }
};