import qs from "qs";
import axios from "axios";
import router from "@/router/index";
import store from "@/store/index";
// DEFAULT CONFIG

const config = {
    baseURL: process.env.VUE_APP_API,
    timeout: 300000,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
    },
    paramsSerializer: (params) => qs.stringify(params),
};

// INSTANCE
const axiosInstance = axios.create(config);

const setToken = (token) => {
    // setting header with user token
    axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
};

if (localStorage.getItem("token")) {
    setToken(localStorage.getItem("token"));
}
axiosInstance.defaults.params = {};
axiosInstance.defaults.params["request_from"] = "web";
const setParams = (params) => {
    axiosInstance.defaults.params = params;
};

// TOKEN
// Window onload function is made because of handling undefined error coming due to accessing global property before it is created
window.onload = function () {
    // Add token to header if found in localStorage
    const token = localStorage.getItem("token");
    if (token) setToken(token);
};

// INTERCEPTORS
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Logout if user is no longer authenticated
        // or the token is expired!
        if (error.response && error.response.status == 401) {
            // status code 401 is for unauthorized access when user token expires, etc...
            localStorage.removeItem("token");
            store.dispatch("reset");
            router.push({ name: "Login" });
        }

        if (error.response && error.response.status == 429) {
            // Handle Too many Requests Infinite Loop when any parameter is undefined  server responds with 429
            return Promise.reject(error);
        }

        //If status is not provided, axios is not sure what happend!
        if (!error.response && !error.status) {
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }
);

export default axiosInstance;
export { setToken, setParams };
