<template>
    <div>
        <hr />
        <div class="p-5 mb-0 card rounded-md">
            <h3 class="mb-0">{{ $t(addOrEdit) }}</h3>

            <Form @submit="onSubmit" v-loading="pageSpinner" class="">
                <div class="mt-3 row">
                    <div class="col-md-6">
                        <label for="name">{{ $t("name") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            :label="$t('name')"
                            name="name"
                            v-model="form.name"
                        />
                        <ErrorMessage name="name" class="error" />
                    </div>
                    <div class="col-md-6" v-if="id !== 'add'">
                        <label for="insurance_status">{{ $t("the_insurance") }}</label>
                        <Field
                            as="select"
                            rules="required"
                            class="form-control"
                            :label="$t('insurance')"
                            name="insurance_status"
                            v-model="form.insurance_status"
                        >
                            <option v-for="option in insuranceStatusOptions" :key="option.value" :value="option.value">
                                {{ option.label }}
                            </option>
                        </Field>
                        <ErrorMessage name="insurance_status" class="error" />
                    </div>
                </div>

                <div class="mt-3 row">
                    <div class="col-md-6">
                        <label for="">{{ $t("contact_name") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            :label="$t('contact_name')"
                            class="form-control"
                            name="contact_name"
                            v-model="form.contact_name"
                        />
                        <ErrorMessage name="contact_name" class="error" />
                    </div>

                    <div class="col-md-6">
                        <div class="service-pro-wrap">
                            <label for="">{{ $t("mobile_no") }}</label>
                            <small class="ml-2"
                                >({{ $t("example") }} : 5xxxxxxxx)
                            </small>
                        </div>
                        <Field
                            type="number"
                            rules="required|length:9"
                            :label="$t('mobile_no')"
                            class="form-control"
                            name="mobile_no"
                            v-model="form.contact_mobile_no"
                        />
                        <ErrorMessage name="mobile_no" class="error" />
                    </div>
                </div>

                <div class="mt-3 row" v-if="id === 'add'">
                    <div class="col-md-6">
                        <label for="">{{ $t("password") }}</label>
                        <Field
                            name="password"
                            class="form-control"
                            type="password"
                            v-model="form.password"
                            rules="required|min:8"
                            :label="$t('password')"
                        />
                        <ErrorMessage name="password" class="error" />
                    </div>

                    <div class="col-md-6">
                        <label for="">{{ $t("password_confirmation") }}</label>
                        <Field
                            name="password_confirmation"
                            class="form-control"
                            type="password"
                            v-model="form.password_confirmation"
                            rules="confirmed:@password"
                            :label="$t('password_confirmation')"
                        />
                        <ErrorMessage
                            name="password_confirmation"
                            class="error"
                        />
                    </div>
                </div>

                <div class="w-full mt-5 text-center">
                    <base-button
                        native-type="submit"
                        class="text-white bg-primary-color"
                        :loading="buttonSpinner"
                    >
                        {{ $t("save") }}
                    </base-button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import {
    getCompany,
    addServiceProviderCompany,
    editServiceProviderCompany,
} from "@/api/serviceProviderCompanies";
import mixin from "@/mixins/common";

export default {
    data() {
        return {
            addOrEdit: "",
            serviceProviderCompany: {},
            form: {
                name: null,
                contact_name: null,
                contact_mobile_no: null,
                password: null,
                password_confirmation: null,
                insurance_status: null,
            },
            buttonSpinner: false,
            pageSpinner: false,
        };
    },

    props: ["id"],

    mixins: [mixin],

    computed: {
        insuranceStatusOptions() {
            return [
                { value: "insured", label: this.$t("insured") },
                { value: "not_insured", label: this.$t("not_insured") },
                { value: "pending", label: this.$t("pending") },
            ];
        },
    },

    created() {
        if (this.id === "add") {
            this.addOrEdit = this.$t("add_service_provider_company");
        } else {
            this.addOrEdit = this.$t("edit_service_provider_company");
            this.getServiceProviderCompany();
        }
    },

    methods: {
        getServiceProviderCompany() {
            this.pageSpinner = true;
            getCompany(this.id, {
                include: "user",
            })
                .then((res) => {
                    this.pageSpinner = false;
                    this.serviceProviderCompany = res;
                    this.form = res;
                    this.form.contact_name = res.user.name;
                    this.form.contact_mobile_no = res.user.mobile_no;
                    this.form.insurance_status = res.insurance_status;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },

        async onSubmit() {
            this.buttonSpinner = true;
            if (this.id == "add") {
                let data = {
                    name: this.form.name,
                    contact_name: this.form.contact_name,
                    contact_mobile_no: this.form.contact_mobile_no,
                    password: this.form.password,
                    password_confirmation: this.form.password_confirmation,
                    insurance_status: this.form.insurance_status,
                };
                addServiceProviderCompany(data)
                    .then(() => {
                        this.buttonSpinner = false;
                        this.runToast(
                            this.$t("success.service_provider_company_add"),
                            "success"
                        );
                        this.$router.push({
                            name: "ServiceProviderCompanies List",
                        });
                    })
                    .catch(({ response }) => {
                        this.buttonSpinner = false;
                        this.apiError(response);
                    });
            } else {
                let data = {
                    name: this.form.name,
                    contact_name: this.form.contact_name,
                    contact_mobile_no: this.form.contact_mobile_no,
                    insurance_status: this.form.insurance_status,
                };
                editServiceProviderCompany(this.id, data, {
                    _method: "PATCH",
                })
                    .then((res) => {
                        this.buttonSpinner = false;
                        this.runToast(
                            this.$t("success.service_provider_company_update"),
                            "success"
                        );

                        this.$router.push({
                            name: "ServiceProviderCompanies List",
                        });
                    })
                    .catch(({ response }) => {
                        this.buttonSpinner = false;
                        this.apiError(response);
                    });
            }
        },
    },
};
</script>

<style></style>
