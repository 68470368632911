<template>
    <div>
        <!-- Page content -->
        <div class="vw-100 vh-100">
            <div class="login justify-content-center align-items-center">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="login__content">
                            <img
                                src="@/assets/images/login-img.svg"
                                alt="Brand Logo"
                            />
                            <div class="login__content--text">
                                <h3
                                    class="font-bold text-xl uppercase text-white"
                                >
                                    {{ $t("sms_login_text_1") }}
                                </h3>
                                <p>
                                    {{ $t("sms_login_text_2") }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="login__img">
                            <div
                                class="login-form"
                                style="flex: 1 1 auto;max-width:500px;  min-height: 1px; padding: 1.5rem; p-0"
                            >
                                <!-- Card Body --->

                                <div class="login-logo text-muted mb-4">
                                    <img
                                        src="@/assets/images/brand/logo.png"
                                        alt="Brand Logo"
                                        style="width: 200px; height: auto"
                                    />
                                </div>
                                <h1 class="mt-3 login-title">
                                    {{ $t("login_with_sms") }}
                                </h1>

                                <!-- Start of Login Form -->
                                <Form @submit="onSubmit">
                                    <Field
                                        name="mobile_no"
                                        :label="$t('mobile_no')"
                                        rules="required|max:10"
                                        v-if="showMobileNo"
                                    >
                                        <base-input
                                            inputGroupClasses="form-wrap-bordered"
                                            type="number"
                                            inputClasses="form-login-input"
                                            alternative
                                            name="mobile_no"
                                            addon-left-icon="ni ni-mobile-button"
                                            :placeholder="$t('mobile_no')"
                                            :disabled="showOtp"
                                        >
                                        </base-input>
                                    </Field>
                                    <ErrorMessage
                                        name="mobile_no"
                                        class="error"
                                    />
                                    <!-- OTP Field  -->
                                    <Field
                                        name="code"
                                        rules="required"
                                        v-if="showOtp"
                                        :label="$t('otp')"
                                    >
                                        <base-input
                                            type="number"
                                            inputGroupClasses="form-wrap-bordered"
                                            inputClasses="form-login-input"
                                            alternative
                                            name="code"
                                            addon-left-icon="far fa-keyboard"
                                            :placeholder="$t('otp')"
                                        >
                                        </base-input>
                                    </Field>
                                    <ErrorMessage
                                        name="code"
                                        class="error mb-2"
                                    />
                                    <div class="otp-message">
                                        <div class="text-left">
                                            <span v-if="showOtp && this.otp"
                                                >{{ $t("otp") }}:
                                                {{ this.otp }}</span
                                            >
                                        </div>
                                        <!-- Login  -->
                                        <div class="text-right mb-4">
                                            <router-link
                                                :to="{ name: 'Login' }"
                                                >{{
                                                    $t("login_with_password")
                                                }}</router-link
                                            >
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <!-- Resend OTP  -->
                                        <base-button
                                            v-if="showOtp"
                                            @click.native="resendCode"
                                            :loading="resendCodeSpinner"
                                            class="my-4 login-btn"
                                        >
                                            {{ $t("resend_otp") }}
                                        </base-button>

                                        <!-- Submit -->
                                        <base-button
                                            native-type="submit"
                                            :loading="submitSpinner"
                                            class="login-btn"
                                        >
                                            {{ $t("submit") }}
                                        </base-button>
                                    </div>
                                </Form>
                                <!-- ENd of Login Form  -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { loginWithSms, login, getResendCode } from "@/api/auth";
import { setToken } from "@/plugins/customAxios";
import mixin from "@/mixins/common";
export default {
    components: {},
    data() {
        return {
            showOtp: false,
            showMobileNo: true,
            mobileNo: null,
            submitSpinner: false,
            resendCodeSpinner: false, // isLoading is for showing loader animation while loading data
            otp: 123,
        };
    },
    mixins: [mixin],
    created() {},

    methods: {
        onSubmit(values) {
            this.submitSpinner = true;
            values["request_from"] = "web"; // additional parameter to api required
            values["type"] = 2;
            values["country_code"] = 966;
            if (this.showOtp === true) {
                this.showMobileNo = false;
                values["mobile_no"] = this.mobileNo;
                loginWithSms(values)
                    .then((res) => {
                        this.submitSpinner = false;

                        const token = res.token;
                        setToken(token);
                        this.$store.dispatch("setUser", res.user); // setting user data in vuex store

                        if (this.hasRole(4)) {
                            this.$store.dispatch(
                                "setStoreCredit",
                                res.user?.stores[0]?.credit
                                    ? res.user?.stores[0]?.credit
                                    : 0
                            );
                        }

                        if (this.hasRole(9)) {
                            this.$store.dispatch(
                                "setStoreCredit",
                                res.user?.credit ? res.user?.credit : 0
                            );
                        }
                        localStorage.setItem("token", token);
                        localStorage.setItem("lang", res.user.lang);
                        this.$router.push({ name: "Dashboard" });
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        this.apiError(response);
                    });
            } else {
                login(values)
                    .then((res) => {
                        this.submitSpinner = false;
                        this.mobileNo = values.mobile_no;
                        this.showOtp = true;
                        this.otp = res?.otp ? res.otp : null;
                        this.runToast(res.message, "success");
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        this.apiError(response);
                    });
            }
        },
        resendCode() {
            this.resendCodeSpinner = true;
            const requestBody = {
                mobile_no: this.mobileNo,
                country_code: 966,
                type: 2,
            };
            getResendCode(requestBody)
                .then((res) => {
                    this.otp = res?.otp ? res.otp : null;
                    this.resendCodeSpinner = false;
                    this.runToast(res.message, "success");
                })
                .catch(({ response }) => {
                    this.resendCodeSpinner = false;
                    this.apiError(response);
                });
        },
    },
};
</script>
<style lang="scss">
.otp-message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 16px;
}
</style>
