<template>
    <div>
        <div class="row dashboard-counters">
            <div
                class="col"
                v-for="(count, key) in ordersCount"
                :key="key"
            >
                <div
                    class="card rounded-md px-4 py-3"
                    @click="filterOrderStatus(key)"
                    role="button"
                    :class="activeStatus == key ? 'box-active' : ''"
                >
                    <span class="icon-wrap mt-2">
                        <img src="@/assets/svg/shopping-cart.svg" />
                    </span>
                    <h3 class="card-title text-uppercase text-muted mt-2 mb-0">
                        {{ $t("dashboard_page." + key + "_orders") }}
                    </h3>
                    <span class="font-weight-700 h1"> {{ count }}</span>
                </div>
            </div>

            <!-- Order Completion Percentage Card -->
            <div v-if="selectedOperatorId" class="col">
                <div class="card rounded-md px-4 py-3">
                    <span class="icon-wrap mt-2">
                        <img src="@/assets/svg/shopping-cart.svg" />
                    </span>
                    <h3 class="card-title text-uppercase text-muted mt-2 mb-0">
                        {{ $t("dashboard_page.order_completion") }}
                    </h3>
                    <span class="font-weight-700 h1">
                        {{ orderCompletionPercentage }}%
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { getOrderStatusCounter } from "@/api/dashboard";
import mixin from "@/mixins/common";

export default {
    data() {
        return {
            ordersCount: [],
            activeStatus: null,
            startDate: null,
            endDate: null,
            vehicleType: null,
            orderCompletionPercentage: 0
        };
    },
    components: {},
    props: {
        selectedOperatorId: {
            type: Number,
            required: false,
        },
        selectedDate: {
            type: Array,
            required: false,
        },
        vehicleType: {
            type: Number,
            required: false,
        },
    },
    created() {
        this.getOrderStatusCounterApi();
    },
    watch: {
        selectedOperatorId(newValue) {
            this.getOrderStatusCounterApi();
        },
        selectedDate: {
            handler(newVal) {
                this.getOrderStatusCounterApi();
            },
            deep: true,
        },
        vehicleType: {
            handler() {
                this.getOrderStatusCounterApi();
            },
        },
    },

    mixins: [mixin],

    methods: {
        filterOrderStatus(orderStatus) {
            this.activeStatus = orderStatus;
            this.$emit("statusChanged", orderStatus);
        },
        getOrderStatusCounterApi() {
            let params = {
                request_from: "web",
                operator_id: this.selectedOperatorId,
                status: this.activeStatus,
                start_date: this.selectedDate ? this.selectedDate[0] : null,
                end_date: this.selectedDate ? this.selectedDate[1] : null,
                vehicle_type_id: this.vehicleType,
            };

            if (this.hasRole(4) || this.hasRole(5)) {
                params.store_id = this.storeId;
            }
            if (this.hasRole(9)) {
                params.service_provider_company_id = this.serviceProviderCompanyId;
            }

            getOrderStatusCounter(params)
                .then((res) => {
                    this.ordersCount = res;
                    if (this.selectedOperatorId) {
                        const completed = res.completed || 0;
                        const total = Object.values(res).reduce(
                            (sum, count) => sum + count,
                            0
                        );
                        this.orderCompletionPercentage = total
                            ? Math.round((completed / total) * 100)
                            : 0;
                    }
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        }
    },
};
</script>

<style scoped lang="scss">
.box-active {
    border: 1px solid #7368fb;
    box-shadow: #7368fb38 0px 20px 30px -10px !important;
}
</style>
