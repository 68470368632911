<template>
    <div>
        <div v-if="$route.name == 'Dashboard'">
            <dashcard @statusChanged="changeDashboardOrderStatus"></dashcard>
        </div>
        <div class="card mb-0 px-5 rounded-md">
            <div class="order-list-packages">
                <a
                    href="javascript:;"
                    class="order-list-labels"
                    :class="selectedOrderType == '' ? 'active' : ''"
                    @click="orderTypeChange('all')"
                >
                    {{ $t("all") }}
                </a>
                <!-- Normal order filter -->
                <a
                    v-if="allowedToSeeNormalOrder"
                    href="javascript:;"
                    class="order-list-labels"
                    :class="selectedOrderType == 'normal' ? 'active' : ''"
                    @click="orderTypeChange('normal')"
                >
                    {{
                        $route.name == "Store Orders"
                            ? $t("storeExpressOrders")
                            : $t("expressOrders")
                    }}
                </a>
                <!-- Store pickup order filter -->
                <a
                    v-if="allowedToSeeStorePickupOrder"
                    href="javascript:;"
                    class="order-list-labels"
                    :class="selectedOrderType == 'store_pickup' ? 'active' : ''"
                    @click="orderTypeChange('store_pickup')"
                    >{{ $t("store_pickup") }}</a
                >
                <!-- Store normal order filter -->
                <a
                    v-if="allowedToSeeStoreOrder"
                    href="javascript:;"
                    class="order-list-labels"
                    :class="selectedOrderType == 'store-normal' ? 'active' : ''"
                    @click="orderTypeChange('store-normal')"
                >
                    {{ $t("storeExpressOrders") }}
                </a>
                <!-- Package order filter -->
                <a
                    v-if="allowedToSeeStoreOrder"
                    href="javascript:;"
                    class="order-list-labels"
                    :class="selectedOrderType == 'package' ? 'active' : ''"
                    @click="orderTypeChange('package')"
                    >{{ $t("packages") }}</a
                >
                <!-- Only status filter -->
                <a
                    v-if="isZidshipStore()"
                    href="javascript:;"
                    class="order-list-labels"
                    :class="selectedOrderType == 'on_hold' ? 'active' : ''"
                    @click="orderTypeChange('on_hold')"
                    >{{ $t("on_hold") }}</a
                >
            </div>
            <div class="row align-items-center mt-3">
                <div class="col">
                    <button
                        v-if="(hasRole(5) || hasRole(4)) && addOrderEnabled"
                        type="button"
                        class="btn btn-sm btn--new-order bg-primary text-white rounded-lg"
                        @click="addOrder"
                    >
                        {{ $t("add_new_order") }}
                    </button>
                </div>
            </div>

            <div class="border-0 card-header row">
                <div class="d-flex">
                    <div class="d-flex" style="gap: 20px">
                        <div v-if="$route.name == 'Store Orders'">
                            <Field
                                :placeholder="$t('store_name')"
                                type="text"
                                class="form-control"
                                name="store_name"
                                v-model="filters.store_name"
                            />
                        </div>
                        <div>
                        <div v-if="operators && operators.length">
                            <el-select
                                clearable
                                class="d-block"
                                :placeholder="$t('select_operator')"
                                v-model="selectedOperatorId"
                                @change="emitOperatorChange"
                                filterable
                            >
                                <el-option
                                    v-for="operator in operators"
                                    :key="operator.id"
                                    :value="operator.id"
                                    :label="operator.name"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                        <div>
                            <el-select
                                clearable
                                class="d-block"
                                :placeholder="$t('order_status')"
                                v-model="filters.status"
                                @change="allowOnHoldStatusProcess()"
                                filterable
                            >
                                <el-option
                                    v-for="option in defaultOptions.orderStatus"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="$t(option.label)"
                                    :key="option.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div>
                            <Field
                                :placeholder="$t('order_no')"
                                type="number"
                                class="form-control"
                                name="order_no"
                                v-model="filters.order_no"
                            />
                        </div>
                        <div v-if="!hasRole(5) && !hasRole(4)">
                            <Field
                                :placeholder="
                                    $t('proof_of_delivery.provider_name')
                                "
                                type="text"
                                class="form-control"
                                name="provider_name"
                                v-model="filters.provider_name"
                            />
                        </div>
                        <div>
                            <flat-picker
                                :config="{
                                    allowInput: true,
                                    dateFormat: 'd-m-Y',
                                    // defaultDate: new Date(),
                                }"
                                class="form-control datepicker"
                                :placeholder="$t('dashboard_page.start_date')"
                                v-model="selectedDate[0]"
                                @change="dateFilterChange()"
                            >
                            </flat-picker>
                        </div>
                        <div>
                            <flat-picker
                                :config="{
                                    allowInput: true,
                                    dateFormat: 'd-m-Y',
                                    // defaultDate: new Date(),
                                }"
                                class="form-control datepicker"
                                :placeholder="$t('dashboard_page.end_date')"
                                v-model="selectedDate[1]"
                                @change="dateFilterChange()"
                            >
                            </flat-picker>
                        </div>
                        <div>
                            <el-select
                                clearable
                                class="d-block"
                                :placeholder="$t('select_vehicle_type')"
                                v-model="filters.vehicle_type_id"
                                @change="vehicleTypeChange()"
                                filterable
                            >
                                <el-option
                                    v-for="option in vehicleTypes"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex">
                <div class="row align-items-center mb-2">
                    <order-on-hold-status-update
                        v-if="isAllowOnHoldStatusProcess"
                        @onHoldOrderStatusUpdated="getOrders"
                        :onHoldOrders="onHoldOrderSelected"
                    >
                    </order-on-hold-status-update>
                </div>
            </div>

            <!-- End of Card Header -->
            <div class="row">
                <main class="col-md-12 col-xs-11 p-l-2 p-t-2">
                    <!-- Start of Table -->

                    <el-table
                        class="table-responsive table-flush table-flush-col-hover"
                        header-row-class-name="thead-light"
                        :data="orders"
                        v-loading="isLoading"
                        :empty-text="$t('no_data_found')"
                        @row-click="rowClicked"
                        @selection-change="handleSelectionChangeRowCheck"
                    >
                        <!-- Checkbox -->
                        <el-table-column
                            type="selection"
                            width="55"
                            v-if="isAllowOnHoldStatusProcess"
                        >
                        </el-table-column>

                        <!-- Store Name -->
                        <el-table-column
                            v-if="
                                (selectedOrderType == 'store-normal' ||
                                    selectedOrderType == 'package' ||
                                    (this.$route.name == 'Store Orders' &&
                                        selectedOrderType == '')) &&
                                !this.hasRole(4)
                            "
                            :label="$t('store_name')"
                            min-width="200px"
                        >
                            <template v-slot="{ row }">
                                <badge
                                    size="lg"
                                    class="bg-sky-dark cell p-2"
                                    style="cursor: pointer"
                                    v-if="row.store"
                                >
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize text-break"
                                        >{{ row.store?.name }}</span
                                    >
                                    <br />
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.store?.user?.mobile_no }}</span
                                    >
                                </badge>
                            </template>
                        </el-table-column>

                        <!-- Order No  -->
                        <el-table-column
                            :label="$t('order_no')"
                            min-width="150px"
                        >
                            <template v-slot="{ row }">
                                <div>
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.order_no }}
                                    </span>
                                    <span
                                        v-if="
                                            row.is_provider_eligible_for_bonus
                                        "
                                    >
                                        🔥
                                    </span>
                                </div>
                                <div
                                    v-if="
                                        row.pending_complaints?.length > 0 &&
                                        (userRole === 1 || userRole === 6)
                                    "
                                    class="mt-2"
                                >
                                    <router-link
                                        target="_blank"
                                        class="nav-link p-0"
                                        to="/complaints"
                                    >
                                        <custom-label label="complaint" />
                                    </router-link>
                                </div>
                                <div v-if="row.vehicle_type" class="mt-2">
                                    <badge
                                        class="status-badge"
                                        rounded
                                        style="background-color: #7368fb"
                                        size="md"
                                    >
                                        <span style="color: white !important">{{
                                            $store.getters.getLang == "ar"
                                                ? row.vehicle_type.type_ar
                                                : row.vehicle_type.type
                                        }}</span>
                                    </badge>
                                </div>
                            </template>
                        </el-table-column>

                        <!-- Zidship Order No  -->
                        <el-table-column
                            :label="$t('zidship_order_no')"
                            min-width="150px"
                            v-if="
                                (userRole == 1 ||
                                    userRole == 6 ||
                                    userRole == 4) &&
                                (($route.name == 'Dashboard' &&
                                    selectedOrderType == 'store-normal') ||
                                    $route.name == 'Store Orders')
                            "
                        >
                            <template v-slot="{ row }">
                                <span
                                    class="font-weight-600 name mb-0 text-capitalize"
                                    >{{ row.zidship_order_id }}</span
                                >
                            </template>
                        </el-table-column>

                        <el-table-column
                            :label="$t('order_type')"
                            min-width="150px"
                        >
                            <template v-slot="{ row }">
                                <span>{{ $t("orderType." + row.type) }}</span>
                            </template>
                        </el-table-column>

                        <!-- Customer -->
                        <el-table-column
                            :label="$t('customer')"
                            min-width="150px"
                        >
                            <template v-slot="{ row }">
                                <badge
                                    size="lg"
                                    class="bg-sky-dark cell p-2"
                                    style="cursor: pointer"
                                    v-if="row.customer"
                                >
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize text-break"
                                        >{{ row.customer?.name }}</span
                                    >
                                    <br />
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.customer?.mobile_no }}</span
                                    >
                                </badge>
                                <span
                                    v-else
                                    class="font-weight-600 name mb-0 text-capitalize text-break text-danger"
                                    >{{ $t("customerDeleted") }}</span
                                >
                            </template>
                        </el-table-column>
                        <!-- Provider -->
                        <el-table-column
                            :label="$t('provider')"
                            min-width="220px"
                        >
                            <template v-slot="{ row }">
                                <badge
                                    size="lg"
                                    class="bg-sky-dark cell p-2"
                                    style="cursor: pointer"
                                    v-if="row.provider"
                                >
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize text-break"
                                        >{{ row.provider?.name }}</span
                                    >
                                    <br />
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.provider?.mobile_no }}</span
                                    >
                                </badge>
                                <span
                                    v-else-if="
                                        !row.provider &&
                                        (row.status == 'Accepted' ||
                                            row.status == 'On The Way' ||
                                            row.status == 'Picked Up' ||
                                            row.status == 'Dropped Off' ||
                                            row.status == 'Completed')
                                    "
                                    class="font-weight-600 name mb-0 text-capitalize text-break text-danger"
                                    >{{ $t("providerDeleted") }}</span
                                >
                            </template>
                        </el-table-column>
                        <!-- Total  -->
                        <el-table-column :label="$t('total')" min-width="120px">
                            <template v-slot="{ row }">
                                <span class="font-weight-500"
                                    >{{ row.total }}
                                    {{
                                        row.total != null ? $t("SAR") : ""
                                    }}</span
                                >
                            </template>
                        </el-table-column>
                        <!-- Status  -->
                        <el-table-column
                            :label="$t('status')"
                            min-width="150px"
                            prop="status"
                        >
                            <template v-slot="{ row }">
                                <custom-label :label="row.status" />
                                <!-- Zidship status info -->
                                <zidship-order-status-info
                                    :order="row"
                                    v-if="
                                        row.source == 'zidship_order' &&
                                        !isZidshipStore()
                                    "
                                />
                            </template>
                        </el-table-column>

                        <el-table-column
                            :label="$t('zidship_status')"
                            min-width="150px"
                            prop="status"
                            v-if="!isZidshipStore()"
                        >
                            <template v-slot="{ row }">
                                <custom-label
                                    v-if="row.source == 'zidship_order'"
                                    :label="row.zidship_order_status"
                                />
                            </template>
                        </el-table-column>

                        <!-- Order Date  -->
                        <el-table-column
                            :label="$t('order_date')"
                            min-width="200px"
                        >
                            <template v-slot="{ row }">
                                <span class="font-weight-600 name mb-0">{{
                                    row.order_date
                                }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            :label="$t('actions')"
                            min-width="210px"
                        >
                            <template v-slot="{ row }">
                                <div
                                    class="d-flex align-items-center flex-wrap action-width"
                                >
                                    <!-- View Order Details  -->
                                    <el-tooltip
                                        :content="$t('view')"
                                        placement="top"
                                    >
                                        <badge
                                            circle
                                            size="lg"
                                            class="bg-sky-dark"
                                            style="cursor: pointer"
                                        >
                                            <router-link
                                                target="_blank"
                                                class="nav-link"
                                                :to="
                                                    storeOrder
                                                        ? '/store-orders/' +
                                                          row.id
                                                        : '/orders/' + row.id
                                                "
                                            >
                                                <img
                                                    src="@/assets/svg/view.svg"
                                                />
                                            </router-link>
                                        </badge>
                                    </el-tooltip>
                                    <!-- order status -->
                                    <update-order-status
                                        :order="row"
                                        @orderStatusChange="getOrders"
                                        v-if="isShowCompleteOrderBtn(row)"
                                    >
                                        <template #default="{ clickEvent }">
                                            <el-tooltip
                                                :content="$t('completed')"
                                                placement="top"
                                            >
                                                <badge
                                                    circle
                                                    size="lg"
                                                    class="bg-sky-dark"
                                                    style="cursor: pointer"
                                                    :title="$t('edit')"
                                                    @click="clickEvent"
                                                >
                                                    <img
                                                        src="@/assets/svg/check.svg"
                                                    />
                                                </badge>
                                            </el-tooltip>
                                        </template>
                                    </update-order-status>
                                    <ProviderAssignModal
                                        v-if="
                                            (row.type == 'Normal' ||
                                                row.type == 'Package' ||
                                                row.type == 'Store_pickup') &&
                                            (((userRole === 1 ||
                                                userRole === 6) &&
                                                (row.status == 'Requested' ||
                                                    row.status == 'Confirmed' ||
                                                    row.status == 'Accepted' ||
                                                    row.status ==
                                                        'On The Way' ||
                                                    row.status ==
                                                        'Picked Up')) ||
                                                (userRole === 9 &&
                                                    row.status == 'Confirmed'))
                                        "
                                        :currentRow="row"
                                        :type="'order'"
                                        :orderId="row.id"
                                        :isMultiple="false"
                                        :store="row.store"
                                        @getOrders="getOrders"
                                    >
                                        <template
                                            v-slot:default="{ clickEvent }"
                                        >
                                            <el-tooltip
                                                :content="$t('assign_provider')"
                                                placement="top"
                                            >
                                                <badge
                                                    circle
                                                    size="lg"
                                                    class="bg-sky-dark"
                                                    style="cursor: pointer"
                                                    @click="clickEvent"
                                                >
                                                    <img
                                                        src="@/assets/svg/users.svg"
                                                    />
                                                </badge>
                                            </el-tooltip>
                                        </template>
                                    </ProviderAssignModal>
                                    <!-- Custom Design for change status Icon with Badge display using SLots
                                    Update User Status Component with props like user data  -->

                                    <!--Alert  Modal with Delete Orders Function -->
                                    <el-tooltip
                                        :content="$t('location')"
                                        placement="top"
                                        v-if="
                                            $route.path === '/dashboard' &&
                                            orderStatus === 'live'
                                        "
                                    >
                                        <badge
                                            circle
                                            size="lg"
                                            class="bg-sky-dark"
                                            style="cursor: pointer"
                                            @click="displayLocation(row)"
                                        >
                                            <img
                                                src="@/assets/svg/location.svg"
                                                alt=""
                                            />
                                        </badge>
                                    </el-tooltip>

                                    <alert-modal
                                        alertCategory="cancel_order"
                                        @click="cancelOrder(row)"
                                        v-if="cancelOrderAllowed(row.status)"
                                        :id="row.id"
                                        :body="orderCancelMessage"
                                        :order="row"
                                        @alertModalCompleted="getOrders"
                                    >
                                        <template v-slot:orderNo>
                                            <h3>
                                                {{ $t("order_no") }} :
                                                {{ row.order_no }}
                                            </h3>
                                        </template>
                                    </alert-modal>

                                    <!-- Invoice Button  -->
                                    <!-- <el-tooltip
                                        :content="$t('invoice.title')"
                                        placement="top"
                                        v-if="
                                            row.invoice != null &&
                                            (userRole == 1 ||
                                                userRole == 6 ||
                                                userRole == 4)
                                        "
                                    >
                                        <badge
                                            circle
                                            size="lg"
                                            class="bg-sky-dark"
                                            style="cursor: pointer"
                                            @click="viewInvoiceModal(row)"
                                        >
                                            <img
                                                src="@/assets/svg/invoice.svg"
                                                class="h-small"
                                            />
                                        </badge>
                                    </el-tooltip> -->
                                    <order-invoice
                                        v-if="
                                            row.invoice != null &&
                                            (userRole == 1 ||
                                                userRole == 6 ||
                                                userRole == 4)
                                        "
                                        :order="row"
                                    ></order-invoice>

                                    <!-- <cancel-order-invoice
                                        v-if="
                                            row.invoice != null &&
                                            row.invoice.order_cancel_price !=
                                                null &&
                                            (userRole == 1 ||
                                                userRole == 6 ||
                                                userRole == 4)
                                        "
                                        :order="row"
                                    ></cancel-order-invoice> -->

                                    <el-tooltip
                                        :content="$t('proof_of_delivery.title')"
                                        placement="top"
                                        v-if="
                                            row.proof_of_delivery_url != null &&
                                            (userRole == 1 || userRole == 4)
                                        "
                                    >
                                        <badge
                                            circle
                                            size="lg"
                                            class="bg-sky-dark"
                                            style="cursor: pointer"
                                            @click="
                                                viewProofOfDeliveryModal(row)
                                            "
                                        >
                                            <img
                                                src="@/assets/images/proof-of-delivery.png"
                                                class="h-small"
                                            />
                                        </badge>
                                    </el-tooltip>
                                    <el-tooltip
                                        :content="$t('downloadAirwayBill')"
                                        placement="top"
                                        v-if="
                                            row.airway_bill_url != null &&
                                            (userRole == 1 || userRole == 4)
                                        "
                                    >
                                        <badge
                                            circle
                                            size="lg"
                                            class="bg-sky-dark"
                                            style="cursor: pointer"
                                            @click="downloadAirwayBill(row)"
                                        >
                                            <img
                                                src="@/assets/images/airway-bill.png"
                                            />
                                        </badge>
                                    </el-tooltip>
                                    <!-- Mark Order as Pending  -->
                                    <el-tooltip
                                        :content="$t('mark_as_pending')"
                                        placement="top"
                                        v-if="
                                            $route.name == 'store-orders' &&
                                            markOrderStatusToPendingAllowed(
                                                row.status
                                            )
                                        "
                                    >
                                        <badge
                                            circle
                                            size="lg"
                                            class="bg-default cursor-pointer"
                                            tooltip="View"
                                            style="cursor: pointer"
                                            @click="markPendingModal(row.id)"
                                        >
                                            <i class="fas fa-clock mx-1"></i>
                                        </badge>
                                    </el-tooltip>
                                    <el-tooltip
                                        :content="$t('location')"
                                        placement="top"
                                        v-if="
                                            row.status != null &&
                                            row.status == 'live'
                                        "
                                    >
                                        <badge
                                            circle
                                            size="lg"
                                            class="bg-sky-dark"
                                            tooltip="Location"
                                            style="cursor: pointer"
                                            @click="displayLocation(row)"
                                        >
                                            <img
                                                src="@/assets/svg/location.svg"
                                            /> </badge
                                    ></el-tooltip>
                                    <!-- Mack it public order -->
                                    <el-tooltip
                                        :content="$t('make_it_public')"
                                        placement="top"
                                        v-if="
                                            row.provider_id != null &&
                                            (row.status == 'Accepted' ||
                                                row.status == 'On The Way' ||
                                                row.status == 'Picked Up' ||
                                                row.status == 'Dropped Off') &&
                                            (userRole == 1 ||
                                                userRole == 6 ||
                                                userRole == 7 ||
                                                userRole == 8)
                                        "
                                    >
                                        <badge
                                            circle
                                            size="lg"
                                            class="cursor-pointer bg-sky-dark text-secondary"
                                            style="cursor: pointer"
                                            @click="
                                                viewMakeItPublicModal(row.id)
                                            "
                                        >
                                            <i class="mx-1 fas fa-sync"></i>
                                        </badge>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </main>
            </div>
            <!-- End of row class -->
            <div class="card-footer p-0 pt-5 pb-5" v-if="orders.length > 0">
                <!-- Start of pagination  -->
                <base-pagination
                    :meta="meta"
                    v-model="ordersCurrentPage"
                    :total="ordersTotalData"
                ></base-pagination>
            </div>
            <!-- End of pagination -->
            <!-- Modal  -->
            <modal
                footerClasses="justify-content-center"
                :show="showPendingModal"
                @close="showPendingModal = false"
                size="sm"
                body-classes="p-0"
            >
                <template v-slot:header>
                    <div>
                        <img
                            src="@/assets/svg/cancel-order.svg"
                            alt=""
                            style="width: 150px; height: 150px"
                        />
                    </div>
                </template>
                <div class="px-3 d-block text-center">
                    <h2 class="text-break font-weight-900">
                        {{ $t("orderPendingAlert") }}
                    </h2>
                </div>
                <template v-slot:footer>
                    <div class="w-full text-center mt-3">
                        <base-button
                            native-type="submit"
                            class="my-4"
                            :loading="markOrderPendingIsLoading"
                            @click="markOrderPending"
                        >
                            {{ $t("yes") }}
                        </base-button>
                        <button
                            class="btn btn-default text-white"
                            data-dismiss="modal"
                            @click="closePendingModal"
                        >
                            {{ $t("no") }}
                        </button>
                    </div>
                </template>
            </modal>

            <!-- Make it public model -->
            <modal
                footerClasses="justify-content-center"
                :show="showMakeItPublicModal"
                @close="showMakeItPublicModal = false"
                size="sm"
                body-classes="p-0"
            >
                <template v-slot:header class="mt-3">
                    <div class="text-bold text-lg text-center mt-2">
                        <h1>{{ $t("make_it_public") }}</h1>
                    </div>
                </template>
                <div class="mt-3 px-3 d-block text-center">
                    <h2 class="text-break font-weight-900">
                        {{ $t("orderMakeItPublicAlert") }}
                    </h2>
                </div>
                <template v-slot:footer class="p-0">
                    <div class="w-full text-center">
                        <base-button
                            native-type="submit"
                            class="my-4"
                            :loading="markItPublicOrderLoading"
                            @click="makePublicOrder()"
                        >
                            {{ $t("yes") }}
                        </base-button>
                        <button
                            class="btn btn-default text-white"
                            data-dismiss="modal"
                            @click="closeMakeItPublicModal()"
                        >
                            {{ $t("no") }}
                        </button>
                    </div>
                </template>
            </modal>

            <!-- Location Modal -->
            <modal
                footerClasses="justify-content-center"
                :show="orderLocationModal"
                @close="orderLocationModal = false"
                size="lg"
                body-classes=""
                headerClasses="border-bottom-2"
            >
                <template v-slot:header>
                    <div>
                        <div
                            class="font-weight-700 text-lg text-center mt-2 mb-1"
                        >
                            {{ $t("location") }}
                        </div>
                    </div>
                </template>
                <map-for-order-location
                    :lat="latitude"
                    :lon="longitude"
                    :locations="mapData"
                    v-if="orderLocationModal === true"
                />
                <template v-slot:footer>
                    <div class="w-full text-center mt-3">
                        <button
                            class="btn btn-default text-white"
                            @click="orderLocationModal = false"
                        >
                            {{ $t("close") }}
                        </button>
                    </div>
                </template>
            </modal>

            <!-- Invoice model code add in Order invoice component -->
            <!-- Invoice Modal  -->
            <!-- <modal
                footerClasses="justify-content-center"
                :show="showInvoiceModal"
                @close="showInvoiceModal = false"
                size="lg"
                body-classes=""
                headerClasses="border-bottom-2"
            >
                <template v-slot:header>
                    <div>
                        <div
                            class="font-weight-700 text-lg text-center mt-2 mb-1"
                        >
                            {{ $t("invoice.title") }}
                        </div>
                    </div>
                </template>
                <div class="px-3 d-block">
                    <div class="row" style="border-bottom: 1px solid">
                        <div class="col-md-3">
                            <label for="">{{
                                $t("invoice.invoice_tax_number")
                            }}</label>

                            <h2 class="text-break font-weight-900">
                                {{ "302251248900003" }}
                            </h2>
                        </div>

                        <div class="col-md-3">
                            <label for="">{{ $t("invoice.invoice_no") }}</label>

                            <h2 class="text-break font-weight-900">
                                {{
                                    order.invoice
                                        ? order.invoice.invoice_no
                                        : ""
                                }}
                            </h2>
                        </div>

                        <div class="col-md-3">
                            <label for="">{{
                                $t("invoice.invoice_client")
                            }}</label>
                            <div
                                v-if="
                                    order.source == 'zidship_order' &&
                                    (order.zidship_invoice_name != null ||
                                        order.zidship_invoice_mobile_no != null)
                                "
                            >
                                <h2 class="text-break font-weight-900">
                                    {{ order.zidship_invoice_name }}
                                </h2>
                                <h2 class="text-break font-weight-900">
                                    {{
                                        "+ " +
                                            order.zidship_invoice_country_code +
                                            " " +
                                            order.zidship_invoice_mobile_no ||
                                        ""
                                    }}
                                </h2>
                            </div>
                            <div v-else>
                                <h2
                                    v-if="order.customer != null"
                                    class="text-break font-weight-900"
                                >
                                    {{ order.customer?.name }}
                                </h2>
                                <h2 class="text-break font-weight-900">
                                    {{
                                        "+ " +
                                            order.customer?.country_code +
                                            " " +
                                            order.customer?.mobile_no || ""
                                    }}
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div class="row pt-2" style="border-bottom: 1px solid">
                        <div class="col-md-6">
                            <label for="">{{
                                $t("invoice.invoice_service")
                            }}</label>
                            <h2 class="text-break font-weight-900">
                                {{
                                    $store.getters.getLang == "ar"
                                        ? order.vehicle_type != null
                                            ? order.vehicle_type.type_ar
                                            : ""
                                        : order.vehicle_type != null
                                        ? order.vehicle_type.type
                                        : ""
                                }}
                                {{ $t("vehicle") }}
                            </h2>
                        </div>
                        <div class="col-md-6">
                            <label for="">{{
                                $t("invoice.invoice_trip_detail")
                            }}</label>
                            <h2 class="text-break font-weight-900">
                                {{ order.customer_notes || "-" }}
                            </h2>
                        </div>

                        <div class="col-md-6">
                            <label for="">{{ $t("from") }}</label>
                            <h2 class="text-break font-weight-900">
                                {{
                                    order.type == "Store_pickup" &&
                                    order.pickup_store_id != null
                                        ? order?.pickup_store.name
                                        : order?.pickup_address?.address_line1
                                }}
                            </h2>
                        </div>
                        <div class="col-md-6">
                            <label for="">{{ $t("to") }}</label>
                            <h2 class="text-break font-weight-900">
                                {{ order?.dropoff_address?.address_line1 }}
                            </h2>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6">
                            <div class="row pt-2">
                                <div class="col-md-6">
                                    <label for="">{{
                                        $t("invoice.sub_total")
                                    }}</label>
                                </div>

                                <div class="col-md-6">
                                    <h2 class="text-break font-weight-900">
                                        {{
                                            order.invoice
                                                ? order.invoice.sub_total
                                                : 0
                                        }}
                                        {{ $t("SAR") }}
                                    </h2>
                                </div>
                            </div>
                            <div
                                class="row pt-2"
                                v-if="
                                    order.invoice &&
                                    order.invoice.assembly_charge > 0
                                "
                            >
                                <div class="col-md-6">
                                    <label for="">{{
                                        $t("assembly_charge")
                                    }}</label>
                                </div>

                                <div class="col-md-6">
                                    <h2 class="text-break font-weight-900">
                                        {{ order.invoice.assembly_charge }}
                                        {{ $t("SAR") }}
                                    </h2>
                                </div>
                            </div>
                            <div
                                class="row"
                                v-if="
                                    order.invoice && order.invoice.discount > 0
                                "
                            >
                                <div class="col-md-6">
                                    <label for="">{{ $t("discount") }}</label>
                                </div>
                                <div class="col-md-6">
                                    <h2 class="text-break font-weight-900">
                                        {{ order.invoice.discount }}
                                        {{ $t("SAR") }}
                                    </h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="">{{
                                        $t("invoice.vat")
                                    }}</label>
                                </div>
                                <div class="col-md-6">
                                    <h2 class="text-break font-weight-900">
                                        {{
                                            order.invoice
                                                ? order.invoice.vat
                                                : 0
                                        }}
                                        {{ $t("SAR") }}
                                    </h2>
                                </div>
                            </div>
                            <div
                                class="row"
                                v-if="order?.invoice?.order_cancel_price"
                            >
                                <div class="col-md-6">
                                    <label for="">{{
                                        $t("cancelOrderPrice")
                                    }}</label>
                                </div>
                                <div class="col-md-6">
                                    <h2 class="text-break font-weight-900">
                                        {{
                                            order?.invoice?.order_cancel_price
                                                ? order?.invoice
                                                      ?.order_cancel_price
                                                : 0
                                        }}
                                        {{ $t("SAR") }}
                                    </h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label for=""> {{ $t("total") }}</label>
                                </div>
                                <div class="col-md-6">
                                    <h2 class="text-break font-weight-900">
                                        {{ order.total }} {{ $t("SAR") }}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-slot:footer>
                    <div class="w-full text-center mt-3">
                        <base-button
                            v-if="
                                order.status == 'Completed' ||
                                order.status == 'Cancelled'
                            "
                            native-type="submit"
                            class="my-4"
                            :loading="downloadInvoiceSpinner"
                            @click="downloadInvoice"
                        >
                            {{ $t("invoice_download_btn") }}
                        </base-button>
                        <button
                            class="btn btn-default text-white"
                            @click="showInvoiceModal = false"
                        >
                            {{ $t("close") }}
                        </button>
                    </div>
                </template>
            </modal> -->
        </div>
        <select-order-type-modal
            v-if="showAddOrderPopup"
            :showModal="showAddOrderPopup"
            @openNormalOrderPopup="openOutOfTownOrderTypePopup"
            @openPackageOrderPopup="openPackageOrderPopup"
            @close="showAddOrderPopup = false"
        >
        </select-order-type-modal>
        <select-out-of-town-order-type-modal
            v-if="showOutOfTownOrderTypePopup"
            :showModal="showOutOfTownOrderTypePopup"
            @openInTownOrderPopup="openNormalOrderPopup"
            @openOutOfTownOrderPopup="openOutOfTownOrderPopup"
            @close="showOutOfTownOrderTypePopup = false"
            @back="showOutOfTownOrderTypePopup = false; showAddOrderPopup = true"
        >
        </select-out-of-town-order-type-modal>
        <add-order-modal
            v-if="showAddNormalOrderPopup"
            :showModal="showAddNormalOrderPopup"
            @close="
                showAddNormalOrderPopup = false;
                getOrders();
            "
        >
        </add-order-modal>
        <add-out-of-town-order-modal
            v-if="showAddOutOfTownOrderPopup"
            :showModal="showAddOutOfTownOrderPopup"
            @close="
                showAddOutOfTownOrderPopup = false;
                getOrders();
            "
        >
        </add-out-of-town-order-modal>
        <add-package-order-modal
            v-if="showAddPackageOrderPopup"
            :showModal="showAddPackageOrderPopup"
            @close="
                showAddPackageOrderPopup = false;
                getOrders();
            "
        >
        </add-package-order-modal>
        <proof-of-delivery
            :showModal="proofOfDelivery"
            @close="proofOfDelivery = false"
            :order="order"
        >
        </proof-of-delivery>
    </div>
    <!-- End of Root div -->
</template>

<script>
import {
    getOrders,
    changeStatus,
    downloadInvoice,
    makePublicOrder,
} from "@/api/orders";
import { me } from "@/api/users";
import store from "@/store/index";
import { mapGetters } from "vuex";
import mixin from "@/mixins/common";
import "flatpickr/dist/flatpickr.css";
import AddOrderModal from "./AddOrderModal.vue";
import AddOutOfTownOrderModal from "./AddOutOfTownOrderModal.vue";
import OrderInvoice from "./OrderInvoice.vue";
import flatPicker from "vue-flatpickr-component";
import { getSettings } from "@/api/systemSetting";
import { getVehicleTypes } from "@/api/vehicleType";
import Dashcard from "../../views/Dashboard/Dashcard";
import AlertModal from "@/components/common/AlertModal";
import CancelOrderInvoice from "./CancelOrderInvoice.vue";
import MapForOrderLocation from "./MapForOrderLocation.vue";
import ProviderAssignModal from "./ProviderAssignModal.vue";
import SelectOrderTypeModal from "./SelectOrderTypeModal.vue";
import SelectOutOfTownOrderTypeModal from "./SelectOutOfTownOrderTypeModal.vue";
import AddPackageOrderModal from "./AddPackageOrderModal.vue";
import ProofOfDelivery from "@/components/ProofOfDelivery.vue";
import ZidshipOrderStatusInfo from "./ZidshipOrderStatusInfo.vue";
import UpdateOrderStatus from "@/components/common/UpdateOrderStatus.vue";
import OrderOnHoldStatusUpdate from "@/components/common/OrderOnHoldStatusUpdate";

export default {
    data() {
        return {
            ordersCurrentPage: 1,
            showPendingModal: false,
            openProvidersModal: false,
            orders: [],
            showLocationModal: false,
            order: {},
            // showInvoiceModal: false,
            providerList: [],
            orderCancelMessage: "",
            orderId: null,
            latitude: "",
            longitude: "",
            meta: {},
            showUpdateOrderStatusModal: false,
            isLoading: false,
            type: "",
            filters: {
                // type: "normal",
                order_no: null,
                //provider_id: null,
                provider_name: null,
                status: this.$route.name == "Dashboard" ? "live" : null,
                store_name: null,
                // order_status: this.$route.name == "Dashboard" ? "live" : null,
                vehicle_type_id: null,
                operator_id: null
            },
            ordersTotalData: null,
            markOrderPendingIsLoading: false,
            // downloadInvoiceSpinner: false,
            orderLocationModal: false,
            mapData: [],
            showAddOrderPopup: false,
            showAddNormalOrderPopup: false,
            showAddOutOfTownOrderPopup: false,
            showAddPackageOrderPopup: false,
            showOutOfTownOrderTypePopup: false,
            showMakeItPublicModal: false,
            markItPublicOrderLoading: false,
            showUpdateOrderStatusIcon: false,
            addOrderEnabled: false,
            isShowAddOrderBtn: localStorage.getItem("user"),
            selectedDate: [],
            selectedOrderType: "",
            isAllowOnHoldStatusProcess: false,
            onHoldOrderSelected: [],
            vehicleTypes: [],
            orderStatus: this.$route.name == "Dashboard" ? "live" : null,
            proofOfDelivery: false,
            selectedOperatorId: null,
        };
    },
    props: {
        storeOrder: {
            type: Boolean,
            default: false,
        },
        operators: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Dashcard,
        AlertModal,
        MapForOrderLocation,
        SelectOrderTypeModal,
        SelectOutOfTownOrderTypeModal,
        AddOrderModal,
        AddOutOfTownOrderModal,
        AddPackageOrderModal,
        ProviderAssignModal,
        UpdateOrderStatus,
        OrderOnHoldStatusUpdate,
        flatPicker,
        ZidshipOrderStatusInfo,
        ProofOfDelivery,
        OrderInvoice,
        CancelOrderInvoice,
    },
    watch: {
        ordersCurrentPage: {
            handler: function () {
                this.getOrders();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.ordersCurrentPage = 1;
                this.getOrders();
            },
        },

        // orderStatus: {
        //     immediate: true,
        //     handler: function (status) {
        //         this.filters.status = status;
        //     },
        // },
        type: {
            handler: function () {
                this.getOrders();
            },
        },
    },
    mixins: [mixin],
    computed: {
        ...mapGetters({
            storeFromVuex: "store",
        }),
        allowedToSeeStorePickupOrder() {
            return (
                (this.hasRole(1) || this.hasRole(6) || this.hasRole(8)) &&
                (this.$route.name == "Orders" ||
                    this.$route.name == "Dashboard")
            );
        },
        allowedToSeeStoreOrder() {
            return (
                ((this.hasRole(1) ||
                    this.hasRole(6) ||
                    this.hasRole(7) ||
                    this.hasRole(9)) &&
                    (this.$route.name == "Store Orders" ||
                        this.$route.name == "Dashboard")) ||
                ((this.hasRole(4) || this.hasRole(5)) &&
                    (this.$route.name == "Orders" ||
                        this.$route.name == "Dashboard"))
            );
        },
        allowedToSeeNormalOrder() {
            return (
                (this.hasRole(1) ||
                    this.hasRole(6) ||
                    this.hasRole(9) ||
                    this.hasRole(8)) &&
                (this.$route.name == "Orders" ||
                    this.$route.name == "Dashboard")
            );
        },
    },
    created() {
        this.getOrders();
        this.getVehicleType();
        if (this.hasRole(9) || this.hasRole(5) || this.hasRole(4))
            this.updateCredit();
    },
    emits: ['operatorChanged', 'vehicleTypeChanged'],
    methods: {
        updateCredit() {
            me().then((res) => {
                if (this.hasRole(5) || this.hasRole(4)) {
                    if (res.user?.stores[0]?.add_order_allowed) {
                        this.addOrderEnabled = true;
                    }
                }

                if (this.hasRole(5)) {
                    this.$store.dispatch(
                        "setStoreCredit",
                        res.user?.credit ? res.user?.credit : 0
                    );
                }
            });
        },
        openPackageOrderPopup() {
            this.showAddPackageOrderPopup = true;
        },
        openNormalOrderPopup() {
            this.showAddNormalOrderPopup = true;
        },
        openOutOfTownOrderPopup() {
            this.showAddOutOfTownOrderPopup = true;
        },
        openOutOfTownOrderTypePopup(){
            this.showOutOfTownOrderTypePopup = true;
        },
        emitOperatorChange() {
            this.filters.operator_id = this.selectedOperatorId;
            this.$emit("operatorChanged", this.filters.operator_id);
        },
        addOrder() {
            if (this.addOrderEnabled) {
                this.showAddOrderPopup = true;
            } else {
                this.runToast(this.$t("notAllowAddOrder"), "error");
            }
        },
        displayLocation(order) {
            this.orderId = order.id;
            this.order = order;
            let pickup_address = {};
            pickup_address.type = "pickup_address";
            pickup_address.latitude = this.order?.pickup_address?.latitude;
            pickup_address.longitude = this.order?.pickup_address?.longitude;
            pickup_address.address = this.order?.pickup_address?.address_line1;
            let dropoff_address = {};
            dropoff_address.type = "dropoff_address";
            dropoff_address.latitude = this.order?.dropoff_address?.latitude;
            dropoff_address.longitude = this.order?.dropoff_address?.longitude;
            dropoff_address.address = this.order?.dropoff_address?.address_line1;
            this.mapData.push(pickup_address);
            this.mapData.push(dropoff_address);
            this.orderLocationModal = true;
        },
        // downloadInvoice() {
        //     if (this.order?.invoice?.url) {
        //         window.open(this.order.invoice.url, "_blank");
        //     }
        // },
        downloadAirwayBill(order) {
            window.open(order.airway_bill_url, "_blank");
        },
        viewOrderDetails(id) {
            let routeData;
            if (this.storeOrder === true) {
                routeData = this.$router.resolve({
                    name: "Store Order Detail",
                    params: { id },
                });
            } else {
                routeData = this.$router.resolve({
                    name: "Order Details",
                    params: { id },
                });
            }
            window.open(routeData.href, "_blank");
        },
        getStoreOrderFilter() {
            if (
                this.$route.name == "Dashboard" &&
                (this.type == "store-normal" || this.type == "package")
            ) {
                return true;
            }

            if (
                this.$route.name == "Dashboard" &&
                (this.type == "normal" || this.type == "store-pickup")
            ) {
                return false;
            }

            if (this.userRole == 7) {
                return true;
            }
            if (
                this.userRole == 8 ||
                (this.userRole == 6 && this.storeOrder == false)
            ) {
                return false;
            }

            if (this.userRole == 1 && this.$route.name == "Orders") {
                return false;
            }

            let storeOrderFilter = this.storeOrder === true ? true : null;
            if (this.$route.name != "Dashboard" && this.type != "all") {
                // this.filters.order_status = storeOrderFilter;
                return storeOrderFilter;
            }
        },
        getOrders() {
            this.isLoading = true;
            getOrders({
                // order_status: this.filters.status === "live" ? this.filters.status : null,
                // order_status: this.filters.order_status,
                page: this.ordersCurrentPage,
                store_id:
                    store.getters.user.role_id === 1 &&
                    this.storeOrder === false
                        ? null
                        : store.getters.store?.id,
                filter: {
                    // if this.storeOrder = false then set filter store_order = null not false because data from Api was not coming if it set to false
                    store_order: this.getStoreOrderFilter(),
                    start_date: this.selectedDate[0],
                    end_date: this.selectedDate[1],
                    operator_id: this.filters.operator_id || null,
                },
                service_provider_company_id: this.hasRole(9)
                    ? this.$store.getters.serviceProviderCompany.id
                    : null,
                query: {
                    ...this.filters,
                    status:
                        this.filters.status === "live"
                            ? null
                            : this.filters.status,

                    order_status:
                        this.filters.status === "live"
                            ? this.filters.status
                            : null,
                },
                type: this.type == "store-normal" ? "normal" : this.type,
                include:
                    this.storeOrder === true
                        ? "customer,pickup_address,dropoff_address,provider,pending_complaints,invoice,store,vehicle_type"
                        : "customer,pickup_address,dropoff_address,provider,pending_complaints,invoice,vehicle_type,pickup_store,store",
            })
                .then((res) => {
                    this.isLoading = false;
                    this.orders = res.data;
                    //this.providerList = res.filter_by.provider;
                    this.ordersTotalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        markPendingModal(id) {
            this.showPendingModal = true;
            this.orderId = id;
        },
        resetFilter() {
            this.filters = {
                order_no: null,
                provider_id: null,
                operator_id: null,
                status: "",
            };
        },
        closePendingModal() {
            this.showPendingModal = false;
        },
        // viewInvoiceModal(orderData) {
        //     this.order = orderData;
        //     this.showInvoiceModal = true;
        // },
        markOrderPending() {
            this.markOrderPendingIsLoading = true;
            changeStatus(this.orderId, {
                status: "requested",
            })
                .then(() => {
                    this.markOrderPendingIsLoading = false;
                    this.showPendingModal = false;
                    this.getOrders();
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        async cancelOrder(order) {
            let activeOrderStatuses = [
                "On The Way",
                "Picked Up",
                "Dropped Off",
            ];

            if (
                order.store_id != null &&
                activeOrderStatuses.includes(order.status)
            ) {
                let setting = await this.getSystemSettings(
                    "business_order_cancel_price"
                );
                this.orderCancelMessage = this.$t(
                    "cancel_order_amount_confirmation",
                    {
                        amount: setting?.value,
                    }
                );
            } else {
                this.orderCancelMessage = this.$t("order_cancel_confirm");
            }
        },
        getSystemSettings(key = null) {
            return new Promise((resolve) => {
                getSettings({
                    limit: -1,
                })
                    .then((res) => {
                        if (key) {
                            res.data.forEach(function (item) {
                                if (item.key == key) {
                                    resolve(item);
                                }
                            });

                            resolve(null);
                        }
                    })
                    .catch(({ response }) => {
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            });
        },
        viewMakeItPublicModal(id) {
            this.orderId = id;
            this.showMakeItPublicModal = true;
        },
        closeMakeItPublicModal() {
            this.showMakeItPublicModal = false;
        },
        makePublicOrder() {
            this.markItPublicOrderLoading = true;
            makePublicOrder(this.orderId, {})
                .then((res) => {
                    this.markItPublicOrderLoading = false;
                    this.showMakeItPublicModal = false;
                    this.getOrders();
                    this.runToast(res.message, "success");
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                    this.markItPublicOrderLoading = false;
                    this.showMakeItPublicModal = false;
                });
        },
        handleSelectionChangeRowCheck(row) {
            this.onHoldOrderSelected = row;
        },
        rowClicked(row, column, event) {
            if (
                column &&
                (column.label == this.$t("store_name") ||
                    column.label == this.$t("provider") ||
                    column.label == this.$t("customer") ||
                    column.label == this.$t("actions"))
            ) {
                if (
                    column.label == this.$t("store_name") &&
                    row.store_id != null
                ) {
                    this.$router.push({
                        name: "Store Details",
                        params: { id: row.store?.id },
                    });
                } else if (
                    column.label == this.$t("provider") &&
                    row.provider_id != null
                ) {
                    this.$router.push({
                        name: "Provider Details",
                        params: { id: row.provider?.id },
                    });
                } else if (
                    column.label == this.$t("customer") &&
                    row.customer_id != null
                ) {
                    this.$router.push({
                        name: "Customer Details",
                        params: { id: row.customer?.id },
                    });
                }
                return;
            } else {
                if (
                    column &&
                    (column.label == this.$t("order_no") ||
                        column.label == this.$t("total") ||
                        column.label == this.$t("order_date") ||
                        column.label == this.$t("status"))
                ) {
                    // There is complaint is there so redirect on complaints page
                    if (
                        column.label == this.$t("order_no") &&
                        row.pending_complaints.length > 0
                    ) {
                        return;
                    }
                    let routeData;
                    if (this.storeOrder === true) {
                        routeData = this.$router.push({
                            name: "Store Order Detail",
                            params: { id: row.id },
                        });
                    } else {
                        routeData = this.$router.push({
                            name: "Order Details",
                            params: { id: row.id },
                        });
                    }
                }
            }
        },
        orderTypeChange(type) {
            if (type == "all") {
                this.type = "";
                this.selectedOrderType = "";
            } else if (type == "store-normal") {
                this.type = "store-normal";
                this.selectedOrderType = "store-normal";
            } else if (type == "on_hold") {
                this.type = "";
                this.selectedOrderType = type;
            } else {
                this.type = type;
                this.selectedOrderType = type;
            }

            this.filters.status =
                type == "on_hold" && type == "on_hold"
                    ? "on_hold"
                    : this.filters.status != "on_hold"
                    ? this.filters.status
                    : null;
            this.allowOnHoldStatusProcess();
        },
        isShowCompleteOrderBtn(order) {
            // if (
            //     (this.hasRole(1) || this.hasRole(6)) &&
            //     (order.status == "Accepted" || order.status == "On The Way" || order.status == "Picked Up" || order.status == "Dropped Off")
            // ) {
            if (
                (this.hasRole(1) || this.hasRole(6)) && order.status != "Cancelled" && order.status != "Completed" 
            ) {
                if (order.type == "Package" && order.parent_id == null) {
                    return false;
                }
                return true;
            } else {
                return false;
            }
        },
        dateFilterChange() {
            this.$emit('dateChanged', this.selectedDate);
            if (this.selectedDate.length > 0) this.getOrders();
        },
        vehicleTypeChange(){
            this.$emit('vehicleTypeChanged', this.filters.vehicle_type_id);    
        },
        // Is allow the process of on hold order status
        allowOnHoldStatusProcess() {
            if (
                this.isZidshipStore() &&
                this.selectedOrderType == "on_hold" &&
                this.filters.status == "on_hold"
            ) {
                this.isAllowOnHoldStatusProcess = true;
            } else {
                this.isAllowOnHoldStatusProcess = false;
            }
        },
        getVehicleType() {
            getVehicleTypes({
                "filter[status]": "active",
                limit: -1,
            })
                .then((res) => {
                    res.data.forEach((item) => {
                        this.vehicleTypes.push({
                            value: item["id"],
                            label:
                                this.getLang == "ar"
                                    ? item["type_ar"]
                                    : item["type"],
                        });
                    });
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        changeDashboardOrderStatus(status) {
            this.filters.status = status;
        },
        viewProofOfDeliveryModal(order) {
            console.log(order);
            this.order = order;
            this.proofOfDelivery = true;
        },
    },
};
</script>

<style lang="scss">
.top-nav-btn .nav-wrapper .nav {
    max-width: 100% !important;
}
.card {
    position: relative;
    padding-left: 50px !important;
    padding-right: 50px !important;
}
.card .btn.table-icons {
    padding: 0rem 1.5rem !important;
}
.card .col.d-flex.justify-content-end {
    padding: 0 !important;
}
.order-list-packages {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.rtl {
    .order-list-packages {
        padding-top: 17px;

        a {
            margin: 0 0 0 10px;
        }
    }
}
.order-list-packages a {
    margin: 0 10px 0 0;
    padding: 0.75rem 1rem;
    color: rgb(23, 23, 72);
    font-weight: 500;
    font-size: 0.875rem;
    border-bottom: 2px solid transparent;
    transition: all 0.15s ease;
    &:hover,
    &:focus {
        transition: 0.5s;
    }
    &.active {
        font-weight: 700;
        color: #7368fb;
        border-color: #7368fb;
    }
}
.table-flush-col-hover col,
.table-flush-col-hover .cell {
    cursor: pointer;
}
</style>
