<template>
    <div>
        <hr />
        <div class="p-5 mb-0 card rounded-md">
            <h3 class="mb-0">{{ $t(addOrEdit) }}</h3>

            <Form
                @submit="onSubmit"
                v-loading="isLoading"
                class=""
                ref="formRef"
            >
                <div class="mt-3 row">
                    <div class="col-md-6">
                        <label for="name">{{ $t("title") }}</label>
                        <Field
                            type="text"
                            class="form-control"
                            :placeholder="$t('title')"
                            :label="$t('title')"
                            name="title"
                            v-model="form.title"
                        />
                        <ErrorMessage name="title" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="">{{ $t("title_ar") }}</label>
                        <Field
                            type="text"
                            :label="$t('title_ar')"
                            class="form-control"
                            :placeholder="$t('title_ar')"
                            name="title_ar"
                            v-model="form.title_ar"
                        />
                        <ErrorMessage name="title_ar" class="error" />
                    </div>
                </div>

                <div class="row mt-3">
                    <div
                        :class="
                            form.banner_image && id != 'add'
                                ? 'col-md-6'
                                : 'col-md-12'
                        "
                    >
                        <label for="banner_image" class="my-1 mr-2">{{
                            $t("banner_image")
                        }}</label>
                        <dropzone-file-upload
                            @thumbnail="imageUploadEvent"
                        ></dropzone-file-upload>
                        <p class="error" v-if="showBannerImageValidateMessage">
                            {{ $t("banner_image_required") }}
                        </p>
                    </div>
                    <div
                        class="col-md-6"
                        v-if="form.banner_image && id != 'add'"
                    >
                        <label for="banner_image" class="my-1 mr-2">{{
                            $t("banner_image")
                        }}</label>
                        <div class="mt-2">
                            <img
                                :src="form.banner_image"
                                alt=""
                                srcset=""
                                style="width: 50%"
                            />
                        </div>
                    </div>
                </div>

                <div class="w-full text-center mt-5">
                    <base-button
                        native-type="submit"
                        class="bg-primary text-white"
                        :loading="submitSpinner"
                    >
                        {{ $t("save") }}
                    </base-button>
                    <base-button
                        native-type="button"
                        class="bg-primary text-white mr-2"
                        @click="goToList"
                    >
                        {{ $t("cancel") }}
                    </base-button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import { addBanner, editBanner, getBanner } from "@/api/banner";
import mixin from "@/mixins/common";
import { serialize } from "object-to-formdata";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";

export default {
    data() {
        return {
            addOrEdit: "",
            banner: {},
            form: {
                title: null,
                title_ar: null,
                banner_image: null,
            },
            isLoading: false,
            submitSpinner: false,
            showBannerImageValidateMessage: false,
            banner_image: "",
        };
    },
    props: ["id"],
    mixins: [mixin],
    components: { DropzoneFileUpload },
    created() {
        if (this.id === "add") {
            this.addOrEdit = this.$t("add_banner");
        } else {
            this.addOrEdit = this.$t("edit_banner");
            this.getBanner();
        }
    },

    methods: {
        goToList() {
            this.$router.push({ name: "Banner List" });
        },
        imageUploadEvent(data) {
            this.banner_image = data;
        },
        getBanner() {
            this.isLoading = true;
            getBanner(this.id, {})
                .then((res) => {
                    this.isLoading = false;
                    this.banner = res;
                    this.form = res;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        async onSubmit(data) {
            this.submitSpinner = true;

            if (!this.banner_image && !this.form.banner_image) {
                this.showBannerImageValidateMessage = true;
                this.submitSpinner = false;
                return false;
            } else {
                this.showBannerImageValidateMessage = false;
            }

            const formData = serialize(data);
            formData.append("banner_image", this.banner_image);

            if (this.id == "add") {
                addBanner(formData)
                    .then(() => {
                        this.submitSpinner = false;
                        this.$router.push({ name: "Banner List" });
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            } else {
                editBanner(this.id, formData, {
                    _method: "PATCH",
                })
                    .then(() => {
                        this.isLoading = false;
                        this.$router.push({ name: "Banner List" });
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            }
        },
    },
};
</script>

<style lang="scss"></style>
