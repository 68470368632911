<template>
    <div>
        <div class="card card-date-wrap mb-3">
            <div class="col-2">
                <label for="storeDropDown">
                    {{ $t("dashboard_page.start_date") }}</label
                >
                <flat-picker
                    :config="{
                        allowInput: true,
                        dateFormat: 'd-m-Y',
                    }"
                    class="form-control datepicker"
                    :placeholder="$t('select')"
                    v-model="selectedDate[0]"
                    @change="getStatisticsData()"
                >
                </flat-picker>
            </div>

            <div class="col-2">
                <label for="storeDropDown">
                    {{ $t("dashboard_page.end_date") }}</label
                >
                <flat-picker
                    :config="{
                        allowInput: true,
                        dateFormat: 'd-m-Y',
                    }"
                    class="form-control datepicker"
                    :placeholder="$t('select')"
                    v-model="selectedDate[1]"
                    @change="getStatisticsData()"
                >
                </flat-picker>
            </div>
            <div class="col-2">
                <label for="storeDropDown"> {{ $t("store") }}</label>
                <el-select
                    clearable
                    class="d-block"
                    :placeholder="$t('select')"
                    v-model="storeId"
                    @change="getStatisticsData()"
                    filterable
                >
                    <el-option
                        v-for="option in storeOptions"
                        class="select-danger"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>

        <div class="row dashboard-counters">
            <div
                class="col"
                v-for="(count, key) in storeStatisticsData"
                :key="count"
            >
                <div class="card rounded-md p-2" style="cursor: pointer">
                    <h3 class="card-title text-uppercase text-muted mt-2 mb-0">
                        {{ $t(key) }}
                    </h3>
                    <span class="font-weight-700 h1"> {{ count }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getStoreStatisticsCount } from "@/api/dashboard";
import { get } from "@/api/store";
import mixin from "@/mixins/common";
import "flatpickr/dist/flatpickr.css";
import flatPicker from "vue-flatpickr-component";
export default {
    data() {
        return {
            storeStatisticsData: {},
            selectedDate: [new Date(), new Date()],
            storeOptions: [],
            storeId: null,
        };
    },
    components: {
        flatPicker,
    },

    watch: {
        // storeId: {
        //     handler() {
        //         this.getStatisticsData();
        //     },
        // },
    },

    mixins: [mixin],

    created() {
        this.getStatisticsData();
    },
    methods: {
        getStatisticsData() {
            getStoreStatisticsCount({
                start_date: this.selectedDate[0],
                end_date: this.selectedDate[1],
                store_id: this.storeId,
            })
                .then((res) => {
                    this.storeStatisticsData = res;
                    get({
                        limit: -1,
                    }).then((storesList) => {
                        this.storeOptions = storesList.data;
                    });
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
    },
};
</script>

<style></style>
