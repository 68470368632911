<template>
    <div>
        <div class="note-wrap">
            <button class="btn btn-default mb-2" @click="notificationModal">
                {{ $t("send_notification") }}
            </button>
        </div>

        <!-- Modal props show is for toggling Modal Display 
        @close child event is for closing modal -->
        <!-- Modal  -->
        <modal
            :show="showModal"
            @close="showModal = false"
            size="sm"
            body-classes="p-0"
        >
            <template v-slot:header>
                <div class="text-bold text-lg text-center mt-2 mb-1">
                    {{ $t("send_notification") }}
                </div>
            </template>
            <div class="px-3 d-block">
                <Form ref="sendNotificationRef" @submit="sendNotification">
                    <label for="" class="my-1">{{
                        $t("notification_title")
                    }}</label>
                    <Field
                        type="text"
                        rules="required"
                        :label="$t('notification_title')"
                        class="form-control"
                        :placeholder="$t('notification_title')"
                        name="title"
                    />
                    <ErrorMessage name="title" class="error" />
                    <label for="" class="my-1">{{
                        $t("notification_content")
                    }}</label>

                    <Field
                        rules="required"
                        :label="$t('notification_content')"
                        class="form-control"
                        v-slot="{ field }"
                        :placeholder="$t('notification_content')"
                        name="content"
                    >
                        <textarea
                            name="content"
                            class="form-control"
                            rows="5"
                            :placeholder="$t('notification_content')"
                            v-bind="field"
                        ></textarea>
                    </Field>
                    <ErrorMessage name="content" class="error" />

                    <div class="w-full text-center mt-3 mb-2">
                        <base-button
                            :loading="showButtonLoader"
                            native-type="submit"
                            class="text-white bg-primary"
                        >
                            {{ $t("submit") }}
                        </base-button>
                        <input
                            type="button"
                            class="btn btn-default text-white"
                            @click="showModal = false"
                            :value="$t('close')"
                        />
                    </div>
                </Form>
            </div>
        </modal>
    </div>
</template>

<script>
import { send } from "@/api/broadcastNotification";
import mixin from "@/mixins/common";

export default {
    data() {
        return {
            showModal: false,
            showButtonLoader: false,
        };
    },
    components: {},
    props: ["id"],
    mixins: [mixin],
    methods: {
        sendNotification(data) {
            this.showButtonLoader = true;
            data["user_id"] = this.id;
            data["notification_for"] = "specific_user";
            send(data)
                .then(() => {
                    this.showButtonLoader = false;
                    this.$refs.sendNotificationRef.resetForm();
                    this.showModal = false;
                    this.$emit("notificationSent");
                })
                .catch(({ response }) => {
                    this.showButtonLoader = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        notificationModal() {
            this.showModal = true;
        },
    },
};
</script>

<style></style>
