<template>
    <div>
        <label for="" class="my-1">{{ $t("name") }}</label>
        <Field
            type="text"
            rules="required|max:20"
            :label="$t('name')"
            class="form-control"
            :placeholder="$t('name')"
            name="name"
            v-model="formState.name"
        />
        <ErrorMessage name="name" class="error" />

        <label for="" class="my-1">{{ $t("email") }}</label>
        <Field
            type="email"
            rules="email"
            :label="$t('email')"
            class="form-control"
            :placeholder="$t('email')"
            name="email"
            id="email"
            v-model="formState.email"
        />
        <ErrorMessage name="email" class="error" />

        <label for="" class="my-1">{{ $t("mobile_no") }}</label>
        <Field
            type="number"
            rules="required|length:9"
            :label="$t('mobile_no')"
            class="form-control"
            :placeholder="$t('mobile_no')"
            name="mobile_no"
            v-model="formState.mobile_no"
        />
        <ErrorMessage name="mobile_no" class="error" />

        <label for="" class="my-1">{{ $t("stcpay_mobile_no") }}</label>
        <Field
            type="number"
            rules="required|length:9"
            :label="$t('stcpay_mobile_no')"
            class="form-control"
            :placeholder="$t('stcpay_mobile_no')"
            name="stcpay_mobile_no"
            v-model="formState.stcpay_mobile_no"
        />
        <ErrorMessage name="stcpay_mobile_no" class="error" />

        <label class="my-1">{{ $t("city") }}</label>
        <Field
            v-slot="{ field }"
            name="city_id"
            rules="required"
            :label="$t('city')"
            class="form-control"
            v-model="formState.city_id"
        >
            <el-select
                class="d-block"
                name="city_id"
                v-bind="field"
                :placeholder="$t('city')"
                v-model="formState.city_id"
                filterable
            >
                <el-option
                    v-for="option in cityOptions"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                >
                </el-option>
            </el-select>
        </Field>
        <ErrorMessage name="city_id" class="error" />

        <div class="mt-2 d-flex align-items-center">
            <label for="allow_business_order" class="my-1 mr-2">{{
                $t("allow_business_order")
            }}</label>
            <Field
                name="allow_business_order"
                v-model="formState.allow_business_order"
            >
                <input
                    type="checkbox"
                    v-model="formState.allow_business_order"
                    name="allow_business_order"
                />
            </Field>
        </div>
        <!-- <div class="mt-2 d-flex align-items-center">
            <label for="allow_all_order" class="my-1 mr-2">{{
                $t("allow_all_order")
            }}</label>
            <Field
                name="is_allow_all_order"
                v-model="formState.is_allow_all_order"
            >
                <input
                    type="checkbox"
                    v-model="formState.is_allow_all_order"
                    name="is_allow_all_order"
                />
            </Field>
        </div> -->
    </div>
</template>

<script>
import { cloneDeep } from "lodash-es";

export default {
    data() {
        return {
            formState: {},
        };
    },
    props: ["provider", "cityOptions"],
    created() {
        if (this.provider) this.cloneData(this.provider);
    },
    methods: {
        cloneData(provider) {
            this.formState = cloneDeep(provider);
        },
    },
};
</script>

<style></style>
