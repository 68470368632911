<template>
    <div>
        <div class="card mb-0 px-5 rounded-md">
            <status-filter-tabs
                statusCategory="complaintStatus"
                @statusChanged="(status) => (filters.status = status)"
            />
            <div>
                <div class="d-flex mb-4">
                    <search-filter
                        :filters="[
                            {
                                id: 'complaint_no',
                            },
                            {
                                id: 'order_no',
                            },
                        ]"
                        @search="(searchObject) => (filters = searchObject)"
                    />
                    <drop-down-filter
                        class="ml-3 mr-3"
                        :dropDownFilters="[
                            {
                                id: 'reason',
                                options: defaultOptions.complaintReason,
                            },
                        ]"
                        @dropDownOptionSelected="
                            (optionsObject) =>
                                (filters = { ...filters, ...optionsObject })
                        "
                    />
                </div>
            </div>
            <div class="row">
                <main
                    :class="
                        isFilter
                            ? 'col-md-9 col-xs-11 p-l-2 p-t-2'
                            : 'col-md-12 col-xs-11 p-l-2 p-t-2'
                    "
                >
                    <el-table
                        id="complaintsTable"
                        class="table-responsive table-flush"
                        header-row-class-name="thead-light"
                        :data="complaints"
                        v-loading="isLoading"
                        :empty-text="$t('no_data_found')"
                    >
                        <el-table-column
                            :label="$t('complaint_no')"
                            min-width="180px"
                        >
                            <template v-slot="{ row }">
                                <span
                                    class="font-weight-600 name mb-0 text-capitalize"
                                    >{{ row.complaint_no }}</span
                                >
                            </template>
                        </el-table-column>
                        <el-table-column
                            :label="$t('order_no')"
                            min-width="150px"
                        >
                            <template v-slot="{ row }">
                                <order-number-label
                                    :row="row?.order"
                                ></order-number-label>
                            </template>
                        </el-table-column>

                        <!-- Complaint By -->
                        <el-table-column
                            :label="$t('complaint_by')"
                            min-width="200px"
                            v-if="showAllComplaints === true"
                        >
                            <template v-slot="{ row }">
                                <span
                                    v-if="row.complaint_by"
                                    class="font-weight-600 name mb-0 text-capitalize"
                                    >{{ row.complaint_by?.name }}</span
                                >
                                <span
                                    v-else
                                    class="font-weight-600 name mb-0 text-capitalize text-danger"
                                    >{{ $t("customerDeleted") }}</span
                                >
                            </template>
                        </el-table-column>

                        <el-table-column
                            :label="$t('reason')"
                            min-width="150px"
                            prop="email"
                        >
                            <template v-slot="{ row }">
                                <span
                                    class="font-weight-600 name mb-0 text-nowrap"
                                    >{{ row.reason }}</span
                                >
                            </template>
                        </el-table-column>

                        <el-table-column
                            :label="$t('complaint')"
                            min-width="250px"
                        >
                            <template v-slot="{ row }">
                                <span class="font-weight-600 name mb-0">{{
                                    row.complaint
                                }}</span>
                            </template>
                        </el-table-column>
                        <!-- Status  -->
                        <el-table-column
                            :label="$t('status')"
                            min-width="150px"
                            prop="status"
                        >
                            <template v-slot="{ row }">
                                <custom-label :label="row.status" />
                            </template>
                        </el-table-column>

                        <el-table-column
                            :label="$t('response')"
                            min-width="250px"
                        >
                            <template v-slot="{ row }">
                                <span class="font-weight-600 name mb-0">{{
                                    row.response
                                }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            :label="$t('created_at')"
                            min-width="200px"
                        >
                            <template v-slot="{ row }">
                                <span
                                    class="font-weight-600 name mb-0 text-capitalize"
                                    >{{ row.created_at }}</span
                                >
                            </template>
                        </el-table-column>

                        <el-table-column
                            :label="$t('resolved_by')"
                            min-width="150px"
                        >
                            <template v-slot="{ row }">
                                <span class="font-weight-600 name mb-0">{{
                                    row.complaint_resolved_by?.name
                                }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            :label="$t('resolved_at')"
                            min-width="200px"
                        >
                            <template v-slot="{ row }">
                                <span
                                    v-if="row.complaint_resolved_by?.name"
                                    class="font-weight-600 name mb-0 text-capitalize"
                                    >{{ row.updated_at }}</span
                                >
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="showAllComplaints === true"
                            :label="$t('actions')"
                            min-width="210px"
                        >
                            <template v-slot="{ row }">
                                <div
                                    class="d-flex justify-content-between"
                                    v-if="changeStatusAllowed(row.status)"
                                >
                                    <el-tooltip
                                        :content="$t('edit')"
                                        placement="top"
                                    >
                                        <badge
                                            circle
                                            size="lg"
                                            class="bg-sky-dark text-secondary"
                                            tooltip="View"
                                            style="cursor: pointer"
                                            @click="closeComplaintModal(row.id)"
                                        >
                                            <i class="fas fa-pen mx-1"></i>
                                        </badge>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </main>
            </div>
            <!-- End of row class -->
            <div class="card-footer p-0 pt-5 pb-5" v-if="complaints.length > 0">
                <!-- Start of pagination  -->
                <base-pagination
                    :meta="meta"
                    v-model="complaintsCurrentPage"
                    :total="complaintsTotalData"
                ></base-pagination>
            </div>
            <!-- End of pagination -->
            <!-- Modal props show is for toggling Modal Display 
        @close child event is for closing modal -->
            <!-- Modal  -->
            <modal
                :show="showModal"
                @close="showModal = false"
                size="sm"
                body-classes="p-0"
                v-loading="isLoading"
            >
                <template v-slot:header>
                    <div class="text-bold text-lg text-center mt-2 mb-1">
                        {{ $t("complete_complaint") }}
                    </div>
                </template>
                <div class="px-3 d-block">
                    <Form @submit="closeComplaint" v-loading="isLoading">
                        <label for="response">{{ $t("response") }}</label>
                        <Field
                            name="response"
                            rules="required"
                            :label="$t('response')"
                            v-slot="{ field }"
                        >
                            <textarea
                                name="response"
                                id="response"
                                :placeholder="$t('response')"
                                class="form-control mb-3"
                                rows="3"
                                v-bind="field"
                            ></textarea>
                        </Field>
                        <ErrorMessage name="response" class="error" />
                        <div class="w-full text-center mb-2">
                            <!-- <input
                                type="submit"
                                :value="$t('submit')"
                                class="btn btn-default text-white"
                            /> -->
                            <base-button
                                native-type="submit"
                                class="btn btn-default text-white"
                                :loading="buttonSpinner"
                            >
                                {{ $t("submit") }}
                            </base-button>
                        </div>
                    </Form>
                </div>
            </modal>
        </div>
        <!-- End of Card-->
    </div>
</template>

<script>
import { get, close } from "@/api/complaints";
import mixin from "@/mixins/common";
import OrderNumberLabel from "@/components/common/OrderNumberLabel.vue";

export default {
    data() {
        return {
            complaints: [],
            complaintsTotalData: null,
            isLoading: true,
            meta: {},
            buttonSpinner: false,
            complaintId: null,
            isFilter: false,
            showModal: false,
            filters: {
                complaint_no: null,
                reason: "",
                order_no: null,
            },
            complaintsCurrentPage: 1,
        };
    },
    components: { OrderNumberLabel },
    watch: {
        complaintsCurrentPage: {
            handler: function () {
                this.getComplaints();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.complaintsCurrentPage = 1;
                this.getComplaints();
            },
        },
    },
    created() {
        if (
            this.showAllComplaints === true &&
            this.businessComplaints === true
        ) {
            this.type = "business";
        } else if (
            this.showAllComplaints === true &&
            this.businessComplaints === false
        ) {
            this.type = "normal";
        } else {
            this.type = "";
        }
        this.getComplaints();
    },
    props: {
        id: [String, Number],
        showAllComplaints: {
            type: Boolean,
            required: false,
            default: false,
        },
        businessComplaints: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mixins: [mixin],
    methods: {
        changeStatusAllowed(status) {
            return (
                status == "pending" &&
                (this.userRole == 1 || this.userRole == 6)
            );
        },
        closeComplaintModal(id) {
            this.showModal = true;
            this.complaintId = id;
        },
        closeComplaint(data) {
            this.buttonSpinner = true;
            close(this.complaintId, data)
                .then((res) => {
                    this.showModal = false;
                    this.buttonSpinner = false;
                    this.runToast(res.message, "success");
                    this.getComplaints();
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                    this.buttonSpinner = false;
                });
        },
        toggleFilter() {
            this.isFilter = !this.isFilter;
            if (this.isFilter) {
                $("#togglebar").addClass("show");
            } else {
                $("#togglebar").removeClass("show");
            }
        },
        getComplaints() {
            this.isLoading = true;
            get({
                page: this.complaintsCurrentPage,

                include: "order,complaintBy,complaintResolvedBy",
                filter: {
                    type: this.type,
                    complaint_by:
                        this.showAllComplaints === false ? this.id : "",
                    ...this.filters,
                },
            })
                .then((res) => {
                    this.isLoading = false;
                    this.complaints = res.data;
                    this.complaintsTotalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                reason: "",
                complaint_no: null,
            };
        },
    },
};
</script>

<style></style>
