<template>
    <div class="d-inline">
        <base-button
            v-if="alertCategory == 'view_cancel_order'"
            class="text-danger bg-transparent border-danger"
            @click="alertModal()"
        >
            {{ $t("cancel_order") }}
        </base-button>
        <el-tooltip
            v-else
            :content="
                alertCategory == 'cancel_order' ? $t('cancel') : $t('delete')
            "
            placement="top"
        >
            <badge
                circle
                size="lg"
                class="bg-sky-dark"
                style="cursor: pointer"
                @click="alertModal()"
            >
                <img
                    :class="alertCategory == 'address' ? 'delete-icon' : ''"
                    src="@/assets/svg/trash.svg"
                    v-if="alertCategory != 'cancel_order'"
                />
                <img src="@/assets/svg/close.svg" v-else />
            </badge>
        </el-tooltip>

        <!-- Modal props show is for toggling Modal Display 
        @close child event is for closing modal -->
        <!-- Modal  -->
        <modal
            footerClasses="justify-content-center"
            :show="showModal"
            @close="showModal = false"
            size="sm"
            body-classes="p-0"
            v-loading="modalLoading"
        >
            <template v-slot:header>
                <div>
                    <img
                        src="@/assets/svg/cancel-order.svg"
                        alt=""
                        style="width: 150px; height: 150px"
                    />
                    <div class="text-bold text-lg text-center mt-2 mb-1">
                        {{ title }}
                    </div>
                </div>
            </template>
            <Form @submit="formSubmit" ref="alertForm">
                <div class="px-3 d-block">
                    <h2 class="text-break font-weight-900 text-center">
                        <slot name="orderNo"> </slot>
                        {{ body }}
                    </h2>
                    <div
                        v-if="
                            alertCategory == 'cancel_order' ||
                            alertCategory == 'view_cancel_order'
                        "
                    >
                        <label for="reason">{{ $t("select_reason") }}</label>
                        <Field
                            v-slot="{ field }"
                            name="reason"
                            rules="required"
                            :label="$t('reason')"
                            v-model="reason"
                        >
                            <el-select
                                class="d-block"
                                name="reason"
                                v-bind="field"
                                :placeholder="$t('select')"
                                v-model="reason"
                                filterable
                            >
                                <el-option
                                    v-for="option in defaultOptions.cancelOrderReason"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="$t(option.label)"
                                    :key="option.label"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage name="reason" class="error" />

                        <span v-if="reason == 'other'">
                            <label for="reason">{{ $t("reason") }}</label>
                            <Field
                                as="textarea"
                                rules="required"
                                :label="$t('reason')"
                                class="form-control"
                                :placeholder="$t('reason')"
                                name="cancelOrderReason"
                                v-model="cancelOrderReason"
                                rows="4"
                            />
                            <ErrorMessage
                                name="cancelOrderReason"
                                class="error"
                            />
                        </span>
                        <div
                            v-if="
                                order != null &&
                                order?.store_id == null &&
                                (order.original_status == 'on_the_way' ||
                                    order.original_status == 'picked_up' ||
                                    order.original_status == 'dropped_off')
                            "
                            class="d-flex"
                        >
                            <input
                                type="checkbox"
                                :id="currentRow?.id"
                                v-model="isDeductCancelOrderCredit"
                                name="value"
                            />
                            <label for="" class="my-1 mx-2"
                                >{{ $t("cancelOrderRequestFromCustomer") }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="w-full text-center mt-3">
                    <base-button
                        :loading="submitSpinner"
                        native-type="submit"
                        class="alert-model-btn btn btn-default text-white"
                    >
                        {{ $t("yes") }}
                    </base-button>
                    <button
                        type="button"
                        class="alert-model-btn btn btn-default text-white"
                        data-dismiss="modal"
                        @click="closeModal"
                    >
                        {{ $t("no") }}
                    </button>
                </div>
            </Form>
        </modal>
    </div>
</template>

<script>
import store from "@/store/index";
import mixin from "@/mixins/common";
import { deleteItem } from "@/api/item";
import { deleteUser } from "@/api/users";
import { changeStatus } from "@/api/orders";
import { deleteBanner } from "@/api/banner";
import { deleteAddress } from "@/api/addresses";
import { deleteCouponCode } from "@/api/couponCodes";
export default {
    data() {
        return {
            showModal: false,
            modalLoading: true,
            submitSpinner: false,
            reason: null,
            cancelOrderReason: null,
            isDeductCancelOrderCredit: false,
        };
    },
    watch: {
        reason: {
            immediate: true,
        },
    },
    props: {
        alertCategory: {
            type: String,
            default: "users",
        },
        title: {
            type: String,
        },
        body: {
            type: String,
            default: "Alert Body",
        },
        id: {
            type: [String, Number],
        },
        order: {
            type: Array,
            default: null,
        },
    },
    mixins: [mixin],
    methods: {
        alertModal() {
            this.$refs.alertForm.resetForm();
            this.showModal = true;
            this.modalLoading = false;
        },
        closeModal() {
            this.showModal = false;
        },
        removeUser() {
            this.submitSpinner = true;
            deleteUser(this.id)
                .then((res) => {
                    this.submitSpinner = false;
                    this.showModal = false;
                    this.$emit("alertModalCompleted");
                    this.runToast(res.message, "success");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        cancelOrder(data) {
            this.submitSpinner = true;
            changeStatus(this.id, {
                status: "cancelled",
                // In Store Admin User Account , Store Id is required
                store_id: this.storeId,
                reason:
                    this.reason == "other"
                        ? this.cancelOrderReason
                        : this.reason,
                is_deduct_cancel_order_credit: this.isDeductCancelOrderCredit
                    ? 1
                    : 0,
            })
                .then((res) => {
                    this.submitSpinner = false;
                    this.showModal = false;
                    // this.runToast(res.message, "success");
                    this.$emit("alertModalCompleted");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        deleteCouponCode() {
            this.submitSpinner = true;
            deleteCouponCode(this.id)
                .then((res) => {
                    this.submitSpinner = false;
                    this.showModal = false;
                    this.$emit("alertModalCompleted");
                    this.runToast(res.message, "success");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        deleteItem() {
            this.submitSpinner = true;
            deleteItem(this.id)
                .then((res) => {
                    this.submitSpinner = false;
                    this.showModal = false;
                    this.$emit("alertModalCompleted");
                    this.runToast(res.message, "success");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    this.apiError(response);
                });
        },
        deleteBanner() {
            deleteBanner(this.id)
                .then(() => {
                    this.submitSpinner = false;
                    this.showModal = false;
                    this.$emit("alertModalCompleted");
                    this.runToast(res.message, "success");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    this.apiError(response);
                });
        },
        deleteAddress() {
            deleteAddress(this.id, {
                store_id: store.getters.store?.id,
            })
                .then((res) => {
                    this.submitSpinner = false;
                    this.showModal = false;
                    this.$emit("alertModalCompleted");
                    this.runToast(res.message, "success");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    this.apiError(response);
                });
        },
        formSubmit(data) {
            if (this.alertCategory === "users") {
                this.removeUser();
            } else if (this.alertCategory === "coupon_code") {
                this.deleteCouponCode();
            } else if (this.alertCategory === "item") {
                this.deleteItem();
            } else if (this.alertCategory === "banner_delete") {
                this.deleteBanner();
            } else if (this.alertCategory === "address") {
                this.deleteAddress();
            } else {
                this.cancelOrder(data);
            }
        },
    },
};
</script>

<style scoped lang="scss">
button.alert-model-btn.btn.btn-default.text-white {
    padding: 0.625rem 3.25rem !important;
}
.delete-icon {
    width: 20px !important;
}
</style>
