<template>
    <side-bar :userName="user.name">
        <template v-slot:links>
            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/dashboard-icon.svg')"
                :link="{
                    name: $t('dashboard'),
                    path: '/dashboard?status=live',
                }"
            />
            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/users.svg')"
                :link="{
                    name: $t('operation_team'),
                    path: '/operation-dashboard?status=live',
                }"
            />
            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/users.svg')"
                :link="{
                    name: $t('individual'),
                }"
            >
                <!-- Link name is convert from the sidebarItem.vue file -->
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/users.svg')"
                    :link="{
                        name: 'customers',
                        path: '/customers',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/shopping-cart.svg')"
                    :link="{
                        name: 'orders',
                        path: '/orders',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/message-question.svg')"
                    :link="{
                        name: 'complaints',
                        path: '/complaints',
                    }"
                />
            </sidebar-item>

            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/shop.svg')"
                :link="{
                    name: $t('business'),
                }"
            >
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/shop.svg')"
                    :link="{
                        name: 'stores',
                        path: '/stores',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/shopping-cart.svg')"
                    :link="{
                        name: 'orders',
                        path: '/store-orders',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/message-question.svg')"
                    :link="{
                        name: 'complaints',
                        path: '/business-complaints',
                    }"
                />
            </sidebar-item>

            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/home-spc.svg')"
                :link="{
                    name: $t('services'),
                }"
            >
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/users.svg')"
                    :link="{
                        name: 'providers',
                        path: '/providers',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/home-spc.svg')"
                    :link="{
                        name: 'service_provider_company',
                        path: '/service-provider-companies',
                    }"
                />

                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/order-reject.svg')"
                    :link="{
                        name: 'order_rejects',
                        path: '/orders/rejects',
                    }"
                />
            </sidebar-item>

            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/credit-card.svg')"
                :link="{
                    name: $t('financial'),
                }"
            >
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/invoice.svg')"
                    v-if="hasRole(1)"
                    :link="{
                        name: 'invoice_history',
                        path: '/invoice-history',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/clock.svg')"
                    :link="{
                        name: 'pending_transactions',
                        path: '/pending-transaction',
                    }"
                />
                <sidebar-item
                    v-if="hasRole(1)"
                    :svg="true"
                    :svgPath="require('@/assets/svg/credit-card.svg')"
                    :link="{
                        name: 'provider_credits',
                        path: '/provider-credits',
                    }"
                />
                <sidebar-item
                    v-if="hasRole(1)"
                    :svg="true"
                    :svgPath="require('@/assets/svg/referral-credit.svg')"
                    :link="{
                        name: 'referral_credits',
                        path: '/referral-credits',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/receipt.svg')"
                    v-if="hasRole(1)"
                    :link="{
                        name: 'credit_history',
                        path: '/credit-history',
                    }"
                />
                <!-- <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/credit-card.svg')"
                    :link="{
                        name: 'wallet_history',
                        path: '/wallet-histories',
                    }"
                /> -->
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/images/compensation.png')"
                    :link="{
                        name: 'compensation',
                        path: '/compensation',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/images/fine.png')"
                    :link="{
                        name: 'fine',
                        path: '/fine',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/bonus-add.svg')"
                    v-if="hasRole(1)"
                    :link="{
                        name: 'bonus',
                        path: '/bonus',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/images/coupon.png')"
                    :link="{
                        name: 'coupon_codes',
                        path: '/coupon-codes',
                    }"
                />
            </sidebar-item>

            <sidebar-item
                :svg="true"
                :svgPath="require('@/assets/svg/setting-2.svg')"
                :link="{
                    name: $t('settings'),
                }"
            >
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/images/box.png')"
                    :link="{
                        name: 'items',
                        path: '/items',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/shop.svg')"
                    :link="{
                        name: 'pickup_stores',
                        path: '/pickup-stores',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/vehicle.svg')"
                    v-if="hasRole(1)"
                    :link="{
                        name: 'vehicle_type',
                        path: '/vehicle-type',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/vehicle-brand.svg')"
                    v-if="hasRole(1)"
                    :link="{
                        name: 'vehicle_brand',
                        path: '/vehicle-brand',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/vehicle-model.svg')"
                    v-if="hasRole(1)"
                    :link="{
                        name: 'vehicle_model',
                        path: '/vehicle-model',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/box.svg')"
                    v-if="hasRole(1)"
                    :link="{
                        name: 'shipment_type',
                        path: '/shipment-type',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/people.svg')"
                    v-if="hasRole(1)"
                    :link="{
                        name: 'users',
                        path: '/users',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/receipt.svg')"
                    v-if="hasRole(1)"
                    :link="{
                        name: 'order_report',
                        path: '/order-reports',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/notification.svg')"
                    :link="{
                        name: 'broadcast_notification',
                        path: '/broadcast-notifications',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/setting-4.svg')"
                    v-if="hasRole(1)"
                    :link="{
                        name: 'system_settings',
                        icon: 'fas fa-cog text-default',
                        path: '/system-setting',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/image.svg')"
                    v-if="hasRole(1)"
                    :link="{
                        name: 'banner',
                        icon: 'fas fa-image text-default',
                        path: '/banner',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/setting-2.svg')"
                    :link="{
                        name: 'user_settings',
                        icon: 'fas fa-cog text-default',
                        path: '/user-setting',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/textalign-center.svg')"
                    :link="{
                        name: 'activity_log_title',
                        path: '/activity-log',
                    }"
                />
                <sidebar-item
                    :svg="true"
                    :svgPath="require('@/assets/svg/logout.svg')"
                    :link="{
                        name: 'logout',
                        icon: 'fas fa-sign-out-alt text-default',
                        path: '/log-out',
                    }"
                    @click.prevent="$emit('logout')"
                />
            </sidebar-item>
        </template>
    </side-bar>
</template>

<script>
import { mapGetters } from "vuex";
import mixin from "@/mixins/common";

export default {
    data() {
        return {};
    },
    mixins: [mixin],
    computed: {
        ...mapGetters(["user"]),
    },
    components: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>
