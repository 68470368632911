<template>
    <div class="d-inline">
        <div class="col">
            <base-button
                class="text-green bg-transparent border-green"
                @click="addBonus"
            >
                {{ $t("add_bonus") }}
            </base-button>
        </div>
        <!-- <slot :clickEvent="addBonus">
            <el-tooltip :content="$t('add_bonus')" placement="top">
                <img
                    class="mr-2 ml-2 mb-1"
                    style="cursor: pointer"
                    src="@/assets/svg/bonus-add.svg"
                    @click="addBonus"
                />
            </el-tooltip>
        </slot> -->
        <modal
            class="text-left"
            :show="showAddBonusModal"
            @close="showAddBonusModal = false"
            size="sm"
            v-loading="isAddBonusLoading"
        >
            <template v-slot:header>
                <div class="text-bold text-lg text-center mt-2 mb-0">
                    {{ $t("add_bonus") }}
                </div>
            </template>
            <div class="pt-0">
                <Form @submit="showConfirmModel" ref="addOrderRef">
                    <label for="" class="my-1">{{ $t("amount") }}</label>
                    <Field
                        type="number"
                        rules="required|min_value:1|numeric"
                        :label="$t('amount')"
                        class="form-control"
                        :placeholder="$t('amount')"
                        name="bonus_amount"
                    />
                    <ErrorMessage name="bonus_amount" class="error" />

                    <label for="" class="my-1">{{ $t("reason") }}</label>

                    <Field
                        as="textarea"
                        rules="required|max:255"
                        :label="$t('reason')"
                        class="form-control"
                        :placeholder="$t('reason')"
                        name="reason"
                        id="reason"
                        rows="5"
                    />
                    <ErrorMessage name="reason" class="error" />

                    <div class="w-full text-center edit-provider-btn-wrap mt-3">
                        <base-button
                            :loading="isAddBonusLoading"
                            native-type="submit"
                            class="text-white px-4 bg-primary"
                        >
                            {{ $t("confirm") }}
                        </base-button>

                        <input
                            type="button"
                            class="btn btn-default text-white"
                            @click="showAddBonusModal = false"
                            :value="$t('close')"
                        />
                    </div>
                </Form>
            </div>
        </modal>
        <modal
            footerClasses="justify-content-center"
            :show="showBonusConfirmModel"
            @close="showBonusConfirmModel = false"
            size="sm"
            body-classes="p-0"
            v-loading="isAddBonusLoading"
        >
            <template v-slot:header>
                <div>
                    <img
                        src="@/assets/svg/confirm-img.svg"
                        alt=""
                        style="width: 150px; height: 150px"
                    />
                    <div class="text-bold text-lg text-center mt-2 mb-1">
                        {{ $t("bonus_confirm") }}
                    </div>
                </div>
            </template>
            <div class="px-3 d-block text-center">
                <h2 class="text-break font-weight-900">
                    {{ $t("bonus_confirm_text") }}
                </h2>
            </div>
            <template v-slot:footer>
                <div class="w-full text-center mt-3">
                    <base-button
                        @click.native="addBonusSubmit()"
                        :loading="isAddBonusLoading"
                    >
                        {{ $t("yes") }}</base-button
                    >
                    <button
                        class="btn btn-default text-white"
                        data-dismiss="modal"
                        @click="
                            (showAddBonusModal = true),
                                (showBonusConfirmModel = false)
                        "
                    >
                        {{ $t("no") }}
                    </button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { addCreditHistory } from "@/api/creditHistory";
export default {
    data() {
        return {
            showAddBonusModal: false,
            isAddBonusLoading: false,
            bonus_amount: null,
            reason: null,
            showBonusConfirmModel: false,
            bonusFromData: [],
        };
    },
    props: ["id"],
    mixins: [mixin],

    methods: {
        addBonus() {
            this.showAddBonusModal = true;
            this.bonus_amount = "";
            this.reason = "";
            this.$refs.addOrderRef.resetForm();
        },
        showConfirmModel(data) {
            this.bonusFromData = data;
            this.showBonusConfirmModel = true;
            this.showAddBonusModal = false;
        },
        addBonusSubmit() {
            this.isAddBonusLoading = true;
            addCreditHistory({
                user_id: this.id,
                type: "bonus",
                amount: this.bonusFromData.bonus_amount,
                reason: this.bonusFromData.reason,
            })
                .then((res) => {
                    this.isAddBonusLoading = false;
                    this.runToast(this.$t("bonus_add_success"), "success");
                    this.showBonusConfirmModel = false;
                    this.$refs.addOrderRef.resetForm();
                    this.$emit("addBonusComplete");
                })
                .catch(({ response }) => {
                    this.isAddBonusLoading = false;
                    this.apiError(response);
                });
        },
    },
};
</script>

<style lang="scss">
.edit-provider-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit-provider-btn-wrap button,
.edit-provider-btn-wrap input {
    width: 140px;
}
.border-green {
    border-color: #2dce89 !important;
}
</style>
