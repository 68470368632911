<template>
    <div>
        <hr />
        <div class="card mb-0 p-5 rounded-md">
            <div class="d-flex mb-4 pt-2">
                <search-filter
                    :filters="[
                        {
                            id: 'provider_name',
                        },
                    ]"
                    @search="(searchObject) => (filters = searchObject)"
                />
            </div>
            <!-- End of Filter Div -->
            <div class="row">
                <main class="col-md-12 col-xs-11 p-l-2 p-t-2">
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="data"
                            v-loading="isLoading"
                            :empty-text="$t('no_data_found')"
                        >
                            <el-table-column
                                :label="$t('providerName')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <badge
                                        size="lg"
                                        class="bg-sky-dark cell p-2"
                                        style="cursor: pointer"
                                        @click="viewProviderDetail(row.user_id)"
                                        v-if="row.user?.name"
                                    >
                                        <span
                                            class="font-weight-600 name mb-0 text-capitalize"
                                            >{{ row.user?.name }}</span
                                        >
                                    </badge>
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('providerRejectOrderCount')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.display_reason }}</span
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('order_city')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{
                                            row.order?.pickup_address?.city
                                        }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('provider_city')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row?.user?.city?.name }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('rejectedAt')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.display_created_at }}</span
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('order_date')"
                                min-width="200px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.order?.order_date }}</span
                                    >
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- End of Table--->
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="data.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="CurrentPage"
                            :total="TotalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getRejectOrderDetails } from "@/api/orders";

export default {
    data() {
        return {
            isLoading: false,
            data: [],
            meta: {},
            CurrentPage: 1,
            filters: {
                provider_name: "",
            },
            TotalData: null,
        };
    },

    props: ["order_id"],
    async created() {
        this.getOrderRejectDetails();
    },
    watch: {
        CurrentPage: {
            handler: function () {
                this.getOrderRejectDetails();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.CurrentPage = 1;
                this.getOrderRejectDetails();
            },
        },
    },
    mixins: [mixin],
    components: {},
    methods: {
        getOrderRejectDetails() {
            this.isLoading = true;
            getRejectOrderDetails(this.order_id, {
                page: this.CurrentPage,
                filter: {
                    ...this.filters,
                },
                include: "order,user.city",
            })
                .then((res) => {
                    this.isLoading = false;
                    this.data = res.data;
                    this.TotalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        viewProviderDetail(id) {
            let routeData = this.$router.resolve({
                name: "Provider Details",
                params: { id: id },
            });
            window.open(routeData.href, "_blank");
        },
        resetFilter() {
            this.filters = {
                provider_name: "",
            };
        },
    },
};
</script>

<style></style>
