<template>
    <div class="d-inline">
        <slot :clickEvent="systemSettingEditModal">
            <i
                class="mx-3 fas fa-pen fa-md text-default"
                style="cursor: pointer"
                @click="systemSettingEditModal"
            >
            </i>
        </slot>
        <div>
            <modal
                :show="showModal"
                @close="showModal = false"
                size="sm"
                body-classes="p-0"
            >
                <template v-slot:header>
                    <div class="mt-2 mb-1 text-lg text-center text-bold">
                        {{ $t("settings_page.update_setting") }}
                    </div>
                </template>

                <div class="px-3 pb-3 d-block">
                    <Form @submit="updateSystemSetting" v-loading="isLoading">
                        <div
                            v-if="currentRow?.key === 'order_enabled'"
                            class="container"
                        >
                            <div class="row">
                                <label for="" class="my-1"
                                    >{{ $t("settings_page.order_enabled") }}
                                </label>
                            </div>
                            <!-- <Field
                                    :rules="getRules"
                                    :v-model="value"
                                    class="form-control"
                                    :name="$t('settings_page.order_from_time')"
                                > -->
                            <div class="row">
                                <input
                                    type="checkbox"
                                    :id="currentRow?.id"
                                    v-model="value"
                                    name="value"
                                />
                                <!-- :value="currentRow?.key" -->
                            </div>

                            <ErrorMessage name="name" class="error" />
                        </div>

                        <div
                            v-if="
                                (currentRow?.key === 'order_enabled' &&
                                    value === true) ||
                                currentRow?.value === true
                            "
                        >
                            <label for="" class="mt-3">{{
                                $t("settings_page.order_from_time")
                            }}</label>
                            <Field
                                rules="required"
                                v-model="order_from_time"
                                class="form-control"
                                name="order_from_time"
                                :label="$t('settings_page.order_from_time')"
                            >
                                <flat-picker
                                    :config="{
                                        allowInput: false,
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: 'G:i K',
                                    }"
                                    v-model="order_from_time"
                                    class="form-control datepicker"
                                    name="order_from_time"
                                    placeholder="hh:mm A"
                                >
                                </flat-picker>
                            </Field>
                            <ErrorMessage
                                name="order_from_time"
                                class="error"
                            />

                            <label for="validFrom" class="mt-3"
                                >{{ $t("settings_page.order_to_time") }}
                            </label>
                            <Field
                                rules="required"
                                class="form-control"
                                v-model="order_to_time"
                                name="order_to_time"
                                :label="$t('settings_page.order_to_time')"
                            >
                                <flat-picker
                                    v-model="order_to_time"
                                    :config="{
                                        allowInput: false,
                                        enableTime: true,
                                        noCalendar: true,
                                        //minDate: order_from_time,
                                        dateFormat: 'G:i K',
                                    }"
                                    placeholder="hh:mm A"
                                    class="form-control datepicker"
                                    name="order_to_time"
                                >
                                </flat-picker>
                            </Field>
                            <ErrorMessage name="order_to_time" class="error" />
                        </div>

                        <!-- <div class="px-3 mt-3 row" v-if="key === 'bank_logo'">
                            <label for="">{{
                                $t("settings_page.bank_logo")
                            }}</label>
                            <Field
                                rules="required"
                                class="form-control"
                                name="value"
                                v-model="value"
                            />

                            <ErrorMessage :name="label" class="error" />
                        </div> -->

                        <div
                            class="px-3 mt-3 row"
                            v-else-if="
                                currentRow &&
                                currentRow?.key !== 'order_enabled'
                            "
                        >
                            <label for="" class="">
                                {{ $t(`settings_page.${currentRow?.key}`) }}
                            </label>
                            <Field
                                :rules="getRules"
                                class="form-control"
                                name="value"
                                v-model="currentRow.value"
                                :label="$t(`settings_page.${currentRow?.key}`)"
                            />

                            <ErrorMessage name="value" class="error" />
                        </div>

                        <div class="px-3 mt-3 row" v-if="key == 'bank_logo'">
                            <label for="">{{
                                $t("settings_page.bank_logo")
                            }}</label>
                            <div v-if="value !== null && value !== ''">
                                <img :src="value" alt="" />
                            </div>
                            <Field
                                rules="required"
                                class="form-control"
                                name="value"
                                accept="image/*"
                                type="file"
                                v-model="bank_logo"
                            />

                            <ErrorMessage :name="label" class="error" />
                        </div>

                        <div class="w-full mt-4 text-center">
                            <base-button
                                :loading="submitSpinner"
                                native-type="submit"
                                class="text-white bg-primary"
                            >
                                {{ $t("update") }}
                            </base-button>
                            <base-button
                                @click="showModal = false"
                                class="text-white bg-primary"
                            >
                                {{ $t("cancel") }}
                            </base-button>
                        </div>
                    </Form>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
import { editSystemSetting, getSystem } from "@/api/systemSetting";
import "flatpickr/dist/flatpickr.css";
import mixin from "@/mixins/common";
import flatPicker from "vue-flatpickr-component";

export default {
    props: {
        id: {
            type: Number,
            default: () => {},
        },
    },
    components: {
        flatPicker,
    },
    data() {
        return {
            uppercaseKey: null,
            key: "",
            label: "",
            value: false,
            order_from_time: null,
            order_to_time: null,
            bank_logo: null,
            isLoading: false,
            systemSetting: null,
            isShowModal: false,
            submitSpinner: false,
            showModal: false,
            currentRow: null,
        };
    },

    mixins: [mixin],

    computed: {
        getRules() {
            if (
                this.key == "credit_percentage" ||
                this.key == "offer_base_price" ||
                this.key == "shipping_fees" ||
                this.key == "offer_max_price_percentage" ||
                this.key == "vat_percentage" ||
                this.key == "commission_percentage" ||
                this.key == "business_order_base_price" ||
                this.key == "business_order_base_provider_price" ||
                this.key == "business_order_return_price" ||
                this.key == "business_order_cancel_price" ||
                this.key == "store_pickup_orders_default_assembly_charge" ||
                this.key == "provider_first_second_offer_price_difference" ||
                this.key == "offer_price_per_distance" ||
                this.key == "extra_km_price" ||
                this.key == "individual_order_helper_price" ||
                this.key == "individual_order_load_and_upload_price" ||
                this.key == "individual_order_floor_to_price" ||
                this.key == "provider_maximum_minus_credit" ||
                this.key == "provider_order_range_km" ||
                this.key == "provider_near_by_order_km" ||
                this.key == "individual_order_cancel_price" ||
                this.key ==
                    "order_status_change_on_the_way_to_pickup_minutes" ||
                this.key ==
                    "order_status_change_pickup_to_dropped_off_minutes" ||
                this.key == "store_initial_discounted_order_percentage" ||
                this.key == "store_initial_discounted_orders" ||
                this.key == "referral_stcpay_transfer_minimum_credit" ||
                this.key == "referral_user_cashback_amount" ||
                this.key == "referral_first_order_discount_percentage" ||
                this.key == "individual_order_wrapping_price"
            ) {
                return "required|numeric";
            } else {
                return "required";
            }
        },
        selected() {
            return this?.currentRow?.value;
        },
    },

    watch: {},

    created() {},

    methods: {
        async systemSettingEditModal() {
            this.showModal = true;
            //if (this.currentRow?.key == "order_enabled") {
            await this.getSystems();
            //}
        },
        getSystems() {
            this.isLoading = true;
            getSystem(this.id, {})
                .then((res) => {
                    this.isLoading = false;
                    this.currentRow = res;
                    this.value = res.value;
                    this.key = res.key;
                    this.label = this.$t(`settings_page.${res.key}`);
                    if (res.related_settings && res.related_settings != null) {
                        res.related_settings.forEach((value, index) => {
                            if (value.key == "order_from_time") {
                                this.order_from_time = value.value;
                            }
                            if (value.key == "order_to_time") {
                                this.order_to_time = value.value;
                            }
                        });
                    }
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },

        labelUppperCase(k) {
            var key = k?.split("_");
            if (key) {
                for (let i = 0; i < key.length; i++) {
                    key[i] = key[i].charAt(0).toUpperCase() + key[i].slice(1);
                }

                return key.join(" ");
            }
        },

        updateSystemSetting(data) {
            this.submitSpinner = true;
            if (this?.currentRow?.key === "order_enabled") {
                // return (this.selected = this.value);
                this.selected = this.value;
                data["value"] = this.value ? "true" : "false";
            }

            let res1 = {
                value: this.selected,
            };

            editSystemSetting(this.currentRow.id, data, res1)
                .then((res) => {
                    this.submitSpinner = false;
                    this.showModal = false;
                    this.runToast(
                        this.$t("systemSettingUpdated", {
                            setting_key: this.$t(`settings_page.${res.key}`),
                        }),
                        "success"
                    );
                    this.$emit("systemSettingEditCompleted");
                })
                .catch(({ response }) => {
                    this.submitSpinner = false;
                    this.apiError(response);
                });
        },
    },
};
</script>
<style lang="scss">
button.btn {
    padding: 10px 49px !important;
}
</style>
