<template>
    <div>
        <hr />
        <div class="">
            <div class="px-5 py-5 card">
                <div
            class="border-0 card-header px-0 d-flex justify-content-between align-items-center"
            >
            <button
                class="text-white btn btn-default btn-sm"
                @click="showAddTransactionModal = true"
            >
                <i class="mr-2 text-white fas fa-plus-circle fa-lg">
                <span>{{ $t("addTransactionRecord") }}</span>
                </i>
            </button>
            </div>
                <!-- filter section start -->
                <div class="d-flex mb-4">
                    <el-select
                        v-model="filters.store_id"
                        class="d-block"
                        filterable
                        remote
                        :remote-method="remoteSearchStores"
                        :loading="isSearchingStores"
                        :placeholder="$t('select_store')"
                        clearable
                    >
                        <el-option
                            v-for="store in stores"
                            :key="store.id"
                            :value="store.id"
                            :label="store.name"
                        >
                            <span>{{ store.name }}</span>
                            <span class="text-gray-500 text-xs ml-2">
                            ({{ store.user?.mobile_no }})
                            </span>
                        </el-option>
                    </el-select>
                </div>
                <div class="row">
                    <main class="col-md-12 col-xs-11 p-l-2 p-t-2">
                        <div>
                            <el-table
                                class="table-responsive table-flush"
                                header-row-class-name="thead-light"
                                :data="pendingTransaction"
                                v-loading="isLoading"
                                :empty-text="$t('no_data_found')"
                            >
                                <el-table-column
                                    :label="$t('store_name')"
                                    min-width="150px"
                                >
                                    <template v-slot="{ row }">
                                        <badge
                                            size="lg"
                                            class="bg-sky-dark cell p-2"
                                            style="cursor: pointer"
                                            @click="
                                                viewStoreDetail(row.store?.id)
                                            "
                                        >
                                            <span
                                                class="mb-0 font-weight-600 name text-capitalize"
                                                >{{ row.store?.name }}</span
                                            >
                                        </badge>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('bank_name')"
                                    min-width="200px"
                                >
                                    <template v-slot="{ row }">
                                        <span
                                            class="mb-0 font-weight-600 name text-nowrap"
                                            >{{ row.bank_name }}</span
                                        >
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('bank_account_name')"
                                    min-width="150px"
                                >
                                    <template v-slot="{ row }">
                                        <span class="mb-0 font-weight-600 name">
                                            {{ row.bank_account_name }}
                                        </span>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('amount')"
                                    min-width="150px"
                                >
                                    <template v-slot="{ row }">
                                        <span class="mb-0 font-weight-600 name">
                                            {{ row.amount }}
                                        </span>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('transaction_date')"
                                    min-width="150px"
                                >
                                    <template v-slot="{ row }">
                                        <span class="mb-0 font-weight-600 name">
                                            {{ row.transaction_date }}
                                        </span>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('transaction_time')"
                                    min-width="150px"
                                >
                                    <template v-slot="{ row }">
                                        <span class="mb-0 font-weight-600 name">
                                            {{ row.transaction_time }}
                                        </span>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label="$t('actions')"
                                    min-width="210px"
                                    v-if="hasRole(1)"
                                >
                                    <template v-slot="{ row }">
                                        <el-tooltip
                                            :content="$t('confirm')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark"
                                                style="cursor: pointer"
                                                @click="confirmTransaction(row)"
                                            >
                                                <img
                                                    src="@/assets/svg/confirm.svg"
                                                />
                                            </badge>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div
                            class="card-footer p-0 pt-5 pb-5"
                            v-if="pendingTransaction.length > 0"
                        >
                            <base-pagination
                                :meta="meta"
                                v-model="currentPage"
                                :total="totalData"
                            ></base-pagination>
                        </div>
                    </main>
                </div>
            </div>
            <!--  End of Table -->
        </div>

        <!-- Add Transaction Modal -->
        <modal
            :show="showAddTransactionModal"
            @close="showAddTransactionModal = false"
            size="sm"
            :header-classes="'pb-0'"
            v-loading="isLoading"
        >
            <template v-slot:header>
                <div class="text-bold text-lg text-left border-b mt-2 mb-1" style="text-align: left;">
                    {{ $t("addTransactionRecord") }}
                </div>
            </template>

            <div class="px-3 d-block">
            <Form v-slot="{ errors }">
                <div class="pt-0">
                <div class="form-group mb-3">
                    <Field name="bank_name" v-model="newTransaction.bank_name" :label="$t('bank_name')" rules="required" v-slot="{ field }">
                    <input v-bind="field" type="text" class="form-control" :placeholder="$t('bank_name')" />
                    </Field>
                    <ErrorMessage name="bank_name" class="error" />
                </div>

                <div class="form-group mb-3">
                    <Field name="bank_account_name" v-model="newTransaction.bank_account_name" :label="$t('bank_account_name')" rules="required" v-slot="{ field }">
                    <input v-bind="field" type="text" class="form-control" :placeholder="$t('bank_account_name')" />
                    </Field>
                    <ErrorMessage name="bank_account_name" class="error" />
                </div>

                <div class="form-group mb-3">
                    <el-select
                        v-model="newTransaction.store_id"
                        class="d-block"
                        filterable
                        remote
                        :remote-method="remoteSearchStores"
                        :loading="isSearchingStores"
                        :placeholder="$t('select_store')"
                    >
                        <el-option
                            v-for="store in stores"
                            :key="store.id"
                            :value="store.id"
                            :label="store.name"
                        >
                            <span>{{ store.name }}</span>
                            <span class="text-gray-500 text-xs ml-2">
                            ({{ store.user?.mobile_no }})
                            </span>
                        </el-option>
                    </el-select>
                    <ErrorMessage name="store_id" class="error" />
                </div>

                <div class="form-group mb-3">
                    <Field name="credit" v-model="newTransaction.credit" :label="$t('amount')" rules="required|numeric|min_value:1" v-slot="{ field }">
                    <input v-bind="field" type="number" class="form-control" :placeholder="$t('amount')" />
                    </Field>
                    <ErrorMessage name="credit" class="error" />
                </div>

                <div class="form-group mb-3">
                    <Field name="transaction_date" v-model="newTransaction.transaction_date" :label="$t('transaction_date')" rules="required" v-slot="{ field }">
                    <input v-bind="field" type="date" class="form-control" />
                    </Field>
                    <ErrorMessage name="transaction_date" class="error" />
                </div>

                <div class="form-group mb-3">
                    <Field name="transaction_time" v-model="newTransaction.transaction_time" :label="$t('transaction_time')" rules="required" v-slot="{ field }">
                    <input v-bind="field" type="time" class="form-control" />
                    </Field>
                    <ErrorMessage name="transaction_time" class="error" />
                </div>

                <div class="w-full text-center mt-5 d-flex justify-content-between">
                    <base-button
                    class="submit option-submit-btn"
                    @click.native="submitAddTransactionForm"
                    :loading="addTransactionLoading"
                    >
                    {{ $t("submit") }}
                    </base-button>
                    <base-button
                    @click.native="cancelTransaction"
                    class="cancel-button"
                    >
                    {{ $t("cancel") }}
                    </base-button>
                </div>
                </div>
            </Form>
            </div>
        </modal>

        <!-- confirm model -->
        <modal
            :show="showConfirmModal"
            @close="showConfirmModal = false"
            size="ls"
            v-loading="isLoading"
            headerClasses="pb-0"
        >
            <template v-slot:header>
                <div class="text-bold text-lg text-center mt-2 mb-1">
                    {{ $t("confirm") }}
                </div>
            </template>
            <div class="pt-0">
                <p>{{ $t("message.bankTransactionConfirm") }}</p>
                <ul>
                    <li>
                        {{ $t("store") }}: {{ bankTransaction.store?.name }}
                    </li>
                    <li>
                        {{ $t("bank_name") }}: {{ bankTransaction.bank_name }}
                    </li>
                    <li>
                        {{ $t("bank_account_name") }}:
                        {{ bankTransaction.bank_account_name }}
                    </li>
                    <li>{{ $t("amount") }}: {{ bankTransaction.amount }}</li>
                </ul>
            </div>
            <div class="text-center my-4">
                <base-button
                    @click.native="confirmTransactionSubmit"
                    :loading="buttonSpinner"
                >
                    {{ $t("confirm") }}</base-button
                >
                <button
                    class="btn btn-default"
                    @click="showConfirmModal = false"
                >
                    {{ $t("close") }}
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import mixin from "@/mixins/common";
import {
  getPendingTransaction,
  confirmTransaction,
} from "@/api/pendingTransaction";
import { bankTransaction } from "@/api/credit";
import { Form, Field, ErrorMessage } from 'vee-validate';
import { get as getStores } from "@/api/store";

export default {
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return {
      pendingTransaction: [],
      currentPage: 1,
      isLoading: false,
      meta: {},
      totalData: null,
      filters: {
        status: "pending",
        store_id: null,
      },
      showConfirmModal: false,
      bankTransaction: "",
      buttonSpinner: false,
      showAddTransactionModal: false,
      addTransactionLoading: false,
      newTransaction: {
        store_id: null,
        bank_name: '',
        bank_account_name: '',
        credit: '',
        transaction_date: '',
        transaction_time: ''
      },
      stores: [],
      isSearchingStores: false,
    };
  },

    mixins: [mixin],

    components: {},

    created() {
        this.getPendingTransaction();
    },

    watch: {
        currentPage: {
            handler: function () {
                this.getPendingTransaction();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.ordersCurrentPage = 1;
                this.getPendingTransaction();
            },
        },
    },

    computed: {
        ...mapGetters(["activity-log"]),
    },

    methods: {
        getPendingTransaction() {
            this.isLoading = true;
            getPendingTransaction({
                include: "store",
                filter: this.filters,
                query: {
                    ...this.filters,
                },
                page: this.currentPage,
            })
                .then((res) => {
                    this.pendingTransaction = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        confirmTransaction(data) {
            this.bankTransaction = data;
            this.showConfirmModal = true;
        },
        cancelTransaction(){
            this.showAddTransactionModal = false;
            this.resetTransactionForm();
        },
        async confirmTransactionSubmit() {
            this.buttonSpinner = true;
            confirmTransaction(this.bankTransaction.id, {
                status: "confirmed",
            })
                .then((res) => {
                    this.buttonSpinner = false;
                    this.showConfirmModal = false;
                    this.runToast(res.message, "success");
                    this.getPendingTransaction();
                })
                .catch(({ response }) => {
                    this.buttonSpinner = false;
                    this.apiError(response);
                });
        },
        viewStoreDetail(id) {
            this.$router.push({ name: "Store Details", params: { id } });
        },
        async remoteSearchStores(query) {
            if (query.length < 2) {
                this.stores = [];
                return;
            }

            this.isSearchingStores = true;
            try {
                const response = await getStores({
                query: {
                    name: query,
                },
                include: "user",
                limit: 25,
                });
                this.stores = response.data;
            } catch (error) {
                this.apiError(error.response);
            } finally {
                this.isSearchingStores = false;
            }
            },

        resetTransactionForm() {
            this.newTransaction = {
                store_id: null,
                bank_name: '',
                bank_account_name: '',
                credit: '',
                transaction_date: '',
                transaction_time: ''
            };
            this.stores = [];
        },
        async submitAddTransactionForm() {
            if (!this.newTransaction.store_id) {
                this.runToast(this.$t("please_select_store"), "error");
                return;
            }
            this.addTransactionLoading = true;
            try {
                const response = await bankTransaction(this.newTransaction);
                this.addTransactionLoading = false;
                this.showAddTransactionModal = false;
                this.runToast(response.message, "success");
                this.getPendingTransaction();
                this.resetTransactionForm();
            } catch (error) {
                this.addTransactionLoading = false;
                this.apiError(error.response);
            }
        }
    },
};
</script>
