<template>
    <div>
        <hr />
        <div class="p-5 mb-0 card rounded-md">
            <h3 class="mb-0">{{ $t(addOrEdit) }}</h3>

            <Form
                @submit="onSubmit"
                v-loading="isLoading"
                class=""
                ref="formRef"
            >
                <div class="mt-3 row">
                    <div class="col-md-6">
                        <label for="name">{{ $t("type") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            :placeholder="$t('type')"
                            :label="$t('type')"
                            name="type"
                            v-model="form.type"
                        />
                        <ErrorMessage name="type" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="">{{ $t("type_ar") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            :label="$t('type_ar')"
                            class="form-control"
                            :placeholder="$t('type_ar')"
                            name="type_ar"
                            v-model="form.type_ar"
                        />
                        <ErrorMessage name="type_ar" class="error" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="">{{ $t("desc") }}</label>
                        <Field
                            name="desc"
                            class="form-control"
                            v-slot="{ field }"
                            v-model="form.desc"
                            rules="required"
                            :label="$t('desc')"
                        >
                            <textarea
                                name="desc"
                                class="form-control"
                                :placeholder="$t('desc')"
                                rows="5"
                                v-bind="field"
                                v-model="form.desc"
                            ></textarea>
                        </Field>
                        <ErrorMessage name="desc" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="">{{ $t("desc_ar") }}</label>
                        <Field
                            name="desc_ar"
                            class="form-control"
                            v-slot="{ field }"
                            v-model="form.desc_ar"
                            rules="required"
                            :label="$t('desc_ar')"
                        >
                            <textarea
                                name="desc_ar"
                                class="form-control"
                                :placeholder="$t('desc_ar')"
                                rows="5"
                                v-bind="field"
                                v-model="form.desc_ar"
                            ></textarea>
                        </Field>
                        <ErrorMessage name="desc_ar" class="error" />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="status" class="">{{ $t("status") }}</label>
                        <Field
                            v-slot="{ field }"
                            name="status"
                            rules="required"
                            :label="$t('status')"
                            v-model="status"
                        >
                            <el-select
                                class="d-block"
                                name="status"
                                v-bind="field"
                                :placeholder="$t('select')"
                                v-model="status"
                                filterable
                            >
                                <el-option
                                    v-for="option in defaultOptions.vehicleTypeStatus"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="$t(option.label)"
                                    :key="option.label"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage name="status" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="vehicle_weight" class="">{{
                            $t("vehicle_weight")
                        }}</label>
                        <Field
                            type="number"
                            name="vehicle_weight"
                            class="form-control"
                            rules="required|min_value:0"
                            v-model="form.vehicle_weight"
                            :label="$t('vehicle_weight')"
                        />
                        <ErrorMessage name="vehicle_weight" class="error" />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="min_size" class="">{{
                            $t("min_size")
                        }}</label>
                        <Field
                            type="number"
                            name="min_size"
                            class="form-control"
                            rules="required|min_value:0"
                            v-model="form.min_size"
                            :label="$t('min_size')"
                        />
                        <ErrorMessage name="min_size" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="max_size" class="">
                            {{ $t("max_size") }}
                        </label>
                        <Field
                            type="number"
                            name="max_size"
                            class="form-control"
                            rules="required|min_value:0"
                            v-model="form.max_size"
                            :label="$t('max_size')"
                        />
                        <ErrorMessage name="max_size" class="error" />
                    </div>
                </div>

                <div class="row mt-3">
                    <div
                        :class="is_show_level3_price ? 'col-md-4' : 'col-md-6'"
                    >
                        <label for="offer_km_price" class=""
                            >{{ $t("offer_km_price_level_1")
                            }}<b> ({{ $t("0to50Km") }})</b></label
                        >
                        <Field
                            type="number"
                            name="offer_km_price"
                            class="form-control"
                            rules="required|min_value:0"
                            v-model="form.offer_km_price"
                            :label="$t('offer_km_price')"
                        />
                        <ErrorMessage name="offer_km_price" class="error" />
                    </div>
                    <div
                        :class="is_show_level3_price ? 'col-md-4' : 'col-md-6'"
                    >
                        <label for="offer_extra_km_price" class="">
                            {{ $t("offer_extra_km_price_level_2") }}
                            <b> ({{ $t("after50To150Km") }})</b>
                        </label>
                        <Field
                            type="number"
                            name="offer_extra_km_price"
                            class="form-control"
                            rules="required|min_value:0"
                            v-model="form.offer_extra_km_price"
                            :label="$t('offer_extra_km_price')"
                        />
                        <ErrorMessage
                            name="offer_extra_km_price"
                            class="error"
                        />
                    </div>
                    <div class="col-md-4" v-if="is_show_level3_price">
                        <label for="offer_extra_km_price" class="">
                            {{ $t("offer_extra_km_price_level_3") }}
                            <b class="mx-2">
                                ({{ $t("after150ToUnlimitedKm") }})</b
                            >
                            <!-- <el-tooltip
                                :content="$t('status_info')"
                                placement="top"
                            >
                                <template #content>
                                    <p>
                                        {{ $t("note") }}:
                                        {{ $t("vehicleTypeLevel3Note") }}
                                    </p>
                                </template>
                                <badge
                                    circle
                                    size="md"
                                    class="cursor-pointer bg-sky-dark text-secondary"
                                    style="cursor: pointer"
                                >
                                    <i class="mx-1 fas fa-info"></i>
                                </badge>
                            </el-tooltip> -->
                        </label>
                        <Field
                            type="number"
                            name="offer_extra_km_price_level_3"
                            class="form-control"
                            rules="required|min_value:0"
                            v-model="form.offer_extra_km_price_level_3"
                            :label="$t('offer_extra_km_price')"
                        />
                        <ErrorMessage
                            name="offer_extra_km_price_level_3"
                            class="error"
                        />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-2 is-allow-checkbox">
                        <Field
                            name="is_allow_store_pickup_order"
                            v-model="form.is_allow_store_pickup_order"
                        >
                            <el-checkbox
                                class="mx-2"
                                name="is_allow_store_pickup_order"
                                v-model="form.is_allow_store_pickup_order"
                            ></el-checkbox>
                        </Field>
                        <label
                            class="is-allow-label"
                            for="is_allow_store_pickup_order"
                            >{{ $t("is_allow_store_pickup_order") }}</label
                        >
                    </div>
                    <div class="col-md-2 is-allow-checkbox">
                        <Field
                            name="is_allow_business_order"
                            v-model="form.is_allow_business_order"
                        >
                            <el-checkbox
                                class="mx-2"
                                name="is_allow_business_order"
                                v-model="form.is_allow_business_order"
                                @change="allowVehicleType($event, 'business')"
                            ></el-checkbox>
                        </Field>
                        <label
                            class="is-allow-label"
                            for="is_allow_business_order"
                            >{{ $t("is_allow_business_order") }}</label
                        >
                    </div>
                    <div class="col-md-2 is-allow-checkbox">
                        <span v-if="isShowBusinessOrderField">
                            <Field
                                name="is_allow_package_order"
                                v-model="form.is_allow_package_order"
                            >
                                <el-checkbox
                                    class="mx-2"
                                    name="is_allow_package_order"
                                    v-model="form.is_allow_package_order"
                                    @change="
                                        allowVehicleType($event, 'package')
                                    "
                                ></el-checkbox>
                            </Field>
                            <label
                                class="is-allow-label"
                                for="is_allow_package_order"
                            >
                                {{ $t("is_allow_package_order") }}
                            </label>
                        </span>
                    </div>
                    <div class="col-md-6">
                        <label for="" class="">{{
                            $t("individual_order_initial_price")
                        }}</label>

                        <Field
                            type="number"
                            name="individual_order_initial_price"
                            class="form-control"
                            rules="required|min_value:0"
                            v-model="form.individual_order_initial_price"
                            :label="$t('individual_order_initial_price')"
                        />
                        <ErrorMessage
                            name="individual_order_initial_price"
                            class="error"
                        />
                    </div>
                </div>

                <!-- Start: Business order field -->
                <div
                    class="border border-gray p-4 my-4"
                    v-if="isShowBusinessOrderField"
                >
                    <h3 class="mt-0">{{ $t("business_order") }}</h3>

                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label for="" class="">{{
                                $t("business_order_base_price")
                            }}</label>

                            <Field
                                type="number"
                                name="order_base_price"
                                class="form-control"
                                rules="required"
                                v-model="form.order_base_price"
                                :label="$t('business_order_base_price')"
                            />
                            <ErrorMessage
                                name="order_base_price"
                                class="error"
                            />
                        </div>
                        <div class="col-md-6">
                            <label for="" class="">{{
                                $t("business_order_base_provider_price")
                            }}</label>

                            <Field
                                type="number"
                                name="provider_base_price"
                                class="form-control"
                                rules="required"
                                v-model="form.provider_base_price"
                                :label="
                                    $t('business_order_base_provider_price')
                                "
                            />
                            <ErrorMessage
                                name="provider_base_price"
                                class="error"
                            />
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label
                                for="business_order_extra_service_price"
                                class=""
                                >{{
                                    $t("business_order_extra_service_price")
                                }}</label
                            >
                            <Field
                                type="number"
                                name="extra_service_price"
                                class="form-control"
                                rules="required"
                                v-model="form.extra_service_price"
                                :label="
                                    $t('business_order_extra_service_price')
                                "
                            />
                            <ErrorMessage
                                name="extra_service_price"
                                class="error"
                            />
                        </div>
                    </div>
                </div>
                <!-- End: Business order field -->

                <!-- Start: package order field -->
                <div
                    v-if="isShowPackageOrderField"
                    class="border border-gray p-4 my-4"
                >
                    <h3 class="mt-0">{{ $t("package_order") }}</h3>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label for="maximum_orders" class="">{{
                                $t("max_orders")
                            }}</label>
                            <Field
                                type="number"
                                name="maximum_orders"
                                class="form-control"
                                rules="required|min_value:0"
                                v-model="form.maximum_orders"
                                :label="$t('max_orders')"
                            />
                            <ErrorMessage name="maximum_orders" class="error" />
                        </div>

                        <div class="col-md-6">
                            <label for="status" class="">
                                {{ $t("minimum_order_price") }}
                            </label>
                            <Field
                                type="number"
                                name="minimum_order_price"
                                class="form-control"
                                rules="required|min_value:0"
                                v-model="form.minimum_order_price"
                                :label="$t('minimum_order_price')"
                            />
                            <ErrorMessage
                                name="minimum_order_price"
                                class="error"
                            />
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label for="initial_price" class="">{{
                                $t("initial_price")
                            }}</label>
                            <Field
                                type="number"
                                name="initial_price"
                                class="form-control"
                                rules="required|min_value:0"
                                v-model="form.initial_price"
                                :label="$t('initial_price')"
                            />
                            <ErrorMessage name="initial_price" class="error" />
                        </div>
                        <div class="col-md-6">
                            <label for="number_of_orders" class="">
                                {{ $t("number_of_orders") }}
                            </label>
                            <Field
                                type="number"
                                name="number_of_orders"
                                class="form-control"
                                rules="required|min_value:0"
                                v-model="form.number_of_orders"
                                :label="$t('number_of_orders')"
                            />
                            <ErrorMessage
                                name="number_of_orders"
                                class="error"
                            />
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label for="threshold" class="">{{
                                $t("threshold")
                            }}</label>
                            <Field
                                type="number"
                                name="threshold"
                                class="form-control"
                                rules="required|min_value:0|max_value:100"
                                v-model="form.threshold"
                                :label="$t('threshold')"
                            />
                            <ErrorMessage name="threshold" class="error" />
                        </div>
                        <div class="col-md-6">
                            <label for="provider_percentage" class="">
                                {{ $t("provider_percentage") }}
                            </label>
                            <Field
                                type="number"
                                name="provider_percentage"
                                class="form-control"
                                rules="required|min_value:0|max_value:100"
                                v-model="form.provider_percentage"
                                :label="$t('provider_percentage')"
                            />
                            <ErrorMessage
                                name="provider_percentage"
                                class="error"
                            />
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label for="minimum_charge" class="">{{
                                $t("minimum_charge")
                            }}</label>
                            <Field
                                type="number"
                                name="minimum_charge"
                                class="form-control"
                                rules="required|min_value:0"
                                min="0"
                                v-model="form.minimum_charge"
                                :label="$t('minimum_charge')"
                            />
                            <ErrorMessage name="minimum_charge" class="error" />
                        </div>
                        <div class="col-md-6">
                            <label for="helper_price" class="">
                                {{ $t("helper_price") }}
                            </label>
                            <Field
                                type="number"
                                name="helper_price"
                                class="form-control"
                                rules="required|min_value:0"
                                v-model="form.helper_price"
                                :label="$t('helper_price')"
                            />
                            <ErrorMessage name="helper_price" class="error" />
                        </div>
                    </div>
                </div>

                <!-- End: Package order field -->

                <!-- <div class="row mt-3"></div> -->

                <div class="row mt-3">
                    <div
                        :class="
                            form.vehicle_image && id != 'add'
                                ? 'col-md-6'
                                : 'col-md-12'
                        "
                    >
                        <label for="vehicle_type_image" class="my-1 mr-2">{{
                            $t("vehicle_type_image")
                        }}</label>
                        <dropzone-file-upload
                            @thumbnail="imageUploadEvent"
                        ></dropzone-file-upload>
                        <p class="error" v-if="showVehicleImageValidateMessage">
                            {{ $t("vehicle_image_required") }}
                        </p>
                    </div>
                    <div
                        class="col-md-6"
                        v-if="form.vehicle_image && id != 'add'"
                    >
                        <label for="vehicle_type_image" class="my-1 mr-2">{{
                            $t("vehicle_type_image")
                        }}</label>
                        <div class="mt-2">
                            <img
                                :src="form.vehicle_image"
                                alt=""
                                srcset=""
                                class="w-auto"
                                style="max-width: 38%"
                            />
                        </div>
                    </div>
                </div>

                <div class="w-full text-center mt-5">
                    <base-button
                        native-type="submit"
                        class="bg-primary text-white"
                        :loading="submitSpinner"
                    >
                        {{ $t("save") }}
                    </base-button>
                    <base-button
                        native-type="button"
                        class="bg-primary text-white mr-2"
                        @click="goToList"
                    >
                        {{ $t("cancel") }}
                    </base-button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import {
    addVehicleType,
    editVehicleType,
    getVehicleType,
} from "@/api/vehicleType";
import mixin from "@/mixins/common";
import { serialize } from "object-to-formdata";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";

export default {
    data() {
        return {
            addOrEdit: "",
            vehicle: {},
            form: {
                type: null,
                type_ar: null,
                desc: null,
                desc_ar: null,
                order_base_price: null,
                provider_base_price: null,
                extra_service_price: null,
                maximum_orders: 0,
                minimum_order_price: 0,
                is_allow_store_pickup_order: false,
                is_allow_business_order: false,
                is_allow_package_order: false,
                vehicle_image: null,
                number_of_orders: null,
                initial_price: null,
                helper_price: null,
                threshold: null,
                provider_percentage: null,
                minimum_charge: null,
                offer_km_price: null,
                offer_extra_km_price: null,
                offer_extra_km_price_level_3: null,
                is_show_level3_price: false,
            },
            status: "",
            statusOptions: [
                {
                    label: "Active",
                    value: "active",
                },
                {
                    label: "InActive",
                    value: "inactive",
                },
            ],
            isLoading: false,
            submitSpinner: false,
            showVehicleImageValidateMessage: false,
            vehicle_image: "",
            isShowBusinessOrderField: false,
            isShowPackageOrderField: false,
        };
    },
    props: ["id"],
    mixins: [mixin],
    components: { DropzoneFileUpload },
    created() {
        if (this.id === "add") {
            this.addOrEdit = this.$t("add_vehicle_type");
        } else {
            this.addOrEdit = this.$t("edit_vehicle_type");
            this.getVehicleType();
        }
    },

    methods: {
        goToList() {
            this.$router.push({ name: "VehicleType List" });
        },
        imageUploadEvent(data) {
            this.vehicle_image = data;
        },
        getVehicleType() {
            this.isLoading = true;
            getVehicleType(this.id, {
                request_for: "web",
            })
                .then((res) => {
                    this.isLoading = false;
                    this.vehicle = res;
                    this.form = res;
                    this.status = res.status;
                    if (res.is_allow_business_order) {
                        this.isShowBusinessOrderField = true;
                    }

                    if (res.is_allow_package_order) {
                        this.isShowPackageOrderField = true;
                    }

                    // Only for show medium vehicle types
                    if (
                        (process.env.NODE_ENV == "production" && res.id == 4) ||
                        res.id == 2
                    ) {
                        this.is_show_level3_price = true;
                    }
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        allowVehicleType(isChecked, type) {
            if (type == "business") {
                if (isChecked) {
                    this.isShowBusinessOrderField = true;
                } else {
                    this.isShowBusinessOrderField = false;
                    this.isShowPackageOrderField = false;
                }
            }

            if (type == "package") {
                if (isChecked) {
                    this.isShowPackageOrderField = true;
                } else {
                    this.isShowPackageOrderField = false;
                }
            }
        },
        async onSubmit(data) {
            this.submitSpinner = true;

            if (!this.vehicle_image && !this.form.vehicle_image) {
                this.showVehicleImageValidateMessage = true;
                this.submitSpinner = false;
                return false;
            } else {
                this.showVehicleImageValidateMessage = false;
            }

            const formData = serialize(data);

            formData.append("vehicle_image", this.vehicle_image);
            formData.append(
                "is_allow_business_order",
                this.form.is_allow_business_order ? 1 : 0
            );
            formData.append(
                "is_allow_package_order",
                this.form.is_allow_package_order ? 1 : 0
            );
            formData.append(
                "is_allow_store_pickup_order",
                this.form.is_allow_store_pickup_order ? 1 : 0
            );

            if (this.id == "add") {
                addVehicleType(formData)
                    .then(() => {
                        this.submitSpinner = false;
                        this.$router.push({ name: "VehicleType List" });
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            } else {
                editVehicleType(this.id, formData, {
                    _method: "PATCH",
                })
                    .then(() => {
                        this.isLoading = false;
                        this.$router.push({ name: "VehicleType List" });
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            }
        },
    },
};
</script>

<style lang="scss">
.is-allow-checkbox {
    display: flex;
    height: auto;
    align-items: center;
    label {
        margin-bottom: 0;
    }
}
.is-allow-label {
    margin-bottom: 0px;
}
</style>
