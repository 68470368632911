import axiosInstance from "@/plugins/customAxios";

export const getSettings = (params = null) => {
    return axiosInstance
        .get(`v1/settings?ascending=0&byColumn=1&request_from=web`, {
            params: params,
        })
        .then((res) => {
            return res.data;
        });
};

export const getSystem = (id, params = null) => {
    return axiosInstance
        .get(`v1/admin/settings/${id}?request_from=web&include=related_settings`)
        .then((res) => {
            return res.data;
        });
};

export const editSystemSetting = (id, data) => {
    return axiosInstance.post(`v1/admin/settings/${id}`, data).then((res) => {
        return res.data;
    });
};

// This api is return the system setting value in key value pair
export const getSettingKeyValue = (params = null) => {
    return axiosInstance
        .get('v1/setting-key-value', { params: params }).then((res) => {
            return res.data
        });
};