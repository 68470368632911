import axiosInstance from "@/plugins/customAxios";

export const getBanners = (params = null) => {
    return axiosInstance
        .get("v1/banners", { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getBanner = (id, params = null) => {
    return axiosInstance
        .get(`v1/admin/banners/${id}`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const editBanner = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/admin/banners/${id}`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};
export const addBanner = (data, params = null) => {
    return axiosInstance
        .post("v1/admin/banners", data, { params: params })
        .then((res) => {
            return res.data;
        });
};
export const deleteBanner = (id) => {
    return axiosInstance.delete(`v1/admin/banners/${id}`).then((res) => {
        return res.data;
    });
};