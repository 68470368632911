<template>
    <div>
        <hr />
        <div class="card py-3 px-5 rounded-md">
            <div
                class="border-0 card-header px-0 d-flex justify-content-between align-items-center"
            >
                <router-link
                    :to="{
                        name: 'ShipmentType Upsert',
                        params: { id: 'add' },
                    }"
                >
                    <button class="btn btn-default text-white btn-sm">
                        <i class="fas fa-plus-circle fa-lg text-white mr-2">
                            <span>{{ $t("add_shipment_type") }}</span>
                        </i>
                    </button>
                </router-link>
            </div>

            <!-- Filter Section Start -->
            <div class="d-flex mb-4">
                <search-filter
                    :filters="[
                        {
                            id: 'type',
                        },
                        {
                            id: 'type_ar',
                        },
                    ]"
                    @search="(searchObject) => (filters = searchObject)"
                />
            </div>
            <!-- Filter Section End  -->

            <div class="row">
                <main
                    :class="
                        isFilter
                            ? 'col-md-9 col-xs-11 p-l-2 p-t-2'
                            : 'col-md-12 col-xs-11 p-l-2 p-t-2'
                    "
                >
                    <div>
                        <el-table
                            class="table-responsive table-flush"
                            header-row-class-name="thead-light"
                            :data="shipmentType"
                            v-loading="isLoading"
                            :empty-text="$t('no_data_found')"
                            @row-click="rowClicked"
                        >
                            <el-table-column
                                :label="$t('type')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-capitalize"
                                        >{{ row.type }}</span
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('description')"
                                min-width="250px"
                            >
                                <template v-slot="{ row }">
                                    <span
                                        class="font-weight-600 name mb-0 text-nowrap"
                                        >{{ row.desc }}</span
                                    >
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('type_ar')"
                                min-width="150px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">{{
                                        row.type_ar
                                    }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('desc_ar')"
                                min-width="250px"
                            >
                                <template v-slot="{ row }">
                                    <span class="font-weight-600 name mb-0">{{
                                        row.desc_ar
                                    }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                :label="$t('status')"
                                min-width="150px"
                                prop="status"
                            >
                                <template v-slot="{ row }">
                                    <custom-label :label="row.status" />
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="$t('actions')"
                                min-width="210px"
                            >
                                <template v-slot="{ row }">
                                    <div class="d-flex justify-content-between">
                                        <el-tooltip
                                            :content="$t('edit')"
                                            placement="top"
                                        >
                                            <badge
                                                circle
                                                size="lg"
                                                class="bg-sky-dark text-secondary cursor-pointer"
                                                :title="$t('edit')"
                                                style="cursor: pointer"
                                            >
                                                <router-link
                                                    target="_blank"
                                                    class="nav-link"
                                                    :to="
                                                        '/shipment-type/' +
                                                        row.id
                                                    "
                                                >
                                                    <img
                                                        src="@/assets/svg/edit-2.svg"
                                                    />
                                                </router-link>
                                            </badge>
                                        </el-tooltip>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                        class="card-footer p-0 pt-5 pb-5"
                        v-if="shipmentType.length > 0"
                    >
                        <base-pagination
                            :meta="meta"
                            v-model="currentPage"
                            :total="totalData"
                        ></base-pagination>
                    </div>
                </main>
            </div>
        </div>
        <!--  End of Table -->
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import { getShipmentTypes } from "@/api/shipmentType";
export default {
    data() {
        return {
            shipmentType: [],
            currentPage: 1,
            filters: {},
            isLoading: false,
            meta: {},
            totalData: null,
            isFilter: false,
        };
    },
    mixins: [mixin],
    created() {
        this.getShipmentTypes();
    },
    watch: {
        currentPage: {
            handler: function () {
                this.getShipmentTypes();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.currentPage = 1;
                this.getShipmentTypes();
            },
        },
    },
    methods: {
        toggleFilter() {
            this.isFilter = !this.isFilter;
            if (this.isFilter) {
                $("#togglebar").addClass("show");
            } else {
                $("#togglebar").removeClass("show");
            }
        },
        getShipmentTypeStatusColor(status) {
            if (status === "active") return this.statusColor.status.active;
            else if (status === "inactive")
                return this.statusColor.status.inactive;
        },
        getShipmentTypes() {
            this.isLoading = true;
            getShipmentTypes({
                page: this.currentPage,
                filter: this.filters,
                query: { ...this.filters },
            })
                .then((res) => {
                    this.shipmentType = res.data;
                    this.isLoading = false;
                    this.totalData = res.meta.total;
                    this.meta = res.meta;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        resetFilter() {
            this.filters = {
                type: "",
                desc: "",
                type_ar: "",
                desc_ar: "",
            };
        },
        rowClicked(row, column, event) {
            if(column.label != this.$t("actions")){
                this.$router.push({
                    name: "ShipmentType Upsert",
                    params: { id: row.id },
                });
            }
            return;
        }
    },
};
</script>
