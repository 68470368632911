import axiosInstance from "@/plugins/customAxios";

// Api call for getting provider vehicle details like name , type, id
export const getVehicle = (params = null) => {
    return axiosInstance
        .get("v1/admin/vehicles", { params: params })
        .then((res) => {
            return res.data;
        });
};
