<template>
    <div>
        <hr />
        <div class="p-5 mb-0 card rounded-md">
            <h3 class="mb-5">{{ $t(addOrEdit) }}</h3>

            <Form @submit="onSubmit" v-loading="isLoading" class="">
                <div class="row">
                    <div class="col-md-6">
                        <label for="">{{ $t("store_name") }}</label>
                        <Field
                            type="text"
                            rules="required"
                            class="form-control"
                            :placeholder="$t('store_name')"
                            :label="$t('store_name')"
                            name="name"
                            v-model="form.name"
                        />
                        <ErrorMessage name="name" class="error" />
                    </div>
                    <div class="col-md-6">
                        <label for="status">{{ $t("status") }}</label>
                        <Field
                            v-slot="{ field }"
                            name="status"
                            rules="required"
                            :label="$t('status')"
                            v-model="form.status"
                        >
                            <el-select
                                class="d-block"
                                name="status"
                                v-bind="field"
                                :placeholder="$t('select')"
                                v-model="form.status"
                                filterable
                            >
                                <el-option
                                    v-for="option in defaultOptions.status"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="$t(option.label)"
                                    :key="option.label"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage name="status" class="error" />
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="open_time">{{ $t("open_time") }}</label>
                        <Field
                            rules="required"
                            v-model="form.open_time"
                            class="form-control"
                            name="open_time"
                            :label="$t('open_time')"
                        >
                            <flat-picker
                                :config="{
                                    allowInput: false,
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: 'G:i K',
                                }"
                                v-model="form.open_time"
                                class="form-control datepicker"
                                name="open_time"
                                placeholder="hh:mm A"
                            >
                            </flat-picker>
                        </Field>
                        <ErrorMessage name="open_time" class="error" />
                    </div>

                    <div class="col-md-6">
                        <label for="close_time">{{ $t("close_time") }} </label>
                        <Field
                            rules="required"
                            class="form-control"
                            v-model="form.close_time"
                            name="close_time"
                            :label="$t('close_time')"
                        >
                            <flat-picker
                                v-model="form.close_time"
                                :config="{
                                    allowInput: false,
                                    enableTime: true,
                                    noCalendar: true,
                                    minDate: form.open_time,
                                    dateFormat: 'G:i K',
                                }"
                                placeholder="hh:mm A"
                                class="form-control datepicker"
                                name="open_time"
                            >
                            </flat-picker>
                        </Field>
                        <ErrorMessage name="open_time" class="error" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label for="district_id" class="form-control-label">{{
                            $t("district")
                        }}</label>
                        <Field
                            rules="required"
                            :label="$t('district')"
                            v-model="form.district_id"
                            name="district_id"
                        >
                            <el-select
                                :placeholder="$t('select')"
                                v-model="form.district_id"
                                class="d-block"
                                name="district_id"
                                @change="changeDistrict(form.district_id)"
                                filterable
                                rules="required"
                            >
                                <el-option
                                    v-for="option in districtOptions"
                                    class="select-danger"
                                    :value="option.id"
                                    :label="
                                        lang == 'ar'
                                            ? option.name_ar
                                            : option.name
                                    "
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>
                        </Field>
                        <ErrorMessage
                            name="district_id"
                            class="error"
                        ></ErrorMessage>
                        <google-maps
                            :lat="form.latitude"
                            :lon="form.longitude"
                            @locationChanged="locationChanged($event)"
                        />
                    </div>
                    <div class="col-md-6">
                        <label for="logo">{{ $t("logo") }}</label>
                        <Field
                            :rules="id == 'add' ? 'required' : ''"
                            :label="$t('logo')"
                            v-model="form.logo"
                            name="logo"
                        >
                            <dropzone-file-upload
                                v-model="form.logo"
                                @thumbnail="handleFileUpload"
                            ></dropzone-file-upload>
                        </Field>
                        <ErrorMessage name="logo" class="error" />
                        <div v-if="logo && id != 'add'">
                            <label for="logo" class="my-1 mr-2">{{
                                $t("uploaded_logo")
                            }}</label>
                            <div class="mt-2">
                                <img
                                    :src="logo"
                                    alt=""
                                    srcset=""
                                    class="avatar avatar-xl"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6"></div>
                </div>

                <div class="w-full text-center mt-5">
                    <base-button
                        native-type="submit"
                        class="bg-primary text-white"
                        :loading="submitSpinner"
                    >
                        {{ id === "add" ? $t("create") : $t("update") }}
                    </base-button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/common";
import "flatpickr/dist/flatpickr.css";
import { getDistricts } from "@/api/addresses";
import { serialize } from "object-to-formdata";
import GoogleMaps from "@/components/GoogleMaps";
import flatPicker from "vue-flatpickr-component";
import { add, edit, getStore } from "@/api/pickupStore";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";

export default {
    data() {
        return {
            addOrEdit: "",
            form: {
                name: null,
                status: null,
                open_time: null,
                close_time: null,
                district_id: null,
                latitude: 24.774265,
                longitude: 46.738586,
                logo: null,
            },
            logo: null,
            isLoading: false,
            submitSpinner: false,
            districtOptions: [],
        };
    },
    props: ["id"],
    mixins: [mixin],
    components: {
        flatPicker,
        GoogleMaps,
        DropzoneFileUpload,
    },
    created() {
        this.getDistricts();
        if (this.id === "add") {
            this.addOrEdit = this.$t("add_pickup_store");
        } else {
            this.addOrEdit = this.$t("edit_pickup_store");
            this.getStoreDetails();
        }
    },

    methods: {
        getStoreDetails() {
            this.isLoading = true;
            getStore(this.id, {
                include: "district",
            })
                .then((res) => {
                    this.isLoading = false;
                    this.logo = res.logo;
                    this.form = res;
                    this.form.logo = null;
                })
                .catch(({ response }) => {
                    this.apiError(response);
                });
        },
        async onSubmit(data) {
            const formData = serialize(data);

            formData.append("latitude", this.form.latitude);
            formData.append("longitude", this.form.longitude);

            if (this.form.logo) formData.append("logo", this.form.logo);

            this.submitSpinner = true;
            if (this.id == "add") {
                add(formData)
                    .then((res) => {
                        this.submitSpinner = false;
                        this.$router.push({ name: "pickup-stores" });
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            } else {
                edit(this.id, formData)
                    .then((res) => {
                        this.submitSpinner = false;
                        this.$router.push({ name: "pickup-stores" });
                    })
                    .catch(({ response }) => {
                        this.submitSpinner = false;
                        // Below method is a common Mixin method that shows error on screen through toast Notification
                        this.apiError(response);
                    });
            }
        },
        getDistricts() {
            // Api Call
            getDistricts({
                limit: -1,
                filter: {
                    city_id: 3,
                },
            })
                .then((res) => {
                    this.districtOptions = res.data;
                })
                .catch(({ response }) => {
                    // Below method is a common Mixin method that shows error on screen through toast Notification
                    this.apiError(response);
                });
        },
        locationChanged(event) {
            this.form.latitude = event.latitude;
            this.form.longitude = event.longitude;
        },
        changeDistrict(district_id) {
            var districtName = this.districtOptions.filter(
                (district) => district.id === district_id
            );
            const geocoder = new google.maps.Geocoder();
            const address = districtName[0]["name"]
                ? districtName[0]["name"]
                : "";
            if (this.form.address_line1 === "") {
                this.form.address_line1 = address;
            }
            geocoder.geocode({ address }, (results, status) => {
                if (status === "OK") {
                    this.form.latitude = results[0].geometry.location.lat();
                    this.form.longitude = results[0].geometry.location.lng();
                }
            });
        },
        handleFileUpload(data) {
            this.form.logo = data;
        },
    },
};
</script>

<style></style>
