<template>
    <badge
        size="lg"
        class="bg-sky-dark cell p-2"
        style="cursor: pointer"
        @click="viewOrderDetail(row)"
        v-if="row?.order_no"
    >
        <span class="font-weight-600 name mb-0 text-capitalize">{{
            row?.order_no
        }}</span>
    </badge>
</template>

<script>
import mixin from "@/mixins/common";
export default {
    mixins: [mixin],
    name: "custom-label",
    data() {
        return {};
    },
    props: {
        row: {
            type: [String],
            required: true,
            default: "row",
        },
    },
    methods: {
        viewOrderDetail(row) {
            let routeData;
            if (row.store_id != null) {
                routeData = this.$router.resolve({
                    name: "Store Order Detail",
                    params: { id: row.id },
                });
            } else {
                routeData = this.$router.resolve({
                    name: "Order Details",
                    params: { id: row.id },
                });
            }
            window.open(routeData.href, "_blank");
        },
    },
};
</script>

<style scoped lang="scss"></style>
