import axiosInstance from "@/plugins/customAxios";
import store from "@/store/index";

export const getOrderStatusCounter = (params = null) => {
    let baseUrl;
    if ([1, 6, 7, 8, 9].includes(store.getters.user?.role_id)) {
        baseUrl = "admin";
    } else {
        baseUrl = "store";
    }
    return axiosInstance
        .get(`v1/${baseUrl}/dashboard/orders/counter`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getOrderCount = (params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 4 ||
        store.getters.user?.role_id === 5
    ) {
        baseUrl = "store";
    } else {
        baseUrl = "admin";
    }
    return axiosInstance
        .get(`v1/${baseUrl}/dashboard/order/status/counter`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getProviderCount = (params = null) => {
    return axiosInstance
        .get(`v1/admin/dashboard/provider/count`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getAddressCount = (params = null) => {
    return axiosInstance
        .get(`v1/store/dashboard/address/count`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getTotalRevenueCount = (params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 4 ||
        store.getters.user?.role_id === 5
    ) {
        baseUrl = "store";
    } else {
        baseUrl = "admin";
    }
    return axiosInstance
        .get(`v1/${baseUrl}/dashboard/order/revenue`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getCreditPaymentCount = (params = null) => {
    return axiosInstance
        .get(`v1/store/dashboard/total-credit-payment`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getCustomerCount = (params = null) => {
    return axiosInstance
        .get(`v1/admin/dashboard/customer/count`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getUserCount = (params = null) => {
    return axiosInstance
        .get(`v1/store/dashboard/users/count`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getStoreStatisticsCount = (params = null) => {
    return axiosInstance
        .get("v1/admin/dashboard/store-statistics", { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getChartData = (params = null) => {
    let baseUrl;
    if (
        store.getters.user?.role_id === 4 ||
        store.getters.user?.role_id === 5
    ) {
        baseUrl = "store";
    } else {
        baseUrl = "admin";
    }
    return axiosInstance
        .get(`v1/${baseUrl}/dashboard/orders/chart`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const geOrderIndicator = (params = null) => {
    return axiosInstance
        .get(`v1/admin/dashboard/order-indicators`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getStoreCount = (params = null) => {
    return axiosInstance
        .get(`v1/admin/dashboard/store/count`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getComplaintCount = (params = null) => {
    return axiosInstance
        .get(`v1/admin/dashboard/complaint/count`, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const getOrderAverage = (params = null) => {
    return axiosInstance
        .get(`v1/admin/dashboard/order/average`, { params: params })
        .then((res) => {
            return res.data;
        });
};