import axiosInstance from "@/plugins/customAxios";

export const get = (params = null) => {
    return axiosInstance.get("v1/items", { params: params }).then((res) => {
        return res.data;
    });
};

export const detail = (id, params = null) => {
    return axiosInstance.get(`v1/items/${id}`, { params: params }).then((res) => {
        return res.data;
    });
};

export const edit = (id, data, params = null) => {
    return axiosInstance
        .post(`v1/items/${id}?_method=PATCH`, data, { params: params })
        .then((res) => {
            return res.data;
        });
};

export const add = (data, params = null) => {
    return axiosInstance.post("v1/items", data, { params: params }).then((res) => {
        return res.data;
    });
};

export const deleteItem = (id) => {
    return axiosInstance.delete(`v1/items/${id}`).then((res) => {
        return res.data;
    });
};