import { createRouter, createWebHashHistory } from "vue-router";

import item from "./item";
import order from "./order";
import users from "./users";
import stores from "./store";
import banner from "./banner";
import provider from "./provider";
import store from "@/store/index";
import complaint from "./complaint";
import customers from "./customers";
import addresses from "./addresses.js";
import couponCode from "./couponCodes";
import activityLog from "./activityLog";
import orderReject from "./orderReject";
import storeCredit from "./storeCredit";
import vehicleType from "./vehicleType";
import pickupStore from "./pickupStore";
import orderReport from "./orderReport";
import vehicleBrand from "./vehicleBrand";
import vehicleModel from "./vehicleModel";
import shipmentType from "./shipmentType";
import $i18n from "@/plugins/lang/i18n.js";
import Login from "@/views/Pages/Login.vue";
import creditHistory from "./creditHistory";
import SmsLogin from "@/views/Pages/SmsLogin";
import AuthLayout from "@/views/Pages/AuthLayout";
import businessComplaints from "./businessComplaints";
import pendingTransaction from "./pendingTransaction";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import OperationDashboard from "@/views/Dashboard/OperationDashboard.vue";
import UserSetting from "@/views/Pages/UserSetting.vue";
import SystemSetting from "@/views/Pages/SystemSetting.vue";
import broadcastNotification from "./broadcastNotification";
import DashboardLayout from "@/views/Layout/DashboardLayout";
import ProviderCredits from "@/views/Pages/ProviderCredits.vue";
import ReferralCredits from "@/views/Pages/ReferralCredits.vue";
import serviceProviderCompanies from "./serviceProviderCompanies";

const routes = [{
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    name: "Dashboard",
    children: [
        ...item,
        ...users,
        ...order,
        ...stores,
        ...banner,
        ...provider,
        ...addresses,
        ...customers,
        ...complaint,
        ...couponCode,
        ...pickupStore,
        ...orderReject,
        ...vehicleType,
        ...storeCredit,
        ...orderReport,
        ...activityLog,
        ...vehicleBrand,
        ...vehicleModel,
        ...shipmentType,
        ...creditHistory,
        ...businessComplaints,
        ...pendingTransaction,
        ...broadcastNotification,
        ...serviceProviderCompanies,
        {
            path: "/dashboard",
            name: "Dashboard",
            components: { default: Dashboard },
            meta: {
                title: "dashboard",
            },
        },
        {
            path: "/operation-dashboard",
            name: "Operation Dashboard",
            components: { default: OperationDashboard },
            meta: {
                title: "operation_team",
            },
        },
        {
            path: "/user-setting",
            name: "User Setting",
            components: { default: UserSetting },
            meta: {
                title: "user_settings",
            },
        },
        {
            path: "/system-setting",
            name: "System Settings",
            components: { default: SystemSetting },
            meta: {
                title: "system_settings",
            },
        },
        {
            path: "/provider-credits",
            name: "Provider Credits",
            components: { default: ProviderCredits },
            meta: {
                title: "provider_credits",
            },
        },
        {
            path: "/referral-credits",
            name: "Referral Credits",
            components: { default: ReferralCredits },
            meta: {
                title: "referral_credits",
            },
        },

        {
            path: "/log-out",
            name: "logOut",
        },
    ],
},
{
    path: "/",
    redirect: "/",
    component: AuthLayout,
    children: [{
        path: "/login",
        name: "Login",
        components: { default: Login },
        meta: {
            title: $i18n.global.t("login"),
            public: true,
        },
    },
    {
        path: "/sms-login",
        name: "Login With SMS",
        components: { default: SmsLogin },
        meta: {
            title: $i18n.global.t("login_with_sms"),
            public: true,
        },
    },
    ],
},
];

const router = createRouter({
    history: createWebHashHistory(),
    linkActiveClass: "active",
    routes,
});

export default router;

// router before each method intercepts request before navigating user to url
// to is the destination url
// from is the source url
// next is the method for custom redirection
router.beforeEach((to, from, next) => {
    document.title = to.meta.title ?
        $i18n.global.t(to.meta.title) + " | " + $i18n.global.t("one8App") :
        $i18n.global.t("one8App");
    if (to.meta.public) {
        // above if checks if user navigates to public routes like Login, Forgot Password , reset password etc
        // then check for token , if found redirect to dashboard.
        if (localStorage.getItem("token")) next({ name: "Dashboard" });
        else {
            // else proceed normal routing
            next();
        }
    } else {
        if (localStorage.getItem("token")) {
            // below if checks for role access and if not found then take user directly to Login Screen .
            if (
                to.meta.role &&
                !to.meta.role.includes(store.getters["user"].role_id)
            ) {
                next({ name: "Login" });
            } else {
                next();
            }
        } else {
            // else if token not found redirect user to login screen
            next({ name: "Login" });
        }
    }
});